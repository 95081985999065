import { amplitudeLabels, amplitudeValues } from 'common/constants/ecgEnums';
import { createEnum } from 'common/utils/actionTypesHelper';
import resources from 'common/constants/resources';

export const presetsEnum = createEnum({
  sinusTachyThreshold: null,
  bradyThreshold: null,
  pauseThreshold: null,
  ventricularTachyThreshold: null,
  supraVentricularTachyThreshold: null,
  prematureIndex: null,
  // amplitude: null,
});

export const presetsLabels = createEnum({
  [presetsEnum.pauseThreshold]: 'Pause',
  [presetsEnum.sinusTachyThreshold]: 'Tachy (from)',
  [presetsEnum.bradyThreshold]: 'Brady (to)',
  [presetsEnum.ventricularTachyThreshold]: 'V-Tach',
  [presetsEnum.supraVentricularTachyThreshold]: 'SV-Tach',
  [presetsEnum.prematureIndex]: 'Premature Index',
  // [presetsEnum.amplitude]: 'Amplitude',
});

export const presetsValueRanges = {
  [presetsEnum.pauseThreshold]: [1, 20],
  [presetsEnum.sinusTachyThreshold]: [90, 500],
  [presetsEnum.bradyThreshold]: [1, 90],
  [presetsEnum.ventricularTachyThreshold]: [90, 500],
  [presetsEnum.supraVentricularTachyThreshold]: [90, 500],
  [presetsEnum.prematureIndex]: [10, 90],
};

export const presetsMetrics = createEnum({
  [presetsEnum.pauseThreshold]: 's',
  [presetsEnum.sinusTachyThreshold]: 'bpm',
  [presetsEnum.bradyThreshold]: 'bpm',
  [presetsEnum.ventricularTachyThreshold]: 'bpm',
  [presetsEnum.supraVentricularTachyThreshold]: 'bpm',
  [presetsEnum.prematureIndex]: '%',
  // [presetsEnum.amplitude]: 'Mv',
});

export const amplitudeOptions = [
  {
    value: amplitudeValues.standard,
    label: amplitudeLabels[amplitudeValues.standard],
  },
  {
    value: amplitudeValues.x2,
    label: amplitudeLabels[amplitudeValues.x2],
  },
  {
    value: amplitudeValues.x4,
    label: amplitudeLabels[amplitudeValues.x4],
  },
];

export const parentEntityOfPresets = createEnum({
  tenant: null,
  procedure: null,
  physician: null,
});

export const resourcesAccordingToPresets = createEnum({
  [parentEntityOfPresets.tenant]: resources.healthStatePreset.tenant,
  [parentEntityOfPresets.procedure]: resources.healthStatePreset.procedure,
  [parentEntityOfPresets.physician]: resources.healthStatePreset.physician,
});
