import moment from 'moment';

const DATE_FORMAT = 'L';

const getDate = (params) => {
  const { value } = params;
  return value && moment(value).format(DATE_FORMAT);
};

const getFullName = (params) => {
  const { value } = params;
  const { extension } = params.row.original;
  return `${value}.${extension}`;
};

const getAttachedBy = (params) => {
  const { fullName } = params.row.createdByAccount || {};
  return fullName || '';
};

export const columns = [
  {
    accessor: 'createdAt',
    Header: 'Date Added',
    valueGetter: getDate,
    width: 15,
  },
  {
    accessor: 'name',
    Header: 'File Name',
    valueGetter: getFullName,
    width: 30,
  },
  {
    accessor: 'type',
    Header: 'File Type',
    width: 15,
  },
  {
    accessor: 'createdByAccount.fullName',
    Header: 'Attached By',
    valueGetter: getAttachedBy,
    width: 17,
  },
  { accessor: 'fileSizeBytes', Header: 'File Size', width: 15 },
];
