export default class UID {
  constructor(name) {
    this.id = `O-${name || ''}-${Date.now()}`;
  }

  get href() {
    return `#${this.id}`;
  }

  toString() {
    return `url(${this.href})`;
  }
}
