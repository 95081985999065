import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';

import resources from 'common/constants/resources';
import usePrevious from 'common/hooks/usePrevious';
import dataProvider from 'store/dataProvider';

const useGetEventLastActivity = (event = {}) => {
  const [loading, setLoading] = useState(false);
  const [activity, setActivity] = useState(null);

  const eventId = useMemo(() => event?.id, [event?.id]);
  const eventStatus = useMemo(() => event?.status, [event?.status]);
  const prevEventStatus = usePrevious(eventStatus);

  const activityLabel = useMemo(() => {
    if (!activity) {
      return undefined;
    }

    const {
      action,
      accountId,
      accountFullName,
      accountLastName,
      accountFirstName,
    } = activity;
    const isEdited = action === eventStatus;

    const userName =
      accountFullName || `${accountFirstName} ${accountLastName}`;

    const activityString = accountId && `Processed by: ${userName}`;

    return isEdited ? activityString : null;
  }, [activity, eventStatus]);

  const getEventActivity = useCallback(async () => {
    if (!eventId || !eventStatus) {
      setActivity(null);
      setLoading(false);
      return;
    }

    if (eventStatus === prevEventStatus) {
      return;
    }

    try {
      const filter = { entityId: eventId };
      setLoading(true);

      const { data } =
        (await dataProvider.getList(resources.accountAction.activityByEntity, {
          filter,
        })) || {};

      setActivity(data);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }, [eventId, eventStatus, prevEventStatus]);

  useEffect(() => {
    getEventActivity();
  }, [getEventActivity]);

  if (loading && !activityLabel) {
    return (
      <Skeleton
        height="21px"
        variant="rect"
        style={{
          marginTop: 8,
          marginRight: 8,
          marginBottom: 8,
          minWidth: '250px',
        }}
      />
    );
  }

  return activityLabel ? (
    <Typography
      variant="h3"
      color="primary"
      style={{
        marginTop: 8,
        marginBottom: 8,
        paddingRight: 8,
        cursor: 'pointer',
        userSelect: 'none',
      }}
    >
      {activityLabel}
    </Typography>
  ) : null;
};

export default useGetEventLastActivity;
