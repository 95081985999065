import React, { lazy, useMemo, Suspense } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { Switch, Route } from 'react-router-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import routes from 'common/constants/path';
import PrivateRoute from 'common/components/routes/PrivateRoute';
import useAuthProvider from 'store/authProvider/useAuthProvider';
import PublicRoute from 'common/components/routes/PublicRoute';
import FallbackLoader from 'common/components/FallbackLoader';
import Notification from 'common/modules/Notification';
import ModalHandler from 'ui/layout/ModalHandler';
import MainLayout from 'ui/layout/MainLayout';
import NotFound from 'ui/layout/NotFound';

const AuthLayout = lazy(() =>
  import(/* webpackChunkName: "auth" */ 'ui/layout/AuthLayout')
);

const Router = () => {
  const authProvider = useAuthProvider();
  const isLoggedIn = useMemo(
    () => authProvider.isLoggedIn,
    [authProvider.isLoggedIn]
  );

  if (typeof isLoggedIn !== 'boolean') {
    return 'loading';
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Notification />

      <ModalHandler />

      <Switch>
        <PublicRoute
          exact
          path={[
            routes.public.login,
            routes.public.confirmationCode,
            routes.public.forgotPassword,
            routes.public.resetPassword,
          ]}
          render={() => (
            <Suspense fallback={<FallbackLoader />}>
              <AuthLayout />
            </Suspense>
          )}
        />

        <PrivateRoute path={routes.private.index} component={MainLayout} />

        <Route path="*" to={routes.public.notFound} component={NotFound} />
      </Switch>
    </MuiPickersUtilsProvider>
  );
};

export default Router;
