// moment formats
export const monthFormat = 'MM/DD';
export const timeFormat = 'hh:mm:ssA';
export const dateFormat = 'MM/DD/YYYY';
export const dateFormatWithHyphen = 'MM-DD-YYYY';
export const ecgTimeFormat = 'hh:mm:ss a';
export const dateTimeFormat = 'MM/DD/YYYY hh:mm:ssA';
export const timeInHeaderFormat = 'hh:mmA z';
export const dateInHeaderFormat = 'ddd, DD/MM/YYYY';
export const datePhysicianHeaderFormat = 'dddd, DD/MM/YYYY';
export const dateTimeNonSec = 'MM/DD/YYYY hh:mm A';
export const escalatedPDFDateFormat = 'hh:mm A MM/DD/YYYY';

// date picker formats
export const datePickerFormat = 'MM/dd/yyyy';
export const timePickerFormat = 'hh:mm:ss a';
