import unionBy from 'lodash/unionBy';
import { createRequestActionTypes } from 'common/utils/actionTypesHelper';
import { requestReducer } from 'common/utils/generators';

export const kitsActionTypes = createRequestActionTypes(
  [
    'getList',
    'assign',
    'getOne',
    'update',
    'updateMany',
    'updateStatus',
    'updateKitsTenantId',
    'create',
  ],
  'kits'
);

const initialState = {
  loading: false,
  data: [],
  error: false,
};

export default requestReducer(
  [kitsActionTypes.getList, kitsActionTypes.getOne, kitsActionTypes.update],
  initialState,
  (payload, state) => {
    const { data, total } = payload;
    return {
      ...state,
      data: Array.isArray(data) ? data : unionBy([data], state.data, 'id'),
      total,
    };
  }
);

export const getKitsList = (payload) => ({
  type: kitsActionTypes.getList.requested,
  payload,
});

export const assignKit = (
  { assigneId, assigneType, kitId },
  onSuccess,
  onError
) => ({
  type: kitsActionTypes.assign.requested,
  payload: {
    assigneId,
    kitId,
    assigneType,
    onSuccess,
    onError,
  },
});

export const getKit = (id) => ({
  type: kitsActionTypes.getOne.requested,
  payload: { id },
});

export const updateKit = (payload, onSuccess, onError) => ({
  type: kitsActionTypes.update.requested,
  payload,
  onSuccess,
  onError,
});

export const createKit = (payload, onSuccess, onError) => ({
  type: kitsActionTypes.create.requested,
  payload,
  onSuccess,
  onError,
});

export const updateKitsStatus = (payload, onSuccess, onError) => ({
  type: kitsActionTypes.updateStatus.requested,
  payload,
  onSuccess,
  onError,
});

export const updateKitsTenantId = (payload, onSuccess, onError) => ({
  type: kitsActionTypes.updateKitsTenantId.requested,
  payload,
  onSuccess,
  onError,
});
