import moment from 'moment';
import { createSelector } from 'reselect';

import { getProcedureTotalDays } from 'common/utils/helpers/date';
import { getProcedure as getProcedureEntity } from 'common/utils/entitySelectors';

const getReportTable = (store) => store.reportTable;

export const getTotal = createSelector(
  getReportTable,
  (tableState) => tableState.total || 0
);

export const getLoading = createSelector(
  getReportTable,
  (tableState) => tableState.loading
);

export const getReportTableData = (procedureId, tableProcedure) =>
  createSelector(
    getReportTable,
    getProcedureEntity(procedureId),
    (tableState, entityProcedure) => {
      const procedure = tableProcedure || entityProcedure;
      const { fullName } = procedure?.patient || {};
      const patientName =
        fullName && fullName.includes(',')
          ? fullName.split(',').reverse().join(' ').trim()
          : fullName;
      const reports = tableState?.data || [];

      return reports.map(({ reviewedBy, ...report }) => {
        const reportDate = moment(report.date).endOf('day').toISOString();
        const day = procedure
          ? getProcedureTotalDays(procedure.startDate, reportDate)
          : 0;

        const title = `Day ${Math.max(day, 1)} ${report.type} ${report.title}`;

        return {
          ...report,
          reviewedBy,
          name: report.name || title,
          title: report.title === patientName ? title : report.title,
          reviewed: Boolean(reviewedBy),
        };
      });
    }
  );
