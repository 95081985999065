import React, { useCallback, useEffect, useMemo } from 'react';
import moment from 'moment';
import clsx from 'classnames';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';

import { useVisibility } from 'common/hooks';
import { ecgTypeLabels } from 'common/constants/ecgEnums';
import { getGraphDuration } from 'common/utils/helpers/graphs';
import SimpleGraph from 'common/components/Widgets/SimpleGraph';
import { getCenterBetweenDatesUTC } from 'common/utils/helpers/date';
import { useTemplateStyles } from './styles';

const TemplateHeader = ({
  abnormalityType = '',
  sequenceNumber = 0,
  selected = false,
  similar = [],
}) => {
  const classes = useTemplateStyles();
  const label = abnormalityType && ecgTypeLabels[abnormalityType];

  return (
    <Grid
      container
      wrap="nowrap"
      alignItems="center"
      justifyContent="space-around"
      className={clsx(classes.container, {
        [classes.containerSelected]: selected,
      })}
    >
      <Typography variant="body2" className={classes.title}>
        {`${label.replace('-TEMPLATES', '')} Temp ${sequenceNumber}`}
      </Typography>

      <Typography className={classes.subInfo} variant="body2">
        {similar.length}
      </Typography>
    </Grid>
  );
};

const TemplateItem = ({
  width = 0,
  height = 0,
  onClick = () => null,
  fetchEventEcg,
  getEventEcg,
  selected = false,
  graphProps = {},
  setTemplate = () => null,
  currentEvent = {},
}) => {
  const dispatch = useDispatch();
  const [visible, container] = useVisibility();
  const { similar = [], abnormalityType } = currentEvent;

  const event = useMemo(() => similar[similar.length - 1], [similar]);

  const rotateEcg = useMemo(() => event?.rotateEcg, [event]);

  const eventId = useMemo(() => event?.id, [event?.id]);
  const procedureId = useMemo(() => event?.procedureId, [event?.procedureId]);

  const duration = useMemo(
    () => getGraphDuration(abnormalityType),
    [abnormalityType]
  );

  const ecg = useSelector(getEventEcg(event?.id));

  const eventData = useMemo(
    () => ({ ...currentEvent, rotateEcg, ecg }),
    [currentEvent, rotateEcg, ecg]
  );

  const eventDate = useMemo(() => {
    const start = event?.dateFrom;
    const end = event?.dateTo;

    if (start && end) {
      return moment(getCenterBetweenDatesUTC(start, end));
    }

    return null;
  }, [event?.dateTo, event?.dateFrom]);

  const fetchEcg = useCallback(() => {
    if (!eventDate) {
      return null;
    }

    const filter = {
      procedureId,
      precision: 25,
      dateFrom: eventDate
        .clone()
        .subtract(duration / 2, 'seconds')
        .toISOString(),
      dateTo: eventDate
        .clone()
        .add(duration / 2, 'seconds')
        .toISOString(),
    };

    return dispatch(fetchEventEcg({ filter }, eventId));
  }, [procedureId, eventId, duration, dispatch, eventDate, fetchEventEcg]);

  useEffect(() => {
    let action = null;

    if (visible && !ecg) {
      action = fetchEcg();
    }

    return () => action?.abort && action.abort();
  }, [ecg, fetchEcg, visible]);

  const goToTemplateEvents = () => {
    setTemplate(currentEvent.id);
  };

  return (
    <Grid
      container
      wrap="nowrap"
      direction="column"
      style={{ height: '100%' }}
      ref={container}
    >
      {!ecg?.length ? (
        <Skeleton
          variant="rect"
          animation="wave"
          style={{
            minWidth: width || '100%',
            minHeight: height ? height + 18.5 : '100%',
          }}
        />
      ) : (
        <>
          <TemplateHeader {...currentEvent} selected={selected} />
          <SimpleGraph
            noDescription
            withoutGreed
            width={width}
            height={height}
            onClick={onClick}
            selected={selected}
            eventData={eventData}
            graphProps={graphProps}
            onDoubleClick={goToTemplateEvents}
          />
        </>
      )}
    </Grid>
  );
};

export default TemplateItem;
