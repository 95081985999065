import { all, call, put, takeEvery, select } from 'redux-saga/effects';
import uniqBy from 'lodash/uniqBy';
import uniq from 'lodash/uniq';

import dataProvider from 'store/dataProvider';
import resources from 'common/constants/resources';
import { requestSaga } from 'common/utils/generators';
import { ecgEventStatus } from 'common/constants/enums';
import { commonActionTypes } from 'common/ducks/actionTypes';
import { mergePEWithEvents } from 'common/utils/helpers/report';
import { getManuallyAccepted, setManuallyAccepted } from 'common/utils/helpers';

import { actionTypes } from '../reducer';

export function* getOngoingReport() {
  yield requestSaga({
    request: dataProvider.get,
    actionType: actionTypes.get,
    resource: resources.report.ongoingDocument,
  });
}

export function* fetchAdditionalOngoingReportData() {
  yield requestSaga({
    request: dataProvider.get,
    actionType: actionTypes.additionalFetch,
    resource: resources.report.ongoingDocument,
  });
}

export function* getOngoingEventEcg() {
  yield requestSaga({
    request: dataProvider.getList,
    actionType: actionTypes.eventsEcg,
    resource: resources.ecgData.byPeriod,
  });
}

export function* getPEMeasures() {
  yield requestSaga({
    request: dataProvider.getList,
    actionType: actionTypes.getPEMeasures,
    resource: resources.sensorData.index,
  });
}

export function* setEventSignificant() {
  yield requestSaga({
    request: dataProvider.createOne,
    actionType: actionTypes.setSignificant,
    resource: resources.eventGroup.setSignificant,
  });
}

function* patientEventsSaga({ payload }) {
  try {
    const { data: allPEEvents } = yield call(
      dataProvider.getList,
      resources.healthData.patientEvents,
      payload
    );

    const peEventAttachedEventId = allPEEvents
      .map((e) => e.ecgEventGroupIds)
      .flat();

    const { data: events } = yield call(
      dataProvider.getList,
      resources.eventGroup.byPeriod,
      {
        filter: {
          ecgLength: 1,
          stepPercentage: 1,
          procedureId: payload.filter.procedureId,
          eventGroupIds: uniq(peEventAttachedEventId),
          status: [ecgEventStatus.accepted, ecgEventStatus.included],
        },
      }
    );

    const pe = mergePEWithEvents(allPEEvents, events);

    yield put({
      type: actionTypes.patientEvents.success,
      payload: { data: pe },
    });
  } catch ({ message }) {
    yield put({ type: actionTypes.patientEvents.failed, message });
  }
}

export function* getPatientEvents() {
  yield takeEvery(actionTypes.patientEvents.requested, patientEventsSaga);
}

const getEcgEvents = ({ status, ...filter }) =>
  function* getData(abnormality) {
    const data = yield call(
      dataProvider.getList,
      resources.eventGroup.byPeriod,
      {
        pagination: { page: 1, perPage: 6 },
        filter: {
          ...filter,
          status,
          abnormalityTypes: [abnormality],
          sortingOptions: 'Newest',
        },
      }
    );

    return {
      ...(data || {}),
      abnormalityType: abnormality,
    };
  };

function* getEcgTotals(filter, status) {
  const { data } = yield call(
    dataProvider.getList,
    resources.eventGroup.count,
    {
      filter: { ...filter, status },
    }
  );

  return data.reduce((acc, val) => ({ ...acc, [val.type]: val.count }), {});
}

function* getEventTotalsByStatus(filter) {
  const included = yield call(getEcgTotals, filter, [ecgEventStatus.included]);
  const accepted = yield call(getEcgTotals, filter, [ecgEventStatus.accepted]);

  return filter.abnormalityTypes.reduce((acc, abType) => {
    const includedTotal = included[abType] || 0;
    const acceptedTotal = accepted[abType] || 0;

    return {
      ...acc,
      [abType]: {
        included: includedTotal,
        accepted: acceptedTotal,
        total: includedTotal + acceptedTotal,
      },
    };
  }, {});
}

function* eventGroupSaga({ payload }) {
  const { filter } = payload;

  const eventRequest = getEcgEvents(filter);

  try {
    const data = yield all(filter.abnormalityTypes.map(eventRequest));
    const events = data.map((eventsData) => eventsData.data).flat();
    const totals = yield call(getEventTotalsByStatus, filter);

    yield put({
      type: actionTypes.getEventGroups.success,
      payload: { data: events, totals },
    });
  } catch ({ message }) {
    yield put({ type: actionTypes.getEventGroups.failed, message });
  }
}

export function* getEventGroups() {
  yield takeEvery(actionTypes.getEventGroups.requested, eventGroupSaga);
}

function* eventGroupTotalsSaga({ payload }) {
  try {
    const totals = yield call(getEventTotalsByStatus, payload.filter);

    yield put({
      type: actionTypes.getEventGroupTotals.success,
      payload: { data: totals },
    });
  } catch ({ message }) {
    yield put({ type: actionTypes.getEventGroupTotals.failed, message });
  }
}

export function* getEventGroupTotals() {
  yield takeEvery(
    actionTypes.getEventGroupTotals.requested,
    eventGroupTotalsSaga
  );
}

function* handleWatchUpdateEcgEvents({ payload }) {
  const store = yield select();
  const autoIncludedEvents = Object.values(
    store.onGoingReport.autoIncludeEventData || {}
  ).filter(Boolean);

  const acceptedEventsToUpdate = (payload.body || []).filter(
    (e) => e.status === ecgEventStatus.accepted
  );
  const includedEventsToUpdate = (payload.body || []).filter(
    (e) => e.status === ecgEventStatus.included
  );

  const manuallyAcceptedFromAutoIncluded = acceptedEventsToUpdate.filter((e) =>
    autoIncludedEvents.some((ae) => e.id === ae.id)
  );

  const manuallyIncludedFromAutoIncluded = includedEventsToUpdate.filter((e) =>
    autoIncludedEvents.some((ae) => e.id === ae.id)
  );

  const localManuallyAcceptedFromAutoIncluded = getManuallyAccepted().filter(
    (s) => !manuallyIncludedFromAutoIncluded.some((e) => e.id === s.id)
  );
  const updatedManuallyAccepted = uniqBy(
    [
      manuallyAcceptedFromAutoIncluded,
      localManuallyAcceptedFromAutoIncluded,
    ].flat(),
    'id'
  );

  setManuallyAccepted(updatedManuallyAccepted);
}

export function* watchUpdateEcgEvents() {
  yield takeEvery(
    commonActionTypes.updateEcgEvents.requested,
    handleWatchUpdateEcgEvents
  );
}
