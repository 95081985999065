import { useContext } from 'react';

import { AuthContext } from './AuthProvider';

const useAuthProvider = (...args) => {
  const authContext = useContext(AuthContext);

  if (args?.length && args?.length === 1) {
    const [authProp] = args;
    return authContext[authProp];
  }

  if (args?.length) {
    return args.reduce(
      (acc, authProp) => ({ ...acc, [authProp]: authContext[authProp] }),
      {}
    );
  }

  return authContext;
};

export default useAuthProvider;
