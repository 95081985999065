import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

import {
  kitSelector,
  patientSelector,
  procedureSelector,
  physicianSelector,
} from 'common/utils/entitySelectors';
import routes from 'common/constants/path';
import { formatPath } from 'common/utils/helpers/url';
import useAuthProvider from 'store/authProvider/useAuthProvider';

import { PatientInfo, ProcedureInfo, ShortInfo } from './components';
import FormatData from './formatData';
import useStyles from './styles';

const UserCard = ({ procedureId, shortInfo = false }) => {
  const classes = useStyles();
  const { isEcgTech } = useAuthProvider();

  const procedureEntity = useSelector(procedureSelector(procedureId));
  const kitEntity = useSelector(kitSelector(procedureEntity?.kit)) || {};
  const patientEntity =
    useSelector(patientSelector(procedureEntity?.patient)) || {};
  const physicianEntity =
    useSelector(physicianSelector(procedureEntity?.physician)) || {};

  if (!procedureEntity) {
    return (
      <Grid container justifyContent="center" alignItems="center">
        <CircularProgress />
      </Grid>
    );
  }

  const procedure = new FormatData(
    procedureEntity,
    patientEntity,
    physicianEntity,
    kitEntity
  );

  const path = formatPath(routes.private.procedures.monitoring.index, {
    id: procedureId,
  });

  return (
    <Grid
      container
      to={path}
      component={!isEcgTech ? Link : 'div'}
      direction="column"
      className={classes.link}
    >
      {shortInfo ? (
        <Grid item>
          <ShortInfo
            procedureInfo={procedure.procedureInfo}
            userInfo={procedure.userInfo}
            kitInfo={procedure.kitInfo}
          />
        </Grid>
      ) : (
        <>
          <Grid item>
            <PatientInfo
              procedureInfo={procedure.procedureInfo}
              kitInfo={procedure.kitInfo}
              userInfo={procedure.userInfo}
            />
          </Grid>

          <Grid item>
            <ProcedureInfo
              procedureInfo={procedure.procedureInfo}
              physicianInfo={procedure.physicianInfo}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

UserCard.propTypes = {
  shortInfo: PropTypes.bool,
  procedureId: PropTypes.string,
};

export default UserCard;
