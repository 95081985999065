import React from 'react';
import { Provider } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ConnectedRouter } from 'connected-react-router';
import { ThemeProvider } from '@material-ui/core/styles';
import 'react-day-picker/lib/style.css';

import createStore from './createStore';
import { history } from './store/rootReducer';
import AuthProvider from './store/authProvider';
import theme from './theme';

import Router from './ui/router';

const store = createStore();

const App = () => (
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ConnectedRouter history={history}>
        <AuthProvider>
          <Router />
        </AuthProvider>
      </ConnectedRouter>
    </ThemeProvider>
  </Provider>
);

export default App;
