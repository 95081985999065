import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const getNumberOfDay = (day = 0) => Math.max(day, 0);

const ProcedureInfo = ({ procedureInfo, physicianInfo }) => (
  <Grid container direction="column">
    <Grid item>
      <Typography variant="h6">
        {`Start: ${procedureInfo.startDate}`}
      </Typography>
    </Grid>
    <Grid item container spacing={1}>
      <Grid item>
        <Typography variant="h6">{`End: ${procedureInfo.endDate}`}</Typography>
      </Grid>
      <Grid item>
        <Typography variant="h6">
          {`${getNumberOfDay(procedureInfo.countOfDays)} Days`}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="h6">
          {`${getNumberOfDay(procedureInfo.currentDay)} of ${getNumberOfDay(
            procedureInfo.countOfDays
          )}`}
        </Typography>
      </Grid>
    </Grid>
    <Grid item>
      <Typography variant="h6">
        {physicianInfo.fullName || 'Unknown'}
      </Typography>
    </Grid>
  </Grid>
);

ProcedureInfo.propTypes = {
  procedureInfo: PropTypes.objectOf(PropTypes.any),
  physicianInfo: PropTypes.shape({
    fullName: PropTypes.string,
  }),
};

export default ProcedureInfo;
