import { call, put, takeLatest } from 'redux-saga/effects';
import resources from 'common/constants/resources';
import dataProvider from 'store/dataProvider';
import { kitsActionTypes } from '../reducer';

function* assignKit({ payload }) {
  const { assigneId, kitId, assigneType, onSuccess, onError } = payload;
  try {
    yield call(
      dataProvider.updateOnly,
      `${resources.kit.assignees}?id=${kitId}`,
      {
        id: assigneId,
        type: assigneType,
      }
    );

    yield put({
      type: kitsActionTypes.assign.success,
    });
    onSuccess();
  } catch (error) {
    yield put({
      type: kitsActionTypes.assign.failed,
      error,
    });
    onError();
  }
}

export default function* () {
  yield takeLatest(kitsActionTypes.assign.requested, assignKit);
}
