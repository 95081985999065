import Delete from '@material-ui/icons/Delete';
import { procedureStatusTypes } from 'common/constants/enums';

export const deleteButton = {
  text: 'DELETE',
  icon: Delete,
  color: 'error',
  variant: 'text',
};

export const orderButton = {
  text: 'ORDER',
  variant: 'outlined',
};

export const monitorButton = {
  text: 'MONITOR',
  variant: 'outlined',
};

export const completeButton = {
  text: 'COMPLETE',
  variant: 'outlined',
};

export const createBulkButtons = (onOrder, onMonitor, onComplete, onDelete) => {
  orderButton.onClick = onOrder;
  monitorButton.onClick = onMonitor;
  completeButton.onClick = onComplete;
  deleteButton.onClick = onDelete;

  return {
    [procedureStatusTypes.ordered]: [
      onMonitor && monitorButton,
      onComplete && completeButton,
      onDelete && deleteButton,
    ],
    [procedureStatusTypes.monitoring]: [
      onOrder && orderButton,
      onComplete && completeButton,
      onDelete && deleteButton,
    ],
    [procedureStatusTypes.completed]: [
      onOrder && orderButton,
      onMonitor && monitorButton,
      onDelete && deleteButton,
    ],
    [procedureStatusTypes.deleted]: [
      onOrder && orderButton,
      onMonitor && monitorButton,
      onComplete && completeButton,
    ],
  };
};
