import {
  updateNestedEventData,
  mergePEWithAttachedEvents,
} from 'common/utils/helpers/report';
import { escalatedEventParts, hrGroupTypes } from 'common/constants/enums';
import { createRequestActionTypes } from 'common/utils/actionTypesHelper';

export const actionTypes = createRequestActionTypes(
  [
    'get',
    'create',
    'addEvent',
    'clearState',
    'addPatientEvent',
    'addNotes',
    'addPENotes',
    'removeEvent',
    'removePatientEvent',
    'eventEcg',
    'heartRate',
    'open',
    'close',
  ],
  'escalatedReport'
);

const initialState = {
  loading: false,
  ecgEventLoading: {},
  reports: {},
  ecg: {},
  heartRate: {},
  error: null,
  isEscalatedModal: false,
};

export default (state = initialState, action) => {
  const { type, payload, message, requestPayload } = action;

  switch (type) {
    // requested handler
    case actionTypes.get.requested:
    case actionTypes.create.requested:
    case actionTypes.addEvent.requested:
    case actionTypes.addPatientEvent.requested:
    case actionTypes.removeEvent.requested:
    case actionTypes.removePatientEvent.requested:
      return { ...state, loading: true, error: null };

    case actionTypes.get.success: {
      const { procedureId } = requestPayload;
      const patientEvents = mergePEWithAttachedEvents(
        [],
        payload.data.patientEvents
      );

      return {
        ...state,
        loading: false,
        reports: {
          ...state.reports,
          [procedureId]: {
            ...payload.data,
            patientEvents,
          },
        },
      };
    }

    case actionTypes.create.success: {
      const { procedureId } = requestPayload;
      return {
        ...state,
        loading: false,
        reports: {
          ...state.reports,
          [procedureId]: payload.data,
        },
      };
    }

    case actionTypes.addEvent.success: {
      const { data } = payload;
      const reportEvents = state.reports[data.procedureId]?.ecgEventGroups;

      return {
        ...state,
        loading: false,
        reports: {
          ...state.reports,
          [data.procedureId]: {
            ...state.reports[data.procedureId],
            ecgEventGroups: reportEvents.concat(data),
          },
        },
      };
    }
    case actionTypes.addPatientEvent.success: {
      const { procedureId } = requestPayload;
      const patientEvents = state.reports[procedureId]?.patientEvents;
      const mappedPatientEvent = mergePEWithAttachedEvents(
        patientEvents,
        payload.data
      );

      return {
        ...state,
        loading: false,
        reports: {
          ...state.reports,
          [procedureId]: {
            ...state.reports[procedureId],
            patientEvents: patientEvents.concat(mappedPatientEvent),
          },
        },
      };
    }

    case actionTypes.addNotes.success: {
      const {
        body,
        procedureId,
        params: { eventGroupId },
      } = requestPayload;

      const events = state.reports[procedureId].ecgEventGroups.map((ev) => {
        if (ev.id !== eventGroupId) {
          return ev;
        }

        return { ...ev, ...body };
      });

      return {
        ...state,
        reports: {
          ...state.reports,
          [procedureId]: {
            ...state.reports[procedureId],
            ecgEventGroups: events,
          },
        },
      };
    }

    case actionTypes.removeEvent.success: {
      const { procedureId, params } = requestPayload;
      const { eventGroupId } = params;
      const reportEvents = state.reports[procedureId].ecgEventGroups;

      return {
        ...state,
        loading: false,
        reports: {
          ...state.reports,
          [procedureId]: {
            ...state.reports[procedureId],
            ecgEventGroups: reportEvents.filter((ev) => ev.id !== eventGroupId),
          },
        },
      };
    }
    case actionTypes.removePatientEvent.success: {
      const { procedureId, params } = requestPayload;
      const { eventGroupId } = params;

      const { patientEvents } = state.reports[procedureId];

      return {
        ...state,
        loading: false,
        reports: {
          ...state.reports,
          [procedureId]: {
            ...state.reports[procedureId],
            patientEvents: patientEvents.filter((pe) => pe.id !== eventGroupId),
          },
        },
      };
    }

    case actionTypes.eventEcg.requested: {
      const ecgEventLoading = updateNestedEventData(
        state.ecgEventLoading,
        payload,
        true
      );

      return {
        ...state,
        ecgEventLoading,
      };
    }
    case actionTypes.eventEcg.success: {
      const ecgEventLoading = updateNestedEventData(
        state.ecgEventLoading,
        requestPayload,
        false
      );

      const ecg = updateNestedEventData(
        state.ecg,
        requestPayload,
        payload.data
      );

      return {
        ...state,
        ecgEventLoading,
        ecg,
      };
    }
    case actionTypes.eventEcg.failed:
      return {
        ...state,
        ecgEventLoading: updateNestedEventData(
          state.ecgEventLoading,
          requestPayload,
          false
        ),
      };

    case actionTypes.heartRate.success: {
      return {
        ...state,
        heartRate: updateNestedEventData(
          state.heartRate,
          requestPayload,
          payload.data.average
        ),
      };
    }

    // failed handler
    case actionTypes.get.failed:
    case actionTypes.create.failed:
    case actionTypes.addEvent.failed:
    case actionTypes.addPatientEvent.failed:
    case actionTypes.removeEvent.failed:
    case actionTypes.removePatientEvent.failed:
      return { ...state, loading: false, error: message };

    case actionTypes.open.requested:
      return { ...state, isEscalatedModal: true };
    case actionTypes.close.requested:
      return { ...state, isEscalatedModal: false };

    case actionTypes.clearState.requested:
      return initialState;

    default:
      return state;
  }
};

export const getReport = (procedureId) => ({
  type: actionTypes.get.requested,
  payload: { params: { procedureId } },
  procedureId,
});

export const clearReportState = (payload = {}) => ({
  type: actionTypes.clearState.requested,
  payload,
});

export const createReport = (procedureId, date, onSuccess) => ({
  type: actionTypes.create.requested,
  payload: { params: { procedureId }, query: { date } },
  procedureId,
  onSuccess,
});

export const addEventToReport = (
  reportId,
  eventId,
  procedureId,
  onSuccess,
  onError
) => ({
  type: actionTypes.addEvent.requested,
  payload: { params: { reportId }, body: eventId },
  procedureId,
  onSuccess,
  onError,
});

export const addPatientEventToReport = (
  reportId,
  patientEventId,
  procedureId,
  onSuccess,
  onError
) => ({
  type: actionTypes.addPatientEvent.requested,
  payload: { params: { reportId }, body: patientEventId },
  procedureId,
  onSuccess,
  onError,
});

export const addNotes = (params, body, procedureId) => ({
  type: actionTypes.addNotes.requested,
  payload: { params, body },
  procedureId,
});

export const addPENotes = (params, body, procedureId) => ({
  type: actionTypes.addPENotes.requested,
  payload: { params, body },
  procedureId,
});

export const removeEventFromReport = (
  payload,
  procedureId,
  onSuccess,
  onError
) => ({
  type: actionTypes.removeEvent.requested,
  payload,
  procedureId,
  onSuccess,
  onError,
});

export const removePatientEventFromReport = (
  payload,
  procedureId,
  onSuccess,
  onError
) => ({
  type: actionTypes.removePatientEvent.requested,
  payload,
  procedureId,
  onSuccess,
  onError,
});

export const fetchEventEcg = (
  filter,
  eventId,
  part = escalatedEventParts.event
) => ({
  type: actionTypes.eventEcg.requested,
  payload: { filter },
  eventId,
  part,
});

export const fetchPartHeartRate = (
  filter,
  eventId,
  part = escalatedEventParts.event
) => ({
  type: actionTypes.heartRate.requested,
  payload: { filter: { checkBeatsCount: true, groupType: hrGroupTypes.none } },
  eventId,
  part,
});

export const openEscalatedReportModal = () => ({
  type: actionTypes.open.requested,
});

export const closeEscalatedReportModal = () => ({
  type: actionTypes.close.requested,
});
