import { requestSaga } from 'common/utils/generators';
import resources from 'common/constants/resources';
import dataProvider from 'store/dataProvider';
import { actionTypes } from '../reducer';

function* fetchUsers() {
  yield requestSaga({
    actionType: actionTypes.getList,
    request: dataProvider.getList,
    resource: resources.user.list,
  });
}

export default fetchUsers;
