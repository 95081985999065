import dataProvider from 'store/dataProvider';
import resources from 'common/constants/resources';
import { requestSaga } from 'common/utils/generators';

import { actionTypes } from '../reducer';

export function* setStatus() {
  yield requestSaga({
    request: dataProvider.createOne,
    actionType: actionTypes.status,
    resource: resources.eventGroup.review,
  });
}

export function* setSignificantEvent() {
  yield requestSaga({
    request: dataProvider.createOne,
    actionType: actionTypes.significant,
    resource: resources.eventGroup.setSignificant,
  });
}
