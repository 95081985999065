import { createRequestActionTypes } from 'common/utils/actionTypesHelper';
import { requestReducer } from 'common/utils/generators';

export const rolesActionTypes = createRequestActionTypes(['getList'], 'roles');

const initialState = {
  loading: false,
  data: [],
  total: 0,
  error: false,
};

export default requestReducer(
  [rolesActionTypes.getList],
  initialState,
  (payload, state) => {
    const { data, total } = payload;
    return {
      ...state,
      loading: false,
      data,
      total,
    };
  }
);

export const getRoles = () => ({
  type: rolesActionTypes.getList.requested,
  payload: {},
});
