import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';

import useStyles from './styles';

const LoadingProgress = () => {
  const styles = useStyles();

  return (
    <LinearProgress
      classes={{
        indeterminate: styles.loader,
        bar1Indeterminate: styles.loaderSection,
        bar2Indeterminate: styles.loaderSection,
      }}
      data-testid="linear-progress"
    />
  );
};

export default LoadingProgress;
