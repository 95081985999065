import React from 'react';
import clsx from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import grey from '@material-ui/core/colors/grey';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';

import { useProcedureLocked } from 'common/hooks';
import useGetEntitiesCount from 'common/hooks/useGetEntitiesCount';

const useStyles = makeStyles(({ palette, spacing }) => ({
  link: {
    width: '100%',
    textDecoration: 'none',
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  },
  linkSelected: {
    color: palette.text.light,
    backgroundColor: palette.background.mainDark,
  },

  content: {
    height: 35,
    alignItems: 'center',
    padding: spacing(0.5, 3),
    paddingRight: spacing(2),
    color: palette.text.primary,
  },
  contentHover: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: grey['200'],
      color: palette.text.primary,
    },
  },
  contentSelected: {
    color: palette.text.light,
    backgroundColor: palette.background.mainDark,
  },
}));

export const VCMenuLinkItem = ({
  beforeIcon: Icon,
  text,
  classes,
  isSelected,
  counterFetchParams,
}) => {
  const contentClasses = clsx(classes.content, {
    [classes.contentHover]: !isSelected,
    [classes.contentSelected]: isSelected,
  });

  const { countSting: count } = useGetEntitiesCount(counterFetchParams);

  return (
    <Grid container alignItems="center" className={contentClasses}>
      {Icon ? <Icon fontSize="small" /> : null}

      <Grid container alignItems="center" justifyContent="space-between">
        <Typography>{text}</Typography>

        <Typography data-testid="item-count">{count}</Typography>
      </Grid>
    </Grid>
  );
};

const VCMenuLink = ({ to = '', modal, onOpen, lockWithPatient, ...props }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const locked = useProcedureLocked();
  const disableLink = lockWithPatient && locked;

  if (modal) {
    const openModal = () => {
      if (disableLink) {
        return;
      }

      dispatch(onOpen());
    };

    return (
      <div role="presentation" onClick={openModal} className={classes.link}>
        <VCMenuLinkItem {...props} classes={classes} />
      </div>
    );
  }

  return (
    <Link
      to={!disableLink && to}
      className={classes.link}
      width="100%"
      data-testid="menu-link-item"
    >
      <VCMenuLinkItem {...props} classes={classes} disabled={disableLink} />
    </Link>
  );
};

VCMenuLink.propTypes = {
  to: PropTypes.string,
  onOpen: PropTypes.func,
  lockWithPatient: PropTypes.bool,
  modal: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

VCMenuLinkItem.propTypes = {
  counterFetchParams: PropTypes.arrayOf(PropTypes.shape()),
  beforeIcon: PropTypes.shape(),
  classes: PropTypes.shape(),
  isSelected: PropTypes.bool,
  text: PropTypes.string,
};

export default VCMenuLink;
