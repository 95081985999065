import { requestSaga } from 'common/utils/generators';
import resources from 'common/constants/resources';
import dataProvider from 'store/dataProvider';
import { kitsActionTypes } from '../reducer';

function* getKitsList() {
  yield requestSaga({
    actionType: kitsActionTypes.getList,
    request: dataProvider.getList,
    resource: resources.kit.index,
  });
}

export default getKitsList;
