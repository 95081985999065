import React, { useCallback } from 'react';
import { lineGenerator } from './utils';

const EventSegmentsLine = ({
  data = {},
  parent,
  color,
  lineProps,
  visibleRange,
}) => {
  const { xScale, yScale, clip = {}, scale, options } = parent;
  const { xPropKey, yPropKey } = options;

  const line = useCallback(
    (...args) =>
      lineGenerator(
        { x: xPropKey, y: yPropKey },
        yScale,
        visibleRange,
        30
      )(...args),
    [yScale, xPropKey, yPropKey, visibleRange]
  );

  return (
    <path
      {...lineProps}
      stroke={color}
      clipPath={clip}
      strokeWidth={10}
      d={line(data, scale || xScale)}
    />
  );
};

export default EventSegmentsLine;
