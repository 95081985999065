import { requestSaga } from 'common/utils/generators';
import dataProvider from 'store/dataProvider';
import resources from 'common/constants/resources';
import { physicianActionTypes } from '../reducer';

function* fetchPhysicianSaga() {
  yield requestSaga({
    actionType: physicianActionTypes.getOne,
    request: dataProvider.getOne,
    resource: resources.physician.item,
  });
}

export default fetchPhysicianSaga;
