import { call, put, takeLatest } from 'redux-saga/effects';

import {
  templateTypes,
  abnormalityTypeEnum,
  abnormalityAccordingTemplates,
} from 'common/constants/ecgEnums';
import dataProvider from 'store/dataProvider';
import resources from 'common/constants/resources';
import { actionTypes } from '../reducer';

const defaultResponse = { data: 0, total: 0 };

function* getEventsSaga(action) {
  const { filter, ...params } = action.payload;
  const isPatient = filter.abnormalityTypes.includes(
    abnormalityTypeEnum.patient
  );
  const abnormalityTypes = filter.abnormalityTypes.filter(
    (abType) =>
      abType !== abnormalityTypeEnum.patient && !templateTypes.includes(abType)
  );

  const templateAbTypes = filter.abnormalityTypes.filter((abType) =>
    templateTypes.includes(abType)
  );

  try {
    const peResponse = isPatient
      ? yield call(dataProvider.getList, resources.healthData.patientEvents, {
          ...params,
          filter: {
            ...filter,
            reviewed: undefined,
            abnormalityTypes: undefined,
          },
        })
      : defaultResponse;

    const templatesResponses = templateAbTypes.length
      ? yield call(dataProvider.getList, resources.eventGroup.templatesCount, {
          ...params,
          filter: {
            ...filter,
            abnormalityTypes: templateAbTypes
              .map((abT) => abnormalityAccordingTemplates[abT])
              .filter(Boolean),
          },
        })
      : defaultResponse;

    const eventResponse = abnormalityTypes.length
      ? yield call(dataProvider.getList, resources.eventGroup.count, {
          ...params,
          filter: { ...filter, abnormalityTypes },
        })
      : defaultResponse;

    const total =
      peResponse.total +
      (eventResponse?.data || []).reduce((acc, val) => acc + val.count, 0) +
      templatesResponses.data;

    yield put({
      type: actionTypes.getTotal.success,
      payload: {
        data: total,
      },
    });
  } catch (error) {
    yield put({ type: actionTypes.getTotal.failed, payload: error });
  }
}

export function* getTotal() {
  yield takeLatest(actionTypes.getTotal.requested, getEventsSaga);
}
