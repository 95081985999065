import React from 'react';
import Grid from '@material-ui/core/Grid';
import Loader from '@material-ui/core/CircularProgress';

const CircularProgress = () => (
  <Grid
    container
    justifyContent="center"
    alignItems="center"
    style={{ height: '100%' }}
  >
    <Loader />
  </Grid>
);

export default CircularProgress;
