import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import TextInput from 'common/components/simpleInputs/TextInput';
import StripSettingsContainer from './StripSettingsContainer';
import { selectStripStyles as useStyles } from '../styles';

const StripSettings = ({
  name = '',
  label = '',
  options = [],
  onChange = () => null,
  defaultValue = '',
  currentValues = {},
  ...props
}) => {
  const classes = useStyles();

  const value = useMemo(() => {
    if (typeof currentValues === 'string') {
      return currentValues;
    }

    return currentValues?.[name] || defaultValue;
  }, [currentValues, defaultValue, name]);

  const handleChange = useCallback(
    (event, newValue) => {
      onChange(name, newValue);
    },
    [onChange, name]
  );

  return (
    <StripSettingsContainer>
      <TextInput
        {...props}
        select
        fullWidth
        name={name}
        size="small"
        label={label}
        value={value}
        options={options}
        variant="standard"
        onChange={handleChange}
        SelectProps={{
          classes: {
            root: classes.selectMenu,
          },
        }}
      />
    </StripSettingsContainer>
  );
};

StripSettings.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  currentValues: PropTypes.shape(),
  options: PropTypes.arrayOf(PropTypes.shape()),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default StripSettings;
