import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/styles/makeStyles';

import useAuthProvider from 'store/authProvider/useAuthProvider';
import MenuItem from './components/MenuItem';

const useStyles = makeStyles((theme) => ({
  menuItemIconContainer: {
    alignItems: 'center',
    marginRight: theme.spacing(),
    color: theme.palette.text.colored,
  },
  menuItemTextContainer: {
    width: '100%',
    textDecoration: 'none',
  },
  vcMenuLinkItem: {
    marginRight: theme.spacing(),
  },
  vcMenuLinkItemText: {
    marginLeft: theme.spacing(),
  },
}));

const AccordionMenu = ({ routes }) => {
  const classes = useStyles();
  const authProvider = useAuthProvider();

  const role = useMemo(
    () => authProvider.currentUserRole,
    [authProvider.currentUserRole]
  );

  return (
    <>
      {routes.map(({ restrictions, ...route }) => {
        if (role && restrictions && !restrictions.includes(role)) {
          return null;
        }

        return (
          <MenuItem
            {...route}
            classes={classes}
            key={route.url || route?.modal}
          />
        );
      })}
    </>
  );
};

AccordionMenu.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.shape()),
};

export default AccordionMenu;
