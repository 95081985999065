import fetchUsers from './fetchUsers';
import createUser from './createUser';
import fetchUser from './fetchUser';
import updateUser from './updateUser';
import updateUserStatus from './updateUserStatus';
import getUserActivityStatistic from './getUserActivityStatistic';

export default [
  fetchUsers,
  createUser,
  fetchUser,
  updateUser,
  updateUserStatus,
  getUserActivityStatistic,
];
