import React from 'react';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';

const PhoneMaskInput = ({ inputRef, ...props }) => (
  <MaskedInput
    guide
    {...props}
    ref={(ref) => {
      inputRef(ref ? ref.inputElement : null);
    }}
    mask={[
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ]}
  />
);

PhoneMaskInput.defaultProps = {
  inputRef: () => null,
};

PhoneMaskInput.propTypes = {
  inputRef: PropTypes.func,
};

export default PhoneMaskInput;
