import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import DialogContent from '@material-ui/core/DialogContent';

import resources from 'common/constants/resources';
import Autocomplete from 'common/components/formFields/Autocomplete';

const SelectExistingContact = ({ setContact }) => {
  const { formState, control, watch } = useForm();

  const contact = watch('contact');

  useEffect(() => {
    if (contact !== undefined) {
      setContact(contact);
    }
  }, [contact, setContact]);

  return (
    <DialogContent>
      <Autocomplete
        label="Choose existing contact"
        size="small"
        disableClearable={false}
        control={control}
        form={formState}
        name="contact"
        optionLabel="fullName"
        optionValueName="id"
        resource={resources.contact.list}
      />
    </DialogContent>
  );
};

export default SelectExistingContact;
