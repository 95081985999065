import { requestSaga } from 'common/utils/generators';
import resources from 'common/constants/resources';
import dataProvider from 'store/dataProvider';
import { patchesActionTypes } from '../reducer';

function* createPhone() {
  yield requestSaga({
    actionType: patchesActionTypes.create,
    request: dataProvider.createOne,
    resource: resources.patch.index,
  });
}

export default createPhone;
