import React, { useMemo } from 'react';
import moment from 'moment';
import clsx from 'classnames';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { getProcedureTotalDays } from 'common/utils/helpers/date';
import { getProcedure } from 'common/utils/entitySelectors';
import { timeFormat } from 'common/constants/dateFormats';
import { ecgTypeLabels } from 'common/constants/ecgEnums';

import { descriptionStyles as useStyles } from './styles';

export const getDayNumber = (eventData) =>
  createSelector(getProcedure(eventData?.procedureId), (procedure) => {
    if (!procedure || !eventData) {
      return 0;
    }

    const { date, dateFromEvent, dateFrom } = eventData;
    const { startDate } = procedure;

    const eventDate = moment(date || dateFromEvent || dateFrom).endOf('day');
    const duration = getProcedureTotalDays(startDate, eventDate);

    return Math.max(0, duration);
  });

const Description = ({ eventData, selected, noSimilar = false }) => {
  const dayNumber = useSelector(getDayNumber(eventData));
  const classes = useStyles();
  const {
    similar,
    date,
    total,
    dateFrom,
    dateFromEvent,
    abnormalityType,
    avgHeartRate,
  } = eventData;
  const formattedTime = moment(date || dateFromEvent || dateFrom).format(
    timeFormat
  );

  const title = useMemo(
    () =>
      [
        abnormalityType && ecgTypeLabels[abnormalityType],
        `D${dayNumber}`,
        formattedTime,
        avgHeartRate && `${avgHeartRate}bpm`,
        (!!(!noSimilar || total) && similar?.length) || total,
      ]
        .filter(Boolean)
        .join(' ')
        .trim(),
    [
      abnormalityType,
      formattedTime,
      avgHeartRate,
      dayNumber,
      noSimilar,
      similar?.length,
      total,
    ]
  );

  return (
    <Grid
      container
      justifyContent="space-around"
      alignItems="center"
      wrap="nowrap"
      title={title}
      className={clsx(classes.container, {
        [classes.containerSelected]: selected,
      })}
    >
      <Typography variant="subtitle2" className={classes.title}>
        {abnormalityType && ecgTypeLabels[abnormalityType]}
      </Typography>

      <Typography className={classes.subInfo} variant="subtitle2">
        D{dayNumber}
      </Typography>

      <Typography className={classes.subInfo} variant="subtitle2">
        {formattedTime}
      </Typography>

      {!!avgHeartRate && (
        <Typography
          className={clsx(classes.subInfo, classes.increasedText)}
          variant="body2"
        >
          {`${avgHeartRate}bpm`}
        </Typography>
      )}

      {!!(!noSimilar || total) && (
        <Typography variant="body2" className={classes.increasedText}>
          {similar?.length || total || null}
        </Typography>
      )}
    </Grid>
  );
};

Description.propTypes = {
  eventData: PropTypes.shape({
    procedureId: PropTypes.string,
    abnormalityType: PropTypes.string,
    id: PropTypes.string,
    total: PropTypes.number,
    similar: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.arrayOf(PropTypes.shape({})),
      PropTypes.number,
    ]),
    date: PropTypes.string,
    dateFrom: PropTypes.string,
    dateFromEvent: PropTypes.string,
    avgHeartRate: PropTypes.number,
  }),
  selected: PropTypes.bool,
  noSimilar: PropTypes.bool,
};

export default Description;
