import { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import dataProvider from 'store/dataProvider';
import { useNotify } from 'common/modules/Notification/hooks';
import { openConfirmationModal } from 'common/ducks/general/actions/modalActions';

import { entityPaths } from './constants';
import { getMessage } from './utils';

const useCheckForActiveProcedures = (
  entityName = '',
  optionToDisplay = 'name'
) => {
  const notify = useNotify();
  const dispatch = useDispatch();
  const entityPath = useMemo(() => entityPaths[entityName], [entityName]);
  const [hasProcedures, setHasProcedures] = useState(false);

  const getEntityWithActiveProcedures = useCallback(
    async (entities) => {
      const ids = entities.map(({ id }) => id).filter(Boolean);

      if (!ids.length) {
        setHasProcedures(false);
        return [];
      }

      const { data } = await dataProvider.getList(entityPath, {
        filter: {
          ids,
          hasActiveProcedures: true,
        },
      });

      setHasProcedures(!!data?.length);

      return data || [];
    },
    [entityPath]
  );

  const getIsActiveProcedures = useCallback(
    async (entities, status, onConfirm) => {
      const requestEntities = Array.isArray(entities) ? entities : [entities];
      const data = await getEntityWithActiveProcedures(requestEntities);
      const entitiesWithActiveProcedures = data.filter(({ id }) =>
        requestEntities.find((e) => e.id === id)
      );

      const isActiveProcedures = !!entitiesWithActiveProcedures.length;

      const messages = getMessage(
        entityName,
        isActiveProcedures ? entitiesWithActiveProcedures : requestEntities,
        status,
        optionToDisplay
      );

      if (!entitiesWithActiveProcedures.length) {
        if (onConfirm) {
          dispatch(
            openConfirmationModal({
              ...(messages || {}),
              onConfirm,
            })
          );
        }

        return isActiveProcedures;
      }

      notify.warning({
        autoHideDuration: null,
        message: messages?.warning || '',
      });

      return isActiveProcedures;
    },
    [
      notify,
      dispatch,
      entityName,
      optionToDisplay,
      getEntityWithActiveProcedures,
    ]
  );

  return [getIsActiveProcedures, hasProcedures];
};

export default useCheckForActiveProcedures;
