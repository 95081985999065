import unionBy from 'lodash/unionBy';

import { createRequestActionTypes } from 'common/utils/actionTypesHelper';
import { requestReducer } from 'common/utils/generators';

export const organizationActionTypes = createRequestActionTypes(
  [
    'getList',
    'getOne',
    'update',
    'create',
    'updateMany',
    'getLoggedIn',
    'filter',
    'login',
    'clearState',
  ],
  'organizations'
);

export const initialState = {
  data: [],
  loading: false,
  error: null,
};

const onRequestSuccess = (payload, state) => {
  const listObject = (payload.data ? [payload.data] : []).filter(Boolean);

  return {
    ...state,
    total: payload.total,
    data: Array.isArray(payload.data)
      ? payload.data
      : unionBy(listObject, state.data, 'id'),
  };
};

const organizations = requestReducer(
  [
    organizationActionTypes.getList,
    organizationActionTypes.getOne,
    organizationActionTypes.update,
  ],
  initialState,
  onRequestSuccess
);

export const getOrganizations = (payload) => {
  return {
    type: organizationActionTypes.getList.requested,
    payload,
  };
};

export const updateOrganization = (payload, onSuccess, onError) => ({
  type: organizationActionTypes.update.requested,
  payload,
  onSuccess,
  onError,
});

export const createOrganization = (payload, onSuccess, onError) => ({
  type: organizationActionTypes.create.requested,
  payload,
  onSuccess,
  onError,
});

export const getOrganization = (payload) => ({
  type: organizationActionTypes.getOne.requested,
  payload,
});

export const updateOrganizations = (payload, onSuccess) => ({
  type: organizationActionTypes.updateMany.requested,
  onSuccess,
  payload,
});

export default organizations;
