import { call, put, takeEvery } from 'redux-saga/effects';
import resources from 'common/constants/resources';
import dataProvider from 'store/dataProvider';
import { queryingPath } from 'common/utils/helpers/url';
import { procedureActionTypes } from '../reducer';

function* updateStatus(action) {
  const {
    payload: { id, status },
    onSuccess,
    onError,
  } = action;
  try {
    const url = queryingPath(resources.procedure.statuses, { id });
    yield call(dataProvider.updateOnly, url, status);

    yield put({ type: procedureActionTypes.updateStatuses.success });

    if (onSuccess) {
      onSuccess({});
    }
  } catch (error) {
    yield put({
      type: procedureActionTypes.updateStatuses.failed,
      message: error?.message,
    });

    if (onError) {
      onError(error);
    }
  }
}

export default function* updatePhysicianStatus() {
  yield takeEvery(procedureActionTypes.updateStatuses.requested, updateStatus);
}
