import { requestSaga } from 'common/utils/generators';
import { procedure } from 'common/constants/schemas';
import resources from 'common/constants/resources';
import dataProvider from 'store/dataProvider';

export const createGetProcedureSaga = (actionType) =>
  function* procedureInfoSaga() {
    yield requestSaga({
      actionType,
      schema: procedure,
      request: dataProvider.getOne,
      resource: resources.procedure.short,
    });
  };

export const createGetFullProcedureSaga = (actionType) =>
  function* procedureInfoSaga() {
    yield requestSaga({
      actionType,
      schema: procedure,
      request: dataProvider.getOne,
      resource: resources.procedure.index,
    });
  };
