import { createRequestActionTypes } from 'common/utils/actionTypesHelper';

export const actionTypes = createRequestActionTypes(
  ['getData', 'resetData'],
  'dashboard'
);

const initialState = {
  data: {},
  error: null,
  loading: false,
};

const dashboardReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.getData.requested:
      return { ...state, loading: true };

    case actionTypes.getData.success:
      return {
        ...state,
        loading: false,
        data: { ...state.data, ...action.payload },
      };

    case actionTypes.getData.failed:
      return { ...state, error: action.message, loading: false };

    case actionTypes.resetData.requested:
      return initialState;

    default:
      return state;
  }
};

export const getDashboardData = (
  payload,
  resource,
  dataKey,
  onRequest,
  onSuccess,
  onError
) => ({
  type: actionTypes.getData.requested,
  dataKey,
  payload,
  resource,
  onRequest,
  onSuccess,
  onError,
});

export const resetData = () => ({
  type: actionTypes.resetData.requested,
});

export default dashboardReducer;
