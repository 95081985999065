import {
  getReportData,
  approveOngoing,
  getReportNotes,
  approveEscalated,
  uploadReportDocument,
  getOngoingStudySummary,
  getEscalatedReportData,
} from './pdfReport';
import {
  getBeatsData,
  getFiveMinEventEcg,
  getEcgDataByPeriod,
  getEventSensorData,
  getBeatsDataByPeriod,
  getEscalatedEcgDataByPeriod,
} from './ecgData';
import getIncludedHours from './includedHours';
import getCorrelationDataSaga from './hrCorrelation';
import getOngoingEventListSaga from './eventList';
import { getEscalatedHRSummary } from './generalData';

export default [
  getReportData,
  getReportNotes,
  approveOngoing,
  getEcgDataByPeriod,
  getBeatsDataByPeriod,
  getCorrelationDataSaga,
  getOngoingStudySummary,
  getOngoingEventListSaga,
  // escalated
  getBeatsData,
  approveEscalated,
  getEventSensorData,
  getFiveMinEventEcg,
  getEscalatedHRSummary,
  getEscalatedReportData,
  getEscalatedEcgDataByPeriod,
  uploadReportDocument,

  getIncludedHours,
];
