import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getPatient, getProcedure } from 'common/utils/entitySelectors';

const useProcedureLocked = () => {
  const { id } = useParams();
  const procedure = useSelector(getProcedure(id));
  // const currentUserId = useSelector(getAuthUserId);
  const patient = useSelector(getPatient(procedure?.patient));

  if (!patient?.lockedBy) {
    return false;
  }

  // temporarily disabled due to resolving locking issue, will  be enabled soon
  return false; // patient.lockedBy !== currentUserId;
};

export default useProcedureLocked;
