export default {
  MuiCssBaseline: {
    '@global': {
      '*::-webkit-scrollbar': {
        width: 4,
        height: 4,
        backgroundColor: '#fff',
      },
      '*::-webkit-scrollbar-thumb': {
        borderRadius: 2,
        background: '#C4C4C4',
      },
      '*::-webkit-scrollbar-thumb:hover': {
        background: '#ccc',
      },
    },
  },
  MuiTextField: {
    root: {
      position: 'relative',
    },
  },
  MuiFormHelperText: {
    root: {
      position: 'absolute',
      top: '100%',
      zIndex: 100,
      fontSize: '0.7rem',
    },
  },
  MuiInput: {
    input: {
      font: '500 14px Roboto',
    },
  },
  MuiInputAdornment: {
    root: {
      flexShrink: 0,
      justifyContent: 'center',
      width: 45,
    },
  },
  MuiDialogTitle: {
    root: {
      padding: '16px 24px 8px',
      fontFamily: 'Poppins',
      fontWeight: 500,
      fontSize: '1.125rem',
      lineHeight: '1.5',
    },
  },
  MuiDataGrid: {
    root: {
      backgroundColor: 'white',
      border: 'none',
    },
  },
};
