import { requestSaga } from 'common/utils/generators';
import dataProvider from 'store/dataProvider';
import resources from 'common/constants/resources';

import { contactsActionTypes } from '../reducer';

function* fetchContactSaga() {
  yield requestSaga({
    actionType: contactsActionTypes.getOne,
    request: dataProvider.getOne,
    resource: resources.contact.item,
  });
}

export default fetchContactSaga;
