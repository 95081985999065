import React, { useMemo } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { KeyboardDatePicker } from '@material-ui/pickers';
import DateRangeIcon from '@material-ui/icons/DateRange';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';

import { datePickerFormat } from 'common/constants/dateFormats';
import useStyles from './styles';

const DatePicker = ({
  value,
  onChange,
  minDate,
  maxDate,
  helperText,
  variant,
  disabledDays,
  ...props
}) => {
  const { error, container, root, input } = useStyles();
  const dateValue = useMemo(() => (value ? new Date(value) : null), [value]);

  const disableDates = (date) =>
    disabledDays &&
    (!!minDate || !!maxDate) &&
    ((minDate && moment(date).isBefore(minDate, 'day')) ||
      (maxDate && moment(date).isAfter(maxDate, 'day')));

  return (
    <Grid className={container}>
      <KeyboardDatePicker
        {...props}
        variant="outlined"
        value={dateValue}
        classes={{ root }}
        onChange={onChange}
        inputVariant={variant}
        format={datePickerFormat}
        TextFieldComponent={TextField}
        shouldDisableDate={disableDates}
        InputProps={{
          disableunderline: 'true',
          classes: { input },
          endAdornment: (
            <InputAdornment position="end">
              <DateRangeIcon fontSize="5px" />
            </InputAdornment>
          ),
        }}
      />
      {helperText && (
        <FormHelperText className={error} error>
          {helperText}
        </FormHelperText>
      )}
    </Grid>
  );
};

DatePicker.defaultProps = {
  variant: 'outlined',
  disabledDays: true,
};

const datePropType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
  PropTypes.shape(),
  PropTypes.instanceOf(Date),
]);

DatePicker.propTypes = {
  value: datePropType,
  minDate: datePropType,
  maxDate: datePropType,
  onChange: PropTypes.func,
  helperText: PropTypes.string,
  disabledDays: PropTypes.bool,
  variant: PropTypes.oneOf(['inline', 'outlined']),
};

export default DatePicker;
