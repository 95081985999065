import { commonActionTypes } from 'common/ducks/actionTypes';

export const updateEcgEvents = (events, prevState, onSuccess, onError) => ({
  type: commonActionTypes.updateEcgEvents.requested,
  payload: { body: events },
  prevState,
  onSuccess,
  onError,
});

export const updatePatientEvent = (payload, prevState, onSuccess, onError) => ({
  type: commonActionTypes.updatePatientEvents.requested,
  payload,
  onSuccess,
  onError,
  prevState,
});

export const removeEcgEvents = (eventIds, onSuccess, onError) => ({
  type: commonActionTypes.removeEcgEvents.requested,
  payload: { ecgEventGroupIds: eventIds },
  onSuccess,
  onError,
});

export const bulkStatusUpdate = (
  events,
  onSuccess,
  onError,
  isShouldBeUndoable = true
) => ({
  type: commonActionTypes.bulkStatusUpdate.requested,
  payload: events,
  onSuccess,
  onError,
  isShouldBeUndoable,
});

export const changeVisibilityOfPE = (payload) => ({
  type: commonActionTypes.changeVisibilityPatientEvent.requested,
  payload,
});

export const setInvertEcg = (
  query,
  onSuccess,
  onError,
  isShouldBeUndoable = true
) => ({
  type: commonActionTypes.setInvertEcg.requested,
  payload: { query },
  isShouldBeUndoable,
  onSuccess,
  onError,
});

export const setInvertPEEcg = (
  params,
  onSuccess,
  onError,
  isShouldBeUndoable = true
) => ({
  type: commonActionTypes.setInvertPEEcg.requested,
  payload: { params },
  isShouldBeUndoable,
  onSuccess,
  onError,
});
