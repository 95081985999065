import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

import { useProcedureLocked } from 'common/hooks';
import { symptomLabelsEnum } from 'common/constants/enums';
import AutocompleteInput from 'common/components/simpleInputs/AutocompleteInput';
import StripSettingsContainer from './StripSettingsContainer';
import { eventSymptomsStyles as useStyles } from '../styles';
import { symptomsField } from '../constants';

const renderTag =
  (classes) =>
  ({ label = '' }) =>
    (
      <Typography
        key={label}
        variant="body2"
        className={classes.selectedSymptomOption}
      >
        {label}
      </Typography>
    );

const renderSelectedTag = (classes) => (values) =>
  values.map(renderTag(classes));

const EventSymptoms = ({ disabled, value: ids, onChange }) => {
  const classes = useStyles();
  const locked = useProcedureLocked();
  const [opened, setOpened] = useState(false);
  const [currentValue, setCurrentValue] = useState(false);

  const updateValue = (e, v) => {
    setCurrentValue(v);
  };

  const handleClose = () => {
    onChange(currentValue.map(({ value: v }) => v));
  };

  useEffect(() => {
    const v = ids.map((id) => ({
      label: symptomLabelsEnum[id],
      value: id,
    }));

    setCurrentValue(v);
  }, [setCurrentValue, ids]);

  return (
    <StripSettingsContainer>
      <AutocompleteInput
        {...symptomsField}
        size="small"
        variant="standard"
        value={currentValue}
        onClose={handleClose}
        onChange={updateValue}
        disabled={locked || disabled}
        open={opened && !disabled && !locked}
        onFocus={() => setOpened(true)}
        onBlur={() => setOpened(false)}
        renderTags={renderSelectedTag(classes)}
        classes={{
          root: classes.root,
          input: classes.input,
        }}
      />
    </StripSettingsContainer>
  );
};

EventSymptoms.defaultProps = {
  disabled: false,
  value: [],
  onChange: () => null,
};

EventSymptoms.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
};

export default EventSymptoms;
