import fetchContacts from './fetchContacts';
import fetchContact from './fetchContact';
import createContactAccount from './createContactAccount';
import {
  updateContact,
  assignToContact,
  unAssignFromContact,
} from './updateContact';

export default [
  fetchContacts,
  fetchContact,
  updateContact,
  assignToContact,
  unAssignFromContact,
  createContactAccount,
];
