import unionBy from 'lodash/unionBy';
import { createRequestActionTypes } from 'common/utils/actionTypesHelper';
import { requestReducer } from 'common/utils/generators';

export const contactsActionTypes = createRequestActionTypes(
  [
    'getList',
    'getOne',
    'update',
    'createContactAccount',
    'assignContactToAccount',
    'unAssignContactFromAccount',
  ],
  'contacts'
);

const initialState = {
  loading: false,
  data: [],
  error: false,
};

export default requestReducer(
  [contactsActionTypes.getList, contactsActionTypes.getOne],
  initialState,
  (payload, state) => {
    const { data, total } = payload;
    return {
      ...state,
      data: Array.isArray(data) ? data : unionBy([data], state.data, 'id'),
      total,
    };
  }
);

export const getContactsList = (payload) => ({
  type: contactsActionTypes.getList.requested,
  payload,
});

export const getContact = (id) => ({
  type: contactsActionTypes.getOne.requested,
  payload: { id },
});

export const updateContact = (payload, onSuccess, onError) => ({
  type: contactsActionTypes.update.requested,
  payload,
  onSuccess,
  onError,
});

export const createContactAccount = (payload, onSuccess, onError) => ({
  type: contactsActionTypes.createContactAccount.requested,
  payload,
  onSuccess,
  onError,
});

export const assignContactToAccount = (payload, onSuccess, onError) => ({
  type: contactsActionTypes.assignContactToAccount.requested,
  payload,
  onSuccess,
  onError,
});

export const unAssignContactFromAccount = (payload, onSuccess, onError) => ({
  type: contactsActionTypes.unAssignContactFromAccount.requested,
  payload,
  onSuccess,
  onError,
});
