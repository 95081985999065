import { call, put, takeEvery } from 'redux-saga/effects';

import dataProvider from 'store/dataProvider';
import { actionTypes } from './reducer';

function* getDashboardData(action) {
  const { payload, dataKey, resource, onSuccess, onRequest, onError } = action;

  if (onRequest) {
    onRequest();
  }

  try {
    const { data } = yield call(dataProvider.getList, resource, payload);

    yield put({
      type: actionTypes.getData.success,
      payload: { [dataKey]: data },
    });

    if (onSuccess) {
      onSuccess();
    }
  } catch ({ message }) {
    yield put({ type: actionTypes.getData.failed, message });

    if (onError) {
      onError();
    }
  }
}

function* getDataSaga() {
  yield takeEvery(actionTypes.getData.requested, getDashboardData);
}

export default [getDataSaga];
