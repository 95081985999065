import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';

import useNotify from 'common/modules/Notification/hooks/useNotify';

import { contactsActions } from './ducks';
import ContactsForm from './components/ContactsForm';
import ContactModalTitle from './components/ContactModalTitle';

const ContactEdit = ({
  contact,
  entityId,
  onClose,
  refreshData = () => {},
}) => {
  const dispatch = useDispatch();
  const notify = useNotify();

  const close = () => {
    if (onClose) {
      onClose();
    }
  };

  const onSaveSuccess = () => {
    notify.success('Contact successfully saved');
    refreshData();
    close();
  };

  const onSaveError = ({ body }) => {
    notify.error(body?.exception || 'Error while saving contact');
  };

  const save = (d) => {
    const data = {
      ...contact,
      ...d,
      use2FactorAuth: Boolean(d.use2FactorAuth),
    };

    if (!data.email) {
      data.email = undefined;
    }

    dispatch(
      contactsActions.updateContact(
        { params: { id: data.id }, body: data },
        onSaveSuccess,
        onSaveError
      )
    );
  };

  const onRemoveSuccess = () => {
    notify.success('Contact successfully deleted');
    refreshData();
    close();
  };

  const onRemoveError = () => {
    notify.error('Error while deleting contact');
  };

  const remove = () => {
    dispatch(
      contactsActions.unAssignContactFromAccount(
        { params: { id: contact.id, accountId: entityId } },
        onRemoveSuccess,
        onRemoveError
      )
    );
  };

  return (
    <Grid container direction="column">
      <ContactModalTitle title="Edit" onClose={close} />

      <ContactsForm
        onSubmit={save}
        onDelete={remove}
        defaultValues={contact}
        editing={!!contact?.id}
      />
    </Grid>
  );
};

ContactEdit.propTypes = {
  onClose: PropTypes.func,
  contact: PropTypes.shape(),
  entityId: PropTypes.string,
  refreshData: PropTypes.func,
};

export default ContactEdit;
