import { activityLabel } from 'common/constants/enums';

export const normalizeWheelSpeed = (event) => {
  if (event.nativeEvent.wheelDelta) {
    return event.nativeEvent.wheelDelta % 120 === 0
      ? event.nativeEvent.wheelDelta / 120
      : event.nativeEvent.wheelDelta / 6;
  }

  const rawAmount = event.deltaY ? event.deltaY : event.detail;
  return -(rawAmount % 3 ? rawAmount * 10 : rawAmount / 3);
};

export const pluralize = (count, noun, suffix = 's') =>
  `${count} ${noun}${count !== 1 ? suffix : ''}`;

export const celsiusToFahrenheit = (value) => (value * (9 / 5) + 32).toFixed(1);

export const getActivityByValue = (value) => {
  if (value >= 1 && value <= 5) {
    return activityLabel.low;
  }

  if (value >= 6 && value <= 10) {
    return activityLabel.medium;
  }

  if (value >= 11 && value <= 16) {
    return activityLabel.high;
  }

  return activityLabel.none;
};

export const cancellablePromise = (promise) => {
  let isCanceled = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      (value) => (isCanceled ? reject({ isCanceled, value }) : resolve(value)),
      (error) => reject({ isCanceled, error })
    );
  });

  return {
    promise: wrappedPromise,
    cancel: () => {
      isCanceled = true;
    },
  };
};

export * from './storage';
