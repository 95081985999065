import { call, put, takeEvery } from 'redux-saga/effects';

export default function* sagaGenerator({
  actionType,
  request,
  resource,
  schema,
}) {
  function* fetch(action) {
    const payloadData = Array.isArray(action.payload)
      ? { requestData: action.payload }
      : action.payload;
    const requestPayload = { ...action, ...payloadData };

    const params = action.signal
      ? { ...action.payload, signal: action.signal }
      : action.payload;

    try {
      const payload = yield call(request, resource, params, schema);
      yield put({
        payload,
        type: actionType.success,
        requestPayload,
      });

      if (action.onSuccess) {
        yield call(() =>
          action.onSuccess({
            payload,
            type: actionType.success,
            requestPayload,
          })
        );
      }
    } catch (e) {
      const { message } = e;
      yield put({ type: actionType.failed, message, requestPayload });

      if (action.onError) {
        yield call(action.onError, e);
      }
    }
  }

  yield takeEvery(actionType.requested, fetch);
}
