import { all, call, put, takeLatest } from 'redux-saga/effects';
import moment from 'moment/moment';

import { requestSaga } from 'common/utils/generators';
import resources from 'common/constants/resources';
import dataProvider from 'store/dataProvider';
import { mapIncludedDisclosure } from 'common/utils/procedureEventMappers';

import { actionTypes } from './index';

export function* getProcedureECGPeriod() {
  yield requestSaga({
    actionType: actionTypes.getEcgPeriod,
    request: dataProvider.getList,
    resource: resources.ecgData.byPeriod,
  });
}

export function* getEcgEventsPeriod() {
  yield requestSaga({
    actionType: actionTypes.getEcgEventsPeriod,
    request: dataProvider.getList,
    resource: resources.eventGroup.byPeriod,
  });
}

export function* getPatientEventsPeriod() {
  yield requestSaga({
    actionType: actionTypes.getPatientEvents,
    request: dataProvider.getList,
    resource: resources.healthData.patientEvents,
  });
}

const getHRS = async (disclosure) => {
  const mappedDisclosure = mapIncludedDisclosure(disclosure);

  const filter = {
    dateFrom: mappedDisclosure.dateFrom,
    dateTo: moment(disclosure.dateTime).add(1, 'hour').toISOString(),
    procedureId: disclosure.procedureId,
  };

  const responseHR = await dataProvider.get(
    resources.healthData.heartRateStripSummary,
    {
      filter: {
        ...filter,
        groupType: 'Hour',
      },
    }
  );

  return {
    ...mappedDisclosure,
    heartRateMin: responseHR?.data?.min,
    heartRateMax: responseHR?.data?.max,
    avgHeartRate: responseHR?.data?.average,
    heartRateAverage: responseHR?.data?.average,
  };
};

export function* getIncludedDisclosures() {
  yield requestSaga({
    actionType: actionTypes.getIncludedDisclosures,
    request: dataProvider.getList,
    resource: resources.eventGroup.getIncludedDisclosure,
  });
}

function* onIncludedDisclosures({ payload }) {
  try {
    const response = yield call(
      dataProvider.updateOne,
      resources.eventGroup.includeDisclosure,
      payload
    );

    const newHours = response.data.result || [];
    const result = yield all(newHours.map(getHRS));

    yield put({
      type: actionTypes.includeHour.success,
      payload: { data: { result } },
    });
  } catch ({ message }) {
    yield put({ type: actionTypes.includeHour.failed, message });
  }
}

export function* includedHourDisclosure() {
  yield takeLatest(actionTypes.includeHour.requested, onIncludedDisclosures);
}

export function* declineHourDisclosure() {
  yield requestSaga({
    actionType: actionTypes.declineHour,
    request: dataProvider.updateOne,
    resource: resources.eventGroup.declineDisclosure,
  });
}

export default [
  getEcgEventsPeriod,
  getProcedureECGPeriod,
  getPatientEventsPeriod,

  getIncludedDisclosures,
  includedHourDisclosure,
  declineHourDisclosure,
];
