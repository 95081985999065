import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  groupMessageByType,
  eventGroupMessageMapper,
  patientEventMessageMapper,
} from 'common/utils/helpers/socket';
import { useSocketHub } from 'common/hooks';
import { entities } from 'common/constants/socketConstants';

import { socketMessagePEUpdate, socketMessageUpdate } from '../ducks';

const useUpdateEventStripBySocket = ({
  startRange,
  endRange,
  eventId,
  onClose,
  resource,
  procedureId,
  status,
}) => {
  const [updatedEvents, setUpdatedEvents] = useState(null);
  const dispatch = useDispatch();

  const socketHubPayload = useMemo(() => [procedureId], [procedureId]);

  const updateIsEventActual = useCallback(() => {
    if (!updatedEvents || !onClose) {
      return;
    }

    const modified = (updatedEvents || []).find((e) => e.id === eventId);

    if (!status?.length && eventId && modified?.status) {
      onClose();
    }

    setUpdatedEvents(null);
  }, [onClose, eventId, status?.length, updatedEvents]);

  const onMessage = useCallback(
    (message) => {
      const range = [startRange, endRange];

      const { isEventGroup, isPatientEvent, payload } =
        groupMessageByType(message);

      if (isEventGroup) {
        const { added, updated, removed } = eventGroupMessageMapper(
          payload,
          range
        );

        const modified = [...updated, ...removed];
        setUpdatedEvents(modified);

        dispatch(
          socketMessageUpdate({ added, updated, removed }, status, resource)
        );
      }

      if (isPatientEvent) {
        dispatch(
          socketMessagePEUpdate(
            patientEventMessageMapper(payload, range),
            status,
            resource
          )
        );
      }
    },
    [status, startRange, endRange, resource, dispatch]
  );

  useSocketHub(entities.procedure, socketHubPayload, onMessage, true);

  useEffect(() => {
    updateIsEventActual();
  }, [updateIsEventActual]);
};

export default useUpdateEventStripBySocket;
