import { requestSaga } from 'common/utils/generators';
import { procedure } from 'common/constants/schemas';
import resources from 'common/constants/resources';
import dataProvider from 'store/dataProvider';
import { procedureActionTypes } from '../reducer';

function* updateProcedure() {
  yield requestSaga({
    actionType: procedureActionTypes.update,
    request: dataProvider.updateOne,
    resource: resources.procedure.index,
    schema: procedure,
  });
}

export default updateProcedure;
