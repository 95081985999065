import React from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useController } from 'react-hook-form';

import TextInput from '../../simpleInputs/TextInput';
import useStyles from './styles';

const sanitizeProps = ({ onInputChange, ...props }) => props;

const TextField = ({
  name,
  form,
  control,
  rules,
  defaultValue = '',
  ...rest
}) => {
  const classes = useStyles();
  const props = sanitizeProps(rest);
  const { errors } = form;
  const { field } = useController({
    name,
    rules,
    control,
    defaultValue,
  });

  const onChange = (e, value) => {
    field.onChange(e, value);
  };

  return (
    <TextInput
      {...props}
      name={name}
      field={field}
      form={form}
      onChange={onChange}
      error={!!get(errors, field.name)}
      helperText={
        get(errors, field.name) ? get(errors, field.name).message : ''
      }
      FormHelperTextProps={{
        className: classes.formHelperText,
        'data-testid': 'helper-text',
      }}
    />
  );
};

TextField.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.array,
  ]),
  rules: PropTypes.objectOf(PropTypes.any),
  form: PropTypes.shape({
    errors: PropTypes.objectOf(PropTypes.any),
    touched: PropTypes.objectOf(PropTypes.any),
  }),
  control: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default TextField;
