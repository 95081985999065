import { requestSaga } from 'common/utils/generators';
import resources from 'common/constants/resources';
import dataProvider from 'store/dataProvider';
import { patientsActionTypes } from '../reducer';

function* createPatient() {
  yield requestSaga({
    actionType: patientsActionTypes.create,
    request: dataProvider.createOne,
    resource: resources.patient.index,
  });
}

export default createPatient;
