import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles((theme) => ({
  container: {
    width: '100%',
  },
  root: {
    width: '100%',
    position: 'relative',
    background: theme.palette.common.white,
    '& .MuiInputAdornment-root': {
      position: 'absolute',
      right: 0,
    },
  },
  input: {
    height: 25,
    fontSize: 12,
    padding: theme.spacing(0.8, 1.25),
  },
  error: {
    position: 'relative',
  },
}));
