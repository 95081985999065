import fetchPatches from './fetchPatches';
import fetchPatch from './fetchPatch';
import createPatch from './createPatch';
import updatePatch from './updatePatch';
import updatePatches from './updatePatches';
import updatePatchesStatus from './updateStatus';

export default [
  fetchPatches,
  fetchPatch,
  createPatch,
  updatePatch,
  updatePatches,
  updatePatchesStatus,
];
