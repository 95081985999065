import { requestSaga } from 'common/utils/generators';
import resources from 'common/constants/resources';
import dataProvider from 'store/dataProvider';
import { actionTypes } from '../reducer';

function* updatePhones() {
  yield requestSaga({
    actionType: actionTypes.updateMany,
    request: dataProvider.updateMany,
    resource: resources.simCard.statuses,
  });
}

export default updatePhones;
