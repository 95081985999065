import React, { useMemo } from 'react';
import clsx from 'classnames';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import ModalActionButton from 'common/components/buttons/ModalActionButton';
import { eventGroupSortOptions } from 'common/constants/formFieldOptions';
import { eventGroupsOptionsEnum } from '../constants';
import GridActions from './GridActions';
import Pagination from './Pagination';
import useStyles from '../styles';
import OptionSelector from './OptionSelector';

const createButton =
  (modal = false) =>
  ({ children = null, ...props }) => {
    const variant = modal ? 'button' : 'body1';

    return (
      <ModalActionButton size="small" variant={variant} {...props}>
        {children}
      </ModalActionButton>
    );
  };

const Header = ({
  page,
  sort,
  total,
  modal,
  goBack,
  events,
  onClose,
  setPage,
  perPage,
  setSort,
  selectAll,
  deselectAll,
  isAllSelected,
  eventGroupType,
  disabledSelect,
  selectedEvents,
  abnormalityType,
  onReclassifySuccess,
  handleChangeGroupType,
}) => {
  const styles = useStyles();

  const lastPage = useMemo(
    () => Math.ceil(total / perPage) || 1,
    [total, perPage]
  );
  const smallScreen = useMediaQuery('(max-width: 1360px)');

  const paginationOptions = useMemo(
    () => ({ page, lastPage }),
    [page, lastPage]
  );

  const eventGroupsOptions = useMemo(
    () => (eventGroupType ? eventGroupsOptionsEnum[eventGroupType] : null),
    [eventGroupType]
  );

  const onReviewSuccess = () => {
    onReclassifySuccess();
    deselectAll();
  };

  const Button = createButton(modal);
  const modalText = modal ? 'h4' : 'h6';
  const headerTextVariant = smallScreen ? 'body1' : modalText;

  return (
    <Grid
      container
      wrap="nowrap"
      alignItems="center"
      justifyContent="space-between"
      className={clsx(styles.dialogHeaderWrapper, {
        [styles.dialogHeaderWrapperNonModal]: !modal,
      })}
    >
      <Grid item className={styles.headerActionsContainer}>
        {goBack && (
          <Grid item className={styles.goBackContainer}>
            <ArrowBack onClick={goBack} color="inherit" />
          </Grid>
        )}

        <GridActions
          allEvents={events}
          selectAll={selectAll}
          onSuccess={onReviewSuccess}
          allSelected={isAllSelected}
          selectedEvents={selectedEvents}
          buttonClasses={{
            root: styles.triageSelectedButton,
            disabled: styles.disabledSelectButton,
          }}
          disabledSelect={disabledSelect}
        />
      </Grid>

      {eventGroupType && typeof abnormalityType === 'string' && (
        <Grid item>
          <OptionSelector
            name="eventGroup"
            value={abnormalityType}
            options={eventGroupsOptions}
            onChange={handleChangeGroupType}
          />
        </Grid>
      )}

      {sort && setSort && (
        <Grid item>
          <OptionSelector
            name="sort"
            value={sort}
            options={eventGroupSortOptions}
            onChange={setSort}
          />
        </Grid>
      )}

      <Typography
        item
        component={Grid}
        variant={headerTextVariant}
        className={styles.dialogHeader}
      >
        Total ({total})
      </Typography>

      {!!perPage && (
        <Grid item>
          <Pagination
            modal={modal}
            onPageChanged={setPage}
            options={paginationOptions}
          />
        </Grid>
      )}

      {onClose && (
        <Grid item>
          <Button onClick={onClose}>Close</Button>
        </Grid>
      )}
    </Grid>
  );
};

Header.defaultProps = {
  page: 1,
  total: 0,
  events: [],
  modal: false,
  eventGroupType: null,
  isAllSelected: false,
  setPage: () => null,
  selectedEvents: [],
  disabledSelect: false,
  deselectAll: () => null,
  onReclassifySuccess: () => null,
  handleChangeGroupType: () => null,
};

Header.propTypes = {
  sort: PropTypes.string,
  modal: PropTypes.bool,
  page: PropTypes.number,
  total: PropTypes.number,
  goBack: PropTypes.func,
  setPage: PropTypes.func,
  setSort: PropTypes.func,
  onClose: PropTypes.func,
  selectAll: PropTypes.func,
  deselectAll: PropTypes.func,
  isAllSelected: PropTypes.bool,
  disabledSelect: PropTypes.bool,
  eventGroupType: PropTypes.string,
  abnormalityType: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  onReclassifySuccess: PropTypes.func,
  handleChangeGroupType: PropTypes.func,
  perPage: PropTypes.number.isRequired,
  events: PropTypes.arrayOf(PropTypes.shape()),
  selectedEvents: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.shape(), PropTypes.string])
  ),
};

export default Header;
