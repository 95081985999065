import React, { useMemo } from 'react';

import {
  getCenterBetweenDatesUTC,
  getFormattedDuration,
} from 'common/utils/helpers/date';
import {
  containerChildProps,
  eventGroupType,
} from 'common/constants/sharedPropTypes';

const D3PauseLabel = ({ parent, event }) => {
  const { height, xScale, scale } = parent;
  const sx = scale || xScale;
  const {
    dateFromEvent,
    dateToEvent,
    dateFrom,
    dateTo,
    durationEvent,
    duration,
  } = event;

  const from = useMemo(
    () => dateFromEvent || dateFrom,
    [dateFrom, dateFromEvent]
  );
  const to = useMemo(() => dateToEvent || dateTo, [dateTo, dateToEvent]);

  const center = useMemo(() => getCenterBetweenDatesUTC(from, to), [from, to]);

  const durationInSeconds = useMemo(
    () => getFormattedDuration(durationEvent || duration, true),
    [duration, durationEvent]
  );

  return (
    <text
      fontSize="12"
      transform={`translate(${sx(center) - 10},${height / 2.5})`}
    >
      {durationInSeconds}
    </text>
  );
};

D3PauseLabel.propTypes = {
  parent: containerChildProps,
  event: eventGroupType,
};

export default D3PauseLabel;
