import * as Yup from 'yup';

import * as schemes from 'common/utils/validationSchemas';
import validationMessages from 'common/constants/validationErrors';

export default Yup.object().shape({
  fullName: Yup.string().required(validationMessages.contactName.required),
  phone: schemes.phone.required(validationMessages.phone.required),
  email: schemes.email,
});
