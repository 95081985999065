import { useMemo, useCallback, useEffect, useRef, useState } from 'react';

import useAuthProvider from 'store/authProvider/useAuthProvider';
import { API_AUTHORIZATION_URL } from 'config/api';
import {
  getValueFromToken,
  reauthenticateIfNeeded,
} from 'common/utils/providerHelpers/tokensHelper';

const INDENT_BEFORE_EXPIRATION = 10;

const useRefreshTokenSchedule = () => {
  const authProvider = useAuthProvider();
  const [actualExpTime, setActualTime] = useState(null);
  const prevTimerCleaner = useRef(null);

  const expirationTime = useMemo(() => {
    const { exp } = getValueFromToken() || { exp: actualExpTime };
    return exp;
  }, [actualExpTime]);

  const updateToken = useCallback(
    () =>
      reauthenticateIfNeeded(
        { apiAuthUrl: API_AUTHORIZATION_URL },
        undefined,
        {}
      ).then((tokenData) => {
        authProvider.checkAuth();
        return tokenData;
      }),
    [authProvider]
  );

  const timerId = useMemo(() => {
    const nowSec = Math.floor(Date.now() / 1000);
    const delaySec = expirationTime - nowSec - INDENT_BEFORE_EXPIRATION;
    const delayMs = delaySec * 1000 || null;

    return setTimeout(() => {
      if (delayMs === null) return;

      updateToken().then(({ accessToken }) => {
        setActualTime(getValueFromToken(accessToken));
      });
    }, delayMs);
  }, [expirationTime, updateToken]);

  const clear = useCallback(() => clearTimeout(timerId), [timerId]);

  useEffect(() => {
    prevTimerCleaner.current = clear;

    return () => {
      prevTimerCleaner.current();
      prevTimerCleaner.current = clear;
    };
  }, [clear]);
};

export default useRefreshTokenSchedule;
