import dataProvider from 'store/dataProvider';
import resources from 'common/constants/resources';
import { requestSaga } from 'common/utils/generators';
import { reports } from '../index';

function* fetchReports() {
  yield requestSaga({
    actionType: reports.get,
    request: dataProvider.get,
    resource: resources.report.list,
  });
}

export default fetchReports;
