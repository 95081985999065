import moment from 'moment';
import {
  ecgTypeNames,
  ecgTypeLabels,
  abnormalityTypeEnum,
} from 'common/constants/ecgEnums';
import { ecgEventStatus } from 'common/constants/enums';
import { getCenterBetweenDatesUTC } from './date';

export const updateNestedEventData = (state, { eventId, part }, value) => ({
  ...state,
  [eventId]: { ...(state[eventId] || {}), [part]: value },
});

export const getPrePostDates = (dateFrom, dateTo) => {
  const center = moment(
    getCenterBetweenDatesUTC(dateFrom, dateTo)
  ).toISOString();

  const pre = moment(dateFrom).subtract(10, 'seconds').toISOString();
  const post = moment(dateTo).add(10, 'seconds').toISOString();

  return { pre, post, center };
};

const checkForNsr = (abType, eventGroupIds) =>
  eventGroupIds.length > 1 ? abType !== abnormalityTypeEnum.NSR : true;

const statuses = [ecgEventStatus.included, ecgEventStatus.accepted];

export const mergePEWithEvents = (pe, events) => {
  return pe.map((peEv, index) => {
    const attachedEcgEvents = peEv.ecgEventGroupIds
      .map((id) => events.find((e) => e.id === id))
      .filter(Boolean);

    const isNotNSROnly = !!attachedEcgEvents.find(
      ({ abnormalityType }) => abnormalityType !== abnormalityTypeEnum.NSR
    );

    const includedEv = attachedEcgEvents.find(({ status }) =>
      statuses.includes(status)
    );

    const ev = attachedEcgEvents.find(({ abnormalityType }) =>
      isNotNSROnly
        ? checkForNsr(abnormalityType, peEv.ecgEventGroupIds)
        : !isNotNSROnly
    );

    const finalEcgEv = includedEv || ev;
    const restEventsAbnormalities =
      finalEcgEv &&
      attachedEcgEvents
        .filter((e) => e.id !== finalEcgEv?.id)
        .map((e) => e.abnormalityType);

    const {
      avgHeartRate,
      dateFrom = peEv.date,
      dateTo = peEv.date,
      abnormalityType,
    } = finalEcgEv || {};

    return {
      ...peEv,
      dateTo,
      dateFrom,
      avgHeartRate: avgHeartRate || 'N/A',
      procedureId: finalEcgEv?.procedureId,
      coreAbnormalityType: finalEcgEv?.abnormalityType,
      isPE: true,
      patientEvent: true,
      abnormalityType: abnormalityTypeEnum.patient,
      label: `PE #${index + 1} (${
        abnormalityType ? ecgTypeNames[abnormalityType] : 'N/A'
      })`,
      subtitle: restEventsAbnormalities?.length
        ? `w ${restEventsAbnormalities.join(', ')}`
        : '',
    };
  });
};

export const mergePEWithAttachedEvents = (state, patientEvent) => {
  const currentPEs = state || [];
  let counter = currentPEs[currentPEs.length - 1]?.counter || 0;

  return (Array.isArray(patientEvent) ? patientEvent : [patientEvent])
    .map((pe) => {
      counter += 1;

      return (pe.ecgEventGroups || []).map((eg) => {
        return {
          ...eg,
          counter,
          isPE: true,
          date: pe.date,
          rotateEcg: pe.rotateEcg,
          patientEventId: pe.id,
          dateFromEvent: eg.dateFrom,
          dateToEvent: eg.dateTo,
          symptoms: pe.symptoms,
          label: {
            title: `PE #${counter} (${ecgTypeLabels[eg.abnormalityType]})`,
          },
        };
      });
    })
    .flat();
};
