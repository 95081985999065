import React from 'react';
import PropTypes from 'prop-types';

import { containerChildProps } from 'common/constants/sharedPropTypes';
import { speedMarksSizeDiff } from '../constants';

const BeatRMark = ({ parent, time, value }) => {
  const { xScale, scale, options } = parent;
  const sx = scale || xScale;

  const cof = speedMarksSizeDiff[options.settings.speed];

  if (!value) {
    const circleCof = options.settings.speed < 1 ? cof / 1.5 : cof;
    const radius = 3 * circleCof;

    return <circle cy={35} r={radius} cx={sx(new Date(time)) - 1} />;
  }

  return (
    <text
      fill="red"
      fontWeight={700}
      fontSize={16 * cof}
      transform={`translate(${sx(new Date(time)) - 4}, ${40})`}
    >
      {value}
    </text>
  );
};

BeatRMark.propTypes = {
  time: PropTypes.string,
  value: PropTypes.string,
  parent: containerChildProps,
};

export default BeatRMark;
