import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { containerChildProps } from 'common/constants/sharedPropTypes';

const BeatOutline = ({ parent, position, color, fillOpacity }) => {
  const { xScale, yScale, scale } = parent;
  const xz = scale || xScale;

  const dataValueRange = useMemo(() => yScale.domain(), [yScale]);

  const width = useMemo(
    () => xz(position[1]) - xz(position[0]),
    [position, xz]
  );
  const height = useMemo(
    () => (yScale(dataValueRange[0]) - yScale(dataValueRange[1])) / 2,
    [dataValueRange, yScale]
  );

  return (
    <rect
      width={width}
      fill={color}
      height={height}
      fillOpacity={fillOpacity}
      transform={`translate(${xz(position[0])}, ${height - height / 2})`}
    />
  );
};

BeatOutline.defaultProps = {
  color: 'yellow',
  fillOpacity: 0.3,
};

BeatOutline.propTypes = {
  color: PropTypes.string,
  parent: containerChildProps,
  fillOpacity: PropTypes.number,
  position: PropTypes.arrayOf(Date),
};

export default BeatOutline;
