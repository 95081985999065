import makeStyles from '@material-ui/styles/makeStyles';

export const useTemplateStyles = makeStyles(({ palette, ...theme }) => ({
  container: {
    padding: theme.spacing(0.5, 0.25),
    backgroundColor: palette.background.secondaryDark,
    '& h6': {
      [theme.breakpoints.down(1600)]: {
        fontSize: '0.5rem',
      },
      [theme.breakpoints.down(1450)]: {
        fontSize: '0.45rem',
      },
    },
  },

  containerSelected: {
    backgroundColor: palette.background.darkLight,
  },

  title: {
    fontWeight: 500,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },

  subInfo: {
    fontWeight: 700,
    paddingRight: theme.spacing(0.25),
  },
}));
