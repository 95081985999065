import React, { memo, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import makeStyles from '@material-ui/styles/makeStyles';

const useStyles = makeStyles({
  root: {
    padding: 0,

    '&>span>svg': {
      fontSize: '1.9rem',
    },
  },
});

const TriagesGridRowCell = ({ idx, isChecked, onChange }) => {
  const classes = useStyles();
  const isRowChecked = useMemo(() => isChecked(idx), [isChecked, idx]);
  const handleChange = useCallback(() => onChange(idx), [idx, onChange]);

  return (
    <Checkbox
      color="primary"
      classes={classes}
      checked={isRowChecked}
      onChange={handleChange}
    />
  );
};

TriagesGridRowCell.propTypes = {
  idx: PropTypes.number.isRequired,
  isChecked: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default memo(TriagesGridRowCell);
