import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';

import RangeItem from './RangeItem';

const sanitizeProps = ({
  optionLabel,
  onInputChange,
  optionValueName,
  ...props
}) => props;

const RangeInput = ({
  name,
  select,
  options,
  label = '',
  maxLength = null,
  minValueName = 'min',
  maxValueName = 'max',
  minValueLabel = 'From',
  maxValueLabel = 'To',
  inputType = 'number',
  field = {},
  ...rest
}) => {
  const props = sanitizeProps(rest);
  const [currentValue, setCurrentValue] = useState({
    [minValueName]: '',
    [maxValueName]: '',
  });

  const handleChangeRange = (fieldName) => (event, value) => {
    const newValue = currentValue || {};
    newValue[fieldName] = value;

    setCurrentValue(newValue);

    if (field.onChange) {
      field.onChange(newValue);
    }

    if (props.onChange) {
      props.onChange(event, newValue);
    }
  };

  const renderRangeItem = (rangeItemName, rangeItemPlaceholder) => (
    <Grid item>
      <RangeItem
        {...props}
        size="small"
        margin="none"
        select={select}
        type={inputType}
        options={options}
        variant="standard"
        name={rangeItemName}
        inputProps={{ min: 0 }}
        maxLength={maxLength}
        placeholder={rangeItemPlaceholder}
        value={currentValue[rangeItemName]}
        onChange={handleChangeRange(rangeItemName)}
      />
    </Grid>
  );

  return (
    <FormControl component="fieldset" variant="outlined">
      <FormLabel component="legend">{label}</FormLabel>
      <FormGroup row>
        <Grid container direction="row" wrap="nowrap" spacing={1}>
          {renderRangeItem(minValueName, minValueLabel)}
          {renderRangeItem(maxValueName, maxValueLabel)}
        </Grid>
      </FormGroup>
    </FormControl>
  );
};

RangeInput.propTypes = {
  label: PropTypes.string,
  maxLength: PropTypes.number,
  className: PropTypes.string,
  onInputChange: PropTypes.func,
  name: PropTypes.string.isRequired,
  field: PropTypes.objectOf(PropTypes.any),
  options: PropTypes.arrayOf(PropTypes.any),
  select: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.array,
  ]),
  onChange: PropTypes.func,
  inputType: PropTypes.string,
  minValueName: PropTypes.string,
  maxValueName: PropTypes.string,
  minValueLabel: PropTypes.string,
  maxValueLabel: PropTypes.string,
};

export default RangeInput;
