import { requestSaga } from 'common/utils/generators';
import resources from 'common/constants/resources';
import dataProvider from 'store/dataProvider';

import { contactsActionTypes } from '../reducer';

export function* updateContact() {
  yield requestSaga({
    actionType: contactsActionTypes.update,
    request: dataProvider.updateOne,
    resource: resources.contact.item,
  });
}

export function* assignToContact() {
  yield requestSaga({
    actionType: contactsActionTypes.assignContactToAccount,
    request: dataProvider.updateOne,
    resource: resources.contact.assign,
  });
}

export function* unAssignFromContact() {
  yield requestSaga({
    actionType: contactsActionTypes.unAssignContactFromAccount,
    request: dataProvider.updateOne,
    resource: resources.contact.unAssign,
  });
}
