import dataProvider from 'store/dataProvider';
import resources from 'common/constants/resources';
import { requestSaga } from 'common/utils/generators';

import { actionTypes } from '../index';

function* endProcedureSaga() {
  yield requestSaga({
    request: dataProvider.createOne,
    resource: resources.procedure.end,
    actionType: actionTypes.endProcedure,
  });
}

export default endProcedureSaga;
