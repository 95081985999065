import fetchPhones from './fetchPhones';
import fetchPhone from './fetchPhone';
import createPhone from './createPhone';
import updatePhone from './updatePhone';
import updatePhones from './updatePhones';
import updatePhoneStatus from './updatePhoneStatus';

export default [
  fetchPhones,
  fetchPhone,
  createPhone,
  updatePhone,
  updatePhones,
  updatePhoneStatus,
];
