import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/styles/makeStyles';
import FlipIcon from '@material-ui/icons/FlipCameraAndroidOutlined';

import { eventsActions } from 'common/ducks';
import { useProcedureLocked } from 'common/hooks';
import { ecgEventStatus } from 'common/constants/enums';
import { classifyOptions } from 'common/constants/ecgEnums';
import { clearUndoState } from 'common/modules/Undo/ducks';
import ModalActionButton from 'common/components/buttons/ModalActionButton';
import OptionSelector from './OptionSelector';
import { useNotify } from '../../Notification/hooks';

const useStyles = makeStyles(({ spacing, palette, breakpoints }) => ({
  classifyInput: {
    backgroundColor: palette.background.primary,
  },
  selectAll: {
    width: 100,
    whiteSpace: 'nowrap',
    [breakpoints.down(1367)]: {
      width: 75,
    },
  },

  item: {
    [breakpoints.down(1367)]: {
      padding: `${spacing(0.3)}px !important`,
    },
  },
}));

const GridActions = ({
  onClose,
  onSuccess,
  selectAll,
  allSelected,
  buttonClasses,
  disabledSelect,
  selectedEvents,
  handleUpdateData,
  handleInvertEvents,
}) => {
  const notify = useNotify();
  const classes = useStyles();
  const dispatch = useDispatch();
  const locked = useProcedureLocked();
  const [classification, setClassification] = useState(null);

  const handleSuccess =
    (message = 'Events have been successfully updated') =>
    () => {
      if (onSuccess) {
        onSuccess();
      }

      if (onClose) {
        onClose();
      }

      setClassification(null);

      notify.success({
        message,
        undoable: true,
      });
    };

  const updateEvent = (status) => {
    if (handleUpdateData) {
      handleUpdateData(status, classification || undefined, () =>
        setClassification(null)
      );
      return;
    }

    // clear undo state before new action to avoid conflicts
    dispatch(clearUndoState());

    const evs = selectedEvents.map((evt) => {
      const event = typeof evt === 'string' ? { id: evt } : evt;

      return {
        status,
        id: event.id,
        abnormalityType: classification || event.abnormalityType,
      };
    });

    dispatch(
      eventsActions.updateEcgEvents(evs, selectedEvents, handleSuccess())
    );
  };

  const toggleInvert = () => {
    if (handleInvertEvents) {
      handleInvertEvents(handleSuccess('Events inverted successfully'));

      return;
    }

    const ids = selectedEvents
      .map((evt) => (typeof evt === 'string' ? evt : evt?.id))
      .filter(Boolean);

    const procedureId = selectedEvents[0]?.procedureId;
    dispatch(
      eventsActions.setInvertEcg(
        { ids, procedureId },
        handleSuccess('Events inverted successfully')
      )
    );
  };

  const buttonDisabled = !selectedEvents?.length || locked;

  return (
    <Grid container spacing={1} alignItems="center" wrap="nowrap">
      {selectAll && (
        <Grid item className={classes.item}>
          <ModalActionButton
            size="small"
            onClick={selectAll}
            classes={buttonClasses}
            disabled={disabledSelect}
            className={classes.selectAll}
          >
            {allSelected ? 'Deselect All' : 'Select All'}
          </ModalActionButton>
        </Grid>
      )}

      <Grid item className={classes.item}>
        <ModalActionButton
          size="small"
          classes={buttonClasses}
          disabled={buttonDisabled}
          onClick={() => updateEvent(ecgEventStatus.accepted)}
        >
          Accept
        </ModalActionButton>
      </Grid>

      <Grid item className={classes.item}>
        <ModalActionButton
          size="small"
          classes={buttonClasses}
          disabled={buttonDisabled}
          onClick={() => updateEvent(ecgEventStatus.included)}
        >
          Include
        </ModalActionButton>
      </Grid>

      <Grid item className={classes.item}>
        <OptionSelector
          name="type-select"
          onChange={setClassification}
          options={classifyOptions}
          disabled={buttonDisabled}
          value={classification || ''}
          label={!classification ? 'Classify' : null}
        />
      </Grid>

      <Grid item className={classes.item}>
        <ModalActionButton
          size="small"
          classes={buttonClasses}
          disabled={buttonDisabled}
          onClick={() => updateEvent(ecgEventStatus.denied)}
        >
          Deny
        </ModalActionButton>
      </Grid>

      <Grid item className={classes.item}>
        <ModalActionButton
          size="small"
          disabled={buttonDisabled}
          onClick={toggleInvert}
          endIcon={<FlipIcon />}
          classes={buttonClasses}
        >
          Invert
        </ModalActionButton>
      </Grid>

      {onClose && (
        <Grid item className={classes.item}>
          <ModalActionButton onClick={onClose}>Close / Exit</ModalActionButton>
        </Grid>
      )}
    </Grid>
  );
};

GridActions.propTypes = {
  onClose: PropTypes.func,
  selectAll: PropTypes.func,
  onSuccess: PropTypes.func,
  allSelected: PropTypes.bool,
  disabledSelect: PropTypes.bool,
  buttonClasses: PropTypes.shape({
    root: PropTypes.string,
    buttonDisabled: PropTypes.string,
  }),
  handleUpdateData: PropTypes.func,
  handleInvertEvents: PropTypes.func,
  selectedEvents: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object])
  ),
};

export default GridActions;
