import React, { useMemo } from 'react';
import clsx from 'classnames';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import makeStyles from '@material-ui/styles/makeStyles';

import paths from 'common/constants/path';
import useRedirect from 'common/hooks/useRedirect';
import { formatPath } from 'common/utils/helpers/url';
import { useNotify } from 'common/modules/Notification/hooks';
import ModalActionButton from 'common/components/buttons/ModalActionButton';
import { createPatient } from '../ducks/reducer';
import PatientForm from './PatientForm';

const useStyles = makeStyles(({ spacing }) => ({
  container: {
    padding: spacing(1),
  },
  modalViewContainer: {
    padding: 0,
  },
  formWrapper: {
    minWidth: 500,
  },
  closeButton: {
    padding: spacing(0.5, 2.5),
  },
}));

const CreatePatient = ({
  onCreateSuccess = () => null,
  physician = {},
  onClose = null,
}) => {
  const notify = useNotify();
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const redirect = useRedirect('replace');

  const physicianId = useMemo(
    () => physician?.id || location?.state?.physicianId,
    [location?.state?.physicianId, physician?.id]
  );

  const onSuccess = (data) => {
    notify.success('Patient has been created!');
    onCreateSuccess(data);

    if (onClose) {
      return onClose();
    }

    const pathToEditPatient = formatPath(paths.private.patients.edit, {
      id: data?.payload?.data?.id,
    });

    return redirect(location.state.redirect || pathToEditPatient);
  };

  const onError = () => {
    notify.error('Creating patient failed');
  };

  const onCreatePatient = (data) => {
    dispatch(createPatient({ ...data }, onSuccess, onError));
  };

  const closeCreatePatientModal = onClose && (
    <ModalActionButton
      size="large"
      onClick={onClose}
      className={classes.closeButton}
    >
      Close
    </ModalActionButton>
  );

  return (
    <Grid
      container
      justifyContent="flex-start"
      direction="column"
      className={clsx(classes.container, {
        [classes.modalViewContainer]: !!onClose,
      })}
    >
      <Grid item className={classes.formWrapper}>
        <PatientForm
          title="Create Patient"
          onSubmit={onCreatePatient}
          defaultValues={{ physicianId }}
          rightContent={closeCreatePatientModal}
        />
      </Grid>
    </Grid>
  );
};

CreatePatient.propTypes = {
  onClose: PropTypes.func,
  physician: PropTypes.shape(),
  onCreateSuccess: PropTypes.func,
};

export default CreatePatient;
