import createEnum from '../utils/actionTypesHelper/createEnum';

const actions = createEnum({
  lockPatients: 'LockPatients',
  unlockPatients: 'ReleasePatients',
  toAll: 'SubscribeToAllProcedureEvents',
  fromAll: 'UnsubscribeFromAllProcedureEvents',
  toEvents: 'SubscribeToProcedureEvents',
  fromEvents: 'UnsubscribeFromProcedureEvents',
  toEcg: 'SubscribeToEcg',
  fromEcg: 'UnsubscribeFromEcg',
  toAllEcg: 'SubscribeToAllEcg',
  fromAllEcg: 'UnsubscribeFromAllEcg',
});

const entities = createEnum({
  ecg: null,
  events: null,
  patients: null,
  procedure: null,
  procedureEcg: null,
});

const subscriptions = createEnum({
  [entities.events]: {
    subscribe: actions.toAll,
    unsubscribe: actions.fromAll,
  },
  [entities.patients]: {
    subscribe: actions.lockPatients,
    unsubscribe: actions.unlockPatients,
  },
  [entities.procedure]: {
    subscribe: actions.toEvents,
    unsubscribe: actions.fromEvents,
  },
  [entities.procedureEcg]: {
    subscribe: actions.toEcg,
    unsubscribe: actions.fromEcg,
  },
  [entities.ecg]: {
    subscribe: actions.toAllEcg,
    unsubscribe: actions.fromAllEcg,
  },
});

const eventOperationEnum = createEnum({
  added: 'Added',
  modified: 'Modified',
  removed: 'Removed',
});

const messageType = createEnum({
  PatientEvent: null,
  EcgEventGroup: null,
  Report: null,
  EcgHealthData: null,
});

export { entities, subscriptions, eventOperationEnum, messageType };
