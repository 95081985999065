import React, { useMemo, useEffect, useState, useCallback } from 'react';
import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import { useController } from 'react-hook-form';

import dataProvider from 'store/dataProvider';
import AutocompleteInput from '../../simpleInputs/AutocompleteInput';

const getCurrentValue = (options, value, optionValueName) => {
  if (typeof value === 'string') {
    return options.find(
      (opt) => opt[optionValueName] === value || opt === value
    );
  }

  return value;
};

const Autocomplete = ({
  form,
  resource,
  searchParams: defaultSearchParams,
  filterFieldName,
  optionLabel,
  optionValueName,
  getOptionValue,
  getOptionLabel,
  label,
  name,
  rules,
  control,
  value: defaultValue,
  resourceForOne,
  multiple,
  queryParams,
  FormHelperTextProps,
  ...restProps
}) => {
  const [data, setData] = useState([]);

  const { field } = useController({
    name,
    rules,
    control,
    defaultValue,
  });

  const helperText = useMemo(
    () => get(form?.errors, field.name)?.message || '',
    [form, field.name]
  );

  const error = useMemo(() => Boolean(helperText), [helperText]);

  const value = useMemo(() => {
    let resultValue = defaultValue || field.value;
    if (multiple && typeof resultValue === 'string' && resultValue.length > 0) {
      resultValue = resultValue.split(',');
    }
    return resultValue;
  }, [defaultValue, field.value, multiple]);

  const fetchData = useCallback(
    async (filterVal) => {
      try {
        const defaultSearchStr = Object.keys(defaultSearchParams).reduce(
          (acc, curr, idx) => {
            const result = `${curr}=${defaultSearchParams[curr]}`;
            return acc + (idx > 0 ? '&' : '') + result;
          },
          ''
        );

        let searchParamStr = '';

        if (filterVal) {
          searchParamStr += `${filterFieldName}=${encodeURIComponent(
            filterVal
          )}`;
        }

        const otherParamsStr =
          Object.keys(queryParams)
            .map((key) => `${key}=${queryParams[key]}`)
            .join('&') || '';

        const query = [defaultSearchStr, searchParamStr, otherParamsStr]
          .filter(Boolean)
          .join('&');

        const url = `${resource}${query ? `?${query}` : ''}`;

        const response = await dataProvider.getList(url, {});
        const { data: responseData } = response;

        setData(responseData || []);
      } catch (err) {
        console.error(err);
      }
    },
    [defaultSearchParams, filterFieldName, queryParams, resource]
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const currentOptions = useMemo(() => {
    if (!value) {
      return data;
    }

    const valueIdentifier =
      typeof value === 'string' ? value : value?.[optionValueName];
    const exist = data.find(
      (d) => d[optionValueName] === valueIdentifier || d === valueIdentifier
    );

    return uniqBy(
      exist || !valueIdentifier ? data : [value, ...data],
      optionValueName
    );
  }, [data, optionValueName, value]);

  const fetchValue = useCallback(
    async (id) => {
      if (!resourceForOne || !id) {
        return;
      }

      try {
        const response = await dataProvider.getOne(resourceForOne, { id });

        setData((d) => (response.data ? [...d, response.data] : d));
      } catch (err) {
        console.error(err);
      }
    },
    [resourceForOne]
  );

  const initLocalValue = useCallback(() => {
    if (value) {
      const identifier =
        typeof value === 'string' ? value : value[optionValueName];
      const option = data.find((opt) => opt[optionValueName] === identifier);

      if (!option) {
        fetchValue(identifier);
      }
    }
  }, [data, fetchValue, optionValueName, value]);

  const handleChange = (e, option) => {
    const newEvent = e;
    const optionValue =
      option && typeof option === 'object' && getOptionValue
        ? getOptionValue(option)
        : option;
    newEvent.target = { ...newEvent.target, value: optionValue };
    field.onChange(newEvent, optionValue);
  };

  const currentValue = useMemo(() => {
    if (Array.isArray(value) && value.length) {
      if (typeof value[0] === 'string') {
        return value.map((v) =>
          getCurrentValue(currentOptions, v, optionValueName)
        );
      }

      return value;
    }
    return getCurrentValue(currentOptions, value, optionValueName);
  }, [currentOptions, optionValueName, value]);

  const changeInputValue = (e, inputValue) => {
    if (e?.type === 'change') {
      fetchData(inputValue);
    }
  };

  useEffect(() => {
    initLocalValue();
  }, [initLocalValue]);

  const handleInputChange = debounce(changeInputValue, 300);

  return (
    <AutocompleteInput
      {...restProps}
      label={label}
      name={name}
      value={currentValue}
      variant="outlined"
      options={currentOptions}
      optionLabel={optionLabel}
      getOptionLabel={getOptionLabel}
      optionValueName={optionValueName}
      multiple={multiple}
      helperText={helperText}
      onChange={handleChange}
      onInputChange={handleInputChange}
      error={error || undefined}
      FormHelperTextProps={FormHelperTextProps}
      inputProps={{
        autoComplete: 'disabled',
      }}
    />
  );
};

Autocomplete.defaultProps = {
  filterFieldName: 'FilterValue',
  searchParams: {},
  name: '',
  value: null,
  multiple: false,
  queryParams: {},
  disableClearable: true,
};

Autocomplete.propTypes = {
  form: PropTypes.shape(),
  disableClearable: PropTypes.bool,
  resource: PropTypes.string.isRequired,
  resourceForOne: PropTypes.string,
  label: PropTypes.string,
  optionLabel: PropTypes.string,
  filterFieldName: PropTypes.string,
  optionValueName: PropTypes.string,
  getOptionValue: PropTypes.func,
  getOptionLabel: PropTypes.func,
  name: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.shape(),
  ]),
  multiple: PropTypes.bool,
  queryParams: PropTypes.shape(),
  rules: PropTypes.objectOf(PropTypes.any),
  options: PropTypes.arrayOf(PropTypes.any),
  control: PropTypes.objectOf(PropTypes.any).isRequired,
  FormHelperTextProps: PropTypes.objectOf(PropTypes.any),
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  searchParams: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default Autocomplete;
