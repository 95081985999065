import createEnum from 'common/utils/actionTypesHelper/createEnum';

export const abnormalityTypeEnum = createEnum({
  afib: 'Afib',
  pause: 'Pause',
  vTach: 'VTach',
  tachy: 'Tachy',
  brady: 'Brady',
  vRun: 'VRun',
  other: 'Other',
  vPair: 'VPair',
  ve: 'Ve',
  veBigeminy: 'VeBigeminy',
  veTrigeminy: 'VeTrigeminy',
  svRun: 'SvRun',
  svPair: 'SvPair',
  sve: 'Sve',
  sveBigeminy: 'SveBigeminy',
  sveTrigeminy: 'SveTrigeminy',
  svTach: 'SvTach',
  NSR: null,
  block: 'Block',
  patient: 'PatientEvent',
  // todo pay attention that it is used for column name only
  vTachSvTach: null,
  blockOtherPe: null,
  activities: null,
  temperatures: null,
  respiration: null,
  oxygenSaturation: null,
  bodyPositions: null,
  // fake event type for displaying all events
  all: null,
  veTemplates: null,
  sveTemplates: null,
  default: null,
  hr: null,
  lowestHR: 'LowestHR',
  highestHR: 'HighestHR',
  noiseArtifact: 'Noise',
  includedHours: 'Full Disclosure',
});

export const ecgTypeLabels = createEnum({
  [abnormalityTypeEnum.afib]: 'AFIB',
  [abnormalityTypeEnum.brady]: 'BRADY',
  [abnormalityTypeEnum.tachy]: 'TACHY',
  [abnormalityTypeEnum.vPair]: 'VE-PAIR',
  [abnormalityTypeEnum.veBigeminy]: 'VE-BIGEMINY',
  [abnormalityTypeEnum.veTrigeminy]: 'VE-TRIGEMINY',
  [abnormalityTypeEnum.ve]: 'VE',
  [abnormalityTypeEnum.other]: 'OTHER',
  [abnormalityTypeEnum.svRun]: 'SV-RUN',
  [abnormalityTypeEnum.svPair]: 'SVE-PAIR',
  [abnormalityTypeEnum.sveBigeminy]: 'SVE-BIGEMINY',
  [abnormalityTypeEnum.sveTrigeminy]: 'SVE-TRIGEMINY',
  [abnormalityTypeEnum.sve]: 'SVE',
  [abnormalityTypeEnum.pause]: 'PAUSE',
  [abnormalityTypeEnum.vRun]: 'V-RUN',
  [abnormalityTypeEnum.svTach]: 'SV-TACH',
  [abnormalityTypeEnum.vTach]: 'V-TACH',
  [abnormalityTypeEnum.vTachSvTach]: 'V-TACH/SV-TACH',
  [abnormalityTypeEnum.block]: 'BLOCK',
  [abnormalityTypeEnum.activities]: 'Activity',
  [abnormalityTypeEnum.respiration]: 'Resp',
  [abnormalityTypeEnum.temperatures]: 'Temp',
  [abnormalityTypeEnum.oxygenSaturation]: 'SpO2',
  [abnormalityTypeEnum.NSR]: 'NSR',
  [abnormalityTypeEnum.patient]: 'PATIENT',
  [abnormalityTypeEnum.blockOtherPe]: 'OTHER/BLOCK/PE',
  [abnormalityTypeEnum.all]: 'ALL',
  [abnormalityTypeEnum.veTemplates]: 'VE-TEMPLATES',
  [abnormalityTypeEnum.sveTemplates]: 'SVE-TEMPLATES',
  [abnormalityTypeEnum.hr]: 'Daily High/Low Heart Rate',
  [abnormalityTypeEnum.lowestHR]: 'Daily Low HR',
  [abnormalityTypeEnum.highestHR]: 'Daily High HR',
  [abnormalityTypeEnum.noiseArtifact]: 'NOISE/ARTIFACT',
  [abnormalityTypeEnum.includedHours]: 'Full Disclosure',
  default: null,
});

export const ecgTypeNames = createEnum({
  [abnormalityTypeEnum.afib]: 'Afib',
  [abnormalityTypeEnum.brady]: 'Brady',
  [abnormalityTypeEnum.tachy]: 'Tachy',
  [abnormalityTypeEnum.vPair]: 'VePair',
  [abnormalityTypeEnum.veBigeminy]: 'VeBigeminy',
  [abnormalityTypeEnum.veTrigeminy]: 'VeTrigeminy',
  [abnormalityTypeEnum.ve]: 'Ve',
  [abnormalityTypeEnum.other]: 'Other',
  [abnormalityTypeEnum.svRun]: 'SvRun',
  [abnormalityTypeEnum.svPair]: 'SvePair',
  [abnormalityTypeEnum.sveBigeminy]: 'SveBigeminy',
  [abnormalityTypeEnum.sveTrigeminy]: 'SveTrigeminy',
  [abnormalityTypeEnum.sve]: 'Sve',
  [abnormalityTypeEnum.pause]: 'Pause',
  [abnormalityTypeEnum.vRun]: 'VRun',
  [abnormalityTypeEnum.svTach]: 'SvTach',
  [abnormalityTypeEnum.vTach]: 'VTach',
  [abnormalityTypeEnum.block]: 'Block',
  [abnormalityTypeEnum.NSR]: 'NSR',
  [abnormalityTypeEnum.noiseArtifact]: 'Noise/Artifact',
  [abnormalityTypeEnum.patient]: 'PE',
  [abnormalityTypeEnum.includedHours]: 'Full Disclosure',
});

const nonClassifyOptions = [
  abnormalityTypeEnum.NSR,
  abnormalityTypeEnum.patient,
  abnormalityTypeEnum.vTachSvTach,
  abnormalityTypeEnum.blockOtherPe,
  abnormalityTypeEnum.activities,
  abnormalityTypeEnum.temperatures,
  abnormalityTypeEnum.respiration,
  abnormalityTypeEnum.oxygenSaturation,
  abnormalityTypeEnum.all,
  abnormalityTypeEnum.veTemplates,
  abnormalityTypeEnum.sveTemplates,
  abnormalityTypeEnum.lowestHR,
  abnormalityTypeEnum.highestHR,
  abnormalityTypeEnum.hr,
  abnormalityTypeEnum.default,
  abnormalityTypeEnum.noiseArtifact,
  abnormalityTypeEnum.includedHours,
];

export const classifyOptions = ecgTypeLabels.toKeys
  .filter((k) => !nonClassifyOptions.includes(k))
  .map((ecgTypeId) => ({
    label: ecgTypeLabels[ecgTypeId],
    value: ecgTypeId,
  }));

export const ecgTypeColors = createEnum({
  [abnormalityTypeEnum.afib]: '#FFB800',
  [abnormalityTypeEnum.brady]: '#00BFFC',
  [abnormalityTypeEnum.tachy]: '#FF00D6',
  [abnormalityTypeEnum.pause]: '#E76F00',
  [abnormalityTypeEnum.vTach]: '#BE0202',
  [abnormalityTypeEnum.svTach]: '#FF0000',
  [abnormalityTypeEnum.block]: '#979797',
  [abnormalityTypeEnum.other]: '#555555',
  [abnormalityTypeEnum.ve]: '#7DF689',
  [abnormalityTypeEnum.vPair]: '#23B932',
  [abnormalityTypeEnum.vRun]: '#32CF42',
  [abnormalityTypeEnum.sve]: '#23B932',
  [abnormalityTypeEnum.svPair]: '#5BFFE3',
  [abnormalityTypeEnum.svRun]: '#00BCC8',
  [abnormalityTypeEnum.veBigeminy]: '#0075FF',
  [abnormalityTypeEnum.sveBigeminy]: '#0d7bff',
  [abnormalityTypeEnum.veTrigeminy]: '#0075FF',
  [abnormalityTypeEnum.sveTrigeminy]: '#0266dc',
  [abnormalityTypeEnum.NSR]: '#0075FF',

  [abnormalityTypeEnum.activities]: '#0075FF',
  [abnormalityTypeEnum.respiration]: '#0075FF',
  [abnormalityTypeEnum.temperatures]: '#0075FF',
  [abnormalityTypeEnum.oxygenSaturation]: '#0075FF',

  [abnormalityTypeEnum.patient]: '#ff0000',
  default: '#ffffff',
});

export const trendEventsByAxis = createEnum({
  [abnormalityTypeEnum.afib]: '10',
  [abnormalityTypeEnum.tachy]: '0',
  [abnormalityTypeEnum.brady]: '-10',
  [abnormalityTypeEnum.temperatures]: '220',
  [abnormalityTypeEnum.activities]: '230',
});

export const eventLinesEnum = {
  [trendEventsByAxis[abnormalityTypeEnum.afib]]: {
    ecg: true,
    name: abnormalityTypeEnum.afib,
    label: ecgTypeLabels[abnormalityTypeEnum.afib],
    color: ecgTypeColors[abnormalityTypeEnum.afib],
  },
  [trendEventsByAxis[abnormalityTypeEnum.tachy]]: {
    ecg: true,
    name: abnormalityTypeEnum.tachy,
    label: ecgTypeLabels[abnormalityTypeEnum.tachy],
    color: ecgTypeColors[abnormalityTypeEnum.tachy],
  },
  [trendEventsByAxis[abnormalityTypeEnum.brady]]: {
    ecg: true,
    name: abnormalityTypeEnum.brady,
    label: ecgTypeLabels[abnormalityTypeEnum.brady],
    color: ecgTypeColors[abnormalityTypeEnum.brady],
  },
  [trendEventsByAxis[abnormalityTypeEnum.temperatures]]: {
    name: abnormalityTypeEnum.temperatures,
    label: ecgTypeLabels[abnormalityTypeEnum.temperatures],
    pointColor: '#0075FF',
  },
  [trendEventsByAxis[abnormalityTypeEnum.activities]]: {
    name: abnormalityTypeEnum.activities,
    label: ecgTypeLabels[abnormalityTypeEnum.activities],
    pointColor: '#0075FF',
  },
};

const measurementUnits = createEnum({
  bpm: null,
  brmp: null,
  percent: '%',
  fahrenheit: 'f',
  activities: {
    6: 'Middle',
    11: 'High',
  },
});

export const eventsMeasurementUnits = createEnum({
  ecg: measurementUnits.bpm,
  [abnormalityTypeEnum.activities]: measurementUnits.activities,
  [abnormalityTypeEnum.respiration]: measurementUnits.percent,
  [abnormalityTypeEnum.temperatures]: measurementUnits.fahrenheit,
  [abnormalityTypeEnum.oxygenSaturation]: measurementUnits.percent,
});

export const escalatedTriageAbnormalities = [
  abnormalityTypeEnum.afib,
  abnormalityTypeEnum.pause,
  abnormalityTypeEnum.vTach,
  abnormalityTypeEnum.svTach,
  abnormalityTypeEnum.tachy,
  abnormalityTypeEnum.brady,
  abnormalityTypeEnum.block,
  abnormalityTypeEnum.vRun,
  abnormalityTypeEnum.svRun,
  abnormalityTypeEnum.other,
  abnormalityTypeEnum.patient,
];

export const commonTriageAbnormalities = [
  abnormalityTypeEnum.ve,
  abnormalityTypeEnum.vPair,
  abnormalityTypeEnum.veBigeminy,
  abnormalityTypeEnum.veTrigeminy,
  abnormalityTypeEnum.sve,
  abnormalityTypeEnum.svPair,
  abnormalityTypeEnum.sveBigeminy,
  abnormalityTypeEnum.sveTrigeminy,
];

export const graphDurations = createEnum({
  4: null,
  6: null,
  8: null,
  10: null,
  default: 6,
});

export const durationByAbnormalities = createEnum({
  [graphDurations[4]]: [
    abnormalityTypeEnum.ve,
    abnormalityTypeEnum.sve,
    abnormalityTypeEnum.veTemplates,
    abnormalityTypeEnum.sveTemplates,
  ],
  [graphDurations[6]]: [
    abnormalityTypeEnum.patient,
    abnormalityTypeEnum.vPair,
    abnormalityTypeEnum.svPair,
    abnormalityTypeEnum.afib,
    abnormalityTypeEnum.tachy,
    abnormalityTypeEnum.brady,
    abnormalityTypeEnum.vTach,
    abnormalityTypeEnum.svTach,
    abnormalityTypeEnum.block,
    abnormalityTypeEnum.other,
  ],
  [graphDurations[8]]: [],
  [graphDurations[10]]: [
    abnormalityTypeEnum.pause,
    abnormalityTypeEnum.veBigeminy,
    abnormalityTypeEnum.veTrigeminy,
    abnormalityTypeEnum.sveBigeminy,
    abnormalityTypeEnum.sveTrigeminy,
    abnormalityTypeEnum.lowestHR,
    abnormalityTypeEnum.highestHR,
    abnormalityTypeEnum.lowestHR,
    abnormalityTypeEnum.highestHR,
  ],
});

export const beatTypes = createEnum({
  V: null,
  S: null,
  N: null,
  default: 'N',
});

export const dailyHRAbnormalities = [
  abnormalityTypeEnum.lowestHR,
  abnormalityTypeEnum.highestHR,
];

export const amplitudeValues = createEnum({
  standard: 0,
  x2: 164,
  x4: 82,
});

export const amplitudeLabels = createEnum({
  [amplitudeValues.standard]: `Standard (328 Mv)`,
  [amplitudeValues.x2]: `2X (${amplitudeValues.x2} Mv)`,
  [amplitudeValues.x4]: `4X (${amplitudeValues.x4} Mv)`,
});

export const templateTypes = [
  abnormalityTypeEnum.veTemplates,
  abnormalityTypeEnum.sveTemplates,
];

export const templateAbnormalities = [
  abnormalityTypeEnum.ve,
  abnormalityTypeEnum.sve,
];

export const abnormalityAccordingTemplates = {
  [abnormalityTypeEnum.veTemplates]: abnormalityTypeEnum.ve,
  [abnormalityTypeEnum.sveTemplates]: abnormalityTypeEnum.sve,
};

export const templatesAccordingAbnormalities = {
  [abnormalityTypeEnum.ve]: abnormalityTypeEnum.veTemplates,
  [abnormalityTypeEnum.sve]: abnormalityTypeEnum.sveTemplates,
};
