import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import DoubleArrow from 'common/components/icons/DoubleArrow';

const useStyles = makeStyles(({ palette }) => ({
  pageCounter: {
    color: palette.text.light,
  },
}));

const useButtonStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    minWidth: 40,
    padding: spacing(0.5),
  },

  disabled: {
    '&.MuiButton-contained': {
      color: palette.primary.lighter,
      backgroundColor: palette.primary.main,
    },
  },
}));

const Pagination = ({ modal, options, onPageChanged }) => {
  const classes = useStyles();
  const buttonClasses = useButtonStyles();
  const { page, lastPage } = options;
  const isFirstPage = useMemo(() => page === 1, [page]);
  const isLastPage = useMemo(() => page === lastPage, [lastPage, page]);

  const setFirstPage = () => onPageChanged(1);
  const setLastPage = useCallback(
    () => onPageChanged(lastPage),
    [lastPage, onPageChanged]
  );
  const previousPage = () => onPageChanged(page - 1);
  const nextPage = () => onPageChanged(page + 1);

  useEffect(() => {
    onPageChanged(page);
  }, [onPageChanged, page]);

  useEffect(() => {
    if (page > lastPage && lastPage) {
      setLastPage();
    }
  }, [page, lastPage, setLastPage]);

  const iconFontSize = modal ? 'medium' : 'small';
  const textVariant = modal ? undefined : 'body1';

  return (
    <Grid
      item
      container
      wrap="nowrap"
      spacing={1}
      alignItems="center"
      justifyContent="center"
    >
      <Typography
        item
        component={Grid}
        variant={textVariant}
        className={classes.pageCounter}
      >
        Page: {page} of {lastPage}
      </Typography>

      <Grid item>
        <Button
          size="small"
          color="primary"
          variant="contained"
          disabled={!page || isFirstPage}
          onClick={setFirstPage}
          classes={buttonClasses}
        >
          <DoubleArrow rotate={180} fontSize={iconFontSize} />
        </Button>
      </Grid>

      <Grid item>
        <Button
          size="small"
          color="primary"
          variant="contained"
          disabled={!page || isFirstPage}
          onClick={previousPage}
          classes={buttonClasses}
        >
          <ChevronLeftIcon fontSize={iconFontSize} />
        </Button>
      </Grid>

      <Grid item>
        <Button
          size="small"
          color="primary"
          variant="contained"
          disabled={isLastPage}
          onClick={nextPage}
          classes={buttonClasses}
        >
          <ChevronRightIcon fontSize={iconFontSize} />
        </Button>
      </Grid>

      <Grid item>
        <Button
          size="small"
          color="primary"
          variant="contained"
          disabled={isLastPage}
          onClick={setLastPage}
          classes={buttonClasses}
        >
          <DoubleArrow fontSize={iconFontSize} />
        </Button>
      </Grid>
    </Grid>
  );
};

Pagination.defaultProps = {
  options: {},
  modal: false,
  onPageChanged: () => null,
};

Pagination.propTypes = {
  modal: PropTypes.bool,
  options: PropTypes.shape({
    page: PropTypes.number,
    lastPage: PropTypes.number,
  }),
  onPageChanged: PropTypes.func,
};

export default Pagination;
