import React, { useCallback, useEffect, useState, useMemo } from 'react';
import {
  useTable,
  useSortBy,
  useRowSelect,
  useFlexLayout,
  usePagination,
  useResizeColumns,
} from 'react-table';
import clsx from 'classnames';
import PropTypes from 'prop-types';
import MuiTable from '@material-ui/core/Table';
import SvgIcon from '@material-ui/core/SvgIcon';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';

import NoRowsOverlay from './components/NoRowsOverlay';
import TableFilterSet from './TableFilterSet';
import SortIcon from './components/SortIcon';
import Toolbar from './components/Toolbar';
import { addSelectionCol } from './utils';
import Loader from './components/Loader';
import useStyles from './styles';

const defaultColumn = {
  width: 2,
  minWidth: 1,
  maxWidth: 50,
};

const Table = ({
  data,
  total,
  loading,
  columns,
  sortable,
  resizable,
  fetchData,
  topHeight,
  selectable,
  rowsPerPage,
  manualSortBy,
  onRowClick,
  defaultSort,
  onCellClick,
  filterProps,
  toolbarProps,
  updateParams,
  withPagination,
  getRowClassName,
  rowsPerPageOptions,
}) => {
  const [extraHeight, setFilterHeight] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  const styles = useStyles({
    isData: !!data.length,
    isToolbar: !!toolbarProps,
    extraHeight: topHeight + extraHeight,
    toolbarProps,
    selectable,
  });

  const initialSort = useMemo(() => {
    if (defaultSort?.field) {
      return [{ id: defaultSort.field, desc: defaultSort.desc }];
    }

    return [];
  }, [defaultSort?.field, defaultSort?.desc]);

  const initialState = useMemo(
    () => ({
      pageSize: rowsPerPage,
      sortBy: initialSort,
    }),
    [initialSort, rowsPerPage]
  );

  const pluginsModifiers = useCallback(
    (hooks) => {
      if (selectable) {
        addSelectionCol(hooks);
      }
    },
    [selectable]
  );

  const plugins = useMemo(
    () =>
      [
        sortable && useSortBy,
        withPagination && usePagination,
        useFlexLayout,
        selectable && useRowSelect,
        resizable && useResizeColumns,
        pluginsModifiers,
      ].filter(Boolean),
    [withPagination, sortable, resizable, selectable, pluginsModifiers]
  );

  const table = useTable(
    {
      data,
      columns,
      pageCount,
      defaultColumn,
      manualSortBy,
      initialState,
      autoResetPage: false,
      disableMultiSort: true,
      manualPagination: true,
    },
    ...plugins
  );

  const {
    rows,
    state,
    gotoPage,
    prepareRow,
    setPageSize,
    headerGroups,
    getTableProps,
  } = table;

  const sortField = useMemo(() => {
    const [sort] = state.sortBy;

    return sort?.id;
  }, [state.sortBy]);

  const sortOrder = useMemo(() => {
    const [sort] = state.sortBy;

    if (!sort) {
      return null;
    }

    return sort.desc ? 'desc' : 'asc';
  }, [state.sortBy]);

  const tableParams = useMemo(() => {
    const params = {};

    if (withPagination) {
      params.pagination = {
        page: state.pageIndex + 1,
        perPage: state.pageSize,
      };
    }

    if (sortOrder && sortField && manualSortBy) {
      params.sort = { field: sortField, order: sortOrder };
    }

    return params;
  }, [
    sortField,
    sortOrder,
    state.pageIndex,
    state.pageSize,
    manualSortBy,
    withPagination,
  ]);

  const handleRowClick = (event, row) => {
    event.preventDefault();
    event.stopPropagation();

    if (onRowClick) {
      onRowClick(row);
    }
  };

  const handleCellClick = (event, cell) => {
    if (cell?.column?.id === 'selection' || cell?.column?.notClickable) {
      event.stopPropagation();
      return;
    }

    if (onCellClick) {
      onCellClick(cell);

      event.preventDefault();
      event.stopPropagation();
    }
  };

  const onChangePage = (evt, newPage) => {
    gotoPage(newPage);
  };

  const onChangeRowsPerPage = ({ target }) => {
    setPageSize(target?.value);
    setPageCount(Math.ceil(total / (target?.value || 1)));
  };

  useEffect(() => {
    if (updateParams) {
      updateParams(tableParams);
    }

    if (fetchData) {
      fetchData(tableParams);
    }
  }, [tableParams, updateParams, fetchData]);

  useEffect(() => {
    setPageCount(Math.ceil(total / rowsPerPage));
  }, [rowsPerPage, total]);

  return (
    <>
      {filterProps && (
        <TableFilterSet setHeight={setFilterHeight} {...filterProps} />
      )}

      <div className={styles.wrapper}>
        <div className={styles.tableContainer}>
          {loading && <Loader {...toolbarProps} selectable={selectable} />}
          {!loading && !data.length && <NoRowsOverlay />}

          {toolbarProps && (
            <Toolbar {...toolbarProps} selectable={selectable} table={table} />
          )}

          <MuiTable {...getTableProps()} className={styles.table}>
            <TableHead className={styles.tableHeader}>
              {headerGroups.map((headerGroup) => {
                const headerProps = headerGroup.getHeaderGroupProps();
                return (
                  <TableRow
                    key={headerProps.key}
                    {...headerProps}
                    className={styles.tableHeaderRow}
                  >
                    {headerGroup.headers.map((column) => {
                      const sortByProps = sortable
                        ? column.getSortByToggleProps()
                        : {};
                      const colProps = column.getHeaderProps();
                      const resizeProps =
                        resizable && column.canResize
                          ? column.getResizerProps()
                          : {};

                      const headerContent = column.render('Header');
                      const title =
                        typeof headerContent === 'string' ? headerContent : '';

                      return (
                        <TableCell
                          key={colProps.key}
                          {...colProps}
                          style={{ ...colProps.style, ...(column.style || {}) }}
                          className={styles.tableHeaderCell}
                        >
                          <div
                            style={colProps.style}
                            className={styles.headerCellContent}
                            {...sortByProps}
                            title={title}
                          >
                            {headerContent}

                            {column.isSorted && (
                              <div
                                className={styles.sortMark}
                                title={sortByProps.title}
                              >
                                <SortIcon {...column} />
                              </div>
                            )}
                          </div>

                          <div
                            role="separator"
                            className={styles.resizer}
                            {...resizeProps}
                          >
                            <SvgIcon
                              height="100%"
                              width={3}
                              viewBox="0 0 10 16"
                            >
                              <path d="M11 19V5h2v14z" />
                            </SvgIcon>
                          </div>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableHead>

            <TableBody className={styles.tableBody}>
              {!!data.length &&
                rows.map((row) => {
                  prepareRow(row);
                  const rowProps = row.getRowProps();

                  return (
                    <TableRow
                      key={rowProps.key}
                      {...rowProps}
                      onClick={(e) => handleRowClick(e, row)}
                      className={clsx(styles.tableRow, getRowClassName(row))}
                    >
                      {row.cells.map((cell) => {
                        const cellProps = cell.getCellProps();
                        const value = cell.column.valueGetter
                          ? cell.column.valueGetter(cell)
                          : cell.value;

                        const isSelect = cell.column.id === 'selection';

                        return (
                          <TableCell
                            key={cellProps.key}
                            {...cellProps}
                            onClick={(e) => handleCellClick(e, cell)}
                            className={styles.tableCell}
                          >
                            {isSelect ? (
                              cell.render('Cell', { value })
                            ) : (
                              <Typography
                                component="div"
                                variant="body1"
                                title={value}
                              >
                                {cell.render('Cell', { value })}
                              </Typography>
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>

            {withPagination && (
              <TableFooter className={styles.tableFooter}>
                <TableRow style={{ borderBottom: 'none' }}>
                  <TablePagination
                    className={styles.pagination}
                    page={state.pageIndex}
                    count={total}
                    rowsPerPage={state.pageSize}
                    onPageChange={onChangePage}
                    rowsPerPageOptions={rowsPerPageOptions}
                    onRowsPerPageChange={onChangeRowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            )}
          </MuiTable>
        </div>
      </div>
    </>
  );
};

const defaultRowsPerPageOptions = [25, 50, 100];

Table.defaultProps = {
  data: [],
  total: 0,
  loading: false,
  topHeight: 0,
  resizable: false,
  fetchData: null,
  updateParams: null,
  sortable: true,
  selectable: true,
  manualSortBy: true,
  rowsPerPage: 100,
  toolbarProps: null,
  withPagination: true,
  getRowClassName: () => undefined,
  rowsPerPageOptions: defaultRowsPerPageOptions,
};

Table.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()),
  total: PropTypes.number,
  loading: PropTypes.bool,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  sortable: PropTypes.bool,
  topHeight: PropTypes.number,
  resizable: PropTypes.bool,
  fetchData: PropTypes.func,
  selectable: PropTypes.bool,
  onRowClick: PropTypes.func,
  onCellClick: PropTypes.func,
  rowsPerPage: PropTypes.number,
  defaultSort: PropTypes.shape({
    field: PropTypes.string,
    desc: PropTypes.bool,
  }),
  filterProps: PropTypes.shape(),
  manualSortBy: PropTypes.bool,
  updateParams: PropTypes.func,
  toolbarProps: PropTypes.shape({
    title: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    bulkActions: PropTypes.arrayOf(PropTypes.any),
    tableActions: PropTypes.arrayOf(PropTypes.any),
  }),
  withPagination: PropTypes.bool,
  getRowClassName: PropTypes.func,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
};

export default Table;
