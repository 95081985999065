import { createSelector } from 'reselect';

export const getEscalatedState = (store) => store.escalatedReport;

export const escalatedReport = (store) => store.escalatedReport.reports;

export const getEscalatedReportEcg = (store) => store.escalatedReport.ecg;

export const getEscalatedReportHR = (store) => store.escalatedReport.heartRate;

export const getIsReportModalOpened = createSelector(
  getEscalatedState,
  (state) => state.isEscalatedModal
);

export const getEscalatedReportLoading = (store) =>
  store.escalatedReport.loading;

export const escalatedReportEcgEventLoading = (store) =>
  store.escalatedReport.ecgEventLoading;

export const getEscalatedReportByProcedureId = (id) =>
  createSelector(escalatedReport, (reports) => reports[id]);

export const getEscalatedEcgByEventId = (id, part) =>
  createSelector(getEscalatedReportEcg, ({ [id]: ecg }) => ecg?.[part]);

export const getEscalatedEventHR = (id, part) =>
  createSelector(getEscalatedReportHR, ({ [id]: HRs }) => HRs?.[part]);

export const escalatedReportEventEcgLoading = (id, part) =>
  createSelector(
    escalatedReportEcgEventLoading,
    ({ [id]: loaders }) => loaders?.[part]
  );
