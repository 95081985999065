import moment from 'moment';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';

import { ecgEventStatus } from 'common/constants/enums';
import { monitoringActionTypes } from 'common/ducks/actionTypes';
import { updateEventsBySocket } from 'common/utils/helpers/socket';
import { createRequestActionTypes } from 'common/utils/actionTypesHelper';
import { generateCancellableAction } from 'common/utils/actionHelpers';
import { modifyHRData } from './helpers';

export const actionTypes = createRequestActionTypes(
  [
    'getEcgPeriod',
    'getNonEcgEvents',
    'getPatientEvents',
    'getEcgEventsPeriod',
    'getHeartRatePeriod',
    'getHeartRateSummary',
    'getProcedureSummary',
    'getFiveMinutesGraph',
    'getProcedure',
    'splitByDate',
    'endProcedure',
    'updateNotes',
  ],
  'procedureMonitoring'
);

const setVisibleRangeActionType = 'procedureMonitoring.setVisibleRange';
const clearStateActionType = 'procedureMonitoring.clearState';

const updateEventsBySocketMessage =
  'procedureMonitoring.updateEventsBySocketMessage';
const updatePatientEventsBySocketMessage =
  'procedureMonitoring.updatePatientEventsBySocketMessage';

export const procedureTrendInitialState = {
  ecg: {},
  ecgDataKeys: [],
  ecgEvents: [],
  patientEvents: [],
  nonEcgEvents: {},
  heartRate: {},
  heartRateSegmentKeys: [],
  heartRateSummary: {},
  ecgEventsSummary: [],
  fiveMinutesGraphData: null,
  visibleRange: null,
  graphSelection: null,
  allDataLoaded: false,
  isEscalatedModal: false,
  loading: false,
  error: null,
};

const acceptableStatuses = [ecgEventStatus.accepted, ecgEventStatus.included];

const procedureTrendReducer = (state = procedureTrendInitialState, action) => {
  switch (action.type) {
    case actionTypes.getEcgPeriod.requested:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.getEcgEventsPeriod.requested:
    case actionTypes.getNonEcgEvents.requested:
    case actionTypes.getHeartRatePeriod.requested: {
      const clearData = action.clearOldData
        ? {
            heartRate: {},
            heartRateSegmentKeys: [],
            nonEcgEvents: {},
            patientEvents: [],
          }
        : {};

      return {
        ...state,
        ...clearData,
        loading: true,
      };
    }
    case actionTypes.getFiveMinutesGraph.requested:
      return {
        ...state,
        loading: true,
        fiveMinutesGraphData: {
          ranges: new Array(5).fill(null).reduce((acc, _, index) => {
            const { dateFrom } = action.payload.filter;
            const lineDate = moment(dateFrom)
              .add(index, 'minute')
              .toISOString();

            return {
              ...acc,
              [lineDate]: null,
            };
          }, {}),
        },
      };
    case actionTypes.getEcgPeriod.failed:
    case actionTypes.getEcgEventsPeriod.failed:
    case actionTypes.getHeartRatePeriod.failed:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case actionTypes.getEcgPeriod.success: {
      const newEcgSegment = action.payload.data;
      const [{ time }] = newEcgSegment;
      return {
        ...state,
        ecgDataKeys: [time, ...state.ecgDataKeys],
        ecg: {
          ...state.ecg,
          [time]: newEcgSegment,
        },
      };
    }

    case actionTypes.getHeartRatePeriod.success: {
      const response = action.payload.data;
      const { filter } = action.requestPayload;
      const newHeartRateSegment = modifyHRData(filter, response);

      const [{ time }] = newHeartRateSegment;

      const heartRateSegmentKeys = uniq([time, ...state.heartRateSegmentKeys]);

      return {
        ...state,
        heartRateSegmentKeys,
        heartRate: {
          ...state.heartRate,
          [time]: uniqBy(newHeartRateSegment, 'time'),
        },
        loading: false,
      };
    }
    case actionTypes.getEcgEventsPeriod.success: {
      const newEvents = uniqBy(
        [...state.ecgEvents, ...action.payload.data],
        'id'
      );

      return {
        ...state,
        ecgEvents: newEvents,
      };
    }

    case updateEventsBySocketMessage:
      return {
        ...state,
        ecgEvents: updateEventsBySocket(
          state.ecgEvents,
          action.payload,
          acceptableStatuses
        ),
        fiveMinutesGraphData: {
          ...state.fiveMinutesGraphData,
          events: updateEventsBySocket(
            state.fiveMinutesGraphData?.events || [],
            action.payload,
            acceptableStatuses
          ),
        },
      };

    case actionTypes.getHeartRateSummary.success:
      return {
        ...state,
        heartRateSummary: action.payload.data || {},
      };

    case actionTypes.getProcedureSummary.success:
      return {
        ...state,
        ecgEventsSummary: action.payload.data || {},
      };

    case actionTypes.getNonEcgEvents.success: {
      return {
        ...state,
        loading: false,
        nonEcgEvents: action.payload.data,
      };
    }

    case actionTypes.getFiveMinutesGraph.success:
      return {
        ...state,
        loading: false,
        fiveMinutesGraphData: {
          ...(state.fiveMinutesGraphData || {}),
          ranges: {
            ...(state.fiveMinutesGraphData?.ranges || {}),
            ...action.payload.range,
          },
          ecg: {
            ...(state.fiveMinutesGraphData?.ecg || {}),
            [action.payload.dateFrom]: action.payload.ecg,
          },
          events: [
            ...(state.fiveMinutesGraphData?.events || []),
            ...action.payload.events,
          ],
          patientEvents: [
            ...(state.fiveMinutesGraphData?.patientEvents || []),
            ...action.payload.patientEvents,
          ],
        },
      };

    case actionTypes.getPatientEvents.success: {
      const patientEvents = uniqBy(
        [...state.patientEvents, ...action.payload.data],
        'id'
      );

      return {
        ...state,
        patientEvents,
      };
    }

    case updatePatientEventsBySocketMessage:
      return {
        ...state,
        patientEvents: updateEventsBySocket(
          state.patientEvents,
          action.payload,
          acceptableStatuses
        ),
        fiveMinutesGraphData: {
          ...state.fiveMinutesGraphData,
          patientEvents: updateEventsBySocket(
            state.fiveMinutesGraphData?.patientEvents || [],
            action.payload,
            acceptableStatuses
          ),
        },
      };

    case monitoringActionTypes.setGraphSelection.requested:
      return {
        ...state,
        graphSelection: { ...action.payload },
      };

    case setVisibleRangeActionType:
      if (
        action.payload[0] === state.visibleRange?.[0] &&
        action.payload[1] === state.visibleRange?.[1]
      ) {
        return state;
      }

      return {
        ...state,
        visibleRange: action.payload,
      };

    case clearStateActionType:
      return procedureTrendInitialState;

    default:
      return state;
  }
};

export const getProcedure = (id) => ({
  type: actionTypes.getProcedure.requested,
  payload: { id },
});

export const getEcgPeriod = (payload) => ({
  type: actionTypes.getEcgPeriod.requested,
  payload,
});

export const getHeartRatePeriod = (
  payload,
  clearOldData = false,
  onSuccess = null
) => ({
  type: actionTypes.getHeartRatePeriod.requested,
  payload,
  onSuccess,
  clearOldData,
});

export const getEventsPeriod = (payload, clearOldData = false) => ({
  type: actionTypes.getEcgEventsPeriod.requested,
  payload,
  clearOldData,
});
export const getPatientEventsPeriod = (payload) => ({
  type: actionTypes.getPatientEvents.requested,
  payload,
});

export const getHeartRateSummary = (payload) => ({
  type: actionTypes.getHeartRateSummary.requested,
  payload,
});

export const getNonEcgEvents = (payload, clearOldData) => ({
  type: actionTypes.getNonEcgEvents.requested,
  payload,
  clearOldData,
});

export const getFiveMinutesGraphData = (payload) =>
  generateCancellableAction({
    type: actionTypes.getFiveMinutesGraph.requested,
    payload,
  });

export const updateEvents = (payload) => ({
  type: updateEventsBySocketMessage,
  payload,
});

export const updatePatientEvents = (payload) => ({
  type: updatePatientEventsBySocketMessage,
  payload,
});

export const setVisibleRange = (payload) => ({
  type: setVisibleRangeActionType,
  payload,
});

export const splitByDate = (payload, onSuccess, onError) => ({
  type: actionTypes.splitByDate.requested,
  payload,
  onSuccess,
  onError,
});

export const endProcedure = (payload, onSuccess, onError) => ({
  type: actionTypes.endProcedure.requested,
  payload,
  onSuccess,
  onError,
});

export const updateProcedureNotes = (payload, onSuccess, onError) => ({
  type: actionTypes.updateNotes.requested,
  payload,
  onSuccess,
  onError,
});

export const clearState = () => ({ type: clearStateActionType });

export default procedureTrendReducer;
