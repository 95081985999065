import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useSelector, useDispatch } from 'react-redux';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { mimeTypes } from 'common/constants/enums';
import useNotify from 'common/modules/Notification/hooks/useNotify';
import TextInput from 'common/components/simpleInputs/TextInput';
import LoadingProgress from 'common/components/LoadingProgress';
import Button from 'common/components/buttons/ModalActionButton';
import { getIsUploading, getUploads } from '../ducks/selectors';
import fileTypes from '../constants/fileTypes';
import { attachmentsActions } from '../ducks';
import { getExtension } from '../utils';
import useStyles from '../styles';

const FileUploadModal = ({
  entityType,
  entityId,
  allowedFormats,
  maxFileSize,
  onClose,
  fetchAttachments,
}) => {
  const notify = useNotify();
  const classes = useStyles();
  const dispatch = useDispatch();
  const fileInput = useRef(null);
  const [fileType, setFileType] = useState(fileTypes[0].value);

  const uploads = useSelector(getUploads);
  const isUploading = useSelector(getIsUploading);

  const acceptMimeTypes = allowedFormats.map(
    (format) => mimeTypes[format.toLowerCase()]
  );

  const close = () => {
    if (onClose) {
      onClose();
    }
  };

  const removeFile = (id) => {
    dispatch(attachmentsActions.removeUploaded(id));
    fileInput.current.value = null;
  };

  const onFileTypeChange = (_, value) => {
    setFileType(value);
  };

  const uploadFile = () => {
    fileInput.current.click();
  };

  const onFileUploaded = (e) => {
    const [file] = e.target.files;

    if (file) {
      const ext = file.name && getExtension(file.name);
      const currentMimeType = file.type || (ext && mimeTypes[ext]);
      const isAccepted = acceptMimeTypes.includes(currentMimeType);

      if (acceptMimeTypes.length > 0 && !isAccepted) {
        notify.error('Invalid file format');
        return;
      }
      if (maxFileSize && file.size > maxFileSize * 10 ** 6) {
        notify.error('File is too large');
        return;
      }
      dispatch(attachmentsActions.uploadFile(file));
    }
  };

  const onSuccess = () => {
    notify.success('File has been saved!');

    if (fetchAttachments) {
      fetchAttachments();
    }

    close();
  };

  const onError = () => {
    notify.error('Saving files failed');
  };

  const saveFiles = () => {
    dispatch(
      attachmentsActions.saveFiles(
        {
          files: uploads,
          entityId,
          entityType,
          type: fileType,
        },
        onSuccess,
        onError
      )
    );
  };

  return (
    <Grid container direction="column">
      <DialogTitle>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item component={Typography} variant="h3" color="primary">
            File Uploads
          </Grid>

          <Grid item>
            <CloseIcon onClick={close} />
          </Grid>
        </Grid>

        {isUploading && <LoadingProgress />}
      </DialogTitle>

      <DialogContent>
        <Grid container direction="column" spacing={2}>
          {allowedFormats.length > 0 && (
            <Grid item component={Typography}>
              Possible formats: {allowedFormats.join(', ').toUpperCase()}
            </Grid>
          )}

          {maxFileSize && (
            <Grid item component={Typography}>
              Max File Size: {maxFileSize}MB
            </Grid>
          )}
        </Grid>
      </DialogContent>

      <DialogContent>
        {uploads.length > 0 && (
          <Grid container direction="column" className={classes.filesWrapper}>
            {uploads.map((file) => (
              <div key={file.id} className={classes.uploadWrapper}>
                <Typography>{`${file.name}.${file.extension}`}</Typography>

                <IconButton onClick={() => removeFile(file.id)}>
                  <CloseIcon />
                </IconButton>
              </div>
            ))}
          </Grid>
        )}
      </DialogContent>

      <DialogContent>
        <TextInput
          select
          fullWidth
          name="file"
          size="small"
          value={fileType}
          options={fileTypes}
          onChange={onFileTypeChange}
        />
      </DialogContent>

      <DialogActions>
        <input
          hidden
          type="file"
          ref={fileInput}
          onChange={onFileUploaded}
          accept={acceptMimeTypes.join(',')}
        />

        <Button disabled={isUploading} onClick={uploadFile}>
          Upload
        </Button>

        <Button
          color="primary"
          onClick={saveFiles}
          disabled={uploads.length === 0 || isUploading}
        >
          Save
        </Button>
      </DialogActions>
    </Grid>
  );
};

FileUploadModal.defaultProps = {
  allowedFormats: [
    'doc',
    'docx',
    'pdf',
    'xls',
    'xlsx',
    'txt',
    'jpg',
    'jpeg',
    'png',
  ],
  maxFileSize: 20,
};

FileUploadModal.propTypes = {
  onClose: PropTypes.func,
  entityId: PropTypes.string,
  entityType: PropTypes.string,
  maxFileSize: PropTypes.number,
  fetchAttachments: PropTypes.func,
  allowedFormats: PropTypes.arrayOf(PropTypes.string),
};

export default FileUploadModal;
