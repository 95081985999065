export const BASE_URL = process.env.REACT_APP_API_URL;
export const API_URL = `${BASE_URL}/api`;
export const WS_URL = `${BASE_URL}/ws`;

export const VALIDATE_ACCOUNT = `${API_URL}/Account/ValidateCredentials`;
export const ACCEPT_TERMS_OF_USE = `${API_URL}/Account/AcceptTermsOfUse`;
export const VALIDATE_CODE = `${API_URL}/Account/ValidateCode`;

export const API_AUTHORIZATION_URL = `${API_URL}/Token`;
export const API_FORGOT_PASSWORD_URL = `${API_URL}/Account/forgot-password`;
export const API_RESET_PASSWORD_URL = `${API_URL}/Account/reset-password`;
export const WS_GENERAL = `${WS_URL}/General`;
