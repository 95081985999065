import { useCallback, useEffect, useMemo } from 'react';

import { entities, subscriptions } from 'common/constants/socketConstants';
import SocketProvider from '../utils/providerHelpers/socketProvider';

const useSocketHub = (entity, payload, onMessage, keepAlive = false) => {
  const id = useMemo(() => Symbol('listener'), []);
  const provider = useMemo(() => new SocketProvider(), []);
  const isNotPatientsEntity = useMemo(
    () => entities.patients !== entity,
    [entity]
  );

  const subscribe = useCallback(() => {
    if (!entity) {
      return;
    }

    provider.subscribe({
      payload,
      key: id,
      action: subscriptions[entity].subscribe,
      onMessage: isNotPatientsEntity && onMessage ? onMessage : null,
    });
  }, [entity, provider, payload, id, isNotPatientsEntity, onMessage]);

  const unsubscribe = useCallback(() => {
    if (!entity) {
      return;
    }

    provider.unsubscribe({
      payload,
      key: id,
      action: subscriptions[entity].unsubscribe,
      keepAlive,
    });
  }, [entity, provider, payload, id, keepAlive]);

  useEffect(() => {
    if (provider.isInit) {
      provider.initPromise.then(() => subscribe());
    } else {
      provider.init().then(() => subscribe());
    }

    return () => unsubscribe();
  }, [provider, subscribe, unsubscribe]);
};

export default useSocketHub;
