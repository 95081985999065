import { useCallback, useEffect } from 'react';
import moment from 'moment';
import { pathToRegexp } from 'path-to-regexp';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { stack } from 'common/constants/path';
import useRedirect from 'common/hooks/useRedirect';
import { formatPath } from 'common/utils/helpers/url';
import { getProcedure } from 'common/utils/entitySelectors';
import { closeAllModals } from 'common/ducks/general/actions/modalActions';
import { useNotify } from 'common/modules/Notification/hooks';
import {
  addEventToReportMessage,
  removeEventFromReportMessage,
  addPatientEventToReportMessage,
  removePatientEventFromReportMessage,
} from '../constants';
import {
  getEscalatedReportLoading,
  getEscalatedReportByProcedureId,
} from '../ducks/selectors';
import { escalatedReportActions } from '../ducks';

const useEscalatedReport = (procedureId) => {
  const notify = useNotify();
  const dispatch = useDispatch();
  const redirect = useRedirect();
  const location = useLocation();
  const loading = useSelector(getEscalatedReportLoading);
  const procedure = useSelector(getProcedure(procedureId));
  const report = useSelector(getEscalatedReportByProcedureId(procedureId));

  const fetchReport = useCallback(() => {
    if (report) return;

    dispatch(escalatedReportActions.getReport(procedureId));
  }, [dispatch, procedureId, report]);

  const handleGoToReport = useCallback(() => {
    dispatch(closeAllModals());

    const redirectUrl = stack.private.procedures.monitoring.index.url;
    const regexp = pathToRegexp(redirectUrl);
    const isAlreadyInMonitoring = regexp.test(location.pathname);

    if (!isAlreadyInMonitoring) {
      redirect(formatPath(redirectUrl, { id: procedureId }));
    }

    dispatch(escalatedReportActions.openEscalatedReportModal());
  }, [dispatch, location.pathname, procedureId, redirect]);

  useEffect(() => fetchReport(), [fetchReport]);

  const onAddSuccess = useCallback(() => {
    handleGoToReport();
    notify.success({
      autoHideDuration: 1500,
      message: addEventToReportMessage.success,
    });
  }, [handleGoToReport, notify]);

  const onAddError = useCallback(
    () => notify.error(addEventToReportMessage.error),
    [notify]
  );

  const addEvent = useCallback(
    (eventId, createdReport = null) => {
      dispatch(
        escalatedReportActions.addEventToReport(
          createdReport?.id || report.id,
          eventId,
          procedureId,
          onAddSuccess,
          onAddError
        )
      );
    },
    [dispatch, report, procedureId, onAddSuccess, onAddError]
  );

  const onPEAddSuccess = useCallback(() => {
    handleGoToReport();

    notify.success({
      autoHideDuration: 1500,
      message: addPatientEventToReportMessage.success,
    });
  }, [handleGoToReport, notify]);

  const onPEAddError = useCallback(
    () => notify.error(addPatientEventToReportMessage.error),
    [notify]
  );
  const addPatientEvent = useCallback(
    (patientEventId, createdReport = null) => {
      dispatch(
        escalatedReportActions.addPatientEventToReport(
          createdReport?.id || report.id,
          patientEventId,
          procedureId,
          onPEAddSuccess,
          onPEAddError
        )
      );
    },
    [dispatch, report, procedureId, onPEAddSuccess, onPEAddError]
  );

  const create = useCallback(
    (eventId, patient) => {
      const onSuccess = ({ payload }) => {
        if (patient) {
          addPatientEvent(patient.id, payload.data);
        } else if (eventId) {
          addEvent(eventId, payload.data);
        }
      };

      const current = moment();
      const date = current.isBefore(procedure?.endDate)
        ? current.toISOString()
        : procedure?.endDate;

      return dispatch(
        escalatedReportActions.createReport(procedureId, date, onSuccess)
      );
    },
    [procedure, dispatch, procedureId, addEvent, addPatientEvent]
  );

  const onRemoveSuccess = useCallback(
    () =>
      notify.success({
        autoHideDuration: 1500,
        message: removeEventFromReportMessage.success,
      }),
    [notify]
  );

  const onRemoveError = useCallback(
    () => notify.error(removeEventFromReportMessage.error),
    [notify]
  );

  const removeEvent = useCallback(
    (eventGroupId) => {
      dispatch(
        escalatedReportActions.removeEventFromReport(
          { params: { reportId: report.id, eventGroupId } },
          procedureId,
          onRemoveSuccess,
          onRemoveError
        )
      );
    },
    [dispatch, report, procedureId, onRemoveSuccess, onRemoveError]
  );

  const onPERemoveSuccess = useCallback(
    () =>
      notify.success({
        autoHideDuration: 1500,
        message: removePatientEventFromReportMessage.success,
      }),
    [notify]
  );

  const onPERemoveError = useCallback(
    () => notify.error(removePatientEventFromReportMessage.error),
    [notify]
  );

  const removePatientEvent = useCallback(
    (patientEventId, eventGroupId) => {
      dispatch(
        escalatedReportActions.removePatientEventFromReport(
          { params: { reportId: report.id, patientEventId, eventGroupId } },
          procedureId,
          onPERemoveSuccess,
          onPERemoveError
        )
      );
    },
    [dispatch, report, procedureId, onPERemoveSuccess, onPERemoveError]
  );

  return {
    loading,
    create,
    report,
    addEvent,
    procedure,
    removeEvent,
    addPatientEvent,
    removePatientEvent,
  };
};

export default useEscalatedReport;
