import uniqBy from 'lodash/uniqBy';
import uniq from 'lodash/uniq';
import moment from 'moment';

import { ecgEventStatus } from 'common/constants/enums';
import { updateEventsBySocket } from 'common/utils/helpers/socket';
import { generateCancellableAction } from 'common/utils/actionHelpers';
import { createRequestActionTypes } from 'common/utils/actionTypesHelper';
import { stripsCount } from '../constants';

export const actionTypes = createRequestActionTypes(
  [
    'getEcgPeriod',
    'getEcgEventsPeriod',
    'getPatientEvents',
    'getIncludedDisclosures',
    'includeHour',
    'declineHour',
  ],
  'fullDisclosure'
);

const setEventsBySocket = 'fullDisclosure.updateEventsBySocket';
const setPatientEventsBySocket = 'fullDisclosure.updatePatientEventsBySocket';

const createNullPoint = (time) => ({ time, value: null });
export const modifyHRData = (filter, data = []) => {
  if (!data.length) {
    return [createNullPoint(filter.dateFrom), createNullPoint(filter.dateTo)];
  }
  let newData = [...data];
  const firstItem = data[0];
  const lastItem = data[data.length - 1];

  if (!moment(firstItem.time).isSame(filter.dateFrom)) {
    newData = [createNullPoint(filter.dateFrom), ...data];
  }

  if (!moment(lastItem.time).isSame(filter.dateTo)) {
    newData.push(createNullPoint(filter.dateTo));
  }
  return newData;
};

const acceptableStatuses = [ecgEventStatus.accepted, ecgEventStatus.included];

export const initialState = {
  ecg: {},
  ecgEvents: [],
  ecgDataKeys: [],
  patientEvents: [],
  includeDisclosures: [],
  loading: false,
  error: null,
  dateRange: null,
  tenSecondsRange: null,
};

const setTenSecondsRangeActionType = 'fullDisclosure.setTenSecondsRange';
const clearDisclosureStateActionType = 'fullDisclosure.clearState';
const setDateRangeActionType = 'fullDisclosure.setDateRange';

const fullDisclosureReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.getEcgPeriod.requested:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.getEcgEventsPeriod.requested:
    case actionTypes.getEcgPeriod.failed:
    case actionTypes.getEcgEventsPeriod.failed:
      return {
        ...state,
        error: action.error,
      };

    case actionTypes.getEcgPeriod.success: {
      const { filter } = action.requestPayload || {};
      const newEcgSegment = action.payload.data;
      const ecg = { ...state.ecg };
      ecg[filter.dateFrom] = modifyHRData(filter, newEcgSegment);
      const ecgDataKeys = state.ecgDataKeys.includes(filter.dateFrom)
        ? [...state.ecgDataKeys]
        : [filter.dateFrom, ...state.ecgDataKeys];

      const loadingState =
        ecgDataKeys.length === stripsCount ? { loading: false } : {};

      return {
        ...state,
        ecg,
        ecgDataKeys: uniq(ecgDataKeys),
        ...loadingState,
      };
    }

    case actionTypes.getEcgEventsPeriod.success: {
      return {
        ...state,
        ecgEvents: action.payload.data || [],
      };
    }

    case actionTypes.getPatientEvents.success:
      return {
        ...state,
        patientEvents: action.payload.data || [],
      };

    case actionTypes.getIncludedDisclosures.success:
      return {
        ...state,
        includeDisclosures: action.payload.data || [],
      };

    case actionTypes.includeHour.success: {
      return {
        ...state,
        includeDisclosures: uniqBy(
          [
            ...[action.payload.data.result].flat().filter(Boolean),
            ...state.includeDisclosures,
          ],
          'dateTime'
        ),
      };
    }

    case actionTypes.declineHour.success: {
      const { ids = [] } = action.requestPayload.query || {};

      return {
        ...state,
        includeDisclosures: state.includeDisclosures.filter(
          (d) => !ids.includes(d.id)
        ),
      };
    }

    case setEventsBySocket: {
      return {
        ...state,
        ecgEvents: updateEventsBySocket(
          state.ecgEvents,
          action.payload,
          acceptableStatuses
        ),
      };
    }

    case setPatientEventsBySocket: {
      return {
        ...state,
        patientEvents: updateEventsBySocket(
          state.patientEvents,
          action.payload,
          acceptableStatuses
        ),
      };
    }

    case setDateRangeActionType:
      return {
        ...state,
        dateRange: action.payload,
      };

    case setTenSecondsRangeActionType:
      return {
        ...state,
        tenSecondsRange: {
          ...state.tenSecondsRange,
          ...action.payload,
        },
      };
    case clearDisclosureStateActionType:
      return { ...initialState, includeDisclosures: state.includeDisclosures };
    default:
      return state;
  }
};

export const getEcgPeriod = (payload) =>
  generateCancellableAction({
    type: actionTypes.getEcgPeriod.requested,
    payload,
  });

export const getEventsPeriod = (payload) =>
  generateCancellableAction({
    type: actionTypes.getEcgEventsPeriod.requested,
    payload,
  });

export const getPatientEventsPeriod = (payload) =>
  generateCancellableAction({
    type: actionTypes.getPatientEvents.requested,
    payload,
  });

export const updateEvents = (payload) => ({
  type: setEventsBySocket,
  payload,
});

export const updatePatientEvents = (payload) => ({
  type: setPatientEventsBySocket,
  payload,
});

export const clearState = () => ({ type: clearDisclosureStateActionType });
export const setDateRange = (payload) => ({
  type: setDateRangeActionType,
  payload,
});
export const setTenSecondsRange = (range, event = null) => ({
  type: setTenSecondsRangeActionType,
  payload: { ...(range || {}), event },
});

export const onIncludeHour = (body) => ({
  type: actionTypes.includeHour.requested,
  payload: { body },
});

export const onDeclineHour = (query) => ({
  type: actionTypes.declineHour.requested,
  payload: { query },
});

export const getIncludedDisclosures = (filter) => ({
  type: actionTypes.getIncludedDisclosures.requested,
  payload: { filter },
});

export default fullDisclosureReducer;
