import React, { useEffect, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { getAge } from 'common/utils/helpers/date';
import { commonRoles } from 'common/constants/enums';
import FormBuilder from 'common/components/FormBuilder';
import CardSection from 'common/components/CardSection';
import useAuthProvider from 'store/authProvider/useAuthProvider';
import { normalizeFormDefaultValues } from 'common/utils/helpers/form';
import { formValidationScheme, patientFields } from '../constants';
import { isLoading } from '../ducks/selectors';

const PatientForm = ({
  title,
  onSubmit,
  onDelete,
  editing = false,
  rightContent = null,
  defaultValues = {},
}) => {
  const authProvider = useAuthProvider();
  const loading = useSelector(isLoading);

  const role = useMemo(
    () => authProvider.currentUserRole,
    [authProvider.currentUserRole]
  );

  const normalizedDefaultValues = useMemo(() => {
    const dV = normalizeFormDefaultValues(defaultValues);

    return {
      ...dV,
      hasPacemaker:
        defaultValues.hasPacemaker ||
        typeof defaultValues.hasPacemaker === 'boolean'
          ? Number(defaultValues.hasPacemaker)
          : undefined,
    };
  }, [defaultValues]);

  const { handleSubmit, control, formState, reset, watch } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: normalizedDefaultValues,
    resolver: yupResolver(formValidationScheme),
  });

  const watchBirthDate = watch('birthDate');

  useEffect(() => {
    const { birthDate } = control.getValues();

    if (birthDate) {
      control.setValue('age', getAge(birthDate).toString());
    }
  }, [control, watchBirthDate]);

  const updateDefaultValues = useCallback(() => {
    if (!Object.keys(normalizedDefaultValues).length) {
      return;
    }
    reset(normalizedDefaultValues);
  }, [normalizedDefaultValues, reset]);

  useEffect(() => {
    updateDefaultValues();
  }, [updateDefaultValues]);

  const submitForm = (data) => {
    const {
      age,
      zipCode,
      birthDate,
      hasPacemaker,
      lastProcedureDate,
      ...restData
    } = data;

    onSubmit({
      ...restData,
      birthDate: birthDate || null,
      zipCode: zipCode?.length ? zipCode : null,
      physicianId: defaultValues?.physicianId || null,
      hasPacemaker: hasPacemaker && Boolean(+hasPacemaker),
    });
  };

  return (
    <CardSection
      title={title}
      rightContent={rightContent}
      titleProps={{ style: { width: '100%', justifyContent: 'space-between' } }}
    >
      <FormBuilder
        control={control}
        editing={editing}
        onDelete={role !== commonRoles.physician && onDelete}
        onSubmit={handleSubmit(submitForm)}
        formState={formState}
        fieldsList={patientFields}
        defaultValues={normalizedDefaultValues}
        defaultFieldXs={4}
        buttonsDisabled={loading}
      />
    </CardSection>
  );
};

PatientForm.propTypes = {
  editing: PropTypes.bool,
  title: PropTypes.string,
  onDelete: PropTypes.func,
  rightContent: PropTypes.element,
  onSubmit: PropTypes.func.isRequired,
  defaultValues: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.array,
      PropTypes.bool,
      PropTypes.shape(),
    ])
  ),
};

export default PatientForm;
