import React, { useCallback, useMemo } from 'react';
import Grid from '@material-ui/core/Grid';

import Save from '../buttons/Save';
import Delete from '../buttons/Delete';
import TextField from '../formFields/TextField';

const sanitizeFieldParams = ({ disabledForPhysician, editOnly, ...params }) =>
  params;

const defaultGetProps = (field) => field;

const FormBuilder = ({
  editing = false,
  control,
  onDelete,
  onSubmit,
  formState,
  fieldsList = [],
  disableForm = false,
  defaultValues = {},
  defaultFieldXs = 3,
  buttonsDisabled = false,
  closeModalButton = null,
  additionalButtons = null,
  getAdditionalFieldsProps = defaultGetProps,
}) => {
  const formFields = useMemo(
    () =>
      editing ? fieldsList : fieldsList.filter(({ editOnly }) => !editOnly),
    [fieldsList, editing]
  );

  const renderField = useCallback(
    (fieldParams) => {
      const { component, xs, getXs, ...field } = fieldParams;
      const FieldComponent = component || TextField;
      const { hideField, ...additionalProps } = getAdditionalFieldsProps(field);
      const fieldDisabled = additionalProps.disabled || disableForm;

      const fieldXs = xs || (getXs && getXs(editing)) || defaultFieldXs;

      if (hideField) {
        return null;
      }

      return (
        <Grid
          item
          container
          key={field.name}
          xs={fieldXs}
          style={{ margin: '2px 0' }}
        >
          <FieldComponent
            {...sanitizeFieldParams(field)}
            {...additionalProps}
            fullWidth
            size="small"
            control={control}
            form={formState}
            disabled={fieldDisabled}
          />
        </Grid>
      );
    },
    [
      control,
      disableForm,
      defaultFieldXs,
      editing,
      formState,
      getAdditionalFieldsProps,
    ]
  );

  return (
    <form noValidate onSubmit={onSubmit}>
      <Grid
        container
        spacing={2}
        justifyContent="flex-start"
        style={{ padding: '0 16px' }}
      >
        {formFields.map(renderField)}
      </Grid>

      <Grid
        container
        justifyContent="space-between"
        style={{ padding: 16, paddingTop: 20 }}
      >
        <Grid item container xs spacing={5} wrap="nowrap">
          <Grid item>
            <Save disabled={buttonsDisabled || disableForm} />
          </Grid>

          {additionalButtons}
        </Grid>

        {editing && onDelete && (
          <Grid item>
            <Delete
              onClick={onDelete}
              disabled={buttonsDisabled || disableForm}
              entityData={defaultValues}
            />
          </Grid>
        )}

        {closeModalButton}
      </Grid>
    </form>
  );
};

export default FormBuilder;
