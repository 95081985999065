import makeStyles from '@material-ui/styles/makeStyles';

export const noteInputStyles = makeStyles((theme) => ({
  inputMarginDense: {
    [theme.breakpoints.down(1367)]: {
      paddingTop: theme.spacing(0.25),
      paddingBottom: theme.spacing(0.5),
    },
  },
}));

export const selectStripStyles = makeStyles((theme) => ({
  selectMenu: {
    [theme.breakpoints.down(1367)]: {
      height: 8,
      minHeight: 8,
      fontSize: '0.7rem',
    },
  },
}));

export const eventSymptomsStyles = makeStyles((theme) => ({
  root: {
    '& .MuiFormLabel-root': {
      [theme.breakpoints.down(1367)]: {
        fontSize: '0.65rem',
      },
    },
  },
  input: {
    [theme.breakpoints.down(1367)]: {
      height: 8,
      minHeight: 8,
      fontSize: '0.65rem',
    },
  },
  focused: {
    position: 'absolute',
  },
  selectedSymptomOption: {
    padding: theme.spacing(0.25),
    backgroundColor: theme.palette.background.secondary,
  },
}));

const useStyles = makeStyles(({ spacing, palette }) => ({
  eventStripHeader: {
    padding: spacing(0.625, 2),
    backgroundColor: palette.background.lightenDark,
  },
  eventStripHeaderModal: {
    padding: spacing(2),
  },

  notesContainer: {
    padding: spacing(0, 1),
    width: 'calc(100% - 50px)',
  },

  gridContent: {
    overflow: 'hidden',
    position: 'relative',
  },
  eventSelectorContainer: {
    zIndex: 1,
    minWidth: 150,
    maxWidth: 160,
    minHeight: 23,
    position: 'absolute',
    backgroundColor: '#fff',
    border: '1px solid gray',
  },
  loadingProgress: {
    position: 'absolute',
    width: '100%',
    height: 2,
  },
  noDataContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default useStyles;
