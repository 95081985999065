import { call, put, all, takeEvery } from 'redux-saga/effects';

import {
  abnormalityTypeEnum,
  templatesAccordingAbnormalities,
} from 'common/constants/ecgEnums';
import dataProvider from 'store/dataProvider';
import resources from 'common/constants/resources';
import { actionTypes } from '../index';

const getTemplatesCount = async (abnormality, filter) => {
  const template = templatesAccordingAbnormalities[abnormality];

  const res = await dataProvider.get(resources.eventGroup.templatesCount, {
    filter: { ...filter, abnormalityTypes: [abnormality] },
  });

  return { count: res?.data || 0, type: template };
};

const getPeCount = async (filter) => {
  const res = await dataProvider.get(resources.counter.patientEvents, {
    filter: {
      ...filter,
      status: filter.status || undefined,
      abnormalityTypes: undefined,
    },
  });

  return { count: res?.data || 0, type: abnormalityTypeEnum.patient };
};

function* getTotalsSaga(action) {
  const { filter, isPatient, templatesAbnormalities } = action.payload;
  const isTemplates = !!templatesAbnormalities?.length;

  try {
    const eventsTotal = yield call(
      dataProvider.getList,
      resources.eventGroup.count,
      { filter }
    );

    const { abnormalityType, ...templatesCountFilter } = filter;

    const templatesCount = isTemplates
      ? yield all(
          templatesAbnormalities.map((abType) =>
            getTemplatesCount(abType, templatesCountFilter)
          )
        )
      : [];

    const peCount = isPatient ? yield call(getPeCount, filter) : undefined;

    const eventsCount = [
      ...(eventsTotal.data || []),
      ...templatesCount,
      peCount,
    ].filter(Boolean);

    yield put({
      type: actionTypes.getProcedureEventTotals.success,
      payload: { data: eventsCount },
    });
  } catch ({ message }) {
    yield put({
      type: actionTypes.getProcedureEventTotals.failed,
      payload: message,
    });
  }
}

export default function* getEventTotals() {
  yield takeEvery(actionTypes.getProcedureEventTotals.requested, getTotalsSaga);
}
