import React from 'react';
import PropTypes from 'prop-types';
import { useController } from 'react-hook-form';
import makeStyles from '@material-ui/styles/makeStyles';

import BooleanInput from '../../simpleInputs/BooleanInput';

const sanitizeProps = ({ onInputChange, fullWidth, ...props }) => props;

const useStyles = makeStyles(({ spacing }) => ({
  root: { paddingLeft: spacing(1) },
}));

const BooleanField = ({
  name,
  form,
  control,
  rules,
  defaultValue = false,
  ...rest
}) => {
  const classes = useStyles();
  const props = sanitizeProps(rest);
  const { field } = useController({
    name,
    rules,
    control,
    defaultValue,
  });

  const onChange = (e, value) => {
    field.onChange(value);
  };

  return (
    <BooleanInput
      {...props}
      name={name}
      form={form}
      field={field}
      value={Boolean(field.value)}
      classes={classes}
      onChange={onChange}
    />
  );
};

BooleanField.propTypes = {
  name: PropTypes.string.isRequired,
  rules: PropTypes.objectOf(PropTypes.any),
  form: PropTypes.shape({
    errors: PropTypes.objectOf(PropTypes.any),
    touched: PropTypes.objectOf(PropTypes.any),
  }),
  control: PropTypes.objectOf(PropTypes.any).isRequired,
  defaultValue: PropTypes.bool,
};

export default BooleanField;
