import React from 'react';
import moment from 'moment/moment';
import Grid from '@material-ui/core/Grid';
import DownloadIcon from '@material-ui/icons/CloudDownloadTwoTone';
import PreviewIcon from '@material-ui/icons/ImageSearchTwoTone';
import { createEnum } from 'common/utils/actionTypesHelper';
import UploadButton from 'common/components/formFields/UploadButton';

export const entityId = 'a0507863-a5a5-4c93-83f4-ca1111dda9e6';
export const entityType = 'AppResources';

export const resourceParams = {
  entityId,
  entityType,
};

export const tabs = createEnum({
  tutorials: null,
  firmwares: null,
});

export const resourceTypes = createEnum({
  tutorials: 'Tutorials',
  appFirmware: 'App',
  patchFirmware: 'Patch',
});

export const tabNames = createEnum({
  [tabs.tutorials]: 'Tutorials',
  [tabs.firmwares]: 'Firmwares',
});

export const addFromTitles = createEnum({
  [tabs.tutorials]: 'Tutorial',
  [tabs.firmwares]: 'Firmware',
});

const DATE_FORMAT = 'L LT';
export const getDate = (params) => {
  const { value } = params;
  return value && moment(value).format(DATE_FORMAT);
};

const DownloadCell = () => (
  <Grid container alignItems="center" justifyContent="center">
    <DownloadIcon color="primary" style={{ cursor: 'pointer' }} />
  </Grid>
);

const PreviewCell = () => (
  <Grid container alignItems="center" justifyContent="center">
    <PreviewIcon color="primary" style={{ cursor: 'pointer' }} />
  </Grid>
);

export const firmwaresColumns = [
  { accessor: 'description', Header: 'Description', width: 44 },
  { accessor: 'type', Header: 'Type', width: 8 },
  { accessor: 'storageFile.extension', Header: 'Extension', width: 8 },
  { accessor: 'version', Header: 'Version', width: 8 },
  // {
  //   accessor: 'storageFile.createdByAccount.fullName',
  //   Header: 'Uploaded By',
  //   width: 10,
  // },
  {
    accessor: 'storageFile.createdAt',
    Header: 'Uploaded At',
    width: 20,
    valueGetter: getDate,
  },
];
export const tutorialsColumns = [
  { accessor: 'name', Header: 'Name', width: 40 },
  { accessor: 'type', Header: 'Type', width: 15 },
  {
    accessor: 'createdAt',
    Header: 'Uploaded At',
    width: 20,
    valueGetter: getDate,
  },
  {
    accessor: 'download',
    Header: 'Download',
    width: 10,
    Cell: DownloadCell,
  },
  {
    accessor: 'preview',
    Header: 'Preview',
    width: 10,
    Cell: PreviewCell,
  },
];

export const allowedInstructionFormats = [
  'jpg',
  'jpeg',
  'png',
  'mp4',
  'avi',
  'mkv',
];

export const allowedFirmwareFormats = {
  [resourceTypes.patchFirmware]: ['zip'],
  [resourceTypes.appFirmware]: ['apk'],
};

const firmwareTypeOptions = [
  { label: 'Patch Firmware', value: resourceTypes.patchFirmware },
  { label: 'Mobile App Firmware', value: resourceTypes.appFirmware },
];

export const addTutorialFormModel = [
  {
    name: 'type',
    label: 'Type',
    disabled: true,
    defaultValue: resourceTypes.tutorials,
  },
  {
    name: 'name',
    label: 'Name',
    required: true,
  },
  {
    name: 'file',
    label: 'select file',
    component: UploadButton,
    allowedFormats: allowedInstructionFormats,
    required: true,
  },
];

export const addFirmwareFormModel = [
  {
    name: 'type',
    label: 'Type',
    select: true,
    options: firmwareTypeOptions,
    defaultValue: resourceTypes.patchFirmware,
  },
  {
    name: 'version',
    label: 'Version',
    required: true,
  },
  {
    name: 'description',
    label: 'Description',
    required: true,
  },
  {
    name: 'file',
    label: 'select file',
    component: UploadButton,
    required: true,
  },
];
