import { commonActionTypes } from 'common/ducks/actionTypes';
import { generateCancellableAction } from 'common/utils/actionHelpers';

export const getProcedure = (payload, onSuccess, signal) =>
  generateCancellableAction({
    type: commonActionTypes.getShortProcedure.requested,
    signal,
    payload,
    onSuccess,
  });

export const getFullProcedure = (payload, onSuccess, signal) =>
  generateCancellableAction({
    type: commonActionTypes.getProcedure.requested,
    signal,
    payload,
    onSuccess,
  });
