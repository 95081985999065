import React from 'react';
import Button from '@material-ui/core/Button';
import SaveIco from '@material-ui/icons/Save';
import PropTypes from 'prop-types';

const Save = ({ text = 'Save', ...props }) => (
  <Button
    {...props}
    type="submit"
    color="primary"
    variant="contained"
    startIcon={<SaveIco />}
  >
    {text}
  </Button>
);

Save.propTypes = {
  text: PropTypes.string,
};

export default Save;
