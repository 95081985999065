import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { eventsActions } from 'common/ducks/general/actions';
import DefaultButton from 'common/components/buttons/DefaultButton';
import { clearUndoState } from './ducks';
import {
  getEcgEvents,
  getPatientEvents,
  getInvertEcgQueries,
  getEcgEventsWithoutStatus,
} from './ducks/selectors';

const actionDelay = 10000;

const Undo = ({
  classes,
  delay = actionDelay,
  onClose = () => null,
  buttonProps = {},
}) => {
  const dispatch = useDispatch();
  const ecgEvents = useSelector(getEcgEvents);
  const patientEvents = useSelector(getPatientEvents);
  const invertEcgQueries = useSelector(getInvertEcgQueries);
  const ecgEventsWithoutStatus = useSelector(getEcgEventsWithoutStatus);
  const [seconds, setSeconds] = useState(delay / 1000);
  const [interval, setIntervalId] = useState(null);

  const clearIntervals = useCallback(() => {
    setIntervalId((t) => {
      if (t) {
        clearInterval(t);
      }

      return null;
    });
  }, []);

  const handleUndo = () => {
    clearIntervals();
    if (ecgEvents?.length) {
      const eventsFroUpdate = ecgEvents.filter(
        ({ newId, status }) => !newId && status
      );

      if (eventsFroUpdate?.length) {
        dispatch(eventsActions.updateEcgEvents(eventsFroUpdate));
      }

      const eventsFroRemove = ecgEvents
        .filter(({ newId }) => !!newId)
        .map(({ newId }) => newId);

      if (eventsFroRemove?.length) {
        dispatch(eventsActions.removeEcgEvents(eventsFroRemove));
      }

      const eventsWithoutStatus = ecgEvents
        .filter(({ newId, status }) => !newId && !status)
        .map(({ id, abnormalityType, status }) => ({
          id,
          status,
          abnormalityType,
        }));

      if (eventsWithoutStatus?.length) {
        dispatch(eventsActions.bulkStatusUpdate(eventsWithoutStatus));
      }
    }

    if (ecgEventsWithoutStatus?.length) {
      dispatch(eventsActions.bulkStatusUpdate(ecgEventsWithoutStatus));
    }

    if (patientEvents?.length) {
      patientEvents.forEach(({ procedureId, ...event }) => {
        dispatch(
          eventsActions.updatePatientEvent({
            body: event,
            query: { procedureId },
          })
        );
      });
    }

    if (invertEcgQueries?.length) {
      invertEcgQueries.forEach((q) => {
        dispatch(eventsActions.setInvertEcg(q, undefined, undefined, false));
      });
    }

    dispatch(clearUndoState());
    onClose();
  };

  const closeOnTimeOut = useCallback(() => {
    if (seconds <= 0) {
      onClose();
      dispatch(clearUndoState());
    }
  }, [seconds, onClose, dispatch]);

  useEffect(() => {
    if (interval) {
      return;
    }

    const intervalId = setInterval(() => {
      setSeconds((s) => s - 1);
    }, 1000);

    setIntervalId(intervalId);
  }, [interval]);

  useEffect(() => {
    closeOnTimeOut();
  }, [closeOnTimeOut]);

  useEffect(() => clearIntervals, [clearIntervals]);

  return (
    <DefaultButton
      text={`UNDO ${seconds}s.`}
      variant="text"
      size="small"
      classes={classes}
      onClick={handleUndo}
      style={{ minWidth: 90 }}
      {...buttonProps}
      title=""
    />
  );
};

export default Undo;
