import { escalatedEventParts } from 'common/constants/enums';
import { createRequestActionTypes } from 'common/utils/actionTypesHelper';

export const actionTypes = createRequestActionTypes(
  [
    // ongoing
    'getData',
    'getEcgData',
    'getReportNotes',
    'approveOngoing',
    'getStudySummary',
    'getCorrelationData',
    'getEcgDataByPeriod',
    'getBeatsDataByPeriod',
    'getOngoingEventList',
    'setPagesParams',
    // escalated
    'getEventStudyTrendData',
    'getFiveMinEventEcg',
    'getEscalatedData',
    'approveEscalated',
    'getIncludedHours',
    'sensorData',
    'eventBeats',
    'heartRate',
    'eventEcg',
    // common
    'storePdf',
    'uploadDocument',
  ],
  'pdfReport'
);

const clearStateActionType = 'pdfReport.clearState';

const initialState = {
  loading: false,
  error: null,
  eventStudyTrend: {},
  reportNotes: {},
  sensorData: {},
  fiveMinEcg: {},
  hrSummary: {},
  beats: {},
  data: {},
  ecg: {},
  hr: {},
  events: {},
  pdf: null,
  reportsState: [],
  pagesParams: [],
  studySummary: null,
  includedHours: {},
  includedHoursLoading: false,
};

const updateNestedEventData = (state, { eventId, part }, value) => ({
  ...state,
  [eventId]: { ...(state[eventId] || {}), [part]: value },
});

const pdfReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.storePdf.requested:
      return {
        ...state,
        pdf: action.payload,
      };
    case actionTypes.uploadDocument.requested:
    case actionTypes.approveOngoing.requested:
    case actionTypes.approveEscalated.requested:
    case actionTypes.getData.requested:
      return {
        ...state,
        loading: true,
      };

    case actionTypes.getIncludedHours.requested:
      return {
        ...state,
        includedHoursLoading: true,
      };

    case actionTypes.getData.success:
      return { ...state, data: action.data, loading: false };

    case actionTypes.getEscalatedData.success: {
      return { ...state, data: action.payload.data, loading: false };
    }

    case actionTypes.getCorrelationData.success: {
      const { date } = action.requestPayload;
      return { ...state, hr: { ...state.hr, [date]: action.payload } };
    }

    case actionTypes.getOngoingEventList.success: {
      const { date } = action.requestPayload;
      return { ...state, events: { ...state.events, [date]: action.payload } };
    }

    case actionTypes.getEcgData.success: {
      const newEcg = action.payload.data.reduce(
        (acc, val) => ({ ...acc, [val.id]: val }),
        {}
      );
      return {
        ...state,
        ecg: {
          ...state.ecg,
          ...newEcg,
        },
      };
    }

    case actionTypes.getEcgDataByPeriod.success: {
      const { payload, requestPayload } = action;

      return {
        ...state,
        ecg: { ...state.ecg, [requestPayload.filter.id]: payload.data },
      };
    }

    case actionTypes.getBeatsDataByPeriod.success: {
      const { payload, requestPayload } = action;

      return {
        ...state,
        beats: { ...state.beats, [requestPayload.filter.id]: payload.data },
      };
    }

    case actionTypes.eventEcg.success: {
      return {
        ...state,
        ecg: updateNestedEventData(
          state.ecg,
          action.requestPayload,
          action.payload.data
        ),
      };
    }

    case actionTypes.sensorData.success: {
      return {
        ...state,
        sensorData: updateNestedEventData(
          state.sensorData,
          action.requestPayload,
          action.payload.data
        ),
      };
    }

    case actionTypes.eventBeats.success: {
      return {
        ...state,
        beats: updateNestedEventData(
          state.beats,
          action.requestPayload,
          action.payload.data
        ),
      };
    }
    case actionTypes.heartRate.success: {
      return {
        ...state,
        hrSummary: updateNestedEventData(
          state.hrSummary,
          action.requestPayload,
          action.payload.data
        ),
      };
    }
    case actionTypes.getEventStudyTrendData.success: {
      const { requestPayload, payload } = action;

      return {
        ...state,
        eventStudyTrend: {
          ...state.eventStudyTrend,
          [requestPayload.eventId]: Array.isArray(payload)
            ? payload[0]
            : payload,
        },
      };
    }

    case actionTypes.getStudySummary.success: {
      const { payload } = action;

      return {
        ...state,
        studySummary: payload.data,
      };
    }

    case actionTypes.getFiveMinEventEcg.success: {
      const { requestPayload, payload } = action;
      // NOTE: while getting this data at selector, please use sorting by key

      return {
        ...state,
        fiveMinEcg: {
          ...state.fiveMinEcg,
          [requestPayload.eventId]: {
            ...(state.fiveMinEcg[requestPayload.eventId] || {}),
            [requestPayload.filter.dateFrom]: payload.data,
          },
        },
      };
    }

    case actionTypes.approveOngoing.success: {
      const reportsState = action.payload.map(({ id, status, fileId }) => ({
        id,
        status,
        fileId,
      }));

      return { ...state, loading: false, reportsState };
    }
    case actionTypes.approveEscalated.success: {
      const { id, status, fileId } = action.payload.data;

      const reportsState = [
        {
          id,
          status,
          fileId,
        },
      ];

      return { ...state, loading: false, reportsState };
    }

    case actionTypes.uploadDocument.success: {
      return {
        ...state,
        loading: false,
      };
    }

    case actionTypes.getReportNotes.success: {
      return {
        ...state,
        reportNotes: {
          ...state.reportNotes,
          [action.payload.data.reportDate]: action.payload.data.notes,
        },
      };
    }

    case actionTypes.getIncludedHours.success: {
      return {
        ...state,
        includedHours: action.payload.data,
        includedHoursLoading: false,
      };
    }

    case actionTypes.getIncludedHours.failed: {
      return {
        ...state,
        includedHoursLoading: false,
      };
    }
    case actionTypes.uploadDocument.failed:
    case actionTypes.getData.failed:
    case actionTypes.approveOngoing.failed:
    case actionTypes.approveEscalated.failed:
      return {
        ...state,
        error: action.error,
        loading: false,
      };

    case actionTypes.setPagesParams.requested: {
      const page = action.payload;
      const pagesParams = [...state.pagesParams];

      const currentPageIndex = pagesParams.findIndex(
        ({ pageNumber }) => pageNumber === page.pageNumber
      );

      if (currentPageIndex > -1) {
        pagesParams[currentPageIndex] = page;
      } else {
        pagesParams.push(page);
      }

      return {
        ...state,
        pagesParams,
      };
    }

    case clearStateActionType:
      return initialState;
    default:
      return state;
  }
};

export const getData = (payload) => ({
  type: actionTypes.getData.requested,
  payload,
});
export const getStudySummary = (payload) => ({
  type: actionTypes.getStudySummary.requested,
  payload,
});
export const approveOngoing = (payload, onSuccess) => ({
  type: actionTypes.approveOngoing.requested,
  payload,
  onSuccess,
});
export const getCorrelationGraphData = (payload, date) => ({
  type: actionTypes.getCorrelationData.requested,
  date,
  payload,
});
export const getAllOngoingEvents = (payload, date) => ({
  type: actionTypes.getOngoingEventList.requested,
  date,
  payload,
});
export const getEcgDataByPeriod = (payload, date) => ({
  type: actionTypes.getEcgDataByPeriod.requested,
  payload,
  date,
});
export const getBeatsDataByPeriod = (payload, date) => ({
  type: actionTypes.getBeatsDataByPeriod.requested,
  payload,
  date,
});
export const storePdf = (payload) => ({
  type: actionTypes.storePdf.requested,
  payload,
});

// escalated
export const getEscalatedReportData = (payload) => ({
  type: actionTypes.getEscalatedData.requested,
  payload,
});
export const fetchEscalatedEventEcg = (
  filter,
  eventId,
  part = escalatedEventParts.event
) => ({
  type: actionTypes.eventEcg.requested,
  payload: { filter },
  eventId,
  part,
});

export const getEscalatedEventSensorData = (
  filter,
  eventId,
  part = escalatedEventParts.event
) => ({
  type: actionTypes.sensorData.requested,
  payload: { filter },
  eventId,
  part,
});

export const fetchEscalatedEventBeats = (
  filter,
  eventId,
  part = escalatedEventParts.event
) => ({
  type: actionTypes.eventBeats.requested,
  payload: { filter },
  eventId,
  part,
});

export const fetchEscalatedHeartRate = (
  filter,
  eventId,
  part = escalatedEventParts.event
) => ({
  type: actionTypes.heartRate.requested,
  payload: { filter },
  eventId,
  part,
});
export const getEventStudyTrendData = (payload) => ({
  type: actionTypes.getEventStudyTrendData.requested,
  payload,
});
export const getFiveMinEventEcg = (filter, eventId) => ({
  type: actionTypes.getFiveMinEventEcg.requested,
  payload: { filter },
  eventId,
});
export const approveEscalated = (payload, onSuccess) => ({
  type: actionTypes.approveEscalated.requested,
  payload,
  onSuccess,
});

export const uploadDocument = (payload, onSuccess, onError) => ({
  type: actionTypes.uploadDocument.requested,
  payload,
  onSuccess,
  onError,
});

export const setPagesParams = (payload) => ({
  type: actionTypes.setPagesParams.requested,
  payload,
});
export const getReportNotes = (payload) => ({
  type: actionTypes.getReportNotes.requested,
  payload,
});
export const getIncludedHours = (payload) => ({
  type: actionTypes.getIncludedHours.requested,
  payload,
});

export const clearState = () => ({ type: clearStateActionType });

export default pdfReportReducer;
