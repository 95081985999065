import { createSelector } from 'reselect';

const getOrganizationsSelectorState = (store) => store.organizationSelector;

export const getIsSelectorLoading = createSelector(
  getOrganizationsSelectorState,
  (state) => state.loading
);

export const getOrganizationsForLogin = createSelector(
  getOrganizationsSelectorState,
  (state) => state.data.map(({ id, name }) => ({ id, name }))
);

export const getInitialTenant = createSelector(
  getOrganizationsSelectorState,
  (state) => state.init
);
