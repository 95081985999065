import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

import {
  ecgTypeColors,
  ecgTypeLabels,
  trendEventsByAxis,
  abnormalityTypeEnum,
  eventsMeasurementUnits,
  escalatedTriageAbnormalities,
} from 'common/constants/ecgEnums';
import { ecgEventStatus, temperatureTypeColors } from 'common/constants/enums';

const nonEcgColor = '#0075FF';

export const getTemperatureColor = (value) => {
  if (value > 102) {
    return temperatureTypeColors.critical;
  }

  if (value > 101) {
    return temperatureTypeColors.highest;
  }

  if (value > 99) {
    return temperatureTypeColors.high;
  }

  return temperatureTypeColors.normal;
};

export const mapDataForStartEventPoints = ({
  id,
  value,
  dateTo,
  dateFrom,
  eventType,
  abnormalityType,
  nonEcgEvent = false,
  ...rest
}) => {
  let newValue = value;
  const color = nonEcgEvent ? nonEcgColor : ecgTypeColors[abnormalityType];
  const title = ecgTypeLabels[abnormalityType];
  const measureUnit = !nonEcgEvent
    ? eventsMeasurementUnits.ecg
    : eventsMeasurementUnits?.[abnormalityType];

  if (newValue || eventType) {
    newValue =
      abnormalityType === abnormalityTypeEnum.activities
        ? eventType.replace('Value', '')
        : `${value} ${measureUnit}`;
  }

  return {
    color,
    title,
    dateTo,
    dateFrom,
    measureUnit,
    nonEcgEvent,
    time: dateFrom,
    value: newValue,
    abnormalityType,
    strokeColor: color,
    id: id || uuidv4(),
    commonTriage: !escalatedTriageAbnormalities.includes(abnormalityType),
    ...rest,
  };
};

export const patientEventMapper = (params) => {
  const { date, time, title, symptoms = [], id } = params;

  return {
    time: date || time,
    value: null,
    title: symptoms.join(', ') || title,
    measureUnit: 'bpm',
    id: id || uuidv4(),
    patientEvent: true,
    abnormalityType: abnormalityTypeEnum.patient,
    color: ecgTypeColors[abnormalityTypeEnum.patient],
  };
};

export const mapDataForEventLines = ({
  id,
  value,
  dateTo,
  dateFrom,
  abnormalityType,
  nonEcgEvent = false,
}) => {
  const yValue = trendEventsByAxis[abnormalityType];

  return {
    nonEcgEvent,
    abnormalityType,
    nonEcgValue: value,
    color: ecgTypeColors[abnormalityType],
    id: id || uuidv4(),
    data: [
      { time: dateFrom, value: +yValue },
      { time: dateTo, value: +yValue },
      { time: moment(dateTo).add(1, 'second').toISOString(), value: null },
    ],
  };
};

export const mapDataForFiveMinuteEventLines = ({
  id,
  dateFrom,
  dateTo,
  abnormalityType,
  value,
  nonEcgEvent = false,
  ...rest
}) => ({
  nonEcgEvent,
  abnormalityType,
  nonEcgValue: value,
  color: ecgTypeColors[abnormalityType],
  id: id || uuidv4(),
  data: [{ time: dateFrom }, { time: dateTo }],
  yPoint: 37,
  ...rest,
});

export const mapIncludedDisclosure = (disclosure = {}) => {
  const dateFrom = disclosure.dateTime;
  const dateTo = moment(disclosure.dateTime).add(11, 'seconds').toISOString();

  return {
    ...disclosure,
    dateFrom,
    dateTo,
    status: ecgEventStatus.included,
    abnormalityType: abnormalityTypeEnum.includedHours,
  };
};
