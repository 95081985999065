import fetchPhysitians from './fetchPhysicians';
import fetchPhysician from './fetchPhysician';
import createPhysician from './createPhysician';
import updatePhysician from './updatePhysician';
import updateStatus from './updateStatus';

export default [
  fetchPhysitians,
  fetchPhysician,
  createPhysician,
  updatePhysician,
  updateStatus,
];
