import uniqBy from 'lodash/uniqBy';
import sortBy from 'lodash/sortBy';
import { v4 as uuidv4 } from 'uuid';

import {
  abnormalityTypeEnum,
  dailyHRAbnormalities,
} from 'common/constants/ecgEnums';
import { ecgEventStatus } from 'common/constants/enums';
import { getManuallyAccepted } from 'common/utils/helpers';
import { updateEventsBySocket } from 'common/utils/helpers/socket';
import { generateCancellableAction } from 'common/utils/actionHelpers';
import { createRequestActionTypes } from 'common/utils/actionTypesHelper';

import {
  mapHrEvents,
  mapHourEvent,
  updateHREvent,
  updateDailyHREventsForState,
} from '../utils';

export const actionTypes = createRequestActionTypes(
  [
    'get',
    'eventsEcg',
    'heartRates',
    'getPEMeasures',
    'patientEvents',
    'setSignificant',
    'updateIncluded',
    'getEventGroups',
    'additionalFetch',
    'updatePeIncluded',
    'getEventGroupTotals',
    'getIncludedDisclosures',
    'clearState',
    'getReportNotes',
    'saveReportNotes',
  ],
  'dailyReport'
);

const disclosureActionTypes = createRequestActionTypes(
  ['includeHour', 'declineHour'],
  'fullDisclosure'
);

const eventStatuses = [ecgEventStatus.accepted, ecgEventStatus.included];

const sortEvents = (events) =>
  sortBy(
    events,
    ({ date, dateFromEvent, dateFrom }) =>
      -new Date(date || dateFromEvent || dateFrom).getTime()
  );

const initialState = {
  ecg: {},
  error: null,
  totals: {},
  report: null,
  loading: true,
  reportNotes: {},
  eventGroups: [],
  fetchLoading: false,
  dailyHeartRates: [],
  significantEvents: {},
  autoIncludeEventData: {},
  includedDisclosures: [],
  [abnormalityTypeEnum.patient]: [],
  [abnormalityTypeEnum.lowestHR]: [],
  [abnormalityTypeEnum.highestHR]: [],
};

export default (state = initialState, action) => {
  const { type, payload, message, requestPayload } = action;

  switch (type) {
    case actionTypes.get.requested:
      return {
        ...state,
        report: null,
        loading: true,
        eventGroups: [],
        significantEvents: {},
        autoIncludeEventData: {},
      };
    case actionTypes.get.success: {
      const { reportTemplate, significantEventData, autoIncludeEventData } =
        payload.data;
      const significantEvents = Object.keys(significantEventData)
        .filter((evtKey) => evtKey !== 'longestAfib')
        .map((evtKey) => significantEventData[evtKey])
        .filter(Boolean)
        .map((evt) => ({ ...evt, mostSignificant: true }));

      const manuallyAccepted = getManuallyAccepted();

      const autoIncludeEvents = Object.values(autoIncludeEventData)
        .filter(Boolean)
        .filter(({ id }) => !manuallyAccepted.some((e) => e.id === id))
        .map((evt) => ({
          ...evt,
          status: ecgEventStatus.included,
          mostSignificant:
            evt.mostSignificant ||
            !!significantEvents.find((e) => e.id === evt.id),
        }));

      const events = sortEvents(
        uniqBy(
          [...autoIncludeEvents, ...significantEvents, ...state.eventGroups],
          'id'
        )
      );

      const lowestHRs = state[abnormalityTypeEnum.lowestHR];
      const highestHRs = state[abnormalityTypeEnum.highestHR];
      const [allLowest, allHighest] = updateDailyHREventsForState(
        lowestHRs,
        highestHRs,
        significantEventData
      );

      return {
        ...state,
        loading: false,
        eventGroups: events,
        report: reportTemplate,
        significantEvents: significantEventData,
        [abnormalityTypeEnum.lowestHR]: allLowest,
        [abnormalityTypeEnum.highestHR]: allHighest,
        autoIncludeEventData,
      };
    }
    case actionTypes.getEventGroupTotals.requested:
    case actionTypes.heartRates.requested:
      return { ...state, fetchLoading: true };
    case actionTypes.get.failed: {
      const { signal } = action.requestPayload;

      return {
        ...state,
        error: message,
        loading: signal?.aborted ? state.loading : false,
      };
    }

    case actionTypes.getEventGroups.requested: {
      return {
        ...state,
        fetchLoading: true,
      };
    }

    case actionTypes.eventsEcg.success: {
      const { dateFrom } = requestPayload?.event || {};
      const { eventId } = requestPayload;

      return {
        ...state,
        ecg: { ...state.ecg, [eventId || dateFrom]: payload.data },
      };
    }

    case actionTypes.eventsEcg.failed:
      return { ...state, error: message };

    case actionTypes.updateIncluded.requested: {
      const { added, updated } = action.payload;

      const unsavedUpdated = updated.filter(
        ({ id }) => !state.eventGroups.find((e) => e.id === id)
      );

      const addedLowestHR = [added, updated]
        .flat()
        .find(
          ({ abnormalityType }) =>
            abnormalityType === abnormalityTypeEnum.lowestHR
        );
      const addedHighestHR = [added, updated]
        .flat()
        .find(
          ({ abnormalityType }) =>
            abnormalityType === abnormalityTypeEnum.highestHR
        );

      let highestHRs = state[abnormalityTypeEnum.highestHR];
      let lowestHRs = state[abnormalityTypeEnum.lowestHR];

      if (addedLowestHR && lowestHRs?.length) {
        lowestHRs = lowestHRs.map((current) =>
          updateHREvent(addedLowestHR, current)
        );
      }

      if (addedHighestHR && highestHRs?.length) {
        highestHRs = highestHRs.map((current) =>
          updateHREvent(addedHighestHR, current)
        );
      }

      const payloadEvents = {
        ...payload,
        updated: updated.filter(
          ({ abnormalityType }) =>
            !dailyHRAbnormalities.includes(abnormalityType)
        ),
      };

      const eventGroups = updateEventsBySocket(
        state.eventGroups,
        payloadEvents,
        eventStatuses
      );

      const significantEvents = Object.keys(state.significantEvents).reduce(
        (acc, signKey) => {
          const ev = state.significantEvents[signKey];
          const upd = updated.find((e) => e.id === ev?.id) || {};

          const newE = ev ? { ...ev, ...upd } : null;

          if (!eventStatuses.includes(upd?.status)) {
            return { ...acc, [signKey]: null };
          }

          return { ...acc, [signKey]: newE };
        },
        {}
      );

      const autoIncludeEventData = Object.keys(
        state.autoIncludeEventData
      ).reduce((acc, signKey) => {
        const ev = state.autoIncludeEventData[signKey];
        const upd = updated.find((e) => e.id === ev?.id) || {};

        const newE = ev ? { ...ev, ...upd } : null;

        if (upd?.status === ecgEventStatus.included) {
          return { ...acc, [signKey]: null };
        }

        return { ...acc, [signKey]: newE };
      }, {});

      return {
        ...state,
        significantEvents,
        autoIncludeEventData,
        eventGroups: sortEvents([
          ...eventGroups,
          ...[unsavedUpdated, added]
            .flat()
            .filter(
              ({ abnormalityType }) =>
                !dailyHRAbnormalities.includes(abnormalityType)
            ),
        ]),
        [abnormalityTypeEnum.lowestHR]: lowestHRs,
        [abnormalityTypeEnum.highestHR]: highestHRs,
      };
    }

    case actionTypes.updatePeIncluded.requested: {
      const pes = updateEventsBySocket(
        state[abnormalityTypeEnum.patient],
        payload,
        eventStatuses
      );

      return {
        ...state,
        [abnormalityTypeEnum.patient]: pes,
      };
    }

    case actionTypes.patientEvents.requested:
    case actionTypes.additionalFetch.requested:
      return { ...state, fetchLoading: true };

    case actionTypes.additionalFetch.success: {
      const { significantEventData, reportTemplate, autoIncludeEventData } =
        payload.data || {};
      const { reportTotal, procedureTotal, patientEvents } =
        reportTemplate || {};

      return {
        ...state,
        fetchLoading: false,
        autoIncludeEventData,
        significantEvents: significantEventData,
        report: {
          ...state.report,
          reportTotal,
          patientEvents,
          procedureTotal,
        },
      };
    }

    case actionTypes.heartRates.success: {
      const highestHRs = mapHrEvents(action.payload.data);

      const lowestHRs = [...highestHRs].reverse().map((hrEvent) => ({
        ...hrEvent,
        key: uuidv4(),
        abnormalityType: abnormalityTypeEnum.lowestHR,
      }));

      const [allLowest, allHighest] = updateDailyHREventsForState(
        lowestHRs,
        highestHRs,
        state.significantEvents
      );

      return {
        ...state,
        fetchLoading: false,
        [abnormalityTypeEnum.highestHR]: allHighest,
        [abnormalityTypeEnum.lowestHR]: allLowest,
      };
    }

    case actionTypes.patientEvents.success: {
      return {
        ...state,
        fetchLoading: false,
        [abnormalityTypeEnum.patient]: sortEvents(
          uniqBy(payload.data, (e) => e.id)
        ),
      };
    }

    case actionTypes.getEventGroups.success: {
      const significantEvents = Object.values(state.significantEvents)
        .filter(Boolean)
        .map((evt) => ({ ...evt, mostSignificant: true }));

      const manuallyAccepted = getManuallyAccepted();

      const autoIncludedEvents = Object.values(state.autoIncludeEventData)
        .filter(Boolean)
        .filter(({ id }) => !manuallyAccepted.some((e) => e.id === id))
        .map((evt) => ({
          ...evt,
          status: ecgEventStatus.included,
          mostSignificant:
            evt.mostSignificant ||
            !!significantEvents.find((e) => e.id === evt.id),
        }));

      const events = sortEvents(
        uniqBy(
          [...autoIncludedEvents, ...significantEvents, ...payload.data],
          'id'
        )
      );

      return {
        ...state,
        fetchLoading: false,
        totals: {
          ...state.totals,
          ...payload.totals,
        },
        eventGroups: events,
      };
    }

    case actionTypes.getEventGroupTotals.success: {
      return {
        ...state,
        fetchLoading: false,
        totals: payload.data,
      };
    }

    case actionTypes.getPEMeasures.success: {
      const measures = action.payload.data;
      const { id } = action.requestPayload.filter;
      const patientEvents = state[abnormalityTypeEnum.patient];
      const newPatientEvents = patientEvents.map((pe) =>
        id === pe.id ? { ...pe, measures } : pe
      );

      return {
        ...state,
        [abnormalityTypeEnum.patient]: sortEvents(newPatientEvents),
      };
    }

    case actionTypes.getReportNotes.success: {
      if (!action.payload.data) {
        return state;
      }

      return {
        ...state,
        reportNotes: {
          ...state.reportNotes,
          [action.payload.data.reportDate]: action.payload.data.notes,
        },
      };
    }

    case actionTypes.getIncludedDisclosures.success: {
      return { ...state, includedDisclosures: action.payload.data };
    }

    case disclosureActionTypes.includeHour.success: {
      return {
        ...state,
        includedDisclosures: uniqBy(
          [
            ...state.includedDisclosures,
            ...[action.payload.data.result]
              .flat()
              .filter(Boolean)
              .map(mapHourEvent),
          ],
          'dateTime'
        ),
      };
    }

    case disclosureActionTypes.declineHour.success: {
      const { ids = [] } = action.requestPayload.query || {};
      return {
        ...state,
        includedDisclosures: state.includedDisclosures.filter(
          (d) => !ids.includes(d.id)
        ),
      };
    }

    case actionTypes.getEventGroupTotals.failed:
    case actionTypes.patientEvents.failed:
    case actionTypes.getEventGroups.failed: {
      return {
        ...state,
        fetchLoading: false,
      };
    }

    case actionTypes.additionalFetch.failed: {
      return { ...state, fetchLoading: false, error: payload?.message };
    }

    case actionTypes.clearState.requested:
      return initialState;

    default:
      return state;
  }
};

export const getReport = (filter) =>
  generateCancellableAction({
    type: actionTypes.get.requested,
    payload: { filter },
  });

export const getEventsEcg = (payload, eventId) =>
  generateCancellableAction({
    type: actionTypes.eventsEcg.requested,
    payload,
    eventId,
  });

export const updateIncludedEvents = (payload) => ({
  type: actionTypes.updateIncluded.requested,
  payload,
});

export const updateIncludedPeEvents = (payload) => ({
  type: actionTypes.updatePeIncluded.requested,
  payload,
});

export const fetchReportData = (filter, onSuccess, onError) => ({
  type: actionTypes.additionalFetch.requested,
  payload: { filter },
  onError,
  onSuccess,
});
export const getEventGroups = (filter) =>
  generateCancellableAction({
    type: actionTypes.getEventGroups.requested,
    payload: { filter },
  });
export const getPatientEvents = (filter) =>
  generateCancellableAction({
    type: actionTypes.patientEvents.requested,
    payload: { filter },
  });
export const getEventGroupTotals = (filter) => ({
  type: actionTypes.getEventGroupTotals.requested,
  payload: { filter },
});
export const getPEMeasures = (filter) => ({
  type: actionTypes.getPEMeasures.requested,
  payload: { filter },
});
export const setSignificant = (payload, onSuccess, onError) => ({
  type: actionTypes.setSignificant.requested,
  payload,
  onError,
  onSuccess,
});

export const getDailyHeartRates = (filter) => ({
  type: actionTypes.heartRates.requested,
  payload: { filter },
});

export const getIncludedDisclosures = (filter) => ({
  type: actionTypes.getIncludedDisclosures.requested,
  payload: { filter },
});

export const getReportNotes = (payload) => ({
  type: actionTypes.getReportNotes.requested,
  payload,
});
export const saveReportNotes = (payload) => ({
  type: actionTypes.saveReportNotes.requested,
  payload,
});

export const clearState = (payload) => ({
  type: actionTypes.clearState.requested,
  payload,
});
