import { createSelector } from 'reselect';

const getRolesState = (store) => store.roles;

export const getRoles = createSelector(getRolesState, (state) => state.data);
export const getTotal = createSelector(getRolesState, (state) => state.total);
export const getLoading = createSelector(
  getRolesState,
  (state) => state.loading
);
