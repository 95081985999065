import { call, takeEvery, put, all, fork, cancel } from 'redux-saga/effects';
import moment from 'moment';

import resources from 'common/constants/resources';
import dataProvider from 'store/dataProvider';
import { ecgEventStatus } from 'common/constants/enums';
import { abnormalityTypeEnum } from 'common/constants/ecgEnums';

import { actionTypes } from '../index';

let fetchingGraphsTask = null;
const withoutAbnormalities = [
  abnormalityTypeEnum.hr,
  abnormalityTypeEnum.lowestHR,
  abnormalityTypeEnum.highestHR,
  abnormalityTypeEnum.includedHours,
];

const status = [ecgEventStatus.accepted, ecgEventStatus.included];

function* requestDateRange(action, dateFrom, dateTo) {
  const { filter } = action.payload;
  const { procedureId } = filter;

  const { data: ecg } = yield call(
    dataProvider.getList,
    resources.ecgData.byPeriod,
    {
      ...action.payload,
      signal: action.signal,
      filter: { ...filter, dateFrom, dateTo, precision: 20 },
    }
  );

  const { data: allEvents = [] } = yield call(
    dataProvider.getList,
    resources.eventGroup.byPeriod,
    {
      filter: {
        status,
        dateFrom,
        dateTo,
        procedureId,
        reviewed: true,
        withRangeIntersection: true,
      },
    }
  );

  const events = allEvents.filter(
    ({ abnormalityType }) => !withoutAbnormalities.includes(abnormalityType)
  );

  const { data: patientEvents = [] } = yield call(
    dataProvider.getList,
    resources.healthData.patientEvents,
    {
      filter: {
        status,
        dateFrom,
        dateTo,
        procedureId,
      },
    }
  );

  yield put({
    type: actionTypes.getFiveMinutesGraph.success,
    payload: {
      ecg,
      events,
      dateFrom,
      patientEvents,
      range: { [dateFrom]: [dateFrom, dateTo] },
    },
  });
}

function* getFiveMinutesGraph(action) {
  // We have to cancel fetching task if we started to load new range
  if (fetchingGraphsTask) {
    yield cancel(fetchingGraphsTask);
  }

  const { filter } = action.payload;
  const { dateFrom } = filter;

  const dateRanges = new Array(5)
    .fill(null)
    .map((_, index) => moment(dateFrom).add(index, 'minute').toISOString());

  fetchingGraphsTask = yield all(
    dateRanges.map((date) => {
      const dateTo = moment(date).add(1, 'minute').toISOString();

      return fork(requestDateRange, action, date, dateTo);
    })
  );
}

export function* getFiveMinutesGraphSaga() {
  yield takeEvery(
    actionTypes.getFiveMinutesGraph.requested,
    getFiveMinutesGraph
  );
}
