import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const NoDataMessage = () => (
  <Grid
    container
    justifyContent="center"
    alignItems="center"
    style={{ height: '100%' }}
  >
    <Typography variant="h3">No data to show.</Typography>
  </Grid>
);

export default NoDataMessage;
