import { createRequestActionTypes } from 'common/utils/actionTypesHelper';

export const filterActionTypes = createRequestActionTypes(
  ['getList'],
  'filter'
);

const resetFiltersActionType = 'filter.resetFilters';

export const initialState = {
  data: {},
  loading: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case filterActionTypes.getList.requested:
      return { ...state, loading: true };

    case filterActionTypes.getList.success: {
      const { data } = action.payload;

      return {
        ...state,
        loading: false,
        data,
      };
    }

    case resetFiltersActionType:
      return { ...initialState };

    default:
      return state;
  }
};

export const getFilters = (payload, resource) => ({
  type: filterActionTypes.getList.requested,
  payload: {
    ...payload,
    resource,
  },
});

export const resetFilters = () => ({
  type: resetFiltersActionType,
});
