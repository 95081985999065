import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { yupResolver } from '@hookform/resolvers/yup';
import IconButton from '@material-ui/core/IconButton';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import LinearProgress from '@material-ui/core/LinearProgress';

import CancelButton from 'common/components/buttons/ModalActionButton';
import TextField from 'common/components/formFields/TextField';
import { useNotify } from 'common/modules/Notification/hooks';
import SaveButton from 'common/components/buttons/Save';
import { commonRoles } from 'common/constants/enums';
import resources from 'common/constants/resources';
import dataProvider from 'store/dataProvider';

import { updatePasswordSchema } from '../constants';

const updateEndpointsAccordingRole = {
  [commonRoles.physician]: resources.physician.updatePassword,
  [commonRoles.contact]: resources.contact.updatePassword,
};

const UpdatePasswordModal = ({ onClose, id, role }) => {
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const { control, formState, handleSubmit } = useForm({
    resolver: yupResolver(updatePasswordSchema),
    mode: 'onChange',
  });

  const urlToUpdate = useMemo(() => updateEndpointsAccordingRole[role], [role]);

  const onSuccess = () => {
    setLoading(false);
    onClose();
    notify.success('Password updated successfully.');
  };

  const onError = (m) => {
    notify.error(m || 'The Password is not updated.');
    setLoading(false);
  };

  const onSubmit = async ({ newPassword }) => {
    try {
      setLoading(true);
      await dataProvider.createOne(urlToUpdate, {
        params: { id },
        body: newPassword,
      });
      onSuccess();
    } catch (e) {
      onError(e?.body?.message);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogTitle style={{ position: 'relative' }}>
        <Typography component="span" variant="h3" color="primary">
          Update Password
        </Typography>

        <IconButton
          onClick={onClose}
          style={{ position: 'absolute', right: 5, top: 0 }}
        >
          <CloseIcon />
        </IconButton>

        {loading && (
          <Grid container style={{ position: 'absolute', bottom: 0, left: 0 }}>
            <LinearProgress style={{ height: 3, width: '100%' }} />
          </Grid>
        )}
      </DialogTitle>

      <Grid component={DialogContent}>
        <Grid item xs style={{ padding: '10px 0' }}>
          <TextField
            fullWidth
            size="small"
            name="newPassword"
            form={formState}
            control={control}
            label="Password"
            type="password"
            autoComplete="new-password"
          />
        </Grid>

        <Grid item xs style={{ padding: '10px 0' }}>
          <TextField
            fullWidth
            size="small"
            type="password"
            name="confirmPassword"
            label="Confirm Password"
            autoComplete="new-password"
            form={formState}
            control={control}
          />
        </Grid>
      </Grid>

      <DialogActions>
        <SaveButton disabled={loading} text="Update Password" />

        <CancelButton onClick={onClose}>Cancel</CancelButton>
      </DialogActions>
    </form>
  );
};

export default UpdatePasswordModal;
