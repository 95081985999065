import PhoneMaskInput from 'common/components/inputMasks/PhoneMaskInput';
import BooleanField from '../../../formFields/BooleanField';

export default [
  {
    name: 'fullName',
    label: 'Name',
    width: '49%',
    required: true,
  },
  {
    name: 'email',
    label: 'Email',
    width: '49%',
  },
  {
    name: 'phone',
    label: 'Phone',
    required: true,
    InputProps: {
      inputComponent: PhoneMaskInput,
    },
    width: '49%',
  },
  {
    name: 'use2FactorAuth',
    label: 'Two Factor Authentication',
    component: BooleanField,
    defaultValue: true,
    width: '49%',
  },
];
