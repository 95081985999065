import React from 'react';
import PropTypes from 'prop-types';

import { containerChildProps } from 'common/constants/sharedPropTypes';
import { speedMarksSizeDiff } from '../constants';

const BeatDurationLabel = ({ parent, time, duration }) => {
  const { xScale, scale, options } = parent;
  const sx = scale || xScale;

  const cof = speedMarksSizeDiff[options.settings.speed];
  const xDiff = 13 * cof;

  return (
    <text
      fontSize={13 * cof}
      transform={`translate(${sx(new Date(time)) - xDiff}, ${40})`}
    >
      {duration}
    </text>
  );
};

BeatDurationLabel.propTypes = {
  time: PropTypes.string,
  duration: PropTypes.number,
  parent: containerChildProps,
};

export default BeatDurationLabel;
