import moment from 'moment';
import {
  beatTypes,
  graphDurations,
  durationByAbnormalities,
} from 'common/constants/ecgEnums';
import { getCenterBetweenDatesUTC } from './date';

const notAvailable = 'N/A';

export const getGraphDuration = (abnormalityType) => {
  const durationKey = Object.keys(durationByAbnormalities).find((duration) => {
    const abnormalities = durationByAbnormalities[duration];

    if (abnormalities) {
      return abnormalities.includes(abnormalityType);
    }

    return null;
  });

  return Number(graphDurations[durationKey] || 10);
};

export const getBeatsDurations = (beats) =>
  beats.map(({ time, duration }) => ({
    time: moment(time)
      .subtract(duration / 2, 'milliseconds')
      .toISOString(),
    duration,
  }));

export const getBeatLabel = (beatType) =>
  beatTypes[beatType] === beatTypes.N ? null : beatType;

export const getActivityAndPositionAverage = (groupedData) => {
  const groupedDataKeys = Object.keys(groupedData || {});

  if (!groupedDataKeys.length) {
    return notAvailable;
  }

  return groupedDataKeys.reduce((acc, value) => {
    const data = groupedDataKeys[value];

    if (!data) {
      return value;
    }

    const prevData = groupedDataKeys[value];
    const currentData = groupedDataKeys[value];

    return prevData?.length > currentData?.length ? acc : value;
  }, '');
};

export const getEventGraphCenterDate = (event) => {
  const { customDateFrom, customDateTo, date, dateFrom, dateTo } = event || {};

  if (customDateFrom && customDateTo) {
    return getCenterBetweenDatesUTC(customDateFrom, customDateTo).toISOString();
  }

  if (dateFrom && dateTo) {
    return getCenterBetweenDatesUTC(dateFrom, dateTo).toISOString();
  }

  if (date) {
    return date;
  }

  return null;
};

export const getEventStripGraphParams = (event = {}, rangeDuration = 15) => {
  const { dateFrom, date, customDateFrom, customDateTo } = event;

  const diff = moment(customDateTo).diff(customDateFrom, 'millisecond') / 2;
  const center = customDateFrom
    ? moment(customDateFrom).add(diff).toISOString()
    : date || dateFrom;

  const from = moment(center).clone().subtract(rangeDuration, 'second');
  const to = moment(center).clone().add(rangeDuration, 'second');

  return { center, range: [from.toISOString(), to.toISOString()] };
};
