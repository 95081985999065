import React, {
  memo,
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import { useSelector, useDispatch } from 'react-redux';

import { usePrevious } from 'common/hooks';
import { abnormalityTypeEnum } from 'common/constants/ecgEnums';
import { eventStripReducerNames } from 'common/constants/enums';
import LoadingProgress from 'common/components/LoadingProgress';
import { eventGroupType } from 'common/constants/sharedPropTypes';
import {
  getCenterBetweenDatesUTC,
  getFirstEqualOrAfterTimeIndex,
} from 'common/utils/helpers/date';

import {
  getBeats,
  getLoading,
  getEventGroups,
  getPatientEvents,
} from './ducks/selectors';
import useStyles from './styles';
import StripHeader from './StripHeader';
import { getStrip, clearState } from './ducks';
import { eventTriageStripSettings } from './constants';
import EventGraphContainer from './EventGraphContainer';
import EventStripButtons from './components/EventStripButtons';
import useGetEventStripTitle from './hooks/useGetEventStripTitle';
import useUpdateEventStripBySocket from './hooks/useUpdateEventStripBySocket';
import { useGetEventLastActivity } from './hooks';

const defaultSettingValues = eventTriageStripSettings.reduce(
  (acc, config) => ({ ...acc, [config.name]: config.defaultValue }),
  {}
);

const EventStrip = ({
  event,
  range,
  status,
  center,
  onClose,
  resource,
  procedureId,
  physicianView,
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const prevEvent = usePrevious(event);

  const beats = useSelector(getBeats(resource));
  const loading = useSelector(getLoading(resource));
  const eventGroups = useSelector(getEventGroups(resource));
  const patientEvents = useSelector(getPatientEvents(resource));

  const currentEvent = useMemo(() => {
    const eventGetter = (e) => e.id === event?.id;
    return eventGroups.find(eventGetter) || patientEvents.find(eventGetter);
  }, [event, eventGroups, patientEvents]);

  const isCurrentEventPE = useMemo(
    () => event?.abnormalityType === abnormalityTypeEnum.patient,
    [event]
  );

  const containerRef = useRef(null);
  const prevCenter = useRef(null);

  const [maxWidth, setMaxWidth] = useState(700);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedPatientEvent, setPatientEvent] = useState(null);
  const [stripSettings, setStripSettings] = useState(defaultSettingValues);
  const [selectedBeatRange, setSelectedBeatRange] = useState(null);
  const [isMeasureRulerShown, setMeasureRulerShown] = useState(false);

  const stripTitle = useGetEventStripTitle(procedureId);

  const isModalView = useMemo(() => !!onClose, [onClose]);
  const eventId = useMemo(() => event?.id, [event?.id]);
  const eventTime = useMemo(() => {
    if (!event) {
      return undefined;
    }

    if (event.customDateFrom) {
      return getCenterBetweenDatesUTC(
        event.customDateFrom,
        event.customDateTo
      ).toISOString();
    }

    return event.date || event.time || event.dateFromEvent || event.dateFrom;
  }, [event]);

  const selectedPeFromStore = useMemo(
    () =>
      selectedPatientEvent &&
      patientEvents.find(
        (patientEvt) => patientEvt.id === selectedPatientEvent.id
      ),
    [patientEvents, selectedPatientEvent]
  );

  const selectedEcgEventFromStore = useMemo(
    () =>
      selectedEvent && eventGroups.find((evt) => evt.id === selectedEvent.id),
    [eventGroups, selectedEvent]
  );

  const dimension = useMemo(
    () => ({
      isModalView,
      width: maxWidth,
      height: 240,
    }),
    [maxWidth, isModalView]
  );

  const selectedCenter = useMemo(() => {
    if (eventTime) {
      return eventTime;
    }

    if (center === prevCenter.current) {
      return null;
    }

    prevCenter.current = center;
    return center;
  }, [center, eventTime]);

  const updateSelectedPatientEvent = useCallback(
    (ev) => {
      if (!ev) {
        return;
      }

      const peEcgEvt = eventGroups.find((evt) =>
        (ev?.ecgEventGroupIds || []).find((id) => evt.id === id)
      );

      setPatientEvent(ev);

      if (peEcgEvt) {
        setSelectedEvent(peEcgEvt);
      }

      setSelectedBeatRange(null);
    },
    [eventGroups]
  );

  const PEClickHandler = (ev) => {
    updateSelectedPatientEvent(ev);
  };

  const graphClickHandler = useCallback(
    (bRange) => {
      if (selectedPatientEvent && selectedBeatRange) {
        setPatientEvent(null);
      }

      setSelectedBeatRange(bRange);
      setSelectedEvent(null);
    },
    [selectedPatientEvent, selectedBeatRange]
  );

  const isDisabledButtons = useMemo(() => {
    return !selectedEvent && !selectedPatientEvent && !selectedBeatRange;
  }, [selectedPatientEvent, selectedBeatRange, selectedEvent]);

  const initializeSelectedEcgEvent = useCallback(() => {
    if (!event || event?.abnormalityType === abnormalityTypeEnum.patient) {
      return;
    }

    setSelectedEvent(event);
  }, [event]);

  const initializeSelectedPatientEvent = useCallback(() => {
    if (
      event?.abnormalityType !== abnormalityTypeEnum.patient ||
      selectedBeatRange
    ) {
      return;
    }

    const pe = patientEvents.find(
      (ev) => ev.id === event?.id || ev.id === event.patientEventId
    );

    updateSelectedPatientEvent(pe);
  }, [event, patientEvents, selectedBeatRange, updateSelectedPatientEvent]);

  const updateSelectedPatientEventFromStore = useCallback(() => {
    if (!selectedPeFromStore) {
      setPatientEvent(null);
      return;
    }

    setPatientEvent((pe) => selectedPeFromStore || pe);
  }, [selectedPeFromStore]);

  const updateSelectedEcgEventFromStore = useCallback(() => {
    if (!selectedEcgEventFromStore) {
      return;
    }

    setSelectedEvent((evt) => selectedEcgEventFromStore || evt);
  }, [selectedEcgEventFromStore]);

  const initializeSelectedBeat = useCallback(() => {
    const eventCenter = event?.dateFromEvent || event?.dateFrom;
    const patient = event?.abnormalityType === abnormalityTypeEnum.patient;

    if (!beats?.length || patient || !eventCenter) {
      return;
    }

    const index = getFirstEqualOrAfterTimeIndex(beats, eventCenter);
    const startOfBeat = beats[index];
    const endOfBeat = beats[index + 1];

    if (index < 0 || !startOfBeat || !endOfBeat) {
      setSelectedBeatRange(null);
      return;
    }

    setSelectedBeatRange([
      new Date(startOfBeat?.time),
      new Date(endOfBeat?.time),
    ]);
  }, [event, beats]);

  const clearPrevSelectionStateOnChangeEvent = useCallback(() => {
    if (event && event?.id !== prevEvent?.id) {
      setPatientEvent(null);
      setSelectedEvent(null);
      setSelectedBeatRange(null);
    }
  }, [event, prevEvent]);

  const startRange = useMemo(() => range[0], [range]);
  const endRange = useMemo(() => range[1], [range]);

  const fetchStripData = useCallback(() => {
    dispatch(
      getStrip(
        {
          status,
          procedureId,
          range: [startRange, endRange],
          eventIds: [eventId],
          reviewed: !!status?.length,
        },
        resource
      )
    );
  }, [status, eventId, dispatch, endRange, resource, startRange, procedureId]);

  const toggleRuler = () => setMeasureRulerShown((prev) => !prev);

  useUpdateEventStripBySocket({
    startRange,
    endRange,
    eventId,
    onClose,
    resource,
    procedureId,
    status,
  });

  const eventActivity = useGetEventLastActivity(selectedEcgEventFromStore);

  useEffect(() => {
    if (!containerRef.current) {
      return;
    }

    setMaxWidth(containerRef.current.clientWidth - 32);
  }, [containerRef]);

  useEffect(() => {
    fetchStripData();
  }, [fetchStripData]);

  useEffect(() => {
    clearPrevSelectionStateOnChangeEvent();
  }, [clearPrevSelectionStateOnChangeEvent]);

  useEffect(() => {
    initializeSelectedBeat();
  }, [initializeSelectedBeat]);

  useEffect(() => {
    initializeSelectedEcgEvent();
  }, [initializeSelectedEcgEvent]);

  useEffect(() => {
    updateSelectedEcgEventFromStore();
  }, [updateSelectedEcgEventFromStore]);

  useEffect(() => {
    initializeSelectedPatientEvent();
  }, [initializeSelectedPatientEvent]);

  useEffect(() => {
    updateSelectedPatientEventFromStore();
  }, [updateSelectedPatientEventFromStore]);

  useEffect(() => () => dispatch(clearState(resource)), [resource, dispatch]);

  return (
    <Card style={{ flex: 1 }}>
      {(eventActivity || isModalView) && (
        <Grid container justifyContent="space-between">
          <Grid item>{isModalView ? stripTitle : ''}</Grid>

          {eventActivity && <Grid item>{eventActivity}</Grid>}
        </Grid>
      )}

      <StripHeader
        onClose={onClose}
        resource={resource}
        openedEvent={event}
        procedureId={procedureId}
        isModalView={isModalView}
        physicianView={physicianView}
        selectedEvent={selectedEvent}
        stripSettings={stripSettings}
        setStripSettings={setStripSettings}
        setSelectedEvent={setSelectedEvent}
        selectedBeatRange={selectedBeatRange}
        selectedPatientEvent={selectedPatientEvent}
        updateSelectedPatientEvent={updateSelectedPatientEvent}
      />

      <Grid item container direction="column" ref={containerRef}>
        <Grid
          item
          container
          justifyContent="center"
          className={styles.gridContent}
        >
          {loading ? (
            <div className={styles.loadingProgress}>
              <LoadingProgress />
            </div>
          ) : null}

          <EventGraphContainer
            {...(dimension || {})}
            resource={resource}
            endRange={endRange}
            inverted={currentEvent?.rotateEcg}
            center={selectedCenter}
            startRange={startRange}
            settings={stripSettings}
            onPEClick={PEClickHandler}
            isTriageView={!status?.length}
            onClick={graphClickHandler}
            position={selectedBeatRange}
            selectedEvent={selectedEvent}
            handleEventSelect={setSelectedEvent}
            isMeasureRulerShown={isMeasureRulerShown}
            selectedPatientEvent={selectedPatientEvent}
          />
        </Grid>

        <Grid item xs>
          <EventStripButtons
            center={center}
            onClose={onClose}
            statuses={status}
            resource={resource}
            isNoBeats={!beats.length}
            isModalView={isModalView}
            procedureId={procedureId}
            toggleRuler={toggleRuler}
            disabled={isDisabledButtons}
            selectedEvent={selectedEvent}
            physicianView={physicianView}
            openedEvent={event}
            currentEventId={event?.id}
            isCurrentEventPE={isCurrentEventPE}
            selectedBeatRange={selectedBeatRange}
            setSelectedBeatRange={setSelectedBeatRange}
            selectedPatientEvent={selectedPatientEvent}
          />
        </Grid>
      </Grid>
    </Card>
  );
};

EventStrip.defaultProps = {
  status: null,
  resource: eventStripReducerNames.eventTriageStrip,
  reviewed: false,
  isTriageView: false,
  withDeclined: false,
  physicianView: false,
};

EventStrip.propTypes = {
  event: eventGroupType,
  onClose: PropTypes.func,
  center: PropTypes.string,
  resource: PropTypes.string,
  reviewed: PropTypes.bool,
  withDeclined: PropTypes.bool,
  isTriageView: PropTypes.bool,
  procedureId: PropTypes.string,
  physicianView: PropTypes.bool,
  range: PropTypes.arrayOf(PropTypes.string),
  status: PropTypes.arrayOf(PropTypes.string),
  statuses: PropTypes.arrayOf(PropTypes.string),
};

const isEqualProps = (prev, next) => {
  const [prevStartRange] = prev.range || [];
  const [nextStartRange] = next.range || [];

  return (
    prev.procedureId === next.procedureId &&
    prev.event?.id === next.event?.id &&
    prevStartRange === nextStartRange &&
    prev.center === next.center
  );
};

export default memo(EventStrip, isEqualProps);
