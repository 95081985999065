import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import useTheme from '@material-ui/core/styles/useTheme';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import useRouterInfo from 'common/hooks/useRouterInfo';
import {
  VCMenuLink,
  VCAccordion,
  VCAccordionDetails,
  VCAccordionSummary,
} from './index';
import useAuthProvider from '../../../../store/authProvider/useAuthProvider';

const defaultRouterInfo = {};

const MenuItem = ({
  nested,
  url,
  title,
  isLink,
  icon: Icon,
  isHidden = false,
  classes = {},
  expanded,
  collapsable,
}) => {
  const { palette } = useTheme();
  const role = useAuthProvider('currentUserRole');
  const routerInfo = useRouterInfo(defaultRouterInfo);
  const { params: routerParams = {}, finalPath } = routerInfo;

  const hasNested = useMemo(() => nested?.length > 0, [nested]);

  const finalUrl = useMemo(
    () => (typeof url === 'function' ? url(routerParams) : url),
    [routerParams, url]
  );

  const isSelected = useCallback(
    (urls) => {
      if (typeof urls === 'function') {
        const currentUrl = urls({ id: routerParams.id });
        return currentUrl === finalPath;
      }

      if (Array.isArray(urls)) {
        return urls.some((urlItem) =>
          typeof urlItem === 'function'
            ? urlItem({ id: routerParams.id }) === finalPath
            : urlItem === finalPath
        );
      }
      return urls === finalPath;
    },
    [finalPath, routerParams.id]
  );

  const defaultExpanded = useMemo(
    () =>
      expanded ||
      isSelected(finalUrl) ||
      isSelected(nested.filter((n) => n.url).map(({ url: u }) => u)),
    [expanded, finalUrl, isSelected, nested]
  );

  const isMenuSelected = useMemo(
    () => isSelected(finalUrl),
    [finalUrl, isSelected]
  );

  if (isHidden && isHidden({ url: routerInfo?.url, role })) {
    return null;
  }

  return (
    <VCAccordion
      key={finalUrl}
      disabled={!hasNested}
      collapsable={collapsable}
      defaultExpanded={defaultExpanded}
      dataTestid="accordion-item"
    >
      <VCAccordionSummary
        expandIcon={
          hasNested && collapsable ? (
            <ExpandMoreIcon data-testid="expand-more-icon" />
          ) : null
        }
      >
        <Grid
          container
          direction="row"
          wrap="nowrap"
          alignItems="center"
          to={finalUrl}
          onClick={(event) => {
            if (isLink && !isMenuSelected) {
              event.stopPropagation();
            }
          }}
          style={{ textDecoration: 'none' }}
          className={classes.sectionHeader}
          component={isLink ? Link : 'div'}
        >
          <Grid className={classes.menuItemIconContainer}>
            {Icon && <Icon fontSize="small" data-testid="menu-item-icon" />}
          </Grid>

          <Grid
            aria-label="Title"
            selected={isMenuSelected}
            className={classes.menuItemTextContainer}
            style={{
              color: isMenuSelected
                ? palette.text.colored
                : palette.text.primary,
            }}
          >
            <Typography>{title}</Typography>
          </Grid>
        </Grid>
      </VCAccordionSummary>

      <VCAccordionDetails>
        <Grid container direction="column">
          {nested.map((route) => {
            const { restrictions } = route;

            if (role && restrictions && !restrictions.includes(role)) {
              return null;
            }

            return (
              <VCMenuLink
                key={route.url || route.key}
                to={
                  typeof route.url === 'function'
                    ? route.url(routerParams)
                    : route.url
                }
                text={route.title}
                isSelected={route.url && isSelected(route.url)}
                {...route}
              />
            );
          })}
        </Grid>
      </VCAccordionDetails>
    </VCAccordion>
  );
};

MenuItem.propTypes = {
  url: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  modal: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  title: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.shape(), PropTypes.func]),
  classes: PropTypes.shape(),
  nested: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      title: PropTypes.string,
    })
  ),
  onOpen: PropTypes.func,
  isHidden: PropTypes.func,
  isLink: PropTypes.bool,
  expanded: PropTypes.bool,
  collapsable: PropTypes.bool,
};

export default MenuItem;
