import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

const filter = createFilterOptions();

const formControlStyle = { width: '100%' };

const helperTextStyle = {
  marginLeft: 14,
  marginRight: 14,
};

const AutocompleteInput = ({
  name,
  options,
  multiple,
  label,
  variant,
  optionLabel,
  optionValueName,
  inputProps,
  defaultValue,
  onInputChange,
  field = {},
  helperText,
  getOptionLabel,
  creatable,
  withUnknown,
  FormHelperTextProps,
  loading,
  error,
  ...rest
}) => {
  const restProps = rest;
  const handleChangeAutocomplete = (event, value, ...restArgs) => {
    if (restProps.onChange) {
      restProps.onChange(event, value, ...restArgs);
    }
  };

  restProps.getOptionSelected = (option, value) => {
    if (!optionValueName) {
      return option === value;
    }
    if (!value) {
      return null;
    }

    return option[optionValueName] === value[optionValueName];
  };

  if (creatable) {
    restProps.filterOptions = (filterOptions, params) => {
      const { inputValue } = params;
      const filtered = filter(filterOptions, params);
      if (inputValue.length > 0) {
        if (optionValueName && optionLabel) {
          filtered.unshift({
            [optionValueName]: null,
            [optionLabel]: inputValue,
          });
        } else if (!filterOptions.includes(inputValue)) {
          filtered.unshift(inputValue);
        }
      }

      return filtered;
    };
  }

  const localOptions = useMemo(() => {
    if (!withUnknown) {
      return [...options].filter((option) =>
        typeof option === 'string'
          ? !!option
          : !!(option?.[optionLabel] && option?.[optionValueName])
      );
    }

    return [
      {
        [optionValueName]: null,
        [optionLabel]: 'Unknown',
      },
      ...options,
    ];
  }, [options, withUnknown, optionValueName, optionLabel]);

  const getOption = (option) => {
    if (getOptionLabel) {
      return getOptionLabel(option);
    }
    return typeof option === 'string' ? option : option?.[optionLabel] || '';
  };

  const value = restProps.value || (multiple ? [] : null);

  return (
    <FormControl style={formControlStyle}>
      <Autocomplete
        {...field}
        {...restProps}
        value={value}
        options={localOptions}
        multiple={multiple}
        getOptionLabel={getOption}
        onInputChange={onInputChange}
        onChange={handleChangeAutocomplete}
        loading={loading}
        loadingText="Loading..."
        data-testid="autocomplete-org"
        getOptionSelected={(opt, val) => {
          const option = typeof opt === 'string' ? opt : opt[optionValueName];
          const identifier =
            typeof val === 'string' ? val : val[optionValueName];
          return option === identifier;
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              ...inputProps,
            }}
            variant={variant}
            label={label}
            placeholder={label}
            error={error}
          />
        )}
      />
      {helperText && (
        <FormHelperText style={helperTextStyle} {...FormHelperTextProps} error>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

AutocompleteInput.defaultProps = {
  label: '',
  inputProps: {},
  multiple: false,
  creatable: false,
  withUnknown: false,
  defaultValue: '',
  variant: 'outlined',
  optionLabel: 'title',
  onInputChange: () => null,
};

AutocompleteInput.propTypes = {
  label: PropTypes.string,
  multiple: PropTypes.bool,
  variant: PropTypes.string,
  className: PropTypes.string,
  optionLabel: PropTypes.string,
  optionValueName: PropTypes.string,
  inputProps: PropTypes.shape(),
  onInputChange: PropTypes.func,
  name: PropTypes.string.isRequired,
  field: PropTypes.objectOf(PropTypes.any),
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string])
  ),
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.array,
  ]),
  creatable: PropTypes.bool,
  withUnknown: PropTypes.bool,
  helperText: PropTypes.string,
  getOptionLabel: PropTypes.func,
  FormHelperTextProps: PropTypes.objectOf(PropTypes.any),
  loading: PropTypes.bool,
  error: PropTypes.bool,
};

export default AutocompleteInput;
