import React from 'react';
import PropTypes from 'prop-types';

import { ecgEventStatus } from 'common/constants/enums';
import TextInput from 'common/components/simpleInputs/TextInput';
import StripSettingsContainer from './StripSettingsContainer';
import { statusField } from '../constants';
import { selectStripStyles as useStyles } from '../styles';

const EventStatus = ({ disabled, value, onChange }) => {
  const classes = useStyles();

  return (
    <StripSettingsContainer>
      <TextInput
        {...statusField}
        select
        fullWidth
        size="small"
        variant="standard"
        disabled={disabled}
        value={value}
        onChange={(e, v) => onChange(v)}
        SelectProps={{
          classes: {
            root: classes.selectMenu,
          },
        }}
      />
    </StripSettingsContainer>
  );
};

EventStatus.defaultProps = {
  disabled: false,
  value: ecgEventStatus.default,
  onChange: () => null,
};

EventStatus.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.oneOf(Object.values(ecgEventStatus)),
  onChange: PropTypes.func,
};

export default EventStatus;
