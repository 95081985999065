import React, { memo, useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';

import { commonRoles } from 'common/constants/enums';
import { useRefreshTokenSchedule } from 'common/hooks';
import useAuthProvider from 'store/authProvider/useAuthProvider';
import OrganizationSelector from '../OrganizationSelector';
import PhysicianSelector from '../PhysicianSelector';
import UserButton from './UserButton';

const useStyles = makeStyles(({ palette, ...theme }) => ({
  container: {
    height: 66,
    color: palette.common.white,
    paddingLeft: theme.spacing(2),
    backgroundColor: palette.primary.dark,
  },
  tenantSelectContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  tenantSelectLabel: {
    color: palette.common.white,
    fontWeight: 500,
  },
  tenantSelect: {
    height: 29,
    width: 230,
    marginLeft: theme.spacing(),
  },
  profileIconWrapper: {
    color: palette.common.white,
  },
  menuItem: {
    marginLeft: theme.spacing(2),
  },
  avatarIco: {
    width: 20,
    height: 20,
  },
}));

const HeaderLayout = () => {
  useRefreshTokenSchedule();
  const classes = useStyles();
  const authProvider = useAuthProvider();

  const role = useMemo(
    () => authProvider.currentUserRole,
    [authProvider.currentUserRole]
  );

  const selectorLabel = useMemo(
    () => (role === commonRoles.contact ? 'Physicians' : 'Organizations'),
    [role]
  );
  const Selector = useMemo(
    () =>
      role === commonRoles.contact ? PhysicianSelector : OrganizationSelector,
    [role]
  );

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      data-testid="header"
      className={classes.container}
    >
      <div className={classes.tenantSelectContainer}>
        {role !== commonRoles.physician && (
          <>
            <Typography className={classes.tenantSelectLabel}>
              {selectorLabel}:
            </Typography>

            <div className={classes.tenantSelect}>
              <Selector />
            </div>
          </>
        )}
      </div>

      <UserButton classes={classes} />
    </Grid>
  );
};

const isEqual = () => true;

export default memo(HeaderLayout, isEqual);
