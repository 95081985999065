import unionBy from 'lodash/unionBy';
import { createRequestActionTypes } from 'common/utils/actionTypesHelper';
import { requestReducer } from 'common/utils/generators';

export const actionTypes = createRequestActionTypes(
  ['getList', 'getOne', 'update', 'updateMany', 'updateStatus', 'create'],
  'simCards'
);

const initialState = {
  loading: false,
  data: [],
  total: 0,
  error: false,
};

export default requestReducer(
  [actionTypes.getList, actionTypes.getOne, actionTypes.update],
  initialState,
  (payload, state) => {
    const { data, total } = payload;
    return {
      ...state,
      loading: false,
      data: Array.isArray(data) ? data : unionBy([data], state.data, 'id'),
      total,
    };
  }
);

export const getSimCards = (payload) => ({
  type: actionTypes.getList.requested,
  payload,
});

export const getSimCard = ({ id }) => ({
  type: actionTypes.getOne.requested,
  payload: { id },
});

export const updateSimCard = (payload, onSuccess, onError) => ({
  type: actionTypes.update.requested,
  payload,
  onSuccess,
  onError,
});

export const updateSimCards = (payload, onSuccess, onError) => ({
  type: actionTypes.updateMany.requested,
  payload,
  onSuccess,
  onError,
});

export const createSimCard = (payload, onSuccess, onError) => ({
  type: actionTypes.create.requested,
  payload,
  onSuccess,
  onError,
});
