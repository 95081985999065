import { call, put, takeEvery } from 'redux-saga/effects';
import groupBy from 'lodash/groupBy';
import moment from 'moment/moment';

import { getActivityAndPositionAverage } from 'common/utils/helpers/graphs';
import { requestSaga } from 'common/utils/generators';
import resources from 'common/constants/resources';
import dataProvider from 'store/dataProvider';

import { actionTypes } from '../reducer';

export function* getEcgDataByPeriod() {
  yield requestSaga({
    actionType: actionTypes.getEcgDataByPeriod,
    request: dataProvider.getList,
    resource: resources.ecgData.byPeriod,
  });
}

export function* getBeatsDataByPeriod() {
  yield requestSaga({
    actionType: actionTypes.getBeatsDataByPeriod,
    request: dataProvider.getList,
    resource: resources.healthData.stripBeats,
  });
}

export function* getBeatsData() {
  yield requestSaga({
    actionType: actionTypes.eventBeats,
    request: dataProvider.get,
    resource: resources.healthData.stripBeats,
  });
}

const getSensorData = async (date, procedureId) => {
  const dateFrom = moment(date).subtract(5, 'seconds').toISOString();
  const dateTo = moment(date).add(5, 'seconds').toISOString();

  const filter = { dateFrom, dateTo, procedureId };

  const { data: stripTemperatures } = await dataProvider.get(
    resources.sensorData.temperature,
    { filter }
  );

  const { data: stripActivities } = await dataProvider.get(
    resources.sensorData.activity,
    { filter }
  );

  const { data: stripBodyPosition } = await dataProvider.get(
    resources.sensorData.bodyPosition,
    { filter }
  );

  const tempAvg =
    (stripTemperatures || []).reduce((acc, item) => acc + item.value, 0) /
    (stripTemperatures?.length || 1);
  const groupedActivity = groupBy(stripActivities, 'value');
  const groupedPosition = groupBy(stripBodyPosition, 'value');

  const skinTemp = tempAvg ? `${tempAvg.toFixed(1)} F` : 'N/A';
  const activity = getActivityAndPositionAverage(groupedActivity);
  const position = getActivityAndPositionAverage(groupedPosition);

  return { skinTemp, activity, position };
};

function* getSensorDataSaga(action) {
  const { payload } = action;
  const { dateFrom, procedureId } = payload.filter;

  try {
    const data = yield call(getSensorData, dateFrom, procedureId);

    yield put({
      type: actionTypes.sensorData.success,
      payload: { data },
      requestPayload: action,
    });
  } catch (e) {
    yield put({
      type: actionTypes.sensorData.failed,
      message: e.message,
    });
  }
}

export function* getEventSensorData() {
  yield takeEvery(actionTypes.sensorData.requested, getSensorDataSaga);
}

export function* getEscalatedEcgDataByPeriod() {
  yield requestSaga({
    actionType: actionTypes.eventEcg,
    request: dataProvider.getList,
    resource: resources.ecgData.byPeriod,
  });
}

export function* getFiveMinEventEcg() {
  yield requestSaga({
    actionType: actionTypes.getFiveMinEventEcg,
    request: dataProvider.getList,
    resource: resources.ecgData.byPeriod,
  });
}
