import * as Yup from 'yup';

import { password } from 'common/utils/validationSchemas';

export const updatePasswordSchema = Yup.object().shape({
  newPassword: password,
  confirmPassword: Yup.string().oneOf(
    [Yup.ref('newPassword'), null],
    'Must match with New password'
  ),
});
