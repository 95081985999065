import { messageTemplates, entityEnum } from './constants';

const capitalizeFirstLetter = (str = '') =>
  str.charAt(0).toUpperCase() + str.slice(1);

const capitalizeSentence = (messages) =>
  messages
    .split('. ')
    .map((sentence) => capitalizeFirstLetter(sentence))
    .join('. ');

export const getMessage = (entity, entityData, status, option) => {
  const { plural, singular } = entityEnum[entity];
  const entityValue = entityData.length > 1 ? plural : singular;
  const messages = messageTemplates[status];

  const entityNames = entityData
    .map((e) => (typeof e === 'string' ? e : e[option] || ''))
    .join(', ');

  return Object.keys(messages).reduce(
    (acc, key) => ({
      ...acc,
      [key]: capitalizeSentence(
        messages[key]
          .replaceAll('{entity}', entityValue)
          .replaceAll('{entityNames}', entityNames ? `"${entityNames}"` : '')
      ),
    }),
    {}
  );
};
