import fetchKits from './fetchKits';
import assign from './assign';
import fetchKit from './fetchKit';
import createKit from './createKit';
import updateStatus from './updateStatus';
import updateKit, { updateKitTenantId } from './updateKit';

export default [
  fetchKits,
  assign,
  fetchKit,
  createKit,
  updateKit,
  updateStatus,
  updateKitTenantId,
];
