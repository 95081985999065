import React, { useCallback, useEffect, useMemo, useState } from 'react';
import clsx from 'classnames';
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';

import {
  getRecordsFromRange,
  getFormattedDuration,
  getCenterBetweenDatesUTC,
} from 'common/utils/helpers/date';
import { ecgEventStatus } from 'common/constants/enums';
import { eventsActions } from 'common/ducks/general/actions';
import { ecgTimeFormat } from 'common/constants/dateFormats';
import { clearUndoState } from 'common/modules/Undo/ducks';
import { abnormalityTypeEnum } from 'common/constants/ecgEnums';
import { getBeatsRangeHRAvg } from 'common/components/D3Components/utils';
import useNotify from 'common/modules/Notification/hooks/useNotify';
import {
  getBeats,
  getVisibleRange,
  getPatientEvents,
  getActivityAverage,
  getTemperatureAverage,
  getBodyPositionAverage,
} from './ducks/selectors';
import PatientEventIcon from './components/PatientEventIcon';
import { eventTriageStripSettings } from './constants';
import StripSettings from './components/StripSettings';
import EventSymptoms from './components/EventSymptoms';
import EventStatus from './components/EventStatus';
import StripValue from './components/StripValue';
import Notes from './components/Notes';
import useStyles from './styles';

const notAvailable = 'N/A';

const StripHeader = ({
  onClose = null,
  resource = '',
  openedEvent,
  procedureId = '',
  isModalView = false,
  isTriageView = false,
  physicianView = false,
  stripSettings = {},
  selectedEvent = null,
  setStripSettings = () => null,
  setSelectedEvent = () => null,
  selectedBeatRange = null,
  selectedPatientEvent = null,
  updateSelectedPatientEvent = () => null,
}) => {
  const styles = useStyles();
  const notify = useNotify();
  const dispatch = useDispatch();
  const beats = useSelector(getBeats(resource));
  const visibleRange = useSelector(getVisibleRange(resource));
  const patientEvents = useSelector(getPatientEvents(resource));
  const tempAvg = useSelector(getTemperatureAverage(resource));
  const posAvg = useSelector(getBodyPositionAverage(resource));
  const actAvg = useSelector(getActivityAverage(resource));

  const [symptoms, setSymptoms] = useState([]);

  const handleUpdateStripSettings = (name, value) => {
    setStripSettings((prevSettings) => ({
      ...prevSettings,
      [name]: value,
    }));
  };

  const dispatchUpdateSymptoms = useCallback(
    (list) => {
      if (!selectedPatientEvent && !selectedBeatRange) return;
      // clear undo state before new action to avoid conflicts
      dispatch(clearUndoState());

      const onUpdate = () =>
        notify.success({
          message: 'Event has been updated successfully.',
          undoable: true,
        });

      if (!selectedPatientEvent && selectedBeatRange) {
        const [from, to] = selectedBeatRange;
        const peCenter = getCenterBetweenDatesUTC(from, to);
        const date = new Date(peCenter).toISOString();

        const updatePESelection = ({ payload }) => {
          const { data } = payload;

          updateSelectedPatientEvent(data);
          onUpdate();
        };

        const onUpdateSuccess = ({ payload }) => {
          const { data } = payload;

          const newPE = {
            date,
            status: ecgEventStatus.accepted,
            symptoms: list,
            ecgEventGroupIds: [data?.[0]?.id],
          };

          dispatch(
            eventsActions.updatePatientEvent(
              { body: newPE, query: { procedureId } },
              [{ ...newPE, procedureId }],
              updatePESelection
            )
          );
        };

        const ecgEvent = {
          procedureId,
          dateTo: to.toISOString(),
          dateFrom: from.toISOString(),
          status: ecgEventStatus.accepted,
          abnormalityType: abnormalityTypeEnum.NSR,
        };

        dispatch(
          eventsActions.updateEcgEvents([ecgEvent], [ecgEvent], onUpdateSuccess)
        );
      } else {
        const newPE = {
          ...selectedPatientEvent,
          symptoms: list,
        };

        dispatch(
          eventsActions.updatePatientEvent(
            {
              query: { procedureId },
              body: newPE,
            },
            [
              {
                ...selectedPatientEvent,
                id: list?.length ? selectedPatientEvent.id : undefined,
                procedureId,
              },
            ],
            onUpdate
          )
        );

        if (!list?.length && onClose) {
          onClose();
        }
      }
    },
    [
      notify,
      onClose,
      dispatch,
      procedureId,
      selectedBeatRange,
      selectedPatientEvent,
      updateSelectedPatientEvent,
    ]
  );

  const isSymptomsDisabled = useMemo(() => {
    if (physicianView && !isModalView) return true;

    if (!selectedBeatRange && !selectedPatientEvent) return true;

    if (selectedBeatRange && !selectedPatientEvent) {
      const PE = getRecordsFromRange(patientEvents, selectedBeatRange, 'date');
      return !!PE.length;
    }

    return false;
  }, [
    isModalView,
    physicianView,
    patientEvents,
    selectedBeatRange,
    selectedPatientEvent,
  ]);

  const symptomsHandler = useCallback(
    (value) => {
      setSymptoms(value);

      if (!selectedPatientEvent && !selectedBeatRange) return;

      if (!selectedPatientEvent && selectedBeatRange && !value.length) return;

      dispatchUpdateSymptoms(value);
    },
    [selectedPatientEvent, selectedBeatRange, dispatchUpdateSymptoms]
  );

  const updateStatusHandler = useCallback(
    (status) => {
      // clear undo state before new action to avoid conflicts
      dispatch(clearUndoState());

      const onSuccess = () => {
        notify.success({
          message: 'Event has been updated successfully.',
          undoable: true,
        });
      };

      if (selectedPatientEvent) {
        const newPE = { ...selectedPatientEvent, status };
        dispatch(
          eventsActions.updatePatientEvent(
            {
              query: { procedureId },
              body: newPE,
            },
            [{ ...selectedPatientEvent, procedureId }],
            onSuccess
          )
        );
        return;
      }

      const ev = {
        ...selectedEvent,
        status,
      };

      setSelectedEvent(ev);

      dispatch(eventsActions.updateEcgEvents([ev], [selectedEvent], onSuccess));
    },
    [
      selectedPatientEvent,
      setSelectedEvent,
      selectedEvent,
      procedureId,
      dispatch,
      notify,
    ]
  );

  const time = useMemo(() => {
    if (selectedEvent?.dateFrom) {
      return moment(selectedEvent.dateFrom).format(ecgTimeFormat);
    }

    if (selectedBeatRange) {
      return moment(selectedBeatRange[0]).format(ecgTimeFormat);
    }

    if (selectedPatientEvent?.date) {
      return moment(selectedPatientEvent.date).format(ecgTimeFormat);
    }

    return notAvailable;
  }, [selectedBeatRange, selectedEvent, selectedPatientEvent]);

  const duration = useMemo(() => {
    if (!selectedEvent?.dateFrom || !selectedEvent?.id) {
      return notAvailable;
    }

    return getFormattedDuration(selectedEvent.duration);
  }, [selectedEvent]);

  const stripAverage = useMemo(() => {
    if (!visibleRange || !beats.length) {
      return notAvailable;
    }

    const averageBPM = getBeatsRangeHRAvg(beats, visibleRange);

    return `HR (10s) ${averageBPM}`;
  }, [beats, visibleRange]);

  const eventHRRange = useMemo(() => {
    if (!selectedEvent?.duration || selectedEvent?.duration < 60000) {
      return null;
    }

    const { minHeartRate, maxHeartRate } = selectedEvent;

    return `HR ${minHeartRate} - ${maxHeartRate}`;
  }, [selectedEvent]);

  const updateNote = useCallback(
    (note) => {
      setSelectedEvent((ev) => ({ ...ev, note }));
    },
    [setSelectedEvent]
  );

  useEffect(() => {
    setSymptoms(selectedPatientEvent?.symptoms || []);
  }, [selectedPatientEvent]);

  return (
    <Grid
      container
      spacing={1}
      alignItems="center"
      justifyContent="space-between"
      className={clsx(styles.eventStripHeader, {
        [styles.eventStripHeaderModal]: isModalView,
      })}
    >
      <Grid item container xs={3} wrap="nowrap" alignItems="center" spacing={1}>
        {selectedPatientEvent && <PatientEventIcon />}

        <Notes
          resource={resource}
          event={selectedEvent}
          openedEvent={openedEvent}
          onChangeNote={updateNote}
          isBeatSelected={!!selectedBeatRange?.length}
        />
      </Grid>

      <Grid
        item
        container
        xs={6}
        wrap="nowrap"
        alignItems="flex-start"
        justifyContent="space-around"
        style={{ flex: 100, paddingLeft: 16 }}
      >
        <StripValue title="Time:" value={time} flex={17} />
        <StripValue title="Duration:" value={duration} flex={15} />

        <Grid item container direction="column" style={{ flex: 23 }}>
          <StripValue
            title="Strip (10s) Avg:"
            value={stripAverage}
            flex={100}
          />

          {!!eventHRRange && (
            <StripValue
              flex={100}
              title="Event HR Range:"
              value={eventHRRange}
              styles={{ marginTop: 4 }}
            />
          )}
        </Grid>

        <StripValue title="Temp:" value={tempAvg} flex={12} />

        <StripValue title="Activity:" value={actAvg} flex={14} />

        <StripValue title="Position:" value={posAvg} flex={14} />
      </Grid>

      <Grid item container xs alignItems="center">
        {eventTriageStripSettings.map((settingsField) => (
          <StripSettings
            {...settingsField}
            key={settingsField.name}
            currentValues={stripSettings}
            onChange={handleUpdateStripSettings}
          />
        ))}

        <EventSymptoms
          value={symptoms}
          disabled={isSymptomsDisabled}
          onChange={symptomsHandler}
        />

        <EventStatus
          onChange={updateStatusHandler}
          disabled={isTriageView || !selectedEvent}
          value={selectedPatientEvent?.status || selectedEvent?.status}
        />
      </Grid>
    </Grid>
  );
};

StripHeader.propTypes = {
  onClose: PropTypes.func,
  resource: PropTypes.string,
  procedureId: PropTypes.string,
  openedEvent: PropTypes.shape(),
  isModalView: PropTypes.bool,
  isTriageView: PropTypes.bool,
  physicianView: PropTypes.bool,
  stripSettings: PropTypes.shape(),
  selectedEvent: PropTypes.shape(),
  setStripSettings: PropTypes.func,
  setSelectedEvent: PropTypes.func,
  selectedPatientEvent: PropTypes.shape(),
  updateSelectedPatientEvent: PropTypes.func,
  selectedBeatRange: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)])
  ),
};

export default StripHeader;
