import { stringify } from 'query-string';

const defaultQueryOptions = {
  arrayFormat: '&',
  encode: false,
  skipNull: true,
};

// TODO: add comments and rename all variables to more intuitive.
export const recursiveKey = (obj, key) =>
  Object.keys(obj).reduce((acc, item) => {
    return {
      ...acc,
      [item]: Object.keys(obj[item]).filter(
        (sub) => typeof obj[item][sub] === 'object'
      ).length
        ? recursiveKey(obj[item], key)
        : obj[item][key],
    };
  }, {});

export const getUrlEnum = (obj) =>
  Object.keys(obj).reduce((acc, name) => {
    if (name === 'url') {
      return { ...acc, [obj[name]]: obj };
    }

    return (
      (typeof obj[name] === 'object' && {
        ...acc,
        ...getUrlEnum(obj[name]),
      }) ||
      acc
    );
  }, {});

export const formatPath = (path, params) =>
  Object.entries(params).reduce(
    (p, [key, value]) => p.replace(`:${key}`, value),
    path
  );

export const queryingPath = (path, params, options) => {
  const queryOptions = { ...defaultQueryOptions, ...options };
  const query = stringify(params, queryOptions);
  return `${path}?${query}`;
};

export const parseQuery = (query = '', isQuestionMark = true) => {
  const queryString = (isQuestionMark ? query.substr(1) : query)
    .split('&')
    .map((part) => part.split('='));

  return Object.fromEntries(queryString);
};
