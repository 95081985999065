import { takeLatest, put, call } from 'redux-saga/effects';
import resources from 'common/constants/resources';
import dataProvider from 'store/dataProvider';
import { attachmentsActionTypes } from '../reducer';

function* upload({ payload }) {
  try {
    const { file } = payload;
    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileName', file.name);
    const { data } = yield call(
      dataProvider.createOneFormData,
      resources.files.upload,
      { body: formData }
    );

    yield put({
      type: attachmentsActionTypes.upload.success,
      data,
    });
  } catch (error) {
    yield put({ type: attachmentsActionTypes.upload.failed, payload: error });
  }
}

export default function* uploadFile() {
  yield takeLatest(attachmentsActionTypes.upload.requested, upload);
}
