import { createRequestActionTypes } from 'common/utils/actionTypesHelper';
import { requestReducer } from 'common/utils/generators';

export const reports = createRequestActionTypes(['get'], 'reportCalendar');

const initialState = {
  reports: [],
  loading: false,
  error: false,
};

export default requestReducer(reports.get, initialState, (payload, state) => ({
  ...state,
  reports: payload.data,
}));

export const getReportCalendar = (filter) => ({
  type: reports.get.requested,
  payload: { filter },
});
