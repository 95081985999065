import { getEvents, getEventEcg } from './getEvents';
import { getTotal } from './getEventTotals';
import { setStatus, setSignificantEvent } from './updateEvent';

export default [
  getEvents,
  getEventEcg,
  getTotal,
  setStatus,
  setSignificantEvent,
];
