import { createSelector } from 'reselect';
import groupBy from 'lodash/groupBy';

import { abnormalityTypeEnum, templateTypes } from 'common/constants/ecgEnums';

export const getTemplatesGridState = (store) => store.localGrid;

export const getLoading = createSelector(
  getTemplatesGridState,
  (state) => state.loading
);

const getEventsEcg = createSelector(
  getTemplatesGridState,
  (state) => state.ecg
);

export const getEcgByEventId = (id) =>
  createSelector(getEventsEcg, (eventsEcg) => eventsEcg[id]);

const getAllEvents = createSelector(
  getTemplatesGridState,
  (state) => state.events
);

const getEventGroups = createSelector(getAllEvents, (events) =>
  groupBy(events, 'abnormalityType')
);

export const getEvents = (abnormalityType, subType) =>
  createSelector(getEventGroups, getAllEvents, (eventGroups, allEvents) => {
    if (!abnormalityType) {
      return {
        similar: Object.keys(eventGroups)
          .filter((key) => !templateTypes.includes(key))
          .map((groupType) => eventGroups[groupType])
          .flat(),
        abnormalityType: abnormalityTypeEnum.all,
      };
    }

    if (subType) {
      // in this case abnormalityType is templateId
      const currentTemplate = allEvents.find(({ id }) => id === subType);

      return {
        abnormalityType: null,
        similar: currentTemplate?.similar,
      };
    }

    const eventsByType = eventGroups?.[abnormalityType] || [];

    return {
      abnormalityType,
      similar: eventsByType,
    };
  });
