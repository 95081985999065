import { requestSaga } from 'common/utils/generators';
import resources from 'common/constants/resources';
import dataProvider from 'store/dataProvider';
import { actionTypes } from './reducer';

function* getEscalatedReport() {
  yield requestSaga({
    actionType: actionTypes.get,
    request: dataProvider.get,
    resource: resources.report.escalated.get,
  });
}

function* createEscalatedReport() {
  yield requestSaga({
    actionType: actionTypes.create,
    request: dataProvider.createOne,
    resource: resources.report.escalated.create,
  });
}

function* addEventToEscalatedReport() {
  yield requestSaga({
    actionType: actionTypes.addEvent,
    request: dataProvider.createOne,
    resource: resources.report.escalated.addEvent,
  });
}

function* addPatientEventToEscalatedReport() {
  yield requestSaga({
    actionType: actionTypes.addPatientEvent,
    request: dataProvider.createOne,
    resource: resources.report.escalated.addPatientEvent,
  });
}

function* editEventNotes() {
  yield requestSaga({
    actionType: actionTypes.addNotes,
    request: dataProvider.updateOne,
    resource: resources.report.escalated.editEventNotes,
  });
}

function* editPatientEventNotes() {
  yield requestSaga({
    actionType: actionTypes.addPENotes,
    request: dataProvider.updateOne,
    resource: resources.report.escalated.editPatientEventNotes,
  });
}

function* removeEventFromEscalatedReport() {
  yield requestSaga({
    actionType: actionTypes.removeEvent,
    request: dataProvider.deleteOne,
    resource: resources.report.escalated.removeEvent,
  });
}

function* removePatientEventFromEscalatedReport() {
  yield requestSaga({
    actionType: actionTypes.removePatientEvent,
    request: dataProvider.deleteOne,
    resource: resources.report.escalated.removePatientEvent,
  });
}

function* fetchEscalatedReportEventEcg() {
  yield requestSaga({
    actionType: actionTypes.eventEcg,
    request: dataProvider.getList,
    resource: resources.ecgData.byPeriod,
  });
}

function* fetchEscalatedReportEventHeartRate() {
  yield requestSaga({
    actionType: actionTypes.heartRate,
    request: dataProvider.get,
    resource: resources.healthData.heartRateStripSummary,
  });
}

export default [
  editEventNotes,
  getEscalatedReport,
  createEscalatedReport,
  editPatientEventNotes,
  addEventToEscalatedReport,
  fetchEscalatedReportEventEcg,
  removeEventFromEscalatedReport,
  addPatientEventToEscalatedReport,
  fetchEscalatedReportEventHeartRate,
  removePatientEventFromEscalatedReport,
];
