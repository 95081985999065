import { requestSaga } from 'common/utils/generators';
import resources from 'common/constants/resources';
import dataProvider from 'store/dataProvider';
import { phonesActionTypes } from '../reducer';

function* updatePhones() {
  yield requestSaga({
    actionType: phonesActionTypes.updateMany,
    request: dataProvider.updateList,
    resource: resources.phone.index,
  });
}

export default updatePhones;
