import { reportActionTypes } from 'common/ducks/actionTypes';

export const reviewReport = (payload, onSuccess) => ({
  type: reportActionTypes.review.requested,
  onSuccess,
  payload,
});

export const reviewReportByFileId = (payload, onSuccess) => ({
  type: reportActionTypes.reviewByFileId.requested,
  onSuccess,
  payload,
});

export const reEmail = (payload, onSuccess, onError) => ({
  type: reportActionTypes.reEmail.requested,
  payload,
  onError,
  onSuccess,
});
export const reFax = (payload, onSuccess, onError) => ({
  type: reportActionTypes.reFax.requested,
  payload,
  onError,
  onSuccess,
});
export const submitAccepted = (payload, onSuccess, onError) => ({
  type: reportActionTypes.submitAccepted.requested,
  payload,
  onError,
  onSuccess,
});
export const submitEditsOptions = (payload, onSuccess, onError) => ({
  type: reportActionTypes.submitEditsOptions.requested,
  payload,
  onError,
  onSuccess,
});
