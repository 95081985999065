import React, { useMemo, useState, useCallback } from 'react';
import * as d3 from 'd3';
import PropTypes from 'prop-types';

import { containerChildProps } from 'common/constants/sharedPropTypes';
import { xTickFormats } from '../../constants';
import {
  xAxisGen,
  yAxisGen,
  hideLines,
  setTextFontSize,
  renderDashedYAxis,
  renderEventYAxis,
} from './utils';

const AxesGrid = ({
  color,
  parent,
  xTicks,
  strokeWidth,
  hiddenLines,
  dashedLines,
  xTickFormat,
  yTickFormat,
  smallScreen,
  segmentsHeight,
  eventSegmentLines,
}) => {
  const [xRef, setXRef] = useState(null);
  const [yRef, setYRef] = useState(null);
  const { width, height, options, domain, xScale, yScale, scale } = parent;
  const { margin } = options || {};

  const xAxis = useCallback(
    (...args) => xAxisGen(height, margin, xTicks, xTickFormat)(...args),
    [height, margin, xTicks, xTickFormat]
  );

  const yAxis = useCallback(
    (...args) => yAxisGen(width, margin, domain.y, yTickFormat)(...args),
    [width, margin, domain.y, yTickFormat]
  );

  const textFontSize = useMemo(() => {
    if (smallScreen) {
      return 5;
    }

    return xTickFormat === xTickFormats.full ? 8 : 10;
  }, [xTickFormat, smallScreen]);

  useMemo(
    () =>
      d3
        .select(xRef)
        .call(xAxis, scale || xScale)
        .call(setTextFontSize(textFontSize)),
    [xRef, xAxis, scale, xScale, textFontSize]
  );

  useMemo(
    () =>
      d3
        .select(yRef)
        .call(yAxis, yScale)
        .call(hideLines(hiddenLines))
        .call(renderDashedYAxis(dashedLines))
        .call(renderEventYAxis(eventSegmentLines, segmentsHeight))
        .call(setTextFontSize(10)),
    [
      yRef,
      yAxis,
      yScale,
      hiddenLines,
      dashedLines,
      eventSegmentLines,
      segmentsHeight,
    ]
  );

  return (
    <>
      <g
        id="x-axis"
        ref={setXRef}
        color={color}
        cursor="default"
        className="x-axis"
        strokeWidth={strokeWidth}
      />
      <g
        id="y-axis"
        ref={setYRef}
        color={color}
        className="y-axis"
        cursor="default"
        strokeWidth={strokeWidth}
      />
    </>
  );
};

AxesGrid.defaultProps = {
  color: 'black',
  strokeWidth: 0.2,
  hiddenLines: [],
  dashedLines: [],
  smallScreen: false,
  xTicks: (v) => v,
};

AxesGrid.propTypes = {
  color: PropTypes.string,
  xTicks: PropTypes.func,
  strokeWidth: PropTypes.number,
  parent: containerChildProps,
  yTickFormat: PropTypes.func,
  xTickFormat: PropTypes.string,
  hiddenLines: PropTypes.arrayOf(PropTypes.number),
  dashedLines: PropTypes.arrayOf(PropTypes.number),
  eventSegmentLines: PropTypes.arrayOf(PropTypes.string),
  smallScreen: PropTypes.bool,
  segmentsHeight: PropTypes.number.isRequired,
};

export default AxesGrid;
