import HttpError from './HttpError';
import * as fetchUtils from './fetchUtils';
import { getHeaders, reauthenticateIfNeeded } from './tokensHelper';
import httpCodes from './httpCodes';

const fetchWrapper = async (...params) => {
  const result = await fetch(...params);

  const { status } = result;

  if (status < httpCodes.minSuccess || status > httpCodes.maxSuccess) {
    const { body, statusText } = result;
    throw new HttpError(body || statusText, status, body);
  }

  return result;
};

export const httpClient =
  ({ apiAuthUrl, permissionsUrl, clearTokens, saveTokens }) =>
  async (url, options = {}, fetchJson = true) => {
    const fetchMethod = fetchJson ? fetchUtils.fetchJson : fetchWrapper;

    try {
      return await fetchMethod(url, {
        ...options,
        headers: getHeaders(true),
      });
    } catch (error) {
      if (
        await reauthenticateIfNeeded(
          { apiAuthUrl, permissionsUrl },
          error.status,
          {
            clearTokensData: clearTokens,
            saveTokensData: saveTokens,
          }
        )
      ) {
        return fetchMethod(url, { ...options, headers: getHeaders(true) });
      }

      throw error;
    }
  };

// this method use only for auth provider
// eslint-disable-next-line consistent-return
export async function sendData(
  method,
  url,
  authorizationUrl,
  permissionsUrl,
  params,
  saveTokens,
  clearTokens,
  errorMessage
) {
  try {
    const response = await fetchUtils.fetchJson(url, {
      method,
      body: JSON.stringify(params),
      headers: getHeaders(false),
    });
    return response.json;
  } catch (e) {
    if (e.status === httpCodes.badRequest) {
      // eslint-disable-next-line no-throw-literal
      throw new Error(errorMessage);
    }

    if (e.status < httpCodes.minSuccess || e.status > httpCodes.maxSuccess) {
      throw e;
    }
  }
}

export const authAccount = async (url, params, loginErrorMessage) => {
  const request = new Request(url, {
    method: 'POST',
    body: JSON.stringify(params),
    headers: getHeaders(false),
  });
  const response = await fetch(request);

  if (response.status === httpCodes.notAuthorized) {
    // eslint-disable-next-line no-throw-literal
    throw new Error(loginErrorMessage);
  }

  if (
    response.status < httpCodes.minSuccess ||
    response.status > httpCodes.maxSuccess
  ) {
    const body = await response.text();
    let json;
    try {
      json = JSON.parse(body);
    } catch (e) {
      json = body;
    }
    throw new HttpError(
      json && (json.message || json.messages),
      response.status,
      json
    );
  }

  return response.json();
};
// this method use only for login
export const authLogin = async (url, params, saveTokens, loginErrorMessage) => {
  const requestUrl = new URL(url);

  Object.keys(params).forEach((key) =>
    requestUrl.searchParams.append(key, params[key])
  );

  const request = new Request(requestUrl, {
    method: 'GET',
    headers: getHeaders(false),
  });
  let json;

  const response = await fetch(request);

  if (response.status === httpCodes.notAuthorized) {
    // eslint-disable-next-line no-throw-literal
    throw new Error(loginErrorMessage);
  }

  if (
    response.status < httpCodes.minSuccess ||
    response.status > httpCodes.maxSuccess
  ) {
    const body = await response.text();
    try {
      json = JSON.parse(body);
    } catch (e) {
      json = body;
    }
    throw new HttpError(
      json && (json.message || json.messages),
      response.status,
      json
    );
  }

  const data = await response.json();
  saveTokens(data);
};

export const updatePassword = async (url, params) => {
  const request = new Request(url, {
    method: 'PATCH',
    body: JSON.stringify(params),
    headers: getHeaders(false),
  });
  let json;

  const response = await fetch(request);

  if (
    response.status < httpCodes.minSuccess ||
    response.status > httpCodes.maxSuccess
  ) {
    const body = await response.text();
    try {
      json = JSON.parse(body);
    } catch (e) {
      json = body;
    }
    throw new HttpError(
      json && (json.message || json.messages),
      response.status,
      json
    );
  }
};

export const fetchUser = async (url, saveTokens) => {
  const request = await fetch(url, {
    headers: getHeaders(true),
  });

  if (!request.ok) {
    return new HttpError(request.status);
  }

  const response = await request.json();
  saveTokens({ role: response.role });
  return response;
};
