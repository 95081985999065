import dataProvider from 'store/dataProvider';
import resources from 'common/constants/resources';

export const getUsersOnPage = async (filter) => {
  try {
    const res = await dataProvider.get(resources.pageInfo.visitor, { filter });

    return res.data;
  } catch (e) {
    return null;
  }
};

export const setUserOnPage = async (query) => {
  try {
    const res = await dataProvider.createOne(resources.pageInfo.visitor, {
      query,
    });

    return res.data;
  } catch (e) {
    return null;
  }
};
