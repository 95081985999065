import { requestSaga } from 'common/utils/generators';
import resources from 'common/constants/resources';
import dataProvider from 'store/dataProvider';
import { organizationActionTypes } from '../reducer';

export function* getOrganizationsSaga() {
  yield requestSaga({
    actionType: organizationActionTypes.getList,
    request: dataProvider.getList,
    resource: resources.tenant.list,
  });
}

export function* getOrganizationSaga() {
  yield requestSaga({
    actionType: organizationActionTypes.getOne,
    request: dataProvider.getOne,
    resource: resources.tenant.index,
  });
}

export function* updateOrganization() {
  yield requestSaga({
    actionType: organizationActionTypes.update,
    request: dataProvider.updateOne,
    resource: resources.tenant.index,
  });
}

export function* updateOrganizationList() {
  yield requestSaga({
    actionType: organizationActionTypes.updateMany,
    request: dataProvider.updateMany,
    resource: resources.tenant.batchUpdate,
  });
}

export function* createOrganization() {
  yield requestSaga({
    actionType: organizationActionTypes.create,
    request: dataProvider.createOne,
    resource: resources.tenant.index,
  });
}
