import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';

import { tableHeaderHeight } from '../constants';
import { getToolbarHeight } from '../utils';

/**
 * @param {Number} height - toolbar height
 * @param {Boolean} selectable - is table selectable
 * * */

const Loader = ({ height = 0, selectable = true }) => {
  return (
    <div
      style={{
        position: 'absolute',
        top: getToolbarHeight({ height }, selectable) + tableHeaderHeight + 1,
        width: '100%',
        zIndex: 10,
      }}
    >
      <LinearProgress />
    </div>
  );
};

export default Loader;
