import reducer, { escalatedReportActions } from './ducks';
import * as selectors from './ducks/selectors';
import * as hooks from './hooks';

import modal from './components/EscalatedReportModal';

export default {
  modal,
  hooks,
  reducer,
  selectors,
  actions: escalatedReportActions,
};
