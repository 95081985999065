import moment from 'moment';

export const tableHeaderHeight = 30;

const DATE_TIME_FORMAT = 'L LT';

const getDateTime = (params) => {
  const { value } = params;
  return value && moment(value).format(DATE_TIME_FORMAT);
};

const dateColumn = {
  accessor: 'createdAt',
  Header: 'Date',
  width: 50,
  valueGetter: getDateTime,
};

export const kitAssignHistoryColumns = [
  {
    accessor: 'procedureName',
    Header: 'Procedure',
    width: 50,
  },
  dateColumn,
];

export const procedureAssignHistoryColumns = [
  {
    accessor: 'kitSerial',
    Header: 'Kit Serial #',
    width: 50,
  },
  dateColumn,
];
