import { takeLatest, put, call } from 'redux-saga/effects';
import resources from 'common/constants/resources';
import dataProvider from 'store/dataProvider';
import { attachmentsActionTypes } from '../reducer';

function* save(action) {
  const {
    payload: { files, type, entityType, entityId },
    onSuccess,
    onError,
  } = action;
  try {
    const filesParam = files.map((file) => ({
      ...file,
      type,
      entityType,
      entityId,
    }));
    const { data } = yield call(
      dataProvider.createOne,
      resources.files.multiple,
      filesParam
    );
    yield put({
      type: attachmentsActionTypes.save.success,
      data,
    });
    onSuccess();
  } catch (error) {
    yield put({ type: attachmentsActionTypes.save.failed, payload: error });
    onError();
  }
}

export default function* saveFile() {
  yield takeLatest(attachmentsActionTypes.save.requested, save);
}
