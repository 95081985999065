import {
  symptomEnum,
  ecgEventStatus,
  symptomLabelsEnum,
} from 'common/constants/enums';

export const eventNotes = [
  { value: 'AFIB' },
  { value: 'Bradycardia' },
  { value: 'Heart Block' },
  { value: 'Highest HR' },
  { value: 'Lowest HR' },
  { value: 'Other' },
  { value: 'Pause' },
  { value: 'Sinus Rhythm' },
  { value: 'SV-Tach' },
  { value: 'SVE' },
  { value: 'SVE Pair' },
  { value: 'SVE Run' },
  { value: 'SVE-Bigeminy' },
  { value: 'SVE-Trigeminy' },
  { value: 'Tachycardia' },
  { value: 'V-Tach' },
  { value: 'VE' },
  { value: 'VE Pair' },
  { value: 'VE Run' },
  { value: 'VE-Bigeminy' },
  { value: 'VE-Trigeminy' },
];

export const amplitudeOptions = [
  {
    value: 0.5, // mv
    label: '5mm/mV',
  },
  {
    value: 1, // mv
    label: '10mm/mV',
  },
  {
    value: 2, // mv
    label: '20mm/mV',
  },
];

export const speedOptions = [
  {
    value: 0.2,
    label: '5mm/s',
  },
  {
    value: 0.4,
    label: '10mm/s',
  },
  {
    value: 1,
    label: '25mm/s',
  },
  {
    value: 2,
    label: '50mm/s',
  },
];

const symptomOptions = [
  {
    label: symptomLabelsEnum[symptomEnum.accidental],
    value: symptomEnum.accidental,
  },
  {
    label: symptomLabelsEnum[symptomEnum.baseline],
    value: symptomEnum.baseline,
  },
  {
    label: symptomLabelsEnum[symptomEnum.chestPain],
    value: symptomEnum.chestPain,
  },
  {
    label: symptomLabelsEnum[symptomEnum.dizziness],
    value: symptomEnum.dizziness,
  },
  {
    label: symptomLabelsEnum[symptomEnum.fastHeartRate],
    value: symptomEnum.fastHeartRate,
  },
  {
    label: symptomLabelsEnum[symptomEnum.fluttering],
    value: symptomEnum.fluttering,
  },
  {
    label: symptomLabelsEnum[symptomEnum.palpitations],
    value: symptomEnum.palpitations,
  },
  {
    label: symptomLabelsEnum[symptomEnum.fatigued],
    value: symptomEnum.fatigued,
  },
  {
    label: symptomLabelsEnum[symptomEnum.passedOut],
    value: symptomEnum.passedOut,
  },
  {
    label: symptomLabelsEnum[symptomEnum.shortnessOfBreath],
    value: symptomEnum.shortnessOfBreath,
  },
  {
    label: symptomLabelsEnum[symptomEnum.irregularHeartbeat],
    value: symptomEnum.irregularHeartbeat,
  },
  {
    label: symptomLabelsEnum[symptomEnum.skippedBeat],
    value: symptomEnum.skippedBeat,
  },
];

const statusOptions = [
  {
    value: ecgEventStatus.accepted,
    label: ecgEventStatus.accepted,
  },
  {
    value: ecgEventStatus.included,
    label: ecgEventStatus.included,
  },
  {
    value: ecgEventStatus.denied,
    label: ecgEventStatus.denied,
  },
];

export const eventTriageStripSettings = [
  {
    name: 'amplitude',
    label: 'Amplitude',
    options: amplitudeOptions,
    defaultValue: amplitudeOptions[1].value,
  },
  {
    name: 'speed',
    label: 'Speed',
    options: speedOptions,
    defaultValue: speedOptions[2].value,
  },
];

export const symptomsField = {
  limitTags: 1,
  multiple: true,
  name: 'symptoms',
  label: 'Symptoms',
  optionLabel: 'label',
  optionValueName: 'value',
  disableCloseOnSelect: true,
  options: symptomOptions,
};

export const statusField = {
  name: 'status',
  label: 'Status',
  placeholder: 'Status',
  options: statusOptions,
};
