import { templateTypes } from 'common/constants/ecgEnums';
import { commonActionTypes } from 'common/ducks/actionTypes';
import { createRequestActionTypes } from 'common/utils/actionTypesHelper';
import { generateCancellableAction } from 'common/utils/actionHelpers';

import {
  eventsFilter,
  updateTemplatesWithAddedEvents,
  updateTemplatesWithUpdatedEvents,
  updateTemplatesWithRemovedEvents,
} from '../utils';

export const actionTypes = createRequestActionTypes(
  ['get', 'update', 'getTotal', 'ecg', 'status', 'significant', 'clear'],
  'itemsGrid'
);

const initialState = {
  ecg: {},
  events: [],
  total: 0,
  loading: false,
};

export default (state = initialState, action) => {
  const { type, payload, requestPayload } = action;

  switch (type) {
    case commonActionTypes.updateEcgEvents.requested:
    case actionTypes.get.requested:
      return {
        ...state,
        loading: true,
      };

    case actionTypes.get.success: {
      const { data, total } = payload;

      const newEcg = Object.keys(state.ecg).reduce((acc, id) => {
        const isEvent = data.find((e) => e.id === id);

        if (isEvent) {
          return {
            ...acc,
            [id]: state.ecg[id],
          };
        }

        return acc;
      }, {});

      return {
        ...state,
        events: data,
        ecg: newEcg,
        loading: false,
        total: total || data.length,
      };
    }

    case actionTypes.ecg.success:
      return {
        ...state,
        ecg: {
          ...state.ecg,
          [requestPayload.eventId]: payload.data,
        },
      };

    case commonActionTypes.updateEcgEvents.success:
    case commonActionTypes.updateEcgEvents.failed:
    case actionTypes.get.failed:
      return {
        ...state,
        loading: false,
      };

    case actionTypes.getTotal.success: {
      return { ...state, total: payload.data };
    }

    case actionTypes.update.requested: {
      const {
        added,
        updated,
        removed,
        status,
        abnormalityType,
        eventAbnormalities,
      } = action.payload;

      const targetEventAbnormalities = [eventAbnormalities]
        .flat()
        .filter(Boolean)
        .includes(abnormalityType)
        ? eventAbnormalities
        : [abnormalityType].flat();

      const isTemplate = templateTypes.includes(abnormalityType);

      let events = [...state.events];

      if (!isTemplate) {
        const newUpdated = updated.filter(
          (e) => !state.events.find(({ id }) => e.id === id)
        );

        const addedFiltered = [...added, ...newUpdated].filter((e) =>
          eventsFilter(e, status, targetEventAbnormalities)
        );

        events = events
          .map((event) => {
            const target = updated.find(({ id }) => id === event.id);

            if (target) {
              const peData = event.patientEvent
                ? { coreAbnormalityType: target.abnormalityType }
                : {};

              return {
                ...event,
                ...peData,
                status: target.status,
                rotateEcg: target.rotateEcg,
                customDateTo: target.customDateTo,
                customDateFrom: target.customDateFrom,
                abnormalityType: event.patientEvent
                  ? event.abnormalityType
                  : target.abnormalityType,
              };
            }

            return event;
          })
          .filter((e) => eventsFilter(e, status, targetEventAbnormalities))
          .filter((e) => !removed.find((rmEvt) => rmEvt.id === e.id))
          .concat(addedFiltered);
      }

      if (isTemplate && added?.length) {
        events = added?.length
          ? updateTemplatesWithAddedEvents(events, added, status)
          : events;
      }

      if (isTemplate && updated?.length) {
        events = updated.length
          ? updateTemplatesWithUpdatedEvents(events, updated, status)
          : events;
      }

      if (isTemplate && removed?.length) {
        events = removed.length
          ? updateTemplatesWithRemovedEvents(events, removed, status)
          : events;
      }

      return {
        ...state,
        events: events.filter(Boolean),
      };
    }

    case actionTypes.clear.requested:
      return initialState;

    default:
      return state;
  }
};

export const getEvents = (payload) => ({
  type: actionTypes.get.requested,
  payload,
});

export const getEventEcg = (payload, eventId) =>
  generateCancellableAction({
    type: actionTypes.ecg.requested,
    payload,
    eventId,
  });

export const setSignificant = (payload, onSuccess, onError) => ({
  type: actionTypes.significant.requested,
  payload,
  onError,
  onSuccess,
});

export const updateEvents = (payload) => ({
  type: actionTypes.update.requested,
  payload,
});

export const getTotal = (payload, abnormalityType) => ({
  type: actionTypes.getTotal.requested,
  payload,
  abnormalityType,
});

export const clearState = () => ({ type: actionTypes.clear.requested });
