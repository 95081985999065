import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import DeleteIco from '@material-ui/icons/Delete';

import { commonStatusTypes } from 'common/constants/enums';

const Delete = ({ entityData, disabled, ...props }) => {
  const showDelete = useMemo(
    () =>
      entityData
        ? entityData.status !== commonStatusTypes.deleted || !entityData.status
        : !entityData,
    [entityData]
  );

  if (!showDelete) {
    return null;
  }

  return (
    <Button
      variant="text"
      color="secondary"
      startIcon={<DeleteIco color={disabled ? 'disabled' : 'error'} />}
      disabled={disabled}
      {...props}
    >
      Delete
    </Button>
  );
};

Delete.propTypes = {
  disabled: PropTypes.bool,
  entityData: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.object,
      PropTypes.array,
    ])
  ),
};

export default Delete;
