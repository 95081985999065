import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { useForm } from 'react-hook-form';
import CloseIcon from '@material-ui/icons/Close';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import resources from 'common/constants/resources';
import { kitsActions } from 'common/modules/kits/ducks';
import { commonStatusTypes } from 'common/constants/enums';
import { useNotify } from 'common/modules/Notification/hooks';
import TextField from 'common/components/formFields/TextField';
import Button from 'common/components/buttons/ModalActionButton';
import Autocomplete from 'common/components/formFields/Autocomplete';

const queryParams = {
  hasActiveProcedures: false,
  statuses: commonStatusTypes.active,
};

const KitAssignModal = ({ entity, assigneType, refreshData, onClose }) => {
  const notify = useNotify();
  const dispatch = useDispatch();

  const entityFullName = useMemo(() => {
    const { fullName, firstName, lastName } = entity;
    if (fullName) {
      return fullName;
    }

    if (firstName || lastName) {
      return `${firstName} ${lastName}`;
    }

    return '';
  }, [entity]);

  const { control, formState, watch } = useForm({
    defaultValues: { fullName: entityFullName },
  });

  const kit = watch('kit');

  const close = () => {
    if (onClose) {
      onClose();
    }
  };

  const onSuccess = () => {
    notify.success('Kit successfully assigned');
    close();
    refreshData();
  };

  const onError = () => {
    notify.error('Error while assigning kit');
  };

  const onSubmit = () => {
    dispatch(
      kitsActions.assignKit(
        {
          assigneType,
          kitId: kit?.id,
          assigneId: entity?.id,
        },
        onSuccess,
        onError
      )
    );
  };

  return (
    <>
      <Grid
        container
        wrap="nowrap"
        alignItems="center"
        justifyContent="space-between"
      >
        <DialogTitle>
          <Typography component="span" variant="h3" color="primary">
            Assign Kit
          </Typography>
        </DialogTitle>

        <DialogTitle>
          <IconButton onClick={close}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
      </Grid>

      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <TextField
              disabled
              fullWidth
              form={formState}
              name="fullName"
              control={control}
              label={assigneType}
            />
          </Grid>

          <Grid item xs={6}>
            <Autocomplete
              name="kit"
              label="Kit"
              form={formState}
              control={control}
              optionValueName="id"
              optionLabel="serialNumber"
              queryParams={queryParams}
              resource={resources.kit.index}
              searchParams={{ notAssignedToId: entity?.id }}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button disabled={!entity || !kit} onClick={onSubmit} color="primary">
          Assign
        </Button>
      </DialogActions>
    </>
  );
};

KitAssignModal.propTypes = {
  onClose: PropTypes.func,
  refreshData: PropTypes.func,
  assigneType: PropTypes.string,
  entity: PropTypes.shape({
    id: PropTypes.string,
    fullName: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
};

export default KitAssignModal;
