import { requestReducer } from 'common/utils/generators';
import { createRequestActionTypes } from 'common/utils/actionTypesHelper';

import { onSuccessFetch } from '../utils';

export const procedureActionTypes = createRequestActionTypes(
  [
    'getList',
    'create',
    'getOne',
    'update',
    'updateStatuses',
    'getRecentProceduresList',
  ],
  'procedures'
);

const initialState = {
  data: [],
  loading: false,
  error: false,
};

const procedureReducer = requestReducer(
  [
    procedureActionTypes.getList,
    procedureActionTypes.getOne,
    procedureActionTypes.update,
    procedureActionTypes.getRecentProceduresList,
  ],
  initialState,
  onSuccessFetch
);

export const getProcedureList = (payload) => ({
  type: procedureActionTypes.getList.requested,
  payload,
});

export const getRecentProceduresList = (payload) => ({
  type: procedureActionTypes.getRecentProceduresList.requested,
  payload,
});

export const getProcedure = ({ id }) => ({
  type: procedureActionTypes.getOne.requested,
  payload: { id },
});

export const updateProcedure = (payload, onSuccess, onError) => ({
  type: procedureActionTypes.update.requested,
  payload,
  onSuccess,
  onError,
});

export const updateStatuses = (payload, onSuccess, onError) => ({
  type: procedureActionTypes.updateStatuses.requested,
  payload,
  onSuccess,
  onError,
});

export const createProcedure = (payload, onSuccess, onError) => {
  return {
    type: procedureActionTypes.create.requested,
    payload,
    onSuccess,
    onError,
  };
};

export default procedureReducer;
