import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';
import { useSelector, useDispatch } from 'react-redux';

import Undo from 'common/modules/Undo';
import { getNotification } from './ducks/selectors';
import { hideNotification } from './ducks';

const undoAnchorOrigin = { vertical: 'top', horizontal: 'center' };

const VCAlert = ({
  children = null,
  onClose = () => null,
  severity = null,
  ...props
}) => {
  const renderAlert = (alertProps) => <Alert {...alertProps} {...props} />;

  return (
    <Grid
      container
      onClose={onClose}
      severity={severity}
      component={renderAlert}
    >
      {children}
    </Grid>
  );
};

const Notification = ({ type, autoHideDuration, ...props }) => {
  const [open, setOpen] = useState(false);
  const notification = useSelector(getNotification);
  const dispatch = useDispatch();

  useEffect(() => {
    setOpen(!!notification?.message);
  }, [notification]);

  const handleRequestClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleExited = useCallback(() => {
    dispatch(hideNotification());
  }, [dispatch]);

  const undoAction = useMemo(
    () =>
      notification?.undoable && (
        <Undo delay={autoHideDuration} onClose={handleExited} />
      ),
    [notification?.undoable, autoHideDuration, handleExited]
  );

  const autoHideAfter = useMemo(
    () =>
      typeof notification.autoHideDuration === 'undefined'
        ? autoHideDuration
        : notification.autoHideDuration,
    [notification, autoHideDuration]
  );

  return (
    <Snackbar
      open={open}
      title={notification?.message || ''}
      onClose={handleRequestClose}
      message={notification?.message}
      anchorOrigin={notification.undoable ? undoAnchorOrigin : undefined}
      TransitionProps={{ onExited: handleExited }}
      autoHideDuration={autoHideAfter}
      disableWindowBlurListener={notification?.undoable}
      {...props}
    >
      <VCAlert
        action={notification?.undoable && undoAction}
        onClose={handleRequestClose}
        severity={notification?.type || type}
      >
        <Typography variant="body1" style={{ lineHeight: 1.4 }}>
          {notification?.message}
        </Typography>
      </VCAlert>
    </Snackbar>
  );
};

Notification.propTypes = {
  type: PropTypes.string,
  autoHideDuration: PropTypes.number,
};

Notification.defaultProps = {
  type: 'info',
  autoHideDuration: 10000,
};

export default Notification;
