import { eventStripReducerNames } from 'common/constants/enums';
import { requestSaga } from 'common/utils/generators';
import resources from 'common/constants/resources';
import dataProvider from 'store/dataProvider';
import { actionTypes } from '../index';

export function* updateEventsOnStripSaga() {
  yield requestSaga({
    actionType: actionTypes[eventStripReducerNames.eventTriageStrip].update,
    request: dataProvider.createOne,
    resource: resources.eventGroup.review,
  });
}

export function* updateEventsOnMonitoringSaga() {
  yield requestSaga({
    actionType: actionTypes[eventStripReducerNames.monitoringEventStrip].update,
    request: dataProvider.createOne,
    resource: resources.eventGroup.review,
  });
}

export function* setCustomViewOnStripSaga() {
  yield requestSaga({
    actionType:
      actionTypes[eventStripReducerNames.eventTriageStrip].setCustomPeriod,
    request: dataProvider.updateMany,
    resource: resources.eventGroup.setCustomPeriod,
  });
}

export function* setCustomViewOnMonitoringSaga() {
  yield requestSaga({
    actionType:
      actionTypes[eventStripReducerNames.monitoringEventStrip].setCustomPeriod,
    request: dataProvider.updateMany,
    resource: resources.eventGroup.setCustomPeriod,
  });
}
