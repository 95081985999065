import unionBy from 'lodash/unionBy';
import { createRequestActionTypes } from 'common/utils/actionTypesHelper';
import { requestReducer } from 'common/utils/generators';

export const phonesActionTypes = createRequestActionTypes(
  ['getList', 'getOne', 'update', 'updateMany', 'updateStatus', 'create'],
  'phones'
);

const initialState = {
  loading: false,
  data: [],
  total: 0,
  error: false,
};

export default requestReducer(
  [
    phonesActionTypes.getList,
    phonesActionTypes.getOne,
    phonesActionTypes.update,
  ],
  initialState,
  (payload, state) => {
    const { data, total } = payload;
    return {
      ...state,
      loading: false,
      data: Array.isArray(data) ? data : unionBy([data], state.data, 'id'),
      total,
    };
  }
);

export const getPhoneList = (payload) => ({
  type: phonesActionTypes.getList.requested,
  payload,
});

export const getPhone = ({ id }) => ({
  type: phonesActionTypes.getOne.requested,
  payload: { id },
});

export const updatePhone = (payload, onSuccess, onError) => ({
  type: phonesActionTypes.update.requested,
  payload,
  onSuccess,
  onError,
});

export const updatePhones = (payload, onSuccess, onError) => ({
  type: phonesActionTypes.updateMany.requested,
  payload,
  onSuccess,
  onError,
});

export const updatePhoneStatus = (payload, onSuccess, onError) => ({
  type: phonesActionTypes.updateStatus.requested,
  payload,
  onSuccess,
  onError,
});

export const createPhone = (payload, onSuccess, onError) => ({
  type: phonesActionTypes.create.requested,
  payload,
  onSuccess,
  onError,
});
