import React, { memo, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import { containerChildProps } from 'common/constants/sharedPropTypes';

const WIDTH = 45;

const D3SymptomRect = ({ parent, position, width, strokeWidth, onClick }) => {
  const { height, clip, scale, xScale } = parent;
  const sx = scale || xScale;

  const posX = useMemo(() => sx(new Date(position)), [sx, position]);

  const onRectClick = useCallback(() => onClick(), [onClick]);

  return (
    <rect
      id="symptom"
      stroke="red"
      fillOpacity="0"
      clipPath={clip}
      strokeDasharray={4}
      onClick={onRectClick}
      strokeWidth={strokeWidth}
      width={Math.max(width, 0)}
      x={posX}
      y={height / 4}
      height={height - height / 1.6}
    />
  );
};

D3SymptomRect.defaultProps = {
  onClick: () => null,
  strokeWidth: 5,
  width: WIDTH,
};

D3SymptomRect.propTypes = {
  strokeWidth: PropTypes.number,
  parent: containerChildProps,
  position: PropTypes.string,
  onClick: PropTypes.func,
  width: PropTypes.number,
};

export default memo(D3SymptomRect);
