import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  buttonText: {
    textTransform: 'none',
    color: (props) => (props.white ? theme.palette.text.light : 'inherit'),

    [theme.breakpoints.down(1501)]: {
      fontSize: '0.75rem',
    },

    [theme.breakpoints.down(1367)]: {
      fontSize: '0.7rem',
    },
  },
}));

const ModalActionButton = ({
  className,
  children,
  variant = 'button',
  endIcon = undefined,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Button
      variant="contained"
      color="default"
      className={className}
      {...rest}
      endIcon={endIcon}
    >
      <Typography className={classes.buttonText} variant={variant}>
        {children}
      </Typography>
    </Button>
  );
};

ModalActionButton.propTypes = {
  endIcon: PropTypes.element,
  variant: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default ModalActionButton;
