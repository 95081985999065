import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';

const useStyles = makeStyles(({ palette, spacing }) => ({
  card: {
    padding: spacing(1.5, 1),
  },
  headerContainer: {
    padding: spacing(1, 2),
  },
  title: {
    color: palette.text.colored,
  },
  subtitle: {
    paddingTop: spacing(0.5),
    color: palette.text.secondary,
  },
}));

const CardSection = ({
  title,
  subtitle,
  children,
  elevation = 1,
  radius = 4,
  cardProps,
  rightContent,
  titleProps,
  headerProps,
  titleVariant,
  ...restBoxProps
}) => {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      {...restBoxProps}
    >
      <Card
        elevation={elevation}
        className={classes.card}
        {...cardProps}
        style={{ borderRadius: radius, ...(cardProps?.style || {}) }}
      >
        <Grid
          container
          direction="column"
          alignItems="flex-start"
          className={classes.headerContainer}
          {...headerProps}
        >
          <Grid
            item
            container
            direction="row"
            alignItems="center"
            {...titleProps}
          >
            <Typography
              item
              component={Grid}
              variant={titleVariant}
              className={classes.title}
              data-testid="profile-card-section-title"
            >
              {title}
            </Typography>

            {rightContent || null}
          </Grid>
          {subtitle && (
            <Typography variant="body2" className={classes.subtitle}>
              {subtitle}
            </Typography>
          )}
        </Grid>
        {children}
      </Card>
    </Grid>
  );
};

CardSection.defaultProps = {
  rightContent: null,
  titleVariant: 'h3',
};

CardSection.propTypes = {
  radius: PropTypes.number,
  subtitle: PropTypes.string,
  elevation: PropTypes.number,
  titleVariant: PropTypes.string,
  title: PropTypes.string.isRequired,
  rightContent: PropTypes.element,
  children: PropTypes.element.isRequired,
  cardProps: PropTypes.objectOf(PropTypes.any),
  titleProps: PropTypes.objectOf(PropTypes.any),
  headerProps: PropTypes.objectOf(PropTypes.any),
};

export default CardSection;
