import { updateEventsBySocket } from 'common/utils/helpers/socket';
import { createRequestActionTypes } from 'common/utils/actionTypesHelper';
import { eventStripReducerNames } from 'common/constants/enums';
import { commonActionTypes } from 'common/ducks/actionTypes';

const actionTypeKeys = [
  'strip',
  'update',
  'updateSymptoms',
  'getMinuteHRSummary',
  'socketMessageUpdate',
  'socketMessagePEUpdate',
  'setVisibleRange',
  'getPatientEventById',
  'setCustomPeriod',
  'clear',
];

export const actionTypes = Object.keys(eventStripReducerNames).reduce(
  (acc, val) => ({
    ...acc,
    [val]: createRequestActionTypes(actionTypeKeys, val),
  }),
  {}
);

const initialState = {
  loading: true,
  error: null,
  updateLoading: false,
  data: {
    ecg: [],
    beats: [],
    summary: null,
    eventGroups: [],
    patientEvents: [],
  },
  visibleRange: null,
};

const generateEventStripReducer =
  (reducerName) =>
  (state = initialState, action) => {
    switch (action.type) {
      case actionTypes[reducerName].setVisibleRange.requested:
        return {
          ...state,
          visibleRange: action.payload,
        };

      case actionTypes[reducerName].getMinuteHRSummary.requested:
        return { ...state, loading: true };

      case actionTypes[reducerName].getMinuteHRSummary.success:
        return { ...state, loading: false };

      case actionTypes[reducerName].strip.requested: {
        return {
          ...state,
          loading: true,
          data: {
            ...state.data,
            ecg: [],
          },
        };
      }

      case actionTypes[reducerName].strip.success: {
        const { ecg, ...procedure } = action.payload;

        return {
          ...state,
          loading: false,
          data: {
            ...state.data,
            ...procedure,
            ecg,
          },
        };
      }

      case actionTypes[reducerName].socketMessageUpdate.requested: {
        const { status } = action;

        return {
          ...state,
          data: {
            ...state.data,
            eventGroups: updateEventsBySocket(
              state.data.eventGroups,
              action.payload,
              status
            ),
          },
        };
      }
      case actionTypes[reducerName].strip.failed:
        return {
          ...state,
          data: initialState.data,
          loading: false,
          error: action.payload,
        };

      case actionTypes[reducerName].socketMessagePEUpdate.requested: {
        const { status } = action;

        return {
          ...state,
          data: {
            ...state.data,
            patientEvents: updateEventsBySocket(
              state.data.patientEvents,
              action.payload,
              status
            ),
          },
        };
      }

      case actionTypes[reducerName].getPatientEventById.success: {
        const { status } = action.requestAction;

        return {
          ...state,
          data: {
            ...state.data,
            patientEvents: updateEventsBySocket(state.data.patientEvents, [
              action.payload.data,
              status,
            ]),
          },
        };
      }

      case commonActionTypes.setInvertEcg.requested:
        return { ...state, loading: true };

      case commonActionTypes.setInvertEcg.success: {
        return {
          ...state,
          loading: false,
        };
      }

      case commonActionTypes.setInvertEcg.failed:
        return { ...state, loading: false };

      case actionTypes[reducerName].clear.requested: {
        return initialState;
      }

      default:
        return state;
    }
  };

export const generatedReducer = Object.keys(eventStripReducerNames).reduce(
  (acc, val) => ({
    ...acc,
    [val]: generateEventStripReducer(val),
  }),
  {}
);

export const getStrip = (
  payload,
  reducerType = eventStripReducerNames.eventTriageStrip
) => ({
  type: actionTypes[reducerType].strip.requested,
  payload,
});

export const updateEvents = (
  events,
  reducerType = eventStripReducerNames.eventTriageStrip,
  onSuccess = null
) => ({
  type: actionTypes[reducerType].update.requested,
  payload: { body: events },
  onSuccess,
});

export const updateSymptoms = (
  procedureId,
  event,
  id,
  reducerType = eventStripReducerNames.eventTriageStrip,
  onSuccess = null
) => ({
  type: actionTypes[reducerType].updateSymptoms.requested,
  payload: { id, procedureId, event },
  onSuccess,
});

export const socketMessageUpdate = (
  payload,
  status,
  reducerName = eventStripReducerNames.eventTriageStrip
) => ({
  type: actionTypes[reducerName].socketMessageUpdate.requested,
  payload,
  status,
});

export const socketMessagePEUpdate = (
  payload,
  status,
  reducerName = eventStripReducerNames.eventTriageStrip
) => ({
  type: actionTypes[reducerName].socketMessagePEUpdate.requested,
  status,
  payload,
});

export const getHRSummaryForOneMinute = (filter, resource, onSuccess) => ({
  type: actionTypes[resource].getMinuteHRSummary.requested,
  payload: { filter },
  onSuccess,
});

export const getPatientEventById = (
  payload,
  status,
  resource,
  onSuccess,
  onError
) => ({
  type: actionTypes[resource].getPatientEventById.requested,
  status,
  payload,
  onError,
  onSuccess,
});

export const clearState = (
  reducerType = eventStripReducerNames.eventTriageStrip
) => ({
  type: actionTypes[reducerType].clear.requested,
});

export const setCustomPerios = (
  query,
  reducerType = eventStripReducerNames.eventTriageStrip
) => ({
  type: actionTypes[reducerType].setCustomPeriod.requested,
  payload: { query },
});

export const setVisibleRange = (
  range,
  reducerType = eventStripReducerNames.eventTriageStrip
) => ({
  type: actionTypes[reducerType].setVisibleRange.requested,
  payload: range,
});

export default generatedReducer;
