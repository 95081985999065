import moment from 'moment';
import { takeEvery, put, call, all } from 'redux-saga/effects';

import dataProvider from 'store/dataProvider';
import resources from 'common/constants/resources';
import { abnormalityTypeEnum } from 'common/constants/ecgEnums';

import { actionTypes } from '../index';

function* preparePatientEvents(patientEvents, filter) {
  const PEEventsRequests = patientEvents.map(
    ({ id, date, ...eventData }, index) => {
      const ecgDateTo = moment(date).add(3, 'second').toISOString();
      const ecgDateFrom = moment(date).subtract(3, 'second').toISOString();

      return {
        id,
        date,
        procedureId: filter?.procedureId,
        ...eventData,
        dateTo: ecgDateTo,
        patientEventId: id,
        patientEvent: true,
        dateFromEvent: date,
        dateFrom: ecgDateFrom,
        eventNumber: index + 1,
        coreAbnormalityType: null,
        abnormalityType: abnormalityTypeEnum.patient,
      };
    }
  );

  const events = yield all(PEEventsRequests);

  return events.flat();
}

const getEventGroupsForRow =
  (pagination, procedureId) => async (abnormalityTypes) => {
    const response = await dataProvider.getList(resources.eventGroup.byPeriod, {
      pagination,
      filter: { procedureId, abnormalityTypes, reviewed: false },
    });

    return {
      ...response,
      abnormalityType: abnormalityTypes,
    };
  };

function* getEscalatedEvents(action) {
  const {
    payload: { filter, pagination },
  } = action;
  const { procedureId, abnormalityTypes } = filter;

  const abnormalities = abnormalityTypes.filter(
    (type) => type !== abnormalityTypeEnum.patient
  );

  try {
    const response = yield all(
      abnormalities.map(getEventGroupsForRow(pagination, procedureId))
    );

    const { data: responsePatientEvents = [], total: peTotal = 0 } =
      filter.events.includes(abnormalityTypeEnum.patient)
        ? yield call(dataProvider.getList, resources.healthData.patientEvents, {
            pagination,
            filter: { procedureId, status: null },
          })
        : {};

    const preparedPatientEvents = yield call(
      preparePatientEvents,
      responsePatientEvents,
      filter
    );

    const totals = response.reduce(
      (acc, val) => ({ ...acc, [val.abnormalityType]: val.total }),
      { [abnormalityTypeEnum.patient]: peTotal }
    );

    const events = response.map(({ data }) => data).flat();

    const allEvents = [...events, ...preparedPatientEvents];

    yield put({
      type: actionTypes.getEscalatedEvents.success,
      payload: { data: allEvents, totals },
    });
  } catch ({ message }) {
    yield put({
      type: actionTypes.getEscalatedEvents.failed,
      payload: message,
    });
  }
}

function* getEscalatedEventsSaga() {
  yield takeEvery(actionTypes.getEscalatedEvents.requested, getEscalatedEvents);
}

export default getEscalatedEventsSaga;
