import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const NotFound = () => (
  <Grid
    container
    direction="column"
    justifyContent="center"
    alignItems="center"
  >
    <Typography variant="h1">404</Typography>
    <Typography variant="h2">Page not found</Typography>
  </Grid>
);

export default NotFound;
