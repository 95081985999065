import React from 'react';

import { axesGridChildrenPropTypes } from 'common/constants/sharedPropTypes';

const LINE_WIDTH = 4;
const LINE_BOTTOM_OFFSET = 8;

const EventLine = ({
  data,
  yPoint,
  parent = {},
  color = '#000',
  lineWidth = LINE_WIDTH,
  noScale = false,
}) => {
  const {
    clip,
    scale,
    height,
    options = {},
    xScale = () => null,
    yScale = () => null,
  } = parent;
  const sx = scale || xScale;
  const scaledYPoint = noScale ? yPoint : yScale(yPoint);
  const y = yPoint ? scaledYPoint : null;
  const { xPropKey } = options;

  return (
    <line
      clipPath={clip}
      stroke={color}
      strokeWidth={lineWidth}
      x1={sx(new Date(data[0][xPropKey]))}
      x2={sx(new Date(data[1][xPropKey]))}
      y1={y || height - LINE_BOTTOM_OFFSET}
      y2={y || height - LINE_BOTTOM_OFFSET}
    />
  );
};

EventLine.propTypes = axesGridChildrenPropTypes;

export default EventLine;
