import React, { useCallback, useEffect, useMemo, useState } from 'react';
import orderBy from 'lodash/orderBy';
import moment from 'moment';
import * as d3 from 'd3';

import {
  trendEventsByAxis,
  abnormalityTypeEnum,
} from 'common/constants/ecgEnums';
import createEnum from 'common/utils/actionTypesHelper/createEnum';
import { getFirstEqualOrAfterTimeIndex } from 'common/utils/helpers/date';
import Popper from './Popper';

const activityClassifications = createEnum({
  none: 'None',
  low: 'Low',
  medium: 'Medium',
  high: 'High',
});

const activityColors = createEnum({
  [activityClassifications.none]: '#ADDCFF',
  [activityClassifications.low]: '#182EF2',
  [activityClassifications.medium]: '#FFFF00',
  [activityClassifications.high]: '#ce1717',
  default: null,
});

const y = +trendEventsByAxis[abnormalityTypeEnum.activities];

const ActivityLine = ({ data = {}, parent }) => {
  const { activities = [], bodyPositions } = data || {};
  const { xScale, yScale, clip = {}, scale } = parent;

  const [hoverData, setHoverData] = useState(null);

  const xs = useMemo(() => scale || xScale, [scale, xScale]);

  const lineData = useMemo(() => {
    const sortedActivities = orderBy(activities, ['date'], 'asc');
    return sortedActivities.reduce((acc, item, index) => {
      if (!acc.length) {
        return [...acc, item];
      }

      const isLast = index === sortedActivities.length - 1;
      if (isLast) {
        return [
          ...acc,
          {
            ...item,
            date: moment(item.date).add(1, 'millisecond').toISOString(),
          },
        ];
      }

      const next = sortedActivities[index + 1];

      if (!next) {
        return acc;
      }

      const isNullPoint = moment(next.date).diff(item.date) > 9000;
      const closePoint = {
        date: moment(item.date).add(1, 'millisecond').toISOString(),
        value: item.value,
      };
      const nullPoint = {
        date: moment(next.date).subtract(1, 'millisecond').toISOString(),
        value: null,
      };

      if (isNullPoint) {
        return [...acc, closePoint, nullPoint, next];
      }

      acc[acc.length - 1] = item;

      return acc;
    }, []);
  }, [activities]);

  const onMouseMove = useCallback(
    (event) => {
      event.preventDefault();
      const [mouseX, mouseY] = d3.pointer(event);
      const xDate = xs.invert(mouseX);
      const yValue = yScale.invert(mouseY);

      const hoverMin = y - 10;
      const hoverMax = y + 10;

      if (yValue > hoverMax || y < hoverMin) {
        setHoverData(null);
        return;
      }

      const activityIndex = getFirstEqualOrAfterTimeIndex(
        lineData,
        xDate,
        'date'
      );
      const bodyPositionIndex = getFirstEqualOrAfterTimeIndex(
        bodyPositions,
        xDate,
        'date'
      );

      const activity = lineData[activityIndex].value;
      const bodyPosition = bodyPositions[bodyPositionIndex];

      setHoverData({
        activity,
        bodyPosition: bodyPosition.value,
        hoverCenter: xDate,
      });
    },
    [bodyPositions, lineData, yScale, xs]
  );

  const onMouseleave = useCallback(() => {
    setHoverData(null);
  }, []);

  const handleHover = useCallback(() => {
    const lines = d3.selectAll('.activityAndBodyPositionLine');
    lines.on('mousemove', onMouseMove);
    lines.on('mouseleave', onMouseleave);

    return () => {
      lines.on('mousemove', null);
      lines.on('mouseleave', null);
    };
  }, [onMouseMove, onMouseleave]);

  const lineRenderer = useCallback(
    (pathD) =>
      d3
        .line()
        .x((d) => xs(new Date(d.date)))
        .y(yScale(y))
        .defined((d) => !!d?.value)(pathD),
    [yScale, xs]
  );

  useEffect(() => handleHover(), [handleHover]);

  if (!activities?.length) {
    return null;
  }

  return (
    <g className="activityAndBodyPositionLine">
      <path
        clipPath={clip}
        strokeWidth={8}
        stroke={activityColors[activityClassifications.high]}
        d={lineRenderer(lineData)}
      />

      {!!hoverData && (
        <Popper
          title={`Activity - ${hoverData.activity || 'N/A'}`}
          content={`Body position - ${hoverData.bodyPosition || 'N/A'}`}
        >
          <circle
            r={10}
            cy={yScale(y)}
            cx={xs(hoverData.hoverCenter)}
            fill="transparent"
          />
        </Popper>
      )}
    </g>
  );
};

export default ActivityLine;
