import { modalsActionTypes } from 'common/ducks/actionTypes';

const initialState = [];

const modalsReducer = (state = initialState, { type, ...action }) => {
  switch (type) {
    case modalsActionTypes.close.requested:
      return [...state].filter(({ modal }) => modal !== action.modal);

    case modalsActionTypes.open.requested: {
      const existed = state.find(({ modal }) => modal === action.modal);
      if (existed) {
        return [...state].map((modalData) =>
          modalData.modal === action.modal
            ? {
                ...modalData,
                ...action,
                payload: { ...modalData.payload, ...action.payload },
              }
            : modalData
        );
      }

      return [...state, action];
    }

    case modalsActionTypes.closeAll.requested:
      return initialState;

    default:
      return state;
  }
};

export const closeModal = (modal) => ({
  type: modalsActionTypes.close.requested,
  modal,
});

export const closeAllModals = () => ({
  type: modalsActionTypes.closeAll.requested,
});

export default modalsReducer;
