import React from 'react';
import { Link } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';

import { stack } from 'common/constants/path';

import mainLogo from 'assets/images/main_logo.png';

const useStyles = makeStyles(({ palette, ...theme }) => ({
  container: {
    backgroundColor: palette.primary.dark,
  },
  card: {
    height: 66,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    color: palette.common.white,
    backgroundColor: palette.primary.main,
    textDecoration: 'none',
  },
  logoContainer: {
    height: 50,
    marginLeft: theme.spacing(3),
  },
  textContainer: {
    color: 'inherit',
    marginLeft: theme.spacing(2),
  },
}));

const MainLogo = () => {
  const classes = useStyles();
  return (
    <Grid className={classes.container} data-testid="main-logo">
      <Card
        elevation={3}
        component={Link}
        className={classes.card}
        to={stack.private.dashboard.url}
      >
        <div className={classes.logoContainer}>
          <img src={mainLogo} height="100%" alt="versaCardio logo" />
        </div>

        <div className={classes.textContainer}>
          <Typography variant="h4">VERSA CARDIO</Typography>
          <Typography variant="h6">Cardiac Monitoring</Typography>
        </div>
      </Card>
    </Grid>
  );
};

export default MainLogo;
