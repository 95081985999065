import React, { useMemo } from 'react';

import { ecgTypeColors } from 'common/constants/ecgEnums';
import {
  eventType,
  containerChildProps,
} from 'common/constants/sharedPropTypes';

const D3EventLine = ({ parent, event }) => {
  const { height, clip, scale } = parent;

  const yPosition = useMemo(() => height - 10, [height]);

  return (
    <line
      y1={yPosition}
      y2={yPosition}
      strokeWidth={5}
      clipPath={clip}
      x1={scale && scale(new Date(event.dateFrom))}
      x2={scale && scale(new Date(event.dateTo))}
      stroke={ecgTypeColors[event.abnormalityType]}
    />
  );
};

D3EventLine.propTypes = {
  event: eventType,
  parent: containerChildProps,
};

export default D3EventLine;
