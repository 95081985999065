import { takeLatest, put, call } from 'redux-saga/effects';
import resources from 'common/constants/resources';
import dataProvider from 'store/dataProvider';
import { phonesActionTypes as actionTypes } from '../reducer';

function* getPhone(action) {
  try {
    const phone = yield call(
      dataProvider.getOne,
      resources.phone.index,
      action.payload
    );

    if (phone.data?.simCardId) {
      const simCard = yield call(dataProvider.getOne, resources.simCard.item, {
        id: phone.data.simCardId,
      });
      phone.data.simCard = simCard?.data;
    }

    yield put({
      type: actionTypes.getOne.success,
      payload: phone,
    });
  } catch (error) {
    yield put({
      type: actionTypes.getList.failed,
      payload: error,
    });
  }
}

export default function* get() {
  yield takeLatest(actionTypes.getOne.requested, getPhone);
}
