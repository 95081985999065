import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

const Popper = ({ children = null, title = '', content = '', ...props }) => {
  const titleNode = (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Typography variant="h4">{title}</Typography>
      <Typography variant="h5">{content}</Typography>
    </Grid>
  );

  return (
    <Tooltip {...props} title={titleNode}>
      {children}
    </Tooltip>
  );
};

Popper.defaultProps = {
  placement: 'top',
};

Popper.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  placement: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.func,
    PropTypes.object,
  ]),
};

export default Popper;
