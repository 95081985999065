import { takeLatest, put, call } from 'redux-saga/effects';
import resources from 'common/constants/resources';
import dataProvider from 'store/dataProvider';
import { actionTypes } from '../reducer';

function* getSimCard(action) {
  try {
    const simCard = yield call(
      dataProvider.getOne,
      resources.simCard.index,
      action.payload
    );

    yield put({
      type: actionTypes.getOne.success,
      payload: simCard,
    });
  } catch (error) {
    yield put({
      type: actionTypes.getList.failed,
      payload: error,
    });
  }
}

export default function* get() {
  yield takeLatest(actionTypes.getOne.requested, getSimCard);
}
