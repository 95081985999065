import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { escalatedReportActions } from '../ducks';

const useCloseEscalatedReportOnApprove = () => {
  const dispatch = useDispatch();

  const closeReportModal = useCallback(
    () => dispatch(escalatedReportActions.closeEscalatedReportModal()),
    [dispatch]
  );

  const clearReport = useCallback(
    () => dispatch(escalatedReportActions.clearReportState()),
    [dispatch]
  );

  return useCallback(() => {
    clearReport();
    closeReportModal();
  }, [closeReportModal, clearReport]);
};

export default useCloseEscalatedReportOnApprove;
