import { call, put, takeLatest } from 'redux-saga/effects';

import { eventStripReducerNames } from 'common/constants/enums';
import { queryingPath } from 'common/utils/helpers/url';
import resources from 'common/constants/resources';
import dataProvider from 'store/dataProvider';
import { actionTypes } from '../index';

const resource = resources.healthData.updatePatientEvents;

const generateUpdateSymptoms = (actionType) =>
  function* updateSymptoms(action) {
    const {
      onSuccess,
      payload: { id: patientEventId, procedureId, event },
    } = action;

    try {
      const url = queryingPath(resource, { procedureId, patientEventId });
      const response = yield call(dataProvider.updateOnly, url, event);

      if (onSuccess) {
        yield call(onSuccess, response);
      }
    } catch (error) {
      yield put({
        type: actionType.updateSymptoms.failed,
        payload: error,
      });
    }
  };

export default function* updateSymptomsSaga() {
  yield takeLatest(
    actionTypes[eventStripReducerNames.eventTriageStrip].updateSymptoms
      .requested,
    generateUpdateSymptoms(actionTypes[eventStripReducerNames.eventTriageStrip])
  );
  yield takeLatest(
    actionTypes[eventStripReducerNames.monitoringEventStrip].updateSymptoms
      .requested,
    generateUpdateSymptoms(actionTypes[eventStripReducerNames.eventTriageStrip])
  );
}
