import {
  yesNo,
  genderTypes,
  commonStatuses,
  relationshipTypes,
} from 'common/constants/formFieldOptions';
import resources from 'common/constants/resources';
import { commonStatusTypes } from 'common/constants/enums';
import Datepicker from 'common/components/formFields/Datepicker';
import PhoneMaskInput from 'common/components/inputMasks/PhoneMaskInput';
import ResourceSelectField from 'common/components/formFields/ResourceSelectField';

export default [
  {
    name: 'firstName',
    label: 'Patient First Name',
    required: true,
  },
  {
    name: 'lastName',
    label: 'Patient Last Name',
    required: true,
  },
  {
    name: 'middleName',
    label: 'Patient Middle Name',
  },
  {
    name: 'phone',
    label: 'Phone',
    InputProps: {
      inputComponent: PhoneMaskInput,
    },
  },
  {
    name: 'phoneCell',
    label: 'Mobile Phone',
    InputProps: {
      inputComponent: PhoneMaskInput,
    },
  },
  {
    name: 'gender',
    label: 'Sex',
    select: true,
    options: genderTypes,
  },
  {
    name: 'status',
    label: 'Status',
    select: true,
    required: true,
    options: commonStatuses,
    defaultValue: commonStatusTypes.active,
  },
  {
    name: 'fax',
    label: 'Fax',
    InputProps: {
      inputComponent: PhoneMaskInput,
    },
  },
  {
    name: 'personalId',
    label: 'Patient ID',
  },
  {
    name: 'relationship',
    label: 'Relationship to Patient',
    select: true,
    options: relationshipTypes,
  },
  {
    name: 'email',
    label: 'Email',
  },
  {
    name: 'birthDate',
    label: 'DOB',
    maxDate: new Date(),
    component: Datepicker,
  },
  {
    name: 'physicianPersonalId',
    label: 'Physician Assigned ID',
    xs: 8,
  },
  {
    name: 'age',
    label: 'Age',
    disabled: true,
  },
  {
    name: 'hasPacemaker',
    label: 'Patient Has Pacemaker',
    select: true,
    options: yesNo,
    defaultValue: '0',
  },
  {
    name: 'street',
    label: 'Street',
  },
  {
    name: 'street2',
    label: 'Street 2',
  },
  {
    name: 'city',
    label: 'City',
  },
  {
    name: 'stateId',
    label: 'State',
    resource: resources.state.index,
    component: ResourceSelectField,
  },
  {
    name: 'zipCode',
    label: 'Zip Code',
  },
  {
    name: 'primaryContact',
    label: 'Primary Contact',
  },
  {
    name: 'lastProcedureDate',
    label: 'Last Procedure Date',
    editOnly: true,
    disabled: true,
    component: Datepicker,
  },
  {
    name: 'primaryInsurance',
    label: 'Primary Insurance',
  },
  {
    name: 'secondaryInsurance',
    label: 'Secondary Insurance',
  },
  {
    name: 'insurancePhone',
    label: 'Insurance Phone',
    InputProps: {
      inputComponent: PhoneMaskInput,
    },
  },
  {
    name: 'insuranceMemberId',
    label: 'Member Id',
  },
  {
    name: 'insuranceGroupId',
    label: 'Group Id',
  },
  {
    name: 'notes',
    label: 'Special Notes',
    multiline: true,
    minRows: 5,
    maxRows: 5,
    xs: 12,
  },
];
