import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { match } from 'path-to-regexp';

import { urlEnum } from 'common/constants/path';
import { parseQuery } from '../utils/helpers/url';

const useRouterInfo = (defaultInfo = null) => {
  const [info, setInfo] = useState(defaultInfo);
  const { pathname, search, state } = useLocation();

  useEffect(() => {
    setInfo(() =>
      Object.keys(urlEnum).reduce((acc, item) => {
        const parse = match(item, { decode: decodeURIComponent });
        const found = parse(pathname);
        if (!found) {
          return acc;
        }

        return {
          ...urlEnum[item],
          state,
          search: parseQuery(search),
          params: found.params,
          finalPath: found.path,
        };
      }, defaultInfo)
    );
  }, [pathname, search, state, defaultInfo]);

  return info;
};

export default useRouterInfo;
