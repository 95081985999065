import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { useController } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import sortBy from 'lodash/sortBy';
import get from 'lodash/get';

import { createEnum } from 'common/utils/actionTypesHelper';
import { roleLabels } from 'common/constants/enums';
import resources from 'common/constants/resources';
import dataProvider from 'store/dataProvider';

import TextInput from '../../simpleInputs/TextInput';
import useStyles from '../TextField/styles';

const resourcesSortingMethods = createEnum({
  [resources.state.index]: (data) => sortBy(data, 'name'),
  [resources.role.index]: (data) =>
    data.map((role) => ({ ...role, name: roleLabels[role.name] })),
  default: (data) => data,
});

const sanitizeProps = ({ onInputChange, ...props }) => props;

const ResourceSelectField = ({
  form,
  name,
  label,
  rules,
  filter,
  control,
  resource,
  filterName,
  optionLabel,
  defaultValue,
  filterOptions,
  optionValueName,
  ...rest
}) => {
  const classes = useStyles();
  const props = sanitizeProps(rest);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const { errors } = form;

  const { field } = useController({
    name,
    rules,
    control,
    defaultValue,
  });

  const onChange = (e, value) => {
    field.onChange(e, value);
  };

  const getOptions = useCallback(async () => {
    if (!resource) {
      return;
    }

    setLoading(true);
    try {
      const { data } = (await dataProvider.getList(resource, { filter })) || {};

      setLoading(false);

      if (data) {
        const statesData = filterName
          ? data.filter((d) => {
              if (Array.isArray(filterName)) {
                return !filterName.includes(d[optionLabel]);
              }

              return d[optionLabel] !== filterName;
            })
          : data;

        const filtered = filterOptions
          ? statesData.filter(filterOptions)
          : statesData;

        const handleSort = resourcesSortingMethods[resource];

        setOptions(handleSort(filtered));
      }
    } catch (e) {
      setLoading(false);
    }
  }, [resource, filter, filterName, filterOptions, optionLabel]);

  const placeholderOption = useMemo(
    () =>
      field.value && {
        [optionValueName]: field.value,
        [optionLabel]: label,
      },
    [field.value, label, optionLabel, optionValueName]
  );

  const currentOptions = useMemo(
    () =>
      !options?.length && placeholderOption ? [placeholderOption] : options,
    [options, placeholderOption]
  );

  useEffect(() => {
    getOptions();
  }, [getOptions]);

  if (loading) {
    return (
      <Grid container justifyContent="center">
        <Skeleton variant="rect" height={37} width="100%" />
      </Grid>
    );
  }

  return (
    <TextInput
      {...props}
      select
      name={name}
      form={form}
      label={label}
      field={field}
      options={currentOptions}
      onChange={onChange}
      optionLabel={optionLabel}
      optionValueName={optionValueName}
      error={!!get(errors, field.name)}
      helperText={
        get(errors, field.name) ? get(errors, field.name).message : ''
      }
      FormHelperTextProps={{
        className: classes.formHelperText,
      }}
    />
  );
};

ResourceSelectField.defaultProps = {
  filter: {},
  filterName: '',
  optionLabel: 'name',
  defaultValue: '',
  filterOptions: null,
  optionValueName: 'id',
};

ResourceSelectField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.array,
  ]),
  rules: PropTypes.objectOf(PropTypes.any),
  form: PropTypes.shape({
    errors: PropTypes.objectOf(PropTypes.any),
    touched: PropTypes.objectOf(PropTypes.any),
  }),
  filter: PropTypes.shape(),
  resource: PropTypes.string.isRequired,
  filterName: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  optionLabel: PropTypes.string,
  filterOptions: PropTypes.func,
  optionValueName: PropTypes.string,
  control: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ResourceSelectField;
