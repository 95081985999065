import { requestSaga } from 'common/utils/generators';
import resources from 'common/constants/resources';
import dataProvider from 'store/dataProvider';

import { all, put, takeEvery } from 'redux-saga/effects';
import moment from 'moment';
import { actionTypes } from '../reducer';

function* approveAllOngoingReports(action) {
  const { fileId, status, title, name, pagesConfig, reports } = action.payload;

  try {
    const data = yield all(
      reports.map(async (report) => {
        const response = await dataProvider.createOne(
          resources.report.ongoing,
          { ...report, status, title, name, fileId, pagesConfig }
        );

        return response?.data;
      })
    );

    yield put({
      type: actionTypes.approveOngoing.success,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: actionTypes.approveOngoing.failed,
      message: e?.message,
    });
  }
}

export function* approveOngoing() {
  yield takeEvery(
    actionTypes.approveOngoing.requested,
    approveAllOngoingReports
  );
}

export function* approveEscalated() {
  yield requestSaga({
    actionType: actionTypes.approveEscalated,
    request: dataProvider.createOne,
    resource: resources.report.escalated.generate,
  });
}

const fetchReport = (procedureId) => async (date) => {
  const filter = {
    procedureId,
    date: moment(date).toISOString(),
  };

  const { data } = await dataProvider.getList(
    resources.report.ongoingDocument,
    {
      filter,
    }
  );

  return { [date]: data };
};

function* getReports(action) {
  try {
    const { procedureId, days } = action.payload;

    const response = yield all(days.map(fetchReport(procedureId)));

    yield put({
      type: actionTypes.getData.success,
      data: response.reduce((acc, report) => ({ ...acc, ...report }), {}),
    });
  } catch (e) {
    yield put({
      type: actionTypes.getData.failed,
      message: e?.message,
    });
  }
}

export function* getReportData() {
  yield takeEvery(actionTypes.getData.requested, getReports);
}

export function* getEscalatedReportData() {
  yield requestSaga({
    actionType: actionTypes.getEscalatedData,
    request: dataProvider.getList,
    resource: resources.report.escalatedDocument,
  });
}

export function* uploadReportDocument() {
  yield requestSaga({
    actionType: actionTypes.uploadDocument,
    request: dataProvider.createOneFormData,
    resource: resources.report.uploadDocument,
  });
}

export function* getOngoingStudySummary() {
  yield requestSaga({
    actionType: actionTypes.getStudySummary,
    request: dataProvider.getList,
    resource: resources.report.ongoingStudySummary,
  });
}

export function* getReportNotes() {
  yield requestSaga({
    request: dataProvider.get,
    actionType: actionTypes.getReportNotes,
    resource: resources.report.reportNotes,
  });
}
