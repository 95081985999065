import React, { useMemo, useCallback, useEffect } from 'react';
import clsx from 'classnames';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';

import {
  groupMessageByType,
  eventGroupMessageMapper,
} from 'common/utils/helpers/socket';
import { entities } from 'common/constants/socketConstants';
import { abnormalityTypeEnum } from 'common/constants/ecgEnums';
import { useSocketHub, useSelect, usePrevious } from 'common/hooks';
import { GridLayout, Header } from './components';
import EventItem from './components/EventItem';
import useGridState from './hooks/useGridState';
import { getEventId } from './utils';
import useStyles from './styles';

const defaultItemSize = {
  width: 165,
  height: 66,
};

const LocalPaginatedGrid = ({
  modal,
  items,
  goBack,
  onClose,
  perPage,
  loading,
  itemProps,
  isTemplate,
  graphProps,
  eventGroupType,
  procedureId: id,
  nestedSelection,
  contentStyles,
  columnsAmount,
  itemComponent,
  abnormalityType,
  isGlobalTriage,
  disableSelection,
  handleChangeGroupType,
}) => {
  const styles = useStyles();
  const prevEventGroupType = usePrevious(abnormalityType);

  const selectionState = useSelect('id', nestedSelection);
  const { total, onSelect, onDeselect, isSelected, deselectAll } =
    selectionState;

  const [state, setPage, updateEventsBySocket] = useGridState(
    items,
    abnormalityType,
    perPage
  );

  const { events: stateEvents } = state;

  const propsForItem = useMemo(
    () => ({ ...defaultItemSize, ...itemProps }),
    [itemProps]
  );

  const eventIds = useMemo(() => stateEvents.map(getEventId), [stateEvents]);

  const events = useMemo(
    () =>
      eventIds.map((eventId) => {
        const event = stateEvents.find((evt) => getEventId(evt) === eventId);

        return {
          ...event,
          similar: event?.similar || [eventId],
          abnormalityType: event?.abnormalityType || abnormalityType,
        };
      }),
    [abnormalityType, eventIds, stateEvents]
  );

  const disabledSelect = useMemo(
    () => abnormalityType === abnormalityTypeEnum.patient || !eventIds.length,
    [abnormalityType, eventIds.length]
  );

  const subscriptionParams = useMemo(
    () => ({
      entity: id ? entities.procedure : entities.events,
      payload: id ? [id] : null,
    }),
    [id]
  );

  const onMessage = useCallback(
    (message) => {
      if (!modal || isTemplate) {
        return;
      }

      const { isEventGroup, isPatientEvent, payload } =
        groupMessageByType(message);

      const shouldUpdate = isPatientEvent || isEventGroup;

      if (!shouldUpdate) return;

      const eventGroups = eventGroupMessageMapper(payload);
      const { updated: upd, removed } = eventGroups;
      let { added } = eventGroups;

      if (isPatientEvent) {
        added = added.map((evt) => ({
          ...evt,
          abnormalityType: abnormalityTypeEnum.patient,
        }));
      }

      const updated = [...upd, ...removed];

      updateEventsBySocket({
        added,
        updated,
        isGlobalTriage,
      });

      onDeselect(updated.map((ev) => ev.id));
    },
    [modal, isTemplate, updateEventsBySocket, isGlobalTriage, onDeselect]
  );

  const { entity, payload } = subscriptionParams;
  useSocketHub(modal && entity, payload, onMessage, true);

  const availableForSelectAll = useMemo(() => {
    if (abnormalityType === abnormalityTypeEnum.patient) {
      return [];
    }

    return state.all
      .map((e) => (typeof e === 'string' ? { id: e } : e))
      .filter((e) => e.abnormalityType !== abnormalityTypeEnum.patient);
  }, [abnormalityType, state.all]);

  const availableForSelectIds = useMemo(
    () => availableForSelectAll.map(getEventId),
    [availableForSelectAll]
  );

  const isAllSelected = useMemo(
    () => isSelected(availableForSelectIds),
    [availableForSelectIds, isSelected]
  );

  const handleSelectAll = useCallback(() => {
    if (isAllSelected) {
      deselectAll();
    } else {
      onSelect(availableForSelectAll);
    }
  }, [isAllSelected, deselectAll, onSelect, availableForSelectAll]);

  useEffect(() => {
    if (prevEventGroupType !== abnormalityType) {
      onDeselect();
    }
  }, [onDeselect, prevEventGroupType, abnormalityType]);

  return (
    <Grid
      container
      wrap="nowrap"
      direction="column"
      style={{ height: '100%', position: 'relative' }}
    >
      {loading && (
        <div
          className={clsx(styles.loaderWrapper, {
            [styles.modalLoaderWrapper]: modal,
          })}
        >
          <LinearProgress />
        </div>
      )}
      <Header
        page={state?.page}
        modal={modal}
        goBack={goBack}
        perPage={perPage}
        onClose={onClose}
        setPage={setPage}
        selectedEvents={total}
        total={state?.all?.length || 0}
        deselectAll={deselectAll}
        selectAll={handleSelectAll}
        events={availableForSelectAll}
        isAllSelected={isAllSelected}
        disabledSelect={disabledSelect}
        eventGroupType={eventGroupType}
        abnormalityType={abnormalityType}
        handleChangeGroupType={handleChangeGroupType}
      />

      <GridLayout
        total={state?.all?.length || 0}
        modal={modal}
        events={events}
        loading={loading}
        onSelect={onSelect}
        isSelected={isSelected}
        onDeselect={onDeselect}
        graphProps={graphProps}
        itemProps={propsForItem}
        itemComponent={itemComponent}
        contentStyles={contentStyles}
        columnsAmount={columnsAmount}
        isGlobalTriage={isGlobalTriage}
        disabledSelect={disableSelection || disabledSelect}
      />
    </Grid>
  );
};

LocalPaginatedGrid.defaultProps = {
  modal: false,
  perPage: 49,
  loading: false,
  itemProps: {},
  graphProps: {},
  isTemplate: false,
  procedureId: null,
  contentStyles: {},
  eventGroupType: null,
  isGlobalTriage: false,
  disableSelection: false,
  handleChangeGroupType: () => null,
  itemComponent: EventItem,
};

LocalPaginatedGrid.propTypes = {
  itemProps: PropTypes.shape(),
  items: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  ),
  goBack: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  modal: PropTypes.bool,
  onClose: PropTypes.func,
  loading: PropTypes.bool,
  isTemplate: PropTypes.bool,
  procedureId: PropTypes.string,
  abnormalityType: PropTypes.string,
  nestedSelection: PropTypes.string,
  eventGroupType: PropTypes.string,
  isGlobalTriage: PropTypes.bool,
  disableSelection: PropTypes.bool,
  columnsAmount: PropTypes.number,
  perPage: PropTypes.number,
  handleChangeGroupType: PropTypes.func,
  graphProps: PropTypes.shape(),
  contentStyles: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
  itemComponent: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.func,
    PropTypes.object,
  ]),
};

export default LocalPaginatedGrid;
