import makeStyles from '@material-ui/styles/makeStyles';

import { headerHeights } from './constants';

const useStyles = makeStyles((theme) => ({
  itemsGridContainer: { height: '100%', position: 'relative' },
  root: {
    width: '100%',
  },
  table: {
    paddingBottom: theme.spacing(1.5),
  },
  pagination: {
    fontSize: theme.typography.pxToRem(16),
  },
  gridBody: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    padding: theme.spacing(1, 1),
    paddingBottom: theme.spacing(0.625),
  },
  tableRow: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    padding: theme.spacing(1, 0),
  },
  tableCell: {
    textAlign: 'right',
    border: 'none',
    width: '100%',
    padding: 0,
    paddingTop: 0,
    paddingBottom: 0,
  },
  bodyRowSelect: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  bodyTableCell: {
    padding: theme.spacing(0, 1),
  },
  selectAllCell: {
    width: 40,
    padding: 0,
    border: 'none',
    display: 'flex',
    textAlign: 'right',
    position: 'absolute',
    flexDirection: 'column',
    top: 0,
    left: 0,
  },
  dialogHeaderWrapper: {
    backgroundColor: theme.palette.primary.dark,
    padding: theme.spacing(2.75, 2),
    color: theme.palette.common.white,
    maxHeight: headerHeights.modal,
  },
  dialogHeaderWrapperNonModal: {
    padding: theme.spacing(1.5, 1),
    maxHeight: headerHeights.layout,
  },
  dialogHeader: {
    fontWeight: 400,
    whiteSpace: 'nowrap',
    color: theme.palette.common.white,
  },

  headerActionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
  },

  goBackContainer: {
    paddingRight: theme.spacing(1),
  },

  checkbox: {
    position: 'absolute',
    top: -2,
    left: 145,
    padding: 0,
  },
  triageSelectedButton: {
    minWidth: 40,
    padding: theme.spacing(0.5, 0.7),
  },

  disabledSelectButton: {
    backgroundColor: `${theme.palette.background.secondary} !important`,
  },

  loaderWrapper: {
    position: 'absolute',
    left: 0,
    right: 0,
    height: 4,
    top: headerHeights.layout - 4,
  },
  modalLoaderWrapper: {
    top: headerHeights.modal - 4,
  },
}));

export default useStyles;
