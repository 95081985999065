export default [
  {
    label: 'Order Form',
    value: 'OrderForm',
  },
  {
    label: 'Insurance Card',
    value: 'InsuranceCard',
  },
  {
    label: 'Information Sheet',
    value: 'informationSheet',
  },
  {
    label: 'Clinical',
    value: 'Clinical',
  },
];
