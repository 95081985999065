import { requestSaga } from 'common/utils/generators';
import resources from 'common/constants/resources';
import dataProvider from 'store/dataProvider';
import { patchesActionTypes } from '../reducer';

function* updatePatch() {
  yield requestSaga({
    actionType: patchesActionTypes.update,
    request: dataProvider.updateOne,
    resource: resources.patch.index,
  });
}

export default updatePatch;
