import { requestSaga } from 'common/utils/generators';
import dataProvider from 'store/dataProvider';
import resources from 'common/constants/resources';

import { eventStripReducerNames } from 'common/constants/enums';
import { actionTypes } from '../index';

export function* getHeartRateSummary() {
  yield requestSaga({
    request: dataProvider.getList,
    resource: resources.healthData.heartRateStripSummary,
    actionType:
      actionTypes[eventStripReducerNames.eventTriageStrip].getMinuteHRSummary,
  });
}
