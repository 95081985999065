import React from 'react';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

const DeleteConfirmationModal = ({
  title = '',
  message = '',
  onClose = () => null,
  onConfirm = () => null,
  handleClose = () => null,
  confirmButtonText = 'Confirm',
  cancelButtonText = 'Cancel',
  ...props
}) => {
  const close = () => {
    handleClose();
    onClose();
  };

  const confirm = () => {
    onConfirm(props);
    onClose();
  };

  return (
    <>
      <DialogTitle disableTypography>
        <Typography variant="h3" color="primary">
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" style={{ whiteSpace: 'pre-wrap' }}>
          {message}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={close} color="default">
          {cancelButtonText}
        </Button>
        <Button variant="contained" onClick={confirm} color="primary">
          {confirmButtonText}
        </Button>
      </DialogActions>
    </>
  );
};

export default DeleteConfirmationModal;
