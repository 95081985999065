import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import Table from 'common/components/Table';
import CardSection from 'common/components/CardSection';
import { useNotify } from 'common/modules/Notification/hooks';
import {
  openFileUploadModal,
  openConfirmationModal,
} from 'common/ducks/general/actions/modalActions';
import Add from '@material-ui/icons/Add';
import { getAttachments, getLoading, getTotal } from '../ducks/selectors';
import { columns } from '../constants/filesTableData';
import { attachmentsActions } from '../ducks';
import { createBulkButtons } from '../utils';

const defaultSort = { field: 'createdAt' };
const selectCellName = '__check__';
const defaultPageSize = 5;
const rowsPerPageOptions = [5, 10, 15];

const AttachmentsTable = () => {
  const notify = useNotify();
  const dispatch = useDispatch();
  const { id: procedureId } = useParams();
  const [params, setParams] = useState();

  const total = useSelector(getTotal);
  const loading = useSelector(getLoading);
  const data = useSelector(getAttachments);

  const fetchAttachments = useCallback(() => {
    if (!params) {
      return;
    }

    dispatch(
      attachmentsActions.fetchAttachments({
        ...params,
        filter: {
          entityType: 'Procedure',
          entityId: procedureId,
        },
      })
    );
  }, [dispatch, params, procedureId]);

  const onDeleteSuccess = useCallback(() => {
    notify.success('Files have been deleted!');
    fetchAttachments();
  }, [fetchAttachments, notify]);

  const onDeleteError = useCallback(() => {
    notify.error('Deleting filed failed!');
  }, [notify]);

  const handleCellClick = ({ column, row }) => {
    if (column.id === selectCellName) {
      return;
    }

    const fileName = `${row.original.name}.${row.original.extension}`;
    dispatch(attachmentsActions.downloadFile(row.original.id, fileName));
  };

  const handleDelete = useCallback(
    ({ selectedIds }) => {
      dispatch(
        attachmentsActions.archiveFiles(
          selectedIds,
          onDeleteSuccess,
          onDeleteError
        )
      );
    },
    [dispatch, onDeleteError, onDeleteSuccess]
  );

  const onDeleteClick = useCallback(
    ({ state }) => {
      const selectedIds = state.selection.map(({ id }) => id);

      dispatch(
        openConfirmationModal({
          selectedIds,
          onConfirm: handleDelete,
          maxWidth: 'xs',
          title: 'Deleting files',
          confirmButtonText: 'Ok',
          message: 'Are you sure you want to delete selected files?',
        })
      );
    },
    [dispatch, handleDelete]
  );

  const onUploadClick = useCallback(
    () =>
      dispatch(
        openFileUploadModal({
          maxWidth: 'sm',
          entityType: 'Procedure',
          entityId: procedureId,
          fetchAttachments,
        })
      ),
    [dispatch, fetchAttachments, procedureId]
  );

  const bulkButtons = useMemo(
    () => createBulkButtons(onDeleteClick),
    [onDeleteClick]
  );
  const tableActions = useMemo(
    () => [
      {
        text: 'Upload File',
        onClick: onUploadClick,
        icon: Add,
        size: 'medium',
        variant: 'text',
      },
    ],
    [onUploadClick]
  );

  const toolbarProps = useMemo(
    () => ({ height: 50, bulkActions: bulkButtons, tableActions }),
    [bulkButtons, tableActions]
  );

  useEffect(() => {
    fetchAttachments();
  }, [fetchAttachments]);

  return (
    <CardSection title="Attachments">
      <Table
        data={data}
        total={total}
        loading={loading}
        columns={columns}
        defaultSort={defaultSort}
        updateParams={setParams}
        rowsPerPage={defaultPageSize}
        onCellClick={handleCellClick}
        toolbarProps={toolbarProps}
        rowsPerPageOptions={rowsPerPageOptions}
      />
    </CardSection>
  );
};

export default AttachmentsTable;
