import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { containerChildProps } from 'common/constants/sharedPropTypes';

const SelectedRange = ({ parent, selectedRange }) => {
  const { height, clip, xScale } = parent;
  const { startRange, endRange } = selectedRange;

  const startRangeDate = useMemo(() => new Date(startRange), [startRange]);
  const endRangeDate = useMemo(() => new Date(endRange), [endRange]);

  const rectWidth = useMemo(
    () => xScale(endRangeDate) - xScale(startRangeDate),
    [startRangeDate, endRangeDate, xScale]
  );

  return (
    <rect
      x={xScale(startRangeDate)}
      width={rectWidth}
      height={height}
      clipPath={clip}
      fill="yellow"
      fillOpacity="0.3"
    />
  );
};

SelectedRange.propTypes = {
  parent: containerChildProps,
  selectedRange: PropTypes.shape({
    startRange: PropTypes.string,
    endRange: PropTypes.string,
  }),
};

export default SelectedRange;
