import React, { useMemo } from 'react';
import Button from '@material-ui/core/Button';
import { useDispatch } from 'react-redux';

import { commonRoles } from 'common/constants/enums';
import { openUpdatePassword } from 'common/ducks/general/actions/modalActions';

const UpdatePasswordButton = ({ row }) => {
  const dispatch = useDispatch();

  const canNotChangePassword = useMemo(() => !row.original?.email, [row]);

  const openChangePasswordModal = (e) => {
    e.preventDefault();
    e.stopPropagation();

    dispatch(
      openUpdatePassword({ ...(row.original || {}), role: commonRoles.contact })
    );
  };

  return (
    <Button
      fullWidth
      size="small"
      color="primary"
      title={
        canNotChangePassword
          ? 'Email address should be added to change password'
          : 'Change Password'
      }
      variant="contained"
      disabled={canNotChangePassword}
      style={{ fontSize: 12, minWidth: 80 }}
      onClick={openChangePasswordModal}
    >
      Change Password
    </Button>
  );
};

export default UpdatePasswordButton;
