import React, { useCallback, useEffect, useState } from 'react';
import MUIAvatar from '@material-ui/core/Avatar';

import dataProvider from 'store/dataProvider';
import cacheProvider from 'store/cacheProvider';
import { mimeTypes } from 'common/constants/enums';
import resources from 'common/constants/resources';

const Avatar = ({ className = '', fileId = '', ...props }) => {
  const [avatarUrl, setAvatarUrl] = useState('');

  const getAvatarFile = useCallback(async () => {
    if (!fileId) {
      setAvatarUrl('');
      return null;
    }

    try {
      const cachedBlob = await cacheProvider.getOne(fileId);

      if (cachedBlob) {
        return setAvatarUrl(window.URL.createObjectURL(cachedBlob));
      }

      const fileInfo = await dataProvider.getOne(resources.files.index, {
        id: fileId,
      });
      const { extension } = fileInfo?.data || {};
      const type = mimeTypes[extension];

      const response = await dataProvider.getFile(resources.files.download, {
        params: { id: fileId },
        download: false,
      });

      const { data } = response;
      const blob = new Blob([data.blob], { type });

      await cacheProvider.addOne(fileId, blob);

      return setAvatarUrl(window.URL.createObjectURL(blob));
    } catch (e) {
      return e;
    }
  }, [fileId]);

  useEffect(() => {
    getAvatarFile();
  }, [getAvatarFile]);

  return <MUIAvatar {...props} classes={{ root: className }} src={avatarUrl} />;
};

export default Avatar;
