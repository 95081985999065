import { useCallback, useEffect, useRef } from 'react';
import debounce from 'lodash/debounce';

const useResizeFiltersWithWindow = (setHeight, filtersLength) => {
  const ref = useRef();

  const handleResize = useCallback(
    (shouldResize) => {
      const container = ref.current;

      if (container?.clientHeight >= 0 && setHeight && shouldResize) {
        setHeight(container.clientHeight);
      }
    },
    [ref, setHeight]
  );

  const updateResizeDebounced = debounce(
    () => handleResize(filtersLength),
    300
  );

  const onWindowResize = useCallback(() => {
    window.addEventListener('resize', updateResizeDebounced);

    return () => window.removeEventListener('resize', handleResize);
  }, [updateResizeDebounced, handleResize]);

  useEffect(() => {
    // resize on change filters count
    handleResize(filtersLength || 1);
  }, [handleResize, filtersLength]);

  useEffect(() => onWindowResize(), [onWindowResize]);

  return ref;
};

export default useResizeFiltersWithWindow;
