import React, { useCallback, useEffect, useMemo } from 'react';
import moment from 'moment';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useSelector, useDispatch } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import Button from 'common/components/buttons/ModalActionButton';
import TriageButton from 'common/components/buttons/TriageButton';
import { getProcedure, getPatient } from 'common/utils/entitySelectors';
import {
  isLoading,
  getStartEndTime,
  getHourIncluded,
  getIsDayIncluded,
  getCurrentDayNumber,
  getAllIncludedDisclosures,
} from '../ducks/selectors';
import {
  clearState,
  setDateRange,
  onIncludeHour,
  onDeclineHour,
  getIncludedDisclosures,
} from '../ducks';

const headerTimeRangeFormat = 'hh:mm a';
const timeUnit = 'minutes';
const fullDisclosureDuration = 60;

const createNewRange = (date, prev = false) => {
  const newDate = moment(date);

  if (prev) {
    const newStart = moment(newDate).subtract(fullDisclosureDuration, timeUnit);
    return [newStart.toISOString(), date];
  }

  const newEnd = moment(newDate).add(fullDisclosureDuration, timeUnit);
  return [date, newEnd.toISOString()];
};

const ModalHeader = ({
  onClose = () => null,
  procedureId = '',
  selectedTime,
}) => {
  const dispatch = useDispatch();
  const loading = useSelector(isLoading);
  const procedure = useSelector(getProcedure(procedureId));
  const patient = useSelector(getPatient(procedure?.patient));
  const timeRange = useSelector(getStartEndTime(procedure, selectedTime));
  const currentDay = useSelector(getCurrentDayNumber(procedure, selectedTime));

  const [from] = timeRange;
  const hourIncluded = useSelector(getHourIncluded(from));
  const isDayIncluded = useSelector(getIsDayIncluded);
  const allIncludedHours = useSelector(getAllIncludedDisclosures);

  const isHourIncluded = useMemo(() => Boolean(hourIncluded), [hourIncluded]);

  const procedureName = useMemo(() => {
    if (procedure?.name) {
      return procedure.name;
    }

    if (patient) {
      const { fullName, firstName, lastName } = patient;

      return fullName || [lastName, firstName].filter(Boolean).join(', ');
    }

    return 'Unknown';
  }, [procedure, patient]);

  const setNewRange = (range) => {
    dispatch(clearState());
    dispatch(setDateRange(range));
  };

  const currentStartDay = useMemo(
    () => moment(timeRange[0]).startOf('day').toISOString(),
    [timeRange]
  );

  const prevRange = () => {
    const newRange = createNewRange(timeRange[0], true);
    setNewRange(newRange);
  };
  const nextRange = () => {
    const newRange = createNewRange(timeRange[1]);
    setNewRange(newRange);
  };

  const handleGetIncludedDisclosures = useCallback(() => {
    const dateFrom = moment(currentStartDay).startOf('day').toISOString();
    const dateTo = moment(currentStartDay).endOf('day').toISOString();

    dispatch(getIncludedDisclosures({ dateFrom, dateTo, procedureId }));
  }, [dispatch, currentStartDay, procedureId]);

  const includeHour = () => {
    if (isHourIncluded) {
      dispatch(onDeclineHour({ ids: [hourIncluded.id] }));
      return;
    }

    const { kitId } = procedure || {};
    dispatch(
      onIncludeHour([
        {
          procedureId,
          kitId: kitId || undefined,
          dateTime: timeRange[0],
        },
      ])
    );
  };

  const includeDay = () => {
    if (isDayIncluded) {
      const allIds = allIncludedHours.map((includedHr) => includedHr.id);
      dispatch(onDeclineHour({ ids: allIds }));

      return;
    }

    const { kitId } = procedure || {};

    const includedDay = new Array(24)
      .fill(null)
      .map((_, index) => {
        const dateTime = moment(currentStartDay)
          .add(index, 'hour')
          .toISOString();
        return { dateTime, procedureId, kitId: kitId || undefined };
      })
      .filter(
        (f) =>
          !allIncludedHours.find((ih) =>
            moment(f.dateTime).isSame(ih.dateTime, 'hour')
          )
      );

    dispatch(onIncludeHour(includedDay));
  };

  useEffect(() => {
    handleGetIncludedDisclosures();
  }, [handleGetIncludedDisclosures]);

  return (
    <Grid
      container
      component={Box}
      alignItems="center"
      bgcolor="background.primary"
      style={{
        top: 0,
        padding: 8,
        position: 'absolute',
      }}
      justifyContent="space-between"
    >
      <Grid item xs={3} container>
        <Box component={Typography} color="text.colored" variant="h4">
          Full Disclosure - {procedureName}
        </Box>
      </Grid>

      <Grid item xs={4}>
        <Box component={Typography} color="text.colored" variant="h4">
          60-Minute Window / Day {currentDay} /{' '}
          {moment(timeRange?.[0]).format(headerTimeRangeFormat) || 0} -{' '}
          {moment(timeRange?.[1]).format(headerTimeRangeFormat) || 0}
        </Box>
      </Grid>

      <Grid
        item
        container
        xs={5}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Button
            size="small"
            color="primary"
            onClick={prevRange}
            disabled={loading}
            style={{ margin: '0 4px' }}
          >
            <ChevronLeftIcon />
          </Button>

          <Button
            size="small"
            color="primary"
            onClick={nextRange}
            disabled={loading}
            style={{ margin: '0 4px' }}
          >
            <ChevronRightIcon />
          </Button>
        </Grid>

        <Grid item>
          <TriageButton
            size="small"
            typographyVariant="body1"
            color={isHourIncluded ? 'default' : 'primary'}
            onClick={includeHour}
            disabled={loading}
            style={{ margin: '0 4px', padding: 9 }}
          >
            {isHourIncluded ? 'This Hour Included' : 'Include This Hour'}
          </TriageButton>

          <TriageButton
            size="small"
            color={isDayIncluded ? 'default' : 'primary'}
            onClick={includeDay}
            disabled={loading}
            typographyVariant="body1"
            style={{ margin: '0 4px', padding: 9 }}
          >
            {isDayIncluded ? 'This Day Included' : 'Include This Day'}
          </TriageButton>
        </Grid>

        <Button onClick={onClose}>Close</Button>
      </Grid>
    </Grid>
  );
};

export default ModalHeader;
