import moment from 'moment/moment';

import { getFirstEqualOrAfterTimeIndex } from 'common/utils/helpers/date';
import { isReversedRange } from '../../utils';

const DEFAULT_SELECTED_BEATS = 1;

export const getFirstSelectionBeatIndex = (beats, xLeftDate, xRightDate) => {
  const isReversed = isReversedRange(xLeftDate, xRightDate);
  const iterator = isReversed
    ? ({ time }) => moment(xLeftDate).isBefore(time)
    : ({ time }) => moment(time).isSameOrAfter(xLeftDate);
  const leftBeatIndex = beats.findIndex(iterator);

  // index correction for reversed range
  let foundIndex = isReversed ? leftBeatIndex - 1 : leftBeatIndex;

  // check for selection that less than one beat
  if (
    xRightDate &&
    new Date(beats[foundIndex].time).getTime() === xRightDate.getTime()
  ) {
    foundIndex = !isReversed ? foundIndex - 1 : foundIndex + 1;
  }

  return foundIndex;
};

export const getFirstSelectionBeat = (beats, xLeftDate, xRightDate) => {
  const index = getFirstSelectionBeatIndex(beats, xLeftDate, xRightDate);

  return new Date((beats[index] || beats[0]).time);
};

export const getLastSelectionBeatByLeft = (beats, leftBeatDate) => {
  const closestLeftBeatIndex = beats.findIndex(({ time }) =>
    moment(time).isAfter(leftBeatDate)
  );

  const rightBeat = beats[closestLeftBeatIndex + DEFAULT_SELECTED_BEATS];

  return new Date(rightBeat.time);
};

export const getRulerSelection = (beats, currentTime) => {
  const index = getFirstEqualOrAfterTimeIndex(beats, currentTime);

  const prevBeat = beats[index - 1] || beats[0];
  const nextBeat = beats[index] || beats[beats.length - 1];

  const prevBeatTime = prevBeat.time;
  const nextBeatTime = nextBeat.time;
  const diffWithPrev = moment(currentTime).diff(prevBeatTime);
  const diffWithNext = moment(nextBeatTime).diff(currentTime);

  const isAttachToRR = Math.min(diffWithPrev, diffWithNext) <= 30;

  if (isAttachToRR) {
    const targetBeat = diffWithPrev < diffWithNext ? prevBeat : nextBeat;

    return new Date(targetBeat.time);
  }

  return currentTime;
};

const getEdgeSelectionIdxs = (beats, left, right) => {
  const leftIndex = getFirstEqualOrAfterTimeIndex(beats, left);
  const rightIndex = getFirstEqualOrAfterTimeIndex(beats, right);

  return [leftIndex, rightIndex];
};

export const getBeatsCount = (beats, left, right) => {
  const [leftIndex, rightIndex] = getEdgeSelectionIdxs(beats, left, right);

  const sliceParams =
    leftIndex < rightIndex ? [leftIndex, rightIndex] : [rightIndex, leftIndex];

  return beats.slice(...sliceParams).length;
};

export const getIsBeatsSelection = (beats, left, right) => {
  const [leftIndex, rightIndex] = getEdgeSelectionIdxs(beats, left, right);

  const leftBeat = beats[leftIndex] || beats[0];
  const rightBeat = beats[rightIndex] || beats[beats.length - 1];

  const leftBeatTime = leftBeat.time;
  const rightBeatTime = rightBeat.time;
  const diffWithLeft = moment(leftBeatTime).diff(left);
  const diffWithRight = moment(rightBeatTime).diff(right);

  return !diffWithLeft && !diffWithRight;
};
