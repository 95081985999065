import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const NoRowsOverlay = ({ resource = 'data' }) => (
  <Grid
    container
    justifyContent="center"
    alignItems="flex-end"
    style={{
      bottom: 50,
      zIndex: 1,
      width: '100%',
      minHeight: 100,
      position: 'absolute',
      paddingBottom: 24,
    }}
  >
    <Typography variant="h3" component={Grid} item>
      No {resource} found!
    </Typography>
  </Grid>
);

export default NoRowsOverlay;
