import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import withStyles from '@material-ui/styles/withStyles';

export default withStyles(({ palette }) => ({
  root: {
    backgroundColor: palette.common.white,
    borderBottom: '1.5px solid rgba(0, 0, 0, .4)',
    height: 35,
    minHeight: 35,
    '&$expanded': {
      height: 35,
      minHeight: 35,
      borderBottom: '1.5px solid rgba(0, 0, 0, .4)',
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
}))(MuiAccordionSummary);
