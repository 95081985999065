import {
  templateTypes,
  templateAbnormalities,
} from 'common/constants/ecgEnums';

export const getEventId = (event) =>
  typeof event === 'string' ? event : event?.id;

export const getElementsIds = (elements) => elements.map((el) => el?.id);

export const eventsFilter = (event, status, eventAbnormalities = []) => {
  const isTemplate = templateTypes.includes(event.abnormalityType);

  if (isTemplate) {
    return true;
  }

  const isAbnormalityAcceptable = eventAbnormalities?.length
    ? eventAbnormalities.includes(event.abnormalityType)
    : true;

  if (!status?.length) {
    return !event.status && isAbnormalityAcceptable;
  }

  if (Array.isArray(status)) {
    return status.includes(event.status) && isAbnormalityAcceptable;
  }

  return status === event.status && isAbnormalityAcceptable;
};

export const updateTemplatesWithAddedEvents = (
  oldEvents,
  newEvents,
  status
) => {
  const events = oldEvents;

  newEvents.forEach((event) => {
    const templateIds = event.templateIds || [];
    if (!templateIds?.length) {
      return;
    }

    templateIds.forEach((templateId) => {
      const templateIndex = events.findIndex((e) => e?.id === templateId);

      if (templateIndex < 0) {
        return;
      }

      const template = events[templateIndex];
      const isEventShouldPresent = eventsFilter(
        event,
        status,
        templateAbnormalities
      );

      const similar = isEventShouldPresent
        ? [...template.similar, event]
        : template.similar;

      events[templateIndex] = {
        ...template,
        similar,
      };
    });
  });

  return events;
};

export const updateTemplatesWithUpdatedEvents = (
  oldEvents,
  newEvents,
  status
) => {
  const events = oldEvents;

  newEvents.forEach((event) => {
    const templateIds = event.templateIds || [];
    if (!templateIds?.length) {
      return;
    }

    templateIds.forEach((templateId) => {
      const templateIndex = events.findIndex((e) => e?.id === templateId);

      if (templateIndex < 0) {
        return;
      }

      const template = events[templateIndex];
      const isEventShouldPresent = eventsFilter(
        event,
        status,
        templateAbnormalities
      );

      const isExist = !!template.similar.find((e) => e.id === event.id);

      let similar = [...template.similar];

      if (isExist && isEventShouldPresent) {
        similar = template.similar.map((e) =>
          e.id === event.id ? { ...e, ...event } : e
        );
      }

      if (!isExist && isEventShouldPresent) {
        similar = [...template.similar, event];
      }

      if (!isEventShouldPresent) {
        similar = template.similar.filter(({ id }) => id !== event.id);
      }

      events[templateIndex] = {
        ...template,
        similar,
      };
    });
  });

  return events;
};

export const updateTemplatesWithRemovedEvents = (oldEvents, removedEvents) => {
  const events = oldEvents;

  removedEvents.forEach((event) => {
    const templateIds = event.templateIds || [];
    if (!templateIds?.length) {
      return;
    }

    templateIds.forEach((templateId) => {
      const templateIndex = events.findIndex((e) => e?.id === templateId);

      if (templateIndex < 0) {
        return;
      }

      const template = events[templateIndex];
      const similar = template.similar.filter(({ id }) => id !== event.id);

      events[templateIndex] = {
        ...template,
        similar,
      };
    });
  });

  return events;
};

const PER_PAGE = 49;

export const getEventsByPage = (events = [], page = 1, perPage = PER_PAGE) =>
  events.slice((page - 1) * perPage, page * perPage);

export const getLastPage = (events = [], perPage = PER_PAGE) =>
  Math.ceil((events?.length || 0) / perPage);
