import React from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

const InformationModal = ({
  title = '',
  message = '',
  onClose = () => null,
  onConfirm = () => null,
  confirmButtonText = 'Ok',
  ...props
}) => {
  const confirm = () => {
    onConfirm(props);
    onClose();
  };

  return (
    <>
      <DialogTitle disableTypography>
        <Typography variant="h3" color="primary">
          {title}
        </Typography>
      </DialogTitle>

      <DialogContent>
        <Typography
          variant="h3"
          style={{ whiteSpace: 'pre-wrap', fontWeight: 'normal' }}
        >
          {message}
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button variant="contained" onClick={confirm} color="primary">
          {confirmButtonText}
        </Button>
      </DialogActions>
    </>
  );
};

export default InformationModal;
