import {
  patchTypes,
  physicianTypesEnum,
  eventGroupSortTypes,
  sendingReportFrequency,
} from './enums';
import { createEnum } from '../utils/actionTypesHelper';

export const commonStatuses = [
  {
    label: 'Active',
    value: 'Active',
  },
  {
    label: 'Inactive',
    value: 'Inactive',
  },
  {
    label: 'Deleted',
    value: 'Deleted',
  },
];

export const patchTypeOptions = [
  {
    label: 'Versa Cardio Patch',
    value: patchTypes.versaCardio,
  },
  {
    label: 'eCordum Patch',
    value: patchTypes.eCordumMCT,
  },
];

export const phoneConditions = [
  {
    label: 'Good',
    value: 'Good',
  },
  {
    label: 'Lost',
    value: 'Lost',
  },
  {
    label: 'Being Repaired',
    value: 'BeingRepaired',
  },
  {
    label: 'Unrepairable',
    value: 'Unrepairable',
  },
];

export const procedureConditions = [
  {
    label: 'Ready',
    value: 'Ready',
  },
  {
    label: 'Recording',
    value: 'Recording',
  },
  {
    label: 'Issue',
    value: 'Issue',
  },
];

export const procedureStatuses = [
  {
    label: 'Ordered',
    value: 'Ordered',
  },
  {
    label: 'Monitoring',
    value: 'Monitoring',
  },
  {
    label: 'Completed',
    value: 'Completed',
  },
  {
    label: 'Deleted',
    value: 'Deleted',
  },
];

export const reportSendingFrequency = [
  {
    label: 'Daily Reports',
    value: 'DailyReports',
  },
  {
    label: 'Multi-Day Reports',
    value: 'MultiDayReports',
  },
  {
    label: 'Single/Multi Report',
    value: 'SingleMultiReport',
  },
];

export const lateActivityFilterValues = createEnum({
  all: null,
  late: null,
  intermittent: null,
});

export const lateActivityValuesAccordingToFilter = {
  [lateActivityFilterValues.all]: {
    hasLateActivityEvents: true,
    hasLowBpm: true,
  },
  [lateActivityFilterValues.late]: {
    hasLateActivityEvents: true,
    hasLowBpm: null,
  },
  [lateActivityFilterValues.intermittent]: {
    hasLateActivityEvents: null,
    hasLowBpm: true,
  },
};

export const proceduresLateActivity = [
  {
    label: 'All Late Activity',
    value: lateActivityFilterValues.all,
  },
  {
    label: 'Late Activity',
    value: lateActivityFilterValues.late,
  },
  {
    label: 'Intermittent',
    value: lateActivityFilterValues.intermittent,
  },
];

export const yesNo = [
  {
    label: 'Yes',
    value: '1',
  },
  {
    label: 'No',
    value: '0',
  },
];

export const physicianTypes = [
  {
    name: 'IndividualPractice',
    value: physicianTypesEnum.individualPractice,
    label: 'Individual Practice',
  },
  {
    name: 'Group',
    value: physicianTypesEnum.group,
    label: 'Group',
  },
  {
    name: 'UrgentCare',
    value: physicianTypesEnum.urgentCare,
    label: 'Urgent Care',
  },
  {
    name: 'Hospital',
    value: physicianTypesEnum.hospital,
    label: 'Hospital',
  },
  {
    name: 'Contracted',
    value: physicianTypesEnum.contracted,
    label: 'Contracted',
  },
];

export const physicianStatuses = [
  {
    name: 'Active',
    value: 'Active',
    label: 'Active',
  },
  {
    name: 'Active OD',
    value: 'ActiveOD',
    label: 'Active OD',
  },
  {
    name: 'New',
    value: 'New',
    label: 'New',
  },
  {
    name: 'Returning Device',
    value: 'ReturningDevice',
    label: 'Returning Device',
  },
  {
    name: 'Cancelled',
    value: 'Cancelled',
    label: 'Cancelled',
  },
  {
    name: 'Closed',
    value: 'Closed',
    label: 'Closed',
  },
  {
    name: 'Dissatisfied',
    value: 'Dissatisfied',
    label: 'Dissatisfied',
  },
];

export const physicianReports = [
  {
    name: 'Email',
    value: 'Email',
    label: 'Email',
  },
  {
    name: 'Fax',
    value: 'Fax',
    label: 'Fax',
  },
];

export const genderTypes = [
  {
    name: 'Male',
    value: 'Male',
    label: 'Male',
  },
  {
    name: 'Female',
    value: 'Female',
    label: 'Female',
  },
];

export const relationshipTypes = [
  {
    name: 'self',
    value: 'Self',
    label: 'Self',
  },
  {
    name: 'spouse',
    value: 'Spouse',
    label: 'Spouse',
  },
  {
    name: 'parent',
    value: 'Parent',
    label: 'Parent',
  },
  {
    name: 'legalGuardian',
    value: 'LegalGuardian',
    label: 'Legal Guardian',
  },
  {
    name: 'other',
    value: 'Other',
    label: 'Other',
  },
];

export const sendingReportFrequencyOptions = [
  {
    value: sendingReportFrequency.daily,
    label: 'Daily Reports',
  },
  {
    value: sendingReportFrequency.multi,
    label: 'Multi-Day Reports (Every 4 Days)',
  },
  {
    value: sendingReportFrequency.singleMulti,
    label: 'Single/Multi Report (4 Days at Final Day)',
  },
];

export const eventGroupSortOptions = [
  {
    value: eventGroupSortTypes.highestHR,
    label: 'Highest HR',
  },
  {
    value: eventGroupSortTypes.lowestHR,
    label: 'Lowest HR',
  },
  {
    value: eventGroupSortTypes.newest,
    label: 'Newest',
  },
  {
    value: eventGroupSortTypes.oldest,
    label: 'Oldest',
  },
  {
    value: eventGroupSortTypes.longest,
    label: 'Longest',
  },
  {
    value: eventGroupSortTypes.shortest,
    label: 'Shortest',
  },
];
