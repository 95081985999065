import React, { useMemo, useState, lazy, Suspense } from 'react';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/styles/makeStyles';

import { useActivityHandler } from 'common/hooks';
import { commonRoles } from 'common/constants/enums';
import FallbackLoader from 'common/components/FallbackLoader';
import useAuthProvider from 'store/authProvider/useAuthProvider';

import SideMenu from 'ui/layout/SideMenu';
import HeaderLayout from 'ui/layout/HeaderLayout';

const PrivateLayout = lazy(() =>
  import(/* webpackChunkName: "vcPortal" */ './PrivateLayout')
);
const PhysicianLayout = lazy(() =>
  import(/* webpackChunkName: "physicianPortal" */ './PhysicianLayout')
);
const ContactLayout = lazy(() =>
  import(/* webpackChunkName: "contactsLayout" */ './ContactLayout')
);

const EcgTechLayout = lazy(() =>
  import(/* webpackChunkName: "contactsLayout" */ './EcgTechLayout')
);

const useStyles = makeStyles(({ palette }) => ({
  menuContainer: {
    width: 240,
    minWidth: 240,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: palette.background.secondary,
  },

  container: {
    overflow: 'auto',
    minHeight: '100vh',
    backgroundColor: palette.background.secondary,
  },

  layout: {
    minHeight: 'calc(100vh - 66px)',
  },
}));

const privateLayoutRoles = [
  commonRoles.admin,
  commonRoles.superAdmin,
  commonRoles.technician,
];

const MainLayout = () => {
  const classes = useStyles();
  const authProvider = useAuthProvider();
  const role = useMemo(
    () => authProvider.currentUserRole,
    [authProvider.currentUserRole]
  );
  const [elementRef, setRef] = useState(null);

  useActivityHandler(elementRef);

  return (
    <Grid container ref={setRef} wrap="nowrap" className={classes.container}>
      <div className={classes.menuContainer}>
        <SideMenu role={role} />
      </div>

      <Grid item container direction="column">
        <HeaderLayout />

        <Suspense fallback={<FallbackLoader />}>
          {role === commonRoles.physician && <PhysicianLayout />}

          {role === commonRoles.contact && <ContactLayout />}

          {role === commonRoles.ecgTech && <EcgTechLayout />}

          {privateLayoutRoles.includes(role) && <PrivateLayout />}
        </Suspense>
      </Grid>
    </Grid>
  );
};

export default MainLayout;
