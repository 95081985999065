import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import useStyles from '../styles';

const SelectAllCheckbox = ({
  eventIds = [],
  isSelected = () => null,
  handleAllCheck = () => null,
}) => {
  const styles = useStyles();

  return (
    <div className={styles.tableRow}>
      <Grid item className={styles.selectAllCell}>
        <Grid item container wrap="nowrap" alignItems="center">
          <Checkbox
            color="primary"
            size="small"
            checked={isSelected(eventIds)}
            onChange={handleAllCheck}
            style={{ padding: 0 }}
          />
        </Grid>

        <ArrowDownwardIcon fontSize="small" />
      </Grid>
    </div>
  );
};

SelectAllCheckbox.defaultProps = {
  eventIds: [],
  isSelected: () => null,
  handleAllCheck: () => null,
};

SelectAllCheckbox.propTypes = {
  isSelected: PropTypes.func,
  handleAllCheck: PropTypes.func,
  eventIds: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.shape(), PropTypes.string])
  ),
};

export default SelectAllCheckbox;
