import {
  getValueFromToken,
  clearTokens as defaultClearTokens,
  saveTokens as defaultSaveTokens,
  hasTokens as defaultHasTokens,
} from './tokensHelper';
import {
  authLogin,
  fetchUser,
  authAccount,
  updatePassword,
} from './httpHelper';

export default (
  {
    saveTokens = defaultSaveTokens,
    clearTokens = defaultClearTokens,
    hasTokens = defaultHasTokens,
    getPermissions,
  },
  urls,
  errorMessages
) => ({
  validateAccount: (params) =>
    authAccount(urls.validateAccount, params, errorMessages.login),
  acceptTermsOfUse: (params) =>
    authAccount(urls.acceptTermsOfUse, params, errorMessages.login),
  login: (params) =>
    authLogin(
      urls.validateCode,
      params,
      saveTokens,
      errorMessages.confirmationCode
    ),
  logout: async () => {
    await clearTokens();
    return false;
  },
  fetchUser: () => fetchUser(urls.authorization, saveTokens),
  checkAuth: async () => {
    if (hasTokens()) {
      return getValueFromToken();
    }
    // eslint-disable-next-line no-throw-literal
    throw null;
  },
  forgotPassword: async (params) => {
    await updatePassword(urls.forgotPassword, params);
    return false;
  },
  resetPassword: async (params) => {
    await updatePassword(urls.resetPassword, params);
    return false;
  },
  checkError: async () => {},
  saveTokensLogin: (data) => defaultSaveTokens(data),
  getPermissions,

  currentTenantId: (getValueFromToken() || {}).TenantId,
  currentUserRole: (getValueFromToken() || {}).Role,
  currentUserId: (getValueFromToken() || {}).Id,
});
