import { requestSaga } from 'common/utils/generators';
import resources from 'common/constants/resources';
import dataProvider from 'store/dataProvider';
import { actionTypes } from '../reducer';

function* getUserActivityStatistic() {
  yield requestSaga({
    actionType: actionTypes.getActivity,
    request: dataProvider.get,
    resource: resources.accountAction.activityByAccount,
  });
}

export default getUserActivityStatistic;
