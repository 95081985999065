import { requestReducer } from 'common/utils/generators';
import { profileActionTypes } from 'common/ducks/actionTypes';

const initialProfileState = {
  data: {},
  loading: false,
  error: null,
};

const profileReducer = requestReducer(
  [
    profileActionTypes.get,
    profileActionTypes.update,
    profileActionTypes.saveData,
  ],
  initialProfileState,
  (payload, state) => ({
    ...state,
    data: payload.data,
  })
);

export const getProfileInfo = (payload) => ({
  type: profileActionTypes.get.requested,
  payload,
});

export const updateProfileInfo = (payload, onSuccess, onError) => ({
  type: profileActionTypes.update.requested,
  payload,
  onSuccess,
  onError,
});

export const updatePassword = (payload, onSuccess, onError) => ({
  type: profileActionTypes.updatePassword.requested,
  payload,
  onSuccess,
  onError,
});

export const clearProfileData = () => ({
  type: profileActionTypes.get.success,
  payload: { data: {} },
});

export default profileReducer;
