import createEnum from 'common/utils/actionTypesHelper/createEnum';
import { abnormalityTypeEnum } from './ecgEnums';

export const activityValuesEnum = createEnum({
  lay: 1,
  stand: 2,
  walk: 3,
  run: 4,
});

export const submitEditsStatusEnum = createEnum({
  accepted: 'Accepted',
  requested: 'Requested',
});

export const submitEditsStatusLabel = createEnum({
  [submitEditsStatusEnum.accepted]: 'Accepted',
  [submitEditsStatusEnum.requested]: 'Edits Requested',
});

export const ecgEventStatus = createEnum({
  accepted: 'Approved',
  included: 'Included',
  denied: 'Denied',
  default: 0,
});

export const physicianTypesEnum = createEnum({
  individualPractice: 'IndividualPractice',
  group: 'Group',
  urgentCare: 'UrgentCare',
  hospital: 'Hospital',
  contracted: 'Contracted',
});

export const hrGroupTypes = createEnum({
  minute: 'Minute',
  hour: 'Hour',
  none: 'None',
});

export const symptomEnum = createEnum({
  accidental: 'Accidental',
  baseline: 'Baseline',
  chestPain: 'ChestPain',
  dizziness: 'Dizziness',
  fastHeartRate: 'FastHeartRate',
  fluttering: 'Fluttering',
  palpitations: 'Palpitations',
  skippedBeat: 'SkippedBeat',
  fatigued: 'Fatigued',
  passedOut: 'PassedOut',
  shortnessOfBreath: 'ShortnessOfBreath',
  irregularHeartbeat: 'IrregularHeartbeat',
});

export const symptomLabelsEnum = createEnum({
  [symptomEnum.accidental]: 'Accidental',
  [symptomEnum.baseline]: 'Baseline / Test',
  [symptomEnum.chestPain]: 'Chest Pain',
  [symptomEnum.dizziness]: 'Dizziness',
  [symptomEnum.fastHeartRate]: 'Fast Heart Rate',
  [symptomEnum.fluttering]: 'Fluttering',
  [symptomEnum.palpitations]: 'Palpitations',
  [symptomEnum.skippedBeat]: 'Skipped Beat',
  [symptomEnum.fatigued]: 'Fatigued',
  [symptomEnum.passedOut]: 'PassedOut',
  [symptomEnum.shortnessOfBreath]: 'Shortness Of Breath',
  [symptomEnum.irregularHeartbeat]: 'Irregular Heartbeat',
});

export const commonStatusTypes = createEnum({
  active: 'Active',
  deleted: 'Deleted',
  inactive: 'Inactive',
});

export const successStatusActionsMessages = createEnum({
  [commonStatusTypes.active]: 'activated',
  [commonStatusTypes.deleted]: 'deleted',
  [commonStatusTypes.inactive]: 'deactivated',
});

export const patchTypes = createEnum({
  versaCardio: 'VersaCardio',
  eCordumMCT: 'ECordumMCT',
});

export const commonRoles = createEnum({
  admin: 'Admin',
  contact: 'Contact',
  ecgTech: 'EcgTech',
  physician: 'Physician',
  superAdmin: 'SuperAdmin',
  technician: 'Technician',
});

export const roleLabels = createEnum({
  [commonRoles.admin]: 'Admin',
  [commonRoles.contact]: 'Contact',
  [commonRoles.ecgTech]: 'Ecg Tech',
  [commonRoles.physician]: 'Physician',
  [commonRoles.superAdmin]: 'Super Admin',
  [commonRoles.technician]: 'Technician',
  default: 'Unknown Role',
});

export const physicianStatusTypes = createEnum({
  active: 'Active',
  activeOd: 'Active OD',
  new: 'New',
  returningDevice: 'Returning device',
  cancelled: 'Cancelled',
  closed: 'Closed',
  dissatisfied: 'Dissatisfied',
});

export const procedureStatusTypes = createEnum({
  monitoring: 'Monitoring',
  ordered: 'Ordered',
  completed: 'Completed',
  deleted: 'Deleted',
});

export const inventoryConditionTypes = createEnum({
  Good: 'Good',
  Lost: 'Lost',
  BeingRepaired: 'Being Repaired',
  Unrepairable: 'Unrepairable',
});

export const autocompleteReasons = createEnum({
  clear: null,
  toggleInput: null,
  removeOption: 'remove-option',
});

export const reportTypes = createEnum({
  ongoing: 1,
  escalated: 2,
});

export const procedureTypes = createEnum({
  MCTOnly: null,
  HolterMCT: null,
});

export const reportStatuses = createEnum({
  inProgress: 1,
  generated: 2,
});
export const reportStatusLabels = createEnum({
  inProgress: 'InProgress',
  generated: 'Generated',
});

export const activityLabel = createEnum({
  none: 'None',
  low: 'Low',
  medium: 'Medium',
  high: 'High',
  default: 'N/A',
});

export const modalTypes = createEnum({
  eventStripModal: null,
  multipleEventsModal: null,
  fullDisclosureModal: null,
  similarEventsGridModal: null,
  fileUploadModal: null,
  kitAssignModal: null,
  contactCreate: null,
  contactEdit: null,
  procedureMonitoringTriages: null,
  physicianReportsReview: null,
  pdfReport: null,
  confirmation: null,
  pdfPreview: null,
  addNewPatient: null,
  reassignKitTenantModal: null,
  updatePasswordModal: null,
  informationModal: null,
  warningModal: null,
});

export const eventStripReducerNames = {
  eventTriageStrip: 'eventTriageStrip',
  monitoringEventStrip: 'monitoringEventStrip',
};

export const reportType = createEnum({
  ongoing: 'Ongoing',
  escalated: 'Escalated',
});

export const reportDayType = createEnum({
  last: null,
  current: null,
  missing: null,
});

export const escalatedEventParts = createEnum({
  preEvent: null,
  event: null,
  postEvent: null,
});

export const historyEntities = createEnum({
  kit: null,
  procedure: null,
});

export const resourceTypes = createEnum({
  technician: null,
  enroll: null,
  physician: null,
  procedure: null,
  escalated: null,
  late: null,
  unknowns: null,
  common: null,
  firstDay: null,
  ongoing: null,
  final: null,
  patientReview: null,
});

export const sendingReportFrequency = createEnum({
  daily: 'DailyReports',
  multi: 'MultiDayReports',
  singleMulti: 'SingleMultiReport',
});

export const mimeTypes = createEnum({
  pdf: 'application/pdf',
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  txt: 'text/plain',
  xls: 'application/vnd.ms-excel',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  jpeg: 'image/jpeg',
  jpg: 'image/jpeg',
  png: 'image/png',
  zip: 'application/zip',
  mp4: 'video/mp4',
  mkv: 'video/x-matroska,.mkv',
  avi: 'video/avi',
  apk: 'application/vnd.android.package-archive',
  default: '',
});

export const eventGroupSortTypes = createEnum({
  highestHR: 'HighestHR',
  lowestHR: 'LowestHR',
  newest: 'Newest',
  oldest: 'Oldest',
  longest: 'Longest',
  shortest: 'Shortest',
});

export const defaultSortByEventGroup = createEnum({
  [abnormalityTypeEnum.afib]: eventGroupSortTypes.highestHR,
  [abnormalityTypeEnum.pause]: eventGroupSortTypes.longest,
  [abnormalityTypeEnum.tachy]: eventGroupSortTypes.highestHR,
  [abnormalityTypeEnum.brady]: eventGroupSortTypes.lowestHR,
  [abnormalityTypeEnum.vTach]: eventGroupSortTypes.highestHR,
  [abnormalityTypeEnum.svTach]: eventGroupSortTypes.highestHR,
  [abnormalityTypeEnum.vRun]: eventGroupSortTypes.longest,
  [abnormalityTypeEnum.svRun]: eventGroupSortTypes.longest,
  default: eventGroupSortTypes.newest,
});

export const sortParamsAccordingToSortTypes = createEnum({
  [eventGroupSortTypes.highestHR]: { field: 'avgHeartRate', desc: true },
  [eventGroupSortTypes.lowestHR]: { field: 'avgHeartRate', desc: false },
  [eventGroupSortTypes.newest]: { field: 'dateFrom', desc: true },
  [eventGroupSortTypes.oldest]: { field: 'dateFrom', desc: false },
  [eventGroupSortTypes.longest]: { field: 'duration', desc: true },
  [eventGroupSortTypes.shortest]: { field: 'duration', desc: false },
});

export const activityTypes = createEnum({
  none: 'None',
  low: 'Low',
  medium: 'Medium',
  high: 'High',
});

export const temperatureTypeColors = createEnum({
  normal: '#182EF2',
  high: '#FFFF00',
  highest: '#D68812',
  critical: '#ce1717',
});
