import moment from 'moment';
import momentTZ from 'moment-timezone';

import { dateTimeNonSec } from 'common/constants/dateFormats';
import { celsiusToFahrenheit } from './index';

export const getTotalDays = (startDay, endDay) => {
  if (!startDay || !endDay) {
    return '';
  }

  const startDate = moment(startDay).subtract(1, 'day').startOf('day');
  return moment(endDay).diff(startDate, 'days');
};

export const getAge = (dob) => {
  if (!dob) {
    return '';
  }

  const startDate = moment(dob);
  const endDate = moment();
  return endDate.diff(startDate, 'years');
};

export const getTimeDifference = (time, unit = 'minutes') => {
  if (!time) {
    return {};
  }

  const now = moment();
  const lastActivity = moment(time);
  const minutesDifference = now.diff(lastActivity, unit);

  return {
    minutes: minutesDifference,
    hours: minutesDifference >= 60 ? Math.ceil(minutesDifference / 60) : null,
  };
};

export const getTimeZone = () => {
  const currentZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const currentZoneAbbr = momentTZ.tz.zone(currentZone).abbr(moment());

  return `${currentZone} - ${currentZoneAbbr}`;
};

export const getDurationUTC = (from, to) => {
  const dateFrom = new Date(from).getTime();
  const dateTo = new Date(to).getTime();

  return dateTo - dateFrom;
};

export const getCenterBetweenDatesUTC = (from, to) => {
  const dateFrom = new Date(from).getTime();
  const duration = getDurationUTC(from, to) / 2;

  return new Date(dateFrom + duration);
};

export const getFirstEqualOrAfterTimeIndex = (range, time, key = 'time') => {
  const currentTime = new Date(time).getTime();
  const iterator = ({ [key]: t }) => new Date(t).getTime() >= currentTime;
  const foundIndex = range.findIndex(iterator);

  return foundIndex > 0 ? foundIndex : 0;
};

export const getFormattedDuration = (duration, isSecond = false) => {
  const minutes = Math.floor(duration / 60 / 1000);
  const seconds = (duration - (isSecond ? 0 : minutes * 60 * 1000)) / 1000;

  const minutesFormat = minutes > 0 && !isSecond ? `${minutes}m ` : '';

  if (minutes === 0) {
    const extendedSecondsFormat =
      seconds >= 1 ? `${seconds.toFixed(1)}s` : '<1s';

    return `${minutesFormat}${extendedSecondsFormat}`;
  }

  const secondsFormat = seconds >= 1 ? `${seconds.toFixed(1)}s` : '1s';

  return `${minutesFormat}${secondsFormat}`;
};

export const getRecordsFromRange = (data, [from, to], key = 'time') => {
  const iterator = ({ [key]: t }) => {
    const date = moment(t);

    return date.isSameOrAfter(from) && date.isSameOrBefore(to);
  };

  return data.filter(iterator);
};

export const getRecordsOverlappedByRange = (
  data,
  [from, to],
  keys = ['dateFrom', 'dateTo']
) => {
  const iterator = ({ [keys[0]]: eventFrom, [keys[1]]: eventTo }) => {
    const dateFrom = moment(eventFrom);
    const dateTo = moment(eventTo);

    return (
      (dateTo.isSameOrAfter(from) && dateTo.isSameOrBefore(to)) ||
      (dateFrom.isSameOrBefore(to) && dateFrom.isSameOrAfter(from)) ||
      (dateFrom.isSameOrBefore(from) && dateTo.isSameOrAfter(to))
    );
  };

  return data.filter(iterator);
};

// non-ecg data exclusive function
const getRecordIndexFromRange = (dataRange, [from, to]) =>
  dataRange.findIndex(({ time, value, dateFrom, duration: d }) => {
    const duration = value?.duration || d;
    const startDate = time || dateFrom;
    const endDate = moment(startDate).clone().add(duration, 'milliseconds');

    return (
      (moment(startDate).isSameOrBefore(from) && endDate.isSameOrAfter(to)) ||
      (moment(from).isBetween(startDate, endDate, 'milliseconds', '[]') &&
        moment(to).isBetween(startDate, endDate, 'milliseconds', '[]'))
    );
  });

export const getTemperatureWithoutRange = (data, unit = '') => {
  if (!data?.length || !data) return 'N/A';

  return `${celsiusToFahrenheit(data)}${unit}`;
};

export const getRecordFromRange = (dataRange = [], [from, to]) => {
  const index = getRecordIndexFromRange(dataRange, [from, to]);

  if (index < 0) {
    return { index, time: from, value: 'N/A' };
  }

  const { value, time, dateFrom } = dataRange[index];

  return {
    index,
    time: time || dateFrom,
    value: typeof value === 'object' ? value.value : value,
  };
};

export const getParsedFromDate = (date) =>
  // to avoid warning related to the date is not in correct ISO format
  moment(new Date(date).toISOString()).format(dateTimeNonSec);

export const getProcedureTotalDays = (start, end) => {
  const from = moment(start);

  const totalDays = moment(end).diff(from, 'days', true);

  return Math.ceil(totalDays);
};

export const sliceDataByDateRange = (data, [from, to]) => {
  const fistIndex = getFirstEqualOrAfterTimeIndex(data, from);
  const lastIndex = getFirstEqualOrAfterTimeIndex(data, to);

  return data.slice(fistIndex, lastIndex);
};
