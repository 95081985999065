import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles({
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
    height: 22,
  },

  root: {
    '& label': {
      width: 'calc(100% - 36px)',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },

    '& .MuiInputLabel-shrink': {
      width: '100%',
    },
  },
});
