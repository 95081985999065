import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'classnames';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';

import { ecgTypeLabels, ecgTypeColors } from 'common/constants/ecgEnums';

const useStyles = makeStyles((theme) => ({
  select: {
    width: '100%',
    '& > .MuiInputBase-input': {
      display: 'flex',
      padding: theme.spacing(0.375, 2.5, 0.375, 0.5),
    },
    '& > .MuiInputBase-input:focus': {
      backgroundColor: theme.palette.transparent,
    },
    '& .DeleteEvent-icon': {
      display: 'none',
    },
    '&:before, &:after': {
      display: 'none',
    },
  },
  selectWithoutValue: {
    '& > .MuiInputBase-input': {
      color: theme.palette.text.secondary,
    },
  },
  multiMenuItem: {
    padding: theme.spacing(0.32, 0.5),
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    width: '100%',
    minWidth: 200,
  },
  deleteIcon: {
    position: 'absolute',
    right: 15,
    bottom: 2,
  },
  menuItemWrapper: {
    width: 15,
    height: 15,
    minWidth: 15,
    borderRadius: '50%',
    marginRight: theme.spacing(1),
  },
}));

const EventSelector = ({
  events,
  value,
  deletable,
  onChange,
  onDelete,
  getIsDeletable,
}) => {
  const styles = useStyles();

  const isValueValid = useMemo(
    () => events.find(({ id }) => id === value),
    [events, value]
  );

  const handleChangeEvent = (e) => {
    const event = events.find(({ id }) => id === e.target.value);
    onChange(event);
  };

  const handleDeleteEvent = (e, deleteEvent) => {
    e.stopPropagation();

    onDelete(deleteEvent);
  };

  return (
    <Select
      autoWidth
      displayEmpty
      onChange={handleChangeEvent}
      value={isValueValid ? value : ''}
      className={clsx(styles.select, { [styles.selectWithoutValue]: !value })}
    >
      <MenuItem value="" disabled>
        <Typography>Select event</Typography>
      </MenuItem>

      {events.map((event) => (
        <MenuItem
          key={event.id}
          value={event.id}
          className={styles.multiMenuItem}
        >
          <div
            className={styles.menuItemWrapper}
            style={{ backgroundColor: ecgTypeColors[event.abnormalityType] }}
          />

          <Typography>{ecgTypeLabels[event.abnormalityType]}</Typography>

          {deletable && getIsDeletable(event) && (
            <CloseIcon
              onClick={(e) => handleDeleteEvent(e, event)}
              className={clsx(styles.deleteIcon, 'DeleteEvent-icon')}
              fontSize="small"
            />
          )}
        </MenuItem>
      ))}
    </Select>
  );
};

EventSelector.defaultProps = {
  onChange: () => {},
  onDelete: () => {},
  deletable: false,
  getIsDeletable: () => true,
};

EventSelector.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  deletable: PropTypes.bool,
  getIsDeletable: PropTypes.func,
  events: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      abnormalityType: PropTypes.string,
    })
  ),
};

const isEqualProps = (prev, next) =>
  prev.events === next.events && prev.value === next.value;

export default React.memo(EventSelector, isEqualProps);
