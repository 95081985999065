import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  clearTokens,
  getPermissions,
  hasTokens,
  saveTokens,
} from 'common/utils/providerHelpers/tokensHelper';
import { commonRoles } from 'common/constants/enums';
import { profileActionTypes } from 'common/ducks/actionTypes';
import createAuthProvider from 'common/utils/providerHelpers/createAuthProvider';
import { errorMessages, urls } from './constants';

const authProvider = createAuthProvider(
  { saveTokens, clearTokens, hasTokens, getPermissions },
  urls,
  errorMessages
);

const defaultAuthState = {
  ...authProvider,
  loading: false,
  isEcgTech: false,
  isLoggedIn: null,
  profileData: null,
  termsAccepted: null,
  currentUserId: null,
  currentUserRole: null,
  currentTenantId: null,
  currentPhysicianId: null,
  isTenantAuthorized: false,
};

export const AuthContext = createContext(defaultAuthState);

const getProfileData = (state) => state.profile?.data;

const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [termsAccepted, setTerms] = useState(null);
  const [isLoggedIn, setLoggedIn] = useState(null);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(false);
  const profileData = useSelector(getProfileData);

  const fetchUser = useCallback(async () => {
    try {
      setLoading(true);

      const user = await authProvider.fetchUser();

      setLoading(false);

      return user;
    } catch (e) {
      console.error(e?.body?.message || 'User not found');
      return null;
    }
  }, []);

  const checkAuth = useCallback(() => {
    authProvider
      .checkAuth()
      .then((data) => {
        setLoggedIn(!!data);
        setUserData(data);
      })
      .catch(() => {
        setUserData(null);
        setLoggedIn(false);
        console.warn('Unauthorized');
      });
  }, []);

  const validateAccount = useCallback(
    async ({ params, onError }) => {
      try {
        setLoading(true);

        const result = await authProvider.validateAccount(params);
        setTerms(result?.termsAccepted);

        if (result?.token) {
          saveTokens(result.token);
          checkAuth();
        }

        setLoading(false);
      } catch (e) {
        setLoading(false);

        if (onError) {
          onError(e);
        }
      }
    },
    [checkAuth]
  );

  const acceptTermsOfUse = useCallback(
    async (params) => {
      setLoading(true);

      const result = await authProvider.acceptTermsOfUse(params);
      setTerms(true);
      setLoading(false);

      if (result?.token) {
        saveTokens(result.token);
        checkAuth();
      }

      return result;
    },
    [checkAuth]
  );

  const login = useCallback(
    async ({ params, onError }) => {
      try {
        setLoading(true);

        await authProvider.login(params);
        const user = await fetchUser();
        checkAuth();

        setLoading(false);

        return user;
      } catch (e) {
        if (onError) {
          onError(e);
          setLoading(false);
        }

        return null;
      }
    },
    [checkAuth, fetchUser]
  );

  const logout = useCallback(
    async (params) => {
      setLoading(true);

      const result = await authProvider.logout(params);
      checkAuth();
      setTerms(null);
      setLoading(false);

      return result;
    },
    [checkAuth]
  );

  const forgotPassword = useCallback(async ({ params, onSuccess, onError }) => {
    try {
      setLoading(true);

      const result = await authProvider.forgotPassword(params);

      if (onSuccess) {
        onSuccess(result);
      }
      setLoading(false);
    } catch (e) {
      if (onError) {
        onError(e);
      }

      setLoading(false);
    }
  }, []);

  const resetPassword = useCallback(
    async ({ params, onSuccess, onError }) => {
      try {
        setLoading(true);

        const result = await authProvider.resetPassword(params);

        dispatch({ type: profileActionTypes, payload: { data: result } });

        if (onSuccess) {
          onSuccess(result);
        }
        setLoading(false);
      } catch (e) {
        if (onError) {
          onError(e);
        }

        setLoading(false);
      }
    },
    [dispatch]
  );

  const clearLoginState = useCallback(() => {
    setTerms(null);
    setUserData(null);
  }, []);

  const value = useMemo(
    () => ({
      ...authProvider,
      validateAccount,
      acceptTermsOfUse,
      login,
      logout,
      loading,
      fetchUser,
      isLoggedIn,
      profileData,
      termsAccepted,
      resetPassword,
      forgotPassword,
      clearLoginState,
      isEcgTech: userData?.Role === commonRoles.ecgTech,
      currentUserId: userData?.Id,
      currentUserRole: userData?.Role,
      currentTenantId: userData?.TenantId,
      currentPhysicianId: userData?.PhysicianId,
      isTenantAuthorized: Boolean(userData?.TenantId),
    }),
    [
      login,
      logout,
      loading,
      userData,
      fetchUser,
      isLoggedIn,
      profileData,
      termsAccepted,
      resetPassword,
      forgotPassword,
      validateAccount,
      clearLoginState,
      acceptTermsOfUse,
    ]
  );

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
