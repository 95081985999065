import moment from 'moment';

import { dateTimeNonSec } from 'common/constants/dateFormats';
import createEnum from 'common/utils/actionTypesHelper/createEnum';

const RANGE_PART = 5;
const eventTypes = createEnum({
  pe: null,
  ecg: null,
  nonEcg: null,
});

const measureValues = {
  d: {
    singular: 'day',
    plural: 'days',
    short: 'd',
  },
  h: {
    singular: 'hour',
    plural: 'hours',
    short: 'h',
  },
  m: {
    singular: 'minute',
    plural: 'minutes',
    short: 'm',
  },
  s: {
    singular: 'second',
    plural: 'seconds',
    short: 's',
  },
};

const getMeasure = (value, unit, isShort) => {
  if (isShort) {
    return measureValues[unit].short;
  }

  const { plural, singular } = measureValues[unit];

  return value === 1 ? singular : plural;
};

export const getStudyPeriod = (period, shortMeasures = false) => {
  const duration = moment.duration(period);
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();
  const milliseconds = duration.milliseconds();

  const measureDays = getMeasure(days, 'd', shortMeasures);
  const measureHours = getMeasure(hours, 'h', shortMeasures);
  const measureMinutes = getMeasure(minutes, 'm', shortMeasures);
  const measureSeconds = getMeasure(seconds, 's', shortMeasures);

  if (days > 0) {
    return `${days} ${measureDays} ${hours} ${measureHours}`;
  }

  if (hours > 0) {
    return `${hours} ${measureHours} ${minutes} ${measureMinutes}`;
  }

  if (minutes > 0) {
    return `${minutes} ${measureMinutes} ${seconds} ${measureSeconds}`;
  }

  return `${(seconds + milliseconds / 1000).toFixed(1)} ${measureSeconds}`;
};

export const formatDateTime = (date) => moment(date).format(dateTimeNonSec);

const filterByRange = (list, from, to) =>
  list
    .map((event) => {
      const isPE = !!event.date && !(event.dateFrom || event.dateTo);

      if (isPE) {
        const eventDate = moment(event.date);
        return eventDate.isBetween(from, to) && { ...event, sortKey: 'date' };
      }

      const eventFrom = moment(event.dateFrom);
      const eventTo = moment(event.dateTo);

      if (from.isBefore(eventFrom) && to.isAfter(eventFrom)) {
        return { ...event, sortKey: 'dateFrom' };
      }

      if (from.isBefore(eventTo) && to.isAfter(eventTo)) {
        return { ...event, sortKey: 'dateTo' };
      }

      return from.isAfter(eventFrom) && to.isBefore(eventTo) && event;
    })
    .filter((event) => !!event);

const getCenterFromItems = (items = []) => {
  const sortedItems = items.sort((current, next) => {
    const currentValue = new Date(current[current.sortKey]).getTime();
    const nextValue = new Date(next[next.sortKey]).getTime();

    if (currentValue === nextValue) {
      return 0;
    }

    return currentValue > nextValue ? 1 : -1;
  });

  if (!sortedItems.length) {
    return false;
  }

  const first = sortedItems[0];
  const last = sortedItems.slice(-1)[0];

  if (sortedItems.length === 1) {
    return first[first.sortKey];
  }

  const diff = moment(last[last.sortKey]).diff(
    moment(first[first.sortKey]),
    'seconds',
    true
  );

  return moment(first[first.sortKey]).add(diff, 'seconds').toISOString();
};

const findAllFromManySources = (event, evType, events, nonEcg, pe) => {
  const result = { excluded: [], correlation: { items: [] } };
  const isPE = evType === eventTypes.pe;
  const isECG = evType === eventTypes.ecg;
  const isNonECG = evType === eventTypes.nonEcg;

  if (isPE) {
    const date = moment(event.date);
    const from = date.clone().subtract(RANGE_PART, 'minutes');
    const to = date.clone().add(RANGE_PART, 'minutes');

    result.excluded = filterByRange(pe, from, to).filter(
      (ev) => event.id !== ev.id
    );

    const eventItems = filterByRange(events, from, to);
    const nonEcgItems = filterByRange(nonEcg, from, to);

    result.correlation.items = result.correlation.items.concat(
      eventItems,
      nonEcgItems,
      event
    );

    result.correlation.center =
      getCenterFromItems(result.correlation.items) || event.date;
  }

  if (isECG) {
    const date = moment(event.dateFrom);
    const from = date.clone().subtract(RANGE_PART, 'minutes');
    const to = date.clone().add(RANGE_PART, 'minutes');

    result.excluded = filterByRange(events, from, to).filter(
      (ev) => event.id !== ev.id
    );

    result.correlation.items = filterByRange(nonEcg, from, to);
    result.correlation.center =
      getCenterFromItems(result.correlation.items) || event.dateFrom;
  }

  if (isNonECG) {
    const date = moment(event.date);
    const from = date.clone().subtract(RANGE_PART, 'seconds');
    const to = date.clone().add(RANGE_PART, 'seconds');

    result.correlation.items = filterByRange(events, from, to);
    result.correlation.center =
      getCenterFromItems(result.correlation.items) || event.date;
  }

  return result;
};

export const searchCorrelations = (
  events,
  pe,
  { temperatures, activities }
) => {
  const nonEcgEvents = [activities, temperatures].flat();
  const correlations = [];
  const addedEvents = { ecg: [], pe: [] };

  // PE grouping step
  pe.forEach((peEvent) => {
    const filteredPE = pe.filter(
      (peEv) => !addedEvents.pe.find((addedPE) => peEv.id === addedPE.id)
    );

    const { excluded, correlation } = findAllFromManySources(
      peEvent,
      eventTypes.pe,
      events,
      nonEcgEvents,
      filteredPE
    );

    addedEvents.pe = addedEvents.pe.concat(excluded);
    if (correlation.items.length) {
      correlations.push(correlation);
    }
  });

  // Ecg grouping step
  const filteredEcgEventsBeforeEcgCompare = events.filter(
    (ev) => !addedEvents.ecg.find((e) => ev.id === e.id)
  );

  filteredEcgEventsBeforeEcgCompare.forEach((ecgEvent, i, originArr) => {
    const filteredEcg = originArr.filter(
      (ecgEv) => !addedEvents.ecg.find((addedEcg) => ecgEv.id === addedEcg.id)
    );

    const { excluded, correlation } = findAllFromManySources(
      ecgEvent,
      eventTypes.ecg,
      filteredEcg,
      nonEcgEvents
    );

    addedEvents.ecg = addedEvents.ecg.concat(excluded);
    if (correlation.items.length) {
      correlations.push(correlation);
    }
  });

  // NonEcg grouping step
  const filteredEcgEventsBeforeNonEcgCompare = events.filter(
    (ev) => !addedEvents.ecg.find((e) => ev.id === e.id)
  );

  temperatures.forEach((nonEcgEvent) => {
    const { correlation } = findAllFromManySources(
      nonEcgEvent,
      eventTypes.nonEcg,
      filteredEcgEventsBeforeNonEcgCompare,
      temperatures
    );

    if (correlation.items.length) {
      correlations.push(correlation);
    }
  });

  activities.forEach((nonEcgEvent) => {
    const { correlation } = findAllFromManySources(
      nonEcgEvent,
      eventTypes.nonEcg,
      filteredEcgEventsBeforeNonEcgCompare,
      activities
    );

    // filterBefore adding duplicates
    correlation.items = correlation.items.filter(
      ({ id }) =>
        !correlations.find((cor) => !!cor.items.find((item) => item.id === id))
    );

    if (correlation.items.length) {
      correlations.push(correlation);
    }
  });

  return correlations;
};

const textDefinitions = {
  block: {
    singular: 'Block was',
    plural: 'Blocks were',
  },
  event: {
    singular: 'event was',
    plural: 'events were',
  },
};

export const formatTextAccordingToQuantity = (count, textKey = 'event') => {
  const { singular, plural } = textDefinitions[textKey];

  return count > 1 ? plural : singular;
};
