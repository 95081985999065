import { schema } from 'normalizr';

export const patient = new schema.Entity('patients');
export const physician = new schema.Entity('physicians');
export const kit = new schema.Entity('kits');

export const procedure = new schema.Entity('procedures', {
  kit,
  patient,
  physician,
});
