import { createRequestActionTypes } from 'common/utils/actionTypesHelper';

export const notesActionTypes = createRequestActionTypes(
  ['getList', 'getOne', 'clear', 'update', 'create', 'archive', 'unarchive'],
  'notes'
);

const initialState = {
  loading: false,
  isCreation: false,
  data: [],
  error: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case notesActionTypes.getList.requested:
      return { ...state, loading: true };
    case notesActionTypes.getList.success: {
      const {
        payload: { data, total },
      } = action;
      return {
        ...state,
        loading: false,
        data: [...state.data, ...data],
        total,
      };
    }
    case notesActionTypes.getList.failed:
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: action.message,
      };
    case notesActionTypes.clear.requested:
      return {
        ...state,
        data: [],
      };
    case notesActionTypes.create.requested:
      return {
        ...state,
        isCreation: true,
      };
    case notesActionTypes.create.success: {
      const { payload } = action;
      return {
        ...state,
        isCreation: false,
        data: [...state.data, payload.data],
        total: state.total + 1,
      };
    }
    case notesActionTypes.create.failed:
      return {
        ...state,
        ...action.payload,
        isCreation: false,
        error: action.message,
      };
    default:
      return state;
  }
};

export const fetchNotes = ({ pagination, ...payload }) => ({
  type: notesActionTypes.getList.requested,
  payload: {
    pagination,
    ...payload,
  },
});

export const createNote = (payload, onSuccess, onError) => ({
  type: notesActionTypes.create.requested,
  payload,
  onSuccess,
  onError,
});

export const clearData = () => ({
  type: notesActionTypes.clear.requested,
});
