import React, { useMemo } from 'react';
import moment from 'moment';
import clsx from 'classnames';
import { useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';

import { useProcedureLocked } from 'common/hooks';
import { useNotify } from 'common/modules/Notification/hooks';
import { ecgEventStatus } from 'common/constants/enums';
import { clearUndoState } from 'common/modules/Undo/ducks';
import { eventsActions } from 'common/ducks/general/actions';
import { abnormalityTypeEnum } from 'common/constants/ecgEnums';

const updateEventsMessages = {
  success: 'Events status has been updated successfully',
  error: 'Events status updating error',
};

const useStyles = makeStyles(({ palette }) => {
  const button = {
    width: 15,
    display: 'flex',
    cursor: 'pointer',
    userSelect: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid black',
    backgroundColor: palette.grey.A100,
  };

  return {
    container: {
      height: '100%',
      maxWidth: 15,
    },
    includeButton: {
      ...button,
      height: '100%',
    },
    significantButton: {
      ...button,
      height: '50%',
    },

    includeActive: {
      backgroundColor: palette.graphButtons.included,
    },

    includeWithSignificant: {
      height: '50%',
    },
    significantActive: {
      color: palette.text.light,
      backgroundColor: palette.graphButtons.significant,
    },

    disabled: {
      opacity: 0.5,
    },
  };
});

const GraphButtons = ({
  event = {},
  width = 15,
  includeButton = false,
  isPatientEvent = false,
  setEventStatus = null,
  significantButton = false,
  setEventSignificant = () => null,
}) => {
  const notify = useNotify();
  const dispatch = useDispatch();
  const locked = useProcedureLocked();
  const included = useMemo(
    () => event.status === ecgEventStatus.included,
    [event]
  );

  const isSignificant = event.mostSignificant;
  const classes = useStyles();

  const onUpdateSuccess = () =>
    notify.success({ message: updateEventsMessages.success, undoable: true });
  const onUpdateError = () => notify.error(updateEventsMessages.error);

  const handleInclude = () => {
    if (locked) {
      return;
    }

    if (setEventStatus) {
      setEventStatus();
      return;
    }

    dispatch(clearUndoState());

    const newStatus = included
      ? ecgEventStatus.accepted
      : ecgEventStatus.included;

    const { abnormalityType, ecg, label, mostSignificant, ...restEvent } =
      event;
    const isPe =
      isPatientEvent ||
      event?.patientEvent ||
      abnormalityType === abnormalityTypeEnum.patient;

    if (isPe) {
      const payload = {
        query: { procedureId: event.procedureId },
        body: {
          ...restEvent,
          status: newStatus,
        },
      };

      dispatch(
        eventsActions.updatePatientEvent(payload, [event], onUpdateSuccess)
      );
      return;
    }

    dispatch(
      eventsActions.updateEcgEvents(
        [{ ...restEvent, mostSignificant, abnormalityType, status: newStatus }],
        [event],
        onUpdateSuccess
      )
    );
  };

  const setAsSignificant = () => {
    if (locked || event?.mostSignificant) {
      return;
    }

    const setSignificantAction = setEventSignificant(
      {
        query: {
          ecgEventGroupId: event.id,
          reportDate: moment(event?.dateFrom).startOf('day').toISOString(),
        },
      },
      () => notify.success(updateEventsMessages.success),
      onUpdateError
    );

    if (setSignificantAction?.type) {
      dispatch(setSignificantAction);
    }
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="stretch"
      className={classes.container}
      style={{ width, maxWidth: width }}
    >
      {significantButton && (
        <Typography
          item
          variant="h3"
          component={Grid}
          style={{ width }}
          onClick={setAsSignificant}
          className={clsx(classes.significantButton, {
            [classes.significantActive]: isSignificant,
            [classes.disabled]: locked,
          })}
        >
          F
        </Typography>
      )}

      {includeButton && (
        <Typography
          item
          variant="h3"
          component={Grid}
          style={{ width }}
          onClick={handleInclude}
          className={clsx(classes.includeButton, {
            [classes.includeWithSignificant]: significantButton,
            [classes.includeActive]: included,
            [classes.disabled]: locked,
          })}
        >
          I
        </Typography>
      )}
    </Grid>
  );
};

export default GraphButtons;
