import { call, put, takeEvery } from 'redux-saga/effects';
import resources from 'common/constants/resources';
import dataProvider from 'store/dataProvider';
import { queryingPath } from 'common/utils/helpers/url';
import { phonesActionTypes } from '../reducer';

function* updateStatus(action) {
  const {
    payload: { id, status },
    onSuccess,
    onError,
  } = action;
  try {
    const url = queryingPath(resources.phone.status, { id });
    const { data } = yield call(dataProvider.updateOnly, url, status);

    yield put({
      type: phonesActionTypes.updateMany.success,
      requestPayload: action.payload,
      payload: data,
    });

    if (onSuccess) {
      onSuccess();
    }
  } catch ({ message }) {
    yield put({ type: phonesActionTypes.updateStatus.failed, message });
    onError();
  }
}

export default function* updatePhonesStatus() {
  yield takeEvery(phonesActionTypes.updateStatus.requested, updateStatus);
}
