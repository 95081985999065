import { call, takeEvery, put, all } from 'redux-saga/effects';

import dataProvider from 'store/dataProvider';
import resources from 'common/constants/resources';
import {
  hrGroupTypes,
  ecgEventStatus,
  eventStripReducerNames,
} from 'common/constants/enums';
import { actionTypes } from '../index';

const generateGetProcedureStripSaga = (actionType) =>
  function* getProcedureStrip({ payload }) {
    try {
      const {
        status,
        eventIds,
        reviewed,
        procedureId,
        range: [dateFrom, dateTo],
      } = payload;

      const allEventIds = eventIds.filter(Boolean);

      const filter = { dateFrom, dateTo, procedureId };

      const stripEcg = yield call(
        dataProvider.get,
        resources.ecgData.byPeriod,
        { filter: { ...filter, precision: 25 } }
      );

      const stripTemperatures = yield call(
        dataProvider.get,
        resources.sensorData.temperature,
        { filter }
      );

      const stripActivities = yield call(
        dataProvider.get,
        resources.sensorData.activity,
        { filter }
      );

      const stripBodyPosition = yield call(
        dataProvider.get,
        resources.sensorData.bodyPosition,
        { filter }
      );

      const stripEvents = yield call(
        dataProvider.get,
        resources.eventGroup.byPeriod,
        {
          filter: {
            ...filter,
            status: status ? status.filter(Boolean) : null,
            reviewed,
            eventGroupIds:
              reviewed || !allEventIds?.length ? undefined : allEventIds,
            withRangeIntersection: reviewed,
          },
        }
      );

      const stripBeats = yield call(
        dataProvider.get,
        resources.healthData.stripBeats,
        {
          filter: {
            ...filter,
            checkBeatsCount: false,
            groupType: hrGroupTypes.none,
          },
        }
      );

      const patientInfo = yield call(
        dataProvider.get,
        resources.healthData.patientEvents,
        {
          filter: {
            ...filter,
            status: status || [
              ecgEventStatus.accepted,
              ecgEventStatus.included,
              ecgEventStatus.denied,
              null,
            ],
          },
        }
      );

      const [
        { data: ecg },
        { data: eventGroups },
        { data: beats },
        { data: patientEvents },
        { data: temperatures },
        { data: activities },
        { data: bodyPosition },
      ] = yield all([
        stripEcg,
        stripEvents,
        stripBeats,
        patientInfo,
        stripTemperatures,
        stripActivities,
        stripBodyPosition,
      ]);

      yield put({
        type: actionType.strip.success,
        payload: {
          ecg,
          beats,
          eventGroups,
          patientEvents,
          temperatures,
          activities,
          bodyPosition,
        },
        requestPayload: payload,
      });
    } catch ({ message }) {
      yield put({
        type: actionType.strip.failed,
        payload: message,
        requestPayload: payload,
      });
    }
  };

export function* getProcedureStripSaga() {
  const eventStripActionTypes =
    actionTypes[eventStripReducerNames.eventTriageStrip];
  const monitoringStripActionTypes =
    actionTypes[eventStripReducerNames.monitoringEventStrip];
  yield takeEvery(
    eventStripActionTypes.strip.requested,
    generateGetProcedureStripSaga(eventStripActionTypes)
  );
  yield takeEvery(
    monitoringStripActionTypes.strip.requested,
    generateGetProcedureStripSaga(monitoringStripActionTypes)
  );
}
