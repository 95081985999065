import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    height: '100%',
    width: '100%',
    cursor: 'pointer',
  },
  container: {
    lineHeight: 0,
  },
  description: {
    maxHeight: 19,
    height: '100%',
  },
}));

export const descriptionStyles = makeStyles(({ palette, ...theme }) => ({
  container: {
    backgroundColor: palette.background.primary,
    '& h6': {
      [theme.breakpoints.down(1600)]: {
        fontSize: '0.5rem',
      },
      [theme.breakpoints.down(1450)]: {
        fontSize: '0.45rem',
      },
    },
  },

  containerSelected: {
    backgroundColor: palette.background.darkLight,
  },

  title: {
    maxWidth: 40,
    fontWeight: 500,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',

    [theme.breakpoints.down(1366)]: {
      maxWidth: 29,
    },
  },

  subInfo: {
    paddingRight: theme.spacing(0.25),
  },

  increasedText: {
    [theme.breakpoints.down(1600)]: {
      fontSize: '0.65rem',
    },
    [theme.breakpoints.down(1367)]: {
      fontSize: '0.6rem',
    },
  },
}));
