import {
  eventsSagas,
  patientsSagas,
  pdfReportSagas,
  proceduresSagas,
} from 'common/utils/commonSagaCreators';
import { commonActionTypes, reportActionTypes } from 'common/ducks/actionTypes';

export default [
  patientsSagas.createLockPatientSaga(commonActionTypes.lockPatients),
  patientsSagas.createUnlockPatientSaga(commonActionTypes.unlockPatients),
  proceduresSagas.createGetProcedureSaga(commonActionTypes.getShortProcedure),
  proceduresSagas.createGetFullProcedureSaga(commonActionTypes.getProcedure),
  eventsSagas.createUpdateEcgEventSaga(commonActionTypes.updateEcgEvents),
  eventsSagas.createRemoveEcgEventSaga(commonActionTypes.removeEcgEvents),
  eventsSagas.createPEUpdateSaga(commonActionTypes.updatePatientEvents),
  eventsSagas.createBulkStatusUpdateSaga(commonActionTypes.bulkStatusUpdate),
  eventsSagas.createChangeVisibilityPESaga(
    commonActionTypes.changeVisibilityPatientEvent
  ),
  eventsSagas.createSetInvertEcgSaga(commonActionTypes.setInvertEcg),
  eventsSagas.createSetInvertPEEcgSaga(commonActionTypes.setInvertPEEcg),

  pdfReportSagas.createReFaxSaga(reportActionTypes.reFax),
  pdfReportSagas.createReEmailSaga(reportActionTypes.reEmail),
  pdfReportSagas.createReviewReportSaga(reportActionTypes.review),
  pdfReportSagas.createReviewReportByFileIdSaga(
    reportActionTypes.reviewByFileId
  ),
];
