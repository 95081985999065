import { all, put, takeEvery, call } from 'redux-saga/effects';
import dataProvider from 'store/dataProvider';
import resources from 'common/constants/resources';
import { requestSaga } from 'common/utils/generators';
import { reportActionTypes } from 'common/ducks/actionTypes';
import { actionTypes } from './reducer';

function* fetchReportList() {
  yield requestSaga({
    actionType: actionTypes.list,
    request: dataProvider.getList,
    resource: resources.report.list,
  });
}

function* submitStatusAccepted() {
  yield requestSaga({
    actionType: reportActionTypes.submitAccepted,
    request: dataProvider.updateOne,
    resource: resources.report.submitStatusAccepted,
  });
}

function* submitEditsOption() {
  yield requestSaga({
    actionType: reportActionTypes.submitEditsOptions,
    request: dataProvider.updateOne,
    resource: resources.report.submitEditsOptions,
  });
}

function* getReport() {
  yield requestSaga({
    actionType: actionTypes.getReport,
    request: dataProvider.get,
    resource: resources.report.shortReport,
  });
}

// deleteOne
const deleteOneReport = (reportId) =>
  dataProvider.deleteOne(resources.report.index, { reportId });

function* deleteReportsSaga({ payload, onSuccess }) {
  try {
    const response = yield all(payload.map(deleteOneReport));

    yield put({
      type: actionTypes.delete.success,
      payload: response.data,
    });

    if (onSuccess) {
      yield call(onSuccess);
    }
  } catch ({ message }) {
    yield put({ type: actionTypes.delete.failed, message });
  }
}

function* deleteReports() {
  yield takeEvery(actionTypes.delete.requested, deleteReportsSaga);
}

export default [
  getReport,
  deleteReports,
  fetchReportList,
  submitEditsOption,
  submitStatusAccepted,
];
