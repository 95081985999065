import moment from 'moment';
import { getProcedureTotalDays } from 'common/utils/helpers/date';

const DATE_FORMAT = 'L';
const DATE_TIME_FORMAT = 'L LT';

export const getDate = (params) => {
  const { value } = params;
  return value && moment(value).format(DATE_FORMAT);
};

export const getDateTime = (params) => {
  const { value } = params;
  return value && moment(value).format(DATE_TIME_FORMAT);
};

export const getColData = ({ row }, col = 'patient') =>
  row?.original?.[col] || {};

export const getNameRecentProcedures = ({ value: name }) => name || 'Unknown';

export const getDOB = (params) => {
  const { birthDate } = getColData(params) || {};
  return birthDate ? moment(birthDate).format(DATE_FORMAT) : null;
};

export const getPhone = (params) => {
  const { phone } = getColData(params);

  return phone;
};

export const getKit = (params) => {
  const { serialNumber } = getColData(params, 'kit');
  return serialNumber || 'Unknown';
};

export const getPhysician = (params) => {
  const { fullName } = getColData(params, 'physician');
  return fullName || 'Unknown';
};

export const getDuration = (params) => {
  const startDate = getColData(params, 'startDate');
  const endDate = getColData(params, 'endDate');
  return startDate && endDate
    ? getProcedureTotalDays(startDate, endDate)
    : params.value;
};

export const getPatientName = (params) => {
  const { fullName, firstName, lastName } = getColData(params);

  return fullName || `${firstName} ${lastName}`;
};

export const getLockedByName = (params) => {
  const {
    lockedByFirstName = '',
    lockedByLastName = '',
    lockedByFullName = '',
  } = getColData(params, 'patient') || {};

  return (
    lockedByFullName ||
    `${lockedByFirstName} ${lockedByLastName}`.trim() ||
    'Unknown'
  );
};

export const getLockedAt = (params) => {
  const { lockedAt } = getColData(params, 'patient') || {};

  return lockedAt && moment(lockedAt).format(DATE_FORMAT);
};

export const getTimeElapsed = (params) => {
  const value = getColData(params, 'lastActivity');
  const isValue = typeof value === 'string';

  const duration = moment.duration(moment().diff(value));

  const hours = Math.floor(duration.asHours());

  if (hours < 24) {
    return isValue ? moment.utc(duration.asMilliseconds()).format('HH:mm') : '';
  }

  return isValue ? moment(value).fromNow(true) : '';
};

export const getLastKit = (params) => {
  const lastKit = params.value;
  const currentKit = getColData(params, 'kit');

  return currentKit?.id ? '' : lastKit;
};
