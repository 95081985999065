import { all, call, put, takeLatest } from 'redux-saga/effects';
import orderBy from 'lodash/orderBy';
import moment from 'moment';

import dataProvider from 'store/dataProvider';
import resources from 'common/constants/resources';
import { requestSaga } from 'common/utils/generators';
import {
  abnormalityTypeEnum,
  templatesAccordingAbnormalities,
} from 'common/constants/ecgEnums';

import { actionTypes } from './reducer';

function* getEventsEcg() {
  yield requestSaga({
    request: dataProvider.getList,
    resource: resources.ecgData.byPeriod,
    actionType: actionTypes.getEventEcg,
  });
}

function* getEvent() {
  yield requestSaga({
    request: dataProvider.getList,
    resource: resources.eventGroup.index,
    actionType: actionTypes.getEventWithEcg,
  });
}

function* getEventWithoutEcg() {
  yield requestSaga({
    request: dataProvider.getList,
    resource: resources.eventGroup.byPeriod,
    actionType: actionTypes.getEvent,
  });
}

function* preparePatientEvents(patientEvents, eventsFilter) {
  const { reviewed, stepPercentage, ecgLength, procedureId } = eventsFilter;
  const baseFilter = { reviewed, stepPercentage, ecgLength, procedureId };

  if (!reviewed) {
    return patientEvents.map((pe) => ({
      ...pe,
      dateFrom: moment(pe.date).subtract(3, 'seconds').toISOString(),
      dateTo: moment(pe.date).add(3, 'seconds').toISOString(),
      abnormalityType: abnormalityTypeEnum.patient,
    }));
  }

  const PEEventsRequests = patientEvents
    .filter(({ ecgEventGroupIds }) => ecgEventGroupIds.length)
    .map(({ id, ecgEventGroupIds }, index) => {
      const filter = {
        ...baseFilter,
        eventGroupIds: ecgEventGroupIds,
      };

      return new Promise((resolve) => {
        dataProvider
          .getList(resources.eventGroup.byPeriod, { filter })
          .then(({ data: events }) => {
            resolve(
              events.map(({ abnormalityType, ...eventData }) => ({
                ...eventData,
                patientEventId: id,
                patientEvent: true,
                eventNumber: index + 1,
                coreAbnormalityType: abnormalityType,
                abnormalityType: abnormalityTypeEnum.patient,
              }))
            );
          });
      });
    });

  const events = yield all(PEEventsRequests);

  return events
    .flat()
    .filter(({ status }) => eventsFilter.status.includes(status));
}

function* getProcedureTemplates(abType, filter) {
  const { data: templates } = yield call(
    dataProvider.getList,
    resources.eventGroup.templatesByPeriod,
    { filter }
  );

  return orderBy(
    templates,
    (template) => template.ecgEventGroups.length,
    'desc'
  ).map((template, index) => ({
    ...template,
    sequenceNumber: index + 1,
    abnormalityType: templatesAccordingAbnormalities[abType],
    similar: template.ecgEventGroups,
  }));
}

function* getProcedureEvents(action) {
  const {
    payload: { filter, templatesAbnormalities = [] },
  } = action;
  const {
    procedureId,
    reviewed = true,
    dateFrom,
    dateTo,
    status,
    abnormalityTypes,
  } = filter;
  const isPe =
    abnormalityTypes && abnormalityTypes.includes(abnormalityTypeEnum.patient);
  const abnormalities =
    abnormalityTypes &&
    abnormalityTypes.filter((type) => type !== abnormalityTypeEnum.patient);

  try {
    const { data: events } = abnormalityTypes
      ? yield call(dataProvider.getList, resources.eventGroup.byPeriod, {
          filter: {
            ...filter,
            abnormalityTypes: abnormalities,
          },
        })
      : { data: [] };

    const { data: responsePatientEvents } = isPe
      ? yield call(dataProvider.getList, resources.healthData.patientEvents, {
          filter: { procedureId, dateFrom, dateTo, status, reviewed },
        })
      : {};

    const preparedPatientEvents = isPe
      ? yield call(preparePatientEvents, responsePatientEvents, filter)
      : [];

    const allTemplates = templatesAbnormalities
      ? yield all(
          templatesAbnormalities.map((abType) =>
            getProcedureTemplates(abType, {
              ...filter,
              abnormalityTypes: [abType],
            })
          )
        )
      : [];

    const templates = allTemplates.flat();

    const allEvents = [...events, ...preparedPatientEvents, ...templates];

    yield put({
      type: actionTypes.getEvents.success,
      payload: { data: allEvents },
    });
  } catch ({ message }) {
    yield put({
      type: actionTypes.getEvents.failed,
      payload: message,
    });
  }
}

function* getProcedureEventsSaga() {
  yield takeLatest(actionTypes.getEvents.requested, getProcedureEvents);
}

function* updateEventsSaga() {
  yield requestSaga({
    request: dataProvider.createOne,
    resource: resources.eventGroup.review,
    actionType: actionTypes.setEventStatus,
  });
}

export default [
  getProcedureEventsSaga,
  getEventWithoutEcg,
  updateEventsSaga,
  getEventsEcg,
  getEvent,
];
