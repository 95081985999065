import { eventStripReducerNames } from 'common/constants/enums';
import { requestSaga } from 'common/utils/generators';
import resources from 'common/constants/resources';
import dataProvider from 'store/dataProvider';
import { actionTypes } from '../index';

export function* getPatientEventById() {
  yield requestSaga({
    actionType:
      actionTypes[eventStripReducerNames.eventTriageStrip].getPatientEventById,
    request: dataProvider.getOne,
    resource: resources.healthData.patientEvent,
  });
}
