import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import { axesGridChildrenPropTypes } from 'common/constants/sharedPropTypes';
import * as d3 from 'd3';

const SelectedTimeRange = ({ parent, range, middleLine = true }) => {
  const [start, , end] = range;
  const { height, xScale, options, clip, scale } = parent;

  const xz = useMemo(() => scale || xScale, [scale, xScale]);

  const rectHeight = height - options.margin.top - options.margin.bottom;
  const rectWidth = Math.max(xz(new Date(end)) - xz(new Date(start)), 0);

  const createLine = useCallback(
    () =>
      d3
        .line()
        .x((d) => d[0])
        .y((d) => d[1])([
        [rectWidth / 2, 0],
        [rectWidth / 2, rectHeight],
      ]),
    [rectHeight, rectWidth]
  );

  return (
    <g clipPath={clip}>
      <g transform={`translate(${xz(new Date(start))}, ${0})`}>
        <rect
          x={0}
          fill="yellow"
          width={rectWidth}
          height={rectHeight}
          fillOpacity={0.3}
        />
        {middleLine && (
          <path
            d={createLine()}
            stroke="red"
            height={height}
            strokeWidth={0.3}
          />
        )}
      </g>
    </g>
  );
};

SelectedTimeRange.propTypes = {
  middleLine: PropTypes.bool,
  range: PropTypes.arrayOf(PropTypes.string),
  ...axesGridChildrenPropTypes,
};

export default SelectedTimeRange;
