import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';

import TriageButton from 'common/components/buttons/DefaultButton';
import { eventGroupType } from 'common/constants/sharedPropTypes';
import { abnormalityTypeEnum } from 'common/constants/ecgEnums';

import { setCustomPerios } from '../ducks';
import { getVisibleRange, getOpenedEcgEvent } from '../ducks/selectors';
import TooltipForHRTypeEvents from './TooltipForHRTypeEvents';

const dailyHRAbnormalities = [
  abnormalityTypeEnum.lowestHR,
  abnormalityTypeEnum.highestHR,
];

const CustomPeriodButtons = ({ event, resource, openedEvent }) => {
  const dispatch = useDispatch();
  const visibleRange = useSelector(getVisibleRange(resource));
  const storeOpenedEvent = useSelector(
    getOpenedEcgEvent(openedEvent?.id, resource)
  );

  const currentEvent = useMemo(() => {
    if (event) {
      return event;
    }

    if (
      storeOpenedEvent &&
      dailyHRAbnormalities.includes(storeOpenedEvent?.abnormalityType)
    ) {
      return storeOpenedEvent;
    }

    return undefined;
  }, [event, storeOpenedEvent]);

  const isCustomPeriod = useMemo(() => {
    const { customDateFrom, customDateTo } = currentEvent || {};

    return Boolean(customDateFrom && customDateTo);
  }, [currentEvent]);

  const setCustomPeriod = useCallback(() => {
    const { id } = currentEvent || {};
    const [customDateFrom, customDateTo] = visibleRange || [];

    if (!customDateFrom) {
      return;
    }

    dispatch(setCustomPerios({ id, customDateFrom, customDateTo }, resource));
  }, [currentEvent, resource, dispatch, visibleRange]);

  const resetCustomPeriod = useCallback(() => {
    const { id } = currentEvent || {};
    dispatch(
      setCustomPerios(
        { id, customDateFrom: null, customDateTo: null },
        resource
      )
    );
  }, [dispatch, currentEvent, resource]);

  return (
    <Grid container style={{ paddingTop: 8 }} spacing={1}>
      <Grid item>
        <TooltipForHRTypeEvents
          actionType="Select custom view"
          event={currentEvent}
        >
          <TriageButton
            disabled={!currentEvent?.id}
            onClick={setCustomPeriod}
            text="Select custom view"
          />
        </TooltipForHRTypeEvents>
      </Grid>

      <Grid item>
        <TriageButton
          text="Reset"
          disabled={!currentEvent?.id || !isCustomPeriod}
          onClick={resetCustomPeriod}
        />
      </Grid>
    </Grid>
  );
};

CustomPeriodButtons.propTypes = {
  event: PropTypes.oneOfType([eventGroupType, PropTypes.any]),
  openedEvent: PropTypes.oneOfType([eventGroupType, PropTypes.any]),
  resource: PropTypes.string,
};

export default CustomPeriodButtons;
