import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { eventsActions } from 'common/ducks/general/actions';
import { useNotify } from 'common/modules/Notification/hooks';
import useStyles from '../../styles';
import EventSelector from '../EventSelector';
import { getEcgEventsInRange } from '../../utils';
import { getEventGroups } from '../../ducks/selectors';

const EcgEventsSelector = ({
  parent = {},
  resource = '',
  selectedEvent = null,
  handleEventSelect = () => null,
  selectedBeatRange = null,
  selectedPatientEvent = null,
}) => {
  const { scale } = parent;
  const styles = useStyles();
  const notify = useNotify();
  const dispatch = useDispatch();
  const eventGroups = useSelector(
    getEventGroups(resource, { withoutHRMin: true, inRange: false })
  );
  const isPE = !!selectedPatientEvent;

  const eventsIntersectedWithSelectedBeatRange = useMemo(() => {
    if (isPE && !selectedBeatRange) {
      return eventGroups.filter(({ id }) =>
        selectedPatientEvent.ecgEventGroupIds?.includes(id)
      );
    }

    if (!selectedBeatRange) {
      return null;
    }

    return getEcgEventsInRange(eventGroups, selectedBeatRange);
  }, [eventGroups, isPE, selectedBeatRange, selectedPatientEvent]);

  const xPosition = useMemo(() => {
    if (isPE && !selectedBeatRange && scale) {
      const position = scale(new Date(selectedPatientEvent.date));

      return position - 75;
    }

    if (!selectedBeatRange?.length || !scale) {
      return 0;
    }

    const [from] = selectedBeatRange;

    return scale(from) - 75;
  }, [isPE, scale, selectedBeatRange, selectedPatientEvent]);

  if (!eventsIntersectedWithSelectedBeatRange?.length) {
    return null;
  }

  const onSuccess = () =>
    notify.success({ message: 'Event has been updated', undoable: true });

  const handleDetachPatientEvent = (ev) => {
    if (!isPE) {
      return;
    }

    const { procedureId } = ev;

    const payload = {
      query: { procedureId },
      body: {
        ...selectedPatientEvent,
        ecgEventGroupIds: selectedPatientEvent.ecgEventGroupIds.filter(
          (id) => id !== ev.id
        ),
      },
    };

    dispatch(
      eventsActions.updatePatientEvent(
        payload,
        [{ ...selectedPatientEvent, procedureId }],
        onSuccess
      )
    );
  };

  const getIsDeletable = (event) => {
    if (
      !event ||
      !selectedPatientEvent ||
      selectedPatientEvent?.ecgEventGroupIds?.length <= 1
    ) {
      return false;
    }

    return !!selectedPatientEvent.ecgEventGroupIds.find(
      (id) => id === event?.id
    );
  };

  return (
    <div style={{ left: xPosition }} className={styles.eventSelectorContainer}>
      <EventSelector
        deletable={isPE}
        onChange={handleEventSelect}
        value={selectedEvent?.id || ''}
        onDelete={handleDetachPatientEvent}
        events={eventsIntersectedWithSelectedBeatRange}
        getIsDeletable={getIsDeletable}
      />
    </div>
  );
};

EcgEventsSelector.propTypes = {
  parent: PropTypes.shape(),
  resource: PropTypes.string,
  selectedEvent: PropTypes.shape(),
  handleEventSelect: PropTypes.func,
  selectedPatientEvent: PropTypes.shape(),
  selectedBeatRange: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)])
  ),
};

export default EcgEventsSelector;
