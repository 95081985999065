import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDebouncedCallback } from 'use-debounce';
import { useSelector, useDispatch } from 'react-redux';

import {
  CurveLine,
  BeatRMark,
  Grid as D3Grid,
  BeatDurationLabel,
  Container as D3Container,
} from 'common/components/D3Components';
import {
  EventLine,
  SymptomRect,
  BeatOutline,
} from 'common/components/D3Components/eventStrip';
import paths from 'common/constants/path';
import useRouterInfo from 'common/hooks/useRouterInfo';
import { settingProps } from 'common/constants/sharedPropTypes';
import { getBeatIndex } from 'common/components/D3Components/utils';
import {
  getEcg,
  getBeats,
  getLoading,
  getEventGroups,
  getPatientEvents,
  getVisibleRPoints,
  getVisibleBeatsDuration,
} from '../ducks/selectors';
import MeasureRuler from './MeasureRuler';
import { setVisibleRange } from '../ducks';
import EcgEventsSelector from './EcgEventsSelector';

const graphOptions = {
  xPropKey: 'time',
  yPropKey: 'value',
  padding: { top: 0, left: 0, right: 0, bottom: 0 },
};

const eventsSelectorOptions = { withoutHRMin: true, inRange: true };

const EventStripGraph = ({
  width,
  height,
  settings,
  onClick,
  position,
  inverted,
  resource,
  onPEClick,
  focusOptions,
  selectedEvent,
  handleEventSelect,
  isMeasureRulerShown,
  selectedPatientEvent,
}) => {
  const router = useRouterInfo();
  const dispatch = useDispatch();
  const beats = useSelector(getBeats(resource));
  const loading = useSelector(getLoading(resource));
  const visibleRPoints = useSelector(getVisibleRPoints(resource));
  const ecgEvents = useSelector(
    getEventGroups(resource, eventsSelectorOptions)
  );
  const ecgData = useSelector(getEcg(resource, true, 20));
  const visibleBeatsDuration = useSelector(getVisibleBeatsDuration(resource));
  const patientEvents = useSelector(getPatientEvents(resource, true));

  const isMonitoringPage = useMemo(
    () => router?.url === paths.private.procedures.monitoring.index,
    [router]
  );

  const lineTransform = useMemo(
    () =>
      !isMonitoringPage && inverted
        ? `scale(1, -1) translate(0, -${height + 30})`
        : undefined,
    [height, inverted, isMonitoringPage]
  );

  const clickHandler = useCallback(
    (point, isShiftKey) => {
      if (!beats.length || loading) return;

      const index = getBeatIndex(beats, point);

      if (index <= 0) {
        return;
      }

      if (!isShiftKey) {
        const start = beats[index - 1][graphOptions.xPropKey];
        const end = beats[index][graphOptions.xPropKey];

        onClick([start && new Date(start), end && new Date(end)]);

        return;
      }

      if (!position) return;

      const isLeftDirection = point.getTime() < position[0].getTime();
      if (isLeftDirection) {
        const start = new Date(beats[index - 1][graphOptions.xPropKey]);
        onClick([start, position[1]]);
      } else {
        const end = new Date(beats[index][graphOptions.xPropKey]);
        onClick([position[0], end]);
      }
    },
    [beats, position, loading, onClick]
  );

  const PEClickHandler = useCallback(
    (event) => {
      onPEClick(event);
    },
    [onPEClick]
  );

  const onScrollHandler = useCallback(
    (inv, sc, range) => {
      if (!range) {
        return;
      }

      dispatch(setVisibleRange(range, resource));
    },
    [dispatch, resource]
  );

  const scrollDebounce = useDebouncedCallback(onScrollHandler, 0);

  const ecgEventSelector = useMemo(
    () => (
      <EcgEventsSelector
        resource={resource}
        selectedBeatRange={position}
        selectedEvent={selectedEvent}
        handleEventSelect={handleEventSelect}
        selectedPatientEvent={selectedPatientEvent}
      />
    ),
    [resource, position, selectedEvent, handleEventSelect, selectedPatientEvent]
  );

  return (
    <D3Container
      zoomable
      forceScroll
      width={width}
      height={height}
      focus={focusOptions}
      options={graphOptions}
      stripSettings={settings}
      onClick={clickHandler}
      onScrollHandler={scrollDebounce}
      foreignObjects={[ecgEventSelector]}
    >
      <D3Grid color="gray" strokeWidth={0.5} />

      {visibleBeatsDuration.map((d) => (
        <BeatDurationLabel {...d} key={d.time} />
      ))}

      {visibleRPoints.map((d) => (
        <BeatRMark {...d} key={d.time} />
      ))}

      <CurveLine
        data={ecgData}
        color="black"
        strokeWidth={2}
        transform={lineTransform}
      />

      {position && beats.length && <BeatOutline position={position} />}

      {isMeasureRulerShown && beats.length && (
        <MeasureRuler beats={beats} position={position} />
      )}

      {ecgEvents.map((ev) => (
        <EventLine key={ev.id} event={ev} />
      ))}

      {patientEvents.map((event) => (
        <SymptomRect
          id={event.id}
          key={event.id}
          position={event.date}
          onClick={() => PEClickHandler(event)}
        />
      ))}
    </D3Container>
  );
};

EventStripGraph.defaultProps = {
  onClick: () => null,
};

EventStripGraph.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  onClick: PropTypes.func,
  inverted: PropTypes.bool,
  settings: settingProps,
  resource: PropTypes.string,
  position: PropTypes.arrayOf(PropTypes.any),
  onPEClick: PropTypes.func,
  selectedEvent: PropTypes.shape({}),
  handleEventSelect: PropTypes.func,
  isMeasureRulerShown: PropTypes.bool,
  focusOptions: PropTypes.shape({
    center: PropTypes.string,
    yDomain: PropTypes.arrayOf(PropTypes.number),
    xDomain: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  }),
  selectedPatientEvent: PropTypes.shape({}),
};

const isEqual = (prev, next) =>
  prev.width === next.width &&
  prev.center === next.center &&
  prev.onClick === next.onClick &&
  prev.loading === next.loading &&
  prev.inverted === next.inverted &&
  prev.resource === next.resource &&
  prev.settings === next.settings &&
  prev.position === next.position &&
  prev.focusOptions === next.focusOptions &&
  prev.selectedEvent === next.selectedEvent &&
  prev.isMeasureRulerShown === next.isMeasureRulerShown;

export default React.memo(EventStripGraph, isEqual);
