import grey from '@material-ui/core/colors/grey';
import { resourceTypes } from 'common/constants/enums';

export default {
  transparent: 'transparent',
  logo: {
    main: '#191E9B',
  },
  background: {
    primary: '#FFFFFF',
    mainLight: '#D9E1F2',
    secondary: '#E5E5E5',
    secondaryLight: '#F7F8FC',
    secondaryDark: '#E3E3E3',
    lightGrey: '#F1F3F4',
    darkGrey: '#605E5E',
    midGrey: '#706D6D',
    dark: '#000000',
    darkLight: '#A4C5FF',
    mainDarkLight: '#C6D8EF',
    eventStrip: '#DDE0FF',
    mainDark: '#3D4978',
    mainAuthDark: '#57639C',
    mainDark90: '#395691',
    note: '#7a613d',

    pdfBg: '#c9dcf7',
    pdfBgGrayscale: '#D9D9D9',
    calendar: '#f6f7fb',
  },
  border: {
    primary: '#C4C4C4',
    lightenDark: '#eeeeee',
    lightGrey: '#F1F3F4',
    dark: '#000000',
    table: '#dadada',
  },
  grey: {
    lighter: '#F7F8FC',
    A200: '#EEEEEE',
    A300: '#E5E5E5',
    A400: '#757575',
    A500: '#C4C4C4',
    A600: '#E3E3E3',
    A900: '#212121',
  },
  primary: {
    lighter: '#9197AD',
    main: '#4D5883',
    dark: '#3D4978',
  },
  text: {
    primary: '#333333',
    gray: '#5E6366',
    lightGrey: '#C4C4C4',
    secondary: grey['500'],
    light: '#FFFFFF',
    colored: '#4C8AFB',
    coloredDark: '#3A4693',
    red: '#FF000F',
    error: '#ff4343',
    dark: '#000000',
  },
  // TODO: refactor color structure. Remove general names with creating color groups according to used place
  brown: {
    A300: '#FF8E3B',
    A400: '#DE8746',
    A600: '#BD6D31',
  },
  blue: {
    A100: '#DDF1FF',
    A200: '#D9E1F2',
    A300: '#B4C6E7',
    A400: '#2CB0FF',
    A700: '#607cba',
  },
  green: {
    main: '#3a8966',
  },
  loginIcons: '#4C788F',
  borderColor: grey[500],
  icons: {
    heart: '#FB001F',
  },
  eventIcons: {
    oxygen: '#4C8AFB',
    activity: '#000000',
    respiration: '#18DAA0',
    temperature: '#FF9898',
    tempPurple: 'purple',
  },
  colorCoding: {
    grey: {
      main: '#CECECE',
      hover: '#CECECE',
      text: '#FFF',
      hoverText: '#FFF',
    },
    green: {
      main: '#B8E29E',
      hover: '#B8E29E',
      text: '#FFF',
      hoverText: '#FFF',
    },
    red: {
      main: '#FF9F9F',
      hover: '#FF9F9F',
      text: '#FFF',
      hoverText: '#FFF',
    },
    blue: {
      main: '#4BBDFD',
      hover: '#4BBDFD',
      text: '#FFF',
      hoverText: '#FFF',
    },
    accept: {
      main: '#25AE85',
      hover: '#25AE85',
      text: '#FFF',
      hoverText: '#FFF',
    },
    include: {
      main: '#18DAA0',
      hover: '#18DAA0',
      text: '#FFF',
      hoverText: '#FFF',
    },
    deny: {
      main: '#E05B5B',
      hover: '#E05B5B',
      text: '#FFF',
      hoverText: '#FFF',
    },
    report: {
      main: '#647DFF',
      hover: '#647DFF',
      text: '#FFF',
      hoverText: '#FFF',
    },
    cancel: {
      main: '#757575',
      hover: '#757575',
      text: '#FFF',
      hoverText: '#FFF',
    },
  },
  headerButtonColors: {
    buttons: {
      bg: '#EAE9E9',
      border: '#FFF',
      text: '#000',
    },
    base: {
      createReport: '#D9E1F2',
      base: '#b4c6e7',
    },
  },
  triages: {
    userCard: {
      fontColor: '#000',
    },
    tableCheckbox: {
      checkColor: '#606060',
      bg: '#FFF',
      directionColor: '#606060',
    },
    [resourceTypes.escalated]: {
      main: '#95B7E1',
      asset: '#C6D8EF',
      contentBackground: '#FFF',
    },
    [resourceTypes.unknowns]: {
      main: '#FFCDC6',
      asset: '#FFE6E2',
    },
    [resourceTypes.common]: {
      main: '#CCFFD1',
      asset: '#B3F7B9',
    },
    commonAlt: {
      main: '#acf3e7',
      asset: '#CBFFF6',
    },
    [resourceTypes.late]: {
      main: '#FFCDC6',
      asset: '#FFE6E2',
    },
    [resourceTypes.firstDay]: {
      main: '#D0EBFF',
      asset: '#BBE2FF',
    },
    [resourceTypes.ongoing]: {
      main: '#ADDCFF',
      asset: '#78C6FF',
    },
    [resourceTypes.final]: {
      main: '#ADDCFF',
      asset: '#4EB4FF',
    },
  },
  label: {
    green: '#38EA49',
    red: '#FF9898',
    orange: '#FFC267',
  },
  notification: {
    success: '#2BA936',
    info: '#FFAA09',
    error: '#F44336',
  },
  highlight: {
    blue: '#182EF2',
    purple: '#F218DC',
    orange: '#D68812',
  },
  button: {
    accept: {
      base: '#1b7508',
      hover: '#248e0b',
    },
    warning: {
      base: '#e31a1a',
      hover: '#ce1717',
    },
    close: {
      base: '#555555',
      hover: '#404040',
    },
  },
  reportPanel: {
    missing: '#FF9E9E',
    selectedMissing: '#FF7373',
    last: '#FC9FF3',
    selectLast: '#FF74F4',
    current: '#B5E7B4',
    selectCurrent: '#03E600A1',
    grey: '#B4C6E7',
    selected: '#7EAAFF',
    default: '#D9E1F2',
    selectedDefault: '#6DB0FFD4',
    selectedAbnormality: '#cf3737',
  },
  reportReview: {
    emergent: {
      titleBg: '#FD2727',
      background: '#FF6868',
    },
    snackbar: {
      main: '#4BBDFD',
    },
  },
  graphButtons: {
    included: '#38EA49',
    significant: '#07385D',
  },
  triageCalendar: {
    rangeDay: '#CADCFF',
    startDay: '#CBFFCA',
    endDay: '#EECAFF',
  },
};
