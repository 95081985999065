import createEnum from 'common/utils/actionTypesHelper/createEnum';
import { commonStatuses } from 'common/constants/formFieldOptions';
import { commonStatusTypes } from 'common/constants/enums';

export const filterFieldOptions = createEnum({
  fullName: {
    label: 'Full Name',
    name: 'fullNames',
    multiple: true,
  },
  phone: {
    label: 'Phone',
    name: 'phones',
    multiple: true,
  },
  status: {
    select: true,
    resource: null,
    label: 'Status',
    name: 'statuses',
    options: commonStatuses,
    defaultValue: commonStatusTypes.active,
  },
});
