import { call, put, takeEvery } from 'redux-saga/effects';
import dataProvider from 'store/dataProvider';
import { filterActionTypes } from '../index';

function* getFilters(action) {
  try {
    const { resource, ...payload } = action.payload;

    const { data } = yield call(dataProvider.getList, resource, payload);

    yield put({ type: filterActionTypes.getList.success, payload: { data } });
  } catch (e) {
    console.error(e);
  }
}

function* getFiltersSaga() {
  yield takeEvery(filterActionTypes.getList.requested, getFilters);
}

export default [getFiltersSaga];
