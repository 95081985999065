import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import moment from 'moment';

import {
  messageType,
  eventOperationEnum,
} from 'common/constants/socketConstants';
import { abnormalityTypeEnum } from '../../constants/ecgEnums';

const OPERATION_TYPE_KEY = 'operationType';
const EVENT_GROUP_KEY = 'ecgEventGroupId';
const DATE_SORT_KEYS = ['dateFrom', 'dateTo'];

const getArguments = (message) => message.arguments;

const eventMapper = (array) =>
  array.map(({ operationType, [EVENT_GROUP_KEY]: ecgEventId, id, ...ev }) => ({
    ...ev,
    id: id || ecgEventId,
  }));

const eventFilterByRange = (array, range) => {
  if (!range) return array;

  return array.filter(
    (ev) =>
      moment(ev.dateFrom).isBetween(...range) ||
      moment(ev.dateTo).isBetween(...range)
  );
};

const patientEventMapper = (array) =>
  array.map(({ patientId, operationType, ...ev }) => ({
    ...ev,
    abnormalityType: abnormalityTypeEnum.patient,
  }));

const patientEventFilterByRange = (array, range, dateKey = 'date') => {
  if (!range) return array;

  return array.filter((ev) => moment(ev[dateKey]).isBetween(...range));
};

const updateEventsBySocket = (events, payload, statuses) => {
  const newUpdated = payload.updated.filter(
    (e) => !events.find((ev) => e.id === ev.id)
  );

  return uniqBy(events.concat([...payload.added, ...newUpdated]), 'id')
    .map((ev) => ({
      ...ev,
      ...(payload.updated.find((e) => e.id === ev.id) || {}),
    }))
    .filter((ev) => {
      if (!statuses) {
        return !ev.status;
      }

      return statuses.includes(ev.status);
    })
    .filter(({ id }) => !payload.removed.find((ev) => ev.id === id));
};

const groupMessageByType = (message) => {
  const isReport = message.target === messageType.Report;
  const isEventGroup = message.target === messageType.EcgEventGroup;
  const isPatientEvent = message.target === messageType.PatientEvent;
  const isEcgHealthData = message?.target === messageType.EcgHealthData;

  return {
    isReport,
    isEventGroup,
    isPatientEvent,
    isEcgHealthData,
    payload: getArguments(message),
  };
};

const eventGroupMessageMapper = (payload, range = null) => {
  const grouped = groupBy(payload, OPERATION_TYPE_KEY);

  const newEvents = grouped[eventOperationEnum.added];
  const sortedNewEvents = sortBy(newEvents, DATE_SORT_KEYS).reverse();

  const added = eventFilterByRange(eventMapper(sortedNewEvents || []), range);

  const updated = eventFilterByRange(
    eventMapper(grouped[eventOperationEnum.modified] || []),
    range
  );

  const removed = eventFilterByRange(
    eventMapper(grouped[eventOperationEnum.removed] || []),
    range
  );

  return { added, updated, removed };
};

const patientEventMessageMapper = (payload, range = null) => {
  const grouped = groupBy(payload, OPERATION_TYPE_KEY);

  const added = patientEventFilterByRange(
    patientEventMapper(grouped[eventOperationEnum.added] || []),
    range
  );

  const updated = patientEventFilterByRange(
    patientEventMapper(grouped[eventOperationEnum.modified] || []),
    range
  );

  const removed = patientEventFilterByRange(
    patientEventMapper(grouped[eventOperationEnum.removed] || []),
    range
  );

  return { added, updated, removed };
};

export {
  groupMessageByType,
  eventGroupMessageMapper,
  patientEventMessageMapper,
  updateEventsBySocket,
};
