import { Children, cloneElement, useMemo } from 'react';
import PropTypes from 'prop-types';

import { yGen } from '../../utils';

const EventsWrapper = ({ children, parent, xDomain, ...props }) => {
  const { data, height, xScale, options = {} } = parent;
  const { yPropKey, padding, settings } = options;

  const yScale = useMemo(
    () =>
      yGen(
        data,
        yPropKey,
        [height - padding.bottom, padding.top],
        settings.amplitude
      ),
    [data, height, padding.bottom, padding.top, yPropKey, settings.amplitude]
  );

  if (!children) {
    return null;
  }

  const childProps = {
    parent,
    options,
    xScale,
    yScale,
    allData: data,
    ...props,
  };

  return Children.map(children.filter(Boolean), (child) =>
    cloneElement(child, { ...childProps, data: child.props?.data || data })
  );
};

EventsWrapper.propTypes = {
  children: PropTypes.node,
  parent: PropTypes.objectOf(PropTypes.any),
  xDomain: PropTypes.arrayOf(PropTypes.object),
};

export default EventsWrapper;
