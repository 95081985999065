import { updateNotes, procedureInfoSaga } from './procedure';
import splitProcedureByDate from './splitProcedureByDate';
import endProcedure from './endProcedure';
import { getFiveMinutesGraphSaga } from './fiveMinutesGraph';
import {
  getNonEcgEvents,
  getEcgEventsPeriod,
  getHeartRateSummary,
  getProcedureECGPeriod,
  getPatientEventsPeriod,
  getProcedureHeartRatePeriod,
} from './procedureTrend';

export default [
  updateNotes,
  procedureInfoSaga,
  getNonEcgEvents,
  getEcgEventsPeriod,
  getHeartRateSummary,
  getProcedureECGPeriod,
  getPatientEventsPeriod,
  getProcedureHeartRatePeriod,
  getFiveMinutesGraphSaga,
  splitProcedureByDate,
  endProcedure,
];
