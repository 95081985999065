import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { templateTypes } from 'common/constants/ecgEnums';
import TemplateItem from './TemplateItem';
import EventItem from './EventItem';

const GridItem = ({ currentEvent, ...props }) => {
  const { id: procedureId = '' } = useParams();

  const isTemplates = useMemo(
    () => templateTypes.includes(currentEvent?.abnormalityType),
    [currentEvent]
  );

  const fullEventData = useMemo(
    () => ({
      ...(currentEvent || {}),
      label: currentEvent?.patientEvent
        ? `Patient Event (${currentEvent.coreAbnormalityType})`
        : currentEvent?.abnormalityType,
    }),
    [currentEvent]
  );

  if (!currentEvent) {
    return null;
  }

  if (isTemplates) {
    return (
      <TemplateItem
        {...props}
        height={100}
        procedureId={procedureId}
        currentEvent={fullEventData}
      />
    );
  }

  return (
    <EventItem
      {...props}
      procedureId={procedureId}
      currentEvent={fullEventData}
    />
  );
};

export default GridItem;
