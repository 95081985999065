import { abnormalityTypeEnum } from 'common/constants/ecgEnums';

const width = 1920;
const height = 37;

export const expectedDuration = 120;
export const selectedRangeRadius = 5;

const xRange = [0, expectedDuration];
const yRange = [0, 40];

export const stripsCount = 30;
export const rangeMinutesDuration = 60;
export const stripDurationInMinutes = rangeMinutesDuration / stripsCount;
export const eventsForLines = [
  abnormalityTypeEnum.afib,
  abnormalityTypeEnum.brady,
  abnormalityTypeEnum.tachy,
];

export const graphConfig = {
  width,
  height,
  yRange,
  xRange,
  options: {
    xPropKey: 'time',
    yPropKey: 'value',
  },
};
