import React, { useCallback, useEffect, useMemo, useState } from 'react';

import path from 'common/constants/path';
import dataProvider from 'store/dataProvider';
import resources from 'common/constants/resources';
import useAuthProvider from 'store/authProvider/useAuthProvider';
import AutocompleteInput from 'common/components/simpleInputs/AutocompleteInput';

import useStyles from '../entityAuthSelectorStyles';

const PhysicianSelector = () => {
  const authProvider = useAuthProvider();
  const { inputWrapper, ...classes } = useStyles();
  const [physicians, setPhysicians] = useState([]);

  const physicianId = useMemo(
    () => authProvider.currentPhysicianId,
    [authProvider.currentPhysicianId]
  );

  const currentUserId = useMemo(
    () => authProvider.currentUserId,
    [authProvider.currentUserId]
  );

  const currentSelectedPhysician = useMemo(() => {
    if (!physicianId || !physicians?.length) {
      return null;
    }

    return physicians.find(({ id }) => id === physicianId);
  }, [physicians, physicianId]);

  const selectPhysician = useCallback(
    (evt, value) => {
      if (value?.id === physicianId) {
        return;
      }

      dataProvider
        .createOne(resources.physician.loginToPhysician, {
          params: { id: value.id },
        })
        .then(({ data }) => {
          localStorage.accessToken = data.accessToken;
          const { origin } = document.location;

          document.location.replace(`${origin}${path.private.index}`);
        });
    },
    [physicianId]
  );

  const getContactPhysicians = useCallback(() => {
    dataProvider
      .getList(resources.physician.list, {
        filter: { assignedContactIds: [currentUserId] },
      })
      .then(({ data }) => {
        setPhysicians(data);
      });
  }, [currentUserId]);

  const renderOption = (option) => (
    <span
      style={{
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      }}
    >
      {option.fullName}
    </span>
  );

  useEffect(() => getContactPhysicians(), [getContactPhysicians]);

  return (
    <AutocompleteInput
      disableClearable
      name="physician"
      value={currentSelectedPhysician}
      classes={classes}
      variant="standard"
      options={physicians}
      onChange={selectPhysician}
      className={inputWrapper}
      optionLabel="fullName"
      renderOption={renderOption}
      optionValueName="id"
    />
  );
};

export default PhysicianSelector;
