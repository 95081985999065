import { profileActionTypes } from 'common/ducks/actionTypes';
import { requestSaga } from 'common/utils/generators';
import resources from 'common/constants/resources';
import dataProvider from 'store/dataProvider';

export function* getProfileSaga() {
  yield requestSaga({
    actionType: profileActionTypes.get,
    request: dataProvider.getOne,
    resource: resources.profile.account,
  });
}

export function* updateProfileSaga() {
  yield requestSaga({
    actionType: profileActionTypes.update,
    request: dataProvider.updateOne,
    resource: resources.profile.index,
  });
}

export function* updatePassword() {
  yield requestSaga({
    actionType: profileActionTypes.updatePassword,
    request: dataProvider.patchData,
    resource: resources.profile.updatePassword,
  });
}
