import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';

const useStyles = makeStyles(({ breakpoints }) => ({
  title: {
    fontSize: '1rem',
    [breakpoints.down(1367)]: {
      fontSize: '0.8rem',
    },
  },
  value: {
    fontSize: '0.9rem',
    [breakpoints.down(1367)]: {
      fontSize: '0.7rem',
    },
  },
}));

const StripValue = ({ title = '', value = '', flex = 40, styles = {} }) => {
  const classes = useStyles();

  return (
    <Grid
      item
      container
      spacing={1}
      direction="column"
      style={{ ...styles, flex }}
    >
      <Typography component={Grid} item variant="h3" className={classes.title}>
        {title}
      </Typography>

      <Grid item>
        <Typography variant="body1" className={classes.value}>
          {value}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default StripValue;
