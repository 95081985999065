import * as d3 from 'd3';
import React, {
  memo,
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import { getCenterBetweenDatesUTC } from 'common/utils/helpers/date';
import { rulerGen, xTransform } from 'common/components/D3Components/utils';
import { containerChildProps } from 'common/constants/sharedPropTypes';
import {
  getBeatsCount,
  getRulerSelection,
  getIsBeatsSelection,
  getFirstSelectionBeat,
  getLastSelectionBeatByLeft,
} from './utils';

const DRAG_POINT_WIDTH = 15;
const DRAG_POINT_OFFSET = DRAG_POINT_WIDTH / 2;
const PADDING_TOP = 75;
const posPadding = 15;

const textYPos = PADDING_TOP - posPadding - 5;
const textMsYPos = PADDING_TOP - posPadding + 15;
const sideYPos = PADDING_TOP - posPadding;
const milliMinute = 60000;

const updateTextPosition = (g, x, pos) => g.attr('x', x(pos) - 30);

const getBpmWithDuration = (beats, left, right) => {
  const rangeMilliseconds = Math.abs(moment(right).diff(left));

  const isBeatsSelection = getIsBeatsSelection(beats, left, right);
  const beatsCount = getBeatsCount(beats, left, right);

  const bpm = isBeatsSelection
    ? Math.round(milliMinute / (rangeMilliseconds / beatsCount))
    : Math.round(milliMinute / rangeMilliseconds);

  return { bpm, duration: rangeMilliseconds };
};

const MeasureRuler = ({ parent, beats, position }) => {
  const { height: parentHeight, center, scale } = parent;

  const leftBeat = useRef(
    position?.[0] || getFirstSelectionBeat(beats, center)
  );
  const rightBeat = useRef(
    position?.[1] || getLastSelectionBeatByLeft(beats, leftBeat.current)
  );

  const rulerCenter = useRef(
    getCenterBetweenDatesUTC(leftBeat.current, rightBeat.current)
  );

  const [averageBPM, setAverageBPM] = useState(
    () => getBpmWithDuration(beats, leftBeat.current, rightBeat.current).bpm
  );
  const [selectedMs, setSelectedMs] = useState(
    () =>
      getBpmWithDuration(beats, leftBeat.current, rightBeat.current).duration
  );

  const [measureRef, setMeasureRef] = useState(null);
  const [leftDragPointRef, setLeftDragPointRef] = useState(null);
  const [rightDragPointRef, setRightDragPointRef] = useState(null);
  const [averageBpmTextRef, setAverageBpmTextRef] = useState(null);
  const [selectedMsTextRef, setSelectedMsTextRef] = useState(null);

  const rulerHeight = useMemo(() => parentHeight * 0.1, [parentHeight]);
  const ruler = useCallback(
    (...args) => rulerGen(rulerHeight, PADDING_TOP - posPadding)(...args),
    [rulerHeight]
  );

  const averageBpmText = useMemo(
    () => d3.select(averageBpmTextRef),
    [averageBpmTextRef]
  );

  const selectedMsText = useMemo(
    () => d3.select(selectedMsTextRef),
    [selectedMsTextRef]
  );

  const measureRuler = useMemo(() => {
    return d3
      .select(measureRef)
      .call(ruler, scale(leftBeat.current), scale(rightBeat.current));
  }, [scale, measureRef, ruler]);

  const leftDragPoint = useMemo(
    () => d3.select(leftDragPointRef),
    [leftDragPointRef]
  );

  const rightDragPoint = useMemo(
    () => d3.select(rightDragPointRef),
    [rightDragPointRef]
  );

  const onRangeUpdated = useCallback(
    (left, right) => {
      const { duration, bpm } = getBpmWithDuration(beats, left, right);
      setAverageBPM(bpm);
      setSelectedMs(duration);
    },
    [beats]
  );

  const onDragLeftPoint = useCallback(
    (event) => {
      if (!scale) {
        return;
      }
      const xz = scale;
      const { x: currentX } = event;
      const updatedXLeftPos = xz.invert(currentX);

      measureRuler.call(ruler, xz(updatedXLeftPos), xz(rightBeat.current));
      leftDragPoint.call(xTransform, xz(updatedXLeftPos) - DRAG_POINT_OFFSET);

      const rulerWidth = xz(rightBeat.current) - xz(updatedXLeftPos);
      const updatedBpmX = xz(updatedXLeftPos) + rulerWidth / 2;

      averageBpmText.call(updateTextPosition, xz, xz.invert(updatedBpmX));
      selectedMsText.call(updateTextPosition, xz, xz.invert(updatedBpmX));
      onRangeUpdated(updatedXLeftPos, rightBeat.current);
    },
    [
      ruler,
      scale,
      rightBeat,
      measureRuler,
      leftDragPoint,
      averageBpmText,
      selectedMsText,
      onRangeUpdated,
    ]
  );

  const onDragRightPoint = useCallback(
    (event) => {
      if (!scale) {
        return;
      }

      const xz = scale;
      const { x: eventX } = event;
      const updatedXRightPos = xz.invert(eventX);

      measureRuler.call(ruler, xz(leftBeat.current), xz(updatedXRightPos));
      rightDragPoint.call(xTransform, xz(updatedXRightPos) - DRAG_POINT_OFFSET);

      const rulerWidth = xz(updatedXRightPos) - xz(leftBeat.current);
      const updatedBpmX = xz(leftBeat.current) + rulerWidth / 2;
      averageBpmText.call(updateTextPosition, xz, xz.invert(updatedBpmX));
      selectedMsText.call(updateTextPosition, xz, xz.invert(updatedBpmX));
      onRangeUpdated(leftBeat.current, updatedXRightPos);
    },
    [
      scale,
      measureRuler,
      ruler,
      rightDragPoint,
      averageBpmText,
      onRangeUpdated,
      selectedMsText,
    ]
  );

  const onDragLeftPointEnd = useCallback(
    (event) => {
      if (!scale) {
        return;
      }

      const xz = scale;
      const { x: eventX } = event;
      const releasePoint = xz.invert(eventX);
      const updatedLeftBeat = getRulerSelection(beats, releasePoint);

      const updatedRulerCenter = getCenterBetweenDatesUTC(
        updatedLeftBeat,
        rightBeat.current
      );

      leftBeat.current = updatedLeftBeat;
      rulerCenter.current = updatedRulerCenter;

      onRangeUpdated(updatedLeftBeat, rightBeat.current);

      averageBpmText.call(updateTextPosition, xz, updatedRulerCenter);
      selectedMsText.call(updateTextPosition, xz, updatedRulerCenter);
      measureRuler.call(ruler, xz(updatedLeftBeat), xz(rightBeat.current));
      leftDragPoint.call(xTransform, xz(updatedLeftBeat) - DRAG_POINT_OFFSET);
    },
    [
      scale,
      beats,
      onRangeUpdated,
      averageBpmText,
      selectedMsText,
      measureRuler,
      ruler,
      leftDragPoint,
    ]
  );

  const onDragRightPointEnd = useCallback(
    (event) => {
      if (!scale) {
        return;
      }

      const xz = scale;
      const { x: eventX } = event;
      const releasePoint = xz.invert(eventX);

      const updatedRightBeat = getRulerSelection(beats, releasePoint);
      const updatedRulerCenter = getCenterBetweenDatesUTC(
        leftBeat.current,
        updatedRightBeat
      );

      rightBeat.current = updatedRightBeat;
      rulerCenter.current = updatedRulerCenter;

      onRangeUpdated(leftBeat.current, updatedRightBeat);

      averageBpmText.call(updateTextPosition, xz, updatedRulerCenter);
      selectedMsText.call(updateTextPosition, xz, updatedRulerCenter);
      measureRuler.call(ruler, xz(leftBeat.current), xz(updatedRightBeat));
      rightDragPoint.call(xTransform, xz(updatedRightBeat) - DRAG_POINT_OFFSET);
    },
    [
      scale,
      beats,
      ruler,
      measureRuler,
      onRangeUpdated,
      averageBpmText,
      rightDragPoint,
      selectedMsText,
    ]
  );

  useEffect(() => {
    if (!leftDragPointRef) {
      return;
    }

    d3.select(leftDragPointRef).call(
      d3.drag().on('drag', onDragLeftPoint).on('end', onDragLeftPointEnd)
    );
  }, [leftDragPointRef, onDragLeftPoint, onDragLeftPointEnd]);

  useEffect(() => {
    if (!rightDragPointRef) {
      return;
    }

    d3.select(rightDragPointRef).call(
      d3.drag().on('drag', onDragRightPoint).on('end', onDragRightPointEnd)
    );
  }, [rightDragPointRef, onDragRightPoint, onDragRightPointEnd]);

  const update = useCallback(() => {
    if (!scale) {
      return;
    }

    averageBpmText.call(updateTextPosition, scale, rulerCenter.current);
    selectedMsText.call(updateTextPosition, scale, rulerCenter.current);
    measureRuler.call(ruler, scale(leftBeat.current), scale(rightBeat.current));
    leftDragPoint.call(xTransform, scale(leftBeat.current) - DRAG_POINT_OFFSET);
    rightDragPoint.call(
      xTransform,
      scale(rightBeat.current) - DRAG_POINT_OFFSET
    );
  }, [
    selectedMsText,
    rightDragPoint,
    averageBpmText,
    leftDragPoint,
    measureRuler,
    ruler,
    scale,
  ]);

  useEffect(() => {
    update();
  }, [update]);

  return (
    <>
      <text
        ref={setAverageBpmTextRef}
        fill="#e69500"
        stroke="#e69500"
        fontSize={16}
        strokeWidth={0.8}
        x={scale(rulerCenter.current)}
        y={textYPos}
      >
        {averageBPM} BPM
      </text>

      <text
        ref={setSelectedMsTextRef}
        fill="#e69500"
        stroke="#e69500"
        fontSize={14}
        strokeWidth={0.8}
        x={scale(rulerCenter.current)}
        y={textMsYPos}
      >
        {selectedMs} ms
      </text>

      <polyline
        ref={setMeasureRef}
        strokeWidth={3}
        stroke="#e69500"
        fill="transparent"
      />

      <rect
        ref={setLeftDragPointRef}
        stroke="none"
        cursor="ew-resize"
        fill="transparent"
        y={sideYPos}
        height={rulerHeight}
        width={DRAG_POINT_WIDTH}
        transform={`translate(${
          scale(leftBeat.current) - DRAG_POINT_OFFSET
        }, 0)`}
      />

      <rect
        ref={setRightDragPointRef}
        stroke="none"
        cursor="ew-resize"
        fill="transparent"
        y={sideYPos}
        height={rulerHeight}
        width={DRAG_POINT_WIDTH}
        transform={`translate(${
          scale(rightBeat.current) - DRAG_POINT_OFFSET
        }, 0)`}
      />
    </>
  );
};

MeasureRuler.propTypes = {
  parent: containerChildProps,
  beats: PropTypes.arrayOf(
    PropTypes.shape({
      time: PropTypes.string,
      value: PropTypes.number,
    })
  ).isRequired,
  position: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)])
  ),
};

export default memo(MeasureRuler);
