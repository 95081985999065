import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';

const DoubleArrow = (props) => {
  const { rotate = 0 } = props;

  return (
    <SvgIcon {...props} transform={`rotate(${rotate})`}>
      <path
        transform="translate(-4,0)"
        d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"
      />
      <path
        transform="translate(4,0)"
        d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"
      />
    </SvgIcon>
  );
};

DoubleArrow.propTypes = {
  rotate: PropTypes.number,
};

export default DoubleArrow;
