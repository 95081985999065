import { requestSaga } from 'common/utils/generators';
import resources from 'common/constants/resources';
import dataProvider from 'store/dataProvider';
import { actionTypes } from '../reducer';

export function* getReportNotes() {
  yield requestSaga({
    request: dataProvider.get,
    actionType: actionTypes.getReportNotes,
    resource: resources.report.reportNotes,
  });
}

export function* saveReportNotes() {
  yield requestSaga({
    request: dataProvider.updateOnly,
    actionType: actionTypes.saveReportNotes,
    resource: resources.report.reportNotes,
  });
}
