import { createSelector } from 'reselect';

import { getParsedFromDate } from 'common/utils/helpers/date';

const getPatientsState = (store) => store.patients;

export const patientListSelector = createSelector(
  getPatientsState,
  ({ data }) => data
);

export const isLoading = createSelector(
  getPatientsState,
  (state) => state.loading
);

export const totalPatientsSelector = createSelector(
  getPatientsState,
  (state) => state.total || 0
);

export const getPatient = (id) =>
  createSelector(getPatientsState, (state) => {
    const patient = state.data.find((ph) => ph.id === id);

    if (!patient) {
      return null;
    }

    if (patient.lastProcedureDate) {
      patient.lastProcedureDate = getParsedFromDate(patient.lastProcedureDate);
    }

    return patient;
  });
