import React, { memo, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import makeStyles from '@material-ui/styles/makeStyles';
import Delete from '@material-ui/icons/Delete';
import PropTypes from 'prop-types';

import Table from 'common/components/Table';
import { reportStatuses } from 'common/constants/enums';
import { openPdfPreview } from 'common/ducks/general/actions/modalActions';
import { getReportCalendar } from 'common/components/MonitoringCalendar/ducks';
import { getTotal, getLoading, getReportTableData } from '../ducks/selectors';
import { deleteReports, updateReportsFileId } from '../ducks/reducer';
import { useNotify } from '../../Notification/hooks';

const useStyles = makeStyles(({ palette }) => ({
  unreviewedReportRow: {
    backgroundColor: palette.background.mainDarkLight,
    '&:hover': {
      backgroundColor: `${palette.background.mainDarkLight}80 !important`,
    },
  },
}));

const rowsPerPageDefaultOptions = [5, 10, 15];

const ReportTable = ({
  procedureId = null,
  procedure = null,
  fetchReports = () => null,
  tableActions = null,
  physicianView = false,
  toolbarActions = {},
  columns = null,
  rowsPerPageOptions = rowsPerPageDefaultOptions,
  ...props
}) => {
  const notify = useNotify();
  const styles = useStyles();
  const dispatch = useDispatch();

  const total = useSelector(getTotal);
  const loading = useSelector(getLoading);
  const data = useSelector(getReportTableData(procedureId, procedure));

  const onEditReportSuccess = (prevFile, newFile) => {
    dispatch(updateReportsFileId({ prevFile, newFile }));
  };

  const onRowClick = ({ original }) => {
    dispatch(
      openPdfPreview({
        ...original,
        physicianView,
        fileId: original.fileId,
        reportName: original.name,
        reportType: original.reportType || original.type,
        onEditFileSuccess: onEditReportSuccess,
      })
    );
  };

  const handleDeleteReports = useCallback(
    (params) => {
      const reports = params.state.selection.map(({ id }) => id);

      const onSuccess = () => {
        notify.success('The selected reports has been deleted');
        fetchReports();
        dispatch(
          getReportCalendar({
            procedureId,
            reportStatus: reportStatuses.generated,
          })
        );
      };

      dispatch(deleteReports(reports, onSuccess));
    },
    [dispatch, fetchReports, notify, procedureId]
  );

  const bulkActions = [
    {
      text: 'DELETE',
      icon: Delete,
      color: 'error',
      variant: 'text',
      onClick: handleDeleteReports,
    },
  ];

  const getRowClassName = useCallback(
    (row) => (!row.original.reviewed ? styles.unreviewedReportRow : ''),
    [styles.unreviewedReportRow]
  );

  const physicianViewRowProps = useMemo(
    () => (physicianView ? { getRowClassName } : {}),
    [getRowClassName, physicianView]
  );

  return (
    <Table
      {...props}
      {...physicianViewRowProps}
      data={data}
      loading={loading}
      columns={columns}
      onRowClick={onRowClick}
      total={total || data.length}
      rowsPerPageOptions={rowsPerPageOptions}
      toolbarProps={{
        tableActions,
        bulkActions,
        ...toolbarActions,
      }}
    />
  );
};

ReportTable.propTypes = {
  updateParams: PropTypes.func,
  procedure: PropTypes.shape(),
  fetchReports: PropTypes.func,
  physicianView: PropTypes.bool,
  procedureId: PropTypes.string,
  toolbarActions: PropTypes.shape(),
  selectable: PropTypes.bool,
  columns: PropTypes.arrayOf(PropTypes.shape()),
  tableActions: PropTypes.arrayOf(PropTypes.shape()),
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
};

const isEqualProps = (prev, next) =>
  prev.page === next.page &&
  prev.pageSize === next.pageSize &&
  prev.tableActions === next.tableActions &&
  prev.toolbarActions === next.toolbarActions;

export default memo(ReportTable, isEqualProps);
