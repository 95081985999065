import React, { memo, useMemo, useCallback } from 'react';
import * as d3 from 'd3';
import PropTypes from 'prop-types';

import { axesGridChildrenPropTypes } from 'common/constants/sharedPropTypes';
import { lineGen as lineGenerator } from '../../utils';
import Popper from './Popper';

const EventPoint = ({
  id = '',
  time = '',
  title = '',
  value = '',
  parent = {},
  allData = [],
  color = '#000',
  tooltip = false,
  measureUnit = '',
  strokeWidth = 2,
  circleRadius = 7,
  lineHeight = 15,
  nonEcgPosition = 205, // 200 - 210 reserved space for non ecg event points
  patientEvent = false,
  commonTriage = false,
  nonEcgEvent = false,
  clickable = false,
}) => {
  const {
    scale,
    clip = {},
    options = {},
    xScale = () => null,
    yScale = () => null,
  } = parent;
  const sx = scale || xScale;
  const { xPropKey, yPropKey } = options;

  const bisect = d3.bisector((d) => d[xPropKey]).left;

  const point = useMemo(() => {
    const index = bisect(allData, time, 1);
    return allData[index];
  }, [allData, bisect, time]);

  const popperContent = useMemo(
    () => value || `${point?.[yPropKey]} ${measureUnit}`,
    [point, yPropKey, value, measureUnit]
  );

  const pointEnd = useMemo(() => {
    const lineY2 = nonEcgEvent
      ? nonEcgPosition
      : (point?.[yPropKey] || 0) +
        (commonTriage ? -1 * lineHeight : lineHeight);

    return { ...point, [yPropKey]: lineY2 };
  }, [point, yPropKey, lineHeight, commonTriage, nonEcgEvent, nonEcgPosition]);

  const lineData = useMemo(
    () => [point, pointEnd].filter(Boolean),
    [point, pointEnd]
  );

  const circleData = useMemo(
    () => [point?.[xPropKey], pointEnd?.[yPropKey]],
    [point, pointEnd, xPropKey, yPropKey]
  );

  const line = useCallback(
    (...args) => lineGenerator({ x: xPropKey, y: yPropKey }, yScale)(...args),
    [yScale, xPropKey, yPropKey]
  );

  const svgCircle = (
    <circle
      id={id}
      stroke={color}
      clipPath={clip}
      r={circleRadius}
      cy={yScale(circleData[1])}
      cx={sx(new Date(circleData[0]))}
      fill={patientEvent ? 'white' : color}
      cursor={clickable ? 'pointer' : 'default'}
      strokeWidth={patientEvent ? strokeWidth : 0}
    />
  );

  const renderCircle = tooltip ? (
    <Popper title={title} content={popperContent}>
      {svgCircle}
    </Popper>
  ) : (
    svgCircle
  );

  return (
    <g>
      {!nonEcgEvent && (
        <path
          clipPath={clip}
          strokeWidth={strokeWidth}
          stroke={nonEcgEvent ? color : '#000'}
          d={line(lineData, scale || xScale)}
          className="eventCircle"
        />
      )}
      {renderCircle}
    </g>
  );
};

EventPoint.propTypes = {
  title: PropTypes.string,
  tooltip: PropTypes.bool,
  ...axesGridChildrenPropTypes,
};

const EventStartPoint = memo(EventPoint);

export default EventStartPoint;
