import fetchPatients from './fetchPatients';
import fetchPatient from './fetchPatient';
import createPatient from './createPatient';
import updatePatient from './updatePatient';
import updatePatientStatus from './updateStatus';

export default [
  fetchPatients,
  fetchPatient,
  createPatient,
  updatePatient,
  updatePatientStatus,
];
