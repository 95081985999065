import { createRequestActionTypes } from 'common/utils/actionTypesHelper';

export const modalsActionTypes = createRequestActionTypes(
  ['open', 'close', 'closeAll'],
  'modal'
);

export const monitoringActionTypes = createRequestActionTypes(
  ['setGraphSelection'],
  'monitoring'
);

export const reportActionTypes = createRequestActionTypes(
  [
    'reEmail',
    'reFax',
    'review',
    'reviewByFileId',
    'submitAccepted',
    'submitEditsOptions',
  ],
  'pdfReport'
);

export const commonActionTypes = createRequestActionTypes(
  [
    'lockPatients',
    'unlockPatients',
    'getProcedure',
    'getShortProcedure',
    'removeEcgEvents',
    'updateEcgEvents',
    'bulkStatusUpdate',
    'updatePatientEvents',
    'bulkReviewEcgEvents',
    'changeVisibilityPatientEvent',
    'setInvertEcg',
    'setInvertPEEcg',
  ],
  'commonActions'
);

export const profileActionTypes = createRequestActionTypes(
  ['get', 'update', 'updatePassword', 'saveData'],
  'profile'
);
