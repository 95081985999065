import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Menu from '@material-ui/core/Menu';
import { push } from 'connected-react-router';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';

import paths from 'common/constants/path';
import Avatar from 'common/components/Widgets/Avatar';
import { formatPath } from 'common/utils/helpers/url';
import { profileActionTypes } from 'common/ducks/actionTypes';
import useAuthProvider from 'store/authProvider/useAuthProvider';

const getProfile = (store) => store.profile.data;

const fetchProfileAction = (payload) => ({
  type: profileActionTypes.get.requested,
  payload,
});

const UserButton = ({ classes = {} }) => {
  const dispatch = useDispatch();
  const authProvider = useAuthProvider();
  const profile = useSelector(getProfile);
  const [anchorEl, setAnchorEl] = useState(null);

  const userId = useMemo(
    () => authProvider.currentUserId,
    [authProvider.currentUserId]
  );

  const pathToProfile = formatPath(paths.private.userProfile, { id: userId });

  const handleMenuClick = ({ currentTarget }) => setAnchorEl(currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  const handleProfile = () => {
    handleMenuClose();
    dispatch(push(pathToProfile));
  };

  const handleLogout = () => {
    authProvider.logout();
  };

  useEffect(() => {
    if (!profile?.id && userId) {
      dispatch(fetchProfileAction({ id: userId }));
    }
  }, [dispatch, profile, userId]);

  return (
    <>
      <IconButton data-testid="user-button" onClick={handleMenuClick}>
        <div className={classes.profileIconWrapper}>
          <Avatar fileId={profile?.avatarFileId} />
        </div>
      </IconButton>

      <Menu
        keepMounted
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleProfile}>
          {profile?.avatarFileId ? (
            <Avatar
              fileId={profile?.avatarFileId}
              className={classes.avatarIco}
            />
          ) : (
            <AccountCircleOutlinedIcon fontSize="small" />
          )}
          <div className={classes.menuItem}>My profile</div>
        </MenuItem>

        <MenuItem onClick={handleLogout}>
          <ExitToAppIcon fontSize="small" />
          <div className={classes.menuItem}>Logout</div>
        </MenuItem>
      </Menu>
    </>
  );
};

export default UserButton;
