import {
  updateEventsOnStripSaga,
  setCustomViewOnStripSaga,
  updateEventsOnMonitoringSaga,
  setCustomViewOnMonitoringSaga,
} from './updateEvents';
import { getHeartRateSummary } from './getHRSummary';
import updatePatientEvents from './updatePatientEvents';
import { getPatientEventById } from './getEventsById';
import { getProcedureStripSaga } from './getEventStripInfo';

export default [
  updatePatientEvents,
  getPatientEventById,
  getHeartRateSummary,
  getProcedureStripSaga,
  updateEventsOnStripSaga,
  updateEventsOnMonitoringSaga,
  setCustomViewOnStripSaga,
  setCustomViewOnMonitoringSaga,
];
