import { requestSaga } from 'common/utils/generators';
import resources from 'common/constants/resources';
import dataProvider from 'store/dataProvider';

import { kitsActionTypes } from '../reducer';

function* createKitSaga() {
  yield requestSaga({
    actionType: kitsActionTypes.create,
    request: dataProvider.createOne,
    resource: resources.kit.index,
  });
}

export default createKitSaga;
