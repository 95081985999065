import { useState, useCallback, useMemo } from 'react';
import lodashGet from 'lodash/get';

// Key is used for state selectedData map, to define keys from element object for map
const useSelect = (key, nestedPath = null, single = false) => {
  const [selectedData, setSelectedData] = useState({});

  const total = useMemo(() => {
    if (!nestedPath) {
      return Object.values(selectedData);
    }

    return Object.values(selectedData)
      .map((selectedDataElement) => {
        const nestedData = lodashGet(selectedDataElement, nestedPath);

        if (nestedData && Array.isArray(nestedData)) {
          return nestedData;
        }

        return selectedDataElement;
      })
      .flat();
  }, [selectedData, nestedPath]);

  const isSelected = useCallback(
    (ids) => {
      if (Array.isArray(ids)) {
        return ids.length === 0 ? false : ids.every((id) => !!selectedData[id]);
      }

      return !!selectedData[ids];
    },
    [selectedData]
  );

  const onSelect = useCallback(
    (element) => {
      if (single && element.id) {
        const map = {};
        map[element.id] = { ...element };
        setSelectedData(map);
        return;
      }

      // Selected elements could be array or single value
      setSelectedData((prevData) => {
        const map = { ...prevData };
        if (Array.isArray(element)) {
          element.forEach((selectedElement) => {
            if (selectedElement[key]) {
              map[selectedElement[key]] = { ...selectedElement };
            }
          });
        }

        if (element.id) {
          map[element.id] = { ...element };
        }

        return map;
      });
    },
    [single, key]
  );

  const onDeselect = useCallback((ids) => {
    // Deselected ids could be array or single value
    setSelectedData((prevSelection) => {
      if (!ids) {
        return {};
      }

      const map = { ...prevSelection };
      if (Array.isArray(ids)) {
        ids.forEach((id) => {
          delete map[id];
        });
      } else {
        delete map[ids];
      }

      return map;
    });
  }, []);

  const deselectAll = () => setSelectedData({});

  return { total, isSelected, onDeselect, onSelect, selectedData, deselectAll };
};

export default useSelect;
