import { createSelector } from 'reselect';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import moment from 'moment';
import * as d3 from 'd3';

import {
  getDurationUTC,
  getRecordsFromRange,
  getRecordsOverlappedByRange,
  getFirstEqualOrAfterTimeIndex,
} from 'common/utils/helpers/date';
import {
  getActivityAndPositionAverage,
  getBeatLabel,
} from 'common/utils/helpers/graphs';
import { beatTypes, abnormalityTypeEnum } from 'common/constants/ecgEnums';

const dailyHRAbnormalities = [
  abnormalityTypeEnum.lowestHR,
  abnormalityTypeEnum.highestHR,
];

const getEventStripState = (resource) => (store) => store[resource];

export const getLoading = (resource) =>
  createSelector(getEventStripState(resource), (state) => state.loading);

export const getVisibleRange = (resource) =>
  createSelector(getEventStripState(resource), (state) => state.visibleRange);

const getEventStripData = (resource) =>
  createSelector(getEventStripState(resource), (state) => state.data);

export const getEcg = (resource, inRange = false, offset = 0) =>
  createSelector(
    getEventStripData(resource),
    getVisibleRange(resource),
    (data, visibleRange) => {
      if (!inRange || !visibleRange) {
        return data.ecg;
      }

      const [start, end] = visibleRange;

      const from = getFirstEqualOrAfterTimeIndex(
        data.ecg,
        moment(start).subtract(offset, 'seconds').toISOString()
      );

      const to =
        getFirstEqualOrAfterTimeIndex(
          data.ecg,
          moment(end).add(offset, 'seconds').toISOString()
        ) || data.ecg.length - 1;

      return data.ecg.slice(from, to);
    }
  );

export const getBeats = (resource) =>
  createSelector(getEventStripData(resource), (data) => data.beats);

export const getPatientEvents = (resource, inRange = false) =>
  createSelector(
    getEventStripData(resource),
    getVisibleRange(resource),
    (data, visibleRange) => {
      if (!inRange || !visibleRange) {
        return data.patientEvents;
      }

      const [start, end] = visibleRange;

      return getRecordsFromRange(data.patientEvents, [start, end], 'date');
    }
  );

export const getAllEventGroups = (resource) =>
  createSelector(getEventStripData(resource), (data) => data.eventGroups);

const defaultOpts = { inRange: false, withoutHRMin: false };

export const getEventGroups = (resource, options = defaultOpts) =>
  createSelector(
    getAllEventGroups(resource),
    getVisibleRange(resource),
    (eventGroups, visibleRange) => {
      const { inRange, withoutHRMin } = options;

      const events = withoutHRMin
        ? eventGroups.filter(
            ({ abnormalityType }) =>
              !dailyHRAbnormalities.includes(abnormalityType)
          )
        : eventGroups;

      if (!inRange || !visibleRange) {
        return events;
      }

      const [start, end] = visibleRange;

      return getRecordsOverlappedByRange(events, [start, end]);
    }
  );

export const getOpenedEcgEvent = (id, resource) =>
  createSelector(getAllEventGroups(resource), (eventGroups) =>
    eventGroups.find((e) => e.id === id)
  );

const dY = { min: 1800, max: 2750 };

export const getYMinMaxValues = (resource) =>
  createSelector(getEcg(resource, true), (ecg) => {
    if (!ecg?.length) {
      return dY;
    }

    const min = d3.min(ecg, (d) => d.value);
    const max = d3.max(ecg, (d) => d.value);

    return {
      min: Math.floor(min / 100) * 100,
      max: Math.ceil(max / 100) * 100,
    };
  });

export const getDailyHr = (resource, abnormality) =>
  createSelector(getAllEventGroups(resource), (eventGroups) =>
    eventGroups.find(({ abnormalityType }) => abnormalityType === abnormality)
  );

export const getBeatsOnVisibleRange = (resource, filtered = false) =>
  createSelector(
    getBeats(resource),
    getVisibleRange(resource),
    (allBeats, visibleRange) => {
      if (!visibleRange?.length) {
        return allBeats;
      }

      const beats = filtered
        ? allBeats.filter((value, index) => {
            if (!index) {
              return true;
            }

            const prevBeat = allBeats[index - 1];
            const diffDuration = getDurationUTC(prevBeat.time, value.time);

            return value.duration >= diffDuration;
          })
        : allBeats;

      const startVisible = moment(visibleRange[0]).subtract(2, 'seconds');
      const endVisible = moment(visibleRange[1]).add(2, 'seconds');

      const from = getFirstEqualOrAfterTimeIndex(
        beats,
        startVisible.toISOString()
      );

      const to =
        getFirstEqualOrAfterTimeIndex(beats, endVisible.toISOString()) ||
        beats.length - 1;

      return beats.slice(from, to);
    }
  );

export const getVisibleBeatsDuration = (resource) =>
  createSelector(getBeatsOnVisibleRange(resource, true), (visibleBeats) =>
    visibleBeats.map(({ time, duration }) => ({
      time: moment(time)
        .subtract(duration / 2, 'milliseconds')
        .toISOString(),
      duration,
    }))
  );

const beatNamesAccordingToAbnormalities = {
  [beatTypes.S]: [
    abnormalityTypeEnum.sve,
    abnormalityTypeEnum.svRun,
    abnormalityTypeEnum.svTach,
    abnormalityTypeEnum.svPair,
  ],
  [beatTypes.V]: [
    abnormalityTypeEnum.ve,
    abnormalityTypeEnum.vRun,
    abnormalityTypeEnum.vTach,
    abnormalityTypeEnum.vPair,
  ],
};

const labeledAbnormalities = Object.values(
  beatNamesAccordingToAbnormalities
).flat();

export const getVisibleRPoints = (resource) =>
  createSelector(
    getBeatsOnVisibleRange(resource),
    getEventGroups(resource, { withoutHRMin: true, inRange: true }),
    (visibleBeats, eventGroups) => {
      const veSveEventGroups = eventGroups.filter(({ abnormalityType }) =>
        labeledAbnormalities.includes(abnormalityType)
      );

      return visibleBeats.map(({ time, beatType }) => {
        const eventInRange = veSveEventGroups.find(
          ({ dateFrom, dateFromEvent, dateTo, dateToEvent }) => {
            const eventStartTime = dateFromEvent || dateFrom;
            const eventEndTime = dateToEvent || dateTo;

            return moment(time).isBetween(
              eventStartTime,
              eventEndTime,
              'milliseconds',
              '[)'
            );
          }
        );

        const beatName = Object.keys(beatNamesAccordingToAbnormalities).find(
          (type) =>
            beatNamesAccordingToAbnormalities[type].includes(
              eventInRange?.abnormalityType
            )
        );

        return {
          time,
          value: getBeatLabel(beatName === beatType ? beatType : beatName),
        };
      });
    }
  );

export const getAllEvents = (resource) =>
  createSelector(
    getEventGroups(resource),
    getPatientEvents(resource),
    (events, pes) => {
      const allPEs = pes.map((e) => ({
        ...e,
        abnormalityType: abnormalityTypeEnum.patient,
      }));
      const allData = [...events, ...allPEs];

      return sortBy(allData, ({ date, dateFrom, dateFromEvent }) =>
        new Date(date || dateFromEvent || dateFrom).getTime()
      );
    }
  );

const notAvailable = 'N/A';

const getSensorDataIndex = (range, point) => {
  const pointDate = moment(point);

  return range.findIndex((r) => pointDate.isSameOrBefore(r.date));
};

const getDataFromRange = (data, visibleRange) => {
  const [s, e] = visibleRange;

  const startPoint = new Date(s);
  const endPoint = new Date(e);

  const isOverflow =
    endPoint.getTime() > new Date(data[data.length - 1].date).getTime();
  const startIndex = Math.max(getSensorDataIndex(data, startPoint), 1);
  const endIndex = isOverflow
    ? data.length - 1
    : Math.max(getSensorDataIndex(data, endPoint), 1);

  return data.slice(startIndex, endIndex);
};

export const getTemperatureAverage = (resource) =>
  createSelector(
    getEventStripData(resource),
    getVisibleRange(resource),
    (data, visibleRange) => {
      const allTemps = data.temperatures;
      if (!visibleRange || !allTemps?.length) {
        return notAvailable;
      }

      const tempsFromRange = getDataFromRange(allTemps, visibleRange);

      const sum = tempsFromRange.reduce((acc, item) => acc + item.value, 0);
      const avg = sum ? (sum / tempsFromRange.length).toFixed(1) : 0;

      return `${avg} F`;
    }
  );

export const getActivityAverage = (resource) =>
  createSelector(
    getEventStripData(resource),
    getVisibleRange(resource),
    (data, visibleRange) => {
      const allActivities = data.activities;

      if (!visibleRange || !allActivities?.length) {
        return notAvailable;
      }

      const activitiesFromRange = getDataFromRange(allActivities, visibleRange);
      const groupedData = groupBy(activitiesFromRange, 'value');

      return getActivityAndPositionAverage(groupedData);
    }
  );

export const getBodyPositionAverage = (resource) =>
  createSelector(
    getEventStripData(resource),
    getVisibleRange(resource),
    (data, visibleRange) => {
      const allPositions = data.bodyPosition;

      if (!visibleRange || !allPositions?.length) {
        return notAvailable;
      }

      const positionsFromRange = getDataFromRange(allPositions, visibleRange);
      const groupedData = groupBy(positionsFromRange, 'value');

      return getActivityAndPositionAverage(groupedData);
    }
  );
