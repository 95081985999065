import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  templateTypes,
  abnormalityAccordingTemplates,
} from 'common/constants/ecgEnums';
import GridItem from './components/GridItem';
import LocalPaginatedGrid from './LocalPaginatedGrid';
import {
  getEvents,
  getLoading,
  getEcgByEventId,
} from './isolatedDucks/selectors';
import { actions } from './isolatedDucks';
import useSocketUpdates from './hooks/useSocketUpdates';

const IsolatedLocalPaginatedGrid = ({
  procedureId = null,
  requestPayload = null,
  abnormalityType = '',
  socketParams = {},
  itemProps = {},
  ...props
}) => {
  const dispatch = useDispatch();
  const [selectedParent, setSelectedParent] = useState(null);

  const loading = useSelector(getLoading);
  const events = useSelector(getEvents(abnormalityType, selectedParent));

  const handleGoBack = () => setSelectedParent(null);

  const goBack = selectedParent ? handleGoBack : null;

  const getCurrentTemplates = useCallback(() => {
    if (!requestPayload) {
      return;
    }

    dispatch(actions.getProcedureEvents(requestPayload));
  }, [dispatch, requestPayload]);

  const eventsAbnormalityType = useMemo(() => {
    if (templateTypes.includes(abnormalityType)) {
      return abnormalityAccordingTemplates[abnormalityType];
    }

    return abnormalityType;
  }, [abnormalityType]);

  useEffect(() => {
    if (!events?.similar?.length && goBack) {
      goBack();
    }
  }, [events, goBack]);

  useEffect(() => {
    getCurrentTemplates();
  }, [getCurrentTemplates]);

  useEffect(() => () => dispatch(actions.clearState()), [dispatch]);

  useSocketUpdates(procedureId, socketParams, actions.updateEventsBySocket);

  return (
    <LocalPaginatedGrid
      {...props}
      items={events.similar}
      goBack={goBack}
      loading={loading}
      procedureId={procedureId}
      itemComponent={GridItem}
      abnormalityType={eventsAbnormalityType}
      itemProps={{
        height: 100,
        width: '100%',
        fetchEventEcg: actions.getEventEcg,
        getEventEcg: getEcgByEventId,
        eventGroupType: eventsAbnormalityType,
        setTemplate: setSelectedParent,
        ...itemProps,
      }}
    />
  );
};

export default IsolatedLocalPaginatedGrid;
