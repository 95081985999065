import { createEnum } from 'common/utils/actionTypesHelper';
import { abnormalityTypeEnum } from 'common/constants/ecgEnums';

export const totalsEnum = createEnum({
  hrTotal: null,
  significantTotal: null,
  ectopicBeatsTotal: null,
  oxygenSaturationTotal: null,
  respirationTotal: null,
  temperatureTotal: null,
});

export const totalsTitlesEnum = createEnum({
  [totalsEnum.hrTotal]: 'Heart Rate',
  [totalsEnum.significantTotal]: 'Notable',
  [totalsEnum.ectopicBeatsTotal]: 'Ectopic Beats',
  [totalsEnum.oxygenSaturationTotal]: 'SPO2',
  [totalsEnum.respirationTotal]: 'Respiration',
  [totalsEnum.temperatureTotal]: 'Skin Temp',
});

export const acceptableCorrelationEvents = [
  abnormalityTypeEnum.afib,
  abnormalityTypeEnum.pause,
  abnormalityTypeEnum.tachy,
  abnormalityTypeEnum.brady,
  abnormalityTypeEnum.vTach,
  abnormalityTypeEnum.svTach,
  abnormalityTypeEnum.block,
  abnormalityTypeEnum.vRun,
  abnormalityTypeEnum.svRun,
  abnormalityTypeEnum.vPair,
  abnormalityTypeEnum.veBigeminy,
  abnormalityTypeEnum.veTrigeminy,
  abnormalityTypeEnum.ve,
  abnormalityTypeEnum.svPair,
  abnormalityTypeEnum.sveBigeminy,
  abnormalityTypeEnum.sveTrigeminy,
  abnormalityTypeEnum.sve,
];

export const cardiacTotalsEnum = createEnum({
  hrMinimum: null,
  hrAverage: null,
  hrMaximum: null,
  afibPercent: null,
  vePercent: null,
  svePercent: null,
  pauseCount: null,
  // longestAfibSeconds: null,
  fastestAfibHeartRate: null,
  longestAfibHeartRate: null,
  longestPauseSeconds: null,
  tachycardiaPercent: null,
  tachycardiaMaxHeartRate: null,
  bradycardiaPercent: null,
  bradycardiaMinHeartRate: null,
  vTachCount: null,
  svTachCount: null,
  blockCount: null,
  patientEventCount: null,
  veCount: null,
  veBigeminyCount: null,
  veTrigeminyCount: null,
  vPairCount: null,
  vRunCount: null,
  sveCount: null,
  sveBigeminyCount: null,
  sveTrigeminyCount: null,
  svPairCount: null,
  svRunCount: null,
  minimumFahrenheit: null,
  averageFahrenheit: null,
  maximumFahrenheit: null,
  moreThan96FahrenheitPercent: null,
  moreThan99FahrenheitPercent: null,
  moreThan101FahrenheitPercent: null,
  moreThan102FahrenheitPercent: null,
});

export const cardiacTotalLabels = {
  [cardiacTotalsEnum.hrMinimum]: 'Avg Minimum',
  [cardiacTotalsEnum.hrAverage]: 'Avg Mean',
  [cardiacTotalsEnum.hrMaximum]: 'Avg Maximum',

  [cardiacTotalsEnum.afibPercent]: 'AFIB',
  [cardiacTotalsEnum.pauseCount]: 'Pauses',
  // [cardiacTotalsEnum.longestAfibSeconds]: 'Longest AFIB',
  [cardiacTotalsEnum.fastestAfibHeartRate]: 'Highest AFIB HR',
  [cardiacTotalsEnum.longestPauseSeconds]: 'Longest Pause',
  [cardiacTotalsEnum.tachycardiaPercent]: 'Tachycardia',
  [cardiacTotalsEnum.bradycardiaPercent]: 'Bradycardia',
  [cardiacTotalsEnum.vTachCount]: 'V-Tach',
  [cardiacTotalsEnum.svTachCount]: 'SV-Tach',
  [cardiacTotalsEnum.blockCount]: 'Blocks',
  [cardiacTotalsEnum.patientEventCount]: 'Patient Events',

  [cardiacTotalsEnum.veCount]: "VE's",
  [cardiacTotalsEnum.veBigeminyCount]: 'VE Bigeminy',
  [cardiacTotalsEnum.veTrigeminyCount]: 'VE Trigeminy',
  [cardiacTotalsEnum.vPairCount]: 'VE Pairs',
  [cardiacTotalsEnum.vRunCount]: 'VE Runs',
  [cardiacTotalsEnum.sveCount]: "SVE's",
  [cardiacTotalsEnum.sveBigeminyCount]: 'SVE Bigeminy',
  [cardiacTotalsEnum.sveTrigeminyCount]: 'SVE Trigeminy',
  [cardiacTotalsEnum.svPairCount]: 'SVE Pairs',
  [cardiacTotalsEnum.svRunCount]: 'SVE Runs',

  [cardiacTotalsEnum.minimumFahrenheit]: 'Minimum',
  [cardiacTotalsEnum.averageFahrenheit]: 'Average',
  [cardiacTotalsEnum.maximumFahrenheit]: 'Maximum',
  [cardiacTotalsEnum.moreThan96FahrenheitPercent]: '> 96 f',
  [cardiacTotalsEnum.moreThan99FahrenheitPercent]: '> 99 f',
  [cardiacTotalsEnum.moreThan101FahrenheitPercent]: '> 101 f',
  [cardiacTotalsEnum.moreThan102FahrenheitPercent]: '> 102 f',
};

export const cardiacTotalMeasures = {
  [cardiacTotalsEnum.hrMinimum]: 'bpm',
  [cardiacTotalsEnum.hrAverage]: 'bpm',
  [cardiacTotalsEnum.hrMaximum]: 'bpm',
  [cardiacTotalsEnum.afibPercent]: '%',
  // [cardiacTotalsEnum.longestAfibSeconds]: 's',
  [cardiacTotalsEnum.fastestAfibHeartRate]: 'bpm',
  [cardiacTotalsEnum.longestAfibHeartRate]: 'bpm',
  [cardiacTotalsEnum.longestPauseSeconds]: 's',
  [cardiacTotalsEnum.tachycardiaPercent]: '%',
  [cardiacTotalsEnum.tachycardiaMaxHeartRate]: 'bpm',
  [cardiacTotalsEnum.bradycardiaPercent]: '%',
  [cardiacTotalsEnum.bradycardiaMinHeartRate]: 'bpm',
  [cardiacTotalsEnum.minimumFahrenheit]: 'f',
  [cardiacTotalsEnum.averageFahrenheit]: 'f',
  [cardiacTotalsEnum.maximumFahrenheit]: 'f',
  [cardiacTotalsEnum.moreThan96FahrenheitPercent]: '%',
  [cardiacTotalsEnum.moreThan99FahrenheitPercent]: '%',
  [cardiacTotalsEnum.moreThan101FahrenheitPercent]: '%',
  [cardiacTotalsEnum.moreThan102FahrenheitPercent]: '%',
};

export const temperatureTotals = {
  minimumFahrenheit: null,
  averageFahrenheit: null,
  maximumFahrenheit: null,
  moreThan96FahrenheitPercent: null,
  moreThan99FahrenheitPercent: null,
  moreThan101FahrenheitPercent: null,
  moreThan102FahrenheitPercent: null,
};

export const orderedListOfEvents = [
  abnormalityTypeEnum.pause,
  abnormalityTypeEnum.tachy,
  abnormalityTypeEnum.brady,
  abnormalityTypeEnum.vTach,
  abnormalityTypeEnum.svTach,
  abnormalityTypeEnum.block,
  abnormalityTypeEnum.vRun,
  abnormalityTypeEnum.vPair,
  abnormalityTypeEnum.veBigeminy,
  abnormalityTypeEnum.veTrigeminy,
  abnormalityTypeEnum.ve,
  abnormalityTypeEnum.svRun,
  abnormalityTypeEnum.svPair,
  abnormalityTypeEnum.sveBigeminy,
  abnormalityTypeEnum.sveTrigeminy,
  abnormalityTypeEnum.sve,
  abnormalityTypeEnum.other,
  abnormalityTypeEnum.highestHR,
  abnormalityTypeEnum.lowestHR,
];

export const TEN_SECOND_GRAPH = [
  abnormalityTypeEnum.pause,
  abnormalityTypeEnum.veBigeminy,
  abnormalityTypeEnum.veTrigeminy,
  abnormalityTypeEnum.sveBigeminy,
  abnormalityTypeEnum.sveTrigeminy,
  abnormalityTypeEnum.lowestHR,
  abnormalityTypeEnum.highestHR,
];

export const studyEventsSummary = [
  abnormalityTypeEnum.afib,
  abnormalityTypeEnum.pause,
  abnormalityTypeEnum.tachy,
  abnormalityTypeEnum.brady,
  abnormalityTypeEnum.vTach,
  abnormalityTypeEnum.svTach,
  abnormalityTypeEnum.vRun,
  abnormalityTypeEnum.svRun,
];
