import { requestSaga } from 'common/utils/generators';
import resources from 'common/constants/resources';
import dataProvider from 'store/dataProvider';
import { actionTypes } from './reducer';

function* filterOrganizationsList() {
  yield requestSaga({
    actionType: actionTypes.filter,
    request: dataProvider.getList,
    resource: resources.tenant.list,
  });
}

function* getLoggedInOrganizationSaga() {
  yield requestSaga({
    actionType: actionTypes.getLoggedIn,
    request: dataProvider.getOne,
    resource: resources.tenant.index,
  });
}

function* loginToOrganization() {
  yield requestSaga({
    actionType: actionTypes.login,
    request: dataProvider.createOne,
    resource: resources.tenant.login,
  });
}

export default [
  loginToOrganization,
  filterOrganizationsList,
  getLoggedInOrganizationSaga,
];
