import makeStyles from '@material-ui/styles/makeStyles';
import { getToolbarHeight } from './utils';

const headerHeight = 66;
const headerPaddings = 16;

const useStyles = makeStyles(({ palette, spacing, breakpoints }) => ({
  wrapper: {
    width: '100%',
  },

  tableContainer: {
    position: 'relative',
  },

  table: {
    width: '100%',
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    maxHeight: ({ toolbarProps, extraHeight, selectable }) => {
      const toolbarHeight = getToolbarHeight(toolbarProps, selectable);

      return `calc(100vh - ${
        headerHeight + headerPaddings + toolbarHeight + extraHeight
      }px)`;
    },
    backgroundColor: palette.background.primary,
  },

  tableHeader: {
    maxHeight: 35,
  },
  tableHeaderRow: {
    maxHeight: 35,
  },
  tableHeaderCell: {
    maxHeight: 30,
    display: 'flex',
    padding: spacing(2, 1.5),
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'space-around',
    userSelect: 'none',
    fontSize: '0.7rem',
    [breakpoints.down(1367)]: {
      fontSize: '0.6rem',
    },
  },
  headerCellContent: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    lineHeight: 1.15,
  },

  tableBody: {
    overflow: 'scroll',
    minHeight: ({ isData }) => (isData ? 0 : 100),
  },

  tableRow: {
    width: '100%',
    height: 33,

    '&:hover': {
      backgroundColor: `${palette.background.dark}08`,
    },
  },

  tableCell: {
    maxHeight: 30,
    display: 'flex',
    alignItems: 'center',
    padding: spacing(2),
    justifyContent: 'flex-start',
    cursor: 'default',
    position: 'relative',
    userSelect: 'none',

    '& div': {
      fontSize: '0.75rem',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',

      [breakpoints.down(1367)]: {
        fontSize: '0.65rem',
      },
    },
  },

  tableFooter: {
    maxHeight: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },

  resizer: {
    top: 0,
    right: 0,
    bottom: 0,
    width: 5,
    zIndex: 10,
    height: '100%',
    position: 'absolute',
    color: `${palette.grey['400']}80`,

    '& svg': {
      right: 0,
      position: 'absolute',
      fill: `${palette.grey['400']}80`,
      zIndex: 11,
    },
  },

  sortMark: {
    paddingLeft: spacing(0.5),
    color: `${palette.text.dark}90`,
  },

  pagination: { borderBottom: 'none' },
}));

export default useStyles;
