import { takeLatest, put, call } from 'redux-saga/effects';
import resources from 'common/constants/resources';
import dataProvider from 'store/dataProvider';
import { attachmentsActionTypes } from '../reducer';

function* archive(action) {
  const { ids, onSuccess, onError } = action;
  try {
    yield call(dataProvider.createOne, resources.files.archive, ids);
    yield put({
      type: attachmentsActionTypes.archive.success,
      ids,
    });
    onSuccess();
  } catch (error) {
    onError();
    yield put({ type: attachmentsActionTypes.archive.failed, payload: error });
  }
}

export default function* archiveFile() {
  yield takeLatest(attachmentsActionTypes.archive.requested, archive);
}
