import { requestSaga } from 'common/utils/generators';
import resources from 'common/constants/resources';
import dataProvider from 'store/dataProvider';

import { kitsActionTypes } from '../reducer';

function* updateKitSaga() {
  yield requestSaga({
    actionType: kitsActionTypes.update,
    request: dataProvider.updateOne,
    resource: resources.kit.index,
  });
}

export function* updateKitTenantId() {
  yield requestSaga({
    actionType: kitsActionTypes.updateKitsTenantId,
    request: dataProvider.updateMany,
    resource: resources.kit.bulkTenantUpdate,
  });
}

export default updateKitSaga;
