export const speedMarksSizeDiff = {
  0.2: 0.6,
  0.4: 0.8,
  1: 1,
  2: 1,
};

export const xTickFormats = {
  full: '%a %d',
  oneDay: '%I %p',
};
