import React, { useMemo } from 'react';
import { Route, Redirect } from 'react-router-dom';

import routes from 'common/constants/path';
import useAuthProvider from 'store/authProvider/useAuthProvider';

const PublicRoute = (props) => {
  const authProvider = useAuthProvider();
  const isLoggedIn = useMemo(
    () => authProvider.isLoggedIn,
    [authProvider.isLoggedIn]
  );

  if (!isLoggedIn) {
    return <Route {...props} />;
  }

  return <Redirect to={routes.private.index} />;
};

export default PublicRoute;
