import React, { useMemo } from 'react';
import Tooltip from '@material-ui/core/Tooltip';

import { dailyHRAbnormalities } from 'common/constants/ecgEnums';
import Typography from '@material-ui/core/Typography';

const TooltipForHRTypeEvents = ({
  event,
  actionType = 'Invert it',
  children,
}) => {
  const title = useMemo(
    () => `Please triage the event to ${actionType}!`,
    [actionType]
  );

  const titleNode = useMemo(
    () => <Typography variant="h3">{title}</Typography>,
    [title]
  );

  if (!dailyHRAbnormalities.includes(event?.abnormalityType) || !!event?.id) {
    return children;
  }

  return (
    <Tooltip title={titleNode} placement="top-start">
      <div title={title}>{children}</div>
    </Tooltip>
  );
};

export default TooltipForHRTypeEvents;
