import { createSelector } from 'reselect';

const getContacts = (store) => store.contacts;

export const isLoading = createSelector(getContacts, (state) => state.loading);

export const totalContacts = createSelector(
  getContacts,
  (state) => state.total || 0
);

export const getContactsForTable = createSelector(
  getContacts,
  (state) => state.data
);
