import { requestSaga } from 'common/utils/generators';
import resources from 'common/constants/resources';
import dataProvider from 'store/dataProvider';

export const createUpdateEcgEventSaga = (actionType) =>
  function* updateEventsSaga() {
    yield requestSaga({
      actionType,
      request: dataProvider.createOne,
      resource: resources.eventGroup.review,
    });
  };

export const createPEUpdateSaga = (actionType) =>
  function* updatePatientEvent() {
    yield requestSaga({
      actionType,
      request: dataProvider.updateMany,
      resource: resources.healthData.updatePatientEvents,
    });
  };

export const createChangeVisibilityPESaga = (actionType) =>
  function* updatePatientEvent() {
    yield requestSaga({
      actionType,
      request: dataProvider.createOne,
      resource: resources.healthData.hidePatientEvent,
    });
  };

export const createRemoveEcgEventSaga = (actionType) =>
  function* updatePatientEvent() {
    yield requestSaga({
      actionType,
      request: dataProvider.deleteOne,
      resource: resources.eventGroup.deleteEcgEvents,
    });
  };

export const createBulkStatusUpdateSaga = (actionType) =>
  function* updatePatientEvent() {
    yield requestSaga({
      actionType,
      request: dataProvider.createList,
      resource: resources.eventGroup.bulkStatusUpdate,
    });
  };

export const createSetInvertEcgSaga = (actionType) =>
  function* setInvertEcgSaga() {
    yield requestSaga({
      actionType,
      request: dataProvider.updateMany,
      resource: resources.eventGroup.setInvertEcg,
    });
  };

export const createSetInvertPEEcgSaga = (actionType) =>
  function* setInvertEcgSaga() {
    yield requestSaga({
      actionType,
      request: dataProvider.updateOne,
      resource: resources.healthData.toggleRotateEcg,
    });
  };
