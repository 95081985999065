import {
  getPEMeasures,
  getEventGroups,
  getOngoingReport,
  getPatientEvents,
  getOngoingEventEcg,
  setEventSignificant,
  getEventGroupTotals,
  watchUpdateEcgEvents,
  fetchAdditionalOngoingReportData,
} from './onGoingReport';
import dailyHeartRates from './dailyHeartRates';
import { getIncludedDisclosures } from './includedDisclosures';
import { getReportNotes, saveReportNotes } from './reportNotes';

export default [
  getPEMeasures,
  getEventGroups,
  dailyHeartRates,
  getOngoingReport,
  getPatientEvents,
  getOngoingEventEcg,
  setEventSignificant,
  getEventGroupTotals,
  fetchAdditionalOngoingReportData,

  getReportNotes,
  saveReportNotes,

  getIncludedDisclosures,

  watchUpdateEcgEvents,
];
