import React, { useCallback, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';

import { sliceArrayIntoParts } from 'common/utils/helpers/table';
import { abnormalityTypeEnum } from 'common/constants/ecgEnums';
import TriagesGridRowCell from './TriagesGridRowCell';
import { getElementsIds } from '../utils';
import EventItem from './EventItem';
import useStyles from '../styles';

const defaultContentStyles = {
  height: 'calc(100vh - 212px)',
};

const GridLayout = ({
  total,
  events,
  sideBar,
  loading,
  onSelect,
  itemProps,
  graphProps,
  onDeselect,
  isSelected,
  contentStyles,
  columnsAmount,
  isGlobalTriage,
  disabledSelect,
  handleSelectRow,
  getIsRowChecked,
  containerStyles,
  itemComponent: ItemComponent,
}) => {
  const styles = useStyles();
  const gridBodyStyles = {
    ...defaultContentStyles,
    ...contentStyles,
  };

  const getEvent = useCallback(
    (eventId) => events.find(({ id: evId }) => evId === eventId) || {},
    [events]
  );

  const tableData = useMemo(
    () => sliceArrayIntoParts(events, columnsAmount),
    [events, columnsAmount]
  );

  const handleChange = useCallback(
    (item) => {
      if (item?.abnormalityType === abnormalityTypeEnum.patient) {
        return;
      }

      if (isSelected(item.id)) {
        onDeselect(item.id);
      } else {
        onSelect(item);
      }
    },
    [isSelected, onDeselect, onSelect]
  );

  const getElementsByRow = useCallback(
    (rowIdx) => {
      return events.slice(
        columnsAmount * rowIdx,
        columnsAmount * rowIdx + columnsAmount
      );
    },
    [columnsAmount, events]
  );

  const isRowChecked = useCallback(
    (rowIdx) => {
      if (getIsRowChecked) {
        return getIsRowChecked();
      }

      const elements = getElementsByRow(rowIdx);
      const elementsIds = getElementsIds(elements);
      return isSelected(elementsIds);
    },
    [getIsRowChecked, getElementsByRow, isSelected]
  );

  const handleRowCheck = useCallback(
    (rowIdx) => {
      if (handleSelectRow) {
        handleSelectRow();

        return;
      }

      const elements = getElementsByRow(rowIdx);
      const elementsIds = getElementsIds(elements);
      if (isRowChecked(rowIdx)) {
        onDeselect(elementsIds);
      } else {
        onSelect(elements);
      }
    },
    [handleSelectRow, getElementsByRow, isRowChecked, onDeselect, onSelect]
  );

  const bodyCellStyle = useMemo(
    () => ({
      width: disabledSelect
        ? `calc(100% / ${columnsAmount})`
        : `calc(100% / ${columnsAmount} - 50px / ${columnsAmount})`,
    }),
    [columnsAmount, disabledSelect]
  );

  return (
    <Grid
      container
      wrap="nowrap"
      direction="row"
      component={DialogContent}
      style={{
        ...containerStyles,
        padding: 0,
        overflowY: 'initial',
        height: '100%',
      }}
    >
      {sideBar && <Grid item>{sideBar}</Grid>}
      <Grid xs item style={{ overflowY: 'auto' }}>
        {total === 0 && !loading ? (
          <div
            className={styles.gridBody}
            style={{
              ...gridBodyStyles,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Typography p={3} variant="h3" style={{ textAlign: 'center' }}>
              No data found
            </Typography>
          </div>
        ) : (
          <div style={gridBodyStyles} className={styles.gridBody}>
            {tableData.map((row, idx) => (
              <div
                // eslint-disable-next-line react/no-array-index-key
                key={`row-${idx}`}
                className={styles.tableRow}
                style={!idx ? { paddingTop: 0 } : {}}
              >
                {!disabledSelect && (
                  <Grid
                    item
                    style={{ minWidth: 40 }}
                    className={styles.bodyRowSelect}
                  >
                    {row.length > 0 && (
                      <TriagesGridRowCell
                        idx={idx}
                        isChecked={isRowChecked}
                        onChange={() => handleRowCheck(idx)}
                      />
                    )}
                  </Grid>
                )}

                {row.map((item, index) => {
                  return (
                    <div
                      key={`${item.id + index}`}
                      className={styles.bodyTableCell}
                      style={{
                        ...bodyCellStyle,
                        ...(!index ? { paddingLeft: 0 } : {}),
                      }}
                    >
                      <ItemComponent
                        {...itemProps}
                        noSimilar
                        withoutGreed
                        graphProps={graphProps}
                        selected={isSelected(item.id)}
                        isGlobalTriage={isGlobalTriage}
                        currentEvent={getEvent(item.id)}
                        onClick={() => handleChange(item)}
                      />
                    </div>
                  );
                })}
              </div>
            ))}
          </div>
        )}
      </Grid>
    </Grid>
  );
};

GridLayout.defaultProps = {
  total: null,
  events: [],
  sideBar: null,
  loading: false,
  itemProps: {},
  graphProps: {},
  contentStyles: {},
  containerStyles: {},
  isGlobalTriage: false,
  itemComponent: EventItem,
};

GridLayout.propTypes = {
  total: PropTypes.number,
  loading: PropTypes.bool,
  onSelect: PropTypes.func,
  onDeselect: PropTypes.func,
  isSelected: PropTypes.func,
  itemProps: PropTypes.shape(),
  graphProps: PropTypes.shape(),
  isGlobalTriage: PropTypes.bool,
  disabledSelect: PropTypes.bool,
  columnsAmount: PropTypes.number,
  handleSelectRow: PropTypes.func,
  getIsRowChecked: PropTypes.func,
  contentStyles: PropTypes.shape(),
  containerStyles: PropTypes.shape(),
  events: PropTypes.arrayOf(PropTypes.shape()),
  sideBar: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.object,
    PropTypes.element,
  ]),
  itemComponent: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
    PropTypes.func,
    PropTypes.object,
  ]),
};

export default memo(GridLayout, isEqual);
