import dataProvider from 'store/dataProvider';
import resources from 'common/constants/resources';
import { requestSaga } from 'common/utils/generators';
import { patientsActionTypes } from '../reducer';

export default function* updatePatientStatus() {
  yield requestSaga({
    actionType: patientsActionTypes.updateStatus,
    request: dataProvider.updateMany,
    resource: resources.patient.status,
  });
}
