import moment from 'moment';
import { takeEvery, call, put } from 'redux-saga/effects';

import dataProvider from 'store/dataProvider';
import resources from 'common/constants/resources';
import { abnormalityTypeEnum } from 'common/constants/ecgEnums';
import { actionTypes } from '../reducer';

// todo create common usage saga generators
function* getPatientEvent(payload, signal) {
  const { procedureId, event, eventId, ...restPayload } = payload;
  const {
    data: { date: patientEventDate },
  } = !event?.date
    ? yield call(dataProvider.getOne, resources.healthData.patientEvent, {
        id: eventId,
        ...restPayload,
        signal,
      })
    : { data: {} };

  const date = patientEventDate || event.date;

  const ecgDateTo = moment(date).add(3, 'second').toISOString();
  const ecgDateFrom = moment(date).subtract(3, 'second').toISOString();

  const healthDataRequestParams = {
    filter: {
      procedureId,
      precision: 25,
      dateFrom: ecgDateFrom,
      dateTo: ecgDateTo,
    },
  };

  const { data: ecgData } = yield call(
    dataProvider.get,
    resources.ecgData.byPeriod,
    healthDataRequestParams
  );

  const {
    data: { average: heartRateAverage },
  } = yield call(
    dataProvider.get,
    resources.healthData.heartRateStripSummary,
    healthDataRequestParams
  );

  return {
    data: {
      procedureId,
      heartRateAverage,
      ecg: ecgData,
      abnormalityType: abnormalityTypeEnum.patient,
      dateFromEvent: patientEventDate,
      dateFrom: ecgDateFrom,
      dateTo: ecgDateTo,
    },
  };
}

function* getEcgEvent(payload, signal) {
  const { eventId, filter: payloadFilter } = payload;
  const filter = { ...payloadFilter, eventGroupIds: [eventId] };

  return yield call(dataProvider.get, resources.ecgData.byPeriod, {
    ...payload,
    filter,
    signal,
  });
}

function* getEcgEventGroupSaga(action) {
  const {
    payload: { eventType, event, procedureId, ...requestPayload },
    signal,
  } = action;
  try {
    const payload =
      eventType === abnormalityTypeEnum.patient
        ? yield call(
            getPatientEvent,
            { ...requestPayload, event, procedureId },
            signal
          )
        : yield call(getEcgEvent, requestPayload, signal);

    const successAction = {
      signal,
      payload,
      type: actionTypes.singleEvent.success,
      requestPayload,
    };

    yield put(successAction);

    if (action.onSuccess) {
      yield call(() => action.onSuccess(successAction));
    }
  } catch ({ message }) {
    yield put({
      type: actionTypes.singleEvent.failed,
      message,
      requestPayload,
    });
  }
}

function* getEcgEventGroup() {
  yield takeEvery(actionTypes.singleEvent.requested, getEcgEventGroupSaga);
}

export default getEcgEventGroup;
