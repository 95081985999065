import { call, put, takeEvery } from 'redux-saga/effects';

import dataProvider from 'store/dataProvider';
import resources from 'common/constants/resources';
import { actionTypes } from '../index';

const getPeCount = async (filter) => {
  const res = await dataProvider.getList(resources.healthData.patientEvents, {
    filter: {
      ...filter,
      status: filter.status || undefined,
      reviewed: undefined,
      abnormalityTypes: undefined,
    },
    pagination: { page: 1, perPage: 1 },
  });

  return res?.total || 0;
};

function* getTotalsSaga(action) {
  const { filter, isPatient } = action.payload;

  try {
    const { data: eventsData } = yield call(
      dataProvider.getList,
      resources.eventGroup.count,
      { filter }
    );

    const ecgTotals = eventsData.reduce((acc, { count }) => acc + count, 0);
    const peCount = isPatient ? yield call(getPeCount, filter) : 0;
    const eventsCount = ecgTotals + peCount;

    yield put({
      type: actionTypes.getEventTotalsByStatus.success,
      payload: { data: eventsCount },
      requestPayload: action.payload,
    });
  } catch (e) {
    const { message } = e;
    yield put({
      type: actionTypes.getEventTotalsByStatus.failed,
      payload: message,
    });
  }
}

export default function* getEventTotalsByStatus() {
  yield takeEvery(actionTypes.getEventTotalsByStatus.requested, getTotalsSaga);
}
