import { requestSaga } from 'common/utils/generators';
import resources from 'common/constants/resources';
import dataProvider from 'store/dataProvider';

import { actionTypes } from '../reducer';

export default function* getDailyHeartRates() {
  yield requestSaga({
    request: dataProvider.get,
    actionType: actionTypes.heartRates,
    resource: resources.healthData.ecgBeats,
  });
}
