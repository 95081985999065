import React, { useEffect, useCallback, useMemo } from 'react';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
import { useSelector, useDispatch } from 'react-redux';

import {
  getGraphDuration,
  getEventStripGraphParams,
  getEventGraphCenterDate,
} from 'common/utils/helpers/graphs';
import { eventsActions } from 'common/ducks';
import { usePrevious, useVisibility } from 'common/hooks';
import { getProcedure } from 'common/utils/entitySelectors';
import { abnormalityTypeEnum } from 'common/constants/ecgEnums';
import SimpleGraph from 'common/components/Widgets/SimpleGraph';
import { openEventStripModal } from 'common/ducks/general/actions/modalActions';
import { eventStripReducerNames } from 'common/constants/enums';

import GraphButtons from './EventGraphButtons';
import NoEcgDataView from './NoEcgDataView';

const defaultProp = {};

const EventGraph = ({
  width = null,
  status = null,
  height = null,
  onClick = () => null,
  graphProps = defaultProp,
  fetchProps = defaultProp,
  getEventEcg = () => null,
  currentEvent = undefined,
  socketParams = defaultProp,
  selected = false,
  withoutGreed = false,
  noDescription = false,
  includeButton = false,
  significantButton = false,
  setEventSignificant = () => null,
}) => {
  const {
    ecg,
    date,
    dateFrom,
    customDateFrom,
    id: eventId,
    procedureId,
    abnormalityType,
  } = currentEvent || defaultProp;

  const prevCustomDate = usePrevious(customDateFrom);

  const dispatch = useDispatch();
  const [visible, container] = useVisibility();
  const procedure = useSelector(getProcedure(procedureId));

  const isPE = useMemo(
    () => abnormalityType === abnormalityTypeEnum.patient,
    [abnormalityType]
  );

  const eventStatusButtons = useMemo(
    () => includeButton || significantButton,
    [includeButton, significantButton]
  );

  const duration = useMemo(
    () => getGraphDuration(abnormalityType),
    [abnormalityType]
  );

  const eventDate = useMemo(
    () => getEventGraphCenterDate(currentEvent),
    [currentEvent]
  );

  const handleRemovePE = useCallback(() => {
    if (!isPE) {
      return;
    }

    const payload = {
      query: { procedureId },
      body: {
        id: eventId,
        date: date || dateFrom,
        symptoms: [],
      },
    };

    dispatch(eventsActions.updatePatientEvent(payload));
  }, [date, dateFrom, dispatch, eventId, isPE, procedureId]);

  const openEventModal = () => {
    if (currentEvent?.similar?.length > 1) {
      return;
    }

    const params = getEventStripGraphParams(currentEvent);

    dispatch(
      openEventStripModal({
        status,
        procedureId,
        event: currentEvent,
        resource: eventStripReducerNames.monitoringEventStrip,
        ...fetchProps,
        ...params,
      })
    );
  };

  const fetchEcg = useCallback(() => {
    if (!getEventEcg || !eventDate) {
      return {};
    }

    const filter = {
      procedureId,
      precision: 25,
      dateFrom: moment(eventDate)
        .subtract(duration / 2, 'seconds')
        .toISOString(),
      dateTo: moment(eventDate)
        .add(duration / 2, 'seconds')
        .toISOString(),
    };

    return dispatch(getEventEcg({ filter }, eventId));
  }, [eventId, duration, dispatch, eventDate, procedureId, getEventEcg]);

  useEffect(() => {
    let action = null;

    if (visible && !ecg) {
      action = fetchEcg();
    }

    return () => action?.abort && action.abort();
  }, [visible, fetchEcg, ecg]);

  useEffect(() => {
    if (customDateFrom !== prevCustomDate && ecg) {
      fetchEcg();
    }
  }, [customDateFrom, prevCustomDate, ecg, fetchEcg]);

  if (!currentEvent) {
    return null;
  }

  if (!currentEvent?.ecg) {
    return (
      <Skeleton
        ref={container}
        variant="rect"
        animation="wave"
        style={{
          minWidth: width || '100%',
          minHeight: height || '100%',
        }}
      />
    );
  }

  return (
    <Grid
      container
      wrap="nowrap"
      ref={container}
      direction="row"
      style={{ height: height || '100%' }}
    >
      {!currentEvent.ecg?.length ? (
        <NoEcgDataView
          isPE={isPE}
          event={currentEvent}
          height={height}
          handleRemovePE={handleRemovePE}
        />
      ) : (
        <>
          <SimpleGraph
            width={width}
            height={height}
            onClick={onClick}
            selected={selected}
            procedure={procedure}
            graphProps={{
              scroll: false,
              ...graphProps,
            }}
            eventData={currentEvent}
            definedDuration={duration}
            withoutGreed={withoutGreed}
            noDescription={noDescription}
            onDoubleClick={openEventModal}
            noSimilar={currentEvent?.similar?.length <= 1}
          />

          {eventStatusButtons && (
            <GraphButtons
              event={currentEvent}
              socketParams={socketParams}
              includeButton={includeButton}
              significantButton={significantButton}
              setEventSignificant={setEventSignificant}
            />
          )}
        </>
      )}
    </Grid>
  );
};

export default EventGraph;
