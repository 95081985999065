import moment from 'moment';

export const insertEmptyPointsForData = (d, unitOfTime) => {
  const data = d;

  const emptySpaces = data.reduce((acc, record, index) => {
    const current = record;
    const nextIndex = index + 1;
    const next = data[nextIndex];

    const timeDiff = moment(next?.time).diff(current?.time, unitOfTime);
    const isInsertEmpty = timeDiff > 1;

    if (!isInsertEmpty || !next) {
      return acc;
    }

    return [
      ...acc,
      {
        index: nextIndex,
        zeroPoint: {
          value: null,
          time: moment(current.time).add(1, 'second').toISOString(),
        },
      },
    ];
  }, []);

  emptySpaces.forEach(({ index, zeroPoint }, idx) => {
    data.splice(index + idx, 0, zeroPoint);
  });

  return data;
};
