import unionBy from 'lodash/unionBy';
import { createRequestActionTypes } from 'common/utils/actionTypesHelper';
import { requestReducer } from 'common/utils/generators';

export const patchesActionTypes = createRequestActionTypes(
  ['getList', 'getOne', 'update', 'updateMany', 'updateStatus', 'create'],
  'patches'
);

const initialState = {
  loading: false,
  data: [],
  total: 0,
  error: false,
};

export default requestReducer(
  [
    patchesActionTypes.getList,
    patchesActionTypes.getOne,
    patchesActionTypes.update,
  ],
  initialState,
  (payload, state) => {
    const { data, total } = payload;
    return {
      ...state,
      loading: false,
      data: Array.isArray(data) ? data : unionBy([data], state.data, 'id'),
      total,
    };
  }
);

export const getPatchesList = (payload) => ({
  type: patchesActionTypes.getList.requested,
  payload,
});

export const getPatch = ({ id }) => ({
  type: patchesActionTypes.getOne.requested,
  payload: { id },
});

export const updatePatch = (payload, onSuccess, onError) => ({
  type: patchesActionTypes.update.requested,
  payload,
  onSuccess,
  onError,
});

export const updatePatches = (payload, onSuccess, onError) => ({
  type: patchesActionTypes.updateMany.requested,
  payload,
  onSuccess,
  onError,
});

export const createPatch = (payload, onSuccess, onError) => ({
  type: patchesActionTypes.create.requested,
  payload,
  onSuccess,
  onError,
});

export const updatePatchStatus = (payload, onSuccess, onError) => ({
  type: patchesActionTypes.updateStatus.requested,
  payload,
  onSuccess,
  onError,
});
