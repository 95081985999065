import { createSelector } from 'reselect';
import lodashRange from 'lodash/range';
import orderBy from 'lodash/orderBy';
import moment from 'moment';

import {
  patientEventMapper,
  mapDataForEventLines,
  mapDataForStartEventPoints,
} from 'common/utils/procedureEventMappers';
import { getDatesDuration, isInRangeCheck } from '../utils';

import {
  eventsForLines,
  expectedDuration,
  rangeMinutesDuration,
  stripDurationInMinutes,
} from '../constants';

const timeUnit = 'minutes';

const generateRange = (start, procedure) => {
  const rangeStart = start.isBefore(procedure.startDate)
    ? moment(procedure.startDate)
    : start;

  // get range with the duration for each strip at 90 seconds
  const newRange = lodashRange(
    0,
    rangeMinutesDuration / stripDurationInMinutes
  ).map((item) =>
    rangeStart
      .clone()
      .startOf(timeUnit)
      .add((item + 1) * expectedDuration, 'seconds')
      .toISOString()
  );

  return [rangeStart.toISOString(), ...newRange];
};

const getPatientEvents = (store) => store?.procedure?.patientEvents;
export const getFullDisclosureState = (store) => store.fullDisclosure;

export const isLoading = createSelector(
  getFullDisclosureState,
  (state) => state.loading
);

const getFullDisclosureRange = createSelector(
  getFullDisclosureState,
  (state) => state.dateRange
);

export const getAllIncludedDisclosures = createSelector(
  getFullDisclosureState,
  (state) => state.includeDisclosures
);

export const getIsDayIncluded = createSelector(
  getAllIncludedDisclosures,
  (disclosures) => disclosures.length === 24
);

export const getHourIncluded = (startOfHour) =>
  createSelector(getAllIncludedDisclosures, (disclosures) =>
    disclosures.find((d) => moment(d.dateTime).isSame(startOfHour))
  );

export const getListByTimeRange = (procedure, selectedTime) =>
  createSelector(getFullDisclosureRange, (disclosureRange) => {
    if (disclosureRange) {
      const [startDisclosureRange] = disclosureRange;
      return generateRange(moment(startDisclosureRange), procedure);
    }

    if (!selectedTime) {
      return [];
    }

    const start = moment(selectedTime).startOf('hour');

    return generateRange(start, procedure);
  });

export const getStartEndTime = (procedure, selectedTime) =>
  createSelector(getListByTimeRange(procedure, selectedTime), (range) => {
    const from = range[0];
    const to = range[range.length - 1];

    return [from, to];
  });

export const getCurrentDayNumber = (procedure, selectedTime) =>
  createSelector(getListByTimeRange(procedure, selectedTime), (range) => {
    const [rangeStart] = range;

    return getDatesDuration(procedure.startDate, rangeStart) + 1;
  });

export const getLoading = createSelector(
  getFullDisclosureState,
  (state) => state?.loading
);

// data selectors
export const getAllEcgDataKeys = createSelector(
  getFullDisclosureState,
  (state) => state.ecgDataKeys
);
export const getEcgDataKeys = createSelector(
  getFullDisclosureState,
  getAllEcgDataKeys,
  (state, allKeys) =>
    orderBy(allKeys).filter((date) => state?.ecg?.[date]?.length)
);

export const getEcgDataByKey = (ecgKey) =>
  createSelector(getFullDisclosureState, (state) => {
    return state.ecg?.[ecgKey];
  });

const getEcgEvents = createSelector(
  getFullDisclosureState,
  (state) => state.ecgEvents
);

export const getEcgEventPoints = (dateRangeFrom) =>
  createSelector(getEcgEvents, (events) => {
    if (!events?.length) {
      return [];
    }

    const dateRangeTo = moment(dateRangeFrom).add(2, 'minutes').toISOString();

    return events
      .filter(({ dateFrom }) =>
        moment(dateFrom).isBetween(dateRangeFrom, dateRangeTo)
      )
      .map(mapDataForStartEventPoints);
  });

export const getEcgEventLines = (dateRangeFrom) =>
  createSelector(getEcgEvents, (events) => {
    if (!events?.length) {
      return [];
    }

    const dateRangeTo = moment(dateRangeFrom).add(2, 'minutes').toISOString();

    return events
      ?.filter(({ abnormalityType }) =>
        eventsForLines.includes(abnormalityType)
      )
      .filter(
        ({ dateFrom, dateTo }) =>
          moment(dateFrom).isBetween(dateRangeFrom, dateRangeTo) ||
          moment(dateTo).isBetween(dateRangeFrom, dateRangeTo) ||
          moment(dateRangeFrom).isBetween(dateFrom, dateTo) ||
          moment(dateRangeTo).isBetween(dateFrom, dateTo)
      )
      .map(mapDataForEventLines);
  });

export const getPatientEventPoints = ([rangeStart, rangeEnd]) =>
  createSelector(getPatientEvents, (patientEvents) =>
    patientEvents
      .map(patientEventMapper)
      .filter(({ time }) => moment(time).isBetween(rangeStart, rangeEnd))
  );

export const getCurrentSelectedRange = createSelector(
  getFullDisclosureState,
  (state) => state?.tenSecondsRange
);

export const getTenSecondsRange = (stripRange, selectedTime) =>
  createSelector(getCurrentSelectedRange, (range) => {
    // We need to set selected range by default, based on graphSelection
    if (!range) {
      const startRange = moment(selectedTime).subtract(5, 'seconds');
      const endRange = moment(selectedTime).add(5, 'seconds');

      if (isInRangeCheck(stripRange, startRange, endRange)) {
        return {
          startRange: startRange.toISOString(),
          endRange: endRange.toISOString(),
          center: selectedTime,
        };
      }

      return null;
    }

    const { startRange, endRange } = range;

    if (isInRangeCheck(stripRange, startRange, endRange)) {
      return range;
    }

    return null;
  });
