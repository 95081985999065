import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { useController } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { KeyboardDatePicker } from '@material-ui/pickers';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import { datePickerFormat } from 'common/constants/dateFormats';

import useStyles from './styles';

const DatePicker = ({
  name,
  value,
  rules,
  control,
  minDate,
  maxDate,
  defaultValue = '',
  form: { touched, errors },
  ...props
}) => {
  const classes = useStyles();
  const { container, root, input } = classes;

  const { field } = useController({
    name,
    rules,
    control,
    defaultValue,
  });

  const handleChange = (e, val) => {
    field.onChange(e, val);
  };

  const dateValue = useMemo(() => {
    const resultValue = value || field.value || '';
    return resultValue ? new Date(resultValue) : null;
  }, [value, field.value]);

  return (
    <Grid className={container}>
      <KeyboardDatePicker
        format={datePickerFormat}
        {...props}
        inputVariant="outlined"
        TextFieldComponent={TextField}
        value={dateValue}
        classes={{ root }}
        minDate={minDate}
        maxDate={maxDate}
        InputProps={{
          disableunderline: 'true',
          classes: { input },
          endAdornment: (
            <InputAdornment position="end">
              <DateRangeIcon fontSize="5px" />
            </InputAdornment>
          ),
        }}
        onChange={handleChange}
      />
      {touched[name] && !!errors[name] && (
        <FormHelperText className={classes.error} error>
          {errors[name]}
        </FormHelperText>
      )}
    </Grid>
  );
};

const dateType = [PropTypes.shape(), PropTypes.string, PropTypes.number];

DatePicker.propTypes = {
  name: PropTypes.string,
  form: PropTypes.shape({
    setTouched: PropTypes.func,
    setFieldValue: PropTypes.func,
    setFieldTouched: PropTypes.func,
    touched: PropTypes.shape(),
    errors: PropTypes.shape(),
  }),
  rules: PropTypes.objectOf(PropTypes.any),
  control: PropTypes.objectOf(PropTypes.any).isRequired,
  value: PropTypes.oneOfType(dateType),
  minDate: PropTypes.oneOfType(dateType),
  maxDate: PropTypes.oneOfType(dateType),
  defaultValue: PropTypes.oneOfType(dateType),
};

export default DatePicker;
