import { commonActionTypes } from 'common/ducks/actionTypes';
import { eventsSagas } from 'common/utils/commonSagaCreators';
import { requestSaga } from 'common/utils/generators';
import resources from 'common/constants/resources';
import dataProvider from 'store/dataProvider';

import { actionTypes } from '../reducer';

export const updatePatientEvent = eventsSagas.createPEUpdateSaga(
  actionTypes.updatePatientEvent
);

function* updateProcedureEvents() {
  yield requestSaga({
    actionType: commonActionTypes.bulkReviewEcgEvents,
    request: dataProvider.createOne,
    resource: resources.eventGroup.bulkReview,
  });
}

export default updateProcedureEvents;
