import { requestSaga } from 'common/utils/generators';
import resources from 'common/constants/resources';
import dataProvider from 'store/dataProvider';
import { physicianActionTypes } from '../reducer';

function* createPhysician() {
  yield requestSaga({
    actionType: physicianActionTypes.create,
    request: dataProvider.createOne,
    resource: resources.profile.index,
  });
}

export default createPhysician;
