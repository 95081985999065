import * as Yup from 'yup';
import moment from 'moment';
import validationErrors from 'common/constants/validationErrors';
import {
  phoneRegExp,
  digitRegexp,
  lowerCaseRegexp,
  upperCaseRegexp,
  specialCharacterRegexp,
} from 'common/constants/regularExpressions';

export const objectRequired = (message) =>
  Yup.lazy((value) => {
    if (value === '') {
      return Yup.string().required(message);
    }

    return Yup.mixed().test(
      'is-filled-object',
      message,
      (object) =>
        typeof value === 'object' && Object.keys(object || {}).length > 0
    );
  });

export const getPhoneBaseSchema = (error) =>
  Yup.string().test('phone', error, (message) => {
    const digits = message?.replace(/\D/g, '') || null;
    return message && digits
      ? digits.length === 0 || message.match(phoneRegExp, message)
      : true;
  });

export const email = Yup.string().email(validationErrors.email.email);

export const street = Yup.string().required(
  validationErrors.addressStreet.required
);

export const addressCity = Yup.string().required(
  validationErrors.addressCity.required
);

export const addressState = Yup.string().required(
  validationErrors.addressState.required
);

export const addressZip = Yup.string()
  .matches(/^[0-9]+$/, validationErrors.addressZip.contentType)
  .transform((curr, orig) => (orig === '' ? null : curr))
  .test('length', validationErrors.addressZip.length, (value) => {
    return value ? value.length === 0 || value.length === 5 : true;
  })
  .nullable();

export const phone = getPhoneBaseSchema(validationErrors.phone.matching);

export const fax = getPhoneBaseSchema(validationErrors.fax.matching);

export const phoneCell = getPhoneBaseSchema(
  validationErrors.phoneCell.matching
);

export const password = Yup.string()
  .min(8, 'The min length should be 8 symbols')
  .max(64, 'The max length should be 64 symbols')
  .matches(digitRegexp, 'Should contain at least one digit')
  .matches(lowerCaseRegexp, 'Should contain at least one lowercase letter')
  .matches(upperCaseRegexp, 'Should contain at least one uppercase letter')
  .matches(
    specialCharacterRegexp,
    'Should contain at least one special character'
  );

export const last4ssn = Yup.lazy((value) => {
  if (value?.length > 0) {
    return Yup.string()
      .matches(digitRegexp, 'Should contain only digits')
      .min(4, 'Must be 4 digits long')
      .max(4, 'Must be 4 digits long');
  }
  return Yup.string();
});

export const date = Yup.date()
  .transform((curr, orig) => (orig === '' ? null : curr))
  .nullable()
  .typeError(validationErrors.date.invalid);

export const birthDate = Yup.date()
  .typeError(validationErrors.date.invalid)
  .transform((curr, orig) => (orig === '' ? null : curr))
  .nullable()
  .max(moment().add(1, 'day').toDate(), validationErrors.birthDate.test);

const letterOnlyRegExp = /^[A-Za-z]+$/;

export const createFirstNameValidation = (message, onlyLetterMessage) =>
  Yup.string()
    .required(message)
    .matches(
      letterOnlyRegExp,
      onlyLetterMessage || 'First Name can contain only letters'
    );

export const createLastNameValidation = (message) =>
  Yup.string()
    .required(message)
    .matches(letterOnlyRegExp, 'Last Name can contain only letters');

export const middleName = Yup.string()
  .matches(letterOnlyRegExp, 'Middle Name can contain only letters')
  .transform((curr, orig) => (orig === '' ? null : curr))
  .nullable();
