import { useCallback, useEffect, useMemo, useReducer, useState } from 'react';

import { ecgEventStatus } from 'common/constants/enums';
import { createEnum } from 'common/utils/actionTypesHelper';
import { getEventsByPage, getLastPage } from '../utils';

const PER_PAGE = 49;

const grid = createEnum(
  {
    setEvents: null,
    updateEvents: null,
  },
  'grid'
);

const initState = [];

export const init = (items) => {
  return items || [];
};

const localReducer = (state, { type, payload }) => {
  switch (type) {
    case grid.setEvents:
      return payload;

    case grid.updateEvents: {
      const { added, updated, isGlobalTriage, eventGroupType } = payload;

      const includesIterator = (id) => (ev) => {
        const eid = typeof ev === 'string' ? ev : ev?.id;

        return id === eid;
      };

      const filterByUpdatedEvents = () =>
        state.filter((e) => {
          const id = typeof e === 'string' ? e : e?.id;
          return !updated.find(includesIterator(id));
        });

      const updateByUpdatedEvents = () =>
        state.map((e) => {
          const id = typeof e === 'string' ? e : e?.id;

          return updated.find(includesIterator(id)) || id;
        });

      const modifiedEvents = isGlobalTriage
        ? filterByUpdatedEvents()
        : updateByUpdatedEvents().filter(
            (ev) => ev.status !== ecgEventStatus.denied
          );

      const modifiedAdded = added.filter(
        ({ abnormalityType }) => abnormalityType === eventGroupType
      );

      return [...modifiedAdded, ...modifiedEvents].map(
        (item) => item.id || item
      );
    }

    default:
      return state;
  }
};

const setEvents = (payload) => ({
  type: grid.setEvents,
  payload,
});

const updateEvents = (payload) => ({
  type: grid.updateEvents,
  payload,
});

const useGridState = (items = [], abnormalityType = '', perPage = PER_PAGE) => {
  const [page, setPage] = useState(1);

  const [allItems, dispatch] = useReducer(localReducer, initState, init);

  const state = useMemo(
    () => ({
      page,
      perPage,
      all: allItems,
      events: getEventsByPage(allItems, page, perPage),
      lastPage: getLastPage(allItems, perPage),
      eventGroupType: abnormalityType,
    }),
    [abnormalityType, allItems, page, perPage]
  );

  const setCurrentEvents = useCallback(
    () => dispatch(setEvents(items)),
    [items]
  );

  const updateEventsBySocket = useCallback(
    (payload = {}) => {
      dispatch(updateEvents({ ...payload, eventGroupType: abnormalityType }));
    },
    [abnormalityType]
  );

  useEffect(() => {
    setCurrentEvents();
  }, [setCurrentEvents]);

  return [state, setPage, updateEventsBySocket];
};

export default useGridState;
