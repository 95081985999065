import React from 'react';
import Grid from '@material-ui/core/Grid';

const StripSettingsContainer = ({ children = null }) => (
  <Grid item style={{ width: '50%', padding: '0 8px' }}>
    {children}
  </Grid>
);

export default StripSettingsContainer;
