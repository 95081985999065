import React, { useMemo } from 'react';
import * as d3 from 'd3';

import { axesGridChildrenPropTypes } from 'common/constants/sharedPropTypes';
import Popper from '../TrendGraph/Popper';

const EventDot = ({
  id = '',
  time = '',
  title = '',
  parent = {},
  allData = [],
  color = '#000',
  yPosition = 25000,
  circleRadius = 4,
  strokeWidth = 2,
  tooltip = false,
  patientEvent = false,
  fixedPosition = false,
  clickable,
}) => {
  const { options = {}, xScale, yScale, scale } = parent;
  const { xPropKey } = options;
  const sx = scale || xScale;

  const bisect = d3.bisector((d) => d[xPropKey]).left;

  const point = useMemo(() => {
    const index = bisect(allData, time, 1);
    return allData[index];
  }, [allData, bisect, time]);

  const circleData = useMemo(
    () => [point?.[xPropKey], yPosition + circleRadius],
    [point, yPosition, xPropKey, circleRadius]
  );

  const svgCircle = (
    <circle
      id={id}
      zindex={100}
      stroke={color}
      r={circleRadius}
      cx={sx(new Date(circleData[0]))}
      strokeWidth={patientEvent ? strokeWidth : 0}
      fill={patientEvent ? 'white' : color}
      cursor={clickable ? 'pointer' : 'default'}
      cy={fixedPosition ? circleData[1] : yScale(circleData[1])}
    />
  );

  return tooltip ? (
    <Popper title={title} content={null}>
      {svgCircle}
    </Popper>
  ) : (
    svgCircle
  );
};

EventDot.propTypes = axesGridChildrenPropTypes;

export default EventDot;
