import uniq from 'lodash/uniqBy';

import { commonActionTypes } from 'common/ducks/actionTypes';
import { generateCancellableAction } from 'common/utils/actionHelpers';
import { createRequestActionTypes } from 'common/utils/actionTypesHelper';

export const actionTypes = createRequestActionTypes(
  [
    'getList',
    'procedure',
    'singleEvent',
    'lockPatients',
    'unlockPatients',
    'procedureEvents',
    'updatePatientEvent',
  ],
  'globalEscalated'
);

const updateEventsBySocketMessage =
  'globalEscalated.updateEventsBySocketMessage';

const clearStateActionType = 'globalEscalated.clearState';

const initialState = {
  loading: true,
  multiLoading: false,
  patientIds: [],
  proceduresIds: [],
  events: [],
  counts: {},
  procedureTotals: {},
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.getList.requested:
      return {
        ...state,
        loading: true,
        proceduresIds: [],
      };

    case actionTypes.getList.success: {
      const { data = [] } = payload;

      const counts = data.reduce(
        (acc, { id, lastEcgEventGroups }) => ({
          ...acc,
          [id]: lastEcgEventGroups.reduce(
            (a, v) => ({ ...a, [v.abnormalityType]: v.totalCount }),
            {}
          ),
        }),
        {}
      );

      const events = data
        .map(({ lastEcgEventGroups }) => lastEcgEventGroups)
        .flat();

      const procedureTotals = data.reduce(
        (acc, { id, lastEcgEventGroups }) => ({
          ...acc,
          [id]: lastEcgEventGroups.reduce(
            (a, { totalCount }) => a + totalCount,
            0
          ),
        }),
        {}
      );

      const proceduresIds = data.map(({ id }) => id);
      const patientIds = data.map(({ patientId }) => patientId).filter(Boolean);

      return {
        ...state,
        events,
        counts,
        patientIds,
        proceduresIds,
        procedureTotals,
        loading: false,
      };
    }

    case actionTypes.getList.failed:
      return {
        ...state,
        loading: false,
        proceduresIds: [],
      };

    case actionTypes.procedureEvents.requested: {
      return {
        ...state,
        multiLoading: true,
      };
    }

    case actionTypes.procedureEvents.success: {
      return {
        ...state,
        counts: { ...state.counts, ...payload.counts },
        events: uniq([...state.events, ...payload.events], 'id'),
        procedureTotals: { ...state.procedureTotals, ...payload.total },
        multiLoading: false,
      };
    }

    case actionTypes.procedureEvents.failed:
      return {
        ...state,
        multiLoading: false,
      };

    case updateEventsBySocketMessage: {
      const { added, updated } = payload;

      const events = state.events
        .map((e) => {
          const updE = updated.find((ue) => ue.id === e.id);

          if (updE) {
            return {
              ...e,
              ...updE,
            };
          }

          return e;
        })
        .filter((event) => {
          const newEvent = updated.find((e) => e.id === event.id);
          return newEvent ? !newEvent.status : !!event;
        });

      return {
        ...state,
        events: [...added, ...events],
      };
    }

    case commonActionTypes.setInvertEcg.requested:
      return {
        ...state,
        multiLoading: true,
      };
    case commonActionTypes.setInvertEcg.success:
      return {
        ...state,
        multiLoading: false,
      };
    case commonActionTypes.setInvertEcg.failed:
      return {
        ...state,
        multiLoading: false,
      };

    case clearStateActionType:
      return initialState;

    default:
      return state;
  }
};

export const getProcedureEvents = (procedureId, abnormalityTypes) => ({
  type: actionTypes.procedureEvents.requested,
  payload: { procedureId, abnormalityTypes },
});

export const updateEventsLocally = (events = []) => ({
  type: updateEventsBySocketMessage,
  payload: events,
});

export const updateProcedureEvents = (payload, onSuccess, onError) => ({
  type: commonActionTypes.bulkReviewEcgEvents.requested,
  payload,
  onSuccess,
  onError,
});

export const getProcedure = (payload, onSuccess, signal) =>
  generateCancellableAction({
    type: actionTypes.procedure.requested,
    signal,
    payload,
    onSuccess,
  });

export const getEvent = (
  id,
  eventType,
  procedureId,
  dateFrom,
  dateTo,
  onSuccess,
  signal
) =>
  generateCancellableAction({
    type: actionTypes.singleEvent.requested,
    payload: {
      eventType,
      procedureId,
      eventId: id,
      filter: {
        dateFrom,
        dateTo,
        procedureId,
        stepPercentage: 25,
      },
    },
    signal,
    onSuccess,
  });

export const lockPatients = (patientids, onSuccess = () => null) => ({
  type: actionTypes.lockPatients.requested,
  payload: { query: { patientids } },
  onSuccess,
});

export const unlockPatients = (
  patientids,
  onSuccess = () => null,
  forceUnlock = false
) => ({
  type: actionTypes.unlockPatients.requested,
  payload: { query: { patientids, forceUnlock }, options: { keepalive: true } },
  onSuccess,
});

export const updatePatientEvent = (payload) => ({
  type: actionTypes.updatePatientEvent.requested,
  payload,
});

export const getProceduresList = (abnormalityTypes, onSuccess) => ({
  type: actionTypes.getList.requested,
  payload: { filter: { abnormalityTypes } },
  onSuccess,
});

export const clearState = () => ({ type: clearStateActionType });
