import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/styles/makeStyles';
import Accordion from '@material-ui/core/Accordion';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    paddingTop: spacing(1),
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 'none',
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
      borderBottom: '1px solid rgba(0, 0, 0, .125)',
    },
  },
  expanded: {},
}));

const VCAccordion = ({
  defaultExpanded,
  collapsable,
  children,
  disabled = false,
  dataTestid = '',
}) => {
  const classes = useStyles();
  const [expanded, toggle] = useState(() => defaultExpanded);

  const onChange = (e, state) => {
    if (!disabled) {
      toggle(state);
    }
  };

  useEffect(() => {
    toggle(defaultExpanded);
  }, [defaultExpanded]);

  return (
    <Accordion
      classes={classes}
      onChange={onChange}
      expanded={collapsable ? expanded : true}
      data-testid={dataTestid}
    >
      {children}
    </Accordion>
  );
};

VCAccordion.propTypes = {
  defaultExpanded: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  collapsable: PropTypes.bool,
  dataTestid: PropTypes.string,
};

export default VCAccordion;
