import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import moment from 'moment';

import Datepicker from 'common/components/simpleInputs/Datepicker';

const sanitizeProps = ({
  date,
  optionLabel,
  onInputChange,
  optionValueName,
  ...props
}) => props;

const minDateTime = { hour: 0, minute: 0 };
const maxDateTime = { hour: 23, minute: 59 };

const RangeInput = ({
  name,
  select,
  options,
  label = '',
  minValueName,
  maxValueName,
  minValueLabel,
  maxValueLabel,
  field = {},
  fieldProps = {},
  ...rest
}) => {
  const props = sanitizeProps(rest);
  const [currentValue, setCurrentValue] = useState({
    [minValueName]: '',
    [maxValueName]: '',
  });
  const handleChangeRange = (fieldName) => (value) => {
    const newValue = { ...currentValue } || {};

    const rangeTimeValue =
      fieldName === minValueName
        ? moment(value).set(minDateTime).toISOString()
        : moment(value).set(maxDateTime).toISOString();

    newValue[fieldName] = rangeTimeValue;

    setCurrentValue(newValue);

    if (field.onChange) {
      field.onChange(newValue);
    }

    if (props.onChange) {
      props.onChange(rangeTimeValue, newValue);
    }
  };

  const renderRangeItem = (rangeItemName, rangeItemPlaceholder) => {
    const maxDate =
      rangeItemName === minValueName && currentValue[maxValueName]
        ? currentValue[maxValueName]
        : null;
    const minDate =
      rangeItemName === maxValueName && currentValue[minValueName]
        ? currentValue[minValueName]
        : null;

    return (
      <Grid item style={{ width: '49%' }}>
        <Datepicker
          {...props}
          name={rangeItemName}
          placeholder={rangeItemPlaceholder}
          value={currentValue[rangeItemName]}
          maxDate={fieldProps.maxDate || maxDate}
          minDate={fieldProps.minDate || minDate}
          onChange={handleChangeRange(rangeItemName)}
        />
      </Grid>
    );
  };

  return (
    <FormControl component="fieldset" variant="outlined">
      <FormLabel style={{ paddingBottom: 2 }}>{label}</FormLabel>
      <FormGroup row>
        <Grid container direction="row" justifyContent="space-between">
          {renderRangeItem(minValueName, minValueLabel)}
          {renderRangeItem(maxValueName, maxValueLabel)}
        </Grid>
      </FormGroup>
    </FormControl>
  );
};

RangeInput.defaultProps = {
  minValueName: 'Min',
  maxValueName: 'Max',
  minValueLabel: 'From',
  maxValueLabel: 'To',
};

RangeInput.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  fieldProps: PropTypes.shape(),
  onInputChange: PropTypes.func,
  name: PropTypes.string.isRequired,
  field: PropTypes.objectOf(PropTypes.any),
  options: PropTypes.arrayOf(PropTypes.any),
  select: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.array,
  ]),
  onChange: PropTypes.func,
  inputType: PropTypes.string,
  minValueName: PropTypes.string,
  maxValueName: PropTypes.string,
  minValueLabel: PropTypes.string,
  maxValueLabel: PropTypes.string,
};

export default RangeInput;
