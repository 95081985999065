import moment from 'moment';

export const normalizePhone = (phone) => phone?.replace(/[-_]/g, '') || null;

export const prepareProcedureDates = (start, end) => {
  if (!start && !end) {
    const startDate = moment().startOf('day');
    const endDate = startDate.clone().add(30, 'day').endOf('day');

    return [startDate.toISOString(), endDate.toISOString()];
  }

  const startDate = start
    ? moment(start).startOf('day')
    : moment(end).startOf('day').subtract(30, 'days');

  const endDate = end
    ? moment(end).endOf('day')
    : moment(start).startOf('day').add(30, 'days').endOf('day');

  return [startDate.toISOString(), endDate.toISOString()];
};
