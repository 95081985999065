import { commonActionTypes } from 'common/ducks/actionTypes';

export const lockPatients = (patientids, onSuccess = () => null) => ({
  type: commonActionTypes.lockPatients.requested,
  payload: { query: { patientids } },
  onSuccess,
});

export const unlockPatients = (
  patientids,
  onSuccess = () => null,
  forceUnlock = false
) => ({
  type: commonActionTypes.unlockPatients.requested,
  payload: { query: { patientids, forceUnlock }, options: { keepalive: true } },
  onSuccess,
});
