import dataProvider from 'store/dataProvider';
import resources from 'common/constants/resources';
import { requestSaga } from 'common/utils/generators';

import { actionTypes } from '../index';

export default function* splitProcedureByDate() {
  yield requestSaga({
    request: dataProvider.createOne,
    actionType: actionTypes.splitByDate,
    resource: resources.procedure.splitByDate,
  });
}
