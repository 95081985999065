import fetchProcedureList from './fetchProcedureList';
import fetchProcedure from './fetchProcedure';
import fetchRecentProcedures from './fetchRecentProcedures';
import updateProcedure from './updateProcedure';
import updateStatuses from './updateStatuses';
import createProcedure from './createProcedure';

export default [
  fetchProcedure,
  updateStatuses,
  updateProcedure,
  createProcedure,
  fetchProcedureList,
  fetchRecentProcedures,
];
