import dataProvider from 'store/dataProvider';
import resources from 'common/constants/resources';
import { requestSaga } from 'common/utils/generators';
import { physicianActionTypes } from '../reducer';

export default function* updatePhysicianStatus() {
  yield requestSaga({
    actionType: physicianActionTypes.updateStatus,
    request: dataProvider.updateMany,
    resource: resources.physician.status,
  });
}
