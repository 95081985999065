import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

const RangeItem = ({ name, label, select, options, maxLength, ...rest }) => {
  const [currentValue, setCurrentValue] = useState(() => rest.value);
  const isSelect = Boolean(select && options);

  const handleChange = (event) => {
    const val = maxLength
      ? Number(event.target.value.toString().slice(0, maxLength))
      : event.target.value;

    rest.onChange(event, val);
    setCurrentValue(val);
  };

  return (
    <TextField
      {...rest}
      name={name}
      label={label}
      select={isSelect}
      value={currentValue}
      onChange={handleChange}
    >
      {isSelect &&
        options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
    </TextField>
  );
};

RangeItem.propTypes = {
  select: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  maxLength: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.any),
};

export default RangeItem;
