import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { useForm } from 'react-hook-form';
import CloseIcon from '@material-ui/icons/Close';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import Save from 'common/components/buttons/Save';
import resources from 'common/constants/resources';
import { commonStatusTypes } from 'common/constants/enums';
import { useNotify } from 'common/modules/Notification/hooks';
import Autocomplete from 'common/components/formFields/Autocomplete';
import { kitsActions } from '../ducks';

const queryParams = {
  statuses: commonStatusTypes.active,
  _order: 'asc',
  _sort: 'name',
};

const rules = { required: 'The organization is required' };

const ReassignKitTenantModal = ({ ids, handleSuccess, onClose }) => {
  const notify = useNotify();
  const dispatch = useDispatch();

  const { control, formState, handleSubmit } = useForm({
    reValidateMode: 'onChange',
  });

  const close = () => {
    if (onClose) {
      onClose();
    }
  };

  const onSuccess = () => {
    notify.success('Kits successfully assigned');
    close();
    handleSuccess();
  };

  const onError = (error) => {
    notify.error(error?.body?.message || 'Error while assigning kits');
  };

  const onSubmit = ({ tenantId }) => {
    dispatch(
      kitsActions.updateKitsTenantId(
        { query: { kitIds: ids, tenantId } },
        onSuccess,
        onError
      )
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid
        container
        wrap="nowrap"
        alignItems="center"
        justifyContent="space-between"
      >
        <DialogTitle>
          <Typography component="span" variant="h3" color="primary">
            Assign Kit to Organization
          </Typography>
        </DialogTitle>

        <DialogTitle>
          <IconButton onClick={close}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
      </Grid>

      <DialogContent>
        <Grid container style={{ paddingBottom: 10 }}>
          <Autocomplete
            rules={rules}
            name="tenantId"
            form={formState}
            label="Assign to Organization"
            control={control}
            resource={resources.tenant.list}
            queryParams={queryParams}
            optionLabel="name"
            optionValueName="id"
            getOptionValue={(option) => option.id}
          />
        </Grid>
      </DialogContent>

      <DialogActions>
        <Save disabled={false} text="Assign" />
      </DialogActions>
    </form>
  );
};

ReassignKitTenantModal.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.string),
  onClose: PropTypes.func,
  handleSuccess: PropTypes.func,
};

export default ReassignKitTenantModal;
