import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const SortIcon = ({ isSortedDesc = false }) => (
  <SvgIcon viewBox="0 0 36 24">
    {isSortedDesc ? (
      <path d="M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8 8-8z" />
    ) : (
      <path d="M4 12l1.41 1.41L11 7.83V20h2V7.83l5.58 5.59L20 12l-8-8-8 8z" />
    )}
  </SvgIcon>
);

export default SortIcon;
