import { createSelector } from 'reselect';
import orderBy from 'lodash/orderBy';

const getGridState = (store) => store.gridItems;

export const getLoading = createSelector(
  getGridState,
  (state) => state.loading
);

export const getEvents = (isTemplate) =>
  createSelector(getGridState, (state) => {
    return isTemplate
      ? state.events.filter(({ similar }) => !!similar?.length)
      : state.events;
  });

export const getTotal = createSelector(getGridState, (state) => state.total);

const getAllEcg = createSelector(getGridState, (state) => state.ecg);

export const getEventEcgById = (id) =>
  createSelector(getAllEcg, (ecg) => ecg[id]);

export const getChildrenEvents = (parentId) =>
  createSelector(getGridState, (state) => {
    if (parentId) {
      const parentItem = state.events.find(({ id }) => id === parentId);

      return orderBy(
        parentItem?.similar || parentItem?.ecgEventGroups || [],
        'dateFrom',
        'desc'
      );
    }

    return null;
  });
