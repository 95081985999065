import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import useStyles from '../styles';

const getNumberOfDay = (day = 0) => Math.max(day, 0);

const ShortInfo = ({ procedureInfo, userInfo, kitInfo }) => {
  const classes = useStyles();
  const isFullNameNecessary = !userInfo.lastName && !userInfo.firstName;
  const userName = isFullNameNecessary
    ? userInfo.fullName
    : `${userInfo.lastName},  ${userInfo.firstName}`;

  const procedureName = procedureInfo?.name || userName || 'Unknown';

  return (
    <Grid container direction="column">
      <Tooltip title={procedureName}>
        <Typography
          title={procedureName}
          variant="h6"
          component="div"
          className={classes.headSectionData}
          style={{
            width: '110px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          {procedureName}
        </Typography>
      </Tooltip>

      <Grid item container spacing={1}>
        <Grid item>
          <Typography variant="h6">Kit#</Typography>
        </Grid>

        <Grid item>
          <Typography variant="h6" className={classes.headSectionData}>
            {kitInfo.serialNumber}
          </Typography>
        </Grid>
      </Grid>

      <Typography variant="h6">
        {`Start: ${procedureInfo.shortStartDate}`}
      </Typography>

      <Typography variant="h6">{`End: ${procedureInfo.shortEndDate}`}</Typography>

      <Typography variant="h6">
        {`${getNumberOfDay(procedureInfo.currentDay)} of ${getNumberOfDay(
          procedureInfo.countOfDays
        )}`}
      </Typography>
    </Grid>
  );
};

ShortInfo.propTypes = {
  userInfo: PropTypes.objectOf(PropTypes.any),
  procedureInfo: PropTypes.objectOf(PropTypes.any),
  kitInfo: PropTypes.shape({
    serialNumber: PropTypes.string,
  }),
};

export default ShortInfo;
