import updateEvents, { updatePatientEvent } from './updateEvents';
import { lockPatients, unlockPatients } from './patientslocking';
import { getProcedureEvents } from './getProcedureEvents';
import getEcgEventGroup from './getEcgEventGroup';
import procedureInfoSaga from './procedure';
import { getList } from './getProcedureList';

export default [
  getList,
  updateEvents,
  getEcgEventGroup,
  procedureInfoSaga,
  getProcedureEvents,
  updatePatientEvent,

  lockPatients,
  unlockPatients,
];
