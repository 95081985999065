import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { push, replace } from 'connected-react-router';

const types = {
  push,
  replace,
};

const useRedirect = (type = 'push') => {
  const dispatch = useDispatch();

  return useCallback(
    (redirectTo, state = {}) => {
      const redirectAction = types[type];

      dispatch(redirectAction(redirectTo, state));
    },
    [dispatch, type]
  );
};

export default useRedirect;
