import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { lineGenerator } from './utils';

const TrendLine = ({
  color = '#000',
  strokeWidth = 2,
  parent = {},
  data = [],
  visibleRange = [],
  lineProps = {},
}) => {
  const { xScale, yScale, options, clip = {}, scale } = parent;
  const { xPropKey, yPropKey } = options;

  const line = useCallback(
    (...args) =>
      lineGenerator(
        { x: xPropKey, y: yPropKey },
        yScale,
        visibleRange
      )(...args),
    [yScale, xPropKey, yPropKey, visibleRange]
  );

  return (
    <path
      fill="none"
      stroke={color}
      clipPath={clip}
      className="trend-line"
      strokeWidth={strokeWidth}
      d={line(data, scale || xScale)}
      {...lineProps}
    />
  );
};

TrendLine.propTypes = {
  color: PropTypes.string,
  strokeWidth: PropTypes.number,
  parent: PropTypes.shape({
    xScale: PropTypes.func,
    yScale: PropTypes.func,
    options: PropTypes.shape({
      xPropKey: PropTypes.string,
      yPropKey: PropTypes.string,
    }),
    clip: PropTypes.objectOf(PropTypes.string),
  }),
  data: PropTypes.arrayOf(PropTypes.object),
  lineProps: PropTypes.objectOf(PropTypes.string),
  visibleRange: PropTypes.arrayOf(PropTypes.string),
};

export default TrendLine;
