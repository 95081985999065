import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';

import { commonRoles } from 'common/constants/enums';
import { commonDucksSelectors, roleActions } from 'common/ducks';
import useAuthProvider from 'store/authProvider/useAuthProvider';
import useNotify from 'common/modules/Notification/hooks/useNotify';
import SelectExistingContact from './components/SelectExistingContact';
import ContactModalTitle from './components/ContactModalTitle';
import ContactsForm from './components/ContactsForm';
import { contactsActions } from './ducks';

const getDeepObjectComparison = (source, target) =>
  Object.keys(source)
    .map((key) => {
      if (typeof source[key] === 'object' && typeof target[key] === 'object') {
        return getDeepObjectComparison(source[key], target[key]);
      }

      return source[key] !== target[key] && key;
    })
    .flat()
    .filter(Boolean);

const redirectURL = '/reset-password/';
const query = { redirectPrefix: `${window.location.origin}${redirectURL}` };

const ContactsCreate = ({ entityId, onClose, refreshData = () => {} }) => {
  const notify = useNotify();
  const dispatch = useDispatch();
  const authProvider = useAuthProvider();
  const [contact, setContact] = useState(null);
  const tenantId = useMemo(
    () => authProvider.currentTenantId,
    [authProvider.currentTenantId]
  );

  const roles = useSelector(commonDucksSelectors.roleSelectors.getRoles);

  const close = () => {
    if (onClose) {
      onClose();
    }
  };

  const onSaveSuccess = () => {
    notify.success('Contact successfully saved');
    refreshData();
    close();
  };

  const onSaveError = ({ body }) => {
    notify.error(
      body?.exception || body?.message || 'Error while saving contact'
    );
  };

  const attachEntityToContact = (param) => {
    const id = typeof param === 'string' ? param : param.payload.data.id;

    dispatch(
      contactsActions.assignContactToAccount(
        { params: { id, accountId: entityId } },
        onSaveSuccess,
        onSaveError
      )
    );
  };

  const createNew = (data) => {
    const contactRole = roles.find((r) => r.name === commonRoles.contact);

    if (!contactRole) {
      return;
    }

    const { id: roleId, role } = contactRole;

    const contactData = {
      ...data,
      role,
      roleId,
      name: data.fullName,
      email: data?.email || null,
      lastName: data.fullName,
      firstName: data.fullName,
      tenantIds: tenantId ? [tenantId] : [],
      newPassword: 'Awes0meP@ssw0rd@#44',
      use2FactorAuth: Boolean(data.use2FactorAuth),
    };

    dispatch(
      contactsActions.createContactAccount(
        { query, body: contactData },
        attachEntityToContact,
        onSaveError
      )
    );
  };

  const modifySelected = (data) => {
    const contactData = {
      ...contact,
      ...data,
      use2FactorAuth: Boolean(data.use2FactorAuth),
    };

    if (!contactData.email) {
      contactData.email = undefined;
    }

    const modifiedFields = getDeepObjectComparison(contact, contactData);
    const isContactModified = !!modifiedFields.length;

    if (isContactModified) {
      dispatch(
        contactsActions.updateContact(
          { params: { id: contactData.id }, body: contactData },
          () => attachEntityToContact(contactData.id),
          onSaveError
        )
      );

      return;
    }

    attachEntityToContact(contactData.id);
  };

  const save = (data) => {
    if (contact) {
      modifySelected(data);
      return;
    }

    createNew(data);
  };

  const getAllRoles = useCallback(() => {
    if (roles?.length) {
      return;
    }

    dispatch(roleActions.getRoles({}));
  }, [dispatch, roles]);

  useEffect(() => {
    getAllRoles();
  }, [getAllRoles]);

  return (
    <Grid container direction="column">
      <ContactModalTitle title="Select" onClose={close} />

      <SelectExistingContact setContact={setContact} />

      <ContactModalTitle title="Create" />

      <ContactsForm onSubmit={save} defaultValues={contact || {}} />
    </Grid>
  );
};

ContactsCreate.propTypes = {
  onClose: PropTypes.func,
  entityId: PropTypes.string,
  refreshData: PropTypes.func,
};

export default ContactsCreate;
