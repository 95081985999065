import { all, call, put, takeLatest } from 'redux-saga/effects';
import moment from 'moment';

import dataProvider from 'store/dataProvider';
import resources from 'common/constants/resources';
import { mapIncludedDisclosure } from 'common/utils/procedureEventMappers';
import { actionTypes } from '../reducer';

const getEcg = async (disclosure) => {
  const mappedDisclosure = mapIncludedDisclosure(disclosure);

  const filter = {
    dateFrom: mappedDisclosure.dateFrom,
    dateTo: moment(disclosure.dateTime).add(1, 'hour').toISOString(),
    procedureId: disclosure.procedureId,
  };

  const responseEcg = await dataProvider.get(
    resources.ecgData.byPeriodLimited,
    {
      filter: { ...filter, seconds: 10 },
    }
  );

  const responseHR = await dataProvider.get(
    resources.healthData.heartRateStripSummary,
    {
      filter: {
        ...filter,
        groupType: 'Hour',
      },
    }
  );

  return {
    ...mappedDisclosure,
    ecg: responseEcg?.data || [],
    heartRateMin: responseHR?.data?.min,
    heartRateMax: responseHR?.data?.max,
    avgHeartRate: responseHR?.data?.average,
    heartRateAverage: responseHR?.data?.average,
  };
};

function* fetchIncludedDisclosures({ payload }) {
  try {
    const response = yield call(
      dataProvider.getList,
      resources.eventGroup.getIncludedDisclosure,
      payload
    );

    const includedDisclosures = response.data || [];
    const disclosureWithEcg = yield all(includedDisclosures.map(getEcg));

    yield put({
      type: actionTypes.getIncludedDisclosures.success,
      payload: { data: disclosureWithEcg },
    });
  } catch ({ message }) {
    yield put({ type: actionTypes.getIncludedDisclosures.failed, message });
  }
}

export function* getIncludedDisclosures() {
  yield takeLatest(
    actionTypes.getIncludedDisclosures.requested,
    fetchIncludedDisclosures
  );
}
