import unionBy from 'lodash/unionBy';
import { createRequestActionTypes } from 'common/utils/actionTypesHelper';
import { requestReducer } from 'common/utils/generators';

import { modifyUserActivityStatistic } from '../utils';

export const actionTypes = createRequestActionTypes(
  ['getList', 'getOne', 'update', 'create', 'updateStatus', 'getActivity'],
  'users'
);

const initialState = {
  loading: false,
  activityLoading: false,
  data: [],
  total: 0,
  error: false,
  activity: {},
};

const onRequestReducer = (state, action) => {
  if (action.type === actionTypes.getActivity.requested) {
    return {
      ...state,
      activityLoading: true,
    };
  }

  return state;
};

const onErrorReducer = (state, action) => {
  if (action.type === actionTypes.getActivity.failed) {
    return {
      ...state,
      activityLoading: false,
    };
  }

  return state;
};

export default requestReducer(
  [
    actionTypes.getList,
    actionTypes.getOne,
    actionTypes.update,
    actionTypes.getActivity,
  ],
  initialState,
  (payload, state, action) => {
    const { data, total } = payload;

    if (action.type === actionTypes.getActivity.success) {
      const activity = modifyUserActivityStatistic(data);

      return {
        ...state,
        activity,
        activityLoading: false,
      };
    }

    return {
      ...state,
      loading: false,
      data: Array.isArray(data) ? data : unionBy([data], state.data, 'id'),
      total,
    };
  },
  onRequestReducer,
  onErrorReducer
);

export const getUsers = (payload) => ({
  type: actionTypes.getList.requested,
  payload,
});

export const getUser = (id) => ({
  type: actionTypes.getOne.requested,
  payload: { id },
});

export const updateUser = (payload, onSuccess, onError) => ({
  type: actionTypes.update.requested,
  payload,
  onSuccess,
  onError,
});

export const createUser = (payload, onSuccess, onError) => ({
  type: actionTypes.create.requested,
  payload,
  onSuccess,
  onError,
});

export const updateUserStatus = (payload, onSuccess, onError) => ({
  type: actionTypes.updateStatus.requested,
  payload,
  onSuccess,
  onError,
});

export const getUserActivity = (payload, onSuccess, onError) => ({
  type: actionTypes.getActivity.requested,
  payload,
  onSuccess,
  onError,
});
