export default (actionTypes, initialState, onSuccess, onRequest, onError) =>
  (state = initialState, action) => {
    const { requested, success, failed } = Array.isArray(actionTypes)
      ? actionTypes.find((type) => Object.values(type).includes(action.type)) ||
        {}
      : actionTypes;
    switch (action.type) {
      case requested: {
        const newState = { ...state, loading: true };

        if (onRequest) {
          return onRequest(newState, action);
        }

        return newState;
      }

      case success:
        return onSuccess(action.payload, { ...state, loading: false }, action);

      case failed: {
        const newState = {
          ...state,
          ...action.payload,
          error: action.message,
          loading: false,
        };

        if (onError) {
          return onError(newState, action, action.payload);
        }

        return newState;
      }

      default:
        return state;
    }
  };
