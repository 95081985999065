import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';
import DeleteIcon from '@material-ui/icons/DeleteForeverOutlined';

import { eventsActions } from 'common/ducks/general/actions';
import Button from '../../buttons/DefaultButton';

const useStyles = makeStyles(({ spacing }) => ({
  container: {
    backgroundColor: 'white',
    minHeight: ({ height }) => height || '100%',
    border: ({ isGlobalTriage }) =>
      isGlobalTriage ? '1px solid black' : 'none',
  },
  peButtons: { color: '#FF5F5F90' },
  setAsNoiseBtn: {
    fontSize: 11,
    padding: spacing(0.25, 0.5),
  },
}));

const NoEcgDataView = ({
  isPE,
  event = {},
  height,
  isGlobalTriage,
  handleRemovePE,
}) => {
  const dispatch = useDispatch();
  const styles = useStyles({ height, isGlobalTriage });

  const handleHidePE = () => {
    if (!isPE) {
      return;
    }

    dispatch(
      eventsActions.changeVisibilityOfPE({
        query: { hidden: true },
        params: { id: event.id },
      })
    );
  };

  return (
    <Grid
      container
      justifyContent="center"
      direction="column"
      alignItems="center"
      className={styles.container}
    >
      <Typography>No Ecg Data!</Typography>

      {isPE && handleRemovePE && (
        <Grid
          container
          direction="column"
          className={styles.peButtons}
          alignItems="center"
        >
          {isGlobalTriage && (
            <Grid item>
              <Button
                text="Hide"
                onClick={handleHidePE}
                className={styles.setAsNoiseBtn}
              />
            </Grid>
          )}

          <Grid item title="Remove Patient Event">
            <DeleteIcon fontSize="medium" onClick={handleRemovePE} />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

NoEcgDataView.propTypes = {
  isPE: PropTypes.bool,
  event: PropTypes.shape({}),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isGlobalTriage: PropTypes.bool,
  handleRemovePE: PropTypes.func,
};

export default NoEcgDataView;
