import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles((theme) => ({
  container: {
    position: 'relative',
    width: '100%',
  },
  root: {
    width: '100%',
    position: 'relative',
    background: theme.palette.common.white,
    '& .MuiInputAdornment-root': {
      position: 'absolute',
      right: 0,
    },
  },
  input: {
    height: 24,
    padding: theme.spacing(0.85, 1.25),
    fontSize: 12,
  },
  error: {
    position: 'relative',
  },
}));
