import { takeEvery, put, call } from 'redux-saga/effects';

import dataProvider from 'store/dataProvider';
import resources from 'common/constants/resources';
import { abnormalityTypeEnum } from 'common/constants/ecgEnums';
import { actionTypes } from '../reducer';

const getPEsForProcedure = async (procedureId) =>
  dataProvider
    .getList(resources.healthData.patientEvents, {
      filter: { procedureId, hidden: false, status: null },
      pagination: { page: 1, perPage: 1 },
      sort: { field: 'Date', order: 'desc' },
    })
    .then(({ data = [], total }) => ({
      data: data.map((pe) => ({
        ...pe,
        abnormalityType: abnormalityTypeEnum.patient,
        procedureId,
      })),

      total: total || data.length,
    }));

function* getProcedureEventsSaga(action) {
  const { procedureId, abnormalityTypes } = action.payload;
  try {
    const { data: ecgEventsData } = yield call(
      dataProvider.getList,
      resources.eventGroup.escalated,
      { filter: { procedureId, abnormalityTypes } }
    ) || { data: [], total: 0 };

    const pes = yield call(getPEsForProcedure, procedureId) || {
      data: [],
      total: 0,
    };

    const events = ecgEventsData.map(
      ({ lastEcgEventGroup }) => lastEcgEventGroup
    );

    const counts = ecgEventsData.reduce(
      (acc, { abnormalityType, totalCount }) => ({
        ...acc,
        [abnormalityType]: totalCount,
      }),
      { [abnormalityTypeEnum.patient]: pes.total }
    );

    const totalPerProcedure = ecgEventsData.reduce(
      (acc, { totalCount }) => acc + totalCount,
      pes.total || 0
    );

    yield put({
      type: actionTypes.procedureEvents.success,
      payload: {
        total: { [procedureId]: totalPerProcedure },
        events: [...events, ...pes.data],
        counts: { [procedureId]: counts },
      },
    });
  } catch (error) {
    yield put({ type: actionTypes.procedureEvents.failed, payload: error });
  }
}

export function* getProcedureEvents() {
  yield takeEvery(
    actionTypes.procedureEvents.requested,
    getProcedureEventsSaga
  );
}
