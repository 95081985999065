import fetchSimCards from './fetchSimCards';
import fetchSimCard from './fetchSimCard';
import createSimCard from './createSimCard';
import updateSimCard from './updateSimCard';
import updateSimCards from './updateSimCards';

export default [
  fetchSimCards,
  fetchSimCard,
  createSimCard,
  updateSimCard,
  updateSimCards,
];
