import ListIcon from '@material-ui/icons/List';
import HomeIcon from '@material-ui/icons/Home';
import MonitoringIcon from '@material-ui/icons/DvrTwoTone';
import Users from '@material-ui/icons/AccountCircleTwoTone';
import Dashboard from '@material-ui/icons/DashboardTwoTone';
import ProceduresIcon from '@material-ui/icons/GroupTwoTone';
import ReportIcon from '@material-ui/icons/DescriptionTwoTone';
import PhoneLinkRing from '@material-ui/icons/PhonelinkRingTwoTone';
import PhonelinkSetupTwoTone from '@material-ui/icons/PhonelinkSetupTwoTone';

import { stack } from 'common/constants/path';
import { commonRoles } from 'common/constants/enums';
import { formatPath } from 'common/utils/helpers/url';
import { isNotProcedureUrl } from 'common/utils/helpers/menu';
import SearchCreate from 'common/components/icons/SearchCreate';

import counterFetchParams from './counterParams';

const isProcedureMonitoringHidden = ({ url, role }) => {
  const isNotUrl = isNotProcedureUrl({ url });
  const isPhysician = role === commonRoles.physician;
  const isContact = role === commonRoles.contact;

  return isNotUrl || isContact || isPhysician;
};

export const monitoringMenuStructure = [
  {
    title: 'Procedure Study Trend',
    url: ({ id }) =>
      formatPath(stack.private.procedures.monitoring.index.url, { id }),
    isLink: true,
    icon: MonitoringIcon,
    isHidden: isProcedureMonitoringHidden,
    nested: [
      {
        title: 'Triage Escalated',
        key: 'monitoring-triage-escalated',
        counterFetchParams: counterFetchParams.singleEscalated,
        url: ({ id }) =>
          formatPath(stack.private.procedures.triages.escalated.url, { id }),
      },
      {
        title: 'Triage Common',
        key: 'monitoring-triage-common',
        counterFetchParams: counterFetchParams.singleCommon,
        url: ({ id }) =>
          formatPath(stack.private.procedures.triages.common.url, { id }),
      },
    ],
  },
];

const adminMenu = [
  {
    ...stack.private.dashboard,
    icon: Dashboard,
    isLink: true,
    nested: [],
  },
  ...monitoringMenuStructure,
  {
    url: 'monitoring',
    title: 'Monitoring',
    icon: MonitoringIcon,
    nested: [
      {
        title: 'Escalated',
        url: stack.private.triages.escalated.url,
        counterFetchParams: counterFetchParams.escalated,
      },
      {
        title: 'Escalated List',
        url: stack.private.triages.escalatedList.url,
      },
      {
        title: 'Common',
        url: stack.private.triages.common.url,
        counterFetchParams: counterFetchParams.common,
      },
      {
        title: 'Locked',
        counterFetchParams: counterFetchParams.locked,
        url: stack.private.procedures.lockedProcedures.url,
        restrictions: [
          commonRoles.admin,
          commonRoles.superAdmin,
          commonRoles.technician,
        ],
      },
    ],
  },
  {
    url: 'Reports',
    title: 'Reports',
    icon: ReportIcon,
    nested: [
      {
        title: 'First Day',
        counterFetchParams: counterFetchParams.firstDay,
        url: stack.private.procedures.firstDayReports.url,
      },
      {
        title: 'Ongoing',
        counterFetchParams: counterFetchParams.daily,
        url: stack.private.procedures.dailyReports.url,
      },
      {
        title: 'Final',
        counterFetchParams: counterFetchParams.finalDay,
        url: stack.private.procedures.lastDayReports.url,
      },
    ],
  },
  {
    url: 'procedures',
    title: 'Procedures',
    icon: ProceduresIcon,
    nested: [
      {
        title: 'Unknown',
        counterFetchParams: counterFetchParams.unknown,
        url: stack.private.procedures.unknownPatients.url,
      },
      {
        title: 'Late Activity',
        counterFetchParams: counterFetchParams.lateActivity,
        url: stack.private.procedures.lateActivityEvents.url,
      },
      {
        title: 'All',
        url: stack.private.procedures.list.url,
      },
    ],
  },
  {
    url: 'physicians',
    title: 'Search/Create',
    icon: SearchCreate,
    nested: [
      {
        title: 'Patients',
        url: stack.private.patients.list.url,
      },
      {
        title: 'Physicians',
        url: stack.private.physicians.list.url,
      },
    ],
  },
  {
    collapsable: true,
    url: 'inventory',
    title: 'Inventory',
    icon: PhoneLinkRing,
    nested: [
      {
        title: 'Phones',
        url: stack.private.phones.list.url,
      },
      {
        title: 'Sim Cards',
        url: stack.private.simCards.list.url,
      },
      {
        title: 'Patches',
        url: stack.private.patches.list.url,
      },
      {
        title: 'Kits',
        url: stack.private.kits.list.url,
      },
    ],
  },
  {
    icon: Users,
    title: 'Users',
    collapsable: true,
    url: 'users-roles',
    restrictions: [
      commonRoles.admin,
      commonRoles.superAdmin,
      commonRoles.technician,
    ],
    nested: [
      {
        title: 'All Organizations',
        url: stack.private.organizations.list.url,
        restrictions: [commonRoles.admin, commonRoles.superAdmin],
      },
      {
        title: 'Users',
        url: stack.private.users.list.url,
      },
    ],
  },
  {
    collapsable: false,
    ...stack.private.mobileAppResources,
    icon: PhonelinkSetupTwoTone,
    isLink: true,
    nested: [],
    restrictions: [commonRoles.superAdmin],
  },
];

export const technicianMenu = adminMenu.map((m) =>
  m.url === 'users-roles'
    ? {
        icon: Users,
        title: 'Users',
        url: stack.private.users.list.url,
        nested: [],
        isLink: true,
      }
    : m
);

export const physicianMenuStructure = [
  {
    url: stack.physician.index.url,
    title: 'Recent Procedures',
    icon: HomeIcon,
    isLink: true,
    nested: [],
  },
  {
    url: 'procedures',
    title: 'Procedures',
    icon: ListIcon,
    nested: [
      {
        title: 'All Procedures',
        url: stack.physician.procedures.all.url,
      },
    ],
  },
];

export const contactMenuStructure = [
  {
    url: stack.contact.index.url,
    title: 'Recent Procedures',
    icon: HomeIcon,
    isLink: true,
    nested: [],
  },
  {
    url: 'procedures',
    title: 'Procedures',
    icon: ListIcon,
    nested: [
      {
        title: 'All Procedures',
        url: stack.contact.procedures.all.url,
      },
    ],
  },
];

export const ecgTechMenu = [
  {
    url: 'monitoring',
    title: 'Monitoring',
    icon: MonitoringIcon,
    nested: [
      {
        title: 'Escalated',
        url: stack.private.triages.escalated.url,
        counterFetchParams: counterFetchParams.escalated,
      },
      {
        title: 'Escalated List',
        url: stack.private.triages.escalatedList.url,
      },
      {
        title: 'Common',
        url: stack.private.triages.common.url,
        counterFetchParams: counterFetchParams.common,
      },
      {
        title: 'Locked',
        counterFetchParams: counterFetchParams.locked,
        url: stack.private.procedures.lockedProcedures.url,
      },
    ],
  },
];

export default adminMenu;
