import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import useStyles from '../styles';

const PatientInfo = ({ userInfo, kitInfo, procedureInfo }) => {
  const classes = useStyles();
  const isFullNameNecessary = !userInfo.lastName && !userInfo.firstName;
  const userName = isFullNameNecessary
    ? userInfo.fullName
    : `${userInfo.lastName},  ${userInfo.firstName}`;

  return (
    <Grid container direction="column">
      <Grid item container justifyContent="space-between">
        <Grid item>
          <Typography variant="h6" className={classes.headSectionData}>
            {procedureInfo?.name || userName || 'Unknown'}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h6" className={classes.headSectionData}>
            {kitInfo.serialNumber}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container spacing={1}>
        <Grid item>
          <Typography variant="h6">
            {`DOB: ${userInfo.dateOfBirth ? userInfo.dateOfBirth : 'N/A'}`}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h6">{`(${userInfo.years})`}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="h6">{`(${userInfo.gender})`}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

PatientInfo.propTypes = {
  userInfo: PropTypes.objectOf(PropTypes.any),
  procedureInfo: PropTypes.objectOf(PropTypes.any),
  kitInfo: PropTypes.shape({
    serialNumber: PropTypes.string,
  }),
};

export default PatientInfo;
