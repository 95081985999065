import moment from 'moment';

import { getProcedureTotalDays } from 'common/utils/helpers/date';
import { dateFormat, dateTimeFormat } from 'common/constants/dateFormats';

class FormatData {
  constructor(procedure, patient, physician, kit) {
    this.procedure = procedure;
    this.patient = patient;
    this.physician = physician;
    this.kit = kit;
  }

  // eslint-disable-next-line class-methods-use-this
  formatDate = (date, pattern) => {
    if (!moment(date, true).isValid()) {
      return date;
    }

    return moment(date).format(pattern);
  };

  // eslint-disable-next-line class-methods-use-this
  formatGender = (gender) => (gender ? gender[0] : 'N/A');

  currentDay = (startDate, countOfDays) => {
    if (!moment(startDate, true).isValid()) {
      return '';
    }

    const now = moment();
    const days = this.countDays(startDate, now);

    return days >= countOfDays ? countOfDays : days;
  };

  // eslint-disable-next-line class-methods-use-this
  countDays = (startDate, endDate) => {
    if (
      !moment(startDate, true).isValid() ||
      !moment(endDate, true).isValid()
    ) {
      return 0;
    }

    return getProcedureTotalDays(startDate, endDate);
  };

  // eslint-disable-next-line class-methods-use-this
  getDiff = (from, unit = 'years') => {
    if (!from || !moment(from, true).isValid()) {
      return 0;
    }

    const now = moment();
    const start = moment(from);
    return now.diff(start, unit);
  };

  get userInfo() {
    const { patchId, startDate, endDate } = this.procedure;
    const {
      firstName,
      lastName,
      fullName,
      phone,
      phoneAlt,
      birthDate,
      gender,
    } = this.patient;

    const countOfDays = this.countDays(startDate, endDate);

    return {
      phone,
      phoneAlt,
      firstName,
      lastName,
      fullName,
      patchId,
      years: this.getDiff(birthDate),
      gender: this.formatGender(gender),
      dateOfBirth: this.formatDate(birthDate, dateFormat),
      startDate: this.formatDate(startDate, dateTimeFormat),
      endDate: this.formatDate(endDate, dateFormat),
      countDays: countOfDays,
      currentDay: this.currentDay(startDate, countOfDays),
    };
  }

  get activity() {
    const { lastActivity } = this.procedure;
    return {
      hours: this.getDiff(lastActivity, 'hours'),
      minutes: this.getDiff(lastActivity, 'minutes'),
    };
  }

  get reports() {
    return {
      reportsCountEmergent: this.procedure.reportsCountEmergent,
      reportsCountFirstDate: this.procedure.reportsCountFirstDate,
      reportsCountFinal: this.procedure.reportsCountFinal,
    };
  }

  get procedureInfo() {
    const { startDate, endDate, name } = this.procedure;
    const countOfDays = this.countDays(startDate, endDate);

    return {
      name,
      countOfDays,
      startDate: this.formatDate(startDate, dateTimeFormat),
      endDate: this.formatDate(endDate, dateFormat),
      shortStartDate: this.formatDate(startDate, dateFormat),
      shortEndDate: this.formatDate(endDate, dateFormat),
      currentDay: this.currentDay(startDate, countOfDays),
      physician: this.physician,
    };
  }

  get physicianInfo() {
    const { fullName } = this.physician;

    return { fullName };
  }

  get kitInfo() {
    const { serialNumber } = this.kit;

    return { serialNumber };
  }
}

export default FormatData;
