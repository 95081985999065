import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';

import TextInput from 'common/components/simpleInputs/TextInput';

const useStyles = makeStyles(({ palette, spacing, breakpoints }) => ({
  nativeInput: {
    backgroundColor: palette.background.primary,
  },
  label: {
    margin: 0,
    transform: 'translate(8px, 8px) scale(1) !important',
    [breakpoints.down(1500)]: {
      fontSize: 11.2,
      transform: 'translate(6px, 6px) scale(1) !important',
    },
  },
  selectRoot: {
    minWidth: 50,
    paddingTop: spacing(0.75),
    paddingBottom: spacing(0.75),
    backgroundColor: palette.background.primary,

    [breakpoints.down(1501)]: {
      fontSize: 11,
      minWidth: 45,
      maxWidth: 70,
      paddingTop: spacing(0.4),
      paddingBottom: spacing(0.4),

      '&.MuiSvgIcon-root': {
        width: 12,
        height: 12,
      },
    },

    [breakpoints.down(1380)]: {
      fontSize: 11,
      minWidth: 25,
      maxWidth: 40,
      paddingTop: spacing(0.4),
      paddingBottom: spacing(0.4),

      '&.MuiSvgIcon-root': {
        width: 12,
        height: 12,
      },
    },
  },
}));

const OptionSelector = ({
  name,
  onChange,
  options,
  disabled,
  value,
  label,
}) => {
  const classes = useStyles();

  const handleChange = (e, v) => onChange(v);

  return (
    <TextInput
      select
      size="small"
      name={name}
      label={label}
      value={value}
      options={options}
      disabled={disabled}
      onChange={handleChange}
      InputLabelProps={{
        shrink: false,
        classes: { root: classes.label },
      }}
      SelectProps={{ classes: { root: classes.selectRoot } }}
      InputProps={{ classes: { focused: classes.nativeInput } }}
    />
  );
};

export default OptionSelector;
