export default {
  success: 200,
  multipleChoices: 300,
  badRequest: 400,
  notAuthorized: 401,
  forbidden: 403,
  notFount: 404,
  unprocessableEntity: 422,
  get minSuccess() {
    return this.success;
  },
  get maxSuccess() {
    return this.multipleChoices - 1;
  },
};
