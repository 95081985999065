import moment from 'moment';
import { dateFormat } from 'common/constants/dateFormats';

import UpdatePasswordButton from '../components/UpdatePasswordButton';

const getDate = (params) => {
  const { value: date } = params;

  return date ? moment(date).format(dateFormat) : null;
};

export default [
  {
    accessor: 'fullName',
    Header: 'Name',
    width: 20,
  },
  {
    accessor: 'phone',
    Header: 'Phone',
    width: 20,
  },
  {
    accessor: 'email',
    Header: 'Email',
    width: 20,
  },
  {
    accessor: 'createdAt',
    Header: 'Added',
    width: 20,
    valueGetter: getDate,
  },
  {
    accessor: 'updPassBtn',
    Header: 'Change Password',
    width: 20,
    Cell: UpdatePasswordButton,
    notClickable: true,
  },
];
