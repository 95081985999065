import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles((theme) => ({
  loader: {
    backgroundColor: theme.palette.background.mainDark,
  },
  loaderSection: {
    backgroundColor: theme.palette.background.mainDarkLight,
  },
}));
