import { call, put, takeLatest } from 'redux-saga/effects';

import {
  templatesAccordingAbnormalities,
  abnormalityAccordingTemplates,
  abnormalityTypeEnum,
  templateTypes,
} from 'common/constants/ecgEnums';
import { requestSaga } from 'common/utils/generators';
import resources from 'common/constants/resources';
import dataProvider from 'store/dataProvider';
import { actionTypes } from '../reducer';

const defaultResponse = { data: [], total: 0 };

const getTemplatesCountFrom = ({ page, perPage }) => (page - 1) * perPage;
const getTemplatesPaginationParams = ({ page, perPage }) => {
  const start = (page - 1) * perPage;
  return { start, end: start + perPage };
};

function* getEventsSaga(action) {
  const { filter, ...params } = action.payload;
  const isPatient = filter.abnormalityTypes.includes(
    abnormalityTypeEnum.patient
  );
  const abnormalityTypes = filter.abnormalityTypes.filter(
    (abType) =>
      abType !== abnormalityTypeEnum.patient && !templateTypes.includes(abType)
  );

  const templateAbTypes = filter.abnormalityTypes.filter((abType) =>
    templateTypes.includes(abType)
  );

  try {
    const peResponse = isPatient
      ? yield call(dataProvider.getList, resources.healthData.patientEvents, {
          ...params,
          filter: {
            ...filter,
            reviewed: undefined,
            status: filter?.status || null,
            sortingOptions: undefined,
            abnormalityTypes: undefined,
          },
        })
      : defaultResponse;

    const templatesResponses = templateAbTypes.length
      ? yield call(
          dataProvider.getList,
          resources.eventGroup.templatesByPeriod,
          {
            filter: {
              ...filter,
              ...getTemplatesPaginationParams(params.pagination),
              abnormalityTypes: templateAbTypes
                .map((abT) => abnormalityAccordingTemplates[abT])
                .filter(Boolean),
            },
          }
        )
      : defaultResponse;

    const startCountFrom = getTemplatesCountFrom(params.pagination);

    const templates = templatesResponses.data.map((template, index) => ({
      ...template,
      sequenceNumber: index + startCountFrom + 1,
      abnormalityType:
        templatesAccordingAbnormalities[
          template.ecgEventGroups?.[0].abnormalityType
        ],
      similar: template.ecgEventGroups,
    }));

    const eventResponse = abnormalityTypes.length
      ? yield call(dataProvider.getList, resources.eventGroup.byPeriod, {
          ...params,
          filter: { ...filter, abnormalityTypes },
        })
      : defaultResponse;

    const peData = peResponse.data.map((pe) => ({
      abnormalityType: abnormalityTypeEnum.patient,
      procedureId: filter.procedureId,
      ...pe,
    }));

    const data = [...peData, ...eventResponse.data, ...templates];
    const total =
      peResponse.total + eventResponse.total + templatesResponses.total;

    yield put({
      type: actionTypes.get.success,
      payload: {
        data,
        total,
      },
    });
  } catch (error) {
    yield put({ type: actionTypes.get.failed, payload: error });
  }
}

export function* getEvents() {
  yield takeLatest(actionTypes.get.requested, getEventsSaga);
}

export function* getEventEcg() {
  yield requestSaga({
    request: dataProvider.getList,
    actionType: actionTypes.ecg,
    resource: resources.ecgData.byPeriod,
  });
}
