import { call, put, takeLatest } from 'redux-saga/effects';
import dataProvider from 'store/dataProvider';

import { presetsActionTypes } from './index';
import { resourcesAccordingToPresets } from '../constants';

const generatePresetsSaga = (method, actionType) =>
  function* presetsSaga({ entity, ...action }) {
    const url = resourcesAccordingToPresets[entity];
    const { payload, onSuccess, onError } = action;

    try {
      const { data } = yield call(method, url, { ...payload, params: payload });

      yield put({
        type: actionType.success,
        requestPayload: action.payload,
        requestAction: { ...action, entity },
        payload: data,
        entity,
      });

      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      yield put({
        type: actionType.failed,
        payload: error,
      });

      if (onError) {
        onError(error);
      }
    }
  };

function* removePresetsSaga({ entity, ...action }) {
  const url = resourcesAccordingToPresets[entity];
  const { payload, onSuccess, onError } = action;

  try {
    const { data } = yield call(dataProvider.deleteOne, url, payload);

    yield put({
      type: presetsActionTypes.remove.success,
      requestPayload: action.payload,
      requestAction: { ...action, entity },
      payload: data,
      entity,
    });

    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    yield put({
      type: presetsActionTypes.remove.failed,
      payload: error,
    });

    if (onError) {
      onError(error);
    }
  }
}

function* getSetPresetsForEntity() {
  yield takeLatest(
    presetsActionTypes.get.requested,
    generatePresetsSaga(dataProvider.getOne, presetsActionTypes.get)
  );

  yield takeLatest(
    presetsActionTypes.set.requested,
    generatePresetsSaga(dataProvider.updateOne, presetsActionTypes.set)
  );

  yield takeLatest(presetsActionTypes.remove.requested, removePresetsSaga);
}

export default [getSetPresetsForEntity];
