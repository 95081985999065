import Add from '@material-ui/icons/Add';

const getCreateButton = (onClick = () => null, props) => ({
  text: 'CREATE',
  to: typeof onClick === 'string' ? onClick : undefined,
  onClick: typeof onClick === 'function' ? onClick : () => null,
  icon: Add,
  size: 'medium',
  variant: 'text',
  ...props,
});

export const createCommonTableButtons = (onCreate, props) => [
  getCreateButton(onCreate, props),
];

export const getFilledColumnsCount = (totalCount, columnsCount) => {
  if (totalCount > columnsCount) {
    if (Math.floor(totalCount / columnsCount) <= 1) {
      return totalCount % columnsCount;
    }

    return columnsCount;
  }

  return 0;
};

export const sliceArrayIntoParts = (arr = [], blockSize = 1) => {
  const blocksAmount = Math.ceil(arr.length / blockSize);

  return Array.from({ length: blocksAmount }).map((item, idx) => {
    const nextId = blockSize * idx;
    return arr.slice(nextId, nextId + blockSize);
  });
};
