import createEnum from './createEnum';
import requestState from './requestState';

const createRequestActionTypes = (actionTypeKeys, prefix) => {
  const actionTypes = actionTypeKeys.reduce((acc, type) => {
    if (Array.isArray(type)) {
      const [typeName, ...state] = type;
      const additionalState = Object.fromEntries(
        state.map((name) => [name, null])
      );

      acc[typeName] = createEnum(
        {
          ...requestState,
          ...additionalState,
        },
        `${prefix}.${typeName}`
      );

      return acc;
    }

    acc[type] = createEnum(requestState, `${prefix}.${type}`);
    return acc;
  }, {});

  return createEnum(actionTypes, prefix);
};

export { createEnum, createRequestActionTypes };
