import moment from 'moment';

import { commonActionTypes } from 'common/ducks/actionTypes';
import { notifyActionTypes } from 'common/modules/Notification/ducks';
import unionBy from 'lodash/unionBy';

const initialState = {
  patientEvents: [],
  ecgEvents: [],
  ecgEventWithoutStatus: [],
  invertEcgQueries: [],
  bulkInvertEcgIds: [],
};

const finishUndoAction = 'undo.clearState';

const undoReducer = (state = initialState, action) => {
  switch (action.type) {
    case commonActionTypes.updatePatientEvents.requested: {
      if (!action.prevState) {
        return state;
      }

      return {
        ...state,
        patientEvents: action.prevState,
      };
    }

    case commonActionTypes.updateEcgEvents.requested: {
      if (!action.prevState) {
        return state;
      }

      return {
        ...state,
        ecgEvents: action.prevState,
      };
    }

    case commonActionTypes.updatePatientEvents.success: {
      if (!action.requestPayload.prevState) {
        return state;
      }

      const { data } = action.payload;

      return {
        ...state,
        patientEvents: state.patientEvents.map((event) => {
          const isSame = moment(event.date).isSame(data.date);

          if (isSame && !event?.id) {
            return {
              id: data.id,
              symptoms: [],
              procedureId: event.procedureId,
            };
          }

          return event;
        }),
      };
    }

    case commonActionTypes.updateEcgEvents.success: {
      if (!action.requestPayload.prevState) {
        return state;
      }

      const { data } = action.payload;

      return {
        ...state,
        ecgEvents: state.ecgEvents.map((event) => {
          if (event.id) {
            return event;
          }

          const responseEvent = data.find(
            ({ abnormalityType, dateFrom }) =>
              abnormalityType === event.abnormalityType &&
              moment(dateFrom).isSame(event.dateFrom)
          );

          if (!responseEvent) {
            return event;
          }

          return { ...event, newId: responseEvent?.id };
        }),
      };
    }

    case commonActionTypes.bulkReviewEcgEvents.success: {
      const { data } = action.payload;
      const ecgEvents = data.map(
        ({ prevAbnormalityType, prevStatus = null, id }) => ({
          id,
          status: prevStatus,
          abnormalityType: prevAbnormalityType,
        })
      );

      return {
        ...state,
        ecgEventWithoutStatus: unionBy(
          [...state.ecgEventWithoutStatus, ...ecgEvents],
          'id'
        ),
      };
    }

    case commonActionTypes.bulkStatusUpdate.success: {
      const { data } = action.payload;
      const ecgEvents = data.map(
        ({ prevAbnormalityType, prevStatus = null, id }) => ({
          id,
          status: prevStatus,
          abnormalityType: prevAbnormalityType,
        })
      );

      return {
        ...state,
        ecgEventWithoutStatus: ecgEvents,
      };
    }

    // invert ecg actions
    case commonActionTypes.setInvertEcg.requested: {
      const { query } = action.payload;

      const invertEcgQueries = [state.invertEcgQueries].flat();

      if (action.isShouldBeUndoable) {
        invertEcgQueries.push(query);
      }

      return { ...state, invertEcgQueries };
    }

    case notifyActionTypes.hide.requested:
    case finishUndoAction: {
      return initialState;
    }

    default:
      return state;
  }
};

export const clearUndoState = () => ({
  type: finishUndoAction,
});

export default undoReducer;
