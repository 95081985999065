import { normalize } from 'normalizr';
import jsonServerProvider from './jsonServerHelper';
import { httpClient } from './httpHelper';

export default ({
  apiBaseUrl,
  apiAuthUrl,
  permissionsUrl,
  saveTokens,
  clearTokens,
}) => {
  const jsonDataProvider = jsonServerProvider(
    apiBaseUrl,
    httpClient({ apiAuthUrl, permissionsUrl, saveTokens, clearTokens })
  );

  const listDataProviderMethods = Object.keys(jsonDataProvider);

  // newDataProvider
  return listDataProviderMethods.reduce(
    (prev, method) => ({
      ...prev,
      [method]: async (resource, params, schema) => {
        const { data, total } = await jsonDataProvider[method](
          resource,
          params
        );
        if (schema) {
          const { entities, result } = normalize(data, schema);
          return { entities, result, total, data };
        }

        return { data, total };
      },
    }),
    {}
  );
};
