import createEnum from 'common/utils/actionTypesHelper/createEnum';
import { abnormalityTypeEnum } from 'common/constants/ecgEnums';

export const eventGroupsOptionsEnum = createEnum({
  [abnormalityTypeEnum.vTachSvTach]: [
    { value: abnormalityTypeEnum.vTach, label: 'V-TACH' },
    { value: abnormalityTypeEnum.svTach, label: 'SV-TACH' },
  ],
  [abnormalityTypeEnum.blockOtherPe]: [
    { value: abnormalityTypeEnum.other, label: 'OTHER' },
    { value: abnormalityTypeEnum.block, label: 'BLOCK' },
    { value: abnormalityTypeEnum.patient, label: 'PE' },
  ],
});

export const headerHeights = {
  modal: 75,
  layout: 55,
};
