import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const SearchCreate = (props) => (
  <SvgIcon {...props} viewBox="0 0 54 45">
    <path d="M40.7237 45L23.4227 27.702C20.3001 29.762 16.524 30.5899 12.8259 30.0255C9.1278 29.461 5.7707 27.5444 3.40472 24.6466C1.03874 21.7489 -0.167776 18.0763 0.0188145 14.34C0.205405 10.6037 1.77183 7.06958 4.41474 4.422C7.06184 1.77809 10.596 0.210641 14.3326 0.0232472C18.0692 -0.164146 21.7424 1.04185 24.6407 3.40765C27.5391 5.77346 29.4563 9.1307 30.0211 12.8291C30.5858 16.5276 29.7579 20.304 27.6977 23.427L44.9927 40.728L40.7237 45ZM15.0947 6.042C12.6919 6.04041 10.3868 6.99342 8.68659 8.69137C6.98639 10.3893 6.03033 12.6931 6.02874 15.096C6.02715 17.4989 6.98016 19.8039 8.67811 21.5041C10.3761 23.2043 12.6799 24.1604 15.0827 24.162C17.4856 24.1636 19.7907 23.2106 21.4909 21.5126C23.1911 19.8147 24.1471 17.5109 24.1487 15.108C24.1503 12.7051 23.1973 10.4001 21.4994 8.69986C19.8014 6.99965 17.4976 6.04359 15.0947 6.042ZM47.9927 18H41.9927V12H35.9927V6H41.9927V0H47.9927V6H53.9927V12H47.9927V18Z" />
  </SvgIcon>
);

export default SearchCreate;
