import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import {
  groupMessageByType,
  eventGroupMessageMapper,
  patientEventMessageMapper,
} from 'common/utils/helpers/socket';
import { useSocketHub } from 'common/hooks';
import { ecgEventStatus } from 'common/constants/enums';
import { entities } from 'common/constants/socketConstants';

import * as actions from '../ducks/reducer';

const defaultParams = {
  keepAlive: true,
  withAdded: true,
  withRemoved: true,
  withUpdated: true,
  onDeselect: () => null,
};

const useSocketUpdates = (
  procedureId,
  params,
  updateAction = actions.updateEvents
) => {
  const dispatch = useDispatch();

  const socketParams = useMemo(
    () => ({
      ...defaultParams,
      ...params,
    }),
    [params]
  );

  const {
    status,
    getTotal,
    onDeselect,
    keepAlive,
    withAdded,
    withRemoved,
    withUpdated,
    abnormalityType,
    eventAbnormalities,
    withoutNewAbnormality,
  } = socketParams;

  const subscriptionPayload = useMemo(
    () => (procedureId ? [procedureId] : null),
    [procedureId]
  );

  const onMessage = useCallback(
    (message) => {
      const { isPatientEvent, payload } = groupMessageByType(message);

      const updatePayload = {
        status,
        abnormalityType,
        eventAbnormalities,
        reviewed: !!status?.length,
        isDenied: (status || []).includes(ecgEventStatus.denied),
      };

      const payloadEvents = payload.filter(
        (event) => event.procedureId === procedureId
      );

      const { added, updated, removed } = isPatientEvent
        ? patientEventMessageMapper(payloadEvents)
        : eventGroupMessageMapper(payloadEvents);

      if (!payloadEvents.length) return;

      const isOtherEvents = Boolean(updated.length || removed.length);
      const shouldUpdate = !withAdded ? isOtherEvents && !added.length : true;

      if (getTotal && (shouldUpdate || isPatientEvent)) {
        getTotal();
      }

      updatePayload.added = withAdded ? added : [];

      if (withoutNewAbnormality && withAdded) {
        updatePayload.added = updatePayload.added.filter((event) => {
          if (Array.isArray(withoutNewAbnormality)) {
            return !withoutNewAbnormality.includes(event.abnormalityType);
          }

          return withoutNewAbnormality !== event.abnormalityType;
        });
      }

      updatePayload.updated = withUpdated ? updated : [];
      updatePayload.removed = withRemoved ? removed : [];

      dispatch(updateAction(updatePayload));

      onDeselect([...updated, ...removed].map((ev) => ev.id));
    },
    [
      status,
      getTotal,
      dispatch,
      withAdded,
      onDeselect,
      procedureId,
      withUpdated,
      withRemoved,
      updateAction,
      abnormalityType,
      eventAbnormalities,
      withoutNewAbnormality,
    ]
  );

  useSocketHub(entities.procedure, subscriptionPayload, onMessage, keepAlive);
};

export default useSocketUpdates;
