import httpCodes from './httpCodes';

export const clearTokens = (keyForException = []) => {
  const localStorageKeys = Object.keys(localStorage);

  localStorageKeys.forEach((key) => {
    if (keyForException.includes(key)) return;
    delete localStorage[key];
  });
};

export const saveTokens = (data = {}) => {
  Object.entries(data).forEach(([key, value]) => {
    if (key.toLowerCase().includes('token') && key !== 'refreshToken') {
      localStorage.accessToken = value;
      return;
    }

    localStorage[key] = value;
  });
};

export const hasTokens = () =>
  !!localStorage.accessToken && !!localStorage.username;

export const getHeaders = (authenticated) => {
  const headers = new Headers({ 'Content-Type': 'application/json' });
  if (authenticated)
    headers.set('Authorization', `Bearer ${localStorage.accessToken}`);

  return headers;
};

let newTokenRequest;

export const reauthenticateIfNeeded = async (
  { apiAuthUrl },
  status = httpCodes.notAuthorized,
  { clearTokensData = clearTokens, saveTokensData = saveTokens }
) => {
  try {
    if (status !== httpCodes.notAuthorized || !localStorage.accessToken)
      return false;
    if (newTokenRequest) {
      return newTokenRequest;
    }

    // eslint-disable-next-line no-async-promise-executor
    newTokenRequest = new Promise(async (resolve) => {
      const { refreshToken, accessToken } = localStorage;

      const response = await fetch(apiAuthUrl, {
        method: 'PUT',
        body: JSON.stringify({ refreshToken, accessToken }),
        headers: getHeaders(true),
      });

      if (
        response.status < httpCodes.minSuccess ||
        response.status > httpCodes.maxSuccess
      ) {
        clearTokensData();
        window.location.href = '/';
        resolve(false);
        newTokenRequest = null;
        return;
      }

      const data = await response.json();
      saveTokensData(data);
      resolve(data);
      newTokenRequest = null;
    });

    return newTokenRequest;
  } catch (e) {
    console.error('Update token error', e?.message);
    return null;
  }
};

export const getValueFromToken = (token = localStorage.accessToken) => {
  if (!token) {
    return null;
  }

  return JSON.parse(window.atob(token.split('.')[1]));
};

export const getPermissions = () => {
  const role = localStorage.getItem('role');
  const permissions = localStorage.getItem('permissions');
  return role ? Promise.resolve({ role, list: permissions }) : Promise.reject();
};
