import moment from 'moment/moment';

export const isReversedRange = (start, end) => {
  if (!start || !end) return false;

  return moment(start).isSameOrAfter(end);
};

export const getCenterDateFromRange = (ecgData, startDate, endDate) => {
  const iterator = isReversedRange(startDate, endDate)
    ? ({ time }) =>
        moment(time).isSameOrBefore(startDate) &&
        moment(time).isSameOrAfter(endDate)
    : ({ time }) =>
        moment(time).isSameOrAfter(startDate) &&
        moment(time).isSameOrBefore(endDate);

  const ecgByRange = ecgData.filter(iterator);

  const centerIndex = Math.round(ecgByRange.length / 2);

  return new Date(ecgByRange[centerIndex].time);
};

export const getEcgEventsForPE = (patientEvents, range) =>
  patientEvents.find(({ date }) => moment(date).isBetween(...(range || [])));

export const getEcgEventsInRange = (events, range) =>
  events.filter(({ dateFrom, dateTo, duration: dur }) => {
    if (dur === 0) {
      return (
        moment(range[0]).isBefore(dateFrom) &&
        moment(range[1]).isAfter(dateFrom)
      );
    }

    return (
      (moment(dateFrom).isBefore(range[1]) &&
        moment(dateTo).isAfter(range[0])) ||
      (moment(dateTo).isAfter(range[0]) && moment(dateFrom).isBefore(range[0]))
    );
  });

export const getIsEventInRange = (event, range) => {
  if (!event || !range) {
    return null;
  }

  const isInRange = moment(
    event.time || event.dateFromEvent || event.dateFrom
  ).isBetween(range[0], range[1], 'second', '[]');

  return isInRange && event;
};

export const findIndexPrevOrNextEvent = (time, eventId, events) => {
  if (events?.length <= 1) {
    return {};
  }

  const index = events.findIndex(({ id }) => id === eventId);

  const prevIndex = index < 0 ? index : index - 1;
  const nextIndex = index < 0 ? index : index + 1;
  let prevEvent = events[prevIndex];
  let nextEvent = events[nextIndex];

  if (!nextEvent) {
    const newIdx = events.findIndex(({ date, dateFrom, dateFromEvent }) =>
      moment(date || dateFrom || dateFromEvent).isAfter(time)
    );

    nextEvent = events[newIdx];
  }

  if (!prevEvent) {
    const reversedEvents = [...events].reverse();

    const newIdx = reversedEvents.findIndex(
      ({ date, dateFrom, dateFromEvent }) =>
        moment(date || dateFrom || dateFromEvent).isBefore(time)
    );

    prevEvent = reversedEvents[newIdx];
  }

  return { prevEvent, nextEvent };
};
