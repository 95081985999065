import moment from 'moment';
import groupBy from 'lodash/groupBy';
import { call, put, takeEvery, all } from 'redux-saga/effects';

import {
  getEventGraphCenterDate,
  getActivityAndPositionAverage,
} from 'common/utils/helpers/graphs';
import dataProvider from 'store/dataProvider';
import resources from 'common/constants/resources';
import { hrGroupTypes } from 'common/constants/enums';
import { getCenterBetweenDatesUTC } from 'common/utils/helpers/date';
import {
  ecgTypeNames,
  ecgTypeLabels,
  abnormalityTypeEnum,
} from 'common/constants/ecgEnums';

import { actionTypes } from '../reducer';
import { orderedListOfEvents } from '../../constants';

const getEcg = (payload) =>
  dataProvider.getList(resources.ecgData.byPeriod, payload);

const getBeats = (payload) =>
  dataProvider.get(resources.healthData.stripBeats, payload);

const getSensorData = async (date, procedureId) => {
  const dateFrom = moment(date).subtract(5, 'seconds').toISOString();
  const dateTo = moment(date).add(5, 'seconds').toISOString();

  const filter = { dateFrom, dateTo, procedureId };

  const resp = await dataProvider.get(resources.sensorData.index, { filter });
  const {
    activities: stripActivities,
    temperatures: stripTemperatures,
    bodyPositions: stripBodyPosition,
  } = resp?.data || {};

  const tempAvg =
    (stripTemperatures || []).reduce((acc, item) => acc + item.value, 0) /
    (stripTemperatures?.length || 1);
  const groupedActivity = groupBy(stripActivities, 'value');
  const groupedPosition = groupBy(stripBodyPosition, 'value');

  const skinTemp = tempAvg ? `${tempAvg.toFixed(1)} F` : 'N/A';
  const activity = getActivityAndPositionAverage(groupedActivity);
  const position = getActivityAndPositionAverage(groupedPosition);

  return { skinTemp, activity, position };
};

const getEcgData = (procedureId) =>
  function* ecgSaga([dateFrom, dateTo, eventDateFrom], id, part = null) {
    const { data: ecg } = yield call(getEcg, {
      filter: { dateFrom, dateTo, procedureId, precision: 25 },
    });

    const bCenter = moment(getCenterBetweenDatesUTC(dateFrom, dateTo));
    const bFrom = bCenter.clone().subtract(12, 'seconds').toISOString();
    const bTo = bCenter.clone().add(12, 'seconds').toISOString();

    const { data: beats } = yield call(getBeats, {
      filter: {
        dateFrom: bFrom,
        dateTo: bTo,
        procedureId,
        checkBeatsCount: false,
        groupType: hrGroupTypes.none,
      },
    });

    const sensorData = yield call(getSensorData, eventDateFrom, procedureId);

    return { ecg, id, part, beats, sensorData };
  };

const extend = (ev, counter, title, subtitle, type = 'Strip') => ({
  ...ev,
  hrSummary: { average: ev.avgHeartRate },
  label: {
    type,
    subtitle,
    number: `# ${counter}`,
    title: title || ecgTypeLabels[ev.abnormalityType],
  },
});

// utils
const getRange = (centerDate, leftDuration, rightDuration = 0) => {
  const date = moment(centerDate);

  return [
    date.clone().subtract(leftDuration, 'seconds').toISOString(),
    date
      .clone()
      .add(rightDuration || leftDuration, 'seconds')
      .toISOString(),
  ];
};

function* getOngoingEventList(action) {
  const {
    payload: { pe, evs, procedureId },
    date,
  } = action;
  const ecgRequester = getEcgData(procedureId);

  try {
    // Global Counter
    let stripCounter = 0;

    // PE Section
    const peEventEcgRequests = pe.map((event) => {
      const centerDate = getEventGraphCenterDate(event);

      const range = getRange(centerDate, 20, 20);

      return ecgRequester([...range, event.dateFrom], event.id);
    });

    const ecgs = yield all(peEventEcgRequests);

    const peWithEvents = pe
      .map((peEvent) => {
        const { ecg, beats, sensorData } = ecgs.find(
          ({ id }) => id === peEvent.id
        );
        return {
          ...peEvent,
          ...sensorData,
          beats,
          ecg,
        };
      })
      .map((peEvent) => {
        stripCounter += 1;
        const restEvents = (peEvent?.ecgEventGroups || []).filter(
          ({ id }) => id !== peEvent.ecgEventId
        );
        const multi = restEvents.length > 0;
        const multiTitle = multi ? ',' : '';

        const type = peEvent?.abnormalityType
          ? ecgTypeNames[peEvent.abnormalityType]
          : '';
        const title = `${type}${multiTitle}`;

        const eventTypes = restEvents
          .map((ev) => ev?.abnormalityType && ecgTypeLabels[ev.abnormalityType])
          .filter(Boolean)
          .join(', ');

        const subtitle = multi ? `w ${eventTypes}` : '';

        return extend(peEvent, stripCounter, title, subtitle, 'Event');
      });

    // AFIB Section
    const afibParts = evs
      .filter((ev) => ev.abnormalityType === abnormalityTypeEnum.afib)
      .map((ev) => {
        const onset = {
          ...ev,
          part: 'onset',
          range: getRange(ev.dateFrom, 20),
        };

        const centerDate = getEventGraphCenterDate(ev);

        const center = {
          ...ev,
          part: 'center',
          dateTo: centerDate,
          dateFrom: centerDate,
          range: getRange(centerDate, 20),
        };

        const offset = {
          ...ev,
          part: 'offset',
          dateFrom: ev.dateTo,
          range: getRange(ev.dateTo, 20),
        };

        return [onset, center, offset];
      });

    const afibPartEcgRequest = afibParts
      .map((parts) =>
        parts.map((part) => {
          const { id, range } = part;

          return ecgRequester([...range, part.dateFrom], id, part.part);
        })
      )
      .flat();

    const afibEcgs = yield all(afibPartEcgRequest);

    const afibPartsWithData = afibParts.map((event) => {
      return event.map((part, index) => {
        stripCounter += 1;

        const { ecg, beats, sensorData } = afibEcgs.find(
          (afibEcg) => afibEcg.id === part.id && afibEcg.part === part.part
        );

        const withEcg = { ...part, ecg, beats, ...sensorData };

        const isTitle = index % 2 === 0;
        const isOnset = index === 0;

        const ecgType = ecgTypeLabels[withEcg.abnormalityType];
        const postTitle = (isTitle && (isOnset ? 'Onset' : 'Offset')) || '';
        const title = `${ecgType} ${postTitle}`;

        return extend(withEcg, stripCounter, title, part.note);
      });
    });

    // Common Section
    const commonEvents = evs
      .filter(
        (ev) =>
          ev.abnormalityType !== abnormalityTypeEnum.afib &&
          ev.abnormalityType !== abnormalityTypeEnum.NSR
      )
      .map((ev) => {
        const centerDate = getEventGraphCenterDate(ev);

        return {
          ...ev,
          range: getRange(centerDate, 24.5, 15.5),
        };
      });

    const commonEventRequests = commonEvents.map((event) => {
      const { id, range } = event;

      return ecgRequester([...range, event.dateFrom], id);
    });

    const commonEcgs = yield all(commonEventRequests);

    const grouped = groupBy(commonEvents, 'abnormalityType');

    const commonEventsWithData = orderedListOfEvents.reduce((acc, abType) => {
      const currentEvents = grouped[abType];
      return {
        ...acc,
        [abType]:
          currentEvents &&
          currentEvents.map((event) => {
            stripCounter += 1;

            const { ecg, beats, sensorData } = commonEcgs.find(
              ({ id }) => id === event.id
            );
            const eventWithEcg = { ...event, ecg, beats, ...sensorData };

            return extend(eventWithEcg, stripCounter, undefined, event.note);
          }),
      };
    }, {});

    yield put({
      type: actionTypes.getOngoingEventList.success,
      payload: {
        [abnormalityTypeEnum.patient]: peWithEvents,
        [abnormalityTypeEnum.afib]: afibPartsWithData,
        ...commonEventsWithData,
      },
      requestPayload: { ...action.payload, date },
    });
  } catch ({ message }) {
    yield put({
      type: actionTypes.getOngoingEventList.failed,
      message,
    });
  }
}

export default function* getOngoingEventListSaga() {
  yield takeEvery(
    actionTypes.getOngoingEventList.requested,
    getOngoingEventList
  );
}
