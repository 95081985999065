import { requestSaga } from 'common/utils/generators';
import resources from 'common/constants/resources';
import dataProvider from 'store/dataProvider';
import { procedure } from 'common/constants/schemas';
import { actionTypes } from '../index';

export function* procedureInfoSaga() {
  yield requestSaga({
    actionType: actionTypes.getProcedure,
    request: dataProvider.getOne,
    resource: resources.procedure.item,
    schema: procedure,
  });
}

export function* updateNotes() {
  yield requestSaga({
    actionType: actionTypes.updateNotes,
    request: dataProvider.updateOne,
    resource: resources.procedure.notes,
  });
}
