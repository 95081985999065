import unionBy from 'lodash/unionBy';

import { createRequestActionTypes } from 'common/utils/actionTypesHelper';
import { requestReducer } from 'common/utils/generators';

export const patientsActionTypes = createRequestActionTypes(
  ['getList', 'getOne', 'update', 'updateMany', 'updateStatus', 'create'],
  'patients'
);

const initialState = {
  loading: false,
  data: [],
  total: 0,
  error: false,
};

export default requestReducer(
  [
    patientsActionTypes.getList,
    patientsActionTypes.getOne,
    patientsActionTypes.update,
  ],
  initialState,
  (payload, state) => {
    const { data, total } = payload;
    return {
      ...state,
      loading: false,
      data: Array.isArray(data) ? data : unionBy([data], state.data, 'id'),
      total,
    };
  }
);

export const getPatientsList = (payload) => ({
  type: patientsActionTypes.getList.requested,
  payload,
});

export const updatePatients = (payload, onSuccess, onError) => ({
  type: patientsActionTypes.updateMany.requested,
  payload,
  onSuccess,
  onError,
});

export const updatePatient = (payload, onSuccess, onError) => ({
  type: patientsActionTypes.update.requested,
  payload,
  onSuccess,
  onError,
});

export const updatePatientStatus = (payload, onSuccess, onError) => ({
  type: patientsActionTypes.updateStatus.requested,
  payload,
  onSuccess,
  onError,
});

export const getPatient = (id) => ({
  type: patientsActionTypes.getOne.requested,
  payload: { id },
});

export const createPatient = (payload, onSuccess, onError) => ({
  type: patientsActionTypes.create.requested,
  payload,
  onSuccess,
  onError,
});
