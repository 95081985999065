import { useRef, useEffect, useCallback } from 'react';
import debounce from 'lodash/debounce';

import useAuthProvider from 'store/authProvider/useAuthProvider';
import { isDevelop } from 'common/utils/helpers/remoteLogger';

const EVENTS = [
  'click',
  'dblclick',
  'mousewheel',
  'contextmenu',

  'touchstart',
  'touchmove',

  'keypress',

  'scroll',
  'resize',
  'hashchange',
  'load',
];

// hours * minutes per hour * seconds per minute * converting to ms
const LOGOUT_DELAY = 8 * 60 * 60 * 1000;

const useActivityHandler = (ref) => {
  const elementRef = useRef(ref);
  const authProvider = useAuthProvider();
  const previousTimerId = useRef(null);

  const logoutMethod = useCallback(() => authProvider.logout(), [authProvider]);

  const eventHandler = useCallback(() => {
    if (previousTimerId.current) {
      clearTimeout(previousTimerId.current);
    }

    previousTimerId.current = setTimeout(() => {
      if (isDevelop) return;
      logoutMethod();
    }, LOGOUT_DELAY);
  }, [logoutMethod]);

  const debouncedEventHandler = debounce(eventHandler, 300);

  const initListeners = useCallback(() => {
    EVENTS.forEach((eventName) => {
      elementRef.current.addEventListener(eventName, debouncedEventHandler);
    });

    return () => {
      EVENTS.forEach((eventName) => {
        elementRef.current.removeEventListener(
          eventName,
          debouncedEventHandler
        );
      });
    };
  }, [elementRef, debouncedEventHandler]);

  useEffect(() => {
    if (!ref) return null;

    elementRef.current = ref;
    eventHandler();

    return initListeners();
  }, [ref, initListeners, eventHandler]);
};

export default useActivityHandler;
