import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import makeStyles from '@material-ui/styles/makeStyles';

import { autocompleteReasons } from 'common/constants/enums';
import AutocompleteInput from '../../simpleInputs/AutocompleteInput';

const sanitizeProp = ({ optionValueName, searchKey, ...props }) => props;

const useStyles = makeStyles(
  {
    inputRoot: {
      flexWrap: ({ open }) => (open ? 'wrap' : 'nowrap'),
    },
    input: {
      opacity: ({ value, open }) => (value && !open ? 0 : 1),
    },
    tag: {
      fontSize: '0.6rem',
    },
  },
  { name: 'FilterAutocomplete' }
);

const FilterAutocomplete = ({
  value = [],
  onChange = () => null,
  limitTags = 1,
  ...rest
}) => {
  const props = sanitizeProp(rest);
  const loading = useSelector((store) => store.tableFilters.loading);
  const [anchorEl, setAnchorEl] = useState(null);
  const [pendingValue, setPendingValue] = useState([]);

  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);
  const classes = useStyles({ open, value: value?.length });

  const currentValue = useMemo(() => {
    if (open) {
      return pendingValue;
    }

    return value;
  }, [open, pendingValue, value]);

  const handleFocus = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event, reason) => {
    if (reason === autocompleteReasons.toggleInput) {
      return;
    }
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
    onChange({}, pendingValue);
  };

  const handleChange = (event, newValue, reason, optionData) => {
    if (reason === autocompleteReasons.clear) {
      setPendingValue([]);
      return onChange(event, []);
    }

    if (reason === autocompleteReasons.removeOption && !open) {
      const newValues = value.filter(
        (selectedValue) => selectedValue !== optionData?.option
      );

      setPendingValue(newValues);
      return onChange(event, newValues);
    }

    return setPendingValue(newValue);
  };

  return (
    <AutocompleteInput
      {...props}
      open={open}
      disableCloseOnSelect
      filterSelectedOptions
      limitTags={limitTags}
      value={currentValue}
      onOpen={setAnchorEl}
      onFocus={handleFocus}
      onClose={handleClose}
      onChange={handleChange}
      getOptionSelected={(option, optionValue) => option === optionValue}
      loading={loading}
      classes={classes}
    />
  );
};

export default FilterAutocomplete;
