import React from 'react';

import LocalPaginatedGrid from './LocalPaginatedGrid';
import ServerPaginatedGrid from './ServerPaginatedGrid';
import IsolatedLocalPaginatedGrid from './IsolatedLocalPaginatedGrid';

const COLUMNS_AMOUNT = 7;

const EventsGrid = ({
  serverPagination = false,
  requestPayload = null,
  ...props
}) => {
  if (!serverPagination && requestPayload) {
    return (
      <IsolatedLocalPaginatedGrid {...props} requestPayload={requestPayload} />
    );
  }

  const GridComponent = serverPagination
    ? ServerPaginatedGrid
    : LocalPaginatedGrid;

  return <GridComponent {...props} />;
};

EventsGrid.defaultProps = {
  columnsAmount: COLUMNS_AMOUNT,
};

export default EventsGrid;
