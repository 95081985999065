import unionBy from 'lodash/unionBy';
import { requestReducer } from 'common/utils/generators';
import { createRequestActionTypes } from 'common/utils/actionTypesHelper';

export const actionTypes = createRequestActionTypes(
  ['getLoggedIn', 'filter', 'login', 'clearState'],
  'organizationSelector'
);

export const initialState = {
  data: [],
  loading: false,
  error: null,
};

const onRequestSuccess = (payload, state) => {
  const listObject = (payload.data ? [payload.data] : []).filter(Boolean);

  return {
    ...state,
    total: payload.total,
    init: Array.isArray(payload.data) ? state.init : payload.data,
    data: Array.isArray(payload.data)
      ? payload.data
      : unionBy(listObject, state.data, 'id'),
  };
};

const organizationsSelector = requestReducer(
  [
    actionTypes.getLoggedIn,
    actionTypes.filter,
    actionTypes.login,
    actionTypes.clearState,
  ],
  initialState,
  onRequestSuccess
);

export const getSelectedOrganization = (payload, onSuccess) => ({
  type: actionTypes.getLoggedIn.requested,
  onSuccess,
  payload,
});
export const filterOrganizations = (payload, onSuccess) => ({
  type: actionTypes.filter.requested,
  onSuccess,
  payload,
});

export const loginToOrganization = (payload, onSuccess) => ({
  type: actionTypes.login.requested,
  onSuccess,
  payload,
});

export const clearTenantState = () => ({
  type: actionTypes.clearState.success,
  payload: { data: null },
});

export default organizationsSelector;
