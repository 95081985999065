import moment from 'moment';

const DATE_FORMAT = 'L';

const getLastProcedureDate = ({ value }) =>
  value && moment(value).format(DATE_FORMAT);

const getDate = (params) => {
  const { value } = params;
  return value && moment(value).format(DATE_FORMAT);
};

export const columns = [
  {
    accessor: 'fullName',
    Header: 'Name',
    width: 18,
  },
  {
    accessor: 'birthDate',
    Header: 'DOB',
    width: 10,
    valueGetter: getDate,
  },
  { accessor: 'phone', Header: 'Phone', width: 11 },
  {
    accessor: 'city',
    Header: 'City',
    width: 10,
  },
  {
    accessor: 'state',
    Header: 'State',
    width: 10,
  },
  {
    accessor: 'lastProcedure.startDate',
    Header: 'Last Procedure',
    width: 18,
    valueGetter: getLastProcedureDate,
  },
  { accessor: 'kitSerialNumber', Header: 'Last Monitoring Kit', width: 13 },
  {
    accessor: 'status',
    Header: 'Status',
    width: 10,
  },
];

export const physicianPatientColumns = [
  {
    accessor: 'fullName',
    Header: 'Name',
    width: 13,
  },
  {
    accessor: 'birthDate',
    Header: 'DOB',
    width: 10,
    valueGetter: getDate,
  },
  {
    accessor: 'lastProcedure.startDate',
    Header: 'Last Procedure',
    width: 10,
    valueGetter: getLastProcedureDate,
  },
  {
    accessor: 'lastReportDate',
    Header: 'Last Report',
    width: 10,
  },
  {
    accessor: 'status',
    Header: 'Status',
    width: 10,
  },
];
