import { createSelector } from 'reselect';
import get from 'lodash/get';

export const getProcedures = (store) => get(store, 'entities.procedures', {});
export const getPatients = (store) => get(store, 'entities.patients', {});
export const getKits = (store) => get(store, 'entities.kits', {});
export const getPhysicians = (store) => get(store, 'entities.physicians', {});

const simpleSelector = (id) => (entities) => get(entities, id);

const multiSelector =
  (ids = []) =>
  (entities) =>
    Object.entries(entities)
      .filter(([id]) => ids.includes(id))
      .map(([, entity]) => entity);

export const procedureSelector = (id) =>
  createSelector(getProcedures, simpleSelector(id));

export const proceduresSelector = (ids = []) =>
  createSelector(getProcedures, multiSelector(ids));

export const kitSelector = (id) => createSelector(getKits, simpleSelector(id));
export const kitsSelector = (ids) =>
  createSelector(getKits, multiSelector(ids));

export const patientSelector = (id) =>
  createSelector(getPatients, simpleSelector(id));
export const patientsSelector = (ids) =>
  createSelector(getPatients, multiSelector(ids));

export const physicianSelector = (id) =>
  createSelector(getPhysicians, simpleSelector(id));
export const physiciansSelector = (ids) =>
  createSelector(getPhysicians, multiSelector(ids));

export const getProcedure = (id) =>
  createSelector(
    (store) => store.entities?.procedures,
    (procedures) => procedures?.[id]
  );

export const getPatient = (id) =>
  createSelector(
    (store) => store.entities?.patients,
    (patients) => patients?.[id]
  );

export const getPhysician = (id) =>
  createSelector(
    (store) => store.entities?.physicians,
    (physicians) => physicians?.[id]
  );

export const getKit = (id) => createSelector(getKits, (kits) => kits[id]);
