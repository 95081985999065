import React, { useCallback, useEffect, useMemo, useState } from 'react';
import orderBy from 'lodash/orderBy';
import moment from 'moment/moment';
import * as d3 from 'd3';

import {
  trendEventsByAxis,
  abnormalityTypeEnum,
} from 'common/constants/ecgEnums';
import { getFirstEqualOrAfterTimeIndex } from 'common/utils/helpers/date';
import Popper from './Popper';

const y = trendEventsByAxis[abnormalityTypeEnum.temperatures];

const TemperatureLine = ({ data = [], parent = {} }) => {
  const { xScale, yScale, clip = {}, scale } = parent;
  const [hoverData, setHoverData] = useState(null);

  const sx = useMemo(() => scale || xScale, [scale, xScale]);

  const lineData = useMemo(() => {
    const sortedTemps = orderBy(data, ['date'], 'asc');

    return sortedTemps.reduce((acc, item, index) => {
      if (!acc.length) {
        return [...acc, item];
      }

      const isLast = index === sortedTemps.length - 1;
      if (isLast) {
        return [
          ...acc,
          {
            ...item,
            date: moment(item.date).add(1, 'millisecond').toISOString(),
          },
        ];
      }

      const next = sortedTemps[index + 1];

      if (!next) {
        return acc;
      }

      const isNullPoint = moment(next.date).diff(item.date) > 9000;
      const closePoint = {
        date: moment(item.date).add(1, 'second').toISOString(),
        value: item.value,
      };
      const nullPoint = {
        date: moment(next.date).subtract(1, 'second').toISOString(),
        value: null,
      };

      if (isNullPoint) {
        return [...acc, closePoint, nullPoint, next];
      }

      acc[acc.length - 1] = item;
      return acc;
    }, []);
  }, [data]);

  const lineRenderer = useCallback(
    (pathD) =>
      d3
        .line()
        .x((d) => sx(new Date(d.date)))
        .y(yScale(y))
        .defined((d) => !!d?.value)(pathD),
    [yScale, sx]
  );

  const onMouseMove = useCallback(
    (event) => {
      event.preventDefault();
      const [mouseX, mouseY] = d3.pointer(event);
      const xDate = sx.invert(mouseX);
      const yValue = yScale.invert(mouseY);

      const hoverMin = y - 10;
      const hoverMax = y + 10;

      if (yValue > hoverMax || y < hoverMin) {
        setHoverData(null);
        return;
      }

      const tempIndex = getFirstEqualOrAfterTimeIndex(lineData, xDate, 'date');

      const temperature = lineData[tempIndex].value;

      setHoverData({
        temperature,
        hoverCenter: xDate,
      });
    },
    [lineData, yScale, sx]
  );

  const onMouseleave = useCallback(() => {
    setHoverData(null);
  }, []);

  const handleHover = useCallback(() => {
    const lines = d3.selectAll('.temperatureLine');
    lines.on('mousemove', onMouseMove);
    lines.on('mouseleave', onMouseleave);

    return () => {
      lines.on('mousemove', null);
      lines.on('mouseleave', null);
    };
  }, [onMouseMove, onMouseleave]);

  useEffect(() => handleHover(), [handleHover]);

  return (
    <g className="temperatureLine">
      <path
        clipPath={clip}
        strokeWidth={8}
        stroke="red"
        d={lineRenderer(lineData)}
      />
      {!!hoverData && (
        <Popper
          title="Temperature"
          content={hoverData.temperature ? `${hoverData.temperature} F` : 'N/A'}
        >
          <circle
            r={10}
            cy={yScale(y)}
            cx={sx(hoverData.hoverCenter)}
            fill="transparent"
          />
        </Popper>
      )}
    </g>
  );
};

export default TemperatureLine;
