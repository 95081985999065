import React, { useMemo, useState } from 'react';
import * as d3 from 'd3';
import PropTypes from 'prop-types';

import { containerChildProps } from 'common/constants/sharedPropTypes';

const xyGen = (domain, range) => {
  return d3.scaleLinear().domain(domain).range(range);
};

export const xAxisGridGen =
  (height, margin, ticks = 200) =>
  (g, x) => {
    const grid = g.call(d3.axisBottom(x).ticks(ticks).tickSize(height));

    grid.selectAll('g.tick > text').remove();

    return grid;
  };

const yAxisGen =
  (width, margin, ticks = 10) =>
  (g, y) => {
    const grid = g.call(d3.axisLeft(y).tickSize(-width).ticks(ticks));
    grid.selectAll('g.tick text').remove();

    return grid;
  };

const D3Grid = ({ parent, color, strokeWidth, xDomain, yDomain }) => {
  const [xGridRef, setXGridRef] = useState(null);
  const [yGridRef, setYGridRef] = useState(null);
  const { width, height, options = {} } = parent;
  const { margin } = options;

  const xScale = useMemo(
    () => xyGen(xDomain, [margin.left, width - margin.right]),
    [margin, width, xDomain]
  );

  const yScale = useMemo(
    () => xyGen(yDomain, [height - margin.bottom, margin.top]),
    [yDomain, height, margin]
  );

  const xAxisGrid = useMemo(
    () => xAxisGridGen(height, margin),
    [height, margin]
  );

  const yAxisGrid = useMemo(() => yAxisGen(width, margin), [width, margin]);

  useMemo(() => {
    if (!xGridRef) return null;

    return d3.select(xGridRef).call(xAxisGrid, xScale);
  }, [xGridRef, xAxisGrid, xScale]);

  useMemo(() => {
    if (!yGridRef) return null;

    return d3
      .select(yGridRef)
      .call(yAxisGrid, yScale)
      .call((grid) => grid.select('.domain').remove())
      .call((grid) => grid.selectAll('.tick:first-child').remove())
      .call((grid) => grid.selectAll('.tick:last-child').remove());
  }, [yGridRef, yAxisGrid, yScale]);

  const renderGrid = (setRef, axis) => (
    <g
      className={`grid${axis}`}
      ref={setRef}
      color={color}
      strokeWidth={strokeWidth}
    />
  );

  return (
    <>
      {renderGrid(setXGridRef, 'X')}
      {renderGrid(setYGridRef, 'Y')}
    </>
  );
};

D3Grid.defaultProps = {
  strokeWidth: 0.3,
  color: 'lightcoral',
};

D3Grid.propTypes = {
  color: PropTypes.string,
  parent: containerChildProps,
  strokeWidth: PropTypes.number,
  xDomain: PropTypes.arrayOf(PropTypes.number),
  yDomain: PropTypes.arrayOf(PropTypes.number),
};

export default D3Grid;
