import { requestSaga } from 'common/utils/generators';
import resources from 'common/constants/resources';
import dataProvider from 'store/dataProvider';

export const createGetEventEcgSaga = (actionType) =>
  function* getEventsEcg() {
    yield requestSaga({
      actionType,
      request: dataProvider.getList,
      resource: resources.ecgData.byPeriod,
    });
  };
