import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import DeleteIcon from '@material-ui/icons/Delete';
import makeStyles from '@material-ui/styles/makeStyles';

import { useDebounceFunc } from 'common/hooks';
import { ecgTypeLabels } from 'common/constants/ecgEnums';
import { escalatedEventParts } from 'common/constants/enums';
import { reportEventGroupType } from 'common/constants/sharedPropTypes';
import { getEventGraphCenterDate } from 'common/utils/helpers/graphs';
import { eventNoteKeys, eventNoteKeyByPart } from '../constants';
import { useEscalatedReport } from '../hooks';
import {
  getEscalatedEcgByEventId,
  escalatedReportEventEcgLoading,
} from '../ducks/selectors';
import {
  addNotes,
  addPENotes,
  fetchEventEcg,
  fetchPartHeartRate,
} from '../ducks/reducer';
import ReportEvent from './ReportEvent';

const useStyles = makeStyles(({ spacing, palette }) => ({
  groupWrap: {
    flexWrap: 'nowrap',
    paddingBottom: spacing(2),
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  eventPartWrap: {
    marginRight: spacing(0.5),
    '&:last-child': {
      marginRight: 0,
    },
  },
  removeButtonWrap: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 25,
  },
  removeButton: {
    cursor: 'pointer',
    color: palette.button.warning.base,
    '&:hover': {
      color: palette.button.warning.hover,
    },
  },
  editButtonLabel: {
    color: palette.text.light,
  },
}));

const EscalatedReportEvent = ({ eventGroup, updateCenterDates }) => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const {
    report = {},
    removeEvent,
    removePatientEvent,
  } = useEscalatedReport(eventGroup.procedureId);

  const {
    id,
    isPE,
    dateTo,
    dateFrom,
    procedureId,
    patientEventId,
    abnormalityType,
  } = eventGroup;

  const [notes, setNotes] = useState({});

  // ecg loaders
  const firstEventPartLoading = useSelector(
    escalatedReportEventEcgLoading(id, escalatedEventParts.preEvent)
  );
  const mainEventPartLoading = useSelector(
    escalatedReportEventEcgLoading(id, escalatedEventParts.event)
  );
  const lastEventPartLoading = useSelector(
    escalatedReportEventEcgLoading(id, escalatedEventParts.postEvent)
  );

  // loaded ecg
  const firstEventEcg = useSelector(
    getEscalatedEcgByEventId(id, escalatedEventParts.preEvent)
  );
  const mainEventEcg = useSelector(
    getEscalatedEcgByEventId(id, escalatedEventParts.event)
  );
  const lastEventEcg = useSelector(
    getEscalatedEcgByEventId(id, escalatedEventParts.postEvent)
  );

  const label = useMemo(() => {
    if (isPE) {
      const { counter } = eventGroup;
      return `PE #${counter} (${ecgTypeLabels[abnormalityType]})`;
    }

    return ecgTypeLabels[abnormalityType];
  }, [isPE, eventGroup, abnormalityType]);

  const firstPartCenter = useMemo(
    () => moment(dateFrom).subtract(10, 'seconds').toISOString(),
    [dateFrom]
  );

  const eventCenter = useMemo(
    () => getEventGraphCenterDate(eventGroup),
    [eventGroup]
  );

  const isLastPartIncluded = useMemo(() => {
    const maxAvailableLastPartCenter = moment(dateFrom).add(15, 'seconds');
    return moment(dateTo).isSameOrBefore(maxAvailableLastPartCenter);
  }, [dateFrom, dateTo]);

  const lastPartCenter = useMemo(() => {
    if (!isLastPartIncluded) {
      return dateTo;
    }

    return moment(dateTo).add(5, 'seconds').toISOString();
  }, [isLastPartIncluded, dateTo]);

  const fetchFirstEcg = useCallback(() => {
    const filter = {
      procedureId,
      precision: 25,
      dateFrom: moment(dateFrom).subtract(30, 'seconds').toISOString(),
      dateTo: dateFrom,
    };

    dispatch(fetchEventEcg(filter, id, escalatedEventParts.preEvent));
    dispatch(fetchPartHeartRate(filter, id, escalatedEventParts.preEvent));
  }, [dispatch, procedureId, dateFrom, id]);

  const fetchMainEcg = useCallback(() => {
    const filter = {
      procedureId,
      precision: 25,
      dateFrom: moment(eventCenter).subtract(15, 'seconds').toISOString(),
      dateTo: moment(eventCenter).add(15, 'seconds').toISOString(),
    };

    dispatch(fetchEventEcg(filter, id));
  }, [dispatch, procedureId, eventCenter, id]);

  const lastPartFilterOptions = useMemo(() => {
    if (isLastPartIncluded) {
      return {
        procedureId,
        precision: 25,
        dateFrom: moment(lastPartCenter).subtract(5, 'seconds').toISOString(),
        dateTo: moment(lastPartCenter).add(25, 'seconds').toISOString(),
      };
    }

    return {
      procedureId,
      precision: 25,
      dateFrom: moment(lastPartCenter).subtract(15, 'seconds').toISOString(),
      dateTo: moment(lastPartCenter).add(15, 'seconds').toISOString(),
    };
  }, [isLastPartIncluded, lastPartCenter, procedureId]);

  const fetchLastEcg = useCallback(() => {
    dispatch(
      fetchEventEcg(lastPartFilterOptions, id, escalatedEventParts.postEvent)
    );

    dispatch(
      fetchPartHeartRate(
        lastPartFilterOptions,
        id,
        escalatedEventParts.postEvent
      )
    );
  }, [dispatch, lastPartFilterOptions, id]);

  const updateEventNotes = useCallback(
    (note, part) => {
      const body = { ...notes, [eventNoteKeyByPart[part]]: note };

      if (!isPE) {
        const params = { reportId: report.id, eventGroupId: id };
        dispatch(addNotes(params, body, procedureId));
        return;
      }

      const params = { reportId: report.id, patientEventId, eventGroupId: id };
      dispatch(addPENotes(params, body, procedureId));
    },
    [report.id, isPE, id, patientEventId, procedureId, notes, dispatch]
  );

  const removeEventItem = useCallback(() => {
    if (isPE) {
      removePatientEvent(patientEventId, id);
      return;
    }

    removeEvent(id);
  }, [isPE, id, patientEventId, removePatientEvent, removeEvent]);

  const debouncedUpdateEventNotes = useDebounceFunc(updateEventNotes, 500);

  const updateEventNoteHandler = useCallback(
    (note, part) => {
      debouncedUpdateEventNotes(note, part);
      setNotes((n) => ({ ...n, [eventNoteKeyByPart[part]]: note }));
    },
    [debouncedUpdateEventNotes]
  );

  useEffect(() => {
    if (firstEventEcg || firstEventPartLoading) return;

    fetchFirstEcg();
  }, [firstEventEcg, firstEventPartLoading, fetchFirstEcg]);

  useEffect(() => {
    if (mainEventEcg || mainEventPartLoading) return;

    fetchMainEcg();
  }, [mainEventEcg, mainEventPartLoading, fetchMainEcg]);

  useEffect(() => {
    if (lastEventEcg || lastEventPartLoading) return;

    fetchLastEcg();
  }, [lastEventEcg, lastEventPartLoading, fetchLastEcg]);

  useEffect(() => {
    if (Object.keys(notes).length) {
      return;
    }

    setNotes({
      [eventNoteKeys.preEvent]: eventGroup[eventNoteKeys.preEvent],
      [eventNoteKeys.event]: eventGroup[eventNoteKeys.event],
      [eventNoteKeys.postEvent]: eventGroup[eventNoteKeys.postEvent],
    });
  }, [eventGroup, notes]);

  const onScrollEvents = (eventPart) => (i, s, center) => {
    updateCenterDates(id, eventPart, center);
  };

  return (
    <div className={styles.groupWrap}>
      <Grid container wrap="nowrap" justifyContent="flex-start">
        <Grid item xs className={styles.eventPartWrap}>
          <ReportEvent
            isNote
            subLabel="Pre-Event Strip"
            event={eventGroup}
            center={firstPartCenter}
            note={notes[eventNoteKeys.preEvent]}
            eventPart={escalatedEventParts.preEvent}
            editNote={updateEventNoteHandler}
            onScroll={onScrollEvents(escalatedEventParts.preEvent)}
          />
        </Grid>

        <Grid item xs className={styles.eventPartWrap}>
          <ReportEvent
            isNote
            isMeasure
            label={label}
            event={eventGroup}
            center={eventCenter}
            note={notes[eventNoteKeys.event]}
            eventPart={escalatedEventParts.event}
            editNote={updateEventNoteHandler}
            onScroll={onScrollEvents(escalatedEventParts.event)}
          />
        </Grid>

        <Grid item xs>
          <ReportEvent
            isNote
            subLabel="Post-Event Strip"
            event={eventGroup}
            center={lastPartCenter}
            note={notes[eventNoteKeys.postEvent]}
            eventPart={escalatedEventParts.postEvent}
            editNote={updateEventNoteHandler}
            onScroll={onScrollEvents(escalatedEventParts.postEvent)}
          />
        </Grid>

        <Grid className={styles.removeButtonWrap}>
          <DeleteIcon
            fontSize="small"
            onClick={removeEventItem}
            classes={{ root: styles.removeButton }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

EscalatedReportEvent.propTypes = {
  eventGroup: reportEventGroupType,
  updateCenterDates: PropTypes.func.isRequired,
};

const isEqualProps = (prev, next) => prev.eventGroup === next.eventGroup;

export default memo(EscalatedReportEvent, isEqualProps);
