import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles((theme) => ({
  link: {
    color: theme.palette.triages.userCard.fontColor,
    textDecoration: 'none',
  },

  headSectionData: {
    fontWeight: 700,
  },

  reportTitle: {
    fontSize: '0.625rem',
    lineHeight: 1.2,
  },

  table: {
    tableLayout: 'fixed',
  },

  headRow: {
    '& > th': {
      padding: 0,
      width: ({ count }) => `calc( 2 * 100% / 3 / ${count} )`,
      verticalAlign: 'bottom',
      border: 'none',
    },
    '& > th:first-child': {
      width: 'calc(100% / 3)',
    },
    '& > th:nth-child(2n)': {
      backgroundColor: theme.palette.transparent,
    },
  },

  bodyRow: {
    backgroundColor: 'white',
    '& > td': {
      padding: 0,
      width: ({ count }) => `calc( 2 * 100% / 3 / ${count} )`,
      verticalAlign: 'bottom',
      border: 'none',
    },
    '&:last-child > td': {
      padding: 0,
    },
    '& > td:first-child': {
      width: 'calc(100% / 3)',
    },
  },

  titleCell: {
    backgroundColor: theme.palette.colorCoding.grey.main,
  },

  paper: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.grey.A200,
  },
}));
