import {
  API_URL,
  API_AUTHORIZATION_URL,
  API_FORGOT_PASSWORD_URL,
  API_RESET_PASSWORD_URL,
  VALIDATE_ACCOUNT,
  VALIDATE_CODE,
  ACCEPT_TERMS_OF_USE,
} from 'config/api';

export const urls = {
  validateAccount: VALIDATE_ACCOUNT,
  acceptTermsOfUse: ACCEPT_TERMS_OF_USE,
  validateCode: VALIDATE_CODE,
  authorization: API_AUTHORIZATION_URL,
  base: API_URL,
  forgotPassword: API_FORGOT_PASSWORD_URL,
  resetPassword: API_RESET_PASSWORD_URL,
};

export const errorMessages = {
  login: 'Incorrect email or password',
  confirmationCode: 'Incorrect email or code',
};
