import React, { useCallback, useEffect, memo, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useSocketHub } from 'common/hooks';
import { entities } from 'common/constants/socketConstants';
import { ecgEventStatus, eventStripReducerNames } from 'common/constants/enums';
import { openEventStripModal } from 'common/ducks/general/actions/modalActions';
import {
  groupMessageByType,
  eventGroupMessageMapper,
  patientEventMessageMapper,
} from 'common/utils/helpers/socket';
import {
  updateEvents,
  getEcgPeriod,
  getEventsPeriod,
  updatePatientEvents,
  getPatientEventsPeriod,
} from '../ducks';
import {
  getLoading,
  getEcgDataKeys,
  getCurrentSelectedRange,
} from '../ducks/selectors';
import EcgGraph from './EcgGraph';
import NoDataMessage from './NoDataMessage';
import CircularProgress from './CircularProgress';

const status = [ecgEventStatus.accepted, ecgEventStatus.included];

const GraphsContainer = ({ procedureId = '', range = [], selectedTime }) => {
  const dispatch = useDispatch();
  const loading = useSelector(getLoading);
  const ecgDataKeys = useSelector(getEcgDataKeys);
  const selectedRange = useSelector(getCurrentSelectedRange);

  const socketHubPayload = useMemo(() => [procedureId], [procedureId]);

  const onMessage = useCallback(
    (message) => {
      const fullRange = [range[0], range[range.length - 1]];
      const { isPatientEvent, isEventGroup, payload } =
        groupMessageByType(message);

      if (isEventGroup) {
        const events = eventGroupMessageMapper(payload, fullRange);
        dispatch(updateEvents(events));
      }

      if (isPatientEvent) {
        const events = patientEventMessageMapper(payload, fullRange);
        dispatch(updatePatientEvents(events));
      }
    },
    [dispatch, range]
  );

  useSocketHub(entities.procedure, socketHubPayload, onMessage);

  const getEcgData = useCallback(() => {
    if (!range.length || !procedureId) {
      return null;
    }

    const patientEventsAction = dispatch(
      getPatientEventsPeriod({
        filter: {
          status,
          procedureId,
          dateFrom: range[0],
          dateTo: range[range.length - 1],
        },
      })
    );

    const eventsAction = dispatch(
      getEventsPeriod({
        filter: {
          status,
          procedureId,
          reviewed: true,
          dateFrom: range[0],
          dateTo: range[range.length - 1],
        },
      })
    );

    const actions = range
      .map((date, index) => {
        if (index === range.length - 1) {
          return null;
        }
        const filter = {
          procedureId,
          dateFrom: date,
          dateTo: range[index + 1],
          precision: 8,
        };

        return dispatch(getEcgPeriod({ filter }));
      })
      .filter(Boolean)
      .flat();

    return {
      actions,
      eventsAction,
      patientEventsAction,
    };
  }, [range, procedureId, dispatch]);

  const openEventStrip = useCallback(() => {
    if (!selectedRange) {
      return;
    }

    const { center, event } = selectedRange;
    const stripRange = [range[0], range[range.length - 1]];

    dispatch(
      openEventStripModal({
        event,
        center,
        status,
        procedureId,
        range: stripRange,
        isTriageView: false,
        shouldUpdateCenter: false,
        resource: eventStripReducerNames.monitoringEventStrip,
      })
    );
  }, [range, dispatch, selectedRange, procedureId]);

  useEffect(() => {
    const allActions = getEcgData();

    return () => {
      if (allActions) {
        allActions.eventsAction.abort();
        allActions.patientEventsAction.abort();
        allActions.actions.forEach((a) => a.abort());
      }
    };
  }, [getEcgData]);

  useEffect(() => {
    openEventStrip();
  }, [openEventStrip]);

  if (loading) {
    return <CircularProgress />;
  }

  if (!ecgDataKeys.length && !loading) {
    return <NoDataMessage />;
  }

  return ecgDataKeys.map((date) => (
    <EcgGraph key={date} dateKey={date} selectedTime={selectedTime} />
  ));
};

const areEqual = (prev, next) =>
  prev.procedureId === next.procedureId && prev.range?.[0] === next.range?.[0];

export default memo(GraphsContainer, areEqual);
