import uniqBy from 'lodash/uniqBy';

import { createRequestActionTypes } from 'common/utils/actionTypesHelper';
import { submitEditsStatusEnum } from 'common/constants/enums';
import { reportActionTypes } from 'common/ducks/actionTypes';
import { requestReducer } from 'common/utils/generators';

export const actionTypes = createRequestActionTypes(
  ['list', 'update', 'delete', 'getReport'],
  'reports'
);

const initialState = {
  loading: true,
  error: false,
};

const getReportsWithUpdatedStatus = (state, { params }, getStatus) => {
  const { id } = params;
  const idx = state.data.findIndex((r) => r.id === id);

  if (idx < 0) {
    return state.data;
  }

  const report = state.data[idx];

  return state.data.map((r) => {
    if (r.fileId === report.fileId) {
      return { ...r, ...getStatus(r) };
    }

    return r;
  });
};

export default requestReducer(
  [
    actionTypes.list,
    actionTypes.update,
    actionTypes.getReport,
    reportActionTypes.reviewByFileId,
    reportActionTypes.submitAccepted,
    reportActionTypes.submitEditsOptions,
  ],
  { ...initialState, data: [], total: 0 },
  (payload, state, action) => {
    const { requestPayload, type } = action;

    if (type === actionTypes.getReport.success) {
      return {
        ...state,
        data: uniqBy([...(state.data || []), payload.data], 'id'),
      };
    }

    if (type === reportActionTypes.submitAccepted.success) {
      const getNewStatus = (report) => {
        const submitEditsStatus = [report.submitEditsStatus]
          .flat()
          .filter(Boolean);

        submitEditsStatus.push(submitEditsStatusEnum.accepted);

        return { submitEditsStatus };
      };

      const newData = getReportsWithUpdatedStatus(
        state,
        requestPayload,
        getNewStatus
      );

      return { ...state, data: newData };
    }

    if (type === reportActionTypes.submitEditsOptions.success) {
      const submitEditsOptions = requestPayload.body;
      const getNewStatus = (report) => {
        let submitEditsStatus = [report.submitEditsStatus]
          .flat()
          .filter(Boolean);

        const isOptions = Object.values(requestPayload.body || {}).filter(
          Boolean
        ).length;

        if (!submitEditsStatus.includes(submitEditsStatusEnum.requested)) {
          submitEditsStatus.push(submitEditsStatusEnum.requested);
        }

        if (!isOptions) {
          submitEditsStatus = submitEditsStatus.filter(
            (s) => s !== submitEditsStatusEnum.requested
          );
        }

        return { submitEditsOptions, submitEditsStatus };
      };

      const newData = getReportsWithUpdatedStatus(
        state,
        requestPayload,
        getNewStatus
      );

      return { ...state, data: newData };
    }

    if (!payload.data) {
      const { fileId } = requestPayload.query;
      const reports = (state.data || []).map((report) => {
        if (report.fileId === fileId) {
          return {
            ...report,
            reviewedBy: requestPayload.currentUserId,
          };
        }

        return report;
      });

      return { ...state, data: reports };
    }
    return { ...state, data: payload.data, total: payload.total };
  },
  (state, action) => {
    if (action.type === actionTypes.update.requested) {
      const { prevFile, newFile } = action.payload;

      return {
        ...state,
        loading: false,
        data: (state.data || []).map((report) => {
          if (report.fileId === prevFile) {
            return {
              ...report,
              fileId: newFile,
            };
          }

          return report;
        }),
      };
    }

    return state;
  }
);

export const getReports = (payload) => ({
  type: actionTypes.list.requested,
  payload,
});

export const getReport = (payload, onSuccess) => ({
  type: actionTypes.getReport.requested,
  payload,
  onSuccess,
});

export const updateReportsFileId = (payload) => ({
  type: actionTypes.update.requested,
  payload,
});

export const deleteReports = (payload, onSuccess) => ({
  type: actionTypes.delete.requested,
  onSuccess,
  payload,
});
