import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DialogTitle from '@material-ui/core/DialogTitle';

const ContactModalTitle = ({ title, onClose }) => (
  <DialogTitle>
    <Grid
      container
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <Typography variant="h3" color="primary">
        {title} Contact
      </Typography>

      {onClose && (
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      )}
    </Grid>
  </DialogTitle>
);

ContactModalTitle.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func,
};

export default ContactModalTitle;
