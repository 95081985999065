import { useState, useEffect, useMemo, useRef } from 'react';

function useVisibility(options = {}) {
  const [isIntersecting, setIntersecting] = useState(false);
  const ref = useRef(null);

  const observer = useMemo(
    () =>
      new IntersectionObserver((entries) => {
        const [entry] = entries;
        setIntersecting(entry.isIntersecting);
      }, options),
    [options]
  );

  useEffect(() => {
    const onDisconnect = () => {
      observer.disconnect();
    };

    if (!ref.current) {
      return onDisconnect;
    }

    observer.observe(ref.current);

    return onDisconnect;
  }, [observer, ref]);

  return [isIntersecting, ref];
}

export default useVisibility;
