import { requestSaga } from 'common/utils/generators';
import resources from 'common/constants/resources';
import dataProvider from 'store/dataProvider';
import { patientsActionTypes } from '../reducer';

function* getPhysicianList() {
  yield requestSaga({
    actionType: patientsActionTypes.getList,
    request: dataProvider.getList,
    resource: resources.patient.list,
  });
}

export default getPhysicianList;
