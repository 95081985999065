import React, { useState, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import Add from '@material-ui/icons/Add';
import { useDispatch } from 'react-redux';

import CardSection from 'common/components/CardSection';
import {
  openContactCreateModal,
  openContactEditModal,
} from 'common/ducks/general/actions/modalActions';
import ContactsTable from './components/ContactsTable';
import { contactsActions } from './ducks';

const defaultPageSize = 5;
const defaultSort = { field: 'fullName' };

const createCommonTableButtons = (onCreate, disabled) => [
  {
    text: 'Create',
    onClick: typeof onCreate === 'function' ? onCreate : () => null,
    size: 'medium',
    variant: 'text',
    icon: Add,
    disabled,
  },
];

const ContactsWidget = ({ entityId }) => {
  const dispatch = useDispatch();
  const [params, setParams] = useState();

  const contactParams = useMemo(
    () => ({
      assignedAccountIds: [entityId],
    }),
    [entityId]
  );

  const getContactsList = useCallback(() => {
    if (!params) {
      return;
    }

    dispatch(
      contactsActions.getContactsList({
        ...params,
        filter: contactParams,
        queryOptions: {},
      })
    );
  }, [dispatch, params, contactParams]);

  const openCreateContactModal = useCallback(() => {
    dispatch(
      openContactCreateModal({
        entityId,
        maxWidth: 'md',
        contact: contactParams,
        refreshData: getContactsList,
      })
    );
  }, [dispatch, entityId, contactParams, getContactsList]);

  const openEditContactModal = (row) => {
    dispatch(
      openContactEditModal({
        entityId,
        maxWidth: 'md',
        contact: row.original,
        refreshData: getContactsList,
      })
    );
  };

  useEffect(() => {
    getContactsList();
  }, [getContactsList]);

  return (
    <CardSection title="Contacts">
      <ContactsTable
        selectable={false}
        defaultSort={defaultSort}
        rowsPerPage={defaultPageSize}
        updateParams={setParams}
        onRowClick={openEditContactModal}
        tableActions={createCommonTableButtons(openCreateContactModal)}
      />
    </CardSection>
  );
};

ContactsWidget.propTypes = {
  entityId: PropTypes.string.isRequired,
};

export default ContactsWidget;
