import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import groupBy from 'lodash/groupBy';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';

import dataProvider from 'store/dataProvider';
import resources from 'common/constants/resources';
import { ecgTimeFormat } from 'common/constants/dateFormats';
import { escalatedEventParts } from 'common/constants/enums';
import SimpleGraph from 'common/components/Widgets/SimpleGraph';
import { getFormattedDuration } from 'common/utils/helpers/date';
import TextInput from 'common/components/simpleInputs/TextInput';
import { reportEventGroupType } from 'common/constants/sharedPropTypes';
import { getActivityAndPositionAverage } from 'common/utils/helpers/graphs';
import {
  getEscalatedEventHR,
  getEscalatedEcgByEventId,
} from '../ducks/selectors';

const useStyles = makeStyles(({ spacing, palette }) => ({
  noteSection: {
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    padding: spacing(0.5, 1.125),
    backgroundColor: palette.background.secondaryLight,
  },
  noteSectionTitle: {
    display: 'flex',
    justifyContent: 'center',
    width: 50,
    color: palette.text.colored,
  },
  noteInputWrap: {
    width: 'calc(100% - 60px)',
  },
  noteInput: {
    height: 25,
    border: 'none',
    backgroundColor: palette.common.white,
    '& .MuiInputBase-root': {
      height: 25,
    },
  },
  groupItem: {
    height: 150,
    padding: spacing(1),
    backgroundColor: palette.background.mainDarkLight,
  },
  eventWrap: {
    width: '100%',
  },
  ecgWrap: {
    padding: spacing(1, 0),
    height: 110,
  },
  measureBlockWrap: {
    width: 65,
    maxHeight: '98%',
    backgroundColor: palette.background.lightGrey,
  },
  measureBlock: {
    padding: spacing(0.5),
    width: 65,
  },
  stripValue: {
    padding: spacing(0, 1),
  },
}));

const notAvailable = 'N/A';

const StripValue = ({ title = '', value = '', row = false, xs = 2 }) => {
  return (
    <Grid
      item
      container
      xs={xs}
      wrap="nowrap"
      direction={row ? 'row' : 'column'}
    >
      <Typography
        variant="subtitle2"
        style={{ fontWeight: 700, paddingRight: row ? 2 : 0 }}
      >
        {title}
      </Typography>
      <Typography variant="subtitle2" style={{ textOverflow: 'ellipsis' }}>
        {value}
      </Typography>
    </Grid>
  );
};

const ReportEvent = (props) => {
  const styles = useStyles();
  const { label, subLabel, isNote, isMeasure, onScroll } = props;
  const { event, center, eventPart, editNote, note } = props;
  const [sensorData, setSensorData] = useState({});
  const ecg = useSelector(getEscalatedEcgByEventId(event.id, eventPart));
  const hrAverage = useSelector(getEscalatedEventHR(event.id, eventPart));

  const getSensorData = useCallback(async () => {
    if (!event) {
      return;
    }

    const { dateFrom, procedureId } = event;

    try {
      const from = moment(dateFrom).subtract(5, 'seconds').toISOString();
      const to = moment(dateFrom).add(5, 'seconds').toISOString();
      const filter = { dateFrom: from, dateTo: to, procedureId };

      const { data } = await dataProvider.get(resources.sensorData.index, {
        filter,
      });

      const {
        activities = [],
        bodyPositions = [],
        temperatures = [],
      } = data || {};

      const sum = temperatures.reduce((acc, item) => acc + item.value, 0);
      const avg = sum ? (sum / temperatures.length).toFixed(1) : 0;
      const groupedActivities = groupBy(activities, 'value');
      const groupedBodyPosition = groupBy(bodyPositions, 'value');

      setSensorData({
        temperature: avg ? `${avg} F` : 'N/A',
        activity: getActivityAndPositionAverage(groupedActivities),
        position: getActivityAndPositionAverage(groupedBodyPosition),
      });
    } catch (e) {
      console.error(e);
    }
  }, [event]);

  const time = useMemo(() => {
    if (center) {
      return moment(center).format(ecgTimeFormat);
    }

    return notAvailable;
  }, [center]);

  const eventDuration = useMemo(() => {
    if (!event) {
      return notAvailable;
    }

    return getFormattedDuration(event.durationEvent);
  }, [event]);

  const averageHR = useMemo(
    () => hrAverage || event.heartRateAverage || event.avgHeartRate,
    [event.heartRateAverage, event.avgHeartRate, hrAverage]
  );

  const updateNoteHandler = useCallback(
    (e) => editNote(e.target.value, eventPart),
    [editNote, eventPart]
  );

  useEffect(() => getSensorData(), [getSensorData]);

  return (
    <Grid container direction="column">
      {isNote && (
        <Grid
          item
          container
          alignItems="center"
          justifyContent="space-between"
          className={styles.noteSection}
        >
          <Typography variant="h5" className={styles.noteSectionTitle}>
            Notes:
          </Typography>

          <Grid item className={styles.noteInputWrap}>
            <TextInput
              fullWidth
              name="note"
              size="small"
              value={note}
              className={styles.noteInput}
              onChange={updateNoteHandler}
            />
          </Grid>
        </Grid>
      )}

      <Grid item container direction="row" className={styles.groupItem}>
        <Grid item container direction="column" className={styles.eventWrap}>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ height: 30 }}
          >
            <Grid item xs>
              <Typography variant="h6" style={{ fontWeight: 700 }}>
                {label}
              </Typography>
            </Grid>

            <Grid
              item
              container
              wrap="nowrap"
              justifyContent="space-around"
              xs={isMeasure ? 10 : 8}
            >
              {isMeasure && <StripValue title="Time:" value={time} />}

              {isMeasure && (
                <StripValue title="Duration:" xs={2} value={eventDuration} />
              )}

              <StripValue
                title="Avg bpm:"
                value={averageHR}
                xs={isMeasure ? 2 : 4}
              />

              {isMeasure && (
                <StripValue
                  title="Temp:"
                  xs={1}
                  value={sensorData.temperature}
                />
              )}

              {isMeasure && (
                <Grid item container xs={2} direction="column">
                  <StripValue
                    row
                    title="Activity:"
                    value={sensorData.activity}
                  />

                  <StripValue
                    row
                    title="Position:"
                    value={sensorData.position}
                  />
                </Grid>
              )}
            </Grid>

            {!isMeasure && subLabel && (
              <Grid item xs={2} container>
                <Typography variant="subtitle2" style={{ fontWeight: 700 }}>
                  {subLabel}
                </Typography>
              </Grid>
            )}
          </Grid>

          <Grid container className={styles.ecgWrap}>
            {!ecg?.length && (
              <Skeleton
                variant="rect"
                style={{
                  width: '100%',
                  height: '100%',
                }}
              />
            )}

            {!!ecg?.length && (
              <SimpleGraph
                noDescription
                onScroll={onScroll}
                definedCenter={center}
                definedDuration={10}
                eventData={{ ...event, ecg }}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

ReportEvent.defaultProps = {
  label: 'Sinus Rhythm',
  event: {},
  isNote: false,
  note: '',
  isMeasure: false,
  editNote: () => null,
  onScroll: () => null,
};

ReportEvent.propTypes = {
  label: PropTypes.string,
  subLabel: PropTypes.string,
  event: reportEventGroupType,
  center: PropTypes.string,
  editNote: PropTypes.func,
  isNote: PropTypes.bool,
  note: PropTypes.string,
  isMeasure: PropTypes.bool,
  onScroll: PropTypes.func,
  eventPart: PropTypes.oneOf(escalatedEventParts.toValues),
};

const areEqual = (prev, next) =>
  prev.event.id === next.event.id &&
  prev.ecg === next.ecg &&
  prev.center === next.center &&
  prev.editNote === next.editNote &&
  prev.note === next.note;

export default memo(ReportEvent, areEqual);
