import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';

import {
  modalTypes,
  reportTypes,
  procedureTypes,
  escalatedEventParts,
} from 'common/constants/enums';
import { getPrePostDates } from 'common/utils/helpers/report';
import LoadingProgress from 'common/components/LoadingProgress';
import {
  closeModal,
  openWarningModal,
  openPdfReportModal,
} from 'common/ducks/general/actions/modalActions';
import ModalActionButton from 'common/components/buttons/ModalActionButton';
import moment from 'moment';
import EscalatedReportEvent from './EscalatedReportEvent';
import { escalatedReportActions } from '../ducks';
import { useEscalatedReport } from '../hooks';

const useStyles = makeStyles(({ palette, spacing }) => ({
  headerWrap: {
    paddingBottom: spacing(2),
  },
  generateButton: {
    width: 160,
    marginLeft: spacing(2),
    color: palette.text.light,
    backgroundColor: palette.button.warning.base,
    '&:hover': {
      backgroundColor: palette.button.warning.hover,
    },
  },
  closeButton: {
    marginLeft: spacing(2),
    color: palette.text.light,
    backgroundColor: palette.button.close.base,
    '&:hover': {
      backgroundColor: palette.button.close.hover,
    },
  },
  eventListWrap: {
    overflow: 'auto',
  },
  buttonText: {
    fontWeight: 700,
    fontSize: '1.4rem',
  },
}));

const ReportEditModal = ({ procedureId }) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const { report = {}, loading, procedure } = useEscalatedReport(procedureId);
  const { title: patient = '', ecgEventGroups = [], patientEvents } = report;
  const [eventPartsCenterDates, setEventCenterDates] = useState({});

  const isGenerateWarning = useMemo(() => {
    if (!procedure || !report.date) {
      return false;
    }

    const isHolterMct = procedure.type === procedureTypes.HolterMCT;
    const dates = [
      procedure.startDate,
      moment(procedure.startDate).add(1, 'day').toISOString(),
    ];

    const isInRange = !!dates.find((d) =>
      moment(report.date).startOf('day').isSame(d, 'day')
    );

    return isHolterMct && isInRange;
  }, [report.date, procedure]);

  const title = useMemo(() => {
    const fullName = patient.split(' ').reverse().join(', ');
    return `Escalated Report - ${fullName}`;
  }, [patient]);

  const generateDisabled = useMemo(
    () => !report.ecgEventGroups?.length && !report.patientEvents?.length,
    [report]
  );

  const generateReport = () => {
    if (isGenerateWarning) {
      dispatch(closeModal(modalTypes.warningModal));
    }

    dispatch(
      openPdfReportModal({
        reportType: reportTypes.escalated,
        eventPartsCenterDates,
      })
    );
  };

  const handleReportGeneration = () => {
    if (isGenerateWarning) {
      dispatch(
        openWarningModal({
          title: '** HOLTER STUDY ** ESCALATED REPORTS NOT PRESCRIBED',
          message:
            'Reminder: This procedure is a Holter study escalated reports are not to be generated unless reviewed and approved by the lead, supervisor or management.',
          onConfirm: generateReport,
        })
      );
      return;
    }

    generateReport();
  };

  const handleClose = useCallback(() => {
    dispatch(escalatedReportActions.closeEscalatedReportModal());
  }, [dispatch]);

  const updateCenterDates = (eventId, eventPart, center) => {
    setEventCenterDates((prevCenterDates) => {
      const existedEvent = prevCenterDates[eventId];

      if (existedEvent) {
        return {
          ...prevCenterDates,
          [eventId]: {
            ...existedEvent,
            [eventPart]: center,
          },
        };
      }

      return {
        ...prevCenterDates,
        [eventId]: {
          [eventPart]: center,
        },
      };
    });
  };

  const setInitialEventCenterDates = useCallback(() => {
    (ecgEventGroups || []).forEach(({ id, dateFrom, dateTo }) => {
      const { pre, center, post } = getPrePostDates(dateFrom, dateTo);

      updateCenterDates(id, escalatedEventParts.preEvent, pre);
      updateCenterDates(id, escalatedEventParts.event, center);
      updateCenterDates(id, escalatedEventParts.postEvent, post);
    });
  }, [ecgEventGroups]);

  const setInitialPatientEventCenterDates = useCallback(() => {
    (patientEvents || []).forEach(
      ({ id = '', patientEventId = '', dateFromEvent, dateToEvent }) => {
        const uniqId = id + patientEventId;
        const { pre, post, center } = getPrePostDates(
          dateFromEvent,
          dateToEvent
        );

        updateCenterDates(uniqId, escalatedEventParts.preEvent, pre);
        updateCenterDates(uniqId, escalatedEventParts.event, center);
        updateCenterDates(uniqId, escalatedEventParts.postEvent, post);
      }
    );
  }, [patientEvents]);

  useEffect(() => {
    setInitialEventCenterDates();
    setInitialPatientEventCenterDates();
  }, [setInitialEventCenterDates, setInitialPatientEventCenterDates]);

  return (
    <>
      {loading && <LoadingProgress />}

      <Box
        py={2}
        px={3}
        width="100%"
        height="100%"
        display="flex"
        borderRadius={10}
        flexDirection="column"
        component={Card}
        elevation={0}
      >
        <Grid
          container
          justifyContent="flex-start"
          wrap="nowrap"
          className={styles.headerWrap}
        >
          <Grid item container alignItems="center">
            <Box
              mr={2}
              color="text.colored"
              component={Typography}
              variant="h3"
              width="auto"
            >
              {title}
            </Box>
          </Grid>

          <Grid item>
            <ModalActionButton
              onClick={handleReportGeneration}
              disabled={generateDisabled}
              classes={{
                root: styles.generateButton,
                label: styles.buttonText,
              }}
            >
              Generate Report
            </ModalActionButton>
          </Grid>

          <ModalActionButton
            onClick={handleClose}
            classes={{ root: styles.closeButton, label: styles.buttonText }}
          >
            Close/Exit
          </ModalActionButton>
        </Grid>

        {!ecgEventGroups?.length && !patientEvents?.length && (
          <Grid container justifyContent="center" alignItems="center">
            <Typography variant="h3">
              No added events to Escalated Report
            </Typography>
          </Grid>
        )}

        <Grid className={styles.eventListWrap}>
          {(ecgEventGroups || []).map((event) => (
            <EscalatedReportEvent
              key={event.id}
              eventGroup={event}
              updateCenterDates={updateCenterDates}
            />
          ))}

          {(patientEvents || []).map((event) => (
            <EscalatedReportEvent
              key={event.id + event.patientEventId}
              eventGroup={event}
              updateCenterDates={updateCenterDates}
            />
          ))}
        </Grid>
      </Box>
    </>
  );
};

ReportEditModal.propTypes = {
  procedureId: PropTypes.string,
};

export default ReportEditModal;
