import React, { useMemo } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { useNotify } from 'common/modules/Notification/hooks';
import EventGraph from 'common/components/Widgets/EventGraph';
import { clearUndoState } from 'common/modules/Undo/ducks/reducer';
import { getEventEcgById } from '../ducks/selectors';
import * as actions from '../ducks/reducer';
import { ecgEventStatus } from '../../../constants/enums';

const updateEventsMessages = {
  success: 'Events status has been updated successfully',
  error: 'Events status updating error',
};

const EventItem = ({
  width,
  height,
  onClick,
  selected,
  graphProps,
  getEventEcg,
  currentEvent,
  withoutGreed,
  noDescription,
  fetchEventEcg,
  includeButton,
  significantButton,
  significantEventIds,
  autoIncludedEventIds,
  ...props
}) => {
  const notify = useNotify();
  const dispatch = useDispatch();
  const ecg = useSelector(getEventEcg(currentEvent?.id));

  const event = useMemo(
    () => ({
      ...currentEvent,
      mostSignificant: significantEventIds.includes(currentEvent.id),
      status: autoIncludedEventIds?.includes(currentEvent.id)
        ? ecgEventStatus.included
        : currentEvent.status,
      ecg,
    }),
    [currentEvent, ecg, significantEventIds, autoIncludedEventIds]
  );

  const eventStatusButtons = useMemo(
    () => includeButton || significantButton,
    [includeButton, significantButton]
  );

  const onUpdateSuccess = () =>
    notify.success({ message: updateEventsMessages.success, undoable: true });
  const onUpdateError = () => notify.error(updateEventsMessages.error);

  const setAsSignificant = () => {
    // clear undo state before new action to avoid conflicts
    dispatch(clearUndoState());

    dispatch(
      actions.setSignificant(
        {
          query: {
            ecgEventGroupId: event.id,
            reportDate: moment(event?.dateFrom).startOf('day').toISOString(),
          },
        },
        onUpdateSuccess,
        onUpdateError
      )
    );
  };

  return (
    <EventGraph
      {...props}
      width={width}
      height={height}
      onClick={onClick}
      selected={selected}
      currentEvent={event}
      graphProps={graphProps}
      getEventEcg={fetchEventEcg}
      withoutGreed={withoutGreed}
      includeButton={includeButton}
      noDescription={noDescription}
      significantButton={significantButton}
      eventStatusButtons={eventStatusButtons}
      setEventSignificant={setAsSignificant}
    />
  );
};

EventItem.defaultProps = {
  onClick: () => null,
  status: null,
  graphProps: {},
  selected: false,
  getEventEcg: getEventEcgById,
  currentEvent: {},
  withoutGreed: false,
  fetchEventEcg: actions.getEventEcg,
  includeButton: false,
  significantEventIds: [],
  autoIncludedEventIds: [],
  significantButton: false,
};

EventItem.propTypes = {
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  graphProps: PropTypes.shape(),
  getEventEcg: PropTypes.func,
  currentEvent: PropTypes.shape(),
  withoutGreed: PropTypes.bool,
  fetchEventEcg: PropTypes.func,
  noDescription: PropTypes.bool,
  includeButton: PropTypes.bool,
  significantButton: PropTypes.bool,
  status: PropTypes.arrayOf(PropTypes.string),
  significantEventIds: PropTypes.arrayOf(PropTypes.string),
  autoIncludedEventIds: PropTypes.arrayOf(PropTypes.string),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default EventItem;
