import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { patientActions } from 'common/ducks';
import { procedureSelector } from 'common/utils/entitySelectors';
import { useGetParams } from './useGetParams';

const useLockProcedure = () => {
  const { id } = useGetParams();
  const dispatch = useDispatch();
  const procedure = useSelector(procedureSelector(id));
  const patientId = useMemo(() => procedure?.patient, [procedure]);

  const handlePageUnload = useCallback(() => {
    const listener = () => {
      dispatch(patientActions.unlockPatients([patientId]));
    };

    window.addEventListener('beforeunload', listener);

    return () => window.removeEventListener('beforeunload', listener);
  }, [dispatch, patientId]);

  const lockPatients = useCallback(() => {
    if (!patientId) {
      return;
    }

    const lock = () => dispatch(patientActions.lockPatients([patientId]));

    dispatch(patientActions.unlockPatients([], lock));
  }, [patientId, dispatch]);

  useEffect(() => lockPatients(), [lockPatients]);

  useEffect(() => {
    if (!patientId) {
      return () => null;
    }

    return () => dispatch(patientActions.unlockPatients([patientId]));
  }, [dispatch, patientId]);

  useEffect(() => handlePageUnload(), [handlePageUnload]);
};

export default useLockProcedure;
