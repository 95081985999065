import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import useTheme from '@material-ui/styles/useTheme';

const buttonStyles = {
  textTransform: 'none',
  fontWeight: 'bold',
};

const WarningModal = ({
  title = '',
  message = '',
  onClose = () => null,
  onConfirm = () => null,
  handleClose = () => null,
  confirmButtonText = 'Approve/Continue',
  cancelButtonText = 'Close/Exit',
  ...props
}) => {
  const { palette } = useTheme();
  const close = () => {
    handleClose();
    onClose();
  };

  const confirm = () => {
    onConfirm(props);
    onClose();
  };

  return (
    <Grid style={{ maxWidth: '670px' }}>
      <Box
        disableTypography
        color="text.light"
        bgcolor="#e31a1a"
        style={{ textAlign: 'center' }}
        component={DialogTitle}
      >
        <Typography variant="h2">{title}</Typography>
      </Box>

      <DialogContent style={{ padding: '24px 60px', textAlign: 'center' }}>
        <Typography variant="h4" style={{ whiteSpace: 'pre-wrap' }}>
          {message}
        </Typography>
      </DialogContent>

      <DialogActions style={{ paddingBottom: 32 }}>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item style={{ minWidth: 200 }}>
            <Button
              fullWidth
              variant="contained"
              onClick={close}
              color="default"
              style={buttonStyles}
            >
              {cancelButtonText}
            </Button>
          </Grid>

          <Grid item style={{ minWidth: 200 }}>
            <Button
              fullWidth
              variant="contained"
              onClick={confirm}
              style={{
                ...buttonStyles,
                color: palette.text.light,
                backgroundColor: palette.button.warning.base,
                '&:hover': {
                  backgroundColor: palette.button.warning.hover,
                },
              }}
            >
              {confirmButtonText}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Grid>
  );
};

export default WarningModal;
