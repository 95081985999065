import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';

const DefaultButton = ({
  text,
  to = null,
  onClick,
  autoFocus,
  startIcon,
  ...props
}) => (
  <Button
    startIcon={startIcon}
    component={to ? Link : Button}
    to={to}
    onClick={onClick}
    autoFocus={autoFocus}
    variant="contained"
    color="primary"
    size="small"
    {...props}
  >
    {text}
  </Button>
);

export default DefaultButton;

DefaultButton.propTypes = {
  autoFocus: PropTypes.bool,
  to: PropTypes.string,
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.object,
  ]),
  onClick: PropTypes.func,
  startIcon: PropTypes.node,
};
