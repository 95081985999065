import { requestSaga } from 'common/utils/generators';
import dataProvider from 'store/dataProvider';
import resources from 'common/constants/resources';
import { patientsActionTypes } from '../reducer';

function* fetchPatientSaga() {
  yield requestSaga({
    actionType: patientsActionTypes.getOne,
    request: dataProvider.getOne,
    resource: resources.patient.item,
  });
}

export default fetchPatientSaga;
