import { requestSaga } from 'common/utils/generators';
import resources from 'common/constants/resources';
import dataProvider from 'store/dataProvider';

import { actionTypes } from '../reducer';

export function* getEscalatedHRSummary() {
  yield requestSaga({
    actionType: actionTypes.heartRate,
    request: dataProvider.getList,
    resource: resources.healthData.heartRateStripSummary,
  });
}
