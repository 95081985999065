import { requestSaga } from 'common/utils/generators';
import resources from 'common/constants/resources';
import dataProvider from 'store/dataProvider';
import { physicianActionTypes } from '../reducer';

function* getPhysicianList() {
  yield requestSaga({
    actionType: physicianActionTypes.getList,
    request: dataProvider.getList,
    resource: resources.physician.list,
  });
}

export default getPhysicianList;
