import { stack } from 'common/constants/path';

const procedureUrls = [
  stack.private.procedures.monitoring.index.url,
  stack.private.procedures.monitoring.edit.url,
  stack.private.procedures.monitoring.split.url,
  stack.private.procedures.monitoring.end.url,
  stack.private.procedures.reports.list.url,
  stack.private.procedures.reports.create.url,
  stack.private.procedures.triages.escalated.url,
  stack.private.procedures.triages.common.url,
];

export const isNotProcedureUrl = ({ url }) =>
  procedureUrls.every((procedureUrl) => url !== procedureUrl);
