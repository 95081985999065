import React from 'react';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';

const useStyles = makeStyles(({ palette }) => ({
  peIcon: {
    minWidth: 50,
    minHeight: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: palette.common.white,
    backgroundColor: palette.background.dark,
  },
}));

const PatientEventIcon = () => {
  const classes = useStyles();
  return (
    <div className={classes.peIcon}>
      <Typography variant="h2">PE</Typography>
    </div>
  );
};

export default PatientEventIcon;
