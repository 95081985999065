// eslint-disable-next-line camelcase
import { legacy_createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';

import rootSaga from './store/rootSaga';
import rootReducer, { history } from './store/rootReducer';

const isDevEnv = process.env.REACT_APP_DEV;

const createReduxStore = () => {
  const sagaMiddleware = createSagaMiddleware();
  const composeEnhancers =
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    isDevEnv
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
      : compose;

  const enhancer = composeEnhancers(
    applyMiddleware(sagaMiddleware, routerMiddleware(history))
  );
  const store = legacy_createStore(rootReducer, {}, enhancer);
  sagaMiddleware.run(rootSaga);

  return store;
};

export default createReduxStore;
