import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { useSelector, useDispatch } from 'react-redux';
import makeStyles from '@material-ui/styles/makeStyles';

import useResizeFiltersWithWindow from './useResizeFiltersWithWindow';
import DynamicFilterField from './DynamicFilterField';
import { getFilters, resetFilters } from './ducks';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    minHeight: 75,
    padding: theme.spacing(),
    backgroundColor: theme.palette.background.primary,
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    padding: theme.spacing(1, 0),
    paddingRight: theme.spacing(),
  },
}));

const getOptionValues = (value, optionParams) => {
  if (!optionParams?.getValue) {
    return value;
  }

  return optionParams.getValue(value);
};

const TableFilterSet = ({
  updateFilter,
  resource,
  setHeight,
  localFilters,
  fieldOptions,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const setContainer = useResizeFiltersWithWindow(
    setHeight,
    Object.keys(fieldOptions)?.length
  );

  const filtersData = useSelector((store) => store.tableFilters.data);

  const fetchData = useCallback(
    (value, inputProps) => {
      const targetFilter = value
        ? { [inputProps.columnName]: value.toLowerCase() }
        : {};

      const allValues = { ...localFilters, ...targetFilter };
      const filter = Object.keys(allValues).reduce((acc, filterKey) => {
        const fOptions = Object.values(fieldOptions).find(
          (f) => f.name === filterKey
        );
        const val = allValues[filterKey];
        const getterValue = fOptions?.getValue
          ? getOptionValues(val, fOptions)
          : {};

        return { ...acc, [filterKey]: val, ...getterValue };
      }, {});

      dispatch(getFilters({ filter }, resource));
    },
    [localFilters, resource, dispatch, fieldOptions]
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => () => dispatch(resetFilters()), [dispatch]);

  return (
    <Grid container className={classes.container} ref={setContainer}>
      {Object.keys(fieldOptions).map((columnName) => (
        <DynamicFilterField
          key={columnName}
          classes={classes}
          columnName={columnName}
          localFilters={localFilters}
          onChangeField={updateFilter}
          fetchDataForFilter={fetchData}
          filteredData={filtersData}
          fieldOptions={fieldOptions}
        />
      ))}
    </Grid>
  );
};

TableFilterSet.propTypes = {
  setHeight: PropTypes.func,
  resource: PropTypes.string.isRequired,
  updateFilter: PropTypes.func.isRequired,
  localFilters: PropTypes.objectOf(PropTypes.any).isRequired,
  fieldOptions: PropTypes.instanceOf(Object).isRequired,
};

export default TableFilterSet;
