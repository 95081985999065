import createEnum from 'common/utils/actionTypesHelper/createEnum';
import { escalatedEventParts } from 'common/constants/enums';

export const eventNoteKeys = createEnum({
  preEvent: 'preEventNote',
  event: 'mainEventNote',
  postEvent: 'postEventNote',
});

export const eventNoteKeyByPart = createEnum({
  [escalatedEventParts.preEvent]: eventNoteKeys.preEvent,
  [escalatedEventParts.event]: eventNoteKeys.event,
  [escalatedEventParts.postEvent]: eventNoteKeys.postEvent,
});

export const updateEventsMessages = {
  success: 'Events status has been updated successfully',
  error: 'Events status updating error',
};

export const addEventToReportMessage = {
  success: 'Event has been added to report successfully',
  error: "Event hasn't been added to report",
};

export const addPatientEventToReportMessage = {
  success: 'Patient Event has been added to report successfully',
  error: "Patient Event hasn't been added to report",
};

export const removeEventFromReportMessage = {
  success: 'Event has been removed from report successfully',
  error: "Event hasn't been removed from report",
};

export const removePatientEventFromReportMessage = {
  success: 'Patient Event has been removed from report successfully',
  error: "Patient Event hasn't been removed from report",
};
