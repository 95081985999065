const cacheName = 'avatars';

const isCacheSupported = 'caches' in window;

const getFromCache = async (key) => {
  if (!isCacheSupported) {
    return null;
  }

  const cache = await window.caches.open(cacheName);

  const response = await cache.match(`/${key}`);

  return response?.blob?.();
};

const addToCache = async (key, data) => {
  if (!isCacheSupported) {
    return null;
  }

  const cache = await window.caches.open(cacheName);

  const response = new Response(data);

  return cache.put(`/${key}`, response);
};

const removeFromCache = async (key) => {
  if (!isCacheSupported) {
    return null;
  }

  const cache = await window.caches.open(cacheName);

  return cache.delete(`/${key}`);
};

const cacheProvider = {
  getOne: getFromCache,
  addOne: addToCache,
  removeOne: removeFromCache,
};

export default cacheProvider;
