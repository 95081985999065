import unionBy from 'lodash/unionBy';
import { createRequestActionTypes } from 'common/utils/actionTypesHelper';
import { requestReducer } from 'common/utils/generators';
import { combineReducers } from 'redux';
import { tabs } from '../constants';

const reducers = Object.keys(tabs);

export const allActionTypes = reducers.reduce(
  (acc, rName) => ({
    ...acc,
    [rName]: createRequestActionTypes(
      ['getList', 'addOne', 'deleteData', 'download'],
      rName
    ),
  }),
  {}
);

export const initialState = {
  data: [],
  total: 0,
  error: null,
  loading: false,
};

const onSuccess = (actionTypes) => (payload, state, action) => {
  let listObject = (payload.data ? [payload.data] : []).flat().filter(Boolean);

  if (action.type === actionTypes.download.success) {
    return state;
  }

  if (action.type === actionTypes.deleteData.success) {
    listObject = listObject.filter((item) => item.id !== payload.data.id);
  }

  if (action.type === actionTypes.addOne.success) {
    return {
      ...state,
      total: state.total + 1,
      data: unionBy(listObject, state.data, 'id'),
    };
  }

  return {
    ...state,
    total: typeof payload.total === 'number' ? payload.total : state.total,
    data: Array.isArray(payload.data)
      ? payload.data
      : unionBy(listObject, state.data, 'id'),
  };
};

const mobileAppResources = combineReducers(
  reducers.reduce((acc, rName) => {
    const actionTypes = allActionTypes[rName];

    const reducer = requestReducer(
      [
        actionTypes.getList,
        actionTypes.addOne,
        actionTypes.deleteData,
        actionTypes.download,
      ],
      initialState,
      onSuccess(actionTypes)
    );

    return {
      ...acc,
      [rName]: reducer,
    };
  }, {})
);

export const createGetResourcesAction = (actionTypes) => (payload, onResp) => ({
  type: actionTypes.getList.requested,
  payload,
  onSuccess: onResp,
});

export const createAddResourceAction = (actionTypes) => (payload, onRes) => ({
  type: actionTypes.addOne.requested,
  payload,
  onSuccess: onRes,
});

export const createDeleteResourcesAction =
  (actionTypes) => (payload, onRes) => ({
    type: actionTypes.deleteData.requested,
    payload,
    onSuccess: onRes,
  });

export const createDownloadResourceAction =
  (actionTypes, download) => (id, name, onRes) => ({
    type: actionTypes.download.requested,
    payload: { params: { id }, name, download },
    onSuccess: onRes,
  });

export const allActions = reducers.reduce((acc, rName) => {
  const actionTypes = allActionTypes[rName];

  const actions = {
    getResources: createGetResourcesAction(actionTypes),
    addResource: createAddResourceAction(actionTypes),
    deleteResources: createDeleteResourcesAction(actionTypes),
    downloadResource: createDownloadResourceAction(actionTypes, true),
    getResourceData: createDownloadResourceAction(actionTypes),
  };

  return {
    ...acc,
    [rName]: actions,
  };
}, {});

export default mobileAppResources;
