import { requestSaga } from 'common/utils/generators';
import resources from 'common/constants/resources';
import dataProvider from 'store/dataProvider';
import { attachmentsActionTypes } from '../reducer';

function* getFiles() {
  yield requestSaga({
    request: dataProvider.getFile,
    resource: resources.files.download,
    actionType: attachmentsActionTypes.download,
  });
}

export default getFiles;
