import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import makeStyles from '@material-ui/styles/makeStyles';

const useStyles = makeStyles({
  root: {
    padding: 0,
  },
});

const IndeterminateCheckbox = ({ indeterminate, ...rest }) => {
  const classes = useStyles();

  return (
    <div style={{ position: 'absolute', left: 8, zIndex: 12 }}>
      <Checkbox
        size="medium"
        color="primary"
        indeterminate={indeterminate}
        classes={classes}
        {...rest}
      />
    </div>
  );
};

export const addSelectionCol = (hooks) =>
  hooks.visibleColumns.push((cols) => [
    {
      id: 'selection',
      Header: ({ getToggleAllRowsSelectedProps }) => {
        const { checked, ...selectProps } = getToggleAllRowsSelectedProps();

        return <IndeterminateCheckbox {...selectProps} checked={checked} />;
      },
      Cell: ({ row }) => {
        const { checked, ...selectProps } = row.getToggleRowSelectedProps();

        return <IndeterminateCheckbox {...selectProps} checked={checked} />;
      },
      width: 1,
      style: { flex: '1 0 auto' },
      disableResizing: true,
    },
    ...cols,
  ]);

export const getToolbarHeight = (toolbarProps, selectable) => {
  if (!toolbarProps) {
    return 0;
  }

  if (toolbarProps.height) {
    return toolbarProps.height;
  }

  return selectable ? 60 : 35;
};
