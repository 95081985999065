import HttpCodes from './httpCodes';
import HttpError from './HttpError';

const headers = {
  contentType: 'Content-Type',
};

export const createHeadersFromOptions = (options) => {
  const requestHeaders =
    options.headers ||
    new Headers({
      Accept: 'application/json',
    });
  if (
    !requestHeaders.has(headers.contentType) &&
    !(options && (!options.method || options.method === 'GET')) &&
    !(options && options.body && options.body instanceof FormData)
  ) {
    requestHeaders.set(headers.contentType, 'application/json');
  }
  if (options && options.body && options.body instanceof FormData) {
    requestHeaders.delete(headers.contentType);
  }
  if (options.user && options.user.authenticated && options.user.token) {
    requestHeaders.set('Authorization', options.user.token);
  }

  return requestHeaders;
};

export const downloadLocalFile = (url, fileName) => {
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', decodeURI(fileName));
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};

const downloadFile = async (blob, fileName) => {
  try {
    const url = window.URL.createObjectURL(new Blob([blob]));
    downloadLocalFile(url, fileName);
  } catch (e) {
    console.error('Error in "fetchUtils -> incorrect file loading');
  }
};

export const fetchJson = async (url, options = {}) => {
  const requestHeaders = createHeadersFromOptions(options);

  try {
    const response = await fetch(url, { ...options, headers: requestHeaders });
    let json = {};
    let body = {};
    let file = {};

    // check is file
    if (response.headers.get('content-type') === 'application/octet-stream') {
      const fileName = response.headers.get('file-name') || options.name;
      const blob = await response.blob();
      file = {
        fileName,
        blob,
      };

      if (fileName && options?.download) {
        await downloadFile(blob, fileName);
      }
    } else {
      body = await response.text();
      try {
        json = JSON.parse(body);
      } catch (e) {
        json = body;
      }
    }

    if (response.status === HttpCodes.badRequest) {
      return Promise.reject(
        new HttpError(
          json && (json.message || json.messages),
          response.status,
          json
        )
      );
    }

    if (
      response.status < HttpCodes.minSuccess ||
      response.status > HttpCodes.maxSuccess
    ) {
      return Promise.reject(
        new HttpError(response.statusText, response.status, json)
      );
    }

    return Promise.resolve({
      status: response.status,
      headers: response.headers,
      body,
      json,
      file,
    });
  } catch (e) {
    if (!options?.signal?.aborted) {
      console.error('Error in "fetchUtils -> fetchJson"', e);
    }
    return Promise.reject(e);
  }
};

const isValidObject = (value) => {
  if (!value) {
    return false;
  }

  const isArray = Array.isArray(value);
  const isBuffer = typeof Buffer !== 'undefined' && Buffer.isBuffer(value);
  const isObject = Object.prototype.toString.call(value) === '[object Object]';
  const hasKeys = !!Object.keys(value).length;

  return !isArray && !isBuffer && isObject && hasKeys;
};

export const flattenObject = (value, path = []) => {
  if (isValidObject(value)) {
    return Object.assign(
      {},
      ...Object.keys(value).map((key) =>
        flattenObject(value[key], path.concat([key]))
      )
    );
  }
  return path.length ? { [path.join('.')]: value } : value;
};

export const paramsToPath = (path, params = {}) =>
  Object.entries(params).reduce(
    (p, [key, value]) => p.replace(`:${key}`, value),
    path
  );
