import React, { memo, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import { useDispatch, useSelector } from 'react-redux';

import { getProcedure } from 'common/utils/entitySelectors';
import useGetParams from 'common/hooks/useGetParams';
import GraphsContainer from './componets/GraphsContainer';
import { getListByTimeRange } from './ducks/selectors';
import ModalHeader from './componets/ModalHeader';
import { clearState } from './ducks';

const FullDisclosureModal = ({ onClose = () => null, selectedTime }) => {
  const { id } = useGetParams();
  const dispatch = useDispatch();
  const procedure = useSelector(getProcedure(id));
  const range = useSelector(getListByTimeRange(procedure, selectedTime));

  useEffect(() => () => dispatch(clearState()), [dispatch]);

  return (
    <Card
      flex="1"
      component={Box}
      flexWrap="nowrap"
      style={{ position: 'relative' }}
      flexDirection="column"
      alignItems="center"
    >
      <ModalHeader
        onClose={onClose}
        procedureId={id}
        selectedTime={selectedTime}
      />

      <Box
        height="91vh"
        maxHeight={1150}
        width="100%"
        paddingTop="52px"
        pb={2}
        flex={1}
        display="flex"
        alignItems="center"
        flexDirection="column"
      >
        <GraphsContainer
          range={range}
          procedureId={id}
          selectedTime={selectedTime}
        />
      </Box>
    </Card>
  );
};

export default memo(FullDisclosureModal);
