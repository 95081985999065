import { generateCancellableAction } from 'common/utils/actionHelpers';
import { createRequestActionTypes } from 'common/utils/actionTypesHelper';
import {
  eventsFilter,
  updateTemplatesWithAddedEvents,
  updateTemplatesWithRemovedEvents,
  updateTemplatesWithUpdatedEvents,
} from '../utils';

export const actionTypes = createRequestActionTypes(
  ['getEvent', 'getEventEcg', 'getEventWithEcg', 'setEventStatus', 'getEvents'],
  'localGrid'
);

const setEventsBySocket = 'localGrid.setEventsBySocket';
const clearStateActionType = 'localGrid.clearState';

export const initialState = {
  ecg: {},
  total: 0,
  error: null,
  events: [],
  loading: false,
};

const localGridReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.getEvents.requested:
      return {
        ...state,
        events: [],
        loading: true,
      };

    case actionTypes.getEventWithEcg.success: {
      const event = action.payload.data[0];
      if (!event) {
        return state;
      }

      const { ecg, ...restEventData } = action.payload.data[0];

      const events = [...state.events];
      const ecgs = { ...state.ecg };

      const existingEventIndex = events.findIndex(({ id }) => id === event.id);

      if (event?.ecg?.length) {
        ecgs[event.id] = ecg;
      }

      if (existingEventIndex >= 0) {
        events[existingEventIndex] = restEventData;
      } else {
        events.push(restEventData);
      }

      return { ...state, events, ecg: ecgs };
    }

    case actionTypes.getEvents.success: {
      return {
        ...state,
        events: action.payload.data,
        total: action.payload.data?.length,
        loading: false,
      };
    }

    case setEventsBySocket: {
      const { added, updated, removed, status, eventAbnormalities } =
        action.payload;

      const newUpdated = updated.filter(
        (e) => !state.events.find(({ id }) => e.id === id)
      );

      const addedFiltered = [...added, ...newUpdated].filter((e) =>
        eventsFilter(e, status, eventAbnormalities)
      );

      let events = [...state.events]
        .map((event) => {
          const target = updated.find(({ id }) => id === event.id);

          if (target) {
            const peData = event.patientEvent
              ? { coreAbnormalityType: target.abnormalityType }
              : {};

            return {
              ...event,
              ...peData,
              status: target.status,
              abnormalityType: event.patientEvent
                ? event.abnormalityType
                : target.abnormalityType,
            };
          }

          return event;
        })
        .filter((e) => eventsFilter(e, status, eventAbnormalities))
        .filter((e) => !removed.find((rmEvt) => rmEvt.id === e.id))
        .concat(addedFiltered);

      events = added?.length
        ? updateTemplatesWithAddedEvents(events, added, status)
        : events;
      events = updated.length
        ? updateTemplatesWithUpdatedEvents(events, updated, status)
        : events;
      events = removed.length
        ? updateTemplatesWithRemovedEvents(events, removed, status)
        : events;

      return {
        ...state,
        events: events.filter(Boolean),
      };
    }

    case actionTypes.getEvent.success: {
      const { data } = action.payload;
      const event = data[0];

      const events = [...state.events];
      const eventIndex = events.findIndex((evt) => evt.id === event.id);

      if (eventIndex >= 0) {
        events[eventIndex] = event;
        return {
          ...state,
          events,
        };
      }

      const newEvents = [...events, event];

      return {
        ...state,
        events: newEvents,
      };
    }

    case actionTypes.setEventStatus.success: {
      return {
        ...state,
        loading: false,
      };
    }

    case actionTypes.getEventEcg.success:
      return {
        ...state,
        ecg: {
          ...state.ecg,
          [action.requestPayload.filter.id]: action.payload.data,
        },
      };

    case actionTypes.setEventStatus.failed: {
      return {
        ...state,
        loading: false,
      };
    }

    case actionTypes.getEvents.failed:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case clearStateActionType:
      return initialState;

    default:
      return state;
  }
};

export const getProcedureEvents = (payload) =>
  generateCancellableAction({
    type: actionTypes.getEvents.requested,
    payload,
  });

export const setEventStatus = (
  payload,
  abnormalityType,
  isPatientEvent,
  prevEvents,
  onSuccess,
  onError
) => ({
  type: actionTypes.setEventStatus.requested,
  abnormalityType,
  prevEvents,
  isPatientEvent,
  payload,
  onSuccess,
  onError,
});

export const getEventEcg = (payload) =>
  generateCancellableAction({
    type: actionTypes.getEventEcg.requested,
    payload,
  });

export const getEvent = (payload) =>
  generateCancellableAction({
    type: actionTypes.getEvent.requested,
    payload,
  });

export const getEventWithEcg = (payload) =>
  generateCancellableAction({
    type: actionTypes.getEventWithEcg.requested,
    payload,
  });

export const updateEventsBySocket = (payload) => ({
  type: setEventsBySocket,
  payload,
});

export const clearState = () => ({ type: clearStateActionType });

export default localGridReducer;
