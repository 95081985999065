import { takeEvery, put, call, all } from 'redux-saga/effects';
import groupBy from 'lodash/groupBy';
import { v4 as uuidv4 } from 'uuid';
import uniqBy from 'lodash/uniqBy';
import moment from 'moment';

import dataProvider from 'store/dataProvider';
import resources from 'common/constants/resources';
import { hrGroupTypes, activityTypes } from 'common/constants/enums';
import { insertEmptyPointsForData } from 'common/utils/helpers/patientData';
import { searchCorrelations } from '../../utils/ongoing';
import { actionTypes } from '../reducer';

const getHighActivities = (activities) =>
  activities.reduce((acc, activity) => {
    const isHigh = activity.value === activityTypes.high;
    if (!isHigh && !acc?.length) {
      return acc;
    }

    const last = acc[acc.length - 1];

    if (isHigh) {
      acc.push(activity);
    } else if (last?.value) {
      acc.push({ ...activity, value: null });
    }

    return acc;
  }, []);

function* getCorrelationData(
  range,
  procedureId,
  acceptableEvents,
  isEscalated
) {
  const filter = {
    ...range,
    procedureId,
  };

  try {
    const allData = yield call(
      dataProvider.get,
      resources.report.correlationData,
      {
        filter: {
          ...filter,
          checkBeatsCount: true,
          groupType: hrGroupTypes.minute,
          abnormalityTypes: acceptableEvents,
          criticalActivity: 'Medium',
          criticalTemperature: 102,
        },
      }
    );

    const {
      activities: nonFilteredActivities,
      temperatures: nonFilteredTemperatures,
      patientEvents,
      groupedEcgEvents,
      heartRateSummary,
      groupedHeartRates,
    } = allData?.data || {};

    const activities = uniqBy(
      getHighActivities(nonFilteredActivities),
      (i) => i.date
    );
    const temperatures = uniqBy(nonFilteredTemperatures, (i) => i.date).filter(
      (i) => i.value >= 102
    );

    const groupedByAbnormality = groupBy(groupedEcgEvents, 'abnormalityType');

    const ecgEvents = Object.keys(groupedByAbnormality)
      .map((abnormalityType) => {
        const { items } = groupedByAbnormality[abnormalityType][0];
        return items.map((item) => ({
          ...item,
          abnormalityType,
          id: uuidv4(),
        }));
      })
      .flat()
      // filter events that starts at the end of range
      .filter((e) => !moment(e.dateFrom).isSame(range.dateTo));

    const totals = Object.keys(groupedByAbnormality).reduce(
      (acc, abnormalityType) => {
        const { totalCount } = groupedByAbnormality[abnormalityType][0];

        return {
          ...acc,
          [abnormalityType]: totalCount,
        };
      },
      {}
    );

    const correlations = isEscalated
      ? []
      : searchCorrelations(ecgEvents, patientEvents, {
          temperatures,
          activities,
        });

    return {
      range,
      totals,
      ecgEvents,
      correlations,
      patientEvents,
      hrSummary: heartRateSummary,
      nonEcgEvents: { temperatures, activities },
      hrData: insertEmptyPointsForData(groupedHeartRates, 'minute'),
    };
  } catch (e) {
    return {
      range,
      totals: [],
      ecgEvents: [],
      correlations: [],
      patientEvents: [],
      hrSummary: { max: 0, min: 0, average: 0 },
      nonEcgEvents: {
        temperature: [],
        activity: [],
        bodyPosition: [],
        oxygenSaturation: [],
        respiration: [],
      },
      hrData: [],
    };
  }
}

const getDataForCorrelationGraphs = (actionType) =>
  function* getData(action) {
    const { ranges, procedureId, acceptableEvents, isEscalated } =
      action.payload;

    try {
      const data = yield all(
        ranges.map((range) =>
          call(
            getCorrelationData,
            range,
            procedureId,
            acceptableEvents,
            isEscalated
          )
        )
      );

      yield put({
        type: actionType.success,
        requestPayload: { ...action.payload, date: action.date },
        payload: data,
      });
    } catch (error) {
      yield put({
        type: actionType.failed,
        error,
      });
    }
  };

export default function* getCorrelationDataSaga() {
  yield takeEvery(
    actionTypes.getCorrelationData.requested,
    getDataForCorrelationGraphs(actionTypes.getCorrelationData)
  );

  yield takeEvery(
    actionTypes.getEventStudyTrendData.requested,
    getDataForCorrelationGraphs(actionTypes.getEventStudyTrendData)
  );
}
