import { put, call, takeLatest } from 'redux-saga/effects';

import { requestSaga } from 'common/utils/generators';
import resources from 'common/constants/resources';
import dataProvider from 'store/dataProvider';

import { allActionTypes } from './reducer';
import { resourceParams, tabs } from '../constants';

const appResourcesActionTypes = allActionTypes[tabs.tutorials];

function* getFiles() {
  yield requestSaga({
    actionType: appResourcesActionTypes.getList,
    request: dataProvider.getList,
    resource: resources.files.index,
  });
}

function* archiveFiles() {
  yield requestSaga({
    actionType: appResourcesActionTypes.deleteData,
    request: dataProvider.createOne,
    resource: resources.files.archive,
  });
}

function* downloadFiles() {
  yield requestSaga({
    request: dataProvider.getFile,
    resource: resources.files.download,
    actionType: appResourcesActionTypes.download,
  });
}

async function upload(file) {
  try {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileName', file.name);
    const { data } = await dataProvider.createOneFormData(
      resources.files.upload,
      { body: formData }
    );

    return data;
  } catch (error) {
    return null;
  }
}

function* save(action) {
  const { payload, onSuccess, onError } = action;
  const { file, ...restFileParams } = payload;

  try {
    const uploadedFile = yield call(upload, file);

    const fileData = {
      ...uploadedFile,
      ...restFileParams,
      ...resourceParams,
    };

    const { data } = yield call(
      dataProvider.createOne,
      resources.files.index,
      fileData
    );
    yield put({
      type: appResourcesActionTypes.addOne.success,
      payload: { data },
    });

    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    if (onError) {
      onError();
    }

    yield put({ type: appResourcesActionTypes.addOne.failed, payload: error });
  }
}

function* saveFile() {
  yield takeLatest(appResourcesActionTypes.addOne.requested, save);
}

export default [getFiles, saveFile, archiveFiles, downloadFiles];
