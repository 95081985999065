import React, { useMemo } from 'react';
import * as d3 from 'd3';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';

import LoadingProgress from 'common/components/LoadingProgress';
import { getEcg, getLoading, getYMinMaxValues } from './ducks/selectors';
import EventStripGraph from './components/EventStripGraph';
import useStyles from './styles';

const EventGraphContainer = (props) => {
  const {
    center = '',
    resource = '',
    endRange = '',
    startRange = '',
    height = 0,
    isTriageView = false,
  } = props;

  const styles = useStyles();
  const ecg = useSelector(getEcg(resource));
  const loading = useSelector(getLoading(resource));
  const { min: yMin, max: yMax } = useSelector(getYMinMaxValues(resource));

  const xDomain = useMemo(() => {
    if (ecg?.length) {
      return d3.extent(ecg, (d) => new Date(d.time));
    }

    return [startRange, endRange].map((v) => new Date(v));
  }, [ecg, startRange, endRange]);

  const yDomain = useMemo(() => [yMin - 70, yMax + 225], [yMin, yMax]);

  const focusOptions = useMemo(() => {
    return {
      center,
      domain: {
        x: xDomain,
        y: yDomain,
      },
      duration: 10,
    };
  }, [center, xDomain, yDomain]);

  if (isTriageView && !ecg.length) {
    return (
      <>
        {loading && (
          <div className={styles.loadingProgress} style={{ height }}>
            <LoadingProgress />
          </div>
        )}

        <div style={{ height }} className={styles.noDataContainer}>
          {!loading && !ecg.length && (
            <Typography variant="h4">No data</Typography>
          )}
        </div>
      </>
    );
  }

  return (
    <EventStripGraph
      {...props}
      height={height}
      focusOptions={focusOptions}
      isModalView={isTriageView}
    />
  );
};

EventGraphContainer.propTypes = {
  height: PropTypes.number,
  inverted: PropTypes.bool,
  isActual: PropTypes.bool,
  center: PropTypes.string,
  resource: PropTypes.string,
  endRange: PropTypes.string,
  startRange: PropTypes.string,
  prevCenter: PropTypes.string,
  isTriageView: PropTypes.bool,
  range: PropTypes.arrayOf(PropTypes.string),
};

export default EventGraphContainer;
