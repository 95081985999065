import React, { useMemo } from 'react';
import get from 'lodash/get';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { useController } from 'react-hook-form';
import FormHelperText from '@material-ui/core/FormHelperText';

import { mimeTypes } from 'common/constants/enums';

const UploadButton = ({
  allowedFormats,
  name,
  rules,
  form,
  control,
  label = 'upload',
  multiple = false,
}) => {
  const { errors } = form;
  const { field } = useController({
    name,
    rules,
    control,
  });

  const errorText = useMemo(
    () => (get(errors, field.name) ? get(errors, field.name).message : ''),
    [errors, field.name]
  );

  const handleChangeFile = (event) => {
    const { files } = event.target;
    const [file] = files;

    const data = multiple ? files : file;

    field.onChange(data);
  };

  const acceptFileTypes = useMemo(
    () => allowedFormats.map((format) => mimeTypes[format.toLowerCase()]),
    [allowedFormats]
  );

  const selectedData = useMemo(() => {
    if (!field?.value) {
      return '';
    }

    if (Array.isArray(field?.value)) {
      return field?.value.map((f) => f.name).join(', ');
    }

    return field?.value?.name;
  }, [field?.value]);

  return (
    <Grid container direction="column" style={{ position: 'relative' }}>
      <Grid container wrap="nowrap" alignItems="center" spacing={1}>
        <Grid item>
          <Button variant="contained" component="label">
            {label}
            <input
              hidden
              type="file"
              accept={acceptFileTypes}
              multiple={multiple}
              onChange={handleChangeFile}
            />
          </Button>
        </Grid>

        <Grid item>{selectedData}</Grid>
      </Grid>

      {errorText && (
        <FormHelperText error margin="dense" style={{ left: 8 }}>
          {errorText}
        </FormHelperText>
      )}
    </Grid>
  );
};

export default UploadButton;
