import React from 'react';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/styles/makeStyles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles({
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 100,
  },
});

const FallbackLoader = () => {
  const styles = useStyles();

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      className={styles.container}
    >
      <CircularProgress data-testid="circular-progress" size={80} />
    </Grid>
  );
};

export default FallbackLoader;
