import { ecgEventStatus } from 'common/constants/enums';
import { createRequestActionTypes } from 'common/utils/actionTypesHelper';
import { visibleEventsForEscalatedTriage } from '../constants';

export const actionTypes = createRequestActionTypes(
  ['getEscalatedEvents', 'getEventTotalsByStatus', 'getProcedureEventTotals'],
  'procedureMonitoringTriages'
);

const clearEventGroupsActionType =
  'procedureMonitoringTriages.clearEventGroups';
const clearStateActionType = 'procedureMonitoringTriages.clearState';
const updateEventsBySocketActionType =
  'procedureMonitoringTriages.updateEscalatedEventsBySocketActionType';

export const monitoringTriagesInitialState = {
  events: [],
  eventTotals: {},
  totalsByStatus: {
    [ecgEventStatus.denied]: 0,
    [ecgEventStatus.accepted]: 0,
    [ecgEventStatus.included]: 0,
    aiDeclined: 0,
  },
  eventsEcg: {},
  loading: false,
  error: null,
};

const monitoringTriagesReducer = (
  state = monitoringTriagesInitialState,
  action
) => {
  switch (action.type) {
    case actionTypes.getEscalatedEvents.requested:
      return {
        ...state,
        loading: true,
      };

    case actionTypes.getEscalatedEvents.success: {
      const { data, totals } = action.payload;

      return {
        ...state,
        events: data,
        loading: false,
        eventTotals: totals,
      };
    }

    case updateEventsBySocketActionType: {
      const { added, updated } = action.payload;

      const filteredAdded = added.filter(({ abnormalityType }) =>
        visibleEventsForEscalatedTriage.includes(abnormalityType)
      );

      const withUpdated = state.events.map((event) => {
        const newEvent = updated.find((e) => e.id === event.id);
        return newEvent ? { ...event, ...newEvent } : event;
      });

      const escalated = [...withUpdated, ...filteredAdded].filter((event) => {
        const newEvent = updated.find((e) => e.id === event.id);

        return newEvent ? !newEvent.status : !!event;
      });

      return {
        ...state,
        events: escalated,
      };
    }

    case actionTypes.getProcedureEventTotals.success: {
      const eventTotals = action.payload.data.reduce(
        (acc, val) => ({ ...acc, [val.type]: val.count }),
        {}
      );

      return {
        ...state,
        eventTotals,
      };
    }

    case actionTypes.getEventTotalsByStatus.success: {
      const {
        payload,
        requestPayload: { tab },
      } = action;

      return {
        ...state,
        totalsByStatus: {
          ...state.totalsByStatus,
          [tab]: payload.data,
        },
      };
    }

    case clearEventGroupsActionType:
      return {
        ...state,
        eventTotals: {},
        eventGroups: {},
      };

    case clearStateActionType:
      return monitoringTriagesInitialState;

    default:
      return state;
  }
};

export const getEscalatedEvents = (payload) => ({
  type: actionTypes.getEscalatedEvents.requested,
  payload,
});

export const updateEscalatedEventsBySocket = (payload) => ({
  type: updateEventsBySocketActionType,
  payload,
});

export const getProcedureEventTotals = (payload) => ({
  type: actionTypes.getProcedureEventTotals.requested,
  payload,
});

export const getEventTotalsByStatus = (payload) => ({
  type: actionTypes.getEventTotalsByStatus.requested,
  payload,
});

export const clearEventGroups = () => ({ type: clearEventGroupsActionType });
export const clearState = () => ({ type: clearStateActionType });

export default monitoringTriagesReducer;
