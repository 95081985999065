import React, { useEffect, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import useAuthProvider from 'store/authProvider/useAuthProvider';
import { physicianTypesEnum } from 'common/constants/enums';

import {
  physicianModalColumns,
  physicianModalTableActions,
} from '../constants';
import ReportTable from './ReportTable';
import { reportTableActions } from '../ducks';

const defaultPageSize = 10;
const defaultSort = { field: 'date' }; // desc: true,
const rowsPerPage = [10, 25, 50];

const toolbarActions = { title: 'Reports', height: 45 };

const ReportsModal = ({ onClose, procedure, procedureId }) => {
  const dispatch = useDispatch();
  const [params, setParams] = useState();
  const { profileData, fetchUser } = useAuthProvider();

  const isContracted = useMemo(
    () => profileData?.physicianType === physicianTypesEnum.contracted,
    [profileData]
  );

  const columns = useMemo(() => {
    if (!isContracted) {
      return physicianModalColumns.filter((col) => !col.contracted);
    }

    return physicianModalColumns;
  }, [isContracted]);

  const fetchReports = useCallback(() => {
    if (!procedureId || !params) {
      return;
    }

    dispatch(
      reportTableActions.getReports({
        ...params,
        filter: { procedureId },
      })
    );
  }, [dispatch, params, procedureId]);

  const getUserData = useCallback(async () => {
    if (profileData) {
      return;
    }

    await fetchUser();
  }, [profileData, fetchUser]);

  const onClickClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const tableActions = useMemo(
    () => physicianModalTableActions(onClickClose),
    [onClickClose]
  );

  useEffect(() => {
    fetchReports();
  }, [fetchReports]);

  useEffect(() => {
    getUserData();
  }, [getUserData]);

  return (
    <div style={{ overflow: 'hidden' }}>
      <ReportTable
        physicianView
        columns={columns}
        selectable={false}
        rowsPerPage={defaultPageSize}
        defaultSort={defaultSort}
        procedure={procedure}
        procedureId={procedureId}
        updateParams={setParams}
        tableActions={tableActions}
        toolbarActions={toolbarActions}
        rowsPerPageOptions={rowsPerPage}
      />
    </div>
  );
};

ReportsModal.propTypes = {
  procedureId: PropTypes.string,
  procedure: PropTypes.shape(),
  onClose: PropTypes.func,
};

export default ReportsModal;
