import { call, put, takeEvery } from 'redux-saga/effects';
import resources from 'common/constants/resources';
import dataProvider from 'store/dataProvider';
import { queryingPath } from 'common/utils/helpers/url';
import { kitsActionTypes } from '../reducer';

function* updateStatus(action) {
  const {
    payload: { id, status },
    onSuccess,
    onError,
  } = action;
  try {
    const url = queryingPath(resources.kit.status, { id });
    const { data } = yield call(dataProvider.updateOnly, url, status);

    yield put({
      type: kitsActionTypes.updateMany.success,
      requestPayload: action.payload,
      payload: data,
    });

    if (onSuccess) {
      onSuccess();
    }
  } catch (e) {
    const { message } = e;
    yield put({ type: kitsActionTypes.updateStatus.failed, message });
    onError(e);
  }
}

export default function* updateKitsStatus() {
  yield takeEvery(kitsActionTypes.updateStatus.requested, updateStatus);
}
