const localUrl = 'http://localhost:3030';
const developUrl = 'http://localhost:3000';

const primaryLog = window.console.log;
export const isDevelop = window.location.origin === developUrl;

const checkServer = async () => {
  try {
    await fetch(localUrl, {
      method: 'POST',
      body: JSON.stringify([]),
    });

    return true;
  } catch (error) {
    return false;
  }
};

const remoteLogger = (...messages) => {
  primaryLog(...messages);

  return fetch(localUrl, { method: 'POST', body: JSON.stringify(messages) });
};

export default async () => {
  const isServerConnectable = await checkServer();

  if (isDevelop && isServerConnectable) {
    window.console.log = remoteLogger;
  }
};
