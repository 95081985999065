import React, { useMemo, useState, useCallback } from 'react';
import * as d3 from 'd3';
import PropTypes from 'prop-types';

import { containerChildProps } from 'common/constants/sharedPropTypes';
import { xAxisGridGen, yAxisGridGen } from '../utils';

const D3Grid = ({ parent, color, strokeWidth }) => {
  const [xGridRef, setXGridRef] = useState(null);
  const [yGridRef, setYGridRef] = useState(null);
  const {
    width,
    height,
    duration,
    xScale,
    yScale,
    options = {},
    scale,
  } = parent;
  const { margin, settings = {} } = options;

  const xAxisGrid = useCallback(
    (...args) => xAxisGridGen(height, margin, settings.speed)(...args),
    [height, margin, settings.speed]
  );

  const yAxisGrid = useCallback(
    (...args) =>
      yAxisGridGen(
        width,
        height,
        margin,
        duration,
        settings.amplitude
      )(...args),
    [width, height, margin, duration, settings.amplitude]
  );

  useMemo(
    () => d3.select(xGridRef).call(xAxisGrid, scale || xScale),
    [scale, xScale, xGridRef, xAxisGrid]
  );

  useMemo(
    () => d3.select(yGridRef).call(yAxisGrid, yScale),
    [yScale, yGridRef, yAxisGrid]
  );

  return (
    <>
      <g
        color={color}
        className="grid-x"
        ref={setXGridRef}
        strokeWidth={strokeWidth}
      />
      <g
        color={color}
        className="grid-y"
        ref={setYGridRef}
        strokeWidth={strokeWidth}
      />
    </>
  );
};

D3Grid.defaultProps = {
  strokeWidth: 0.3,
  color: 'lightcoral',
};

D3Grid.propTypes = {
  color: PropTypes.string,
  parent: containerChildProps,
  strokeWidth: PropTypes.number,
};

export default D3Grid;
