import { requestSaga } from 'common/utils/generators';
import dataProvider from 'store/dataProvider';
import resources from 'common/constants/resources';

import { kitsActionTypes } from '../reducer';

function* fetchKitSaga() {
  yield requestSaga({
    actionType: kitsActionTypes.getOne,
    request: dataProvider.getOne,
    resource: resources.kit.item,
  });
}

export default fetchKitSaga;
