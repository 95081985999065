import { requestSaga } from 'common/utils/generators';
import resources from 'common/constants/resources';
import dataProvider from 'store/dataProvider';
import { patchesActionTypes } from '../reducer';

function* updatePatches() {
  yield requestSaga({
    actionType: patchesActionTypes.updateMany,
    request: dataProvider.updateList,
    resource: resources.patch.index,
  });
}

export default updatePatches;
