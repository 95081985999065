import { useLocation } from 'react-router-dom';
import { match, pathToRegexp } from 'path-to-regexp';

import { urlEnum } from 'common/constants/path';

export const useGetParams = () => {
  const location = useLocation();
  const matchPath = Object.keys(urlEnum).find((path) => {
    const regexp = pathToRegexp(path);
    return regexp.exec(location.pathname);
  });

  if (!matchPath) {
    return {};
  }

  const getMatch = match(matchPath);

  return getMatch(location.pathname)?.params || {};
};

export default useGetParams;
