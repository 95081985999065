export const normalizeFormDefaultValues = (values) =>
  Object.keys(values).reduce((acc, key) => {
    let value = values[key];
    if (
      value &&
      !Array.isArray(value) &&
      typeof value === 'object' &&
      !(value instanceof Date)
    ) {
      return { ...acc, [key]: normalizeFormDefaultValues(value) };
    }

    if (Array.isArray(value)) {
      return {
        ...acc,
        [key]: value,
      };
    }

    switch (typeof value) {
      case 'string':
      case 'number':
        value = String(value);
        break;
      case 'boolean':
        value = values[key];
        break;
      default:
        value = '';
    }

    return {
      ...acc,
      [key]: value,
    };
  }, {});

export const trimValues = (values) =>
  Object.keys(values).reduce((acc, key) => {
    const value = values[key];
    if (
      value &&
      !Array.isArray(value) &&
      typeof value === 'object' &&
      !(value instanceof Date)
    ) {
      return { ...acc, [key]: trimValues(value) };
    }

    const newValue = typeof value === 'string' ? value.trim() : value;

    return {
      ...acc,
      [key]: typeof newValue === 'number' || value ? value : null,
    };
  }, {});
