import dataProvider from 'store/dataProvider';
import resources from 'common/constants/resources';
import { requestSaga } from 'common/utils/generators';

export const createLockPatientSaga = (actionType) =>
  function* lockPatients() {
    yield requestSaga({
      actionType,
      request: dataProvider.createOne,
      resource: resources.patient.lockPatients,
    });
  };

export const createUnlockPatientSaga = (actionType) =>
  function* unlockPatients() {
    yield requestSaga({
      actionType,
      request: dataProvider.createOne,
      resource: resources.patient.unlockPatients,
    });
  };
