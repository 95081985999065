import { createRequestActionTypes } from 'common/utils/actionTypesHelper';

import { presetsEnum, parentEntityOfPresets } from '../constants';

export const presetsActionTypes = createRequestActionTypes(
  ['get', 'set', 'update', 'remove', 'clearState'],
  'presets'
);

const defaultPresets = {
  [presetsEnum.sinusTachyThreshold]: '',
  [presetsEnum.bradyThreshold]: '',
  [presetsEnum.supraVentricularTachyThreshold]: '',
  [presetsEnum.ventricularTachyThreshold]: '',
  [presetsEnum.pauseThreshold]: '',
  [presetsEnum.prematureIndex]: '',
  // [presetsEnum.amplitude]: amplitudeValues.standard,
};

const initialState = {
  data: {
    [parentEntityOfPresets.tenant]: defaultPresets,
    [parentEntityOfPresets.procedure]: defaultPresets,
    [parentEntityOfPresets.physician]: defaultPresets,
  },
  prevValueExisting: {
    [parentEntityOfPresets.tenant]: false,
    [parentEntityOfPresets.procedure]: false,
    [parentEntityOfPresets.physician]: false,
  },
  loading: false,
  error: null,
};

const getStateWithNewPresents = (state, action) => {
  const { entity } = action;

  const presets = Object.keys(action.payload || {})
    .filter((key) => key in presetsEnum)
    .reduce((acc, val) => ({ ...acc, [val]: action.payload[val] }), {});

  return {
    ...state,
    data: {
      ...state.data,
      [entity]: {
        ...state.data[entity],
        ...presets,
      },
    },
    loading: false,
  };
};

const presetsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case presetsActionTypes.get.requested:
    case presetsActionTypes.set.requested:
      return { ...state, loading: true, error: null };

    case presetsActionTypes.get.success: {
      const { entity, payload } = action;
      const newState = getStateWithNewPresents(state, action);

      return {
        ...newState,
        prevValueExisting: {
          ...state.prevValueExisting,
          [entity]:
            payload[presetsEnum.pauseThreshold] &&
            payload[presetsEnum.bradyThreshold] &&
            payload[presetsEnum.prematureIndex] &&
            payload[presetsEnum.sinusTachyThreshold] &&
            payload[presetsEnum.ventricularTachyThreshold] &&
            payload[presetsEnum.supraVentricularTachyThreshold],
          // && payload[presetsEnum.amplitude],
        },
      };
    }
    case presetsActionTypes.set.success:
    case presetsActionTypes.update.requested: {
      return getStateWithNewPresents(state, action);
    }

    case presetsActionTypes.get.failed:
    case presetsActionTypes.set.failed:
      return { ...state, loading: false, error: action.message };

    case presetsActionTypes.clearState.requested:
      return initialState;

    default:
      return state;
  }
};

const createSetter = (entity) => (payload, onSuccess, onError) => ({
  type: presetsActionTypes.set.requested,
  payload,
  entity,
  onSuccess,
  onError,
});

const createGetter = (entity) => (payload) => ({
  type: presetsActionTypes.set.requested,
  payload,
  entity,
});

const createRemover =
  (entity) =>
  ({ id, ...payload }, onSuccess, onError) => ({
    type: presetsActionTypes.remove.requested,
    payload: { params: { id }, payload },
    entity,
    onSuccess,
    onError,
  });

export const getPresetsForEntity = ({ entity, ...payload }) => ({
  type: presetsActionTypes.get.requested,
  payload,
  entity,
});

export const setPresetsForEntity = ({ entity, ...data }) => ({
  type: presetsActionTypes.set.requested,
  payload: { data },
  entity,
});

// tenant
export const getTenantPresets = createGetter(parentEntityOfPresets.tenant);
// procedure
export const getProcedurePresets = createGetter(
  parentEntityOfPresets.procedure
);
// physician
export const getPhysicianPresets = createGetter(
  parentEntityOfPresets.physician
);

export const presetSetters = {
  [parentEntityOfPresets.tenant]: createSetter(parentEntityOfPresets.tenant),
  [parentEntityOfPresets.procedure]: createSetter(
    parentEntityOfPresets.procedure
  ),
  [parentEntityOfPresets.physician]: createSetter(
    parentEntityOfPresets.physician
  ),
};

export const presetRemovers = {
  [parentEntityOfPresets.tenant]: createRemover(parentEntityOfPresets.tenant),
  [parentEntityOfPresets.procedure]: createRemover(
    parentEntityOfPresets.procedure
  ),
  [parentEntityOfPresets.physician]: createRemover(
    parentEntityOfPresets.physician
  ),
};

export const updatePresets = ({ entity, ...payload }) => ({
  type: presetsActionTypes.update.requested,
  entity,
  payload,
});

export const clearPresetState = () => ({
  type: presetsActionTypes.clearState.requested,
});

export default presetsReducer;
