import { requestSaga } from 'common/utils/generators';
import resources from 'common/constants/resources';
import dataProvider from 'store/dataProvider';
import { actionTypes } from '../reducer';

function* createUser() {
  yield requestSaga({
    actionType: actionTypes.create,
    request: dataProvider.createOne,
    resource: resources.user.index,
  });
}

export default createUser;
