import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import { ecgEventStatus } from 'common/constants/enums';
import { abnormalityTypeEnum } from 'common/constants/ecgEnums';
import { getCenterBetweenDatesUTC } from 'common/utils/helpers/date';

export const mapHrEvents = (hrs = []) =>
  hrs
    .map(({ value, dateFrom, dateTo }, index) => {
      const center = getCenterBetweenDatesUTC(dateFrom, dateTo);

      return {
        dateTo,
        dateFrom,
        index,
        key: uuidv4(),
        heartRateMin: value,
        heartRateMax: value,
        avgHeartRate: value,
        heartRateAverage: value,
        dateFromEvent: center.toISOString(),
        dateToEvent: center.toISOString(),
        status: ecgEventStatus.accepted,
        abnormalityType: abnormalityTypeEnum.highestHR,
      };
    })
    .filter(Boolean);

export const updateHREvent = (newEvent, currentEvent) => {
  const isSameByRange = moment(newEvent?.dateFrom).isSame(
    currentEvent.dateFrom
  );

  const isSameByEventRange = moment(newEvent?.dateFromEvent).isSame(
    currentEvent.dateFromEvent
  );

  if (isSameByRange || isSameByEventRange) {
    return {
      ...currentEvent,
      id: newEvent.id,
      status: newEvent.status,
      rotateEcg: newEvent.rotateEcg,
      customDateFrom: newEvent.customDateFrom,
      customDateTo: newEvent.customDateTo,
      customAvgHeartRate: newEvent?.customAvgHeartRate,
    };
  }

  return { ...currentEvent, status: ecgEventStatus.accepted };
};

export const updateDailyHREventsForState = (
  lowestHRs,
  highestHRs,
  significantEventData = {}
) => {
  const { lowestHR, highestHR } = significantEventData;

  const allLowest = lowestHR
    ? lowestHRs.map((current) => updateHREvent(lowestHR, current))
    : lowestHRs;
  const allHighest = highestHR
    ? highestHRs.map((current) => updateHREvent(highestHR, current))
    : highestHRs;

  return [allLowest, allHighest];
};

export const mapHourEvent = (event = {}) => ({
  ecg: [],
  key: uuidv4(),
  heartRateMin: 0,
  heartRateMax: 0,
  avgHeartRate: 0,
  heartRateAverage: 0,
  dateFrom: event.dateTime,
  dateTo: moment(event.dateTime).add(10, 'seconds').toISOString(),
  status: ecgEventStatus.accepted,
  abnormalityType: abnormalityTypeEnum.includedHours,
  ...event,
});
