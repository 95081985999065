import { createSelector } from 'reselect';
import { modalTypes } from 'common/constants/enums';

export const getModals = createSelector(
  (store) => store.modals,
  (modals) => modals
);

export const getIsSimilarEventsModal = createSelector(getModals, (modals) =>
  modals.find(({ modal }) => modal === modalTypes.similarEventsGridModal)
);

export const getEventStripModal = createSelector(getModals, (modals) =>
  modals.find(({ modal }) => modal === modalTypes.eventStripModal)
);
