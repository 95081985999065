import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles(({ spacing }) => ({
  attachmentsHeader: {
    padding: spacing(2),
  },

  filesWrapper: {
    borderRadius: 4,
    margin: spacing(2, 0),
    padding: spacing(0, 2),
    border: '1px solid grey',
  },
  uploadWrapper: { display: 'flex', alignItems: 'center' },
}));
