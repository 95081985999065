import moment from 'moment';

import { submitEditsStatusLabel } from 'common/constants/enums';

const DATE_FORMAT = 'L';

const formatDate = ({ value }) => moment(value).format(DATE_FORMAT);

export const physicianModalColumns = [
  {
    accessor: 'title',
    Header: 'Report',
    width: 30,
  },
  {
    accessor: 'date',
    Header: 'Date',
    valueGetter: formatDate,
    width: 20,
  },
  {
    accessor: 'type',
    Header: 'Type',
    width: 15,
  },
  {
    accessor: 'reviewed',
    Header: 'Viewed',
    width: 20,
    valueGetter: ({ value }) => (value ? 'Yes' : 'No'),
    disableSortBy: true,
  },
  {
    accessor: 'submitEditsStatus',
    Header: 'Status',
    width: 20,
    valueGetter: ({ value }) =>
      value
        ? [value]
            .flat()
            .map((s) => submitEditsStatusLabel[s])
            .join(', ')
        : 'Empty',
    contracted: true,
    disableSortBy: true,
  },
];
