import { requestSaga } from 'common/utils/generators';
import dataProvider from 'store/dataProvider';
import resources from 'common/constants/resources';
import { procedureActionTypes } from '../reducer';

function* fetchProcedureSaga() {
  yield requestSaga({
    actionType: procedureActionTypes.getOne,
    request: dataProvider.getOne,
    resource: resources.procedure.item,
  });
}

export default fetchProcedureSaga;
