import { requestSaga } from 'common/utils/generators';
import resources from 'common/constants/resources';
import dataProvider from 'store/dataProvider';
import { physicianActionTypes } from '../reducer';

function* updatePhysician() {
  yield requestSaga({
    actionType: physicianActionTypes.update,
    request: dataProvider.updateOne,
    resource: resources.physician.item,
  });
}

export default updatePhysician;
