import createDataProvider from 'common/utils/providerHelpers/createDataProvider';
import { API_URL, API_AUTHORIZATION_URL } from 'config/api';

const dataProvider = createDataProvider({
  apiBaseUrl: API_URL,
  apiAuthUrl: API_AUTHORIZATION_URL,
  fakeData: {},
});

export default dataProvider;
