import roles, { roleActions } from './roles';
import rolesSagas from './roles/sagas';
import * as roleSelectors from './roles/selectors';

import commonSagas from './general/sagas';

import {
  modalActions,
  eventsActions,
  reportActions,
  patientActions,
  procedureActions,
  monitoringActions,
} from './general/actions';

const commonReducers = {
  roles,
};

export {
  roleActions,
  modalActions,
  eventsActions,
  reportActions,
  patientActions,
  procedureActions,
  monitoringActions,
};

export const commonDucksSelectors = {
  roleSelectors,
};

export const commonDucksSaga = [rolesSagas, commonSagas].flat();

export default commonReducers;
