import { createRequestActionTypes } from 'common/utils/actionTypesHelper';

export const attachmentsActionTypes = createRequestActionTypes(
  ['getList', 'upload', 'removeUploaded', 'save', 'archive', 'download'],
  'attachments'
);

const initialState = {
  loading: false,
  isUploading: false,
  data: [],
  uploads: [],
  error: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case attachmentsActionTypes.archive.requested:
    case attachmentsActionTypes.getList.requested:
      return { ...state, loading: true };
    case attachmentsActionTypes.getList.success: {
      const {
        payload: { data, total },
      } = action;
      return {
        ...state,
        loading: false,
        data,
        total,
      };
    }
    case attachmentsActionTypes.archive.success: {
      const { ids } = action;
      return {
        ...state,
        loading: false,
        data: state.data.filter((item) => !ids.includes(item.id)),
      };
    }
    case attachmentsActionTypes.upload.requested:
      return {
        ...state,
        isUploading: true,
      };
    case attachmentsActionTypes.upload.success: {
      const { data } = action;
      return {
        ...state,
        isUploading: false,
        uploads: [...state.uploads, data],
      };
    }
    case attachmentsActionTypes.removeUploaded.requested: {
      const { id } = action;
      return {
        ...state,
        uploads: state.uploads.filter((file) => file.id !== id),
      };
    }
    case attachmentsActionTypes.save.requested: {
      return {
        ...state,
        isUploading: true,
      };
    }
    case attachmentsActionTypes.save.success: {
      const { data } = action;
      return {
        ...state,
        isUploading: false,
        data: [...data, ...state.data],
        uploads: [],
      };
    }

    case attachmentsActionTypes.getList.failed:
    case attachmentsActionTypes.upload.failed:
    case attachmentsActionTypes.save.failed:
    case attachmentsActionTypes.archive.failed:
      return {
        ...state,
        ...action.payload,
        loading: false,
        isUploading: false,
        error: action.message,
      };
    default:
      return state;
  }
};

export const fetchAttachments = (payload) => ({
  type: attachmentsActionTypes.getList.requested,
  payload,
});

export const uploadFile = (file, onSuccess) => {
  return {
    type: attachmentsActionTypes.upload.requested,
    payload: { file },
    onSuccess,
  };
};

export const saveFiles = (payload, onSuccess, onError) => ({
  type: attachmentsActionTypes.save.requested,
  payload,
  onSuccess,
  onError,
});

export const removeUploaded = (id) => ({
  type: attachmentsActionTypes.removeUploaded.requested,
  id,
});

export const downloadFile = (id, name) => ({
  type: attachmentsActionTypes.download.requested,
  payload: { params: { id }, name, download: true },
});

export const archiveFiles = (ids, onSuccess, onError) => ({
  type: attachmentsActionTypes.archive.requested,
  ids,
  onSuccess,
  onError,
});
