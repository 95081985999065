import PropTypes from 'prop-types';

import { abnormalityTypeEnum } from './ecgEnums';
import { symptomEnum, ecgEventStatus } from './enums';

export const ecgStripType = PropTypes.shape({
  time: PropTypes.string,
  value: PropTypes.number,
});

export const clipPath = PropTypes.shape({
  id: PropTypes.string,
  href: PropTypes.string,
  toString: PropTypes.func,
});

export const settingProps = PropTypes.shape({
  speed: PropTypes.number,
  amplitude: PropTypes.number,
});

export const paddingProps = PropTypes.oneOfType([
  PropTypes.number,
  PropTypes.shape({
    top: PropTypes.number,
    bottom: PropTypes.number,
    left: PropTypes.number,
    right: PropTypes.number,
  }),
]);

export const containerOptions = PropTypes.shape({
  margin: paddingProps,
  padding: paddingProps,
  xPropKey: PropTypes.string,
  yPropKey: PropTypes.string,
});

export const containerChildProps = PropTypes.shape({
  data: PropTypes.arrayOf(ecgStripType),
  clip: clipPath,
  settings: settingProps,
  width: PropTypes.number,
  height: PropTypes.number,
  duration: PropTypes.number,
  options: containerOptions,
  center: PropTypes.oneOfType([
    PropTypes.shape(),
    PropTypes.string,
    PropTypes.number,
  ]),
  ref: PropTypes.oneOfType([PropTypes.element, PropTypes.object]),
});

export const focusProps = PropTypes.shape({
  center: PropTypes.oneOfType([
    PropTypes.shape(),
    PropTypes.string,
    PropTypes.number,
  ]),
  duration: PropTypes.number,
});

export const axesGridChildrenPropTypes = {
  id: PropTypes.string,
  color: PropTypes.string,
  xScale: PropTypes.func,
  yScale: PropTypes.func,
  time: PropTypes.string,
  lineHeight: PropTypes.number,
  strokeWidth: PropTypes.number,
  commonTriage: PropTypes.bool,
  longEventLine: PropTypes.bool,
  nonEcgPosition: PropTypes.number,
  clickable: PropTypes.bool,
  fixedPosition: PropTypes.bool,
  options: containerOptions,
  data: PropTypes.arrayOf(ecgStripType),
  allData: PropTypes.arrayOf(ecgStripType),
  noScale: PropTypes.bool,
};

export const eventGroupType = PropTypes.shape({
  abnormalityType: PropTypes.oneOf(Object.values(abnormalityTypeEnum)),
  dateFrom: PropTypes.string,
  time: PropTypes.string,
  dateFromEvent: PropTypes.string,
  dateTo: PropTypes.string,
  dateToEvent: PropTypes.string,
  duration: PropTypes.number,
  ecg: PropTypes.arrayOf(ecgStripType),
  ecgIds: PropTypes.arrayOf(PropTypes.string),
  avgHeartRate: PropTypes.number,
  heartRateAverage: PropTypes.number,
  patientEventGroupUuids: PropTypes.arrayOf(PropTypes.string),
  id: PropTypes.string.isRequired,
  kitId: PropTypes.string,
  patientId: PropTypes.string,
  procedureId: PropTypes.string,
  recordedDate: PropTypes.string,
});

export const reportEventGroupType = PropTypes.shape({
  abnormalityType: PropTypes.oneOf(Object.values(abnormalityTypeEnum)),
  dateFrom: PropTypes.string,
  dateFromEvent: PropTypes.string,
  dateTo: PropTypes.string,
  dateToEvent: PropTypes.string,
  duration: PropTypes.number,
  durationEvent: PropTypes.number,
  ecg: PropTypes.arrayOf(ecgStripType),
  ecgIds: PropTypes.arrayOf(PropTypes.string),
  // generalHealthData: {}, todo add description of type
  avgHeartRate: PropTypes.number,
  heartRateAverage: PropTypes.number,
  heartRateMax: PropTypes.number,
  heartRateMin: PropTypes.number,
  id: PropTypes.string,
  kitId: PropTypes.string,
  oxygenSaturationAverage: PropTypes.number,
  patientEventGroupUuids: PropTypes.arrayOf(PropTypes.string),
  patientId: PropTypes.string,
  procedureId: PropTypes.string,
  respirationAverage: PropTypes.number,
  status: PropTypes.oneOf(ecgEventStatus.toValues),
  temperatureAverage: PropTypes.number,
});

export const eventType = PropTypes.shape({
  abnormalityType: PropTypes.oneOf(Object.values(abnormalityTypeEnum)),
  dateFrom: PropTypes.string,
  dateTo: PropTypes.string,
  duration: PropTypes.number,
  id: PropTypes.string.isRequired,
  kitId: PropTypes.string,
  patientEventGroupUuids: PropTypes.arrayOf(PropTypes.string),
  patientId: PropTypes.string,
  procedureId: PropTypes.string,
  recordedDate: PropTypes.string,
});

export const symptomType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  symptoms: PropTypes.arrayOf(PropTypes.oneOf(Object.values(symptomEnum))),
});

export const nonECGDataType = PropTypes.shape({
  time: PropTypes.string,
  value: PropTypes.shape({
    dateFrom: PropTypes.string,
    dateTo: PropTypes.string,
    duration: PropTypes.number,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
});

export const nonECGDataTypeWithoutValue = PropTypes.shape({
  dateFrom: PropTypes.string,
  dateTo: PropTypes.string,
  duration: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
});
