import React, { memo, useState, useMemo } from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Datepicker from '../../simpleInputs/Datepicker';
import TextInput from '../../simpleInputs/TextInput';
import RangeInput from '../../simpleInputs/RangeInput';
import FilterAutocomplete from './FilterAutocomplete';
import AutocompleteInput from '../../simpleInputs/AutocompleteInput';
import DateRangePicker from '../../simpleInputs/DateRangePicker';

const renderAutocompleteOption = (getOption) => (option) =>
  (
    <Typography
      title={option}
      style={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      {getOption ? getOption(option) : option}
    </Typography>
  );

const getInputComponent = ({
  date,
  text,
  range,
  select,
  multiple,
  multipleSelect,
  getOptionLabel,
}) => {
  if (multipleSelect) {
    return (props) => <TextInput {...props} select multiple />;
  }

  if (date && range) {
    return DateRangePicker;
  }

  if (date) {
    return Datepicker;
  }

  if (range) {
    return RangeInput;
  }

  if (select || text) {
    return TextInput;
  }

  if (multiple) {
    return (props) => (
      <FilterAutocomplete
        {...props}
        renderOption={renderAutocompleteOption(getOptionLabel)}
      />
    );
  }

  return (props) => (
    <AutocompleteInput
      {...props}
      renderOption={renderAutocompleteOption(getOptionLabel)}
    />
  );
};

const DynamicFilterField = ({
  classes,
  columnName,
  filteredData,
  onChangeField,
  fieldOptions,
  fetchDataForFilter,
}) => {
  const input = useMemo(
    () => fieldOptions[columnName],
    [fieldOptions, columnName]
  );

  const [currentValue, setValue] = useState(
    input.defaultValue || (input.multiple || input.multipleSelect ? [] : '')
  );
  const { range, ...inputProps } = input;

  const currentFilterOptions = useMemo(
    () => get(filteredData, input.name) || [],
    [filteredData, input]
  );

  const handleFieldChange = (event, value) => {
    onChangeField(input.searchKey || input.name, value);
    setValue(value);
  };

  const fetchData = (ev, value) => {
    fetchDataForFilter(value, { ...input, columnName });
  };

  const handleChangeFieldInput = debounce(fetchData, 300);

  const Component = useMemo(() => getInputComponent(input), [input]);

  const minWidth = input.date && input.range ? 240 : 130;

  return (
    <Grid
      xs
      item
      style={{ maxWidth: 300, minWidth }}
      className={classes.itemContainer}
    >
      <Component
        fullWidth
        size="small"
        value={currentValue}
        onChange={handleFieldChange}
        options={currentFilterOptions}
        onInputChange={handleChangeFieldInput}
        inputProps={{
          autoComplete: 'new-password',
          form: {
            autoComplete: 'off',
          },
        }}
        {...inputProps}
      />
    </Grid>
  );
};

DynamicFilterField.propTypes = {
  classes: PropTypes.shape(),
  columnName: PropTypes.string,
  form: PropTypes.objectOf(PropTypes.any),
  onChangeField: PropTypes.func.isRequired,
  control: PropTypes.objectOf(PropTypes.any),
  fetchDataForFilter: PropTypes.func.isRequired,
  fieldOptions: PropTypes.instanceOf(Object).isRequired,
  filteredData: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.bool,
      PropTypes.string,
      PropTypes.object,
    ])
  ),
};

const areEqual = (prev, next) => prev.filteredData === next.filteredData;

export default memo(DynamicFilterField, areEqual);
