import { requestSaga } from 'common/utils/generators';
import resources from 'common/constants/resources';
import dataProvider from 'store/dataProvider';

import { contactsActionTypes } from '../reducer';

function* getContactsList() {
  yield requestSaga({
    actionType: contactsActionTypes.getList,
    request: dataProvider.getList,
    resource: resources.contact.list,
  });
}

export default getContactsList;
