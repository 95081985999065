import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import makeStyles from '@material-ui/core/styles/makeStyles';

import useRouterInfo from 'common/hooks/useRouterInfo';
import AccordionMenu from 'common/components/AccordionMenu';
import UserCard from 'common/components/Widgets/UserCard';
import { commonRoles } from 'common/constants/enums';

import MainLogo from './MainLogo';
import adminMenuStructure, {
  physicianMenuStructure,
  contactMenuStructure,
  technicianMenu,
  ecgTechMenu,
} from './menuStructure';

const useStyles = makeStyles((theme) => ({
  homeRow: {
    color: theme.palette.text.colored,
    margin: theme.spacing(1, 0),
    paddingLeft: theme.spacing(1),
  },
  userCardContainer: {
    padding: theme.spacing(1, 0),
  },
  procedureInfoCard: {
    borderRadius: 0,
    padding: theme.spacing(1, 2),
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.grey.lighter,
  },
  calendarCard: {
    marginTop: theme.spacing(0.25),
    backgroundColor: theme.palette.background.secondaryLight,
    borderRadius: 0,
    minHeight: 170,
  },
  menuCard: {
    marginTop: theme.spacing(0.25),
    minHeight: ({ accordionMinHeightOffset }) =>
      `calc(100vh - ${accordionMinHeightOffset})`,
    height: '100%',
    borderRadius: 0,
  },
  homeIconWrapper: {
    paddingRight: theme.spacing(1),
  },
}));

const SideMenu = ({ role = null }) => {
  const routerInfo = useRouterInfo();
  const accordionMinHeightOffset = routerInfo?.showCalendar ? '420px' : '250px';
  const classes = useStyles({ accordionMinHeightOffset });

  const menuRoutes = useMemo(() => {
    if (role === commonRoles.physician) {
      return physicianMenuStructure;
    }

    if (role === commonRoles.technician) {
      return technicianMenu;
    }

    if (role === commonRoles.contact) {
      return contactMenuStructure;
    }

    if (role === commonRoles.ecgTech) {
      return ecgTechMenu;
    }

    return adminMenuStructure;
  }, [role]);

  const renderProcedureInfo = () => {
    if (routerInfo?.showProcedure) {
      const procedureId = routerInfo?.params?.id;
      return (
        <Card className={classes.procedureInfoCard}>
          <div className={classes.userCardContainer}>
            <UserCard procedureId={procedureId} />
          </div>
        </Card>
      );
    }
    return null;
  };

  return (
    <>
      <MainLogo />

      {renderProcedureInfo()}

      <Card className={classes.menuCard} data-testid="accordion">
        <AccordionMenu routes={menuRoutes} />
      </Card>
    </>
  );
};

SideMenu.propTypes = {
  role: PropTypes.oneOf(Object.values(commonRoles)),
};

export default SideMenu;
