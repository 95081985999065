import React, { memo, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import * as d3 from 'd3';

import {
  EventDot,
  CurveLine,
  MultilineGraphGrid,
  Container as D3Container,
} from 'common/components/D3Components';
import EventLine from 'common/components/D3Components/components/MultilineGraph/EventLine';
import SelectedRange from 'common/components/D3Components/components/MultilineGraph/SelectedRange';
import { getFirstEqualOrAfterTimeIndex } from 'common/utils/helpers/date';
import { getBeatIndex } from 'common/components/D3Components/utils';
import { setTenSecondsRange } from '../ducks';
import {
  graphConfig,
  expectedDuration,
  selectedRangeRadius,
} from '../constants';
import {
  getEcgDataByKey,
  getEcgEventLines,
  getEcgEventPoints,
  getTenSecondsRange,
  getPatientEventPoints,
} from '../ducks/selectors';

const timeUnit = 'seconds';
const { width, height, xRange, yRange, options } = graphConfig;

const EcgGraph = ({ dateKey = '', selectedTime }) => {
  const dispatch = useDispatch();
  const ecg = useSelector(getEcgDataByKey(dateKey));
  const eventLines = useSelector(getEcgEventLines(dateKey));
  const eventPoints = useSelector(getEcgEventPoints(dateKey));

  const [rangeStart, rangeEnd] = useMemo(
    () => d3.extent(ecg, (d) => d[options.xPropKey]),
    [ecg]
  );

  const patientEventPoints = useSelector(
    getPatientEventPoints([rangeStart, rangeEnd])
  );

  const currentTimeRange = useMemo(() => {
    const endOfRange = moment(dateKey).clone().add(expectedDuration, timeUnit);

    return [dateKey, endOfRange.toISOString()];
  }, [dateKey]);
  const xDomain = useMemo(
    () => d3.extent(ecg, (d) => new Date(d[options.xPropKey])),
    [ecg]
  );

  const yDomain = useMemo(
    () =>
      ecg?.length
        ? [
            d3.min(ecg, (d) => d[options.yPropKey]) - 125,
            d3.max(ecg, (d) => d[options.yPropKey]) + 125,
          ]
        : yRange,
    [ecg]
  );

  const selectedRange = useSelector(
    getTenSecondsRange(currentTimeRange, selectedTime)
  );

  const setSelectedRange = useCallback(
    (range, event = null) => {
      dispatch(setTenSecondsRange(range, event));
    },
    [dispatch]
  );

  const handleSelectRange = useCallback(
    (point, key, ev) => {
      const elPath = ev.path?.[0];
      const selectionId = elPath
        ? d3.select(elPath).attr('id')
        : ev?.srcElement?.id;
      const selectableEvents = [...eventPoints, ...patientEventPoints];
      const event = selectableEvents.find(({ id }) => id === selectionId);

      const index = getBeatIndex(ecg, point);

      if (index < 0) {
        return;
      }

      const { [options.xPropKey]: time } = ecg[index];
      const selectedDateTime = moment(time);
      const start = selectedDateTime
        .clone()
        .subtract(selectedRangeRadius, timeUnit);
      const end = selectedDateTime.clone().add(selectedRangeRadius, timeUnit);

      const range = {
        center: event?.dateFrom || time,
        startRange: start.toISOString(),
        endRange: end.toISOString(),
      };

      setSelectedRange(range, event);
    },
    [ecg, eventPoints, patientEventPoints, setSelectedRange]
  );

  const centerIndex = useMemo(
    () =>
      ecg.length
        ? getFirstEqualOrAfterTimeIndex(ecg, ecg[0][options.xPropKey])
        : 0,
    [ecg]
  );

  const focusOptions = useMemo(
    () => ({
      duration: 120,
      domain: { x: xDomain, y: yDomain },
      center: new Date(ecg[centerIndex][options.xPropKey]),
    }),
    [centerIndex, ecg, xDomain, yDomain]
  );

  if (!dateKey || !ecg || !ecg.length) {
    return null;
  }

  return (
    <D3Container
      width={width}
      scroll={false}
      height={height}
      options={options}
      focus={focusOptions}
      rectangleStrokeWidth={0}
      onClick={handleSelectRange}
    >
      <MultilineGraphGrid
        color="gray"
        strokeWidth={0.2}
        xDomain={xRange}
        yDomain={yRange}
      />

      {ecg.length && <CurveLine data={ecg} color="black" strokeWidth={0.5} />}

      {selectedRange && <SelectedRange selectedRange={selectedRange} />}
      {eventLines?.length &&
        ecg.length &&
        eventLines.map((eventLine) => (
          <EventLine
            lineWidth={2}
            allData={ecg}
            yPoint={yDomain[0]}
            key={eventLine.id}
            {...eventLine}
          />
        ))}

      {eventPoints?.length &&
        eventPoints.map((eventPoint) => (
          <EventDot
            tooltip
            clickable
            {...eventPoint}
            allData={ecg}
            yPosition={yDomain[1]}
            key={eventPoint.id}
          />
        ))}

      {patientEventPoints?.length &&
        patientEventPoints.map((eventPoint) => (
          <EventDot
            tooltip
            clickable
            {...eventPoint}
            allData={ecg}
            yPosition={yDomain[1]}
            key={eventPoint.id}
          />
        ))}
    </D3Container>
  );
};

const areEqual = (prev, next) =>
  prev.dateKey === next.dateKey && prev.selectedRange === next.selectedRange;

export default memo(EcgGraph, areEqual);
