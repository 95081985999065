import { requestSaga } from 'common/utils/generators';
import dataProvider from 'store/dataProvider';
import resources from 'common/constants/resources';
import { actionTypes } from '../reducer';

function* fetchUserSaga() {
  yield requestSaga({
    actionType: actionTypes.getOne,
    request: dataProvider.getOne,
    resource: resources.user.item,
  });
}

export default fetchUserSaga;
