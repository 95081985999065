import { requestSaga } from 'common/utils/generators';
import resources from 'common/constants/resources';
import dataProvider from 'store/dataProvider';
import { notesActionTypes } from '../reducer';

function* createNote() {
  yield requestSaga({
    actionType: notesActionTypes.create,
    request: dataProvider.createOne,
    resource: resources.specialNote.index,
  });
}

export default createNote;
