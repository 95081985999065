import { createSelector } from 'reselect';

const getAttachmentsState = (store) => store.attachments;

export const getLoading = createSelector(
  getAttachmentsState,
  (state) => state.loading
);

export const getTotal = createSelector(
  getAttachmentsState,
  (state) => state.total || 0
);

export const getAttachments = createSelector(
  getAttachmentsState,
  (state) => state.data
);

export const getUploads = createSelector(
  getAttachmentsState,
  (state) => state.uploads
);

export const getIsUploading = createSelector(
  getAttachmentsState,
  (state) => state.isUploading
);
