import moment from 'moment';
import groupBy from 'lodash/groupBy';

const startDay = moment('2022-01-01').toDate();

export const getCalendarParams = (selectedDays) => {
  const calendarStart = moment(startDay).startOf('day');
  const calendarEnd = moment().endOf('day');

  return {
    fromMonth: startDay,
    toMonth: new Date(),
    month: new Date(),
    disabledDays: (day) =>
      !moment(day.toISOString()).isBetween(
        calendarStart.toISOString(),
        calendarEnd.toISOString(),
        'day',
        '[]'
      ),

    calendarModifiers: {
      startDate: calendarStart.toDate(),
      endDate: calendarEnd.toDate(),

      today: new Date(),

      selected: (day) => {
        return moment(day).isBetween(
          selectedDays.from,
          selectedDays.to,
          'day',
          '[]'
        );
      },
    },

    modifiersStyles: {
      today: {
        fontWeight: 'bold',
      },
    },
  };
};

export const modifyUserActivityStatistic = (data) => {
  const modifiedActivity = data.map((activityRecord) => {
    if (activityRecord.entityType === 'EcgEvent') {
      return {
        ...activityRecord,
        entityType: activityRecord.abnormalityType,
      };
    }

    return activityRecord;
  });

  const grouped = groupBy(modifiedActivity, 'entityType');
  return Object.keys(grouped).reduce((acc, key) => {
    const entityActions = grouped[key];

    const groupedActions = entityActions.reduce(
      (a, d) => ({
        ...a,
        [d.action]: d.count,
      }),
      {}
    );

    return {
      ...acc,
      [key]: groupedActions,
    };
  }, {});
};
