import { LogLevel, HubConnectionBuilder } from '@microsoft/signalr';

import { WS_GENERAL } from 'config/api';

const validationString = String.fromCharCode(0x1e);

class SocketProvider {
  static options = {
    accessTokenFactory: () => localStorage.accessToken,
  };

  static parse(data) {
    return data
      .split(validationString)
      .slice(0, -1)
      .map((message) => JSON.parse(message));
  }

  isConnectionsExist = false;

  isInit = false;

  initPromise = null;

  connection = null;

  subscriptions = {};

  constructor() {
    if (SocketProvider.context) {
      // eslint-disable-next-line no-constructor-return
      return SocketProvider.context;
    }

    SocketProvider.context = this;

    this.connection = new HubConnectionBuilder()
      .withUrl(WS_GENERAL, SocketProvider.options)
      .configureLogging(LogLevel.Information)
      .withAutomaticReconnect()
      .build();

    const originalReceiver = this.connection.connection.onreceive;

    this.connection.connection.onreceive = (data) => {
      const messages = SocketProvider.parse(data);
      const isPing = messages[0].type === 6;

      originalReceiver(data);

      if (!messages[0].type || isPing) {
        return;
      }

      this.onMessage(messages);
    };

    // reConnect during the losing network connection
    // this.connection.connection.onclose = () => {
    //   console.log('Socket connection has been closed');
    //   console.log('Reconnecting');
    //
    //   this.reInitConnectionAsync()
    //     .then(() => {
    //       console.log('Reconnected');
    //       this.isConnectionsExist = true;
    //       this.updateSubscriptions();
    //     })
    //     .catch(() => {
    //       this.isConnectionsExist = false;
    //     });
    // };

    // eslint-disable-next-line no-constructor-return
    return this;
  }

  onMessage(messages = []) {
    Object.getOwnPropertySymbols(this.subscriptions).forEach((key) => {
      const subscription = this.subscriptions[key];

      if (subscription) {
        messages.forEach(subscription.handler);
      }
    });
  }

  updateSubscriptions() {
    Object.getOwnPropertySymbols(this.subscriptions).forEach((key) => {
      const sub = this.subscriptions[key];
      this.connection.send(sub.action, sub.payload);
    });
  }

  init() {
    this.isInit = true;

    if (this.isConnectionsExist) {
      return this.initPromise;
    }

    this.initPromise = this.initConnection().then(() => {
      this.isConnectionsExist = true;
    });

    return this.initPromise;
  }

  destroy() {
    this.connection.stop().then(() => {
      this.connection = null;
      this.isInit = false;
      this.isConnectionsExist = false;
      this.subscriptions = {};
    });
  }

  subscribe(params) {
    const { payload, action, key, onMessage } = params;
    if (onMessage) {
      this.subscriptions[key] = {
        action,
        payload,
        handler: onMessage,
      };
    }

    if (action && this.isConnectionsExist) {
      if (payload) {
        this.connection.send(action, payload);
      } else {
        this.connection.send(action);
      }
    }
  }

  unsubscribe(params) {
    const { payload, action, key, keepAlive } = params;
    delete this.subscriptions[key];

    if (action && this.isConnectionsExist && !keepAlive) {
      if (payload) {
        this.connection.send(action, payload);
      } else {
        this.connection.send(action);
      }
    }
  }

  initConnection() {
    return new Promise((resolve, reject) => {
      this.connection
        .start()
        .then(resolve)
        .catch((e) => {
          console.warn('Socket connection warning: ', e);
          return this.reInitConnectionAsync();
        })
        .then(resolve)
        .catch((e) => {
          console.error('Socket connection error: ', e);
          reject();
        });
    });
  }

  reInitConnectionAsync(delay = 3000) {
    return new Promise((resolve) => {
      setTimeout(() => {
        this.connection
          .start()
          .then(resolve)
          .catch(() => this.reInitConnectionAsync())
          .then((m) => {
            resolve(m);
          });
      }, delay);
    });
  }
}

export default SocketProvider;
