import dataProvider from 'store/dataProvider';
import resources from 'common/constants/resources';
import { requestSaga } from 'common/utils/generators';

export const createReEmailSaga = (actionType) =>
  function* reEmailReport() {
    yield requestSaga({
      actionType,
      request: dataProvider.createOne,
      resource: resources.report.sendReportEmail,
    });
  };

export const createReFaxSaga = (actionType) =>
  function* reFaxReport() {
    yield requestSaga({
      actionType,
      request: dataProvider.createOne,
      resource: resources.report.sendReportFax,
    });
  };

export const createReviewReportSaga = (actionType) =>
  function* reviewReport() {
    yield requestSaga({
      actionType,
      request: dataProvider.createOne,
      resource: resources.physician.reviewReport,
    });
  };

export const createReviewReportByFileIdSaga = (actionType) =>
  function* reviewReport() {
    yield requestSaga({
      actionType,
      request: dataProvider.createOne,
      resource: resources.physician.reviewReportByFileId,
    });
  };

export const createGetReportsListSaga = (actionType) =>
  function* fetchReportList() {
    yield requestSaga({
      actionType,
      request: dataProvider.get,
      resource: resources.report.list,
    });
  };
