import unionBy from 'lodash/unionBy';

export const onSuccessFetch = (payload, state) => {
  if (Array.isArray(payload.data)) {
    const { data, total } = payload;
    return {
      ...state,
      data,
      total,
    };
  }
  if (payload.data) {
    const { data } = payload;

    return {
      ...state,
      data: unionBy([data], state.data, 'id'),
    };
  }

  return { ...state };
};
