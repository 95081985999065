import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import moment from 'moment';

import { abnormalityTypeEnum } from 'common/constants/ecgEnums';
import Button from 'common/components/buttons/ModalActionButton';
import { openEventStripModal } from 'common/ducks/general/actions/modalActions';
import { getCenterBetweenDatesUTC } from 'common/utils/helpers/date';
import { setGraphSelection } from 'common/ducks/general/actions/monitoringActions';
import { getEventStripModal } from 'ui/layout/ModalHandler/ducks/selector';
import { getAllEvents, getLoading, getVisibleRange } from '../ducks/selectors';
import { findIndexPrevOrNextEvent } from '../utils';

const PrevNextEventsNavigation = ({
  center = '',
  isModal = false,
  resource = '',
  selectedEvent = null,
  selectedBeatRange = null,
  selectedPatientEvent = null,
  setSelectedBeatRange = () => null,
}) => {
  const dispatch = useDispatch();
  const loading = useSelector(getLoading(resource));
  const allEvents = useSelector(getAllEvents(resource));
  const visibleRange = useSelector(getVisibleRange(resource));
  const eventStripModalData = useSelector(getEventStripModal);

  const eventId = useMemo(
    () => selectedPatientEvent?.id || selectedEvent?.id,
    [selectedPatientEvent?.id, selectedEvent?.id]
  );

  const time = useMemo(
    () =>
      selectedBeatRange?.[0] ||
      getCenterBetweenDatesUTC(...(visibleRange || [])),
    [selectedBeatRange, visibleRange]
  );

  const { prevEvent, nextEvent } = useMemo(
    () => findIndexPrevOrNextEvent(time, eventId, allEvents || []),
    [allEvents, eventId, time]
  );

  const prevDisabled = useMemo(
    () => !prevEvent || loading,
    [loading, prevEvent]
  );
  const nextDisabled = useMemo(
    () => !nextEvent || loading,
    [loading, nextEvent]
  );

  const setNewSelectedEvent = useCallback(
    (e) => {
      const event = { ...e, uniqueKey: uuidv4() };

      if (event.abnormalityType === abnormalityTypeEnum.patient) {
        setSelectedBeatRange(null);
      }

      if (isModal && eventStripModalData?.payload) {
        const { payload } = eventStripModalData;
        // fixme changed date for updating component with same data
        const newCenter = moment(payload?.center)
          .clone()
          .add(1, 'day')
          .toISOString();

        dispatch(
          openEventStripModal({
            ...payload,
            center: newCenter,
            event,
          })
        );

        return;
      }

      dispatch(
        setGraphSelection(
          // fixme changed date for updating component with same data
          moment(center).clone().add(1, 'milliseconds').toISOString(),
          event
        )
      );
    },
    [setSelectedBeatRange, isModal, dispatch, center, eventStripModalData]
  );

  const handlePrev = useCallback(() => {
    if (!prevEvent) {
      return;
    }

    setNewSelectedEvent(prevEvent);
  }, [setNewSelectedEvent, prevEvent]);

  const handleNext = useCallback(() => {
    setNewSelectedEvent(nextEvent);
  }, [setNewSelectedEvent, nextEvent]);

  return (
    <>
      <Grid item>
        <Button disabled={prevDisabled} onClick={handlePrev}>
          Previous
        </Button>
      </Grid>

      <Grid item>
        <Button disabled={nextDisabled} onClick={handleNext}>
          Next
        </Button>
      </Grid>
    </>
  );
};

PrevNextEventsNavigation.propTypes = {
  center: PropTypes.string,
  isModal: PropTypes.bool,
  resource: PropTypes.string,
  selectedEvent: PropTypes.shape(),
  setSelectedBeatRange: PropTypes.func,
  selectedPatientEvent: PropTypes.shape(),
  selectedBeatRange: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)])
  ),
};

export default PrevNextEventsNavigation;
