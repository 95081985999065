import * as d3 from 'd3';
import lodashRange from 'lodash/range';
import moment from 'moment';

const generateYaxisTicks = ([min, max], step = 10) =>
  lodashRange(min, max, step);

const formatXTick = (tickFormat = '%I:%M %p') => d3.timeFormat(tickFormat);

export const xAxisGen = (height, margin, ticks, tickFormat) => (g, x) => {
  const transformX = `translate(0, ${height - margin.bottom})`;
  const tSize = -height + margin.bottom + margin.top;
  return g
    .attr('transform', transformX)
    .call(
      d3
        .axisBottom(x)
        .tickFormat(formatXTick(tickFormat))
        .tickSize(tSize)
        .ticks(ticks)
    );
};

export const yAxisGen =
  (width, margin, yDomain, ticksFormatter = (v) => v) =>
  (g, y) => {
    const transformY = `translate(${margin.left}, 0)`;
    const tickValues = generateYaxisTicks(yDomain);

    return g
      .attr('transform', transformY)
      .call(
        d3
          .axisLeft(y)
          .tickFormat(ticksFormatter)
          .tickValues(tickValues)
          .tickSize(-width)
      );
  };

export const lineGenerator =
  (keys, y, visibleRange = [], minutesThreshold = 5) =>
  (data, x) =>
    d3
      .line()
      .curve(d3.curveNatural)
      .x((d) => x(new Date(d?.[keys.x])))
      .y((d) => y(d?.[keys.y]))
      .defined((d) => {
        const xVal = d?.[keys.x];
        const yVal = d?.[keys.y];

        const [start, end] = visibleRange || [];

        const from = moment(start).subtract(minutesThreshold, 'minutes');
        const to = moment(end).add(minutesThreshold, 'minutes');

        return (
          typeof yVal === 'number' && moment(xVal).isBetween(from, to, '[]')
        );
      })(data);

export const renderDashedYAxis = (dashedLines) => (g) =>
  g
    .selectAll('.tick:not(:first-of-type) line')
    .filter((value) => dashedLines.includes(value))
    .attr('stroke-width', 1.5)
    .attr('stroke-dasharray', '2,2');

export const renderEventYAxis = (segmentLines, segmentsHeight) => (g) =>
  g
    .selectAll('.tick:not(:first-of-type) line')
    .filter((value) => segmentLines.includes(value.toString()))
    .attr('stroke-width', segmentsHeight)
    .attr('stroke', '#EFEFEF');

export const hideLines =
  (hiddenLines = []) =>
  (g) =>
    g
      .selectAll('.tick:not(:first-of-type) line')
      .filter((value) => hiddenLines.includes(value))
      .attr('stroke-opacity', 0);

export const setTextFontSize =
  (size = 12) =>
  (g) =>
    g.selectAll('text').attr('font-size', size);
