import unionBy from 'lodash/unionBy';

import { requestReducer } from 'common/utils/generators';
import { createRequestActionTypes } from 'common/utils/actionTypesHelper';

export const actionTypes = createRequestActionTypes(
  ['getList', 'setDisabledState'],
  'proceduresListWithEvents'
);

export const initialState = {
  data: [],
  total: 0,
  loading: false,
  error: null,
};

const proceduresListWithEvents = requestReducer(
  [actionTypes.getList],
  initialState,
  (payload, state) => {
    const listObject = (payload.data ? [payload.data] : [])
      .filter(Boolean)
      .flat();

    return {
      ...state,
      total: payload.total,
      data: Array.isArray(payload.data)
        ? payload.data
        : unionBy(listObject, state.data, 'id'),
    };
  }
);

export const getCommonProcedures = (payload) => ({
  type: actionTypes.getList.requested,
  payload,
});

export const setDisabledState = (query, body) => ({
  type: actionTypes.setDisabledState.requested,
  payload: { query, body: String(body), options: { keepalive: true } },
});

export default proceduresListWithEvents;
