import moment from 'moment';
import {
  abnormalityTypeEnum,
  commonTriageAbnormalities,
  escalatedTriageAbnormalities,
} from 'common/constants/ecgEnums';
import resources from 'common/constants/resources';
import { procedureStatusTypes } from 'common/constants/enums';
import createEnum from 'common/utils/actionTypesHelper/createEnum';

const itemsWithCounter = createEnum({
  escalated: null,
  common: null,
  locked: null,

  firstDay: null,
  daily: null,
  finalDay: null,

  unknown: null,
  lateActivity: null,
  intermittent: null,

  singleEscalated: null,
  singleCommon: null,
});

const escalatedAbnormalities = escalatedTriageAbnormalities.filter(
  (type) => type !== abnormalityTypeEnum.patient
);

const localDate = moment().startOf('day').toISOString();

const counterParams = {
  [itemsWithCounter.escalated]: [
    {
      identifier: `${itemsWithCounter.escalated}/procedures`,
      resource: resources.counter.procedures,
      filter: {
        hasKit: true,
        reviewed: false,
        withNewPatientEvents: true,
        statuses: [procedureStatusTypes.monitoring],
        withNewAbnormalities: escalatedAbnormalities,
      },
    },
    {
      identifier: `${itemsWithCounter.escalated}/events`,
      resource: resources.counter.events,
      filter: {
        hasKit: true,
        reviewed: false,
        withNewPatientEvents: true,
        statuses: [procedureStatusTypes.monitoring],
        withNewAbnormalities: escalatedAbnormalities,
      },
    },
  ],
  [itemsWithCounter.common]: [
    {
      identifier: `${itemsWithCounter.common}/procedures`,
      resource: resources.counter.procedures,
      filter: {
        hasKit: true,
        reviewed: false,
        minEventsCount: 200,
        statuses: [procedureStatusTypes.monitoring],
        withNewAbnormalities: commonTriageAbnormalities,
      },
    },
    {
      identifier: `${itemsWithCounter.common}/events`,
      resource: resources.counter.events,
      filter: {
        hasKit: true,
        reviewed: false,
        minEventsCount: 200,
        statuses: [procedureStatusTypes.monitoring],
        withNewAbnormalities: commonTriageAbnormalities,
      },
    },
  ],
  [itemsWithCounter.locked]: [
    {
      identifier: itemsWithCounter.locked,
      resource: resources.counter.procedures,
      filter: {
        locked: true,
        hasKit: true,
        statuses: [procedureStatusTypes.monitoring],
      },
    },
  ],

  [itemsWithCounter.firstDay]: [
    {
      identifier: itemsWithCounter.firstDay,
      resource: resources.counter.procedures,
      filter: {
        localDate,
        hasFirstDayReports: false,
        statuses: [procedureStatusTypes.monitoring],
      },
    },
  ],
  [itemsWithCounter.daily]: [
    {
      identifier: itemsWithCounter.daily,
      resource: resources.counter.procedures,
      filter: {
        localDate,
        hasDailyReports: false,
        statuses: [procedureStatusTypes.monitoring],
      },
    },
  ],
  [itemsWithCounter.finalDay]: [
    {
      identifier: itemsWithCounter.finalDay,
      resource: resources.counter.procedures,
      filter: {
        localDate,
        hasLastDayReports: false,
        statuses: [procedureStatusTypes.monitoring],
      },
    },
  ],

  [itemsWithCounter.unknown]: [
    {
      identifier: itemsWithCounter.unknown,
      resource: resources.counter.procedures,
      filter: {
        hasPatient: false,
        statuses: [procedureStatusTypes.monitoring],
      },
    },
  ],
  [itemsWithCounter.lateActivity]: [
    {
      identifier: itemsWithCounter.lateActivity,
      resource: resources.counter.procedures,
      filter: {
        hasLowBpm: null,
        hasLateActivityEvents: true,
        statuses: [procedureStatusTypes.monitoring],
      },
    },
    {
      identifier: itemsWithCounter.intermittent,
      resource: resources.counter.procedures,
      filter: {
        hasLowBpm: true,
        hasLateActivityEvents: null,
        statuses: [procedureStatusTypes.monitoring],
      },
    },
  ],

  [itemsWithCounter.singleEscalated]: [
    {
      identifier: itemsWithCounter.singleEscalated,
      resource: resources.counter.events,
      singleProcedure: true,
      filter: {
        reviewed: false,
        withNewPatientEvents: true,
        statuses: [procedureStatusTypes.monitoring],
        withNewAbnormalities: escalatedAbnormalities,
      },
    },
  ],
  [itemsWithCounter.singleCommon]: [
    {
      identifier: itemsWithCounter.singleCommon,
      singleProcedure: true,
      resource: resources.counter.events,
      filter: {
        reviewed: false,
        statuses: [procedureStatusTypes.monitoring],
        withNewAbnormalities: commonTriageAbnormalities,
      },
    },
  ],
};

export default counterParams;
