import React, { useCallback, useEffect, useMemo, useState } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import clsx from 'classnames';

import {
  templateTypes,
  abnormalityTypeEnum,
  abnormalityAccordingTemplates,
} from 'common/constants/ecgEnums';
import { usePrevious, useSelect } from 'common/hooks';
import { defaultSortByEventGroup } from 'common/constants/enums';
import LocalPaginatedGrid from './LocalPaginatedGrid';
import useSocketUpdates from './hooks/useSocketUpdates';
import GridLayout from './components/GridLayout';
import GridItem from './components/GridItem';
import * as actions from './ducks/reducer';
import Header from './components/Header';
import { getEventId } from './utils';
import useStyles from './styles';
import {
  getTotal,
  getEvents,
  getLoading,
  getEventEcgById,
  getChildrenEvents,
} from './ducks/selectors';

const defaultItemSize = {
  width: 165,
  height: 66,
};

const ServerPaginatedGrid = ({
  modal,
  onClose,
  sideBar,
  perPage,
  itemProps,
  graphProps,
  procedureId,
  socketParams,
  requestFilter,
  contentStyles,
  columnsAmount,
  itemComponent,
  eventGroupType,
  isGlobalTriage,
  disableSelection,
  containerStyles,
  abnormalityType: abType,
}) => {
  const [selectedParent, setSelectedParent] = useState(null);

  const styles = useStyles();
  const dispatch = useDispatch();
  const total = useSelector(getTotal);
  const loading = useSelector(getLoading);
  const selectionState = useSelect('id', 'similar');
  const [page, setPage] = useState(1);
  const [abnormalityType, setAbnormalityType] = useState(abType);
  const prevEventGroupType = usePrevious(abnormalityType);
  const [sortType, setSortType] = useState(null);
  const childrenEvents = useSelector(getChildrenEvents(selectedParent));

  const isTemplate = useMemo(
    () => templateTypes.includes(abnormalityType),
    [abnormalityType]
  );

  const sortingOptions = useMemo(() => {
    if (isTemplate || abnormalityType === abnormalityTypeEnum.patient) {
      return null;
    }

    return sortType || defaultSortByEventGroup[abnormalityType];
  }, [abnormalityType, isTemplate, sortType]);

  const events = useSelector(getEvents(isTemplate));

  const handleGoBack = () => setSelectedParent(null);

  const goBack = selectedParent ? handleGoBack : null;

  const totalPages = useMemo(
    () => Math.ceil(total / perPage),
    [perPage, total]
  );

  const { onSelect, onDeselect, isSelected, deselectAll } = selectionState;

  const eventIds = useMemo(() => events.map(getEventId), [events]);

  const disabledSelect = useMemo(
    () => abnormalityType === abnormalityTypeEnum.patient || !eventIds.length,
    [abnormalityType, eventIds.length]
  );

  const availableForSelectAll = useMemo(() => {
    if (abnormalityType === abnormalityTypeEnum.patient) {
      return [];
    }

    return events.filter(
      (e) => e.abnormalityType !== abnormalityTypeEnum.patient
    );
  }, [abnormalityType, events]);

  const availableForSelectIds = useMemo(
    () => availableForSelectAll.map(getEventId),
    [availableForSelectAll]
  );

  const isAllSelected = useMemo(
    () => isSelected(availableForSelectIds),
    [availableForSelectIds, isSelected]
  );

  const handleSelectAll = useCallback(() => {
    if (isAllSelected) {
      deselectAll();
    } else {
      onSelect(availableForSelectAll);
    }
  }, [isAllSelected, deselectAll, onSelect, availableForSelectAll]);

  const getCurrentEvents = useCallback(() => {
    if (!requestFilter || !abnormalityType || !page) {
      return;
    }

    dispatch(
      actions.getEvents({
        pagination: { page, perPage },
        filter: {
          ...requestFilter,
          sortingOptions,
          abnormalityTypes: [abnormalityType].flat(),
        },
      })
    );
  }, [page, perPage, sortingOptions, dispatch, requestFilter, abnormalityType]);

  const getEventsTotal = useCallback(() => {
    dispatch(
      actions.getTotal({
        filter: {
          ...requestFilter,
          abnormalityTypes: [abnormalityType].flat(),
        },
      })
    );
  }, [abnormalityType, dispatch, requestFilter]);

  const paramsForSocket = useMemo(
    () => ({
      onDeselect,
      abnormalityType,
      status: requestFilter.status,
      getTotal: getEventsTotal,
      ...socketParams,
    }),
    [
      onDeselect,
      socketParams,
      getEventsTotal,
      abnormalityType,
      requestFilter.status,
    ]
  );

  const propsForItem = useMemo(
    () => ({
      fetchEventEcg: actions.getEventEcg,
      getEventEcg: getEventEcgById,
      ...defaultItemSize,
      ...itemProps,
      socketParams: paramsForSocket,
      setTemplate: setSelectedParent,
    }),
    [itemProps, paramsForSocket]
  );

  useSocketUpdates(procedureId, paramsForSocket);

  useEffect(() => {
    if (!events?.length && totalPages > 1) {
      setPage((currentPage) => {
        if (totalPages < currentPage) {
          return totalPages;
        }

        return currentPage;
      });

      getCurrentEvents();
    }
  }, [events?.length, totalPages, getCurrentEvents]);

  useEffect(() => {
    if (prevEventGroupType !== abnormalityType) {
      onDeselect();
    }
  }, [onDeselect, prevEventGroupType, abnormalityType]);

  useEffect(() => {
    if (abType) {
      setAbnormalityType(abType);
    }
  }, [abType]);

  useEffect(() => {
    getCurrentEvents();
  }, [getCurrentEvents]);

  useEffect(() => {
    if ((!events?.length || !childrenEvents?.length) && goBack) {
      goBack();
    }
  }, [events, childrenEvents, goBack]);

  useEffect(() => () => dispatch(actions.clearState()), [dispatch]);

  if (childrenEvents) {
    const aType = abnormalityAccordingTemplates[abnormalityType];

    return (
      <LocalPaginatedGrid
        isTemplate
        modal={modal}
        items={childrenEvents}
        goBack={goBack}
        perPage={perPage}
        onClose={onClose}
        loading={loading}
        itemProps={propsForItem}
        graphProps={graphProps}
        procedureId={procedureId}
        columnsAmount={columnsAmount}
        itemComponent={itemComponent}
        eventGroupType={eventGroupType}
        abnormalityType={aType}
        containerStyles={containerStyles}
        disableSelection={disableSelection}
        handleChangeGroupType={setAbnormalityType}
      />
    );
  }

  return (
    <Grid
      container
      wrap="nowrap"
      direction="column"
      className={styles.itemsGridContainer}
    >
      {loading && (
        <div
          className={clsx(styles.loaderWrapper, {
            [styles.modalLoaderWrapper]: modal,
          })}
        >
          <LinearProgress />
        </div>
      )}

      <Header
        page={page}
        sort={sortingOptions}
        total={total}
        modal={modal}
        events={events}
        goBack={goBack}
        perPage={perPage}
        setSort={setSortType}
        onClose={onClose}
        setPage={setPage}
        deselectAll={deselectAll}
        selectAll={handleSelectAll}
        isAllSelected={isAllSelected}
        eventGroupType={eventGroupType}
        disabledSelect={disabledSelect}
        abnormalityType={abnormalityType}
        selectedEvents={selectionState.total}
        handleChangeGroupType={setAbnormalityType}
      />

      <GridLayout
        total={total}
        modal={modal}
        events={events}
        loading={loading}
        sideBar={sideBar}
        onSelect={onSelect}
        isSelected={isSelected}
        onDeselect={onDeselect}
        graphProps={graphProps}
        itemProps={propsForItem}
        itemComponent={itemComponent}
        contentStyles={contentStyles}
        columnsAmount={columnsAmount}
        isGlobalTriage={isGlobalTriage}
        disabledSelect={disableSelection || disabledSelect}
        containerStyles={containerStyles}
      />
    </Grid>
  );
};

ServerPaginatedGrid.defaultProps = {
  modal: false,
  perPage: 49,
  itemProps: {},
  graphProps: {},
  socketParams: {},
  procedureId: null,
  requestFilter: null,
  contentStyles: {},
  isGlobalTriage: false,
  disableSelection: false,
  itemComponent: GridItem,
};

ServerPaginatedGrid.propTypes = {
  modal: PropTypes.bool,
  onClose: PropTypes.func,
  perPage: PropTypes.number,
  itemProps: PropTypes.shape(),
  graphProps: PropTypes.shape(),
  procedureId: PropTypes.string,
  socketParams: PropTypes.shape({}),
  requestFilter: PropTypes.shape(),
  columnsAmount: PropTypes.number,
  contentStyles: PropTypes.shape(),
  eventGroupType: PropTypes.string,
  isGlobalTriage: PropTypes.bool,
  containerStyles: PropTypes.shape(),
  abnormalityType: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  sideBar: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.object,
    PropTypes.element,
  ]),
  disableSelection: PropTypes.bool,
  itemComponent: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
    PropTypes.func,
    PropTypes.object,
  ]),
};

export default ServerPaginatedGrid;
