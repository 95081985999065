import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Clear from '@material-ui/icons/Clear';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';

import { useRedirect } from 'common/hooks';
import DefaultButton from 'common/components/buttons/DefaultButton';
import { getToolbarHeight } from '../utils';

const useStyles = makeStyles((theme) => ({
  buttons: {
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(1),
    },
  },
}));

const renderButtonText = (text, color = 'primary') => (
  <Typography variant="h5" color={color}>
    {text}
  </Typography>
);

const Toolbar = ({
  tableActions = [],
  bulkActions = [],
  height = 0,
  table = {},
  title = null,
  selectable = true,
}) => {
  const styles = useStyles();
  const redirect = useRedirect();
  const toolbarHeight = useMemo(
    () => getToolbarHeight({ height }, selectable),
    [height, selectable]
  );

  const selected = useMemo(
    () => Object.keys(table.state?.selectedRowIds || {}).length,
    [table.state]
  );
  const bgColor = useMemo(
    () => (selected ? 'mainLight' : 'primary'),
    [selected]
  );

  const tableState = useMemo(() => {
    if (!table.state) {
      return null;
    }

    const { selectedRowIds } = table.state;
    const selectedIds = Object.keys(selectedRowIds || {});
    const selectedRows = selectedIds.map((id) => table.rowsById[id]?.original);

    return {
      ...table.state,
      selection: selectedRows,
    };
  }, [table.rowsById, table.state]);

  const selectedItemsLabel = useMemo(
    () => (selected > 1 ? 'items' : 'item'),
    [selected]
  );

  const renderToolbarButton = ({
    to,
    text,
    color,
    icon: Icon,
    onClick,
    disabled,
    routeState = {},
    ...restButtonProps
  }) => {
    const tableProps = {
      ...table,
      state: tableState,
    };

    const handleClick = () => {
      if (disabled) {
        return null;
      }

      return to ? redirect(to, routeState) : onClick(tableProps);
    };

    return (
      <Grid item key={text}>
        <DefaultButton
          disabled={disabled}
          onClick={handleClick}
          startIcon={Icon && <Icon color={color} />}
          text={renderButtonText(text, disabled ? 'inherit' : color)}
          {...restButtonProps}
        />
      </Grid>
    );
  };

  const deselectAllRows = () => table?.toggleAllPageRowsSelected(false);

  return (
    <Grid
      container
      alignItems="center"
      component={Box}
      px={1}
      height={toolbarHeight}
      bgcolor={`background.${bgColor}`}
    >
      {title && (
        <Typography
          variant="h4"
          color="primary"
          style={{ position: 'absolute', top: 10 }}
        >
          {title}
        </Typography>
      )}

      {selected ? (
        <Grid item container justifyContent="space-between">
          <Grid
            item
            container
            xs={6}
            component={Box}
            color="primary.light"
            alignItems="center"
            justifyContent="flex-start"
            direction="row"
          >
            <Grid item>
              <Button
                onClick={deselectAllRows}
                style={{ width: 35, minWidth: 30 }}
                variant="text"
                color="primary"
              >
                <Clear />
              </Button>
            </Grid>

            <Grid item>
              <Typography variant="h5">
                {selected} {selectedItemsLabel} selected
              </Typography>
            </Grid>
          </Grid>

          <Grid
            item
            container
            xs={6}
            wrap="nowrap"
            direction="row"
            className={styles.buttons}
            alignItems="center"
            justifyContent="flex-end"
          >
            {bulkActions.map(renderToolbarButton)}
          </Grid>
        </Grid>
      ) : (
        <Grid
          className={styles.buttons}
          item
          container
          justifyContent="flex-end"
        >
          {tableActions.map(renderToolbarButton)}
        </Grid>
      )}
    </Grid>
  );
};

Toolbar.propTypes = {
  selectable: PropTypes.bool,
  table: PropTypes.objectOf(PropTypes.any),
  tableActions: PropTypes.arrayOf(PropTypes.object),
  bulkActions: PropTypes.arrayOf(PropTypes.object),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
};

export default Toolbar;
