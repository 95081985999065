import { requestSaga } from 'common/utils/generators';
import resources from 'common/constants/resources';
import dataProvider from 'store/dataProvider';
import { actionTypes } from '../index';

export function* getProcedureECGPeriod() {
  yield requestSaga({
    actionType: actionTypes.getEcgPeriod,
    request: dataProvider.getList,
    resource: resources.ecgData.byPeriod,
  });
}

export function* getProcedureHeartRatePeriod() {
  yield requestSaga({
    actionType: actionTypes.getHeartRatePeriod,
    request: dataProvider.getList,
    resource: resources.healthData.stripBeats,
  });
}

export function* getEcgEventsPeriod() {
  yield requestSaga({
    actionType: actionTypes.getEcgEventsPeriod,
    request: dataProvider.getList,
    resource: resources.eventGroup.byPeriod,
  });
}
export function* getPatientEventsPeriod() {
  yield requestSaga({
    actionType: actionTypes.getPatientEvents,
    request: dataProvider.getList,
    resource: resources.healthData.patientEvents,
  });
}

export function* getHeartRateSummary() {
  yield requestSaga({
    actionType: actionTypes.getHeartRateSummary,
    request: dataProvider.getList,
    resource: resources.healthData.heartRateStripSummary,
  });
}

export function* getNonEcgEvents() {
  yield requestSaga({
    actionType: actionTypes.getNonEcgEvents,
    request: dataProvider.getList,
    resource: resources.sensorData.index,
  });
}
