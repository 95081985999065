import routes from 'common/constants/path';

import { getListRoutes } from './utils';

export const physicianPaths = [
  routes.private.index,
  routes.private.userProfile,
  ...getListRoutes(routes.physician),
];
export const contactPaths = [
  routes.private.index,
  routes.private.userProfile,
  ...getListRoutes(routes.contact),
];
export const privatePaths = getListRoutes(routes.private);

export const tenantProtectedRoutesEnum = {
  [routes.private.kits.create]: {
    protected: routes.private.kits.create,
    redirect: routes.private.kits.list,
  },
  [routes.private.phones.create]: {
    protected: routes.private.phones.create,
    redirect: routes.private.phones.list,
  },
  [routes.private.patches.create]: {
    protected: routes.private.patches.create,
    redirect: routes.private.patches.list,
  },
  [routes.private.simCards.create]: {
    protected: routes.private.simCards.create,
    redirect: routes.private.simCards.list,
  },
  [routes.private.patients.create]: {
    protected: routes.private.patients.create,
    redirect: routes.private.patients.list,
  },
  [routes.private.procedures.create]: {
    protected: routes.private.procedures.create,
    redirect: routes.private.procedures.list,
  },
  [routes.private.procedures.monitoring.index]: {
    protected: routes.private.procedures.monitoring.index,
    redirect: routes.private.procedures.list,
  },
  [routes.private.physicians.create]: {
    protected: routes.private.physicians.create,
    redirect: routes.private.physicians.list,
  },
};

export const tenantProtectedRotes = Object.keys(tenantProtectedRoutesEnum).map(
  (key) => tenantProtectedRoutesEnum[key].protected
);
