import { requestSaga } from 'common/utils/generators';
import resources from 'common/constants/resources';
import dataProvider from 'store/dataProvider';
import { procedureActionTypes } from '../reducer';

function* getProcedureList() {
  yield requestSaga({
    actionType: procedureActionTypes.getList,
    request: dataProvider.getList,
    resource: resources.procedure.list,
  });
}

export default getProcedureList;
