import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import Table from 'common/components/Table';
import columns from '../constants/columns';
import {
  isLoading,
  totalContacts,
  getContactsForTable,
} from '../ducks/selectors';

const rowsPerPageOptions = [5, 10, 15];

const ContactsTable = ({
  bulkButtons = [],
  formValues = {},
  tableActions = null,
  selectable,
  ...props
}) => {
  const data = useSelector(getContactsForTable);
  const total = useSelector(totalContacts);
  const loading = useSelector(isLoading);

  const bulkActionButtons = useMemo(() => {
    const { statuses } = formValues || {};

    return bulkButtons[statuses];
  }, [formValues, bulkButtons]);

  return (
    <Table
      {...props}
      selectable={selectable}
      data={data}
      total={total}
      loading={loading}
      columns={columns}
      rowsPerPageOptions={rowsPerPageOptions}
      toolbarProps={{
        tableActions,
        bulkActions: bulkActionButtons,
      }}
    />
  );
};

ContactsTable.propTypes = {
  onRowClick: PropTypes.func,
  formValues: PropTypes.shape(),
  selectable: PropTypes.bool,
  bulkButtons: PropTypes.oneOfType([PropTypes.array, PropTypes.shape()]),
  updateParams: PropTypes.func,
  tableActions: PropTypes.arrayOf(PropTypes.shape()),
};

export default ContactsTable;
