import { requestSaga } from 'common/utils/generators';
import resources from 'common/constants/resources';
import dataProvider from 'store/dataProvider';
import { actionTypes } from './reducer';

function* getProceduresWithCommonEvents() {
  yield requestSaga({
    actionType: actionTypes.getList,
    request: dataProvider.getList,
    resource: resources.procedure.withEventCounts,
  });
}

function* updateProcedureDisabledState() {
  yield requestSaga({
    actionType: actionTypes.setDisabledState,
    request: dataProvider.updateMany,
    resource: resources.procedure.disabledState,
  });
}

export default [getProceduresWithCommonEvents, updateProcedureDisabledState];
