import Delete from '@material-ui/icons/Delete';

export const getExtension = (fileName) => {
  const [ext] = fileName.split('.').reverse();

  return ext;
};

export const deleteButton = {
  text: 'DELETE',
  icon: Delete,
  color: 'error',
  variant: 'text',
};

export const createBulkButtons = (onDelete) => {
  deleteButton.onClick = onDelete;

  return [deleteButton];
};
