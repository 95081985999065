import makeStyles from '@material-ui/styles/makeStyles';
import { alpha } from '@material-ui/core/styles/colorManipulator';

const useStyles = makeStyles((theme) => ({
  input: {
    minWidth: 150,
    color: theme.palette.common.white,
    backgroundColor: alpha(theme.palette.common.white, 0.2),
  },
  inputWrapper: {
    '& .MuiInputBase-input.MuiInput-input.MuiAutocomplete-input': {
      paddingLeft: theme.spacing(0.75),
    },
    '& .MuiAutocomplete-endAdornment svg': {
      display: ({ disabled }) => (disabled ? 'none' : 'inherit'),
      color: theme.palette.common.white,
      backgroundColor: alpha(theme.palette.common.white, 0.2),
    },
    '& .MuiInput-underline:hover:before': {
      border: 'none',
    },
    '& .MuiInput-underline:before': {
      border: 'none',
    },
    '& .MuiInput-underline:after': {
      border: 'none',
    },
  },
}));

export default useStyles;
