import { requestSaga } from 'common/utils/generators';
import resources from 'common/constants/resources';
import dataProvider from 'store/dataProvider';
import { contactsActionTypes } from '../reducer';

function* createContactAccount() {
  yield requestSaga({
    actionType: contactsActionTypes.createContactAccount,
    request: dataProvider.createOne,
    resource: resources.profile.index,
  });
}

export default createContactAccount;
