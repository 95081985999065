import { createSelector } from 'reselect';

const getUndoState = (store) => store.undo;

export const getPatientEvents = createSelector(
  getUndoState,
  (state) => state.patientEvents
);

export const getEcgEvents = createSelector(
  getUndoState,
  (state) => state.ecgEvents
);

export const getEcgEventsWithoutStatus = createSelector(
  getUndoState,
  (state) => state.ecgEventWithoutStatus
);

export const getInvertEcgQueries = createSelector(
  getUndoState,
  (state) => state.invertEcgQueries
);
