import { useCallback, useEffect, useMemo, useState } from 'react';

import dataProvider from 'store/dataProvider';
import useGetParams from 'common/hooks/useGetParams';
import useAuthProvider from 'store/authProvider/useAuthProvider';

const isDevEnv = process.env.REACT_APP_DEV;
// two mins for prod - temporarily
const getCountsInterval = isDevEnv ? 3600000 : 2 * 60000;

const requestedSignals = new Map();

const setRequestSignal = (request, signal) =>
  requestedSignals.set(request, signal);

const getRequestSignal = (request) => requestedSignals.get(request);

const removeRequestSignal = (request) => requestedSignals.delete(request);

let mounted = true;

const getIdentifier = (...args) => args.filter(Boolean).join('_');

const useGetEntitiesCount = (
  countEntitiesParams,
  shouldAbortPrevRequest = true
) => {
  const { id: procedureId } = useGetParams();
  const authProvider = useAuthProvider();
  const isTenantAuthorized = useMemo(
    () => authProvider.isTenantAuthorized,
    [authProvider.isTenantAuthorized]
  );
  const [allCounts, setCounts] = useState({});

  const getCount = useCallback(
    async ({ identifier, resource, filter = {}, singleProcedure = false }) => {
      const requestedSignal = getRequestSignal(identifier);

      if (requestedSignal && shouldAbortPrevRequest) {
        requestedSignal.cancel();
      }

      const controller = new AbortController();
      setRequestSignal(identifier, { cancel: () => controller.abort() });

      try {
        const countFilter = filter;

        if (singleProcedure && procedureId) {
          countFilter.allowedIds = [procedureId];
        }

        const { data } =
          (await dataProvider.get(resource, {
            filter: countFilter,
            signal: controller.signal,
          })) || {};

        return { [getIdentifier(resource, identifier)]: data };
      } catch (e) {
        removeRequestSignal(identifier);
        return e;
      }
    },
    [shouldAbortPrevRequest, procedureId]
  );

  const getAllCount = useCallback(
    async (isMounted = mounted) => {
      if (!countEntitiesParams || !isTenantAuthorized) {
        return;
      }

      const fetchCounts = countEntitiesParams.map(getCount);

      const result = await Promise.all(fetchCounts);
      const counters = (result || []).reduce(
        (acc, val) => ({ ...acc, ...val }),
        {}
      );

      if (isMounted) {
        setCounts(counters);
      }
    },
    [countEntitiesParams, isTenantAuthorized, getCount]
  );

  const listenerForCounts = useCallback(() => {
    mounted = true;
    const interval = setInterval(() => getAllCount(mounted), getCountsInterval);

    return () => {
      mounted = false;
      clearInterval(interval);
    };
  }, [getAllCount]);

  useEffect(() => getAllCount(true), [getAllCount]);
  useEffect(() => listenerForCounts(), [listenerForCounts]);

  return useMemo(
    () => ({
      counts: allCounts,
      countSting:
        countEntitiesParams && Object.keys(allCounts).length
          ? countEntitiesParams
              .map(
                ({ resource, identifier }) =>
                  allCounts[getIdentifier(resource, identifier)]
              )
              .join('/')
          : '',
    }),
    [allCounts, countEntitiesParams]
  );
};

export default useGetEntitiesCount;
