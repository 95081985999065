import React, {
  useLayoutEffect,
  useCallback,
  useState,
  useMemo,
  useRef,
} from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import * as d3 from 'd3';

import {
  CurveLine,
  PauseLabel,
  Grid as D3Grid,
  Container as D3Container,
} from 'common/components/D3Components';
import {
  getEventGraphCenterDate,
  getGraphDuration,
} from 'common/utils/helpers/graphs';
import { abnormalityTypeEnum } from 'common/constants/ecgEnums';
import { getFirstEqualOrAfterTimeIndex } from 'common/utils/helpers/date';

import Description from './Description';
import useStyles from './styles';

const graphOptions = {
  xPropKey: 'time',
  yPropKey: 'value',
};

const SimpleGraph = ({
  eventData = {},
  onClick,
  onDoubleClick,
  width,
  height,
  domainY,
  graphProps = {},
  onScroll = () => null,
  selected = false,
  noSimilar = false,
  noDescription = false,
  withoutGreed = false,
  definedCenter,
  definedDuration,
  children,
}) => {
  const classes = useStyles();
  const containerRef = useRef(null);
  const descriptionRef = useRef(null);
  const [graphWidth, setGraphWidth] = useState(0);
  const [graphHeight, setGraphHeight] = useState(0);
  const { ecg, rotateEcg, ...restData } = eventData;
  const isPauseType = useMemo(
    () => restData.abnormalityType === abnormalityTypeEnum.pause,
    [restData.abnormalityType]
  );

  const graphLineTransform = useMemo(
    () =>
      rotateEcg ? `scale(1, -1) translate(0, -${graphHeight})` : undefined,
    [rotateEcg, graphHeight]
  );

  const centerDate = useMemo(() => {
    if (definedCenter) {
      return definedCenter;
    }

    return getEventGraphCenterDate(restData);
  }, [definedCenter, restData]);

  const startEventIndex = useMemo(
    () => getFirstEqualOrAfterTimeIndex(ecg, centerDate),
    [centerDate, ecg]
  );

  const duration = useMemo(
    () => getGraphDuration(restData.abnormalityType),
    [restData.abnormalityType]
  );

  const focusOptions = useMemo(
    () => ({
      center: definedCenter || ecg[startEventIndex]?.[graphOptions.xPropKey],
      duration: definedDuration || duration,
      domain: {
        x: d3.extent(ecg, (d) => new Date(d[graphOptions.xPropKey])),
        y: domainY || [
          d3.min(ecg, (d) => d[graphOptions.yPropKey]) - 200,
          d3.max(ecg, (d) => d[graphOptions.yPropKey]) + 200,
        ],
      },
    }),
    [ecg, domainY, definedCenter, duration, definedDuration, startEventIndex]
  );

  const initDimensions = useCallback(() => {
    const descriptionH = descriptionRef.current?.clientHeight || 0;

    if (containerRef.current) {
      const { clientWidth, clientHeight } = containerRef.current;
      setGraphWidth(clientWidth + 0.5);
      setGraphHeight(clientHeight - descriptionH);
    }
  }, [containerRef, descriptionRef]);

  useLayoutEffect(() => {
    initDimensions();
  }, [initDimensions]);

  const handleScroll = useCallback(
    (invert, scale, range) => {
      const center = invert(graphWidth / 2);
      onScroll(invert, scale, center.toISOString(), range);
    },
    [graphWidth, onScroll]
  );

  if (!eventData) {
    return 'Loading...';
  }

  return (
    <Grid
      item
      container
      ref={containerRef}
      className={classes.root}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      style={{
        width: width || '100%',
        height: height || '100%',
        minHeight: 60,
      }}
    >
      {!noDescription && (
        <Grid
          item
          container
          xs={12}
          ref={descriptionRef}
          className={classes.description}
        >
          <Description
            selected={selected}
            noSimilar={noSimilar}
            eventData={restData}
          />
        </Grid>
      )}

      {!!ecg.length && (
        <D3Container
          forceScroll
          data={ecg}
          {...graphProps}
          width={graphWidth}
          height={graphHeight}
          focus={focusOptions}
          options={graphOptions}
          onScrollHandler={handleScroll}
        >
          {!withoutGreed && <D3Grid color="grey" />}
          <CurveLine
            data={ecg}
            lineColor="black"
            transform={graphLineTransform}
          />
          {isPauseType ? <PauseLabel event={restData} /> : null}
          {children}
        </D3Container>
      )}
    </Grid>
  );
};

const dimPropTypes = PropTypes.oneOfType([PropTypes.number, PropTypes.string]);

SimpleGraph.propTypes = {
  children: PropTypes.shape({}),
  graphProps: PropTypes.shape({}),
  domainY: PropTypes.arrayOf(PropTypes.number),
  eventData: PropTypes.shape({
    procedureId: PropTypes.string,
    abnormalityType: PropTypes.string,
    id: PropTypes.string,
    similarEcgEventIds: PropTypes.arrayOf(PropTypes.string),
    ecg: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.number,
        i: PropTypes.number,
        y: PropTypes.number,
      })
    ),
    time: PropTypes.string,
    bpm: PropTypes.number,
  }),
  definedCenter: PropTypes.string,
  definedDuration: PropTypes.number,
  onClick: PropTypes.func,
  onDoubleClick: PropTypes.func,
  onScroll: PropTypes.func,
  width: dimPropTypes,
  height: dimPropTypes,
  noSimilar: PropTypes.bool,
  noDescription: PropTypes.bool,
  withoutGreed: PropTypes.bool,
  selected: PropTypes.bool,
};

const isEqual = (prev, next) =>
  prev.onClick === next.onClick &&
  prev.selected === next.selected &&
  prev.eventData.id === next.eventData.id &&
  prev.onDoubleClick === next.onDoubleClick &&
  prev.definedCenter === next.definedCenter &&
  prev.eventData.rotateEcg === next.eventData.rotateEcg &&
  prev.eventData.customDateFrom === next.eventData.customDateFrom &&
  prev.eventData.similar?.length === next.eventData.similar?.length;

export default React.memo(SimpleGraph, isEqual);
