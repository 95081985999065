import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';

import paths from 'common/constants/path';
import { useRedirect } from 'common/hooks';
import useAuthProvider from 'store/authProvider/useAuthProvider';
import { closeAllModals } from 'common/ducks/general/actions/modalActions';
import { getProcedure, getKit, getPatient } from 'common/utils/entitySelectors';

const useGetEventStripTitle = (procedureId) => {
  const dispatch = useDispatch();
  const redirect = useRedirect('replace');
  const { isEcgTech } = useAuthProvider();
  const procedure = useSelector(getProcedure(procedureId));

  const kit = useSelector(getKit(procedure?.kit));
  const patient = useSelector(getPatient(procedure?.patient));

  const pathToMonitoring = useMemo(
    () => paths.private.procedures.monitoring.index.replace(':id', procedureId),
    [procedureId]
  );

  const title = useMemo(() => {
    if (!patient && !kit) {
      return '';
    }

    const { serialNumber = 'Unknown' } = kit || {};
    const { firstName, lastName, fullName } = patient || {};

    const name =
      fullName || (firstName && lastName)
        ? fullName || `${firstName} ${lastName}`
        : 'Unknown';

    const emptySpace = Array(10).fill('\xa0').join('');

    return `${name} ${emptySpace} ${serialNumber}`;
  }, [patient, kit]);

  const navigateToMonitoring = useCallback(() => {
    dispatch(closeAllModals());
    redirect(pathToMonitoring);
  }, [pathToMonitoring, dispatch, redirect]);

  if (isEcgTech) {
    return null;
  }

  return (
    <Typography
      variant="h3"
      color="primary"
      style={{
        marginTop: 8,
        paddingLeft: 8,
        cursor: 'pointer',
        userSelect: 'none',
        textDecoration: 'underline',
      }}
      onClick={navigateToMonitoring}
    >
      {title}
    </Typography>
  );
};

export default useGetEventStripTitle;
