import React, { useEffect, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import Save from 'common/components/buttons/Save';
import Delete from 'common/components/buttons/Delete';
import TextField from 'common/components/formFields/TextField';
import { normalizeFormDefaultValues } from 'common/utils/helpers/form';

import contactsValidationScheme from '../constants/formValidationScheme';
import contactsFormFields from '../constants/contactsFields';
import { isLoading } from '../ducks/selectors';

const ContactsForm = ({
  onSubmit,
  onDelete,
  editing = false,
  defaultValues = {},
}) => {
  const loading = useSelector(isLoading);

  const normalizedDefaultValues = useMemo(
    () => normalizeFormDefaultValues(defaultValues),
    [defaultValues]
  );

  const { handleSubmit, control, formState, reset } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: normalizedDefaultValues,
    resolver: yupResolver(contactsValidationScheme),
  });

  const formFields = editing
    ? contactsFormFields
    : contactsFormFields.filter(({ editOnly }) => !editOnly);

  const updateDefaultValues = useCallback(() => {
    if (!Object.keys(normalizedDefaultValues).length) {
      contactsFormFields.map(({ name }) => reset({ [name]: '' }));
      return;
    }
    reset(normalizedDefaultValues);
  }, [normalizedDefaultValues, reset]);

  useEffect(() => {
    updateDefaultValues();
  }, [updateDefaultValues]);

  const renderField = ({ component, editOnly, width = '48%', ...field }) => {
    const FieldComponent = component || TextField;
    return (
      <Box key={field.name} my={1.4} width={width}>
        <FieldComponent
          {...field}
          fullWidth
          size="small"
          control={control}
          form={formState}
        />
      </Box>
    );
  };

  const submitForm = (data) => {
    const { phone, ...restData } = data;

    const phoneNumber = phone.replace(/-/g, '');

    onSubmit({
      use2FactorAuth: true,
      termsAccepted: false,
      phone: phoneNumber,
      ...restData,
    });
  };

  return (
    <form noValidate onSubmit={handleSubmit(submitForm)}>
      <DialogContent>
        <Grid container justifyContent="space-between">
          {formFields.map(renderField)}
        </Grid>
      </DialogContent>

      <DialogActions>
        <Grid container justifyContent="space-between" alignItems="center">
          <Save disabled={loading} />

          {editing && <Delete onClick={onDelete} disabled={loading} />}
        </Grid>
      </DialogActions>
    </form>
  );
};

ContactsForm.propTypes = {
  editing: PropTypes.bool,
  title: PropTypes.string,
  onDelete: PropTypes.func,
  submitButtonText: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  defaultValues: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.array,
      PropTypes.bool,
      PropTypes.shape(),
    ])
  ),
};

export default ContactsForm;
