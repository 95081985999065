import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';

import {
  ecgStripType,
  containerChildProps,
} from 'common/constants/sharedPropTypes';
import { lineGen } from '../utils';

const D3CurveLine = ({
  parent,
  transform,
  lineColor,
  strokeWidth,
  data = [],
}) => {
  const { clip, xScale, yScale, options = {}, scale } = parent;
  const { xPropKey, yPropKey } = options;

  const line = useCallback(
    (...args) => lineGen({ x: xPropKey, y: yPropKey }, yScale)(...args),
    [yScale, xPropKey, yPropKey]
  );

  return (
    <path
      fill="none"
      clipPath={clip}
      stroke={lineColor}
      strokeWidth={strokeWidth}
      d={line(data, scale || xScale)}
      transform={transform}
    />
  );
};

D3CurveLine.defaultProps = {
  strokeWidth: 1,
  lineColor: 'black',
};

D3CurveLine.propTypes = {
  parent: containerChildProps,
  transform: PropTypes.string,
  lineColor: PropTypes.string,
  strokeWidth: PropTypes.number,
  data: PropTypes.arrayOf(ecgStripType),
};

export default memo(D3CurveLine);
