import resources from 'common/constants/resources';
import { commonStatusTypes } from 'common/constants/enums';

export const messageTemplates = {
  [commonStatusTypes.inactive]: {
    title: 'Deactivate {entity}',
    message:
      'All users, inventories, procedures, patients, physicians within the {entityNames} {entity} will be deactivated. Are you sure you want to proceed?',
    warning:
      'The selected {entityNames} {entity} has an active procedure. {entity} can’t be deactivated due to active procedure',
  },
  [commonStatusTypes.deleted]: {
    title: 'Delete {entity}',
    message:
      'All users, inventories, procedures, patients, physicians within the {entityNames} {entity} will be deleted. Are you sure you want to proceed?',
    warning:
      'The selected {entityNames} {entity} has an active procedure. {entity} can’t be deleted due to active procedure',
  },
  edit: {
    title: 'Edit {entity}',
    message:
      'All users, inventories, procedures, patients, physicians within the {entityNames} {entity} will be deleted. Are you sure you want to proceed?',
    warning:
      'The selected {entityNames} {entity} can’t be edited due to active procedure',
  },
};

export const entityEnum = {
  [resources.kit.index]: { plural: "KIT's", singular: 'KIT' },
  [resources.patient.index]: { plural: 'users', singular: 'user' },
  [resources.patch.index]: { plural: 'patches', singular: 'patch' },
  [resources.phone.index]: { plural: 'phones', singular: 'phone' },
  [resources.simCard.index]: { plural: 'SIM cards', singular: 'SIM card' },
  [resources.physician.index]: { plural: 'physicians', singular: 'physician' },
  [resources.tenant.index]: {
    plural: 'organizations',
    singular: 'organization',
  },
};

export const entityPaths = {
  [resources.kit.index]: resources.kit.index,
  [resources.patch.index]: resources.patch.list,
  [resources.phone.index]: resources.phone.list,
  [resources.tenant.index]: resources.tenant.list,
  [resources.simCard.index]: resources.simCard.list,
  [resources.patient.index]: resources.patient.list,
  [resources.physician.index]: resources.physician.list,
};

export default messageTemplates;
