export const sessionStorageKeys = {
  manuallyAccepted: 'manuallyAccepted',
};

export const setSessionStorageData = (key, data) =>
  window.sessionStorage.setItem(key, data);

export const getSessionStorageData = (key) => {
  try {
    const d = window.sessionStorage.getItem(key);

    return JSON.parse(d);
  } catch (e) {
    return undefined;
  }
};

export const deleteSessionStorageData = (key) =>
  window.sessionStorage.removeItem(key);

export const getManuallyAccepted = () =>
  getSessionStorageData(sessionStorageKeys.manuallyAccepted) || [];

export const setManuallyAccepted = (data = []) =>
  setSessionStorageData(
    sessionStorageKeys.manuallyAccepted,
    JSON.stringify(data)
  );

export const removeManuallyAccepted = () =>
  deleteSessionStorageData(sessionStorageKeys.manuallyAccepted);
