import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import useStyles from './styles';

const TriageButton = ({
  color = 'primary',
  count,
  onClick = () => null,
  children,
  selected,
  className,
  labelClassName,
  typographyVariant = 'body2',
  ...props
}) => {
  const isDisabled = useMemo(() => count === 0, [count]);
  const classes = useStyles({ isDisabled, color, selected });

  return (
    <Button
      size="small"
      onClick={onClick}
      disabled={isDisabled}
      className={`${classes.triageButton} ${className}`}
      {...props}
    >
      <Typography
        variant={typographyVariant}
        className={`${classes.label} ${labelClassName}`}
      >
        {children}
      </Typography>
    </Button>
  );
};

TriageButton.propTypes = {
  onClick: PropTypes.func,
  count: PropTypes.number,
  selected: PropTypes.bool,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  typographyVariant: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  color: PropTypes.oneOf(['primary', 'secondary', 'default']),
};

export default TriageButton;
