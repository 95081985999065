import { createRequestActionTypes } from 'common/utils/actionTypesHelper';

export const notifyActionTypes = createRequestActionTypes(
  ['show', 'hide'],
  'notification'
);

export const initialState = {
  message: '',
  type: null,
  undoable: false,
};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case notifyActionTypes.show.requested: {
      if (!action.payload) {
        return state;
      }

      return { ...state, ...action.payload };
    }
    case notifyActionTypes.hide.requested:
      return initialState;
    default:
      return state;
  }
};

export const showNotification = (payload) => ({
  type: notifyActionTypes.show.requested,
  payload,
});

export const hideNotification = () => ({
  type: notifyActionTypes.hide.requested,
});

export default notificationReducer;
