// Fork from https://github.com/marmelab/react-admin/blob/master/packages/ra-data-json-server/src/index.ts

import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import { stringify } from 'query-string';
import get from 'lodash/get';

import { fetchJson, flattenObject, paramsToPath } from './fetchUtils';

/**
 *
 * queryParams = {
 *   filter: '',
 *   pagination: {
 *     page: number,
 *     perPage: number,
 *   },
 *   sort: {
 *     field: string,
 *     order: 'asc' || 'desc'
 *   },
 *   ids: array of id
 * };
 *
 * getOne              => GET ${apiUrl}${resource}/:id
 * getOnly             => GET ${apiUrl}${resource}
 * getList             => GET ${apiUrl}${resource}?${queryParams}
 *
 * updateOne           => PUT  ${apiUrl}${resource}/:id
 * updateOnly          => PUT  ${apiUrl}${resource}
 * updateList          => PUT  ${apiUrl}${resource}
 *
 * createOne           => POST ${apiUrl}${resource}
 * createList          => POST ${apiUrl}${resource}
 *
 * deleteOne           => DELETE ${apiUrl}${resource}/:id
 * deleteList          => DELETE ${apiUrl}${resource}
 *
 */

function generateQuery({ queryOptions = null, ...params }) {
  let query = get(params, 'filter', false) ? flattenObject(params.filter) : {};

  if (get(params, 'pagination', false)) {
    const { page, perPage } = params.pagination;
    query = {
      ...query,
      _start: (page - 1) * perPage,
      _end: page * perPage,
    };
  }

  if (get(params, 'sort', false)) {
    const { field, order } = params.sort;
    query = {
      ...query,
      _sort: field,
      _order: order,
    };
  }

  if (get(params, 'ids', false)) {
    query = { ...query, ids: params.ids };
  }

  if (Object.keys(query).length) {
    return `?${stringify(
      query,
      queryOptions || { arrayFormat: '&', encode: false }
    )}`;
  }

  return '';
}

// wrapper in progress

// const commonRequestWrapper = (link, props, isNecessaryIdAtTheEnd = false) => {
//   const { params, query, body, ...data } = props;
//
//   const requestBody = body || (typeof +Object.keys(data)?.[0] === 'number'
//         ? Object.values(data)
//         : data);
//
//   const parameterizedLink =
//     isNecessaryIdAtTheEnd && !params
//       ? `${paramsToPath(link, params)}/${requestBody.id}`
//       : paramsToPath(link, params);
//
//   const querisedLink = parameterizedLink
//
//   return {
//     resource,
//     body,
//   };
// };

export default (apiUrl, httpClient = fetchJson) => ({
  get: (resource, { params, signal, ...query }) =>
    httpClient(
      `${apiUrl}/${paramsToPath(resource, params)}${generateQuery(query)}`,
      { signal }
    ).then(({ json }) => ({ data: json })),

  getOne: (resource, { signal, ...params }) => {
    const parsedResource = resource.includes(':id')
      ? paramsToPath(resource, { id: params.id })
      : `${resource}/${params.id}`;

    return httpClient(`${apiUrl}/${parsedResource}`, { signal }).then(
      ({ json }) => ({
        data: json,
      })
    );
  },

  getList: (resource, { signal, ...params }) => {
    const url = `${apiUrl}/${resource}${generateQuery(params)}`;

    return httpClient(url, { signal }).then(({ headers, json }) => {
      const total = headers.has('x-total-count')
        ? parseInt(headers.get('x-total-count').split('/').pop(), 10)
        : null;
      return { data: json, total };
    });
  },

  updateOne: (resource, { params, query = {}, body, ...data }) => {
    const resourceWithId = params?.id
      ? resource.replace(':id', params.id)
      : resource;
    const parsedResource = paramsToPath(resourceWithId, params);
    const requestBody =
      body ||
      (Number.isInteger(+(Object.keys(data)?.[0] || 0))
        ? Object.values(data)
        : data);

    const isBodyEmpty =
      !requestBody ||
      (isArray(requestBody) && !requestBody.length) ||
      (isObject(requestBody) && !Object.keys(requestBody).length);

    let finalBody = body || data;

    if (!isBodyEmpty && typeof finalBody !== 'string') {
      finalBody = JSON.stringify(finalBody);
    }

    return httpClient(
      `${apiUrl}/${parsedResource}${generateQuery({ filter: query })}`,
      {
        method: 'PUT',
        body: isBodyEmpty ? null : finalBody,
      }
    ).then(({ json }) => ({ data: json }));
  },

  updateOneFormData: (resource, params) =>
    httpClient(`${apiUrl}/${resource}`, {
      method: 'PUT',
      body: params,
    }).then(({ json }) => ({ data: json })),

  updateList: (resource, params) =>
    httpClient(`${apiUrl}/${resource}`, {
      method: 'PUT',
      body: JSON.stringify(params),
    }).then(({ json }) => ({ data: json })),

  updateOnly: (resource, params) =>
    httpClient(`${apiUrl}/${resource}`, {
      method: 'PUT',
      body: JSON.stringify(params),
    }).then(({ json }) => ({ data: json })),

  updateMany: (resource, { query, body, options = {} } = {}) => {
    const isBodyEmpty =
      !body ||
      (isArray(body) && !body.length) ||
      (isObject(body) && !Object.keys(body).length);

    return httpClient(
      `${apiUrl}/${resource}${generateQuery({ filter: query })}`,
      {
        ...options,
        method: 'PUT',
        body: isBodyEmpty ? null : JSON.stringify(body),
      }
    ).then(({ json }) => ({ data: json }));
  },
  /**
   *
   * @param {string} resource - link to api endpoint
   * @param {object|array} params - replaceable params in resource if necessary
   * @param {object} query - custom query params for adding to POST request
   * @param {object|array} body - data that will be set to request body
   * @param options
   * @param {object} data - if "body" param isn't provided, this param would be used as a body of request
   * @returns {Promise}
   */
  createOne: (
    resource,
    { params, query, body, options = {}, ...data } = {}
  ) => {
    const res =
      !resource.includes(':id') && params?.id
        ? `${resource}/${params?.id}`
        : resource;

    const parsedResource = paramsToPath(res, params);

    const requestBody =
      body ||
      (Number.isInteger(+(Object.keys(data)?.[0] || ''))
        ? Object.values(data)
        : data);

    const isBodyEmpty =
      !requestBody ||
      (isArray(requestBody) && !requestBody.length) ||
      (isObject(requestBody) && !Object.keys(requestBody).length);

    return httpClient(
      `${apiUrl}/${parsedResource}${generateQuery({ filter: query })}`,
      {
        ...options,
        method: 'POST',
        body: isBodyEmpty ? null : JSON.stringify(body || requestBody),
      }
    ).then(({ json }) => ({ data: json }));
  },

  createOneFormData: (resource, { body, query }) =>
    httpClient(`${apiUrl}/${resource}${generateQuery({ filter: query })}`, {
      method: 'POST',
      body,
    }).then(({ json }) => ({ data: json })),

  createList: (resource, params) =>
    httpClient(`${apiUrl}/${resource}`, {
      method: 'POST',
      body: JSON.stringify(params),
    }).then(({ json }) => ({ data: json })),

  deleteOne: (resource, { params, ...query } = {}) => {
    const parsedResource = paramsToPath(resource, params);

    return httpClient(
      `${apiUrl}/${parsedResource}${generateQuery({ filter: query })}`,
      {
        method: 'DELETE',
      }
    ).then(({ json }) => ({ data: json }));
  },

  deleteList: (resource, params) =>
    httpClient(`${apiUrl}/${resource}`, {
      body: JSON.stringify(params),
      method: 'DELETE',
    }).then(({ json }) => ({ data: json })),

  patchData: (resource, params) =>
    httpClient(`${apiUrl}/${resource}`, {
      method: 'PATCH',
      body: JSON.stringify(params),
    }).then(({ json }) => ({ data: json })),

  archive: (resource, params) => {
    const ids = isArray(params)
      ? params.map((param) => param.id).join(',')
      : params.id;

    const parsedResource = resource.includes(':id')
      ? resource.replace(':id', `archive/${ids}`)
      : `${resource}/archive/${ids}`;

    return httpClient(`${apiUrl}/${parsedResource}`, {
      method: 'POST',
    }).then(({ json }) => ({ data: json }));
  },

  unarchive: (resource, params) => {
    const ids = isArray(params)
      ? params.map((param) => param.id).join(',')
      : params.id;

    const parsedResource = resource.includes(':id')
      ? resource.replace(':id', `unarchive/${ids}`)
      : `${resource}/unarchive/${ids}`;

    return httpClient(`${apiUrl}/${parsedResource}`, {
      method: 'POST',
    }).then(({ json }) => ({ data: json }));
  },

  getFile: (resource, { params, download = false, name, ...query }) =>
    httpClient(
      `${apiUrl}/${paramsToPath(resource, params)}${generateQuery(query)}`,
      { download, name }
    ).then(({ file }) => ({ data: file })),
});
