import React, { useState, useMemo, useCallback } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import TextInput from 'common/components/simpleInputs/TextInput';

import { noteInputStyles as useStyles } from '../styles';
import { eventNotes } from '../constants';

const NoteInput = ({ value = '', onChange = () => null }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const opened = useMemo(() => Boolean(anchorEl), [anchorEl]);

  const handleOpenModal = useCallback((anchor) => {
    setAnchorEl(anchor);

    if (anchor?.blur()) {
      anchor.blur();
    }
  }, []);

  const handleFocus = useCallback(
    (ev) => {
      if (value) {
        return;
      }

      handleOpenModal(ev.currentTarget);
    },
    [handleOpenModal, value]
  );

  const handleChange = useCallback(
    ({ target }) => {
      onChange(target.value);

      if (!target.value) {
        handleOpenModal(target);
      }
    },
    [handleOpenModal, onChange]
  );

  const handleClose = () => setAnchorEl(null);

  const handleSelectNote = useCallback(
    (v) => {
      onChange(v);
      handleClose();
    },
    [onChange]
  );

  return (
    <>
      <TextInput
        fullWidth
        name="note"
        size="small"
        placeholder="Notes"
        value={value}
        onFocus={handleFocus}
        onChange={handleChange}
        InputProps={{
          classes: {
            inputMarginDense: classes.inputMarginDense,
          },
        }}
      />

      <Menu
        open={opened}
        anchorEl={anchorEl}
        onClose={handleClose}
        style={{ maxHeight: 350 }}
      >
        {eventNotes.map(({ value: v }) => (
          <MenuItem key={v} value={v} onClick={() => handleSelectNote(v)}>
            {v}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default NoteInput;
