import moment from 'moment/moment';
import orderBy from 'lodash/orderBy';
import { all, put, takeLatest } from 'redux-saga/effects';

import dataProvider from 'store/dataProvider';
import resources from 'common/constants/resources';
import { getFirstEqualOrAfterTimeIndex } from 'common/utils/helpers/date';
import { actionTypes } from '../reducer';

const getOneEcg = async (filter) => {
  const response = await dataProvider.get(resources.ecgData.byPeriod, {
    filter,
  });

  return response.data;
};

const getAllEcg = async (includedHour) => {
  const ecgRanges = new Array(60).fill(null).map((_, index) => {
    const dateFrom = moment(includedHour.dateTime)
      .add(index, 'minute')
      .toISOString();
    const dateTo = moment(dateFrom).add(1, 'minute').toISOString();

    return {
      dateFrom,
      dateTo,
      precision: 25,
      procedureId: includedHour.procedureId,
    };
  });

  const hrResponse = await dataProvider.get(resources.healthData.ecgBeats, {
    filter: {
      dateFrom: includedHour.dateTime,
      dateTo: moment(includedHour.dateTime).add(1, 'hour').toISOString(),
      procedureId: includedHour.procedureId,
    },
  });

  const responseEcg = await getOneEcg({
    precision: 20,
    procedureId: includedHour.procedureId,
    dateFrom: includedHour.dateTime,
    dateTo: moment(includedHour.dateTime).add(1, 'hour').toISOString(),
  });

  const slicedEcgs = responseEcg?.length
    ? ecgRanges.map(({ dateFrom, dateTo }, index) => {
        const startIdx = getFirstEqualOrAfterTimeIndex(responseEcg, dateFrom);
        const endIdx = getFirstEqualOrAfterTimeIndex(responseEcg, dateTo);

        const lastIndex =
          index >= ecgRanges.length - 1
            ? Math.max(endIdx, responseEcg.length)
            : endIdx;

        return responseEcg.slice(startIdx, lastIndex);
      })
    : [];

  return {
    ...includedHour,
    hrs: hrResponse?.data || [],
    ecg: slicedEcgs,
  };
};

const fetchDataForDay = async ({ date, procedureId }) => {
  const dateFrom = moment(date).startOf('day').toISOString();
  const dateTo = moment(dateFrom).endOf('day').toISOString();

  const filter = { dateFrom, dateTo, procedureId };

  const response = await dataProvider.getList(
    resources.eventGroup.getIncludedDisclosure,
    { filter }
  );

  const includedHoursPerDay = await Promise.all(
    (response.data || []).map(getAllEcg)
  );

  return {
    [date]: orderBy(includedHoursPerDay, 'dateTime', 'asc').filter(
      (ih) => !!(ih?.ecg || []).flat().length
    ),
  };
};

function* fetchData({ payload }) {
  const { procedureId, reportDays } = payload;

  const reportIncludedHours = yield all(
    reportDays.map((date) =>
      fetchDataForDay({
        date,
        procedureId,
      })
    )
  );

  const data = reportIncludedHours.reduce(
    (acc, val) => ({ ...acc, ...val }),
    {}
  );

  try {
    yield put({
      type: actionTypes.getIncludedHours.success,
      payload: { data },
    });
  } catch ({ message }) {
    yield put({
      type: actionTypes.getIncludedHours.failed,
      message,
    });
  }
}

function* getIncludedHours() {
  yield takeLatest(actionTypes.getIncludedHours.requested, fetchData);
}

export default getIncludedHours;
