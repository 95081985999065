import * as Yup from 'yup';

import * as schemes from 'common/utils/validationSchemas';
import validationMessages from 'common/constants/validationErrors';

const letterOnlyRegExp = /^[A-Za-z\s]*$/;

export default Yup.object().shape({
  firstName: Yup.string()
    .required(validationMessages.patientName.firstNameRequired)
    .matches(letterOnlyRegExp, 'First Name can contain only letters'),
  lastName: Yup.string()
    .required(validationMessages.patientName.lastNameRequired)
    .matches(letterOnlyRegExp, 'Last Name can contain only letters'),
  middleName: Yup.string()
    .matches(letterOnlyRegExp, 'Middle Name can contain only letters')
    .nullable(),
  email: schemes.email,
  birthDate: schemes.birthDate,
  status: Yup.string().required(validationMessages.status.required),
  zipCode: schemes.addressZip,
  fax: schemes.fax,
  phone: schemes.phone,
  insurancePhone: schemes.phone,
  phoneCell: schemes.phoneCell,
});
