import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import routes from 'common/constants/path';
import { commonRoles } from 'common/constants/enums';
import useAuthProvider from 'store/authProvider/useAuthProvider';
import {
  contactPaths,
  physicianPaths,
  tenantProtectedRotes,
  tenantProtectedRoutesEnum,
} from './constants';

const PrivateRoute = ({ restriction = null, path, ...props }) => {
  const authProvider = useAuthProvider();
  const role = useMemo(
    () => authProvider.currentUserRole,
    [authProvider.currentUserRole]
  );
  const isLoggedIn = useMemo(
    () => authProvider.isLoggedIn,
    [authProvider.isLoggedIn]
  );
  const tenantAuthorized = useMemo(
    () => authProvider.isTenantAuthorized,
    [authProvider.isTenantAuthorized]
  );

  if (!isLoggedIn) {
    return <Redirect to={routes.public.login} />;
  }

  if (role === commonRoles.physician && !physicianPaths.includes(path)) {
    return <Redirect to={routes.physician.index} />;
  }

  if (role === commonRoles.contact && !contactPaths.includes(path)) {
    return <Redirect to={routes.contact.index} />;
  }

  if (!tenantAuthorized && tenantProtectedRotes.includes(path)) {
    const { redirect } = tenantProtectedRoutesEnum[path] || {};
    return <Redirect to={redirect} />;
  }

  if (restriction && !restriction.includes(role)) {
    return <Redirect to={routes.private.dashboard} />;
  }

  return <Route {...props} path={path} />;
};

PrivateRoute.propTypes = {
  path: PropTypes.string,
  restriction: PropTypes.arrayOf(PropTypes.string),
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

export default PrivateRoute;
